import { formatDateIso } from "../../util/helper";
import { Notificacao, useUser } from "../../Hook/user/UseUser";
import { NotificacaoStyles } from "./styles";

interface CardNotificacaoProps extends Notificacao {
    // acao: any;
    handleClick?: () => void;
    titleBtn?: string;
}

export function CardNotificacao(props: CardNotificacaoProps) {
    const { titulo, data_disparo, describe, id } = props;
    const { notificacoLida } = useUser();
    const regex = /<[^>]*>/;

    async function handleClick(id: number) {
        await notificacoLida(id);
    }

    //console.log("props", props.acao);

    return (
        <NotificacaoStyles>
            <div className={`container-img`}></div>
            <div className="container-text">
                {describe && regex.test(describe) ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: describe,
                        }}
                    ></div>
                ) : (
                    <>
                        <h3>{titulo}</h3>

                        <h4>{formatDateIso(data_disparo)}</h4>

                        <p>{props.acao ? props.acao.conteudo : describe}</p>
                    </>
                )}

                <button
                    onClick={() => {
                        if (props?.handleClick) {
                            props?.handleClick();
                        } else {
                            handleClick(id);
                        }
                    }}
                >
                    {props?.titleBtn ? props?.titleBtn : "MARCAR COMO LIDA"}
                </button>
            </div>
        </NotificacaoStyles>
    );
}
