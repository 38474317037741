import { useState } from "react";
import { Box } from "alisson-application";
import Mask from "react-input-mask";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Button } from "../../Button";
import { ButtonUpload } from "../../ButtonUpload";
import { ButtonUploadDefault } from "../../ButtonUpload/button";

import { Myform, initalValues, validadeForm } from "./config";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";
import { FormStyles } from "./styles";

export type keyOf<T> = keyof T;

export function FormWhiteList() {
    const [file, setFile] = useState<File>({} as File);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { store } = useWhiteList();

    return (
        <FormStyles>
            <Formik
                isInitialValid={false}
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    setLoading(true);

                    values = {
                        ...values,
                        document: values.document.replace(/[^\d]+/g, ""),
                    };

                    const formData = new FormData();

                    const keys = Object.keys(values) as keyOf<Myform>[];

                    keys.forEach((key) => {
                        if (key !== "file") {
                            formData.append(key, values[key] as string);
                        }
                    });

                    if (file.size) {
                        formData.append("file", file);
                    }

                    try {
                        const response = await store(formData);

                        if (response.status === 200) {
                            history.push("/sistema/white-list");
                        }
                    } catch (error) {
                        console.log(error);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue, handleChange, values } = props;
                    const { document } = values;

                    return (
                        <Form>
                            <div className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="name"
                                />
                            </div>
                            <ErrorMessage component="span" name="name" />

                            <div className="container-input-system user">
                                <Mask
                                    mask="999.999.999-99"
                                    placeholder="CPF"
                                    name="document"
                                    type="tel"
                                    onChange={handleChange}
                                    value={document}
                                />
                            </div>
                            <ErrorMessage component="span" name="document" />

                            <ButtonUpload
                                config={{
                                    accept: "image/*,application/pdff",
                                    id: "img-whatsapp",
                                }}
                                onChange={(values) => {
                                    if (values) {
                                        setFile(values[0]);
                                        setFieldValue("file", "ok");
                                    }
                                }}
                            >
                                <ButtonUploadDefault name={file?.name} />
                            </ButtonUpload>
                            <ErrorMessage component="span" name="file" />

                            <Box width="220px" mt="15px" ml="auto">
                                <Button
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    type="submit"
                                    disabled={!props.isValid}
                                >
                                    ADICIONAR
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
