import { useState } from "react";

import { useLogin } from "../../../../Hook/system/useLogin";

import { ConfirmToken } from "./screens/ConfirmToken";
import { FirstUpdate } from "./screens/FirstUpdate";
import { NewPassword } from "./screens/NewPassword";
import SuccessScreen from "./screens/Success";

export function ModalConfirmaCode() {
    return <h1>modal confirma code</h1>;
}

type IPages = "first_update" | "confirm" | "new_pass" | "success";

export function ModalValidaLogin() {
    const { user } = useLogin();
    const isFirstUpdate = user.user.first_update_password === 0;

    const [page, setPage] = useState<IPages>(
        isFirstUpdate ? "first_update" : "confirm"
    );

    const pages = {
        success: <SuccessScreen />,
        new_pass: <NewPassword onNextPage={() => setPage("success")} />,
        confirm: <ConfirmToken onNextPage={() => setPage("new_pass")} />,
        first_update: <FirstUpdate onNextPage={() => setPage("confirm")} />,
    };

    return <>{pages[page]}</>;
}
