import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { UseModal } from "../../Hook/Usemodal";
import { usePassos } from "../../Hook/UsePassos";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { api } from "../../services";
import { HeaderPassos } from "../Mobile/HeaderPassos";
import { Card } from "./card";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
        goBack: true,
    },
];

export interface Logs {
    logs: Delivery[];
}

export interface Delivery {
    active: boolean;
    date: null | string;
    descricao: string;
    linkrastreio: string | null;
    momento_log: string;
    status: string;
    delivery_date?: string;
}

export interface Dados {
    situacao: string;
    data_hora: string;
    momento_log: string;
    codlog: number;
    linkrastreio: string | null;
}

export function Entrega() {
    const width = window.screen.width;
    const history = useHistory();
    const { setState, state } = UseModal();
    const { cartaoId } = UseCartoes();
    const { setPassos } = usePassos();
    const [loading, setLoading] = useState(false);
    const hasPath = history.location.pathname.includes("/entrega");
    const [dados, setDados] = useState<Delivery[]>([]);

    useEffect(() => {
        if (state?.entrega || hasPath) {
            setPassos(arrPassos);
        }
    }, [setPassos, hasPath, state]);

    useEffect(() => {
        async function fectData() {
            setLoading(true);
            try {
                const response = await api.get<Logs>(
                    `cartao/${cartaoId}/delivery`
                );
                const dados: Delivery[] = [...response.data.logs];

                setLoading(false);
                setDados(dados);
            } catch (error) {
                toast.error(
                    "Estamos enfrentando problemas tecnicos. Tente novamente mais tarde!"
                );
                console.log(error);
                setLoading(false);
            }
        }

        if (cartaoId && state?.entrega) {
            fectData();
        }
    }, [cartaoId, state?.entrega]);

    return (
        <>
            <HeaderPassos
                title=""
                handleClose={() => {
                    if (width <= 480) {
                        setState({
                            ...state,
                            entrega: false,
                            acoesCartaoMobile: true,
                        });
                    } else {
                        setState({ ...state, entrega: false });
                        setPassos([]);
                    }
                }}
            />
            <Card loading={loading} dados={dados} />
        </>
    );
}
