import { useAprovacaoTransferencia } from "../../../../Hook/system/useAprovacaoTransferencia";

import { Aprovar } from "./Aprovar";
import { Aprovado } from "./Aprovado";
import { Reprovado } from "./Reprovado";

import { Container } from "./styles";
export function Content() {
    const { tabs } = useAprovacaoTransferencia();

    return (
        <Container>
            <div
                className={`container-tab remove-padding ${
                    tabs[0]?.active ? "active" : ""
                }`}
            >
                {tabs[0]?.active && <Aprovar />}
            </div>

            <div
                className={`container-tab remove-padding ${
                    tabs[1]?.active ? "active" : ""
                }`}
            >
                {tabs[1]?.active && <Aprovado />}
            </div>

            <div
                className={`container-tab remove-padding ${
                    tabs[2]?.active ? "active" : ""
                }`}
            >
                {tabs[2]?.active && <Reprovado />}
            </div>
        </Container>
    );
}
