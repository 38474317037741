import {
    NovoCartao,
    Cupom,
    Extrato,
    SolicitarResgate,
    AtivarCartao,
    AtualizarSenha,
    ModalPerfil,
    DadosCartao,
    ModalSegundaVia,
    Transferir,
} from "..";

import { UseModal } from "../../../Hook/Usemodal";
export function Desktop() {
    const { handleClose, state } = UseModal();
    return (
        <>
            {state?.novoCartao ? (
                <NovoCartao
                    open={state?.novoCartao}
                    handleClose={() => handleClose("novoCartao")}
                />
            ) : (
                <></>
            )}

            {state?.cupom ? (
                <Cupom
                    open={state?.cupom}
                    handleClose={() => handleClose("cupom")}
                />
            ) : (
                <></>
            )}

            {state?.extrato ? (
                <Extrato
                    open={state?.extrato}
                    handleClose={() => handleClose("extrato")}
                />
            ) : (
                <> </>
            )}

            {state?.solicitarResgate ? (
                <SolicitarResgate
                    open={state?.solicitarResgate}
                    handleClose={() => handleClose("solicitarResgate")}
                />
            ) : (
                <></>
            )}

            {state?.ativarCartao ? (
                <AtivarCartao
                    open={state?.ativarCartao}
                    handleClose={() => handleClose("ativarCartao")}
                />
            ) : (
                <></>
            )}

            {state?.atualizaSenha ? (
                <AtualizarSenha
                    open={state?.atualizaSenha}
                    handleClose={() => handleClose("atualizaSenha")}
                />
            ) : (
                <></>
            )}

            {state?.visualizarDados ? (
                <DadosCartao
                    open={state?.visualizarDados}
                    handleClose={() => handleClose("visualizarDados")}
                />
            ) : (
                <></>
            )}

            {state?.perfil ? (
                <ModalPerfil
                    open={state?.perfil}
                    handleClose={() => handleClose("perfil")}
                />
            ) : (
                <></>
            )}

            {state?.segundaVia ? (
                <ModalSegundaVia
                    open={state?.segundaVia}
                    handleClose={() => handleClose("segundaVia")}
                />
            ) : (
                <></>
            )}

            {state?.transferir ? (
                <Transferir
                    open={state?.transferir}
                    handleClose={() => handleClose("transferir")}
                />
            ) : (
                <></>
            )}
        </>
    );
}
