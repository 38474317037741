import { useHistory } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { Header as DefaultHeader } from "../Header";
import { Button } from "../Button";

interface IHeader {
    setSearch: (value: string) => void;
}

export function Header(props: IHeader) {
    const history = useHistory();

    const { setSearch } = props;

    return (
        <div className="busca">
            <DefaultHeader title="Faq" titleBtn="" permission="faq.index" />

            <div className="container-esq">
                <div className="container-input-system pesquisa">
                    <DebounceInput
                        type="text"
                        debounceTimeout={1000}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        placeholder="BUSQUE POR TITULO OU DESCRIÇÃO"
                    />
                </div>

                <Button
                    onClick={() => {
                        history.push("/sistema/faq/novo");
                    }}
                >
                    NOVO
                </Button>
            </div>
        </div>
    );
}
