import { Formik, Form, Field } from "formik";
import { useBackoffice } from "../../../../../Hook/system/useBackoffice";
import { Header } from "../Header";
import { useRef } from "react";

export function Identification() {
    const { state } = useBackoffice();
    const btnRef = useRef<HTMLButtonElement>(null);
    const initialValues = {
        id: state.id,
        document: state.document,
    };

    async function handleSave() {
        btnRef.current?.click();
    }

    return (
        <>
            <Header
                title="IDENTIFICAÇÃO"
                icon="document"
                onSave={handleSave}
                showBtns={false}
            />
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initialValues}
                onSubmit={(values, actions) => {}}
            >
                {(props) => {
                    return (
                        <Form>
                            <div className="container-element-backoffice">
                                <label htmlFor="">id</label>
                                <div className={`container-input-backoffice`}>
                                    <Field
                                        disabled={true}
                                        name="id"
                                        placeholder={state.id}
                                    />
                                </div>
                            </div>
                            <div className="container-element-backoffice">
                                <label htmlFor="">document</label>
                                <div className={`container-input-backoffice`}>
                                    <Field
                                        disabled={true}
                                        name="document"
                                        value={state.document}
                                        placeholder={state.id}
                                    />
                                </div>
                            </div>

                            <button hidden ref={btnRef} type="submit"></button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}
