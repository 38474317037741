import { useEffect, useState } from "react";

import { useAprovado } from "../Hook";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Loader } from "../../../../../Loader";
import Table, { Pagination } from "../../../../Table";
import { Data } from "./config";

import { Container } from "./styles";
import { Button } from "../../../../Button/areas";

import ImgAprovado from "../../../../../../assets/img/aprovado.svg";
import { Empty } from "../../../../Empty";
import {
    formataCPF,
    formatCurrentMoney,
    formatDateIsoSystem,
} from "../../../../../../util/helper";
import { PedidoCredito } from "../../config";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { Box } from "alisson-application";

export function TableAprovar() {
    const width = 1207 / 9;
    const [loading, setLoading] = useState(false);
    const { gePedido } = useAprovado();

    const [pedidos, setPedidos] = useState<PedidoCredito[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await gePedido(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setPedidos(response.data);
        } catch (error) {
            console.log(error);
            setPedidos([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const rows: Data[] = pedidos.map((pedido) => {
        return {
            id: pedido.id,
            data_hora: formatDateIsoSystem(pedido.created_at),
            plataforma: pedido?.plataforma?.name,
            nome: pedido?.user?.name,
            cpf: formataCPF(pedido?.user?.document),
            campanha_origem: pedido?.customer_campanha,
            pontos: "-",
            campanha_pay: pedido?.campanha?.nome,
            valor: `R$ ${formatCurrentMoney(parseFloat(pedido.valor))}`,
            status: pedido.status,
            responsavel: pedido?.approve_user?.name ?? "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "data_hora",
            headerName: "DATA/ HORA",
            width,
        },
        {
            field: "plataforma",
            headerName: "PLATAFORMA",
            width,
        },
        {
            field: "nome",
            headerName: "NOME",
            width,
        },
        {
            field: "cpf",
            headerName: "CPF",
            width,
        },
        {
            field: "campanha_origem",
            headerName: "CAMPANHA ORIGEM",
            width,
        },
        {
            field: "campanha_pay",
            headerName: "CAMPANHA PAY",
        },
        {
            field: "valor",
            headerName: "VALOR",
            width,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Button className="APROVADO">{row.status}</Button>;
            },
            width,
        },

        {
            field: "responsavel",
            headerName: "RESPONSÁVEL",
            width,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reponse = await gePedido();
            const { data, ...resto } = reponse;
            setPedidos(data);
            setConfig(resto);
            setLoading(false);
        }

        fetchData();
    }, [gePedido]);

    return (
        <Container>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : pedidos.length === 0 ? (
                <Box mt="128px">
                    <Empty
                        title="NENHUM CRÉDITO PARA APROVAR, NO MOMENTO"
                        img={ImgAprovado}
                    />
                </Box>
            ) : (
                <Table
                    rows={rows ? rows : []}
                    columns={columns}
                    nameCSV="USUÁRIOS"
                />
            )}

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
