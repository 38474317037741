import styled from "styled-components";

export const Container = styled.div`
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e2e3;
    .icon-user {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
`;
