import { toast } from "react-toastify";

import imgEmailcheck from "../../assets/img/email-check.svg";
import { UseModal } from "../../Hook/Usemodal";
import { usePrecisoDeAjuda } from "../../Hook/user/UsePrecisoDeAjuda";
import { Button } from "../Mobile/Button";

import { Text } from "alisson-application";
import { BtnTicketCode, Container, Title, Warning } from "./styles";

export function RetornoContato() {
    const { dataFaleConosco } = usePrecisoDeAjuda();
    const { handleClose } = UseModal();

    function copy() {
        const id = dataFaleConosco.id.toString();
        navigator.clipboard.writeText(id);
        toast.success(`ticket ${id} copiado(a)`);
    }

    return (
        <Container>
            <div className="container">
                <img className="img-close" src={imgEmailcheck} alt="" />

                <Title>Agradecemos seu contato!</Title>

                <Text as="h5" fontSize="xs" textAlign="center" color="primary">
                    <strong>
                        Em até 6 horas (das 9h às 18h, de segunda à sexta-feira)
                    </strong>
                    , você receberá uma resposta da Equipe Yetz uma resposta
                    pelo e-mail que você preencheu no formulário para nos
                    contatar. Mas <strong>ATENÇÃO</strong>, não deixe de
                    conferir seu <strong>SPAM ou LIXO ELETRÔNICO</strong> caso
                    passe desse período o localize em sua Caixa de Entrada.
                </Text>

                <Warning>Este é seu número de ticket</Warning>

                <BtnTicketCode onClick={() => copy()}>
                    <span>{dataFaleConosco?.id}</span>
                </BtnTicketCode>

                <Button
                    id="submit"
                    onClick={() => handleClose("retornoFaleConosco")}
                >
                    OK, ENTENDI
                </Button>
            </div>
        </Container>
    );
}
