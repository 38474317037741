import { useContext, createContext, useState } from "react";
import { getLocalStore } from "../../util/helper";

interface useConfigProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseConfig {
    setCsvNameExport: (value: string) => void;
    csvNameExport: string;

    search: string;
    setSearch: (value: string) => void;

    salt: string;
    setSalt: (value: string) => void;

    tabsCampanha: tabs[];
    setTabsCampanha: (values: tabs[]) => void;
    // tabCampanhaActived: string;
    // setTabCampanhaActived: (value: string) => void;
}

export interface tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
}

const UseConfigContext = createContext<IuseConfig>({} as IuseConfig);

const arrItens: tabs[] = [
    {
        id: 1,
        title: "carga de infra",
        className: "infra",
        active: getLocalStore().token_adm
            ? getLocalStore()?.permissions["carga.store"] || false
            : false,
        viewer: getLocalStore().token_adm
            ? getLocalStore()?.permissions["carga.store"] || false
            : false,
        permission: getLocalStore().token_adm
            ? getLocalStore()?.permissions["carga.store"] || false
            : false,
    },
    {
        id: 2,
        title: "carga de crédito",
        className: "credito",
        active: false,
        viewer: false,
        permission: getLocalStore().token_adm
            ? getLocalStore()?.permissions["carga.store"] || false
            : false,
    },
    {
        id: 3,
        title: "c. NONAME",
        className: "cartao-de-credito",
        active: false,
        viewer: false,
        permission: getLocalStore().token_adm
            ? getLocalStore()?.permissions["carga.store"] || false
            : false,
    },
    {
        id: 4,
        title: "Estorno de créd",
        className: "estorno-de-cred",
        active: false,
        viewer: false,
        permission: true,
    },
    {
        id: 5,
        title: "produtos",
        className: "produtos",
        active: getLocalStore().token_adm
            ? !getLocalStore().token_adm
                ? getLocalStore()?.permissions["carga.store"]
                : false
            : false,
        viewer: getLocalStore().token_adm
            ? !getLocalStore().token_adm
                ? getLocalStore()?.permissions["carga.store"]
                : false
            : false,
        permission: true,
    },
    {
        id: 6,
        title: "usuários",
        className: "usuarios",
        active: false,
        viewer: false,
        permission: true,
    },
    {
        id: 7,
        title: "regulamento",
        className: "regulamento",
        active: false,
        viewer: false,
        permission: true,
    },
    {
        id: 8,
        title: "ANTIFRAUDE",
        className: "antifralde",
        active: false,
        viewer: false,
        permission: true,
    },
];

export function ConfigProvider(props: useConfigProps) {
    const { children } = props;
    const [search, setSearch] = useState("");
    const [csvNameExport, setCsvNameExport] = useState("");
    const [salt, setSalt] = useState("");
    const [tabsCampanha, setTabsCampanha] = useState<tabs[]>(arrItens);

    return (
        <UseConfigContext.Provider
            value={{
                csvNameExport,
                setCsvNameExport,
                search,
                setSearch,
                salt,
                setSalt,
                tabsCampanha,
                setTabsCampanha,
            }}
        >
            {children}
        </UseConfigContext.Provider>
    );
}

export function useConfig() {
    const context = useContext(UseConfigContext);
    return context;
}
