import * as Yup from "yup";

export interface Myform {
    title: string;
    description: string;
}
export const initalValues: Myform = {
    title: "",
    description: "",
};

export const validadeForm = Yup.object().shape({
    title: Yup.string().required("Campo Obrigatório"),
    description: Yup.string().required("Campo Obrigatório"),
});
