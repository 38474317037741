import styled from "styled-components";

export const FormStyles = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 8px;
    }

    .areas {
        margin-bottom: 48px;
    }
    .disponibilidade {
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
`;
