import styled from "styled-components";

export const Container = styled.div`
    border-radius: 17px;
    border: 1px solid #e1e2e3;
    padding: 27px 17px 19px;
    margin-bottom: 20px;
    h3 {
        color: ${(props) => props.theme.colors.secondary};
        font-size: 14px;
        line-height: 140%;
        text-transform: uppercase;
        padding-bottom: 9px;
        border-bottom: 1px solid #e1e2e3;
        margin-bottom: 12px;
        font-weight: bold;
    }
`;

export const ContainerItemStyles = styled.div`
    margin-bottom: 14px;
    &:last-child {
        margin-bottom: 0;
    }
`;
