import { useState, ChangeEvent, useEffect } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import imgOlho from "../../../assets/img/olho.svg";

import {
    IAlterarSenha,
    altearSenha,
    validationAlterarSenha,
    validationAlterarSenhaCvv,
} from "./config";
import { FormStyles } from "./styles";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";


interface FormAtivarCartaoProps {
    handleSubimit: (values: IAlterarSenha) => void;
}

export function FormAtivarCartao(props: FormAtivarCartaoProps) {
    const { handleSubimit } = props;
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [validation, setValidation] = useState(validationAlterarSenha);
    const [values, setValues] = useState<IAlterarSenha>(altearSenha);
    const { handleClose } = UseModal();

    const { cartao } = UseCartoes();
    const cardType = cartao?.tipo;
    const hasCardPhysical = cardType !== "cartao_virtual";

    useEffect(() => {
        if (hasCardPhysical) {
            setValidation(
                validationAlterarSenha.concat(validationAlterarSenhaCvv)
            );
            setValues({ ...values, cvv: "" });
        } else {
            setValidation(validationAlterarSenha);
            setValues(altearSenha);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={values}
                validationSchema={validation}
                onSubmit={(values, actions) => {
                    handleSubimit(values);
                }}
            >
                {(props: FormikProps<IAlterarSenha>) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={4}
                                    value={values.password}
                                    autoComplete="new-password"
                                    placeholder="Criar Senha do Cartão"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.confirm_password &&
                                    props.touched.confirm_password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="confirm_password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "confirm_password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={4}
                                    value={values.confirm_password}
                                    autoComplete="new-password"
                                    placeholder="Confirmar Senha"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="confirm_password"
                                component="span"
                            />

                            {hasCardPhysical ? (
                                <>
                                    <div
                                        className={`container-input cartao-ativar ${
                                            props.errors.cvv &&
                                            props.touched.cvv
                                                ? "error"
                                                : ""
                                        }`}
                                    >
                                        <Field
                                            name="cvv"
                                            type="tel"
                                            onChange={(
                                                e: ChangeEvent<HTMLInputElement>
                                            ) =>
                                                setFieldValue(
                                                    "cvv",
                                                    e.target.value.replace(
                                                        /[^\d]+/g,
                                                        ""
                                                    )
                                                )
                                            }
                                            maxLength={3}
                                            autoComplete="new-password"
                                            value={values.cvv}
                                            placeholder="CVV "
                                        />
                                    </div>
                                    <ErrorMessage name="cvv" component="span" />
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="mt-auto">
                                <Button type="submit" disabled={!props.isValid}>
                                    Avançar
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <button className="cancelar"
                onClick={() => handleClose("atualizaSenha")}
            >
                Voltar
            </button>
        </FormStyles>
    );
}
