import { useCallback, useEffect, useState } from "react";

import { useCampanha } from "../../../../Hook/system/useCampanha";
import Select, { options } from "../../../NovoSelect";

interface SelectAreaClienteProps {
    id?: number;
    onChange?: (value: options) => void;
}

export function SelectArea(props: SelectAreaClienteProps) {
    const { id, onChange } = props;

    const [contatos, setContatos] = useState<options[]>([]);
    const { getClientesContatos } = useCampanha();

    const get = useCallback(() => {
        async function cliente() {
            if (id) {
                const response = await getClientesContatos(id);
                setContatos(
                    response.map((contato) => {
                        return {
                            label: `${contato.nome} - ${contato.area}`,
                            value: contato.id,
                        };
                    })
                );
            }
        }

        cliente();
    }, [getClientesContatos, id]);

    useEffect(() => {
        get();
    }, [get, id]);

    return (
        <Select
            tipo="contato"
            label="CONTATO"
            options={contatos}
            onChange={(values) => {
                if (onChange) {
                    onChange(values);
                }
            }}
        />
    );
}
