import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { LimiteBoleto } from "../../LimiteBoleto";

export function ModalLimiteBoleto(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <LimiteBoleto />
        </ModalBottom>
    );
}
