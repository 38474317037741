import styled from "styled-components";

export const CardStyles = styled.div`
    background: ${(props) => props.theme.colors.backgroundAlt};
    border-radius: 8px;
    height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        margin-top: 17px;
    }
    .container-img {
        background: ${(props) => props.theme.colors.secondary};
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Container = styled.div`
    padding-top: 105px;
    &.container {
        width: 976px;
    }
    .container-cards {
        padding-top: 119px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        margin-bottom: 220px;
    }

    .header {
        height: 46px;
        justify-content: space-between;
        align-items: center;
        .sair {
            cursor: pointer;
        }
    }
    .acoes {
        width: 313px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            display: flex;
            align-items: center;
            img {
                margin-right: 12px;
            }
        }
    }
`;
