import { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Header } from "../../Header";
import { HedaerStyles } from "./styles";
import { Button } from "../../Button";
import { useCupom } from "../../../../Hook/system/useCupom";

export function HeaderCupom() {
    const [loading, setLoading] = useState(false);
    const { exportar, fetchShowCupons } = useCupom();

    return (
        <HedaerStyles>
            <Header title="CUPONS" titleBtn="" permission="faq.cupom" />

            <div className="container-input-system pesquisa">
                <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    onChange={async (e) => {
                        await fetchShowCupons(undefined, e.target.value);
                    }}
                />
            </div>

            <Button
                isLoading={loading}
                onClick={async () => {
                    setLoading(true);
                    await exportar();
                    setLoading(false);
                }}
            >
                exportar
            </Button>
        </HedaerStyles>
    );
}
