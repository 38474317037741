import styled from "styled-components";

export const Button = styled.button`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 600px;
    text-align: center;
    padding: 10px 16px;
    white-space: nowrap;
`;

export const Container = styled.div`
    height: 44.39px;
    width: 100%;
    .swiper-slide {
        &.swiper-slide-active {
            ${Button} {
                background: ${(props) => props.theme.colors.disabled};
                transition: background 0.5s;
                color: ${(props) => props.theme.colors.secondarydark};
            }
        }
    }
`;
