import { useContext, createContext, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

import { api } from "../../../../../../services";
import { User } from "../../../../../../Hook/system/useUser";
import { toast } from "react-toastify";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { ClienteArea } from "../../../../../../Hook/system/useCampanha";
import { Produto } from "../../Produto/Hook";

const UseCartaoDeCreditoContext = createContext<useCartaoDeCreditoData>(
    {} as useCartaoDeCreditoData
);

interface useCartaoDeCreditoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useCartaoDeCreditoData {
    getCarga: (page?: number) => Promise<cargaData>;
    searchCarga(search: string): Promise<cargaData>;
    inProcess: () => Promise<process[]>;

    storeCarga: (values: FormData) => Promise<AxiosResponse>;
    setFile: (value: File | null) => void;
    file: File | null;

    aprove: (id: number) => Promise<AxiosResponse>;
    disApproval: (id: number) => Promise<AxiosResponse>;

    template: () => Promise<any>;

    getDetalhes: () => Promise<Detalhe>;
    deleteNoname(item_id: number): Promise<void>;
}

interface cargaData extends PaginationConfig {
    data: carga[];
}

export interface carga {
    approve_user_id: string | null;
    campanha_id: number;
    created_at: string;
    id: number;
    nome: string;
    status: string;
    tipo: string;
    total_itens_credito_sum: string;
    total_itens_users: number;
    updated_at: string;
    user: User;
    job: string;
    user_id: number;
    approve_user: AproveUser;
    cliente_area_contato: ClienteArea | null;
    total_noname_credito_sum: string | null;
    observacao: string | null;
}

interface AproveUser {
    created_at: string;
    document: string;
    email: string;
    first_login: number;
    id: number;
    image: null | string;
    name: string;
    phone_number: string;
    register_status: number;
    status: number;
    token: string;
    updated_at: string;
}

interface processData {
    data: process[];
}

export interface Detalhe {
    user_id: number;
    approve_user_id: number;
    campanha_id: number;
    created_at: string;
    file: string;
    id: number;
    nome: string;
    status: string;
    tipo: string;
    nonames: Nonames[];
    creditos_estorno: CreditosEstorno[];
}

export interface CreditosEstorno {
    carga_credito_id: number;
    carga_id: number;
    created_at: string;
    id: number;
    operacao_id: number;
    operation: Operation;
    user: User;
    carga_credito: CargaCredito;
}

export interface CargaCredito {
    carga_id: number;
    created_at: string;
    cupom_item_id: null;
    id: number;
    operacao_id: number;
    referencia: string;
    restante: string;
    status: number;
    updated_at: string;
    user_id: number;
    valor: string;
}

export interface Nonames {
    carga_id: number;
    cartao_id: number;
    created_at: string;
    id: number;
    updated_at: string;
    user: User;
    user_id: null | number;
    cartao: Cartao;
}

interface Operation {
    acg_transference_id: null | number;
    cartao_id: number;
    created_at: string;
    id: number;
    tipo: string;
    unique_id: null | number;
    updated_at: string;
    user_id: null | number;
    valor: string;
}

export interface Cartao {
    acg_account: string;
    acg_account_card_cvv: null | string;
    acg_account_card_expiration: string;
    acg_account_card_id: string;
    acg_account_card_number: string;
    acg_account_id: string;
    acg_comercial_origin_id: string;
    acg_produto_id: string;
    acg_sms_service: number;
    activated_at: null | number;
    campanha_id: number;
    canceled_at: null | number;
    celular: null | string;
    cpf: null | string;
    created_at: string;
    data_nascimento: null | string;
    first_active: number;
    id: number;
    nome_cartao: null | string;
    nome_completo: null | string;
    nome_mae: null | string;
    produto_id: number;
    requested_at: null | string;
    resgates_count: number;
    status: string;
    tipo: string;
    updated_at: string;
    user_id: null | string;
    operation: Operation | null;
    produto?: Produto;
}

export interface process {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: string | null;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    user: User;
    total_itens_users: number;
    total_itens_credito_sum: string;
    total_logs: number;
}

export function CreditoProvider(props: useCartaoDeCreditoProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const [file, setFile] = useState<File | null>(null);

    async function getCarga(page?: number) {
        const hasPage = page ? `&page=${page}` : "";
        try {
            const { data } = await api.get<cargaData>(
                `carga?campanha_id=${id}&status[]=APROVADO&tipo[]=NONAME${hasPage}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as cargaData;
        }
    }

    async function searchCarga(serach: string) {
        try {
            const { data } = await api.get<cargaData>(
                `carga/search?page=1&tipo=NONAME&campanha_id=${id}&search=${serach}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as cargaData;
        }
    }

    async function getDetalhes() {
        try {
            const response = await api.get<Detalhe>(`carga/${id}`);
            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as Detalhe;
        }
    }

    async function storeCarga(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.post("/carga", values, { headers });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function inProcess() {
        try {
            const { data } = await api.get<processData>(
                `carga?status[]=AGUARDANDO&status[]=PROCESSANDO&campanha_id=${id}&tipo[]=NONAME`
            );
            return data.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return [] as process[];
        }
    }

    async function aprove(id: number) {
        try {
            const response = await api.post(`carga/aprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga aprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function disApproval(id: number) {
        try {
            const response = await api.post(`carga/reprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga reprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function template() {
        try {
            const response = await api.get<any>(
                `campanha/${id}/export-example?type=CARTAO`
            );
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error;
        }
    }

    async function deleteNoname(item_id: number) {
        try {
            await api.delete(`carga/${id}/item/${item_id}/NONAME`);
            toast.success("Sucesso");
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error;
        }
    }

    return (
        <UseCartaoDeCreditoContext.Provider
            value={{
                getCarga,
                searchCarga,
                storeCarga,
                setFile,
                file,
                inProcess,
                aprove,
                disApproval,
                template,
                getDetalhes,
                deleteNoname,
            }}
        >
            {children}
        </UseCartaoDeCreditoContext.Provider>
    );
}

export function useCartaoDeCredito() {
    const context = useContext(UseCartaoDeCreditoContext);
    return context;
}
