import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageSolicitarResgate } from "../../../pages/SolicitarResgate";

export function SolicitarResgate(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PageSolicitarResgate />
        </Modal>
    );
}
