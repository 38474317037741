import styled from "styled-components";

interface ButtonProps {
    status?:
        | "CANCELED"
        | "DELIVERED"
        | "ERROR"
        | "NOT_DELIVERED"
        | "READ"
        | "REJECTED"
        | "SEND"
        | "SENT";
}

export const Button = styled.button<ButtonProps>`
    background: ${(props) => props.theme.colors.backgroundAlt};
    border-radius: 4px;
    padding: 8px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.primary};

    &.SEND {
        background: #f1f1f1;
    }

    &.SENT {
        background: #f1f1f1;
    }

    &.SENTED {
        background: #f1f1f1;
    }

    &.READ {
        background: rgba(182, 233, 255, 0.5);
    }

    &.REJECTED,
    &.ERROR {
        background: rgba(255, 187, 187, 0.5);
    }

    &.DELIVERED {
        background: ${(props) => props.theme.colors.disabled};
    }

    &.AGUARDANDO {
        background: #fff1dc;
    }

    &.APROVADO {
        background: rgba(220, 237, 219, 0.5);
    }

    &.REPROVADO {
        background: rgba(255, 227, 230, 0.5);
    }
`;
