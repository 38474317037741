import { usePassos } from "../../../Hook/UsePassos";
import { HeaderPassosStyles } from "./styles";

interface HeaderPassosProps {
    title: string;
}

export function HeaderPassos(props: HeaderPassosProps) {
    const { title } = props;
    const { passos } = usePassos();

    return (
        <HeaderPassosStyles>
            <div className="container-passos">
                {passos.map((item, key) => (
                    <div
                        key={key}
                        className={`${item.completed ? "active" : ""}`}
                    ></div>
                ))}
            </div>
            <h3>{title}</h3>
        </HeaderPassosStyles>
    );
}
