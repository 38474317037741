export const CircleCheck = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
        >
            <path
                d="M9.87688 4.48301C10.1049 4.25498 10.4748 4.25498 10.7028 4.48301C10.9308 4.71111 10.9308 5.08096 10.7028 5.30898L6.31645 9.69547C6.08835 9.92349 5.71849 9.92349 5.49047 9.69547L3.29722 7.50223C3.0692 7.27421 3.0692 6.90435 3.29722 6.67633C3.52533 6.44823 3.89518 6.44823 4.1232 6.67633L5.90346 8.45658L9.87688 4.48301ZM6.99996 0.0893555C8.93268 0.0893555 10.6829 0.872988 11.9496 2.13974C13.2164 3.40641 14 5.15668 14 7.08932C14 9.02227 13.2164 10.7722 11.9496 12.039C10.6829 13.3057 8.93268 14.0894 6.99996 14.0894C5.06709 14.0894 3.31706 13.3058 2.05039 12.039C0.783476 10.7722 0 9.02227 0 7.08932C0 5.15668 0.783633 3.40641 2.05039 2.13974C3.31706 0.872831 5.06709 0.0893555 6.99996 0.0893555ZM11.1237 2.96564C10.0685 1.91046 8.61044 1.25772 6.99996 1.25772C5.38941 1.25772 3.93139 1.91046 2.87629 2.96564C1.8211 4.02082 1.16837 5.47892 1.16837 7.08932C1.16837 8.69995 1.8211 10.158 2.87629 11.2131C3.93139 12.2683 5.38941 12.9209 6.99996 12.9209C8.61044 12.9209 10.0685 12.2683 11.1237 11.2131C12.1789 10.158 12.8316 8.69995 12.8316 7.08932C12.8316 5.47892 12.1789 4.02082 11.1237 2.96564Z"
                fill="#D50632"
            />
        </svg>
    );
};
