/* eslint-disable no-useless-escape */
import * as Yup from "yup";
const regexValidadeCPf =
    /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/;
export const isValidBRZipRegex = /^[0-9]{5}-[0-9]{3}$/;
const date = /\d{2}\/\d{2}\/\d{4}/;
export const regexPassword =
    /^(?!.*(.)\1{3})(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

const regexCPF = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;

export interface IDocumento {
    document: string;
    birth_date: string;
    email: string;
}

export const documento: IDocumento = {
    document: "",
    birth_date: "",
    email: "",
};

export const validationDocumento = Yup.object().shape({
    document: Yup.string()
        .matches(regexValidadeCPf, "Digite um CPF valido")
        .required("Campo obrigatório"),
    birth_date: Yup.string()
        .matches(date, "DIGITE A DATA CORRETAMENTE")
        .required("Campo obrigatório"),
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
});

export interface IEmail {
    email: string;
}

export const email: IEmail = {
    email: "",
};

export const validationEmail = Yup.object().shape({
    email: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
});

export interface IAlterarSenha {
    password: string;
    password_confirmation: string;
}

export const altearSenha: IAlterarSenha = {
    password: "",
    password_confirmation: "",
};

export const validationAlterarSenha = Yup.object().shape({
    password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Senha é obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),
});
