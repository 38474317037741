import { motion } from "framer-motion";
import imgX from "../../../../assets/img/x.svg";
import imgConfirmacao from "../../../../assets/img/confirmacao.svg";
import NovoModal from "../../../NovoModal";
import { Container } from "./styles";
import { Button } from "../../Button";
import { useAprovacaoTransferencia } from "../../../../Hook/system/useAprovacaoTransferencia";

export function Aprovar() {
    const { setModalAprovar, modalAprovar, loading, aprovarPedidos, ids } =
        useAprovacaoTransferencia();

    return (
        <motion.div
            animate={{ scale: [0.1, 1] }}
            transition={{ duration: 0.5 }}
        >
            <NovoModal
                handleClose={() => setModalAprovar(false)}
                open={modalAprovar}
            >
                <Container>
                    <div className="header">
                        <h3>confirmação</h3>
                        <button onClick={() => setModalAprovar(false)}>
                            <img src={imgX} alt="" />
                        </button>
                    </div>

                    <div className="container-img">
                        <img src={imgConfirmacao} alt="" />
                    </div>

                    <div className="container-text">
                        <h2>
                            Tem certeza que <br />
                            deseja <strong>APROVAR</strong> essas cargas de
                            crédito?
                        </h2>

                        <p>
                            Essa operação não poderá <br /> ser desfeita!
                        </p>

                        <div className="btns">
                            <Button
                                onClick={async () => {
                                    await aprovarPedidos(ids);
                                    setModalAprovar(false);
                                }}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                SIM, APROVAR
                            </Button>

                            <button
                                onClick={() => {
                                    setModalAprovar(false);
                                }}
                            >
                                CANCELAR
                            </button>
                        </div>
                    </div>
                </Container>
            </NovoModal>
        </motion.div>
    );
}
