import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { Text } from "alisson-application";
import { DataDetalheComunicacao } from "../../../../Hook/system/useComunicacao";
import { formatDateIsoSystem, formataCPF } from "../../../../util/helper";
import Table, { Pagination } from "../../Table";
import { Button } from "../../Button/areas";
import { Loader } from "../../../Loader";

import imgUser from "../../../../assets/img/user.svg";
import { ContainerDir } from "./styles";

interface DirProps extends DataDetalheComunicacao {
    pagination: (page: number) => void;
    loading: boolean;
}

export function Dir(props: DirProps) {
    const { data, pagination, loading } = props;
    const width = 1110 / 5;

    function status(value: string) {
        switch (value) {
            case "CANCELED":
                return "Cancelado";
            case "DELIVERED":
                return "Entregue";
            case "ERROR":
                return "Erro";
            case "NOT_DELIVERED":
                return "Não entregue";
            case "READ":
                return "Lido";
            case "REJECTED":
                return "Rejeitado";
            case "SEND":
                return "Enviado";
            case "SENT":
                return "Enviado";
            case "SENTED":
                return "Enviado";
            default:
                return "";
        }
    }

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.id}</Text>;
            },
            width: 80,
        },
        {
            field: "nome",
            headerName: "Nome",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.nome}</Text>;
            },
            width: width,
        },
        {
            field: "cpf",
            headerName: "CPF",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.cpf}</Text>;
            },
            width: width,
        },
        {
            field: "data_status",
            headerName: "DATA STATUS ",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.data_status}</Text>;
            },
            width: width,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (!row.status) return "";
                return (
                    <Button className={row.status}>{status(row.status)}</Button>
                );
            },
            width: width,
        },
    ];

    const rows = data?.length
        ? data.map((item) => {
              return {
                  id: item.id,
                  nome: item.name,
                  cpf: formataCPF(item.document),
                  data_status: item.data_disparo
                      ? formatDateIsoSystem(item.data_disparo)
                      : "",
                  status: item.status,
              };
          })
        : [];

    return (
        <ContainerDir>
            <h4>
                <img src={imgUser} alt="" />
                DESTINATÁRIO
            </h4>
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table columns={columns} rows={rows} nameCSV="COMUNICAÇÃO" />
            )}

            <Pagination
                total={props?.last_page}
                currentPage={(page) => pagination(page)}
            />
        </ContainerDir>
    );
}
