import { DesktopAndMobile } from "./DesktopAndMobile";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
export function ModaisWrap() {
    const width = window.screen.width;
    return (
        <>
            {width > 768 ? <Desktop /> : <Mobile />}
            <DesktopAndMobile />
        </>
    );
}
