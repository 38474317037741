import { useState, ChangeEvent, useEffect } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useStateUser } from "../../../Hook/user/UseStateUser";
import { useHistory } from "react-router-dom";
import { IAtivarCarao, ativar, validationAtivarCartao } from "./config";
import { FormStyles } from "./styles";
import imgOlho from "../../../assets/img/olho.svg";
import { UseModal } from "../../../Hook/Usemodal";

export function FormAtivarCartao() {
    const [olhoSenha, setOlhoSenha] = useState(false);
    const { nextPasso, setDados } = usePassos();
    const history = useHistory<{ id: number }>();
    const id = history.location?.state?.id;
    const { state } = UseModal();
    const { cartao } = UseCartoes();
    const { salt } = useStateUser();
    const [value, setValues] = useState({} as IAtivarCarao);
    const testeRegex = /[0-9]{4} [0-9]{4}/;
    const hasVirtual = cartao?.tipo === "cartao_virtual";

    function maskConta(value: string) {
        if (!value) return "";

        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{4})/, "$1 ");
        value = value.replace(/(\d{4,5})/, "$1");
        return value;
    }

    useEffect(() => {
        if (state.ativarCartaoAvulsoSubHeader || id === 0) {
            setValues(ativar);
            return;
        }

        if (hasVirtual) {
            const account =
                cartao.tipo === "cartao_virtual"
                    ? cartao?.acg_account_id?.substring(0, 4) +
                      " " +
                      cartao?.acg_account_id?.substring(4)
                    : "";
            setValues({
                ...ativar,
                cvv:
                    cartao.tipo === "cartao_virtual"
                        ? cartao?.acg_account_card_cvv
                        : "",
                acg_account_id: account,
            });
        } else {
            setValues(ativar);
        }
    }, [cartao, hasVirtual, salt, state.ativarCartaoAvulsoSubHeader, id]);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={value}
                validationSchema={validationAtivarCartao}
                onSubmit={(values, actions) => {
                    const { confirm_password, ...resto } = values;

                    setDados({
                        ...resto,
                        acg_account_id: resto.acg_account_id.replace(" ", ""),
                        cvv: values.cvv,
                    });

                    actions.resetForm();
                    nextPasso(2);
                }}
            >
                {(props: FormikProps<IAtivarCarao>) => {
                    const { values, setFieldValue } = props;
                    const { cvv } = values;

                    return (
                        <Form>
                            <div
                                className={`container-input cartao-ativar ${
                                    props.errors.acg_account_id &&
                                    props.touched.acg_account_id
                                        ? "error"
                                        : ""
                                } ${
                                    cartao?.tipo === "cartao_virtual" &&
                                    testeRegex.test(values?.acg_account_id)
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <Field
                                    placeholder="CONTA"
                                    name="acg_account_id"
                                    type="tel"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (e.target.value.length > 10) return;
                                        setFieldValue(
                                            "acg_account_id",
                                            maskConta(e.target.value)
                                        );
                                    }}
                                    value={maskConta(values.acg_account_id)}
                                />
                            </div>
                            <ErrorMessage
                                name="acg_account_id"
                                component="span"
                            />

                            <div
                                className={`container-input cartao-ativar ${
                                    props.errors.cvv && props.touched.cvv
                                        ? "error"
                                        : ""
                                } ${
                                    cartao?.tipo === "cartao_virtual" &&
                                    values?.cvv?.length === 3
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="cvv"
                                    type="tel"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "cvv",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    autoComplete="new-password"
                                    maxLength={3}
                                    value={cvv}
                                    placeholder="CVV "
                                />
                            </div>
                            <ErrorMessage name="cvv" component="span" />

                            <small>Senha numérica de 4 dígitos</small>

                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    autoComplete="new-password"
                                    maxLength={4}
                                    value={values.password}
                                    placeholder="CRIAR SENHA DO CARTÃO"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.confirm_password &&
                                    props.touched.confirm_password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="confirm_password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    autoComplete="new-password"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "confirm_password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={4}
                                    value={values.confirm_password}
                                    placeholder="CONFIRMAR SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="confirm_password"
                                component="span"
                            />

                            <div className="mt-auto">
                                <Button type="submit" disabled={!props.isValid}>
                                    ATIVAR CARTÃO
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
