import { useState } from "react";
import { useUser } from "./Hook";
import { Button as Btn } from "../../../Button";
import { HeaderStyles } from "./styles";
import { useCampanha } from "../../../../../Hook/system/useCampanha";
import { formatNumber } from "../../../../../util/helper";
import { DebounceInput } from "react-debounce-input";

interface HeaderProps {
    setSearch: (value: string) => void;
}

export function Header(props: HeaderProps) {
    const { setSearch } = props;
    const { exportar } = useUser();
    const { campanhaById } = useCampanha();
    const [loadingBtn, setLoadingBtn] = useState(false);

    return (
        <HeaderStyles>
            <div className="container-usuarios">
                <span>
                    Total de usuários:{" "}
                    <small>
                        {formatNumber(campanhaById?.total_users || 0)}
                    </small>
                </span>
                <span>
                    Total cadastro completo:{" "}
                    <small>
                        {formatNumber(campanhaById?.total_completo || 0)}
                    </small>
                </span>
                <span>
                    Total cadastro incompleto:{" "}
                    <small>
                        {formatNumber(campanhaById?.total_incompleto || 0)}
                    </small>
                </span>
            </div>

            <div className="container-input-system pesquisa">
                <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>

            <div className="container-btn">
                <Btn
                    isLoading={loadingBtn}
                    onClick={async () => {
                        setLoadingBtn(true);
                        await exportar();
                        setLoadingBtn(false);
                    }}
                >
                    Exportar
                </Btn>
            </div>
        </HeaderStyles>
    );
}
