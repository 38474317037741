import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${(props) => props.theme.colors.white};
        mix-blend-mode: luminosity;
        opacity: 0.5;
        z-index: 10;
    }
    .card-status {
        background: ${(props) => props.theme.colors.white};
        text-align: center;
        padding: 23px 7px 6px 7px;
        width: 152px;
        height: 187px;
        position: relative;
        z-index: 99;
        border-radius: 4px;
        img {
            width: 55px;
            margin-bottom: 8px;
        }
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.06em;
            color: ${(props) => props.theme.colors.primary};
        }
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.06em;
        color: ${(props) => props.theme.colors.secondary};
        margin-top: 5px;
    }
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 8px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.06em;
        color: ${(props) => props.theme.colors.secondary};
        display: block;
        margin-bottom: 5px;
        &.solicitado {
            color: #f59e1f;
            font-size: 18px;
        }
        &.cancelar {
            color: ${(props) => props.theme.colors.primaryDark};
            font-size: 10px;
        }
    }
`;
