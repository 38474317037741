import { BlockStatus } from "../blockStatus";
import { SubHeaderStyles } from "./styles";
import imgCartao from "../../../assets/img/cartao-cinza.svg";
import { useNoname } from "../../../Hook/system/useNoname";
import { Button } from "../Button";
import { useCartao } from "../../../Hook/system/useCartao";

export function SubHeader() {
    const { virtualDetail } = useNoname();
    const { getCard } = useCartao();

    return (
        <SubHeaderStyles>
            <div className="container-cartao">
                <h3>
                    <img src={imgCartao} alt="" />
                    CARTÕES VIRTUAIS
                </h3>
                <div className="container-blocks">
                    <BlockStatus
                        title="TOTAL"
                        check={true}
                        value={virtualDetail?.total_virtual}
                    />
                    <div className="container-blocks-button">
                        <Button
                            onClick={async () => {
                                await getCard(1, "", "cartao_virtual");
                            }}
                        >
                            BUSCAR TODOS
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-cartao">
                <h3>
                    <img src={imgCartao} alt="" />
                    CARTÕES FÍSICOS PERSONALIZADOS
                </h3>
                <div className="container-blocks">
                    <BlockStatus
                        title="TOTAL"
                        check={true}
                        value={virtualDetail?.total_fisico}
                    />
                    <BlockStatus
                        title="ATIVOS"
                        value={virtualDetail?.total_fisico_ativo}
                    />
                    <BlockStatus
                        title="NÃO ATIVO"
                        value={virtualDetail?.total_fisico_nao_ativo}
                    />
                    <div className="container-blocks-button">
                        <Button
                            onClick={async () =>
                                await getCard(1, "", "cartao_fisico")
                            }
                        >
                            BUSCAR TODOS
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-cartao">
                <h3>
                    <img src={imgCartao} alt="" />
                    CARTÕES FÍSICOS NO NAME
                </h3>
                <div className="container-blocks">
                    <BlockStatus
                        title="TOTAL"
                        check={true}
                        value={virtualDetail?.total_avulso}
                    />
                    <BlockStatus
                        title="ATIVOS"
                        value={virtualDetail?.total_vinculados_avulso}
                    />
                    <BlockStatus
                        title="NÃO ATIVO"
                        value={virtualDetail.total_nao_vinculados_avulso}
                    />
                    <div className="container-blocks-button">
                        <Button
                            onClick={async () =>
                                await getCard(1, "", "cartao_avulso")
                            }
                        >
                            BUSCAR TODOS
                        </Button>
                    </div>
                </div>
            </div>
        </SubHeaderStyles>
    );
}
