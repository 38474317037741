import { useState, useContext, createContext } from "react";

interface ModalRegulamento {
    setModalRegulamento: (value: boolean) => void;
    modalRegulamento: boolean;

    setNomeArquivoRegulamento: (value: string) => void;
    nomeArquivoRegulamento: string;
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

const Context = createContext<ModalRegulamento>({} as ModalRegulamento);

export function ModalInfraProvider(props: ProviderProps) {
    const { children } = props;
    const [modalRegulamento, setModalRegulamento] = useState(false);
    const [nomeArquivoRegulamento, setNomeArquivoRegulamento] = useState("");

    return (
        <Context.Provider
            value={{
                modalRegulamento,
                setModalRegulamento,
                nomeArquivoRegulamento,
                setNomeArquivoRegulamento,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
