import React from "react";
import { Link } from "react-router-dom";
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import "../../dbr"; // import side effects. The license, engineResourcePath, so on.
import { VideoDecode } from "../VideoDecode";
import imgLogo from "../../assets/img/logo.svg";
import imgSetaEsq from "../../assets/img/seta-esq-verde.svg";
import "./HelloWorld.css";
import { Text } from "alisson-application";

interface isState {
    bShowScanner: boolean;
    bShowImgDecode: boolean;
}

class HelloWorld extends React.Component<any, isState> {
    async componentDidMount() {
        try {
            await BarcodeReader.loadWasm();
        } catch (ex: any) {
            if (ex.message.indexOf("network connection error")) {
                let customMsg =
                    "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                alert(customMsg);
            }
            throw ex;
        }
    }

    render() {
        return (
            <div className="container-scan">
                <div className="header">
                    <Link to="/pagamento-boleto">
                        <img src={imgSetaEsq} alt="" />
                    </Link>

                    <div className="container-logo">
                        <img src={imgLogo} alt="" />
                    </div>
                </div>
                <VideoDecode />

                <div className="container-text">
                    <Text as="p">
                        Posicione a linha verde sobre o código de barras
                    </Text>
                </div>
            </div>
        );
    }
}
export default HelloWorld;
