import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import { useBackoffice } from "../../../../../Hook/system/useBackoffice";
import { Header } from "../Header";
import { SelectDate } from "../Select";
import { options } from "../../../../NovoSelect";
import { Input } from "../Input";
import { Container } from "./styles";
import { IOSSwitch } from "../../../Switch";
import { Flex } from "alisson-application";

const addressTypeOptions: options[] = [
    {
        value: "1",
        label: "RESIDENTIAL",
    },
];

export function Addresses() {
    const {
        state,
        createObjBackoffice,
        updateAddresses,
        loading,
        createAddresses,
    } = useBackoffice();
    const removeKeys = ["isActive", "mailingAddress"];
    const { arrKey, arrKeyRemoved, values } = createObjBackoffice(
        state.addresses,
        "addresses",
        removeKeys
    );
    const btnRef = useRef<HTMLButtonElement>(null);

    const tempArr = [...arrKey];

    const first = tempArr.splice(0, 4);

    async function handleSave() {
        btnRef.current?.click();
    }

    return (
        <Container>
            <Header title="endereço" icon="addresses" onSave={handleSave} />
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={values}
                onSubmit={async (values, actions) => {
                    const { id, ...rest } = values;
                    if (state.addresses.length === 0) {
                        await createAddresses(rest);
                    } else {
                        await updateAddresses(id as number, rest);
                    }
                }}
            >
                {(props) => {
                    const { setFieldValue, values } = props;

                    return (
                        <Form>
                            {first.map((item) => {
                                const [key] = item;
                                return (
                                    <Field
                                        component={Input}
                                        name={key}
                                        disabled={loading}
                                    />
                                );
                            })}

                            <div className="container-itens">
                                {tempArr.map((item) => {
                                    const [key] = item;

                                    if (key === "addressType") {
                                        return (
                                            <Field
                                                component={SelectDate}
                                                name={key}
                                                disabled={loading}
                                                options={addressTypeOptions}
                                            />
                                        );
                                    }
                                    return (
                                        <Field
                                            component={Input}
                                            name={key}
                                            disabled={loading}
                                        />
                                    );
                                })}
                            </div>

                            <button hidden ref={btnRef} type="submit"></button>
                            <div className="isActive">
                                {arrKeyRemoved.map((item) => {
                                    const [key] = item;
                                    const checked = values[key] ? true : false;
                                    return (
                                        <Flex alignItems="inherit" key={key}>
                                            <small>{key}</small>
                                            <IOSSwitch
                                                checked={checked}
                                                disabled={loading}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        key,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </Flex>
                                    );
                                })}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
