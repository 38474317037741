import { useHistory } from "react-router-dom";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { Button } from "../ButtonSlim";
import { ButtonGoBack } from "../ButtonGoBack";
import { Container } from "../../Modais/ModalContainer/styles"
import imgSuccess from "../../../assets/img/money.svg";

import { UseModal } from "../../../Hook/Usemodal";
import { toast } from "react-toastify";

export function AlertaBoleto() {
    const width = window.screen.width;
    const { cartao } = UseCartoes();

    const { handleClose, state, setState } = UseModal();
    const history = useHistory();

    function handleAcceptModal() {
        const updatePassword =
            cartao?.first_update_password === 0 &&
            cartao.tipo === "cartao_virtual";

        if (width <= 480) {
            if (updatePassword) {
                handleClose("alertaBoleto");
                history.push("/alterar-senha-cartao");
                toast.error(
                    "Você precisa cadastrar sua senha antes de continuar"
                );
                return;
            }
            handleClose("alertaBoleto");
            history.push({
                pathname: "/pagamento-boleto",
                state: {
                    valid: true,
                },
            });
        } else {
            if (updatePassword) {
                toast.error(
                    "Você precisa cadastrar sua senha antes de continuar"
                );
                setState({
                    ...state,
                    alertaBoleto: false,
                    atualizaSenha: true,
                });
                return;
            }
            setState({
                ...state,
                alertaBoleto: false,
                boleto: true,
            });
        }
    }

    return (
        <Container>
                <img className="img-close" src={imgSuccess} alt="" />

                <h2 className="success">
                    Custo do Pagamento 
                    <br/>
                    de Contas
                </h2>

                <p className="strong">
                    Há um custo de R$ 3,50 por pagamento, debitado do saldo do seu cartão.
                </p>
                <p>
                    Você concorda com essa cobrança?
                </p>

                <div className="buttonContainer">
                    <Button onClick={() => handleAcceptModal()}>
                        Confirmar Cobrança
                    </Button>

                    <ButtonGoBack onClick={() => handleClose("alertaBoleto")}>
                        Cancelar
                    </ButtonGoBack>
                </div>
        </Container>
    );
}
