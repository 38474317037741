import { Header } from "../Header";
import { Subheader } from "./subheader";
import { TableFiles } from "../TableFiles";

import ImgUpload from "../../../assets/img/menu/upload-files.svg";
import { useUpload } from "../../../Hook/system/useUploadFile";
import ModalAprove from "../../../components/System/ModalAlert";
import { When } from "../../when";
import { Loader } from "../../Loader";

export function UploadFiles() {
    const {
        loading,
        setModalExcluir,
        modalExcluir,
        deleteFile,
        stateEnviados,
        stateRecebidos,
    } = useUpload();

    return (
        <>
            <Header
                title="UPLOAD DE ARQUIVOS"
                permission="usuario.me"
                image={ImgUpload}
            />
            <Subheader />
            <When expr={loading}>
                <Loader height={150} width={150} />
            </When>
            <When expr={!loading}>
                <>
                    <TableFiles
                        data={stateRecebidos}
                        title="ARQUIVOS RECEBIDOS"
                    />
                    <TableFiles
                        data={stateEnviados}
                        title="ARQUIVOS enviados"
                    />
                </>
            </When>

            <ModalAprove
                handleClose={() => setModalExcluir(false)}
                reject={() => setModalExcluir(false)}
                aceept={async () => {
                    await deleteFile();
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalExcluir}
            />
        </>
    );
}
