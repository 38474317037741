import styled from "styled-components";

export const Container = styled.div`
    padding: 0px 5%;
    padding-top: 44px;
    padding-bottom: 51px;
    h1 {
        font-size: 14px;
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: 32px;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
    }

    .container-description {
        margin-top: 31px;
        margin-bottom: 37px;
        p {
            font-size: 16px;
            color: ${({ theme }) => theme.colors.black};
            text-align: center;
            margin-bottom: 32px;
        }
    }
`;
