import * as Yup from "yup";

export interface CampanhaFormUpdate {
    cliente_id?: string;
    cliente_area_contato_id?: string;
    nome?: string;
    chave?: string;
    custom_fields?: Array<string>;
    start_date_time?: string;
    end_date_time?: string;
    register_type?: string;
    external_register: boolean;
    force_account_register: boolean;
    force_activate_account_login: boolean;
    allow_transference_between_users: boolean;
    allow_transference_between_campain: boolean;
    allow_cupom: boolean;
    allow_bill_payment: boolean;
    virtual_card_on_register: boolean;
    allow_bill_payment_available: boolean;
    // regulamento: string;
    // status: string;
}
export const myform: CampanhaFormUpdate = {
    cliente_id: "",
    cliente_area_contato_id: "",
    nome: "",
    chave: "",
    custom_fields: [""],
    start_date_time: "",
    end_date_time: "",
    register_type: "",
    external_register: false,
    force_account_register: false,
    force_activate_account_login: false,
    allow_transference_between_users: false,
    allow_transference_between_campain: false,
    allow_cupom: false,
    allow_bill_payment: false,
    virtual_card_on_register: false,
    allow_bill_payment_available: true,
    // regulamento: "",
    // status: "",
};

export const validadeMyform = Yup.object().shape({
    cliente_id: Yup.string().required("Campo obrigatório"),
    cliente_area_contato_id: Yup.string().required("Campo obrigatório"),
    nome: Yup.string().required("Campo obrigatório"),
    chave: Yup.string().required("Campo obrigatório"),
    custom_fields: Yup.array().of(Yup.string()),
    start_date_time: Yup.string().required("Campo obrigatório"),
    end_date_time: Yup.string().required("Campo obrigatório"),
    register_type: Yup.string().required("Campo obrigatório"),
    external_register: Yup.bool(),
    force_account_register: Yup.bool(),
    force_activate_account_login: Yup.bool(),
    allow_transference_between_users: Yup.bool(),
    allow_transference_between_campain: Yup.bool(),
    allow_cupom: Yup.bool(),
    allow_bill_payment: Yup.bool(),
    virtual_card_on_register: Yup.bool(),
    allow_bill_payment_available: Yup.bool(),
    // regulamento: Yup.string().required("Campo obrigatório"),
    // status: Yup.string().required("Campo obrigatório"),
});
