import { useContext, createContext } from "react";
import { PedidoCredito } from "../../config";
import { api } from "../../../../../../services";

import { toast } from "react-toastify";
import { PaginationConfig } from "../../../../../../Hook/system/config";

interface useAprovarProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useAprovarData {
    gePedido(page?: number): Promise<AprovarData>;
}

export interface AprovarData extends PaginationConfig {
    data: PedidoCredito[];
}

const UseAprovarContext = createContext<useAprovarData>({} as useAprovarData);

export function AprovarProvider(props: useAprovarProviderProps) {
    const { children } = props;

    async function gePedido(page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const { data } = await api.get<AprovarData>(
                `pedido-credito?page=${hasPage}&status=AGUARDANDO`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as AprovarData;
        }
    }

    return (
        <UseAprovarContext.Provider value={{ gePedido }}>
            {children}
        </UseAprovarContext.Provider>
    );
}

export function useAprovar() {
    const context = useContext(UseAprovarContext);
    return context;
}
