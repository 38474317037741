import { useState } from "react";
import { useCupom } from "../../../../Hook/system/useCupom";
import { CheckBox } from "../../CheckBox";
import { Loader } from "../../../Loader";

interface CheckCupomProps {
    id: number;
    status: boolean;
}
export function CheckCupom(props: CheckCupomProps) {
    const { id, status } = props;
    const [loading, setLoading] = useState(false);
    const { updateCupom } = useCupom();

    return (
        <>
            {loading ? (
                <Loader width={20} height={20} />
            ) : (
                <CheckBox
                    checked={status}
                    onChange={async () => {
                        setLoading(true);
                        await updateCupom([id]);
                        setLoading(false);
                    }}
                />
            )}
        </>
    );
}
