import { memo, useEffect, useState } from "react";

import { InfraProvider as Provider, useUser, User } from "./Hook";
import { useHistory } from "react-router-dom";
import { formataCPF, formatCurrentMoney } from "../../../../../util/helper";
import Table, { Pagination } from "../../../Table";
import { Button } from "../../../Button/areas";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Data } from "./config";
import { Container } from "./styles";
import { PaginationConfig } from "../../../../../Hook/system/config";
import { Loader } from "../../../../Loader";
import { When } from "../../../../when";
import { Header } from "./header";

function Usuarios() {
    const { getUser } = useUser();
    const [search, setSearch] = useState<string>("");
    const [users, setUsers] = useState<User[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    async function pagination(page?: number, search?: string) {
        setLoading(true);
        try {
            const response = await getUser(page, search);
            const { data, ...resto } = response;

            setConfig(resto);
            setUsers(response.data);
        } catch (error) {
            console.log(error);
            setUsers([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const rows: Data[] = users?.map((user) => {
        return {
            id: user.id,
            campanha: user.campanha,
            nome: user.name.toUpperCase(),
            cpf: formataCPF(user.document),
            pts_credito: user.total_creditos
                ? "R$ " + formatCurrentMoney(parseFloat(user.total_creditos))
                : "R$ 0,00",
            status: user.status ? "Ativo" : "Inativo",
            cadastro: user?.register_status ? "Completo" : "Incompleto",
            // next: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
        },
        {
            field: "campanha",
            headerName: "Campanha",
            width: 201,
        },
        {
            field: "nome",
            headerName: "Nome",
            width: 201,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/usuario/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            });
                        }}
                    >
                        {row.nome}
                    </button>
                );
            },
        },
        {
            field: "cpf",
            headerName: "CPF",
            width: 201,
        },
        {
            field: "pts_credito",
            headerName: "CRÉDITO EM R$",
            width: 201,
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
        },
        {
            field: "cadastro",
            headerName: "Cadastro",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Button
                        className={`${
                            row.cadastro === "Incompleto" ? "REJECTED" : ""
                        }`}
                    >
                        {row.cadastro}
                    </Button>
                );
            },
            width: 201,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response = await getUser();
            const { data, ...resto } = response;
            setUsers(data);
            setConfig(resto);
            setLoading(false);
        }

        fetchData();
    }, [getUser]);

    useEffect(() => {
        if (search) {
            pagination(1, search);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    return (
        <Container>
            <Header setSearch={setSearch} />

            <When expr={!loading}>
                <Table rows={rows} columns={collumns} nameCSV="USUÁRIOS" />
            </When>

            <When expr={loading}>
                <div className="container-loading">
                    <Loader />
                </div>
            </When>

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page, search);
                    }}
                />
            ) : (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page, search);
                    }}
                />
            )}
        </Container>
    );
}

function UserProvider() {
    return (
        <Provider>
            <Usuarios />
        </Provider>
    );
}

export const UserMemorized = memo(UserProvider);
