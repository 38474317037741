import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";

export interface options {
    label: string;
    value: number | string;
}

interface AutoCompleteProps {
    initalValue?: options | null;

    options: options[];
    onChange?: (value: options) => void;
    label: string;
    icon?: boolean;
}

export default function CustomInputAutocomplete(props: AutoCompleteProps) {
    const { options, onChange, label, initalValue, icon = true } = props;
    const [values, setValues] = useState<options | null>(null);
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (initalValue) {
            setValues(initalValue);
        } else {
            setValues(null);
        }
    }, [initalValue]);

    return (
        <label>
            <Autocomplete
                sx={{
                    display: "inline-block",
                    "& input": {
                        width: "100%",
                        bgcolor: "background.paper",
                        color: (theme) =>
                            theme.palette.getContrastText(
                                theme.palette.background.paper
                            ),
                    },
                }}
                onChange={(event, values: any) => {
                    if (onChange) {
                        onChange(values);
                    }
                    setValues(values);
                }}
                id="custom-input-demo"
                options={options}
                value={values}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderInput={(params) => (
                    <div
                        className={`container-input-system select ${
                            icon ? "user" : ""
                        }`}
                        ref={params.InputProps.ref}
                    >
                        <input
                            type="text"
                            {...params.inputProps}
                            placeholder={label}
                        />
                    </div>
                )}
            />
        </label>
    );
}
