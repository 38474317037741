import { TableAprovar } from "./main/table";
import { AprovadoProvider } from "./Hook";

export function Aprovado() {
    return (
        <AprovadoProvider>
            <TableAprovar />
        </AprovadoProvider>
    );
}
