import { Container } from "./styles";

interface LineTextBoletoProps {
    title: string;
    text: string;
    img?: string;
}

export function LineTextBoleto(props: LineTextBoletoProps) {
    const { text, title, img } = props;
    return (
        <Container>
            {img ? <img src={img} alt="" /> : <></>}
            <p>{title}</p>
            <small>{text}</small>
        </Container>
    );
}
