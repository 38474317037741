import React, { createContext, useContext } from "react";

import { toast } from "react-toastify";
import { api } from "../../services/";
import { ClientCNPJData } from "../types";
import { PaginationConfig } from "./config";

interface UseClienteProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseClienteData {
    getCliente: (page?: number) => Promise<ClienteData>;
    recoveryClientCnpjData: (cnpj: string) => Promise<ClientCNPJData>;
}

interface Areas {
    id: number;
    cliente_id: number;
    nome: string;
    created_at: null | string;
    updated_at: null | string;
}

export interface Clientes {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    areas: Areas[];
}

export interface ClienteData extends PaginationConfig {
    data: Clientes[];
}

const UseClienteContext = createContext<UseClienteData>({} as UseClienteData);

export function ClienteProvider(props: UseClienteProviderProps) {
    const { children } = props;

    async function getCliente(page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const response = await api.get<ClienteData>(`/cliente${hasPage}`);

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar clientes");
            return {} as ClienteData;
        }
    }

    async function recoveryClientCnpjData(cnpj: string) {
        try {
            const { data } = await api.post<ClientCNPJData>("/cnpj/search", { cnpj });

            return data;
        } catch (error: any) {
            toast.error("Erro ao solicitar dados do CNPJ!");
            return { success: false } as ClientCNPJData;
        }
    }

    return (
        <UseClienteContext.Provider value={{ getCliente, recoveryClientCnpjData }}>
            {children}
        </UseClienteContext.Provider>
    );
}

export function useCliente() {
    const context = useContext(UseClienteContext);

    return context;
}
