import { BarcodeReader } from "dynamsoft-javascript-barcode";
BarcodeReader.license =
    "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAyMDIwNTY3LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAyMDIwNTY3IiwiY2hlY2tDb2RlIjotOTg4NjA4ODMyfQ==";

// BarcodeReader.license =
//     "DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxOTI0OTMwLVRYbE5iMkpwYkdWUWNtOXFYMlJpY2ciLCJtYWluU2VydmVyVVJMIjoiaHR0cHM6Ly9tZGxzLmR5bmFtc29mdG9ubGluZS5jb20iLCJvcmdhbml6YXRpb25JRCI6IjEwMTkyNDkzMCIsImNoZWNrQ29kZSI6LTQyODQ3NTk2MH0=";
// BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxOTI0OTMwLVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21kbHMuZHluYW1zb2Z0b25saW5lLmNvbSIsIm9yZ2FuaXphdGlvbklEIjoiMTAxOTI0OTMwIiwiY2hlY2tDb2RlIjotMTI4Njg2ODYwOH0=';
BarcodeReader.engineResourcePath =
    "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.0.2/dist/";

// 15 53 02 35 60
