import { useState } from "react";
import { toast } from "react-toastify";
import { StyledOlho } from "./styles";
import imgOlho from "../../../../assets/img/olho.svg";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { api } from "../../../../services";
import { formatCurrentMoney } from "../../../../util/helper";

interface SaldoProps {
    id: string;
}

export interface Valor {
    saldo: string;
}

export function Saldo(props: SaldoProps) {
    const [valor, setValor] = useState("");
    const [loading, setLoading] = useState(false);

    async function getSaldo() {
        if (valor) {
            setValor("");
            return;
        }

        setLoading(true);

        try {
            const response = await api.get<Valor>(`cartao/${props.id}/saldo`);

            setValor(response.data.saldo);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Erro ao carregar Saldo");
        }
    }

    return (
        <StyledOlho onClick={getSaldo} disabled={loading}>
            <div>
                {loading ? (
                    "⌛"
                ) : valor ? (
                    <>
                        {`R$ ${formatCurrentMoney(parseFloat(valor))}`}
                        <img src={imgX} alt="" />
                    </>
                ) : (
                    <img src={imgOlho} alt="" />
                )}
            </div>
        </StyledOlho>
    );
}
