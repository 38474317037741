import { ContainerVideo } from "./styles";
import Vimeo from "@u-wave/react-vimeo";

interface VideoProps {
    link?: string;
}

export function Video(props: VideoProps) {
    const { link } = props;
    const widthScreen = window.screen.width;
    const width = window.screen.width * 0.95;
    return (
        <ContainerVideo>
            <div className="container-video-modal">
                <Vimeo
                    // src={video + "?embedparameter=value"}
                    video={
                        link ? link : "https://vimeo.com/788072973/6c0d592216"
                    }
                    autoplay
                    width={widthScreen >= 480 ? 600 : parseInt(width + "")}
                    volume={0.5}
                ></Vimeo>
            </div>
        </ContainerVideo>
    );
}
