import { HeaderNovo } from "../../Header/novo";
import { TemplateProvider } from "../../../../Hook/system/useTemplate";
import { FormComunicacao } from "./form";
import { FileProvider } from "../hook";

export function NovaComunicacao() {
    return (
        <TemplateProvider>
            <FileProvider>
                <HeaderNovo title="NOVA COMUNICAÇÃO" />
                <FormComunicacao />
            </FileProvider>
        </TemplateProvider>
    );
}
