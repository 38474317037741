import { Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import {
    useComunicacao,
    Comunicacao as IComunicacao,
} from "../../../../Hook/system/useComunicacao";
import { PaginationConfig } from "../../../../Hook/system/config";
import ModalAprove from "../../../../components/System/ModalAlert";
import { Card } from "./card";
import { Header } from "../../Header";
import { Container } from "../styles";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "../../../Loader";
import { Pagination } from "../../Table";

export function Comunicacao() {
    const history = useHistory();
    const [comunicacao, setComunicacao] = useState<IComunicacao[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [id, setId] = useState<number | null>(null);
    const { getComunicacao, handleDelete } = useComunicacao();

    function handleDeleteButton(id: number) {
        setOpenModal(true);
        setId(id);
    }

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getComunicacao(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setComunicacao(response.data);
        } catch (error) {
            console.log(error);
            setComunicacao([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await getComunicacao();
        const { data, ...resto } = response;
        setComunicacao(data);
        setConfig(resto);
        setLoading(false);
    }, [getComunicacao]);

    useEffect(() => {
        fetchData();
    }, [fetchData, getComunicacao]);

    return (
        <>
            <Box mb="40px">
                <Header
                    title="comunicação"
                    titleBtn="ADICIONAR COMUNICAÇÃO"
                    permission="acao.store"
                    onClickBtn={() => history.push("/sistema/comunicacao/novo")}
                />
            </Box>
            <Container>
                {loading ? (
                    <div className="container-loading">
                        <Loader />
                    </div>
                ) : (
                    comunicacao?.map((com) => (
                        <Card {...com} handleDelete={handleDeleteButton} />
                    ))
                )}
            </Container>

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}

            <ModalAprove
                handleClose={() => setOpenModal(false)}
                reject={() => setOpenModal(false)}
                aceept={async () => {
                    if (id) {
                        await handleDelete(id);
                        await fetchData();
                        setOpenModal(false);
                        setId(null);
                    }
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={openModal}
            />
        </>
    );
}
