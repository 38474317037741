import * as Yup from "yup";

export interface CartaoAvulso {
    acg_account_id: string;
}

export const cartao: CartaoAvulso = {
    acg_account_id: "",
};

export const validationCartao = Yup.object().shape({
    acg_account_id: Yup.string().required("Campo obrigatório"),
});
