import { useEffect, useState } from "react";
import { Container } from "./styles";

import maisImg from "../../assets/img/+-verde.svg";
import menoImg from "../../assets/img/-verde.svg";
import { Loader } from "../../components/Loader";

interface ContatorProps {
    onChange?: (value: number) => void;
    initialValue?: number;
    loading?: boolean;
}

export function Contador(props: ContatorProps) {
    const { onChange, initialValue, loading = false } = props;
    const [value, setValue] = useState(0);

    function decrementar() {
        if (value > 0) {
            setValue(value - 1);
            if (onChange) {
                onChange(value - 1);
            }
        }
    }

    function incrementar() {
        setValue(value + 1);
        if (onChange) {
            onChange(value + 1);
        }
    }

    useEffect(() => {
        if (Number.isInteger(initialValue)) {
            setValue(initialValue ? initialValue : 0);
        }
    }, [initialValue]);

    return (
        <Container className={`${loading ? "active" : ""}`}>
            {loading ? (
                <Loader width={30} height={30} />
            ) : (
                <div className="container-contador">
                    <button onClick={decrementar}>
                        <img src={menoImg} alt="" />
                    </button>
                    {value}
                    <button onClick={incrementar}>
                        <img src={maisImg} alt="" />
                    </button>
                </div>
            )}
        </Container>
    );
}
