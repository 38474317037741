import React, { ButtonHTMLAttributes, useState } from 'react'
import { FraudBtnContainer, WarningContainer, WarningContent } from './styles'
import Modal from '@mui/material/Modal';

import { IOSSwitch } from '../../../../Switch';
import { useUser } from '../../../../../../Hook/system/useUser';

interface IFraudBtn extends ButtonHTMLAttributes<HTMLButtonElement> {
  user_id: number;
}

const FraudBtn = ({ user_id, ...rest }: IFraudBtn) => {

  const { markAsFraud } = useUser()
  const [showConfirmTooltip, setShowConfirmTooltip] = useState(false)
  const [deletedUser, setDeletedUser] = useState(false)

  function handleConfirmFraud() {
    setDeletedUser(true)
    handleCloseTooltip()
    markAsFraud(Number(user_id))
  }

  function handleOpenTooltip() {
    setShowConfirmTooltip(true)
  }

  function handleCloseTooltip() {
    setShowConfirmTooltip(false)
  }

  return (
    <>
      <Modal
        open={showConfirmTooltip}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <WarningContainer>
          <WarningContent>
            <h1>Atenção!</h1>
            <span>
              Essa ação irá deletar todos os dados do usuário! Você tem certeza?
            </span>
            <section>
              <button onClick={handleConfirmFraud}>
                Confirmar
              </button>

              <button onClick={handleCloseTooltip}>Cancelar</button>
            </section>
          </WarningContent>
        </WarningContainer>
      </Modal>

      <FraudBtnContainer onClick={handleOpenTooltip} {...rest}>
        <IOSSwitch
          checked={deletedUser}
        />
      </FraudBtnContainer>
    </>
  )
}

export default FraudBtn