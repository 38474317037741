import { HeaderNovo } from "../../Header/novo";
import { FormCupom } from "./form";

export function NovoCupom() {
    return (
        <>
            <HeaderNovo title="novo cupom" />
            <FormCupom />
        </>
    );
}
