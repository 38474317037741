import styled from "styled-components";
import ImgLupa from "../../../assets/img/lupa.svg";

export const SubHeaderStyles = styled.div`
    height: 71px;
    width: 100%;
    background-color: #f4f4f4;
    padding-left: 33px;
    padding-right: 22px;
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    .container-resultados {
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            margin-left: 8px;
            margin-right: 23px;
        }
    }

    .container-busca {
        margin-left: 66px;
        display: flex;
        align-items: center;
        .container-input-upload-files {
            position: relative;
            margin-left: 8px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${ImgLupa}) no-repeat;
                width: 18px;
                height: 18px;
                z-index: 20;
            }

            input {
                border-radius: 26px;
                border: 1px solid #acafb1;
                background: #fff;

                color: #767b7f;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;

                height: 32px;
                width: 211px;
                padding-right: 26px;
                padding-left: 17px;
                &::placeholder {
                    color: #acafb1;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                }
            }
        }
    }

    .container-button {
        margin-left: auto;
    }
`;
