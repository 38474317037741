import { Slide } from "./slide";
import { FormLogin } from "../FormLogin";
import { Ajuda } from "./ajuda";
import imgLogoMobile from "../../../assets/img/logo-mobile.svg";
import { RefObject } from "react";
import { useFormLogin } from "../../../Hook/user/useFormLogin";

interface ContentLoginProps {
    ref: RefObject<HTMLDivElement>;
    hasRegister: boolean | null;
    setRegister: (value: boolean) => void;
}

export function ContentLogin(props: ContentLoginProps) {
    const { ref, setRegister } = props;
    const { controllerForm } = useFormLogin();

    return (
        <>
            <div className="container-img">
                <img src={imgLogoMobile} alt="" className="logo-mobile" />

                <Slide />

                <div className="container-texto-boas-vindas" ref={ref}>
                    <p className="text-login-mobile">
                        Que bom ver você por aqui! Para começar, informe os seus
                        dados de acesso.
                    </p>
                    <p className="text-registro">
                        Excelente! É sua primeira vez no YETZPAY. Complete seu
                        cadastro para poder acessar.
                    </p>
                </div>
            </div>

            <div className={`content-form`}>
                <FormLogin setRegister={setRegister} />
                {!controllerForm.completeRegister && <Ajuda />}
            </div>
        </>
    );
}
