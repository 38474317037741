import * as Yup from "yup";

export interface Myform
    extends IFormNotificacao,
        FormPopUp,
        FormWhats,
        FormSms {
    titulo: string;
    tipo: string;
    segmentacao: string;
    canal: string;
    recipientes: string;
}
export const initalValues: Myform = {
    titulo: "",
    tipo: "",
    segmentacao: "",
    canal: "",
    recipientes: "",
    recurrence: "",
};

export const validadeForm = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),
    segmentacao: Yup.string(),
    canal: Yup.string(),
    recipientes: Yup.string(),
});

/** NOTIFICAÇÃO */

export const formNotificacao: IFormNotificacao = {
    conteudo: "",
    disparo: "",
    data_disparo: "",
};

export interface IFormNotificacao {
    conteudo?: string;
    disparo?: string;
    data_disparo?: string;
}

export const validadeFormNotificacao = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),

    conteudo: Yup.string().required("Campo Obrigatório"),
    disparo: Yup.string().required("Campo Obrigatório"),
    data_disparo: Yup.string().required("Campo Obrigatório"),
});

/**POPUP */
export interface FormPopUp {
    arte_desktop?: string;
    arte_mobile?: string;
    data_disparo?: string;
    data_fim?: string;
    recurrence: string;
}

export const formPopUp: FormPopUp = {
    arte_desktop: "",
    arte_mobile: "",
    data_disparo: "",
    data_fim: "",
    recurrence: "",
};

export const validadeFormPopUp = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),

    arte_desktop: Yup.string().required("Campo Obrigatório"),
    arte_mobile: Yup.string().required("Campo Obrigatório"),
    data_disparo: Yup.string().required("Campo Obrigatório"),
    data_fim: Yup.string().required("Campo Obrigatório"),
    recurrence: Yup.string().required("Campo Obrigatório"),
    canal: Yup.string().required("Campo Obrigatório"),
});

/**WHATSAPP */
export interface FormWhats {
    template_id?: string;
    arte_desktop?: string;
    disparo?: string;
}

export const formWhats: FormWhats = {
    template_id: "",
    arte_desktop: "",
    disparo: "",
};

export const validadeFormWhats = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),

    template_id: Yup.string().required("Campo Obrigatório"),
    arte_desktop: Yup.string().required("Campo Obrigatório"),
    disparo: Yup.string().required("Campo Obrigatório"),
    data_disparo: Yup.string().required("Campo Obrigatório"),
});

/** SMS */
export interface FormSms {
    template_id?: string;
    data_disparo?: string;
    disparo?: string;
}

export const formSms: FormSms = {
    template_id: "",
    data_disparo: "",
    disparo: "",
};

export const validadeFormSms = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),

    conteudo: Yup.string().required("Campo Obrigatório"),
    data_disparo: Yup.string().required("Campo Obrigatório"),
    disparo: Yup.string().required("Campo Obrigatório"),
});
