import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageAlterarSenhaCartao } from "../../../pages/AlterarSenhaCartao";

export function AtualizarSenha(props: ModalConfig) {
    const { handleClose, open } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PageAlterarSenhaCartao />
        </Modal>
    );
}
