import { useContext, createContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AxiosResponse } from "axios";
import { Clientes } from "./useCliente";
import { api } from "../../services";
import { toast } from "react-toastify";

interface useSegmentacaoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseSegmentacao {
    store: (values: FormData) => Promise<AxiosResponse>;

    getCampanha(ids: Array<number>): Promise<any>;
    // getCampanha(): Promise<any>;
    getClient(): Promise<Cliente[]>;

    setModal: (value: boolean) => void;
    modal: boolean;

    setIds: (ids: Array<number>) => void;
    ids: Array<number>;

    setSegmentacao: (value: any) => void;
    segmentacao: any;

    setSegmentacaoArea: (value: any) => void;
    segmentacaoArea: any;

    campanhas: any;
    setCampanhas: (value: any) => void;
}

const UseSegmentacaoContext = createContext<IuseSegmentacao>(
    {} as IuseSegmentacao
);

export interface CampanhaSeg {
    id: number;
    cliente_id: number;
    cliente_area_contato_id: number;
    nome: string;
    chave: string;
    regulamento: string;
    status: string;
    max_carga_credito_per_user: null | number;
    custom_fields: string;
    emissao: number;
    start_date_time: string;
    end_date_time: string;
    created_at: string;
    updated_at: string;
    fields: Fields;
    cliente: Clientes;
}

export interface Cliente {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    created_at: string;
    updated_at: string;
}

export interface ClienteDate {
    data: Cliente[];
}

interface Fields {
    [key: string]: string[];
}

export function SegmentacaoProvider(props: useSegmentacaoProviderProps) {
    const { children } = props;
    const [modal, setModal] = useState(false);
    const [ids, setIds] = useState<Array<number>>([]);
    const [segmentacao, setSegmentacao] = useState<any>([]);
    const [campanhas, setCampanhas] = useState<Array<number>>([]);
    const [segmentacaoArea, setSegmentacaoArea] = useState<any>([]);

    async function store(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.post("/acao", values, {
                headers,
            });
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function getCampanha(ids: Array<number>) {
        const paramns = ids.reduce((item, acc) => {
            return item + `clientes[]=${acc}&`;
        }, "");

        if (ids.length === 0) {
            setSegmentacao([]);
            toast.error("Selecione uma campanha");
            return;
        }

        try {
            const response = await api.get<CampanhaSeg[]>(
                `/campanha/fields?${paramns}`
            );

            const itens = response.data.reduce((acc, item) => {
                return acc + Object.keys(item.fields).length;
            }, 0);

            if (itens === 0) {
                setSegmentacao([]);
                toast.error("Nenhuma campanha encontrada");
                return;
            }

            const keys = [
                ...new Set(
                    response.data.map((item) => {
                        return item.cliente.id;
                    })
                ),
            ];

            setSegmentacaoArea(
                response.data.map((segmentacao) => {
                    const fields = Object.keys(segmentacao.fields);
                    return [
                        {
                            cliente: segmentacao.cliente.nome,
                            id: segmentacao.id + "",
                            value: segmentacao.nome,
                            checked: false,
                            fields: fields.map((field) => {
                                return {
                                    id: uuidv4(),
                                    value: field,
                                    checked: false,
                                    itens: segmentacao.fields[field].map(
                                        (item) => {
                                            return {
                                                id: uuidv4(),
                                                value: item,
                                                checked: false,
                                            };
                                        }
                                    ),
                                };
                            }),
                        },
                    ];
                })
            );

            setSegmentacao(
                keys.map((key) => {
                    const filter = response.data.filter((item) => {
                        if (item.cliente.id === key) {
                            return item;
                        }
                        return false;
                    });

                    return filter.map((item) => {
                        return {
                            cliente: item.cliente.nome,
                            id: item.id + "",
                            nome: item.nome,
                            checked: false,
                        };
                    });
                })
            );
        } catch (error: any) {
            toast.error("Erro ao carregar clientes");
            console.log(error);
            return error;
        }
    }

    async function getClient() {
        try {
            const response = await api.get<Cliente[]>("/cliente?listAll=true");

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar clientes");
            return [] as Cliente[];
        }
    }

    return (
        <UseSegmentacaoContext.Provider
            value={{
                store,
                getCampanha,
                getClient,
                setModal,
                modal,
                setIds,
                ids,
                setSegmentacao,
                segmentacao,
                setSegmentacaoArea,
                segmentacaoArea,
                campanhas,
                setCampanhas,
            }}
        >
            {children}
        </UseSegmentacaoContext.Provider>
    );
}

export function useSegmentacao() {
    const context = useContext(UseSegmentacaoContext);
    return context;
}
