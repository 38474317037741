import { useEffect, useState } from "react";
import imgXVerde from "../../../../../../assets/img/x.svg";

import { FormEstorno } from "./form";
import { ModalStyles } from "./styles";
import { useContex } from "../Hook/modal";
import { Text } from "alisson-application";
import { ConfirmaDados } from "./confirmaDados";

interface Tabs {
    passo: number;
    active: boolean;
    completed: boolean;
}

export const tabs = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
];

interface ModalEstornoProps {
    handleClose: () => void;
}

export function ModalEstorno(props: ModalEstornoProps) {
    const [passos, setPassos] = useState<Tabs[]>(tabs);
    const { setFormStateEstorno } = useContex();

    const { handleClose } = props;
    function handlePassos(passo: number) {
        const newPassos = tabs.map((tab) => {
            if (tab.passo === passo) {
                tab.active = true;
                tab.completed = true;
            } else {
                tab.active = false;
            }
            return tab;
        });

        setPassos(newPassos);
    }

    useEffect(() => {
        return () => {
            handlePassos(1);
            setFormStateEstorno({} as any);
        };
    }, [setFormStateEstorno]);

    return (
        <ModalStyles>
            <div className="container-header">
                <Text as="p">ESTORNO INATIVIDADE</Text>
                <button type="button" onClick={handleClose}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>
            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    <FormEstorno handlePassos={handlePassos} />
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    <ConfirmaDados />
                </div>
            </div>
        </ModalStyles>
    );
}
