import { Permissao } from "../../../../components/System/Administracao/Permissao/listagem";
import { UserProvider } from "../../../../Hook/system/useUser";

export function PagePermissao() {
    return (
        <UserProvider>
            <Permissao />
        </UserProvider>
    );
}
