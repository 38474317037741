import { DebounceInput } from "react-debounce-input";
import { useHistory } from "react-router-dom";
import { Header as DefaultHeader } from "../../Header";
import { Button } from "../../Button";
import { Container } from "./styles";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";
import { api } from "../../../../services";
import { toast } from "react-toastify";
import { useState } from "react";
export function Header() {
    const { setSearch } = useWhiteList();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function exportar() {
        try {
            setLoading(true);
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(`whitelist-document/exportar`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            setLoading(false);
            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            setLoading(false);
            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <Container>
            <DefaultHeader
                title="white list"
                titleBtn=""
                permission="faq.cupom"
            />

            <div className="container-input-system pesquisa">
                <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
            </div>

            <div className="container-btns">
                <Button isLoading={loading} onClick={exportar}>
                    exportar
                </Button>
                <Button
                    onClick={() =>
                        history.push("/sistema/white-list/adicionar")
                    }
                >
                    Adicionar CPF
                </Button>
            </div>
        </Container>
    );
}
