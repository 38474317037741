import styled from "styled-components";

export const Container = styled.div`
    text-align: center;
    width: fit-content;
    padding: 25px 0px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 8px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    .container-background {
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${(props) => props.theme.colors.disabled};
        border-radius: 4px;
        height: 121px;
        margin: 0 auto;
        margin-bottom: 20px;
        &.preview {
            background: transparent;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;
