import { InformacaoTransferenciaStyles } from "./styles";
// import { useUser } from "../../../Hook/user/UseUser";

interface InformacaoTransferenciaProps {
    carteira: string;
    detalhes: Array<{ label: string; value: string }>;
    cardNumber: string;
}

export function InformacaoTransferencia(props: InformacaoTransferenciaProps) {
    const { carteira, detalhes, cardNumber } = props;
    return (
        <InformacaoTransferenciaStyles>
            <h3>{carteira}</h3>
            <h3>{cardNumber}</h3>
            {/* <div className="container-info">
                <p>NOME: {user.pessoa.nome_cpf}</p>
                <p>CPF: {formataCPF(user.document)}</p>
                <p className="campanha">CAMPANHA {campanha}</p>
            </div> */}

            <ul>
                {detalhes.map((detalhe, key) => (
                    <li key={key}>
                        <span>{detalhe.label}</span>
                        <span>R$ {detalhe.value}</span>
                    </li>
                ))}
            </ul>
        </InformacaoTransferenciaStyles>
    );
}
