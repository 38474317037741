import { useCallback, useEffect, useState } from "react";
import { useFaleConosco, Faq } from "../../Hook/user/UseFaleConosco";
import { UseModal } from "../../Hook/Usemodal";
import Accordion from "../Accordion";
import { DuvidasFrequenteStyles } from "./styles";
import imgLupa from "../../assets/img/lupa.svg";

export function DuvidasFrequentes() {
    const { getFaq } = useFaleConosco();
    const [faqs, setFaqs] = useState<Faq[]>([]);
    // const [loading, setLoading] = useState(false);
    const { state } = UseModal();
    const [value, setValue] = useState<string | null>(null);

    const filter = faqs?.filter((item) =>
        JSON.stringify(item, ["title", "description"])
            .toLocaleLowerCase()
            .includes(value ? value.toLocaleLowerCase() : "")
    );

    const fetchData = useCallback(
        async (page?: number, search?: string) => {
            const response = await getFaq(page, search);
            const { data } = response;
            setFaqs(data);
        },
        [getFaq]
    );

    useEffect(() => {
        if (state?.faleConosco === true) {
            fetchData();
        }
    }, [fetchData, state?.faleConosco]);

    return (
        <DuvidasFrequenteStyles>
            <h1>DÚVIDAS Frequentes</h1>

            <div className="container-input">
                <input
                    type="text"
                    placeholder="DIGITE AQUI UMA PALAVRA-CHAVE:"
                    value={value || ""}
                    onChange={async (e) => {
                        const value = e.target.value;
                        setValue(value);

                        if (filter.length === 0) {
                            await fetchData();
                        } else {
                            await fetchData(1, value.toLocaleLowerCase());
                        }
                    }}
                />
                <img src={imgLupa} alt="" />
            </div>

            {filter.map((faq) => (
                <Accordion
                    key={faq.id}
                    title={faq.title}
                    description={faq.description}
                />
            ))}
        </DuvidasFrequenteStyles>
    );
}
