import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import imgSuccess from "../../../assets/img/success-user-side.svg";
import { Container } from "./styles";

export function DesbloqueioCartao() {
    const { state, setState } = UseModal();
    const { cartao } = UseCartoes();

    function handleClick() {
        setState({
            ...state,
            acaoDesbloquearCartaoMobilePasso1: false,
            acaoDesbloquearCartaoMobilePasso2: true,
        });
    }

    return (
        <Container>
            <img src={imgSuccess} alt="" />
            <h2>DESBLOQUEAR CARTÃO</h2>

            <p>
                Cartão:{" "}
                <strong>
                    {cartao?.tipo === "cartao_fisico" ||
                    cartao?.tipo === "cartao_avulso"
                        ? cartao.acg_account_card_last4digits
                        : cartao?.acg_account_card_last4digits}
                </strong>{" "}
                <br /> Para desbloquear esse cartão clique no botão abaixo.
            </p>

            <Button disabled={!cartao?.id ? true : false} onClick={handleClick}>
                CONFIRMAR DESBLOQUEIO
            </Button>
        </Container>
    );
}
