import Page from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useDemoData } from "@mui/x-data-grid-generator";
import {
    GridColumns,
    GridRowClassNameParams,
    GridRowParams,
    GridRowsProp,
    GridState,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { useConfig } from "../../../Hook/system/useConfig";
import { translateHeaderTable } from "./config";
// import { Toolbar } from "./toolbar";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { useEffect } from "react";
import {
    AntDesignStyledDataGridPro,
    PaginationStyles,
    StyledBox,
} from "./styles";

interface TableProProps {
    columns: GridColumns;
    rows: GridRowsProp;
    onRowClick?: (value: GridRowParams) => void;
    handleState?: (value: GridState) => void;
    onCellClick?: (value: GridCellParams<any, any, any>) => void;
    nameCSV: string;
    checkboxSelection?: boolean;
}

export default function TablePro(props: TableProProps) {
    const { columns, rows, onRowClick, nameCSV } = props;
    const { setCsvNameExport } = useConfig();
    const apiRef = useGridApiRef();
    const { loading, data } = useDemoData({
        dataSet: "Commodity",
        rowLength: 100,
        editable: false,
    });

    useEffect(() => {
        setCsvNameExport(nameCSV);
    }, [setCsvNameExport, nameCSV]);

    return (
        <StyledBox className="table-container">
            <AntDesignStyledDataGridPro
                {...data}
                checkboxSelection={props.checkboxSelection}
                className={onRowClick ? "clickable" : ""}
                columns={columns}
                rows={rows}
                // components={{
                //     Toolbar,
                // }}
                getRowClassName={(params: GridRowClassNameParams) => {
                    return params.row.tipo || "";
                }}
                localeText={{
                    ...translateHeaderTable,
                }}
                loading={loading}
                autoHeight={true}
                // disableSelectionOnClick
                // isRowSelectable={(state) => {
                //     console.log(state);
                //     return state.row.status;
                // }}
                rowThreshold={0}
                apiRef={apiRef}
                onCellClick={props.onCellClick ? props.onCellClick : undefined}
                onStateChange={
                    props.handleState ? props.handleState : undefined
                }
                onSelectionModelChange={(state) => {
                    // console.log(state);
                }}
                filterMode="client"
                onRowClick={(values: GridRowParams, event) => {
                    const hasButton = (
                        event.target as Element
                    ).tagName.includes("BUTTON");

                    if (onRowClick) {
                        if (!hasButton) {
                            onRowClick(values);
                        }
                    }
                }}
            />
        </StyledBox>
    );
}

interface PaginationProps {
    total: number;
    currentPage: (page: number) => void;
}

export function Pagination(pros: PaginationProps) {
    const { total, currentPage } = pros;

    return (
        <PaginationStyles>
            <Stack spacing={2}>
                <Page
                    count={total}
                    onChange={(event, page) => currentPage(page)}
                />
            </Stack>
        </PaginationStyles>
    );
}
