import { AxiosResponse } from "axios";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { options } from "../../components/NovoSelect";
import { CampanhaFormUpdate } from "../../components/System/Campanha/Editar/config";
import { CampanhaForm } from "../../components/System/Campanha/Nova/config";
import { api } from "../../services/";
import { HistoryProps } from "../types";
import { PaginationConfig } from "./config";

interface UseCampanhaProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseCampanhaData {
    storeCampanha: (values: CampanhaForm) => Promise<AxiosResponse>;
    updateCampanha: (values: CampanhaFormUpdate) => Promise<AxiosResponse>;

    getCampanhaByid: () => Promise<any>;
    getCampanhaByIdObj: () => Promise<GetCampanhaByIdObj>;
    getCampanha: (page?: number) => Promise<DataCampanha>;
    campanhaById: Campanha;

    getClientes: () => Promise<DataCliente | undefined>;
    getAllClientes: () => Promise<Cliente[]>;
    getClientesContatos: (id: number) => Promise<ClienteArea[]>;

    updateProdutoCampanha: (segmento: UpdateProdutos[]) => Promise<void>;
    allowCampaignsBillPayment: (value: boolean) => Promise<any>;
    searchCampanha: (search: string) => Promise<ISearchCampaign>;

    loading: boolean;
    selectContato: options[];
}

const UseCampanhaContext = createContext<UseCampanhaData>(
    {} as UseCampanhaData
);

interface DataCliente {
    data: Cliente[];
}

interface PaginationConfigCampanha extends PaginationConfig {
    data: Campanha[];
}

interface DataCampanha {
    allow_bill_payment: boolean;
    campaigns: PaginationConfigCampanha;
}
interface ISearchCampaign {
    success: boolean;
    campaigns: PaginationConfigCampanha;
}

export interface Campanha {
    id: number;
    cliente_id: number;
    cliente_area_contato_id: number;
    nome: string;
    chave: string;
    regulamento: string;
    status: string;
    custom_fields: string;
    emissao: number;
    created_at: string | null;
    updated_at: string | null;
    cliente: Cliente;
    cliente_area_contato: ClienteArea;
    produtos: Produtos[];
    end_date_time: string;
    start_date_time: string;
    total_users: number;
    total_completo: number;
    total_incompleto: number;
    external_register: number;
    register_type: string;
    force_account_register: boolean;
    force_activate_account_login: boolean;
    allow_transference_between_users: boolean;
    allow_transference_between_campain: boolean;
    allow_cupom: number;
    allow_bill_payment: number;
    virtual_card_on_register: boolean;
    allow_bill_payment_available: boolean;
}

export interface Cliente {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    created_at: string | null;
    updated_at: string | null;
    areas: Area[];
}

interface Area {
    id: number;
    cliente_id: number;
    nome: string;
    created_at: string | null;
    updated_at: string | null;
}

export interface Produtos {
    id: number;
    campanha_id: number;
    produto_id: number;
    created_at: string | null;
    updated_at: string | null;
    produto: Produto;
}
export interface Produto {
    id: number;
    acg_produto_id: string;
    acg_comercial_origin_id: string;
    nome: string;
    descricao: string;
    tipo: string;
    lim_quantidade: number;
    lim_saque: string;
    lim_compras: string;
    taxa_cobranca: string;
    created_at: string | null;
    updated_at: string | null;
}

export interface ClienteArea {
    id: number;
    cliente_area_id: number;
    nome: string;
    telefone: string;
    celular: string;
    email: string;
    created_at: string | null;
    updated_at: string | null;
    area: Area;
}

interface UpdateProdutos {
    produto_id: number;
    taxa_cobranca: string | number | null;
    lim_quantidade: string | number | null;
}

interface GetCampanhaByIdObj {
    contatos: options[];
    clientes: options[];
    data: Campanha;
}

export function CampanhaProvider(props: UseCampanhaProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const [campanhaById, setCampanhaById] = useState<Campanha>({} as Campanha);
    const [loading, setLoading] = useState(false);
    const [selectContato, setSelectContato] = useState<options[]>([]);

    async function getCampanha(page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const response = await api.get<DataCampanha>(`campanha${hasPage}`);

            return response.data;
        } catch (error: any) {
            // toast.error("Erro ao carregar campanhas");
            return {} as DataCampanha;
        }
    }

    async function allowCampaignsBillPayment(value: boolean) {
        try {
            const response = await api.patch(
                "campanha/allow-campaigns-bill-payment",
                {
                    value,
                }
            );

            toast.success("Boleto habilitado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao habilitar boleto");
            return error;
        }
    }

    async function getClientes() {
        try {
            const { data } = await api.get<DataCliente>("/cliente");

            return data;
        } catch (error) {
            toast.error("Erro ao buscar clientes");
        }
    }

    async function getAllClientes() {
        try {
            const { data } = await api.get<Cliente[]>("/cliente?listAll=true");

            return data;
        } catch (error) {
            toast.error("Erro ao buscar clientes");
            return [] as Cliente[];
        }
    }

    async function getClientesContatos(id: number) {
        try {
            const { data } = await api.get<ClienteArea[]>(
                `cliente/${id}/contato`
            );

            return data;
        } catch (error: any) {
            toast.error("Erro ao buscar clientes");
            return error;
        }
    }

    async function storeCampanha(values: CampanhaForm) {
        try {
            const response = await api.post<CampanhaForm>("/campanha", {
                ...values,
            });

            toast.success("Campanha criada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao criar campanha");
            return error;
        }
    }

    async function updateCampanha(values: CampanhaFormUpdate) {
        try {
            const response = await api.post<CampanhaFormUpdate>(
                `campanha/${id}`,
                {
                    ...values,
                }
            );

            toast.success("Dados Atualizados com Sucesso!");
            return response;
        } catch (error: any) {
            toast.error("Erro ao criar campanha");
            return error;
        }
    }

    async function updateProdutoCampanha(values: UpdateProdutos[]) {
        try {
            setLoading(true);
            await api.post(`campanha/${id}`, {
                produtos: [...values],
            });
            toast.success("Dados Atualizados com Sucesso!");
            await getCampanhaByid();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao atualizar dados");
        }
    }

    const getCampanhaByid = useCallback(async () => {
        try {
            const { data } = await api.get<Campanha>(`campanha/${id}`);

            const responseContato = await api.get<ClienteArea[]>(
                `cliente/${data.cliente_id}/contato`
            );

            setSelectContato(
                responseContato.data.map((item) => {
                    return {
                        label: item.nome,
                        value: item.id,
                    };
                })
            );
            setCampanhaById(data);
        } catch (error: any) {
            console.log(error);
            // toast.error("Erro ao carregar campanhas");
            setCampanhaById({} as Campanha);
            return error;
        }
    }, [id]);

    async function getCampanhaByIdObj() {
        try {
            const { data } = await api.get<Campanha>(`campanha/${id}`);

            const { data: contato } = await api.get<ClienteArea[]>(
                `cliente/${data.cliente_id}/contato`
            );

            const response = await getClientes();

            return {
                contatos: contato.map((item) => {
                    return {
                        label: item.nome,
                        value: item.id,
                    };
                }),
                clientes: response?.data?.map((cliente: Cliente) => ({
                    label: `${cliente.nome} - ${cliente.cnpj}`,
                    value: cliente.id,
                })),
                data,
            } as GetCampanhaByIdObj;
        } catch (error: any) {
            console.log(error);
            // toast.error("Erro ao carregar campanhas");

            return {} as GetCampanhaByIdObj;
        }
    }

    async function searchCampanha(search: string) {
        try {
            const { data } = await api.get<ISearchCampaign>(
                `campanha/search?search=${search}`
            );

            return data;
        } catch (error: any) {
            toast.error("Erro na pesquisa por campanhas!");

            return { success: false } as ISearchCampaign;
        }
    }

    useEffect(() => {
        const campanha = history.location.pathname
            .split("/")
            .some((item) => item.includes("carga"));

        if (campanha) {
            getCampanhaByid();
        }
    }, [getCampanhaByid, history]);

    return (
        <UseCampanhaContext.Provider
            value={{
                loading,
                selectContato,
                getCampanhaByid,
                getCampanhaByIdObj,
                getCampanha,
                campanhaById,
                getClientes,
                getAllClientes,
                getClientesContatos,
                storeCampanha,
                updateCampanha,
                updateProdutoCampanha,
                allowCampaignsBillPayment,
                searchCampanha,
            }}
        >
            {children}
        </UseCampanhaContext.Provider>
    );
}

export function useCampanha() {
    const context = useContext(UseCampanhaContext);

    return context;
}
