import { LineTextBoleto } from "../LineTextBoleto";
import { Container } from "./styles";

interface DataBoletosProps {
    dtVencimento: string;
    dtPagamento: string;
}

export function DataBoleto(props: DataBoletosProps) {
    const { dtVencimento, dtPagamento } = props;

    return (
        <Container>
            <LineTextBoleto title="Data de Vencimento:" text={dtVencimento} />
            <LineTextBoleto title="Data de Pagamento:" text={dtPagamento} />
        </Container>
    );
}
