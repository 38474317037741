import * as Yup from "yup";
export const isValidBRZipRegex = /^[0-9]{5}-[0-9]{3}$/;

export interface IEndereco {
    cep: string;
    nome: string;
    numero: string;
    bairro: string;
    cidade: string;
    estado: string;
    pais: string;
    complemento: string;
}

export const endereco: IEndereco = {
    cep: "",
    nome: "rua",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    complemento: "",
    pais: "Brasil",
};

export const validationEndereco = Yup.object().shape({
    cep: Yup.string()
        .matches(isValidBRZipRegex, "Digite um CEP valido")
        .required("Campo obrigatório"),
    nome: Yup.string().required("Campo obrigatório"),
    numero: Yup.string().required("Campo obrigatório"),
    bairro: Yup.string().required("Campo obrigatório"),
    cidade: Yup.string().required("Campo obrigatório"),
    estado: Yup.string().required("Campo obrigatório"),
    pais: Yup.string().required("Campo obrigatório"),
    complemento: Yup.string(),
});
