import axios, { AxiosRequestConfig } from "axios";
import { getToken, TokenType } from "../util/helper";
const baseUrl = process.env.REACT_APP_BASE_HTTP;
const baseUrlSistema = process.env.REACT_APP_BASE_HTTP_SISTEMA;

const hasSistema = window.location.href.indexOf("sistema") > 0;

export const api = axios.create({
    baseURL: hasSistema ? baseUrlSistema : baseUrl,
});

export const api_without_interceptors = axios.create({
    baseURL: hasSistema ? baseUrlSistema : baseUrl,
});

export const setToken = (type: TokenType) => {
    api.interceptors.request.use(function (config: AxiosRequestConfig) {
        if (config.headers) {
            config.headers.authorization = `Bearer ${getToken(type)}`;
        }

        return config;
    });
};
