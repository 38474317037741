import { useHistory } from "react-router-dom";
import { Header as DefaultHeader } from "../../Header";
import { Button } from "../../Button";
import { StylesHeader } from "./styles";
import { formatCurrentMoney, formatDate } from "../../../../util/helper";
import imgRelatorio from "../../../../assets/img/menu/relatorio.svg";

export interface IHeader {
    scn: number;
    carteira: number;
    no_name_ativar: number;
    r: number;
    c: number;
    created_at: string;
    start: string;
    end: string;
}

export function Header(props: IHeader) {
    const history = useHistory();

    return (
        <StylesHeader>
            <div className="container-header">
                <DefaultHeader
                    title="RELATÓRIO"
                    titleBtn=""
                    image={imgRelatorio}
                    permission="faq.cupom"
                />
            </div>

            <div className="container-relatorio">
                <div className="item">
                    <span>SOMA CRÉDITO NOVO:</span>
                    <p>R$ {formatCurrentMoney(props.scn)}</p>
                </div>
                <div className="item">
                    <span>carteira:</span>
                    <p>R$ {formatCurrentMoney(props.carteira)}</p>
                </div>
                <div className="item">
                    <span>Noname ativar:</span>
                    <p>R$ {formatCurrentMoney(props.no_name_ativar)}</p>
                </div>
                <div className="item">
                    <span>resgates efetuados:</span>
                    <p>R$ {formatCurrentMoney(props.r)}</p>
                </div>
                <div className="item">
                    <span>Período:</span>
                    <p>
                        {formatDate(props.start)} - {formatDate(props.end)}
                    </p>
                </div>

                <Button
                    onClick={() => {
                        history.push("/sistema/relatorio/novo");
                    }}
                >
                    GERAR RELATÓRIO
                </Button>
            </div>
        </StylesHeader>
    );
}
