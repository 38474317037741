import styled from "styled-components";

export const Container = styled.div`
    padding-top: 77px;
    padding-bottom: 64px;
    img {
        &.img-close {
            position: absolute;
            top: -87px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
            z-index: 99;
        }
    }

    .cancelar {
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        margin-top: 12px;
    }
`;
