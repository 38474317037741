import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Variant } from "./types";

interface UsePassosProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UsePassosData {
    passos: ArrPassos[];
    setPassos: (passos: ArrPassos[]) => void;

    currentPasso: number;

    nextPasso: (passo: number) => void;
    prevPassos: (passo: number) => void;

    setDados: (dados: any) => void;
    dados: any;

    codeType: Variant;
    setCodeType: (value: Variant) => void;
}

export interface ArrPassos {
    passo: number;
    active: boolean;
    completed: boolean;
    goBack?: boolean;
}

const UsePassosContext = createContext<UsePassosData>({} as UsePassosData);

export function UsePassisProvider(props: UsePassosProviderProps) {
    const { children } = props;
    const [currentPasso, setCurrentPasso] = useState<number>(1);
    const [passos, setPassos] = useState([] as ArrPassos[]);
    const [dados, setDados] = useState({});
    const [codeType, setCodeType] = useState<Variant>("whatsapp");
    const history = useHistory();

    function nextPasso(passo: number) {
        const newPassos = passos.map((item) => {
            if (item.passo === passo) {
                return {
                    ...item,
                    active: true,
                    completed: true,
                };
            }

            return {
                ...item,
                active: false,
            };
        });
        const newCurrentPasso = [...newPassos];

        setPassos(newPassos);

        const currentPasso = newCurrentPasso
            .reverse()
            .find((item) => item.active)?.passo;

        if (currentPasso) {
            setCurrentPasso(currentPasso);
        }
    }

    function prevPassos(passo: number) {
        if (passos[currentPasso - 1]?.goBack) {
            history.goBack();
            return;
        }

        const newPassos = passos.map((item) => {
            if (item.passo + 1 === passo) {
                return {
                    ...item,
                    active: true,
                    completed: true,
                };
            }

            if (item.passo === passo) {
                return {
                    ...item,
                    active: false,
                    completed: false,
                };
            }

            return item;
        });

        const newCurrentPasso = [...newPassos];

        const currentAtualPasso = newCurrentPasso
            .reverse()
            .find((item) => item.active)?.passo;

        if (currentAtualPasso) {
            setCurrentPasso(currentAtualPasso);
        }

        setPassos(newPassos);
    }

    return (
        <UsePassosContext.Provider
            value={{
                setPassos,
                passos,
                currentPasso,
                nextPasso,
                prevPassos,
                setDados,
                dados,

                codeType,
                setCodeType,
            }}
        >
            {children}
        </UsePassosContext.Provider>
    );
}

export function usePassos() {
    const context = useContext(UsePassosContext);

    return context;
}
