import { useState } from "react";

import ChevronDown from "../../assets/img/ChevronDown";
import ChevronUp from "../../assets/img/ChevronUp";

import {
    ChildrenContent,
    CollapseButton,
    CollapseContent,
    CollapseItem,
    CollapseText,
} from "./styles";

interface ICollapse {
    title: string;
    description: string;
    subitem?: boolean;
    icon: React.ReactNode;
    children: React.ReactNode;
    theme?: "primary" | "secondary";
}

const Collapse = ({
    children,
    subitem,
    title,
    description,
    icon,
    theme = "primary",
}: ICollapse) => {
    const [showContent, setShowContent] = useState(false);
    const [willclose, setWillClose] = useState(false);

    function handleToggleCollapseVisibility() {
        if (!showContent) return setShowContent(true);
        setWillClose(true);

        setTimeout(() => {
            setShowContent(false);
            setWillClose(false);
        }, 380);
    }

    return (
        <CollapseItem>
            <CollapseButton
                onClick={handleToggleCollapseVisibility}
                isopen={showContent}
                subitem={subitem}
                $theme={theme}
            >
                <CollapseContent>
                    {icon}

                    {subitem ? (
                        <CollapseText subitem={subitem}>
                            <p>{title}</p>
                            <h2>{description}</h2>
                        </CollapseText>
                    ) : (
                        <CollapseText subitem={subitem}>
                            <h2>{title}</h2>
                            <p>{description}</p>
                        </CollapseText>
                    )}
                </CollapseContent>

                {showContent ? <ChevronUp /> : <ChevronDown />}
            </CollapseButton>

            {showContent && (
                <ChildrenContent willclose={willclose || undefined}>
                    {children}
                </ChildrenContent>
            )}
        </CollapseItem>
    );
};

export default Collapse;
