import styled from "styled-components";
import imgUser from "../../../assets/img/user.svg";

interface ContainerStyles {
    img?: string;
}

export const Container = styled.div<ContainerStyles>`
    background: ${(props) => props.theme.colors.background};
    border-radius: 8px;
    height: 155px;
    width: 698px;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-user {
        background: #acafb1;
        width: 38.77px;
        height: 38.77px;
        border-radius: 50%;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: ${(props) => props.theme.colors.white};
            -webkit-mask: url(${(props) => (props.img ? props.img : imgUser)})
                no-repeat;
            width: 20px;
            height: 20px;
            z-index: 20;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primaryDark};
        margin-left: 20px;
    }
`;
