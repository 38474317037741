import ModalBottom from "../../ModalBottom";
import { BloquearCartao } from "../../Mobile/BloquearCartao";
import { DesbloqueioCartao } from "../../Mobile/DesbloquearCartao";
import { ConfirmaDesbloqueioCartao } from "../../Mobile/DesbloquearCartao/confirmar";
import { ConfirmaToggleSms } from "../../Mobile/DetalheMovimentacao/confirmaToggleSms";
import { ModalMovimentacao } from "../../Mobile/DetalheMovimentacao/modal";
import { UseModal } from "../../../Hook/Usemodal";

export function ModaisMenu() {
    const { state, handleClose } = UseModal();
    return (
        <>
            {state?.acoesCartaoMobile ? (
                <ModalBottom
                    open={state?.acoesCartaoMobile}
                    handleClose={() => handleClose("acoesCartaoMobile")}
                >
                    <ModalMovimentacao />
                </ModalBottom>
            ) : (
                <></>
            )}

            {state?.acaoBloquearCartao ? (
                <ModalBottom
                    open={state?.acaoBloquearCartao}
                    handleClose={() => handleClose("acaoBloquearCartao")}
                >
                    <BloquearCartao />
                </ModalBottom>
            ) : (
                <> </>
            )}

            {state?.acaoDesbloquearCartaoMobilePasso1 ? (
                <ModalBottom
                    open={state?.acaoDesbloquearCartaoMobilePasso1}
                    handleClose={() =>
                        handleClose("acaoDesbloquearCartaoMobilePasso1")
                    }
                >
                    <DesbloqueioCartao />
                </ModalBottom>
            ) : (
                <></>
            )}

            {state?.acaoDesbloquearCartaoMobilePasso2 ? (
                <ModalBottom
                    open={state?.acaoDesbloquearCartaoMobilePasso2}
                    handleClose={() =>
                        handleClose("acaoDesbloquearCartaoMobilePasso2")
                    }
                >
                    <ConfirmaDesbloqueioCartao />
                </ModalBottom>
            ) : (
                <></>
            )}

            {state.confirmaToggleSms ? (
                <ModalBottom
                    open={state.confirmaToggleSms}
                    handleClose={() => handleClose("confirmaToggleSms")}
                >
                    <ConfirmaToggleSms />
                </ModalBottom>
            ) : (
                <></>
            )}
        </>
    );
}
