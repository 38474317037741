import { BackofficeProvider } from "../../../Hook/system/useBackoffice";
import { Backoffice } from "../../../components/System/Backoffice";

export function PageBackOffice() {
    return (
        <BackofficeProvider>
            <Backoffice />
        </BackofficeProvider>
    );
}
