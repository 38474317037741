import { EditarWhiteList } from "../../../../components/System/White-list/editar";
import { WhiteListProvider } from "../../../../Hook/system/useWhiteList";

export function PageEditarWhiteList() {
    return (
        <WhiteListProvider>
            <EditarWhiteList />
        </WhiteListProvider>
    );
}
