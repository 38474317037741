import styled from "styled-components";

export const PreviewSms = styled.div`
    /* width: 384px; */
    .container-preview-sms {
        width: 100%;
    }

    .content-preview {
        background: #f2f2f2;
        border-radius: 16px;
        padding: 29px 37px 48px 38px;
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 23px;
            color: ${(props) => props.theme.colors.Cinza01};
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`;
