import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { Entrega } from "../../Entrega";

export function ModalEntrega(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <Entrega />
        </ModalBottom>
    );
}
