import styled from "styled-components";

export const Container = styled.div`
    background-color: #f4f4f4;
    border-radius: 8px;
    padding-top: 24px;
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 44px;
    .container-img {
        display: flex;
        justify-content: center;
        padding-bottom: 22px;
    }

    .container-descricao {
        text-align: center;
        margin-bottom: 17px;

        h2{
            font-size: 20px;
            font-weight: 700;
            color: ${({ theme }) => theme.colors.secondary};
        }

        p {
            margin-top:15px;
            margin-bottom:40px;
            font-size: 14px;
            color: ${({ theme }) => theme.colors.primaryDark};
        }
    }

    .container-inputs {
        display: flex;
        justify-content: center;

        input {
            padding-left: unset !important;
            width: 67px !important;
            height: 67px !important;
            text-align: center !important;
        }
    }
`;
