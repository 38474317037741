export const WhatsAppIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="whatsapp"
    >
      <path
        d="M14.6687 12.01C14.4678 11.9101 13.1617 11.3109 12.9607 11.211C12.7598 11.1111 12.5588 11.1111 12.3579 11.3109C12.157 11.5106 11.7551 12.1099 11.5541 12.3096C11.4537 12.5094 11.2527 12.5094 11.0518 12.4095C10.3485 12.1099 9.6452 11.7104 9.04237 11.211C8.54002 10.7116 8.03766 10.1124 7.63578 9.51311C7.53531 9.31336 7.63578 9.11361 7.73625 9.01373C7.83672 8.91386 7.93719 8.71411 8.13813 8.61423C8.2386 8.51436 8.33908 8.31461 8.33908 8.21473C8.43955 8.11486 8.43955 7.91511 8.33908 7.81523C8.2386 7.71536 7.73625 6.51685 7.53531 6.01748C7.43484 5.31835 7.2339 5.31835 7.03295 5.31835H6.5306C6.32966 5.31835 6.02825 5.5181 5.92778 5.61798C5.32495 6.21723 5.02354 6.91635 5.02354 7.71536C5.12401 8.61423 5.42542 9.51311 6.02825 10.3121C7.13343 11.9101 8.54002 13.2085 10.248 14.0075C10.7504 14.2072 11.1523 14.407 11.6546 14.5069C12.157 14.7066 12.6593 14.7066 13.2621 14.6067C13.9654 14.5069 14.5683 14.0075 14.9701 13.4082C15.1711 13.0087 15.1711 12.6092 15.0706 12.2097L14.6687 12.01ZM17.1805 2.92135C13.2621 -0.973783 6.93248 -0.973783 3.01412 2.92135C-0.200942 6.11735 -0.803766 11.0112 1.40659 14.9064L0 20L5.32495 18.6017C6.83201 19.4007 8.43955 19.8002 10.0471 19.8002C15.573 19.8002 19.9937 15.4057 19.9937 9.91261C20.0942 7.31585 18.989 4.81898 17.1805 2.92135ZM14.4678 16.9039C13.1617 17.7029 11.6546 18.2022 10.0471 18.2022C8.54002 18.2022 7.13343 17.8027 5.82731 17.1036L5.52589 16.9039L2.4113 17.7029L3.21507 14.7066L3.01412 14.407C0.602825 10.412 1.80847 5.41823 5.72683 2.92135C9.6452 0.424469 14.6687 1.72285 17.08 5.5181C19.4913 9.41323 18.3862 14.5069 14.4678 16.9039Z"
        fill="#57B847"
      />
    </svg>
  );
};
