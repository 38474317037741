import { Container } from "./styles";
import imgTime from "../../../assets/img/time.svg";

export function Processando() {
    return (
        <Container>
            <div className="card-status">
                <img src={imgTime} alt="" />
                <span className="solicitado">
                    ATIVANDO <br />
                    CARTÃO
                </span>

                <small>Aguarde...</small>
            </div>
        </Container>
    );
}
