import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { NovoHeader } from "./styles";

import imgSetaEsq from "../../../assets/img/seta-esq-sistema.svg";

interface PropsNovoCliente {
    title: string;
    ml?: string;
    onClick?: () => void;
}
export function HeaderNovo(props: PropsNovoCliente) {
    const { title, ml, onClick } = props;

    const history = useHistory();

    return (
        <NovoHeader>
            <div className={`container-header ${ml ? "ml" : ""}`}>
                <button
                    onClick={() => {
                        if (onClick) {
                            onClick();
                        } else {
                            history.goBack();
                        }
                    }}
                >
                    <img src={imgSetaEsq} alt="" />
                    VOLTAR
                </button>

                <div className="title cliente">
                    <Text textTransform="uppercase">{title}</Text>
                </div>
            </div>
        </NovoHeader>
    );
}
