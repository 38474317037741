import { Text, Box } from "alisson-application";
export function FooterLogin() {
    const width = window.screen.width;
    return (
        <Box background="#fff" padding="5px 0px" width="100%">
            <Text
                color="secondary"
                width="90%"
                m="0 auto"
                textAlign="center"
                fontSize="xss"
            >
                {width <= 480
                    ? `YETZ LTDA | CNPJ: 28.325.166/0001-05 | 2022 | Todos
                    os direitos reservados`
                    : `Este site é melhor visualizado em navegadores com suporte às
                    especificações HTML5 e CSS3 (Google Chrome, Mozilla Firefox,
                    Microsoft Edge, Safari entre outros). Recomendamos que você
                    utilize a versão mais atual destes navegadores, pois alguns
                    recursos podem não ser exibidos corretamente caso não esteja
                    atualizado.`}
            </Text>
        </Box>
    );
}
