
export class ExpiresIn {

    hours:number = 3
    delay:number = this.hours*60*60

    read(): Date | null {
        const expiresInStr = localStorage.getItem('expiresIn');
        return expiresInStr ? new Date(expiresInStr) : null;
    }

    save(seconds: number): void {
        const expiresIn = new Date();
        expiresIn.setSeconds(expiresIn.getSeconds() + seconds);
        localStorage.setItem('expiresIn', expiresIn.toString());
    }

    checkTooLongTime(): boolean {

        const expiresInStr = localStorage.getItem('expiresIn');
        if (!expiresInStr) {
            return false;
        }

        const expiresInPlusDelay = new Date(expiresInStr);
        expiresInPlusDelay.setSeconds(expiresInPlusDelay.getSeconds() + this.delay);

        const currentDate = new Date();

        return currentDate > expiresInPlusDelay;
    }

}
