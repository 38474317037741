import { Text } from "alisson-application";
import { WhatsAppStyles } from "./styles";

interface WhatsAppProps {
    src?: string | null;
}

export function WhatsApp(props: WhatsAppProps) {
    return (
        <WhatsAppStyles>
            <div className="container-img-template">
                <img src={props.src ? props.src : ""} alt="" />
            </div>

            <div className="content-template">
                <Text as="p"> Olá {`{{ nome }}`}!</Text>
                <Text as="p">Seu voucher digital está disponível.</Text>
                <Text as="p">Clique no botão abaixo.</Text>
            </div>
        </WhatsAppStyles>
    );
}
