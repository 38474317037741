import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 23px;
    border-radius: 10px;
    background: #f3f3f3;
    padding: 15px 0px;
`;
