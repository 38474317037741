import styled from "styled-components";

export const Container = styled.div`
    padding: 0 5%;
    text-align: center;
    padding-bottom: 51px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        border-bottom: 1px solid #e1e2e3;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-text {
        h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 31px;
            strong {
                &.reprovar {
                    color: ${(props) => props.theme.colors.failure};
                }
                color: ${(props) => props.theme.colors.secondary};
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 63px;
        }

        .btns {
            button {
                &.reprovar {
                    background: #ffe3e6;
                    color: ${(props) => props.theme.colors.failure};
                }
                &:first-child {
                    margin: 0 auto;
                    width: 234px;
                    height: 60px;
                    margin-bottom: 35px;
                }

                &:last-child {
                    color: ${(props) => props.theme.colors.secondary};
                }
            }
        }
    }
`;
