import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";

import imgdownload from "../../assets/img/menu/download.svg";
import imgShared from "../../assets/img/shared.svg";
import { UseModal } from "../../Hook/Usemodal";
import { usePassos } from "../../Hook/UsePassos";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { useUser } from "../../Hook/user/UseUser";
import { formatCurrentMoney, formatDate, formataCPF } from "../../util/helper";
import { CardConfirmacao, ItemConfirmacao } from "../CardConfirmacao";
import { ContainerValor } from "../ContainerValor";
import { DataBoleto } from "../DataBoleto";
import { Button } from "../Mobile/ButtonSlim";
import { ButtonGoBack } from "../Mobile/ButtonGoBack";

import { Container } from "./styles";

export function ComprovantePagamento() {
    const { dados } = usePassos();
    const { user } = useUser();
    const { cartao } = UseCartoes();
    const { handleClose } = UseModal();
    const hasFisictype = cartao?.tipo === "cartao_fisico" ? true : false;
    let cardNumber = cartao?.acg_account_card_last4digits || "";

    if (!hasFisictype && cartao?.acg_account_card_last4digits) {
        const formatCardNumber = cartao?.acg_account_card_last4digits;
        cardNumber = formatCardNumber.substring(formatCardNumber.length - 4);
    } else {
        cardNumber =
            cartao?.acg_account_card_last4digits.substring(
                cartao?.acg_account_card_last4digits.length - 4
            ) || "";
    }

    const history = useHistory();
    const width = window.screen.width;
    const dtPagamento = new Date()
        .toLocaleString()
        .split(",")[0]
        .replace(/\//g, ".");

    const linhaDigitavel = dados?.boleto?.digitable || "";

    const beneficiario = [
        {
            title: "Nome Fantasia: ",
            text: dados.boleto?.assignor || "",
        },
        {
            title: "Razão Social:",
            text: dados.boleto?.assignor || "",
        },
        {
            title: "CNPJ:",
            text: dados.boleto?.documentRecipient || "",
        },
    ];

    async function share(
        shareurl: string,
        sharetitle: string,
        sharetext: string
    ) {
        const file = dados.boleto?.file as File;

        if (width >= 1024) {
            const extension = file.type.split("/")[1];
            const outputFilename = `${Date.now()}.${extension}`;
            var data = new Blob([file]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();
            return;
        }

        if ("canShare" in navigator) {
            try {
                await navigator.share({
                    url: shareurl,
                    title: sharetitle,
                    text: sharetext,
                    files: [file],
                });
            } catch (err: any) {
                console.log(err);
            }
        }
    }

    return (
        <Container>
            <h4>Comprovante de pagamento</h4>

            <ContainerValor
                value={`${formatCurrentMoney(dados.boleto?.value || 0)}`}
            />

            <div className="container-informacoes">
                <div className="item">
                    <ItemConfirmacao
                        title="Nome do Pagador:"
                        text={user.name}
                    />
                </div>
                <div className="item">
                    <ItemConfirmacao
                        title="CPF:"
                        text={formataCPF(user.document)}
                    />
                </div>
                <div className="item">
                    <ItemConfirmacao
                        title="Cartão utilizado:"
                        text={cardNumber || ""}
                    />
                </div>
            </div>

            <Text as="p" color="primaryDark" fontSize="xs" mb="24px">
                <Text as="small" color="secondary" bold fontSize="xs">
                    Descrição:
                </Text>{" "}
                {dados.boleto?.customerDescription || ""}
            </Text>

            <CardConfirmacao title="Beneficiário:" itens={beneficiario} />

            <ItemConfirmacao
                title="Emissor:"
                text={dados.boleto?.assignor || ""}
            />

            <DataBoleto
                dtVencimento={formatDate(dados.boleto?.dueDate || "")}
                dtPagamento={dtPagamento}
            />

            <div className="container-informacoes">
                <div className="item">
                    <ItemConfirmacao
                        title="Linha digitável:"
                        text={linhaDigitavel.replace(
                            /([0-9]{5})([0-9]{5})([0-9]{5})([0-9]{6})([0-9]{5})([0-9]{6})([0-9]{1})/,
                            "$1.$2 $3.$4 $5.$6 $7 "
                        )}
                    />
                </div>
            </div>
            <div className="buttonContainer">
                <Button
                    onClick={async () =>
                        await share(
                            "https://yetzpay.com.br/",
                            "Comprovante de pagamento",
                            "Comprovante de pagamento"
                        )
                    }
                >
                    {width >= 1024 ? (
                        <>
                            <img src={imgdownload} alt="" /> &nbsp; Baixar
                            comprovante
                        </>
                    ) : (
                        <>
                            <img src={imgShared} alt="" /> &nbsp; compartilhar
                        </>
                    )}
                </Button>
                <ButtonGoBack
                    onClick={() => {
                        history.push("/dashboard");
                        handleClose("boleto");
                    }}
                >
                    Voltar ao início
                </ButtonGoBack>
            </div>
            
        </Container>
    );
}
