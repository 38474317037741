import { useEffect } from "react";

import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { BemVindo } from "./bemVindo";
import { ValidarEmail } from "./validarEmail";
import { Obrigado } from "./obrigado";
import { Container } from "./styles";

const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
];

interface BemVindoProps {
    setActive: (active: boolean) => void;
}

export function BemVindoModal(props: BemVindoProps) {
    const { setPassos, passos } = usePassos();
    const { user } = useUser();
    const { setActive } = props;

    useEffect(() => {
        if (!user.first_login) {
            setPassos(arrPassos);
        }
    }, [setPassos, user.first_login]);

    return (
        <Container>
            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    <BemVindo />
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    <ValidarEmail />
                </div>
                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    <Obrigado onClick={() => setActive(false)} />
                </div>
            </div>

            <div className="passos">
                {passos?.map((item, key) => (
                    <span
                        key={key}
                        className={`${item.completed ? "active" : ""}`}
                    ></span>
                ))}
            </div>
        </Container>
    );
}
