/* eslint-disable react-hooks/exhaustive-deps */
import { memo } from "react";
import { HeaderStyles } from "./styles";
import { useConfig } from "../../../Hook/system/useConfig";

function Header() {
    const { tabsCampanha, setTabsCampanha } = useConfig();

    function handleTab(id: number) {
        const newTabs = tabsCampanha.map((item) => {
            if (item.id === id) {
                item.active = true;
                item.viewer = true;
            } else {
                item.active = false;
                item.viewer = false;
            }

            return item;
        });

        setTabsCampanha(newTabs);
    }

    return (
        <HeaderStyles>
            <ul>
                {tabsCampanha
                    .filter((item) => item.permission)
                    .map((tab) => {
                        return (
                            <li key={tab.id}>
                                <button
                                    className={`${tab.className} ${
                                        tab.active ? "active" : ""
                                    }`}
                                    onClick={() => handleTab(tab.id)}
                                >
                                    {tab.title}
                                </button>
                            </li>
                        );
                    })}
            </ul>
        </HeaderStyles>
    );
}

export const HeaderMemorized = memo(Header);
