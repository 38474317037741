import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalStyles = styled.div`
    padding-top: 83px;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        width: 288px;
        margin: 0 auto 90px;
    }

    button {
        width: 90%;
        margin: 0 auto 106px;
    }
`;
