import { ConfirmaDadosStyles, Divisor } from "./styles";
import { InformacaoTransferencia } from "./informacaoTransferencia";
import { usePassos } from "../../../Hook/UsePassos";
import {
    formatCurrentMoney,
    tempoInicioPlataformaExtendido,
    tempoFimPlataformaExtendido,
} from "../../../util/helper";
import { Button } from "../ButtonSlim";
import { Box } from "alisson-application";
export function ConfirmaDados() {
    const { dados, nextPasso } = usePassos();
    const value = parseFloat(dados?.value || "");

    const detalhesOrigemMock = [
        {
            label: "SALDO ATUAL",
            value: dados?.transferirCardActive?.saldo
                ? formatCurrentMoney(dados?.transferirCardActive?.saldo)
                : "",
        },
        {
            label: "VALOR TRANSFERÊNCIA",
            value: dados?.value ? formatCurrentMoney(value) : "",
        },
        {
            label: "SALDO FINAL",
            value:
                dados?.subTotal >= 0 ? formatCurrentMoney(dados?.subTotal) : "",
        },
    ];

    const detalhesDestinoMock = [
        {
            label: "SALDO ATUAL",
            value: dados?.transferirCurrentCard?.saldo
                ? formatCurrentMoney(dados?.transferirCurrentCard?.saldo)
                : "",
        },
        {
            label: "VALOR TRANSFERÊNCIA",
            value: dados?.value ? formatCurrentMoney(value) : "",
        },
        {
            label: "SALDO FINAL",
            value:
                dados?.transferirCurrentCard?.saldo >= 0 && value
                    ? formatCurrentMoney(
                          parseFloat(dados?.transferirCurrentCard?.saldo) +
                              value
                      )
                    : "",
        },
    ];

    return (
        <ConfirmaDadosStyles>
            <h4>Confira abaixo os dados da transferência. </h4>

            <InformacaoTransferencia
                detalhes={detalhesOrigemMock}
                carteira="CARTÃO ORIGEM"
                cardNumber={dados?.transferirCardActive?.number}
            />

            <Divisor />

            <InformacaoTransferencia
                detalhes={detalhesDestinoMock}
                carteira="CARTÃO  DESTINO"
                cardNumber={dados?.transferirCurrentCard?.number}
            />

            <Box mt="16px" mb="56px">
                <div className="container-alerta">
                    <h3>Atenção!</h3>
                    <p>
                        <strong>Transferência entre Cartões:</strong> até R$ 1.500,00
                        <br />
                        por semana, em dias úteis, das {tempoInicioPlataformaExtendido}h
                        às {tempoFimPlataformaExtendido}h.
                    </p>
                </div>
            </Box>

            <Button mb="32px" mt="47px" onClick={() => nextPasso(3)}>
                Confirmar Transferência
            </Button>
        </ConfirmaDadosStyles>
    );
}
