import { BarChart, Bar, XAxis, Tooltip, ReferenceLine } from "recharts";
import { useTranslation } from "../../../contexts/Localization";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useTheme } from "../../../contexts/ThemeContext";
import { formatDate, formatCurrentMoney } from "../../../util/helper";
import { GraficoStyles } from "./styles";

export function GraficoPositiveNegative() {
    const { currentTheme } = useTheme();
    const { extratos } = UseCartoes();
    const { t } = useTranslation();

    const width = window.screen.width;
    const currentWidth = width >= 1024 ? 812 : (90 / 100) * width;

    const arrDias = extratos?.map((item) => {
        return formatDate(item.eventDate).split("/")[0];
    });

    const keysExtrato = extratos
        ?.map((item) => item.cid)
        ?.reduce((acc, item) => {
            acc[item] = acc[item] + 1 || 1;

            return acc;
        }, {});

    const keysIdUnique = Object.keys(keysExtrato).filter(
        (item) => keysExtrato[item] === 1
    );

    const uniqueKeys = [...keysIdUnique.map((item) => item)];
    const uniqueExtratos = uniqueKeys.map((item) => {
        return extratos.find(
            (extrato) => extrato.cid === item && !extrato.reversed
        );
    });

    const dias = [...new Set(arrDias)].reverse();

    const dados = dias.map((dia) => {
        return {
            name: dia.substring(0, 2),
            [`${t("Saída")}`]: uniqueExtratos?.reduce((acc, item) => {
                if (
                    item?.isCredit === false &&
                    formatDate(item.eventDate).split("/")[0] === dia
                ) {
                    acc = acc + item.principalAmount * -1;
                }
                return parseFloat(acc.toFixed(2));
            }, 0),
            [`${t("Entrada")}`]: uniqueExtratos?.reduce((acc, item) => {
                if (
                    item?.isCredit === true &&
                    formatDate(item.eventDate).split("/")[0] === dia
                ) {
                    acc = acc + item.principalAmount;
                }
                return parseFloat(acc.toFixed(2));
            }, 0),
        };
    });

    return dias.length > 0 ? (
        <GraficoStyles>
            <BarChart
                width={currentWidth}
                height={300}
                data={dados}
                className="grafico"
            >
                <XAxis
                    dataKey="name"
                    stroke="#6D6D6D"
                    strokeOpacity={0}
                    orientation="top"
                />
                <Tooltip
                    formatter={(values: number) => {
                        return "R$ " + formatCurrentMoney(Math.abs(values));
                    }}
                />

                <ReferenceLine y={0} stroke="#6D6D6D" />
                <Bar dataKey={t("Saída")} fill={currentTheme.colors.warning} />
                <Bar
                    dataKey={t("Entrada")}
                    fill={currentTheme.colors.success}
                />
            </BarChart>
        </GraficoStyles>
    ) : (
        <></>
    );
}
