import { AdicionarCupom } from "../../components/Mobile/AdicionarCupom";
import { UseCupomProvider } from "../../Hook/user/useCupom";

export function PageAdicionarCupom() {
    return (
        <UseCupomProvider>
            <AdicionarCupom />
        </UseCupomProvider>
    );
}
