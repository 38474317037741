import styled from "styled-components";

export const Container = styled.div`
    padding-top: 35px;
    .container {
        p {
            width: 260px;
            margin: 30px auto 30px;
            text-align: center;

            font-size: 14px;
            line-height: 120%;
        }
    }
`;

export const FormStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 23px;

    button {
        &.open-modal-cvv {
            width: 100%;
            height: 2.5rem;
            margin-bottom: 68px;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
        }
    }

    .container-input.chave-cartao:before {
        top: 20px;
        left: 13px;
    }

    .container-input.error:after{
        top: 20px;
        right: 40px;
    }

    .container-input.olho button{
        top: 22px;
        right: -4px;
    }

    form{
        width:320px;
    }

    .mt-auto{
        button{
            max-width: 320px;
            height: 2.5rem;
            background-color: ${(props) => props.theme.colors.secondarydark} !important;

            font-weight: 700;
            letter-spacing: 0;
            text-transform: none;
            color:white !important;
        }

        .cancelar {
            width: 320px;
            height: 2rem;
            margin:4px 0 12px;
            background-color: white !important;
            
            font-family: Oxanium;
            font-weight: 400;
            color: #5e5f5f !important;
        }
    }

    input{
        max-width: 320px;
        height: 2.5rem !important;
        padding: 0 45px !important;;

        font-size: 14px !important;;
        text-transform: none !important;
    }
`;
