import { useState } from "react";
import { Text, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { formatDateTimeDb } from "../../../../util/helper";
import { Myform, initalValues, validadeForm } from "../config";
import { DateTime } from "../../DateTime";

import { Button } from "../../Button";
import { ButtonSegmentacao } from "../../SegmentacaoCupom/Button";
import { useSegmentacao } from "../../../../Hook/system/useSegmentacao";
import { useCupom } from "../../../../Hook/system/useCupom";

import { FormStyles } from "./styles";
import { Itens } from "./itens";

interface FormProps {}
export type keyOfCarga<T> = keyof T;

export function FormCupom(props: FormProps) {
    const [loading, setLoading] = useState(false);
    const { segmentacao, setSegmentacao, campanhas } = useSegmentacao();
    const { store } = useCupom();
    const history = useHistory();

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    setLoading(true);

                    if (campanhas?.length === 0) {
                        actions.setFieldError(
                            "segmentacao",
                            "Campo obrigatório"
                        );
                        setLoading(false);
                        return;
                    }
                    const data = {
                        ...values,
                        campanhas: campanhas.map((item: any) => {
                            return parseInt(item.id);
                        }),
                    };

                    const response = await store(data);

                    if (response.status === 200) {
                        history.push("/sistema/cupom");
                        setSegmentacao([]);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue } = props;
                    return (
                        <Form>
                            <Text
                                mb="8px"
                                textTransform="uppercase"
                                fontWeight={500}
                                fontSize="xss"
                            >
                                dados do cupom
                            </Text>

                            <div className="container-input-system cupom">
                                <Field
                                    type="text"
                                    placeholder="NOME DO PEDIDO"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <ButtonSegmentacao />
                            {segmentacao.length === 0 && (
                                <ErrorMessage
                                    component="span"
                                    name="segmentacao"
                                />
                            )}

                            <div className="container-input-system cupom">
                                <Field
                                    type="text"
                                    placeholder="PREFIXO"
                                    name="prefixo"
                                />
                            </div>
                            <ErrorMessage component="span" name="prefixo" />

                            <Field component={Itens} />

                            <DateTime
                                initialValue={null}
                                label="DATA DE VALIDADE"
                                onChange={(dt_inicio) => {
                                    setFieldValue(
                                        "validade",
                                        formatDateTimeDb(dt_inicio).trim()
                                    );
                                }}
                            />

                            <Box width="220px" mt="30px" ml="auto">
                                <Button
                                    type="submit"
                                    disabled={!props.isValid}
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                >
                                    CRIAR CUPOM
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
