import { memo, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

import { getToken, getLocalStore } from "../../util/helper";
import { setToken } from "../../services/";

import { Menu } from "../System/Menu";
import { routesProps } from "../../routes/types";
import { toast } from "react-toastify";
import { Footer } from "../System/Footer";

// import TemporaryDrawer from "../Menu";

function RouteWrapper(props: routesProps) {
    const { isPrivate = false, path } = props;
    const token = getToken("admin");
    const hasBackOffice = path.indexOf("backoffice") > 0;
    const removeMenu = [
        "/sistema",
        "/sistema/campanha/carga/:id/detalhe",
        "/sistema/segmentacao",
    ];

    const hasPermission =
        getLocalStore().permissions[props.permission ? props.permission : ""];

    useEffect(() => {
        if (!hasPermission && props.path !== "*")
            toast.error("Você não tem permissão para acessar este conteúdo!");
    }, [hasPermission, props.path]);

    if (!hasPermission) return <Redirect to="/sistema" from={path} />;

    if (token) {
        setToken("admin");
    }
    // rota privada sem token
    if (isPrivate && !token) {
        return <Redirect to="/sistema" from={path} />;
    }

    // redirecionar rotas inexistentes
    if (path === "*" || path === "/") {
        return <Redirect to="/sistema" from={path} />;
    }

    //Rotas /sistema
    if (
        path.indexOf("novo") > 0 ||
        path.indexOf("nova") > 0 ||
        path.indexOf("editar") > 0 ||
        path.indexOf("adicionar") > 0 ||
        removeMenu.includes(path)
    ) {
        return <Route {...props} />;
    }

    return (
        <>
            <Menu />
            <div className={`content ${hasBackOffice ? "backoffice" : ""}`}>
                <Route {...props} />
                <div className="footer-system">
                    <Footer />
                </div>
            </div>
        </>
    );
}

export default memo(RouteWrapper);
