import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useUser } from "../../../Hook/user/UseUser";
// import { crypted } from "../../../util/helper";
import { UseModal } from "../../../Hook/Usemodal";
import { HeaderPassos } from "../HeaderPassos";
// import { Senha } from "../Senha";
import { foraDoHorario } from "../../../util/helper";
import { Validadar } from "../Validar";
import { AlertaNotificacao, AtivarNotificacao } from "./ativarNotificacao";
import { arrPassos, arrPassosSms } from "./config";
import { Confirmar } from "./confirma";
import { Finalizar } from "./finalizar";
import { Solicitar } from "./solicitar";
import { Container } from "./styles";

export function SolicitarResgate() {
    const [stateSms, setStateSms] = useState(false);
    const [alertaSms, setAlertaSms] = useState(false);
    const [hasFirstResgate, sethasFirstResgate] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setPassos, passos, nextPasso, dados } = usePassos();
    const { resgate, setSaldo, cartao } = UseCartoes();
    const { setState, state } = UseModal();
    const { getUser, validateUser } = useUser();
    const history = useHistory();
    const hasPath = history.location.pathname.includes("/solicitar-resgate");
    const width = window.screen.width;
    const isFirstResgate = cartao?.resgates_count === 0;

    async function handleClick() {
        setLoading(true);
        setSaldo("");

        if (hasFirstResgate) {
            sethasFirstResgate(true);
            setStateSms(false);
            setAlertaSms(false);
            nextPasso(4);
        } else {
            sethasFirstResgate(false);

            try {
                await validateUser("whatsapp");
                nextPasso(3);
            } catch (error: any) {
                console.log(error, error.response);
            }
        }

        setLoading(false);
    }

    function handleClickSms() {
        if (stateSms) {
            handleClick();
        } else {
            setAlertaSms(true);
        }
    }

    async function handleSubmit() {
        const values: any = {
            cartao_id: dados?.currentCard?.id,
            valor: dados.valor,
        };

        if (stateSms) {
            values.sms_service = 1;
        }

        await resgate(values);

        await getUser();

        if (hasFirstResgate) {
            nextPasso(5);
        } else {
            nextPasso(4);
        }
    }

    useEffect(() => {
        if (state?.solicitarResgate || hasPath) {
            if (isFirstResgate) {
                setPassos(arrPassosSms);
            } else {
                setPassos(arrPassos);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPassos, hasPath, state.solicitarResgate]);

    if (foraDoHorario()) {
        history.push("/dashboard");
        return <></>;
    }

    return (
        <Container>
            {((hasFirstResgate && !passos[4]?.active) || (!hasFirstResgate && !passos[3]?.active)) && 
            <HeaderPassos
                title="Inserir Crédito"
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, solicitarResgate: false });
                        setPassos([]);
                    }
                }}
            />}
            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active ? (
                        <Solicitar sethasFirstResgate={sethasFirstResgate} />
                    ) : null}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        <Confirmar
                            hasFirstResgate={hasFirstResgate}
                            onClick={handleClick}
                            loading={loading}
                        />
                    ) : null}
                </div>

                {hasFirstResgate ? (
                    <>
                        <div
                            className={`tab ${
                                passos[2]?.active ? "active" : ""
                            }`}
                        >
                            {passos[2]?.active ? (
                                alertaSms ? (
                                    <AlertaNotificacao
                                        setStateSms={setStateSms}
                                        loading={loading}
                                        onClick={() => {
                                            handleClick();
                                        }}
                                    />
                                ) : (
                                    <AtivarNotificacao
                                        setStateSms={setStateSms}
                                        loading={loading}
                                        onClick={() => {
                                            handleClickSms();
                                        }}
                                    />
                                )
                            ) : null}
                        </div>

                        <div
                            className={`tab ${
                                passos[3]?.active ? "active" : ""
                            }`}
                        >
                            {passos[3]?.active ? (
                                <Validadar handleClick={handleSubmit} />
                            ) : (
                                <></>
                            )}
                        </div>

                        <div
                            className={`tab ${
                                passos[4]?.active ? "active" : ""
                            }`}
                        >
                            <Finalizar />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={`tab ${
                                passos[2]?.active ? "active" : ""
                            }`}
                        >
                            {passos[2]?.active && (
                                <Validadar
                                    handleClick={handleSubmit}
                                    autoSend={false}
                                />
                            )}
                        </div>
                        <div
                            className={`tab ${
                                passos[3]?.active ? "active" : ""
                            }`}
                        >
                            <Finalizar />
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
}
