import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 27px;
    padding-top: 50px;
    padding-bottom: 35px;

    .img-close {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .container-title {
        text-align: center;
        margin-bottom: 38px;
        h2 {
            font-size: 20px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.secondary};
            margin-top: 21px;
            margin-bottom: 15px;
        }

        p {
            font-size: 14px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .container-detalhes {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 28px;
        p {
            font-size: 16px;
            font-weight: 700;
            color: ${(props) => props.theme.colors.secondary};
        }
        span {
            font-size: 14px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }

        small {
            font-size: 14px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .buttonContainer{
        display: flex;
        flex-direction: column;
        gap:6px;
        align-items: center;

        width:273px;
        margin-top:30px;
    }
`;
