import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Text, Box } from "alisson-application";
import { Permissions } from "../../Permission";
import { formatDateIsoSystem } from "../../../../util/helper";
import { toast } from "react-toastify";

import { api } from "../../../../services/";

// import { ProgressSlider } from "../../Slider";
import { Button } from "../../Button";
import { ContainerEsq } from "./styles";
import imgEsq from "../../../../assets/img/seta-esq-sistema.svg";
import imgUser from "../../../../assets/img/user.svg";
import imgCalendario from "../../../../assets/img/calendario.svg";
import imgEnviado from "../../../../assets/img/enviado.svg";
import imgOlho from "../../../../assets/img/olho.svg";

import { DetalheComunicacao } from ".";

interface EsqProps extends DetalheComunicacao {
    count: number;
}

export function Sms(props: EsqProps) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    async function dispararAcao() {
        setLoading(true);
        try {
            const response = await api.post(`acao/fire`, {
                acao_id: props.id,
            });
            toast.success("Ação disparada com sucesso");
            history.goBack();
            return response;
        } catch (error: any) {
            toast.error("Erro ao disparar ação");
            return error;
        } finally {
            setLoading(false);
        }
    }

    return (
        <ContainerEsq>
            <div className="header-go-back">
                <button onClick={() => history.goBack()}>
                    <img src={imgEsq} alt="" />
                    VOLTAR
                </button>
            </div>

            <div className="container-content">
                <h4>MENSAGEM</h4>

                <h3>{props.conteudo}</h3>
            </div>

            <div className="container-footer">
                <div className="detalhe">
                    <div className="card">
                        <img src={imgUser} alt="" />
                    </div>
                    <div className="text">
                        <Text color="primaryDark" fontSize="xs">
                            DESTINATÁRIOS
                        </Text>
                        <Text fontSize="md" fontWeight="600">
                            {props.total_recipientes}
                        </Text>
                    </div>
                </div>
                <div className="detalhe">
                    <div className="card">
                        <img src={imgEnviado} alt="" />
                    </div>
                    <div className="text">
                        <Text color="primaryDark" fontSize="xs">
                            ENVIADOS
                        </Text>
                        <Text fontSize="md" fontWeight="600">
                            {props.total_enviados}
                        </Text>
                    </div>
                </div>

                <div className="detalhe">
                    <div className="card">
                        <img src={imgOlho} alt="" />
                    </div>
                    <div className="text">
                        <Text color="primaryDark" fontSize="xs">
                            VISUALIZADOS
                        </Text>
                        <Text fontSize="md" fontWeight="600">
                            {props.total_visualizados}
                        </Text>
                    </div>
                </div>

                <div className="detalhe">
                    <div className="card">
                        <img src={imgCalendario} alt="" />
                    </div>
                    <div className="text">
                        <Text color="primaryDark" fontSize="xs">
                            DATA/HORA DISPARO
                        </Text>
                        <Text fontSize="md" fontWeight="600" as="p">
                            {props.data_disparo
                                ? formatDateIsoSystem(props.data_disparo)
                                : ""}
                        </Text>
                    </div>
                </div>
                <div className="detalhe">
                    {/* {props.disparo === "IMEDIATO" && ( */}
                    <Permissions role="acao.fire">
                        <Box width="128" mt="30px">
                            <Button
                                isLoading={loading}
                                spiner="BallTriangle"
                                onClick={() => {
                                    dispararAcao();
                                }}
                                disabled={
                                    props.status === "AGUARDANDO" ? false : true
                                }
                            >
                                {props.status === "AGUARDANDO"
                                    ? "DISPARAR"
                                    : "DISPARADO"}
                            </Button>
                        </Box>
                    </Permissions>
                    {/* )} */}
                </div>
            </div>
        </ContainerEsq>
    );
}
