import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const outerTheme = createTheme({
    palette: {
        primary: {
            main: "#5DB356",
        },
    },
});

interface CheckBoxProps {
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox(props: CheckBoxProps) {
    const { checked, disabled, onChange } = props;

    return (
        <ThemeProvider theme={outerTheme}>
            <Checkbox
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        </ThemeProvider>
    );
}
