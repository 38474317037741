import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #e1e2e3;
    margin-bottom: 18px;
    img {
        display: block;
        margin-right: 12px;
    }
    p,
    small {
        font-size: 1rem;
        font-weight: 700;
        line-height: 140%;
        color: ${(props) => props.theme.colors.secondary};
    }
    p {
        margin-right: 6px;
    }
    small {
        font-weight: 400;
        color: ${(props) => props.theme.colors.primaryDark};
        font-weight: bold;
    }
`;
