import styled from "styled-components";
import { Container as DefaultHeader } from "../../Header/styles";
import imgLupa from "../../../../assets/img/lupa.svg";

export const Container = styled.div``;

export const StylesHeader = styled.div`
    height: 181px;
    .container-header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #e1e2e3;
        ${DefaultHeader} {
            border: none;
        }
        button {
            width: auto;
            margin: 0px;
            margin-left: 11px;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            margin-right: 44px;
            input {
                padding-left: 45px;
                border: none;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 20px;
                left: unset;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }
    }

    .container-relatorio {
        background-color: #f4f3eb;
        height: 80px;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: 120px;
        align-items: center;
        position: absolute;
        width: calc(100vw - 147px);
        left: 0px;
        top: 102px;
        padding-left: 5rem;
        padding-right: 42px;
        .item {
            position: relative;
            &:last-child {
                &:before {
                    display: none;
                }
            }
            &:first-child {
                &:before {
                    display: none;
                }
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: -50%;
                transform: translateX(50%);
                width: 1px;
                height: 33px;
                background-color: #d9d8cf;
            }
            span {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                letter-spacing: 0.96px;
                text-transform: uppercase;
                white-space: nowrap;
            }

            p {
                font-family: Oxanium;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 1.12px;
                text-transform: uppercase;
                white-space: nowrap;
            }
        }
    }
`;
