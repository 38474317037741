import * as Yup from "yup";
import { Values } from "./novo/itens";

export interface Myform {
    nome: string;
    prefixo: string;
    itens: Values[];
    validade: string;
}
export const initalValues: Myform = {
    nome: "",
    prefixo: "",
    itens: [],
    validade: "",
};

export const validadeForm = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    prefixo: Yup.string().required("Campo Obrigatório"),
    itens: Yup.array().of(
        Yup.object().shape({
            valor: Yup.string().required("Campo Obrigatório"),
            quantidade: Yup.string().required("Campo Obrigatório"),
        })
    ),
    validade: Yup.string().required("Campo Obrigatório"),
});
