import { Text } from "alisson-application";
import { useCallback, useEffect, useState } from "react";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";
import { formatDateIsoSystem } from "../../../util/helper";
import Table, { Pagination } from "../Table";

import { PaginationConfig } from "../../../Hook/system/config";
import {
    Campanha as CampanhaType,
    useCampanha,
} from "../../../Hook/system/useCampanha";

import { Loader } from "../../Loader";
import { Button } from "../Button/areas";
import { Button as Status } from "../Button/status";
import { Data } from "./config";
import { Header } from "./header";

import { SearchCampaign } from "./components/Search";
import { Container } from "./styles";

export function Campanha() {
    const history = useHistory();
    const { searchCampanha } = useCampanha();
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const { getCampanha } = useCampanha();
    const [campanhas, setCampanhas] = useState<CampanhaType[]>([]);
    const [immutableCampaigns, setImmutableCampaigns] = useState<
        CampanhaType[]
    >([]);
    const [allowBillPayment, setAllowBillPayment] = useState(false);

    async function handleSearch(search: string) {
        const response = await searchCampanha(search);

        setCampanhas(response.campaigns.data);
    }

    async function handleCleanSearch(search: string) {
        if (!search) {
            setCampanhas(immutableCampaigns);
        }
    }

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getCampanha(page);
            const { campaigns } = response;
            const { data, ...resto } = campaigns;

            setConfig(resto);
            setCampanhas(data);
        } catch (error) {
            console.log(error);
            setCampanhas([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await getCampanha();
        const { campaigns } = response;
        if (campaigns?.data) {
            const { data, ...resto } = campaigns;
            setAllowBillPayment(response.allow_bill_payment);
            setCampanhas(data);
            setImmutableCampaigns(data);
            setConfig(resto);
        }

        setLoading(false);
    }, [getCampanha]);

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>#{row.id}</Text>;
            },
            width: 100,
        },
        {
            field: "nome",
            headerName: "Nome",
            width: 150,
        },
        {
            field: "area",
            headerName: "Área",
            width: 400,
        },
        {
            field: "start_date_time",
            headerName: "Data/hora início",
            width: 200,
        },
        {
            field: "end_date_time",
            headerName: " Data/hora Fim",
            width: 200,
        },
        {
            field: "chave",
            headerName: "Chave",
            width: 200,
        },
        {
            field: "cliente",
            headerName: "Cliente",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Button>{row.cliente}</Button>;
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Status className={`${row.status}`}>{row.status}</Status>
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
        },
    ];

    const rows: Data[] = campanhas?.map((campanha) => {
        // const areas = campanha.cliente.areas
        //     .map((area) => area.nome)
        //     .join(", ");

        return {
            id: campanha.id,
            nome: campanha.nome,
            area: campanha?.cliente_area_contato?.area?.nome,
            end_date_time: formatDateIsoSystem(campanha.end_date_time),
            start_date_time: formatDateIsoSystem(campanha.start_date_time),
            chave: campanha.chave,
            cliente: campanha.cliente.nome,
            status: campanha.status,
        };
    });

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Container>
            <Header
                allowBillPayment={allowBillPayment}
                handleChange={fetchData}
            />

            <div className={`content-table ${loading ? "active" : ""}`}>
                <SearchCampaign
                    onSearch={handleSearch}
                    onChange={handleCleanSearch}
                />

                {loading && (
                    <div className="container-loading">
                        <Loader />
                    </div>
                )}

                <Table
                    columns={collumns}
                    rows={rows ? rows : []}
                    nameCSV="ADICIONAR-CAMPANHA"
                    onRowClick={(values) =>
                        history.push({
                            pathname: `/sistema/campanha/carga/${values.id}`,
                            state: { id: values.id },
                        })
                    }
                />
            </div>

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
