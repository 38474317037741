import { useContext, createContext, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
// import { api } from "../../services";

interface UseAprovacaoTransferencia {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseAprovacaoTransferencia {
    tabs: Tabs[];
    handleTab: (id: number) => void;

    ids: number[];
    setIds: (number: number[]) => void;

    modalAprovar: boolean;
    setModalAprovar: (value: boolean) => void;

    modalReprovar: boolean;
    setModalReprovar: (value: boolean) => void;

    loading: boolean;

    aprovarPedidos(ids: number[]): Promise<any>;
    reprovarPedidos(dados: ReprovarPedido): Promise<any>;
}

export interface Tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
}

interface ReprovarPedido {
    pedidos_creditos: number[];
    pedidos_creditos_motivos_reprove_id: number;
}

const UseAprovacaoTransferenciaContext =
    createContext<IuseAprovacaoTransferencia>({} as IuseAprovacaoTransferencia);

const arrItens: Tabs[] = [
    {
        id: 1,
        title: "créditos para aprovar",
        className: "aprovar",
        active: true,
        viewer: true,
        permission: true,
    },
    {
        id: 2,
        title: "créditos aprovados",
        className: "aprovados",
        active: false,
        viewer: false,
        permission: false,
    },
    {
        id: 3,
        title: "créditos reprovados",
        className: "reprovados",
        active: false,
        viewer: false,
        permission: false,
    },
];

export function AprovacaoTransferenciaProvider(
    props: UseAprovacaoTransferencia
) {
    const { children } = props;
    const [tabs, setTabs] = useState(arrItens);
    const [ids, setIds] = useState<number[]>([]);

    const [modalAprovar, setModalAprovar] = useState(false);
    const [modalReprovar, setModalReprovar] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleTab(id: number) {
        const newTabs = tabs.map((item) => {
            if (item.id === id) {
                item.active = true;
                item.viewer = true;
            } else {
                item.active = false;
            }

            return item;
        });

        setTabs(newTabs);
    }

    async function aprovarPedidos(ids: number[]) {
        setLoading(true);
        try {
            const response = await api.post(`pedido-credito/approve`, {
                pedidos_creditos: ids,
            });

            toast.success("Pedido aprovado");
            setLoading(false);
            window.location.reload();
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar pedido");
            setLoading(false);

            return error;
        }
    }

    async function reprovarPedidos(dados: ReprovarPedido) {
        setLoading(true);
        try {
            const response = await api.post(`pedido-credito/repprove`, {
                ...dados,
            });

            toast.success("Pedido reprovado");
            setLoading(false);
            window.location.reload();
            return response;
        } catch (error: any) {
            toast.error("Erro ao reprovado pedido");
            setLoading(false);
            return error;
        }
    }

    return (
        <UseAprovacaoTransferenciaContext.Provider
            value={{
                tabs,
                handleTab,
                setIds,
                ids,
                loading,
                modalAprovar,
                setModalAprovar,
                modalReprovar,
                setModalReprovar,
                aprovarPedidos,
                reprovarPedidos,
            }}
        >
            {children}
        </UseAprovacaoTransferenciaContext.Provider>
    );
}

export function useAprovacaoTransferencia() {
    const context = useContext(UseAprovacaoTransferenciaContext);
    return context;
}
