import { useContext, createContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { api } from "../../services";
import { toast } from "react-toastify";
import { Myform } from "../../components/System/Relatorio/novo/config";
import { FormikHelpers } from "formik";
import { PaginationConfig } from "./config";

interface useRelatorioProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseRelatorio {
    idRelatorio: number | null;
    setIdRelatorio: (value: number | null) => void;

    create(values: Myform, formikHelpers: FormikHelpers<Myform>): Promise<any>;
    deleteRelatorio(): Promise<any>;

    fetch(page?: number): Promise<RelatorioData | undefined>;
    fetchDetail(): Promise<RelatorioDetailData>;
    data: RelatorioData;
    loading: boolean;
}

export interface RelatorioData extends PaginationConfig {
    data: Relatorio[];
}
export interface Relatorio {
    created_at: string;
    end: string;
    file: string;
    id: number;
    start: string;
    status: string;
    title: string;
    updated_at: string;
    user_id: 1;
    tipo: string;
    usuario: string;
    progress: string;
}

export interface RelatorioDetailData {
    data: DetailData;
    scn: number;
    carteira: number;
    no_name_ativar: number;
    r: number;
    c: number;
    created_at: string;
    start: string;
    end: string;
}

export interface DetailData {
    [key: string]: Detail;
}

export interface Detail {
    id: string;
    campanha: string;
    cancelamento: number | string;
    resgate: number | string;
    valor: number | string;
    restante: number | string;
    t_virtual: number | string;
    t_avulso: number | string;
    t_fisico: number | string;
    t_activate: number | string;
    t_c_activate: number | string;
}

const UseRelatorioContext = createContext<UseRelatorio>({} as UseRelatorio);

export function RelatorioProvider(props: useRelatorioProviderProps) {
    const { children } = props;
    const history = useHistory<{ id: number }>();
    const [loading, setLoading] = useState(false);
    const [idRelatorio, setIdRelatorio] = useState<number | null>(null);
    const [data, setData] = useState<RelatorioData>({} as RelatorioData);

    async function fetch(page?: number) {
        const hasPage = `${page ? `?page=${page}` : ""}`;
        try {
            setLoading(true);
            const { data } = await api.get<RelatorioData>(
                `/reports/creditos-campanha${hasPage}`
            );
            setData(data);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao buscar relatório");
        }
    }

    async function fetchDetail(): Promise<RelatorioDetailData> {
        try {
            const { data } = await api.get<RelatorioDetailData>(
                `reports/creditos-campanha/${history.location.state.id}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao buscar relatório");
            return {} as RelatorioDetailData;
        }
    }

    async function create(
        values: Myform,
        formikHelpers: FormikHelpers<Myform>
    ) {
        try {
            setLoading(true);
            await api.post(`/reports/creditos-campanha`, {
                ...values,
            });
            toast.success("Relatório criado com sucesso");
            setLoading(false);
            history.goBack();
            formikHelpers.resetForm();
        } catch (error: any) {
            toast.error("Erro ao criar relatório");
            setLoading(false);
            return error;
        }
    }

    async function deleteRelatorio() {
        if (deleteRelatorio === null)
            return toast.error("Erro ao excluir relatório");

        try {
            const response = await api.delete(
                `/reports/creditos-campanha/${idRelatorio}`
            );
            await fetch();
            toast.success("Relatório excluido com sucesso!");
            return response;
        } catch (error: any) {
            return error;
        }
    }

    useEffect(() => {
        fetch();
    }, []);

    return (
        <UseRelatorioContext.Provider
            value={{
                idRelatorio,
                setIdRelatorio,
                create,
                fetch,
                data,
                deleteRelatorio,
                loading,
                fetchDetail,
            }}
        >
            {children}
        </UseRelatorioContext.Provider>
    );
}

export function useRelatorio() {
    const context = useContext(UseRelatorioContext);
    return context;
}
