import { Button } from "../ButtonSlim";
import { Container } from "../../Modais/ModalContainer/styles"

import ImgAlert from "../../../assets/img/crash.svg";
import { UseModal } from "../../../Hook/Usemodal";

export function SaldoInsuficiente() {
    const { handleClose } = UseModal();
    return (
        <Container>
            
            <img src={ImgAlert} alt="" />
            <h2>
                Saldo Insuficiente
            </h2>
            <p>
                <strong>
                    É necessário R$30,00 (Emissão + Frete)
                </strong><br/>

                em sua Carteira para solicitar o Cartão 
                <br/>
                Físico Personalizado.
            </p>
            <p>
                Aguarde uma nova recarga
            </p>
            <div className="buttonContainer">
                <Button onClick={() => handleClose("saldoInsuficiente")}>
                    Ok
                </Button>
            </div>
        </Container>
    );
}
