import { Box, Flex, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Permissions } from "../../Permission";

import { formatDateTimeDb } from "../../../../util/helper";

import { Campanha, useCampanha } from "../../../../Hook/system/useCampanha";
import { Button } from "../../Button";
import { DateTime } from "../../DateTime";
import { MemorizedCampo } from "./campos";
import { CampanhaFormUpdate, myform, validadeMyform } from "./config";

import Select, { options } from "../../../NovoSelect";
import { IOSSwitch } from "../../Switch";
import { FormStyles } from "./styles";

export interface ArrayArea {
    id: string;
    area: string;
}

const register: options[] = [
    {
        label: "CPF",
        value: "fisica",
    },
    {
        label: "CNPJ",
        value: "juridica",
    },
];

interface FormProps {}

export function FormCampanha(props: FormProps) {
    const { updateCampanha, getCampanhaByIdObj } = useCampanha();
    const [campanhaById, setCampanhaById] = useState<Campanha>({} as Campanha);
    const [clientes, setClientes] = useState<options[]>([]);
    const [contatos, setContatos] = useState<options[]>([]);
    const [initialValues, setInitialValues] =
        useState<CampanhaFormUpdate>(myform);
    const history = useHistory();

    useEffect(() => {
        async function fetch() {
            const response = await getCampanhaByIdObj();
            setCampanhaById(response.data);
            setClientes(response.clientes);
            setContatos(response.contatos);

            setInitialValues({
                ...myform,
                chave: response.data.chave,
                nome: response.data.nome,
                start_date_time: response.data.start_date_time,
                end_date_time: response.data.end_date_time,
                cliente_id: response.data.cliente_id + "",
                cliente_area_contato_id:
                    response.data.cliente_area_contato_id + "",
                custom_fields: JSON.parse(response.data.custom_fields || "[]"),
                external_register: response.data.external_register
                    ? true
                    : false,
                force_account_register: response.data.force_account_register
                    ? true
                    : false,
                force_activate_account_login: response.data
                    .force_activate_account_login
                    ? true
                    : false,
                register_type: response.data.register_type,
                allow_transference_between_users:
                    response.data?.allow_transference_between_users,
                allow_transference_between_campain:
                    response.data.allow_transference_between_campain,
                allow_cupom: response.data.allow_cupom ? true : false,
                allow_bill_payment: response.data.allow_bill_payment
                    ? true
                    : false,
                virtual_card_on_register: response.data.virtual_card_on_register
                    ? true
                    : false,
                allow_bill_payment_available: response.data
                    .allow_bill_payment_available
                    ? true
                    : false,
            });
        }

        fetch();
    }, [getCampanhaByIdObj]);

    const hasOptionsTipoCliente = useCallback(() => {
        if (campanhaById?.register_type) {
            return register.filter(
                (op) => op.value === campanhaById?.register_type
            )[0];
        }
        return null;
    }, [campanhaById]);

    const hasOptionsCliente = useCallback(() => {
        if (campanhaById?.cliente?.nome) {
            return clientes.filter(
                (cliente) => cliente.label === campanhaById?.cliente?.nome
            )[0];
        }
        return null;
    }, [campanhaById, clientes]);

    const hasOptionsContato = useCallback(() => {
        if (campanhaById?.cliente?.nome) {
            return contatos.filter(
                (contato) =>
                    contato.value ===
                    campanhaById?.cliente_area_contato?.area?.id
            )[0];
        }
        return null;
    }, [campanhaById, contatos]);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validadeMyform}
                onSubmit={async (values, actions) => {
                    const response = await updateCampanha(values);

                    if (response.status === 200) {
                        history.goBack();
                    }
                }}
            >
                {(props: FormikProps<CampanhaFormUpdate>) => {
                    const { setFieldValue, values } = props;

                    return (
                        <Form>
                            <Text as="p">DADOS DA CAMPANHA</Text>
                            <div className="container-input-system campanha">
                                <Field
                                    type="text"
                                    placeholder="NOME DA CAMPANHA"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />
                            <div className="container-input-system chave">
                                <Field
                                    type="text"
                                    name="chave"
                                    placeholder="CHAVE DA CAMPANHA"
                                />
                            </div>
                            <ErrorMessage component="span" name="chave" />
                            <DateTime
                                initialValue={
                                    values.start_date_time
                                        ? new Date(values.start_date_time)
                                        : null
                                }
                                label="DATA INICIO"
                                onChange={(dt_inicio) => {
                                    setFieldValue(
                                        "start_date_time",
                                        formatDateTimeDb(dt_inicio).trim()
                                    );
                                }}
                            />
                            <DateTime
                                initialValue={
                                    values.end_date_time
                                        ? new Date(values.end_date_time)
                                        : null
                                }
                                label="DATA FIM"
                                onChange={(dt_end) => {
                                    setFieldValue(
                                        "end_date_time",
                                        formatDateTimeDb(dt_end).trim()
                                    );
                                }}
                            />
                            <Select
                                tipo="cliente"
                                label="TIPO LOGIN"
                                initalValue={hasOptionsTipoCliente()}
                                options={register}
                                onChange={(values) => {
                                    setFieldValue(
                                        "register_type",
                                        values.value
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="register_type"
                            />

                            <Select
                                tipo="cliente"
                                label="CLIENTE"
                                options={clientes}
                                initalValue={hasOptionsCliente()}
                                onChange={(values) => {
                                    setFieldValue("cliente_id", values.value);
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="register_type"
                            />

                            <ErrorMessage component="span" name="cliente_id" />
                            <Box mb="36px">
                                <Select
                                    tipo="contato"
                                    label="CONTATO"
                                    options={contatos}
                                    initalValue={hasOptionsContato()}
                                    onChange={(values) => {
                                        setFieldValue(
                                            "cliente_area_contato_id",
                                            values.value
                                        );
                                    }}
                                />
                                <ErrorMessage
                                    component="span"
                                    name="register_type"
                                />
                            </Box>
                            <Text as="p" mb="16px">
                                CAMPOS PERSONALIZADOS
                            </Text>
                            <Field
                                component={MemorizedCampo}
                                campanhaById={campanhaById}
                            />
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR CADASTRO SEM INFRA
                                <IOSSwitch
                                    checked={values.external_register}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "external_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                OBRIGATORIO CONTA PARA CADASTRO
                                <IOSSwitch
                                    checked={values.force_account_register}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "force_account_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR TRANSFERÊNCIA ENTRE USUÁRIOS
                                <IOSSwitch
                                    checked={
                                        values.allow_transference_between_users
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_transference_between_users",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR TRANSFERÊNCIA ENTRE CAMPANHA
                                <IOSSwitch
                                    checked={
                                        values.allow_transference_between_campain
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_transference_between_campain",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR ADICIONAR CUPOM
                                <IOSSwitch
                                    checked={values.allow_cupom}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_cupom",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                GERAR 1 CARTÃO VIRTUAL AO REGISTRAR-SE
                                <IOSSwitch
                                    checked={values.virtual_card_on_register}
                                    onChange={(e) =>
                                        setFieldValue(
                                            "virtual_card_on_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR PAGAMENTO DE CONTAS
                                <IOSSwitch
                                    checked={values.allow_bill_payment}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            setFieldValue(
                                                "allow_bill_payment_available",
                                                false
                                            );
                                        }

                                        if (e.target.checked) {
                                            setFieldValue(
                                                "allow_bill_payment_available",
                                                true
                                            );
                                        }
                                        setFieldValue(
                                            "allow_bill_payment",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                                className={`disponibilidade ${
                                    !values.allow_bill_payment ? "disabled" : ""
                                }`}
                            >
                                DISPONIBILIDADE DE PAGAMENTO DE BOLETOS
                                <IOSSwitch
                                    checked={
                                        values.allow_bill_payment_available
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_bill_payment_available",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>
                            <Permissions role="campanha.update">
                                <Box width="233px" mt="32px" ml="auto">
                                    <Button type="submit">
                                        ATUALIZAR CAMPANHA
                                    </Button>
                                </Box>
                            </Permissions>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
