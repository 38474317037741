import { ChangeEvent, useState } from "react";

import { Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { DebounceInput } from "react-debounce-input";
import Mask from "react-input-mask";
import { toast } from "react-toastify";
import imgClip from "../../../assets/img/clip.svg";
import { usePrecisoDeAjuda } from "../../../Hook/user/UsePrecisoDeAjuda";
import { api_without_interceptors } from "../../../services";
import { maskCPF_CNPJ } from "../../../util/helper";
import { Button } from "../../Mobile/Button";
import { FormStyles } from "../styles";
import { FaleConosco, faleConosco, validationFaleConosco } from "./config";

export type keyOfFaleConosco<T> = keyof T;

export function FormFaleConosco() {
    const api = api_without_interceptors;

    const { fetchFaleConosco, loading } = usePrecisoDeAjuda();
    const [file, setFile] = useState<File | null>();

    return (
        <FormStyles>
            <p>
                Aqui você pode nos enviar sugestões, dúvidas ou reclamações
                sobre o funcionamento da Plataforma. Caso esteja com problemas
                no cadastro, envie um documento atualizado.
            </p>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={faleConosco}
                validationSchema={validationFaleConosco}
                onSubmit={async (values, actions) => {
                    values = {
                        ...values,
                        document: values.document.replace(/\D/g, ""),
                        phone: "55" + values.phone.replace(/\D/g, ""),
                    };

                    const formData = new FormData();

                    const keys = Object.keys(
                        values
                    ) as keyOfFaleConosco<FaleConosco>[];

                    keys.forEach((key) => {
                        formData.append(key, values[key]);
                    });

                    formData.append("file", file ? file : "");

                    await fetchFaleConosco(formData, actions);
                }}
            >
                {(props: FormikProps<FaleConosco>) => {
                    const {
                        values,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        setFieldError,
                    } = props;
                    const { phone } = values;

                    return (
                        <Form>
                            <div
                                className={`container-input ${
                                    props.errors.campanha &&
                                    props.touched.campanha
                                        ? "error"
                                        : ""
                                } chave`}
                            >
                                <DebounceInput
                                    type="text"
                                    name="campanha"
                                    placeholder="CAMPANHA"
                                    debounceTimeout={2000}
                                    value={values.campanha}
                                    onChange={async (
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const campanha = e.target?.value || "";
                                        setFieldValue("campanha", campanha);

                                        try {
                                            await api.post("/check-campanha", {
                                                campanha,
                                            });

                                            setFieldError("campanha", "");
                                        } catch (err: any) {
                                            const error = err.response.data;

                                            setFieldError(
                                                "campanha",
                                                error?.campanha[0] ||
                                                    "Falha ao validar campanha"
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <ErrorMessage name="campanha" component="span" />

                            <div
                                className={`container-input ${
                                    props.errors.document &&
                                    props.touched.document
                                        ? "error"
                                        : ""
                                } user`}
                            >
                                <Field
                                    name="document"
                                    placeholder="LOGIN (CPF/CNPJ)"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF_CNPJ(
                                            e.target.value
                                        );

                                        if (document.length <= 18) {
                                            setFieldValue("document", document);
                                        }
                                    }}
                                    value={maskCPF_CNPJ(values.document)}
                                />
                            </div>
                            <ErrorMessage name="document" component="span" />

                            <div
                                className={`container-input ${
                                    props.errors.name && props.touched.name
                                        ? "error"
                                        : ""
                                } user`}
                            >
                                <Field
                                    name="name"
                                    placeholder="NOME COMPLETO"
                                />
                            </div>
                            <ErrorMessage name="name" component="span" />

                            <div
                                className={`container-input ${
                                    props.errors.phone && props.touched.phone
                                        ? "error"
                                        : ""
                                } celular`}
                            >
                                <Mask
                                    mask="(99) 99999-9999"
                                    placeholder="TELEFONE"
                                    name="phone"
                                    type="tel"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={phone}
                                />
                            </div>
                            <ErrorMessage name="phone" component="span" />

                            <div
                                className={`container-input ${
                                    props.errors.email && props.touched.email
                                        ? "error"
                                        : ""
                                } contato`}
                            >
                                <Field
                                    name="email"
                                    placeholder="E-MAIL"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            "email",
                                            e.target.value.toLowerCase()
                                        );
                                    }}
                                />
                            </div>
                            <ErrorMessage name="email" component="span" />

                            <div
                                className={`container-input autoFalante ${
                                    props.errors.subject &&
                                    props.touched.subject
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field name="subject" placeholder="ASSUNTO" />
                            </div>
                            <ErrorMessage name="subject" component="span" />

                            <div
                                className={`container-input ${
                                    props.errors.description &&
                                    props.touched.description
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    component="textarea"
                                    name="description"
                                    placeholder="MENSAGEM"
                                />
                            </div>
                            <ErrorMessage name="description" component="span" />

                            <label htmlFor="arquivo" className="arquivo">
                                <img src={imgClip} alt="" />
                                <Text as="p">
                                    {file?.name
                                        ? file?.name
                                        : "insira seu anexo (se DESEJAR)"}
                                </Text>
                                <input
                                    type="file"
                                    id="arquivo"
                                    accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.xm"
                                    onChange={(e) => {
                                        if (e.target.files?.length) {
                                            const file = e.target.files[0];

                                            const fileSizeInMB =
                                                file.size / (1024 * 1024) + "";

                                            if (parseInt(fileSizeInMB) > 25) {
                                                toast.error(
                                                    "O arquivo deve ter no máximo 25MB."
                                                );
                                                return;
                                            }

                                            setFile(file);
                                        }
                                    }}
                                />
                            </label>
                            <ErrorMessage name="file" component="span" />
                            <Text
                                as="p"
                                className="erro-file"
                                color="primaryDark"
                            >
                                Arquivos aceitos: jpg, png, pdf e mp4 com até
                                25mb.
                            </Text>
                            <Text as="h5">
                                <strong>
                                    Em até 6 horas (das 9h às 18h, de segunda à
                                    sexta-feira)
                                </strong>
                                , você receberá uma resposta da Equipe Yetz uma
                                resposta pelo e-mail que você preencheu no
                                formulário para nos contatar. Mas{" "}
                                <strong>ATENÇÃO</strong>, não deixe de conferir
                                seu <strong>SPAM ou LIXO ELETRÔNICO</strong>{" "}
                                caso passe desse período o localize em sua Caixa
                                de Entrada.
                            </Text>
                            <Button
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                mt="31px"
                            >
                                enviar mensagem
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
