import { Container } from "./styles";
import { Text } from "alisson-application";
import { formatCurrentMoney } from "../../../util/helper";
import { Campanhas } from "../../../Hook/user/UseUser";
import imgDinheiro from "../../../assets/img/$.svg";
import imgCampanha from "../../../assets/img/campanha-verde.svg";
import imgDownload from "../../../assets/img/download-circulo.svg";

interface CardCampanhaProps extends Campanhas {}

export function CardCampanha(props: CardCampanhaProps) {
    const { nome, credito_restante, regulamento, produtos = [] } = props;

    return (
        <Container>
            <div className="item">
                <img src={imgCampanha} alt="" />
                <h3>{nome}</h3>
            </div>
            <div className="item">
                <img src={imgDinheiro} alt="" />
                <h3>
                    SALDO R$ {formatCurrentMoney(parseFloat(credito_restante))}
                </h3>
            </div>
            <div className="item list">
                <h2>CARTÕES DA CAMPANHA</h2>
                <ul>
                    {produtos?.map((produto) => (
                        <li key={produto.id}>{produto?.produto?.descricao}</li>
                    ))}
                </ul>
            </div>

            <button
                onClick={() => {
                    window.open(regulamento, "Download");
                }}
                className="item"
            >
                <img src={imgDownload} alt="" />
                <Text
                    color="secondary"
                    fontWeight="bold"
                    textTransform="uppercase"
                >
                    regulamento
                </Text>
            </button>
        </Container>
    );
}
