import { createGlobalStyle } from "styled-components";
import imgChave from "../assets/img/chave.svg";
import imgCheck from "../assets/img/check.svg";
import imgUser from "../assets/img/user.svg";
import imgEndereco from "../assets/img/endereco.svg";
import imgCasa from "../assets/img/casa.svg";
import imgSenha from "../assets/img/senha.svg";
import imgChaveCartaoAtivar from "../assets/img/senha-ativar-cartao.svg";
import imgCalendario from "../assets/img/calendario.svg";
import imgCelular from "../assets/img/celular.svg";
import imgContato from "../assets/img/contato.svg";
import imgAutoFalante from "../assets/img/auto-falante.svg";
import imgCupom from "../assets/img/cupom.svg";
import imgValor from "../assets/img/$.svg";
import imgCartao from "../assets/img/cartao.svg";
import imgCliente from "../assets/img/menu/cliente.svg";
import imgTelefone from "../assets/img/telefone.svg";
import imgCampanha from "../assets/img/campanhas.svg";
import imgSetaBaixo from "../assets/img/seta-baixo.svg";
import imgCarga from "../assets/img/carga.svg";
import imgError from "../assets/img/error.svg";
import imgCartaoAtivar from "../assets/img/cartao-ativar.svg";
import imgComunicacao from "../assets/img/comunicacao.svg";
import imgEstorno from "../assets/img/estorno-cred.svg";
import imgJob from "../assets/img/job.svg";
import imgLupa from "../assets/img/lupa.svg";

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

export const GlobalStyle = createGlobalStyle`
    *{
        margin: 0px;
        padding: 0px;
        outline: 0;
        box-sizing: border-box;
    }

    .btn-acao {
        &.active {
            img {
                transform: rotateX(180deg);
                transition: transform 0.5s;
            }
        }
        img {
            margin-left: 12px;
            transition: transform 0.5s;
        }
    }

    

    .content{
        display: flex;
        flex-direction: column;
        background-color: white;
        position: fixed;
        top: 0px;
        left: 148px;
        width: calc(100% - 148px);
        height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-top: 0px;
        padding-left: 5rem;
        padding-right: 42px;
        scrollbar-color: rgba(89, 92, 118, 0.5) rgba(255, 255, 255, 0.1);
        &.backoffice{
            background-color: #F4F3EB;
        }
        .footer-system{
            margin-top: auto;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
            width: 5px;
        }

        &::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(89, 92, 118, 0.5);
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .container-loading {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        div{
            width: 100px !important;
            height: 100px !important;   
        }
    }

    #root{
        /* overflow-x: hidden; */
    }
    svg{
        pointer-events: none;
    }

    *, 
    *:after,
    *:before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    a{
        text-decoration: none;
        color: inherit;
        
    }

    p {
        &.erro-file {
            font-size: 12px;
        }
    }

    li{
        list-style: none;
    }

    body {
        font-family: "Oxanium", Arial, Helvetica, sans-serif;
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
        transition: all 0.5s;
    }

    button{
        background: transparent;
        cursor: pointer;
        border: none;
        &:disabled{
            position: relative;
            cursor: not-allowed;
            /* opacity: 0.5; */
            &:before{
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255,255,255,0.3);
                z-index: 10;
            }
            div{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 90;
            }
        }
    }

    label{
        cursor: pointer;
        display: flex;
        align-items: center;
        &.arquivo{
            height: 48px;
            width: 100%;
            border: 1px solid
                ${(props) => props.theme.colors.secondarybright};
            box-sizing: border-box;
            border-radius: 4px;
            
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark} !important;
            padding-left: 16px;
            padding-right: 50px;
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark} !important;
                text-transform: uppercase;
            }
        }
        
    }

    input[type="file"]{
        display: none !important;
    }

    .container-loader{
        height: 100vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiAutocomplete-root{
        width: 100%;
    }

    .container-alerta {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 19px;
        h3 {
            font-size: 14px !important;
            color: ${({ theme }) => theme.colors.secondary};
            font-weight: bold;
            margin: 0px;
            text-transform: none !important;
        }
        p {
            color: ${({ theme }) => theme.colors.primaryDark};
            font-size: 14px !important;
            margin: 0px !important;
            text-transform: none !important;
        }
    }

    .combo-box-select{
        width: 100%;
        span{
            color: ${({ theme }) => theme.colors.white};
        }

        &.multipleSelect{
            .MuiInput-root{
                .MuiInput-input{
                    margin-top: 16px;
                }
            }
        }

        &.MuiAutocomplete-root{
            width: 100%;
            .MuiAutocomplete-tag{
                background: ${({ theme }) => theme.colors.primary};
            }

            .MuiOutlinedInput-root{
                padding: 0px !important;
                .MuiAutocomplete-input{
                    padding: 4px 4px 4px 0px;
                    &:before{
                        border-bottom: 1px solid ${({ theme }) =>
                            theme.colors.secondary} !important;
                    }
                }
            }

            .MuiInput-root{
                &::after{
                    border-bottom: 1px solid ${({ theme }) =>
                        theme.colors.secondary} !important;
                }
                &:before{
                    border-bottom: 1px solid ${({ theme }) =>
                        theme.colors.secondary} !important;
                }
            }

            &:hover{
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid #13393B;
                }
            }

            .MuiAutocomplete-input{
                color: ${({ theme }) => theme.colors.primary};
                font-weight: bold;
                &::placeholder{
                    color: ${({ theme }) => theme.colors.primary};
                    font-weight: 400;
                }
            }

            .MuiInputLabel-root{
                left: 0px;
                top: 0px;
                &.MuiInputLabel-formControl{
                    color: ${({ theme }) => theme.colors.secondary};
                    font-weight: 400;
                    font-size: 1rem;
                }
            }

            .MuiAutocomplete-endAdornment{
                top: calc(50% - 12px);
            }

            .MuiOutlinedInput-notchedOutline{
                border: 1px solid transparent !important;
            }
        }
    }

    .MuiDrawer-paperAnchorRight {
        transition: all .5s !important;
        background: ${({ theme }) => theme.colors.primary} !important;
            .MuiBox-root{
                background: ${({ theme }) => theme.colors.primary} !important;
                
                .MuiList-root{
                    background: ${({ theme }) =>
                        theme.colors.primary} !important;
                        .MuiListSubheader-root{
                            background: ${({ theme }) =>
                                theme.colors.primary} !important;
                        }
                }
            }
            .theme {
                position: absolute;
                bottom: 5%;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 250px;
            }
    }

    .container{
        width: 1010px;
        margin: 0 auto;
    }

    .grafico{
        margin:0 auto ;
        margin-bottom: 28px;
    }

    .timer{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .valor-cupom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        margin-top: 34px;
        &:before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: ${(props) => props.theme.colors.secondary};
            border-radius: 50%;
            position: absolute;
            top: 36%;
            left: 60%;
            transform: translateY(-50% -50%);
        }
    }

    .container-tabs {
        position: relative;
        padding-top: 34px;
        &.remove-pt{
            padding-top: 0px;
        }
        &.width{
            width: 100% !important;
        }
        .tab {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s ease-in-out;
            display: none;
            .container{
                display: none;
            }
            &.active {
                opacity: 1;
                pointer-events: all;
                position: static;
                display: block;
                .container{
                    display: block;
                }
            }
        }
    }

    form{
        span {
            font-style: normal;
            font-weight: 500;
            font-size: ${(props) => props.theme.fonts.xss};
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.failure};
            text-align: center;
            display: block;
            margin-bottom: 8px;
        }
    }

    .container-input {
        position: relative;
        margin-bottom: 8px;

        .seta-baixo{
            position: relative;
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                right: 20px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgSetaBaixo}) no-repeat;
                width: 14px;
                height: 10px;
                z-index: 20;
                transition: background-color 0.5s;
            }
        }

        &.error{
            input, textarea{
                border: 1px solid ${({ theme }) => theme.colors.failure};
                color: ${({ theme }) => theme.colors.failure};
            }
            &:before{
                background: ${({ theme }) => theme.colors.failure};
                z-index: 99;
            }
            &:after{
                background: ${({ theme }) => theme.colors.failure};
                -webkit-mask: url(${imgError}) no-repeat;                
                opacity: 1;
            }

            &.genero{
                &:before{
                    top: 43%;
                }
            }

            .container-input{
                &.select{
                    &:before{
                        opacity: 0;
                    }
                }
            }
        }

        &:last-child{
            margin-bottom: 17px;
        }

        &.error-cupom{
            &.cupom{
                &:before{
                    background: ${({ theme }) => theme.colors.failure};
                    transition:  all .5s;
                }
            }
            input{
                border: 1px solid ${({ theme }) => theme.colors.failure};
                transition:  all .5s;
                &::placeholder{
                    color: ${({ theme }) => theme.colors.failure};
                    transition:  all .5s;
                }
            }
        }

        &.active {
            &:before {
                background-color: ${(props) => props.theme.colors.secondary};
                transition: background-color 0.5s;
            }
            &:after {
                opacity: 1;
                transition: opacity 0.5s;
            }
            input {
                background-color: ${(props) => props.theme.colors.disabled};
                transition: background-color 0.5s;
            }
        }

        &.disabled{
            cursor: not-allowed;
            input{
                background-color: ${(props) =>
                    props.theme.colors.disabled} !important;
                pointer-events: none;
            }
        }

        &.olho{
            
            input[type="password"]{
                -webkit-text-security:disc;
            }
            button{
                position: absolute;
                top: 50%;
                right: 4px;
                transform: translate(-50%, -50%);
                cursor: pointer;
                z-index: 30;
                /* background:${({ theme }) => theme.colors.white}; */
                background-color: transparent !important;
                padding: 2px;
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%) rotate(-45deg) scale(0);
                    width: 26px;    
                    height: 2px;
                    background-color: ${({ theme }) => theme.colors.secondary};
                    transition: transform 0.5s;
                }
                &.active{
                    &:before{
                        transform: translate(-50%,-50%) rotate(-45deg) scale(1);
                        transition: transform 0.5s;
                    }
                }
            }
        }
        &.copiar{
            button{
                position: absolute;
                top: 50%;
                right: 4px;
                transform: translate(-50%, -50%);
                cursor: pointer;
                z-index: 30;
                font-weight: bold;
                background:${({ theme }) => theme.colors.white};
                color: ${({ theme }) => theme.colors.secondary};
                padding: 5px;
                width: 22px;
            }
        }

        &.chave {
            &.chave-campanha{
                input{
                    text-transform: uppercase;
                }
            }

            &:before {
                -webkit-mask: url(${imgChave}) no-repeat;
                width: 24px;
                height: 24px;
                z-index: 10;
            }
        }

        &.valor {
            input {
            }
            &:before {
                -webkit-mask: url(${imgValor}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }

        &.cartao {
            input {
            }
            &:before {
                -webkit-mask: url(${imgCartao}) no-repeat;
                width: 21px;
                height: 16px;
            }
        }

        &.cupom {
            input {
                transition:  all .5s;
            }
            &:before {
                -webkit-mask: url(${imgCupom}) no-repeat;
                top: 56%;
                width: 24px;
                height: 24px;
                transition:  all .5s;
            }
        }
        
        &.user {
            &:before {
                -webkit-mask: url(${imgUser}) no-repeat;
                width: 17px;
                height: 20px;
                left: 28px;
            }
        }

        &.endereco {
            &:before {
                -webkit-mask: url(${imgEndereco}) no-repeat;
                width: 24px;
                height: 24px;
                left: 28px;
            }
        }

        &.casa {
            &:before {
                -webkit-mask: url(${imgCasa}) no-repeat;
                width: 24px;
                height: 24px;
                left: 28px;
            }
        }
        
        &.senha {
            &:before {
                -webkit-mask: url(${imgSenha}) no-repeat;
                width: 17px;
                height: 20px;
                left: 28px;
            }
        }

        &.chave-cartao {
            &:before {
                -webkit-mask: url(${imgChaveCartaoAtivar}) no-repeat;
                width: 24px;
                height: 24px;
                left: 28px;
            }
        }

        &.cartao-ativar {
            &:before {
                -webkit-mask: url(${imgCartaoAtivar}) no-repeat;
                width: 21px;
                height: 16px;
                left: 28px;
            }
        }

        &.calendario {
            &:before {
                -webkit-mask: url(${imgCalendario}) no-repeat;
                width: 21px;
                height: 22px;
                left: 28px;
            }
        }

        &.celular {
            &:before {
                -webkit-mask: url(${imgCelular}) no-repeat;
                width: 21px;
                height: 22px;
                left: 28px;
            }
        }

        &.campanha {
            &:before {
                -webkit-mask: url(${imgCampanha}) no-repeat;
                width: 20px;
                height: 16px;
                left: 25px;
                top: 51%;
            }
        }

        &.contato {
            &:before {
                -webkit-mask: url(${imgContato}) no-repeat;
                width: 21px;
                height: 16px;
                left: 28px;
            }
        }

        &.autoFalante {
            &:before {
                -webkit-mask: url(${imgAutoFalante}) no-repeat;
                width: 21px;
                height: 25px;
                left: 28px;
            }
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 24px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            z-index: 20;
            transition: background-color 0.5s;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 27px;
            right: 19px;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            -webkit-mask: url(${imgCheck});
            width: 20px;
            height: 20px;
            z-index: 20;
            opacity: 0;
            transition: opacity 0.5s;
        }

        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0;
            -webkit-appearance: none;  
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        }

        input[placeholder="apelido"]{
            text-transform: uppercase;
        }
        input[placeholder="APELIDO"]{
            text-transform: uppercase;
        }
        input[placeholder="Campanha"]{
            text-transform: uppercase !important;
        }
        input[placeholder="SENHA"]{
            text-transform: unset !important;
        }
        input[placeholder="CRIAR SENHA DE ACESSO"]{
            text-transform: unset !important;
        }
        input[placeholder="CONFIRMAR SENHA"]{
            text-transform: unset !important;
        }

        input[name="nome_cpf"], input[name="nome_da_mae"]{
                &::placeholder{
                    font-size: 0.50rem;
                }
            }

        @media(max-width: 480px){
            input[name="nome_cpf"], input[name="nome_da_mae"]{
                &::placeholder{
                    font-size: 0.50rem;
                }
            }
        }

        input[name="email"], input[name="email_confirmation"]{
            text-transform: unset !important;
        }

        input[type="date"]:before {
            
            }
            input[type="date"]:focus:before {
            content: '' !important;
            }

        select::-ms-expand {
            display: none;
            -webkit-appearance: none;
        }

        select{
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        }
        

        input, select, textarea {
            background: transparent;
            border: none;
            border: 1px solid ${(props) => props.theme.colors.secondarybright};
            border-radius: 600px;

            font-style: normal;
            font-weight: 500;
            font-size: ${(props) => props.theme.fonts.xss};
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark} !important;

            display: flex;
            align-items: center;

            height: 56px;
            width: 100%;

            padding-left: 3.5rem;
            padding-right: 2.5rem;

            transition:  all .5s;
            text-transform: uppercase;
        }

        textarea{
            height: 244px;
            border-radius: 24px;
            padding: 22px 24px;
        }
    }

    .container-element-backoffice{
        label{
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 12px */
            letter-spacing: 0.96px;    
            margin-bottom: 8px;
            text-transform: uppercase;
        }
        


        &.disabled{
            input{
                opacity: 0.5;
                cursor: not-allowed;
                transition: background-color 0.5s;
            }
        }
    }

    .container-input-backoffice{
        margin-bottom: 19px;
        .container-input{
            &:before{
                display: none;
            }
        }
        input{
            border-radius: 8px;
            background-color: #E9E9E9 !important;
            width: 100%;
            height: 48px;
            border: none;
            padding: 0 16px;

            color: #767B7F;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 12px */
            letter-spacing: 0.96px;
            &:focus{
                background-color: #fff !important;
                transition: .5s all;
            }
            &:disabled{
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }

    hr{
        border: 1px solid #E1E2E3;
    }

    .container-input-system {
        margin-bottom: 8px;
        position: relative;

        &.disabled{
            input{
                background-color: #eaf5eb !important;
                cursor: not-allowed;
                transition: background-color 0.5s;
            }
        }
        &.area {
            margin-bottom: 4px;
            input {
                border: 1px solid #e1e2e3;
                background-color: transparent;
            }
        }
        button {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            width: 32px;
            height: 32px;
            font-size: ${(props) => props.theme.fonts.xss};
            padding: 0px;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 55%;
            left: 21px;
            background-color: ${(props) => props.theme.colors.secondary};
            transform: translateY(-50%);
            z-index: 20;
            transition: background-color 0.5s;
        }

        &.cliente {
            &:before{
                -webkit-mask: url(${imgCliente}) no-repeat;
                width: 24px;
                height: 24px;
            }
            input {
                padding-left: 50px;
            }
        }

        &.comunicacao {
            &:before{
                -webkit-mask: url(${imgComunicacao}) no-repeat;
                width: 24px;
                height: 30px;
            }
            input {
                padding-left: 50px;
            }
        }

        &.carga {
            &:before{
                -webkit-mask: url(${imgCarga}) no-repeat;
                width: 24px;
                height: 24px;
            }
            input {
                padding-left: 50px;
            }
        }

        &.chave {
            input {
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgChave}) no-repeat;
                width: 24px;
                height: 24px;
                top: 48%;
                left: 19px;
            }
        }

        &.valor {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgValor}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }

        &.cartao {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgCartao}) no-repeat;
                width: 21px;
                height: 16px;
            }
        }

        &.cupom {
            input {
                
                padding-left: 50px;
                transition:  all .5s;
            }
            &:before {
                -webkit-mask: url(${imgCupom}) no-repeat;
                top: 56%;
                width: 24px;
                height: 24px;
                transition:  all .5s;
            }
        }
        
        &.user {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgUser}) no-repeat;
                width: 17px;
                height: 20px;
            }
        }
        
        &.senha {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgSenha}) no-repeat;
                width: 17px;
                height: 20px;
            }
        }

        &.calendario {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgCalendario}) no-repeat;
                width: 21px;
                height: 22px;
                left: 20px;
                top: 50%;
            }
        }

        &.celular {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgCelular}) no-repeat;
                width: 21px;
                height: 22px;
                left: 24PX;
            }
        }

        &.contato {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgContato}) no-repeat;
                width: 21px;
                height: 22px;
            }
        }

        &.telefone {
            input {
                
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgTelefone}) no-repeat;
                width: 21px;
                height: 22px;
                left: 20px;
                top: 23px;
            }
        }

        &.campanha {
            input {
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgCampanha}) no-repeat;
                width: 20px;
                height: 16px;
                left: 21px;
                top: 51%;
            }
        }

        &.busca {
            input {
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                left: 21px;
                top: 51%;
            }
        }
        
        &.estorno {
            input {
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgEstorno}) no-repeat;
                width: 22px;
                height: 17px;
                left: 21px;
                top: 51%;
            }
        }

        &.job {
            input {
                padding-left: 50px;
            }
            &:before {
                -webkit-mask: url(${imgJob}) no-repeat;
                width: 22px;
                height: 17px;
                left: 21px;
                top: 51%;
            }
        }

        &.select{
            position: relative;
            &:after{
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgSetaBaixo}) no-repeat;
                width: 14px;
                height: 8px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }

        input, textarea {
            height: 48px;
            width: 100%;
            border: 1px solid
                ${(props) => props.theme.colors.secondarybright};
            box-sizing: border-box;
            border-radius: 4px;
            
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark} !important;
            padding-left: 16px;
            padding-right: 50px;
            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }

        textarea{
            height: 95px;
            padding: 18px;
        }
    }

    .react-jinke-music-player{
        &.react-draggable{
            top: 3.8% !important;
            left: 5% !important;
        }
    }

    @media (max-width: 1280PX) {
        .container{
            width: 90%;
        }
    }

    .table{
        img{
            pointer-events: none !important;
        }
        .tr{
            &.header{
                padding-bottom: 36px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
                margin-bottom: 35px;
            }

            &.body{
                height: 57px;
                border-bottom: 1px solid #E1E2E3;
            }
        }
    }

    div[style="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"]{
        display: none;
    }

    .MuiDataGrid-toolbarContainer{
        width: 500px;
        justify-content: space-between;
        padding-bottom: 32px !important;
    }

    .MuiDataGrid-rowCount {
        display: none !important;
    }

    .MuiAutocomplete-paper{
        z-index: 99999;
        background: red;
    }

    .button-olho {
        height: 20px;
    }

    .MuiDataGrid-panelContent{
        .MuiTypography-root{
            text-transform: uppercase !important;
        }
    }

    .swiper-pagination {
        &.swiper-pagination-bullets {
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                    background-color: ${(props) =>
                        props.theme.colors.secondary} !important;
                }
            }
        }
    }

    .content-table {
        position: relative;
        &.active {
            opacity: 0.7;
            cursor: not-allowed;
            .container-loading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    

    .container-scan{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        /* height: calc(var(--vh, 1vh) * 100); */
        height: 100vh;
        
        .header{
            width: calc(100% - 300px);
            /* height: calc(var(--vh, 1vh) * 100); */
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 5% 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            background-color: ${(props) => props.theme.colors.white};
            a{
                width: fit-content;
                img{
                    transform: rotate(90deg);
                }
            }

            .container-logo{
                height: 36px;
                width: 159px;
                position: absolute;
                transform: rotate(90deg) translateX(-50%);
                bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }


        .container-text{
            width: calc(100% - 300px);
            /* height: calc(var(--vh, 1vh) * 100); */
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 5% 0px;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: ${(props) => props.theme.colors.white};
            p{
                height: 36px;
                width: 95vh;
                position: absolute;
                transform: rotate(90deg) translateX(-50%);
                bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
`;
