import { useContext, createContext, useState } from "react";
import { toast } from "react-toastify";

interface useInfraProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useInfraData {
    files: KeyFile | null;
    setFiles(values: any): void;
    handleFiles(File: File, name: string, width: number, height: number): void;
    handleFilesArq(file: File): void;

    arqFiles: File | null;
    setArqFiles(values: any): void;
}

interface KeyFile {
    [key: string]: File;
}

const UseFileContext = createContext<useInfraData>({} as useInfraData);

export function FileProvider(props: useInfraProviderProps) {
    const { children } = props;

    const [files, setFiles] = useState<any>(null);
    const [arqFiles, setArqFiles] = useState<any>(null);

    function handleFiles(
        File: File,
        name: string,
        width: number,
        height: number
    ) {
        let img = new Image();
        img.src = URL.createObjectURL(File);

        img.onload = () => {
            if (img.width <= width && img.height <= height) {
                var file = {} as File;
                file[name] = File;
                setFiles({ ...files, ...file });
            } else {
                toast.error(`Dimensão permitida ${width}px x ${height}px`);
            }
        };
    }

    function handleFilesArq(file: File) {
        setArqFiles(file);
    }

    return (
        <UseFileContext.Provider
            value={{
                handleFiles,
                handleFilesArq,
                files,
                setFiles,
                arqFiles,
                setArqFiles,
            }}
        >
            {children}
        </UseFileContext.Provider>
    );
}

export function useFile() {
    const context = useContext(UseFileContext);
    return context;
}
