import { Box, Text } from "alisson-application";
import { FormAlterarSenha } from "./form";
import { Container } from "./styles";
export function AlterarSenha() {
    return (
        <Container>
            <div className="container-header">
                <Text as="p">
                    Preencha os campos abaixo <br />e clique para alterar.
                </Text>
            </div>

            <Box px="5%">
                <FormAlterarSenha />
            </Box>
        </Container>
    );
}
