import { Text } from "alisson-application";
import imgCheck from "../../../assets/img/check-auth-success.svg";
import { Container } from "./styles";
import { Button } from "../../Desktop/Button";
import { UseLogin } from "../../../Hook/user/UseLogin";

export function Sucesso() {
    const { forceLogin } = UseLogin();

    return (
        <Container>
            <div className="container-img">
                <img src={imgCheck} alt="" />
            </div>

            <div className="container-text">
                <Text as="p" color="secondary">
                    Sua senha foi alterada <br /> com sucesso!
                </Text>
            </div>

            <Button onClick={forceLogin}>EFETUAR LOGIN</Button>
        </Container>
    );
}
