import { WhatsApp } from "./whatsApp";
import { Notificacao } from "./notificacao";
import { Sms } from "./sms";
import { PopUp } from "./popup";
import { DetalheComunicacao } from ".";

interface EsqProps extends DetalheComunicacao {
    count: number;
}

export function Esq(props: EsqProps) {
    switch (props.tipo) {
        case "WHATSAPP":
            return <WhatsApp {...props} />;

        case "NOTIFICACAO":
            return <Notificacao {...props} />;

        case "SMS":
            return <Sms {...props} />;

        case "POPUP":
            return <PopUp {...props} />;

        default:
            return <WhatsApp {...props} />;
    }
}
