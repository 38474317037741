import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import { useBackoffice } from "../../../../../Hook/system/useBackoffice";
import { Header } from "../Header";
import { SelectDate } from "../Select";
import { options } from "../../../../NovoSelect";
import { Input } from "../Input";
import { Container } from "./styles";
import { Flex } from "alisson-application";
import { IOSSwitch } from "../../../Switch";

export function Email() {
    const { state, createObjBackoffice, loading, updateEmail, createEmail } =
        useBackoffice();
    const removeKeys = ["isActive"];
    const { arrKey, arrKeyRemoved, values } = createObjBackoffice(
        state.emails,
        "emails",
        removeKeys
    );
    const emailTypeOptions: options[] = [
        {
            value: "1",
            label: "PERSONAL",
        },
    ];
    const btnRef = useRef<HTMLButtonElement>(null);

    async function handleSave() {
        btnRef.current?.click();
    }

    return (
        <Container>
            <Header title="email" icon="email" onSave={handleSave} />
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={values}
                onSubmit={async (values, actions) => {
                    let { id, ...rest } = values;
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [_, active] = arrKeyRemoved[0];
                    rest = {
                        ...rest,
                        isActive: active as unknown as boolean,
                    } as any;

                    if (state.emails.length === 0) {
                        await createEmail(rest);
                    } else {
                        await updateEmail(id as number, rest);
                    }
                }}
            >
                {(props) => {
                    const { setFieldValue, values } = props;
                    return (
                        <Form>
                            {arrKey.map((item) => {
                                const [key] = item;

                                if (key === "emailType") {
                                    return (
                                        <Field
                                            component={SelectDate}
                                            name={key}
                                            options={emailTypeOptions}
                                            disabled={loading}
                                        />
                                    );
                                }
                                return (
                                    <Field
                                        component={Input}
                                        name={key}
                                        disabled={loading}
                                    />
                                );
                            })}

                            <div className="isActive">
                                {arrKeyRemoved.map((item) => {
                                    const [key] = item;
                                    const checked = values[key] ? true : false;
                                    return (
                                        <Flex alignItems="inherit" key={key}>
                                            <small>{key}</small>
                                            <IOSSwitch
                                                disabled={loading}
                                                checked={checked}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        key,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        </Flex>
                                    );
                                })}
                            </div>

                            <button hidden ref={btnRef} type="submit"></button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
