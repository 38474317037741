import { ChangeEvent } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import { usePassos } from "../../../Hook/UsePassos";

import { CartaoAvulso, cartao, validationCartao } from "./config";
import { FormStyles } from "./styles";

interface FormCartaoProps {
    setValue: (value: string) => void;
}

export function FormCartao(props: FormCartaoProps) {
    const { setValue } = props;
    const { nextPasso, setDados } = usePassos();

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={cartao}
                validationSchema={validationCartao}
                onSubmit={(values, actions) => {
                    setDados(values);
                    actions.resetForm();
                    nextPasso(3);
                }}
            >
                {(props: FormikProps<CartaoAvulso>) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div
                                className={`container-input cartao-ativar ${
                                    props.errors.acg_account_id &&
                                    props.touched.acg_account_id
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="acg_account_id"
                                    type="tel"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setFieldValue(
                                            "acg_account_id",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        );

                                        setValue(
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        );
                                    }}
                                    maxLength={8}
                                    value={values.acg_account_id}
                                    autoComplete="new-password"
                                    placeholder="CONTA"
                                />
                            </div>
                            <ErrorMessage
                                name="acg_account_id"
                                component="span"
                            />

                            <div className="mt-auto">
                                <Button
                                    mt="16px"
                                    type="submit"
                                    disabled={!props.isValid}
                                >
                                    ADICIONAR CARTÃO
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
