import styled from "styled-components";

export const Container = styled.div``;

export const FormStyles = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    .container-date-time {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px;
    }
`;
