import { useState } from "react";
import { motion } from "framer-motion";

import { FormInfra } from "./form";
import { Sucess } from "./sucess";
import { Failure } from "./failure";

export function NovaCargaInfra() {
    const [sucess, setSucess] = useState<boolean | null>(null);

    return (
        <>
            {sucess === null && <FormInfra setSucess={setSucess} />}
            {sucess === true && (
                <motion.div
                    animate={{ scale: [0.1, 1] }}
                    transition={{ duration: 0.5 }}
                >
                    <Sucess setSucess={setSucess} />
                </motion.div>
            )}
            {sucess === false && (
                <motion.div
                    animate={{ scale: [0.1, 1] }}
                    transition={{ duration: 0.5 }}
                >
                    <Failure setSucess={setSucess} />
                </motion.div>
            )}
        </>
    );
}
