import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { NovoSaldo } from "../../NovoSaldo";
import { useUser } from "../../../Hook/user/UseUser";

export function ModalNovoSaldo(props: ModalConfig) {
    const { open, handleClose } = props;
    const { novoSaldo } = useUser();

    return (
        <ModalBottom
            open={open}
            handleClose={async () => {
                handleClose();
                await novoSaldo();
            }}
        >
            <NovoSaldo />
        </ModalBottom>
    );
}
