import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { SegundaVia } from "../../../components/Mobile/SegundaVia";

export function ModalSegundaVia(props: ModalConfig) {
    const { handleClose, open } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <SegundaVia />
        </Modal>
    );
}
