import { Detalhe } from "../../../../components/System/Comunicacao/detalhe";
import { ComunicacaoProvider } from "../../../../Hook/system/useComunicacao";

export function PageDetalheComunicacao() {
    return (
        <ComunicacaoProvider>
            <Detalhe />
        </ComunicacaoProvider>
    );
}
