import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-error": {
        zIndex: 9999,
    },
    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        overflowY: "unset",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "10px",
    },
    ".container": {
        width: "100%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "327px",
            minWidth: "auto",
        },
    },
}));
