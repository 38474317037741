import { Text } from "alisson-application";
import { FieldProps } from "formik";
import { memo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Permissions } from "../../Permission";

import { toast } from "react-toastify";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { Button } from "../../Button";
import { ArrayArea } from "./form";

interface CamposProps extends FieldProps {}

function Campos(props: CamposProps) {
    const { form } = props;
    const { setFieldValue } = form;

    const [tempArea, setTempArea] = useState<ArrayArea[]>([]);
    const [foundArea, setFoundArea] = useState<ArrayArea|undefined>();
    const [areas, setAreas] = useState<ArrayArea[]>([]);
    const ref = useRef<HTMLInputElement>(null);
    const uniqueValuesSet = new Set();

    function handleAddArea() {
        const value = ref.current?.value;

        if (value) {
            const foundArea = areas.find(area => area.area === value);
            if(foundArea?.area) return toast.error(`Está área já existe ${value}`);;
            
            setAreas([...areas, { id: uuidv4(), area: value }]);
            const dados = [
                ...tempArea,
                ...areas,
                { id: uuidv4(), area: value },
            ].map((area) => area.area);

            setFieldValue("custom_fields", [
                ...dados.filter((c, index) => dados.indexOf(c) === index),
            ]);
            ref.current.value = "";
        }
    }

    function handleRemoveArea(id: string) {
        const updatedAreas = areas.filter((a) => a.id !== id);
        const dados = [...updatedAreas].map((area) => area.area);

        setFieldValue("custom_fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        setAreas(updatedAreas);
        setTempArea(updatedAreas);
    }

    function handleChange(newValue: string, id: string) {
        const foundArea = areas.find(area => area.area === newValue);
        setFoundArea(foundArea);

        const updateArea = areas.filter((area) => {
            if (area.id === id) {
                area.area = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempArea].filter((obj) => {
            // check if name property value is already in the set
            const isPresentInSet = uniqueValuesSet.has(obj.id);

            // add name property value to Set
            uniqueValuesSet.add(obj.id);

            // return the negated value of
            // isPresentInSet variable
            return !isPresentInSet;
        });

        const dados = [...tempArea, ...areas].map((area) => area.area);

        setFieldValue("custom_fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        setTempArea(filteredArr);
    }

    return (
        <>
            <div className="container-input-system">
                <input type="text" placeholder="NOME DO CAMPO" ref={ref} />
                <Permissions role="campanha.store">
                    <Button onClick={handleAddArea} type="button">
                        ok
                    </Button>
                </Permissions>
            </div>

            <Text as="span" fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </Text>
            <div className="itens-areas">
                {areas.map((area) => {
                    return (
                        <div
                            key={area.id}
                            className="container-input-system area"
                        >
                            <input
                                type="text"
                                onChange={(e) => {
                                    handleChange(e.target.value, area.id);
                                }}
                                value={area.area}
                            />
                            <Permissions role="campanha.store">
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(area.id)}
                                >
                                    <img src={imgX} alt="" />
                                </button>
                            </Permissions>
                        </div>
                    );
                })}
                
                <br />
                {foundArea?.area ? <span className="error">Campo {foundArea.area} não pode ser usado mais de uma vez!</span> : null}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
