import React from "react";
import {
    ChannelContainer,
    CustomBtn,
    DisabledInput,
    InputSection,
} from "./styles";

interface IChannelProps {
    onSend: () => void;
    text: string;
    icon: React.ReactNode;
    value: string;
}

const Channel = ({ onSend, text, icon, value }: IChannelProps) => {
    function handleSend() {
        if (onSend) onSend();
    }

    return (
        <ChannelContainer>
            <fieldset>
                <legend>ou</legend>
            </fieldset>

            {icon}
            <InputSection>
                <span>{text}</span>
                <DisabledInput disabled value={value} />
            </InputSection>
            <CustomBtn onClick={handleSend}>Reenviar</CustomBtn>
        </ChannelContainer>
    );
};

export default Channel;
