import styled from "styled-components";
import { Flex } from "alisson-application";

export const Container = styled(Flex)`
    .container {
        width: 1471px;
        height: 81px;
        margin: 0 auto;
        justify-content: center;
    }

    @media (max-width: 1366px) {
        .container {
            width: 90%;
        }
    }

    @media (max-width: 480px) {
        .container {
            div {
                width: 100%;
                text-align: center;
                margin: 0px;
            }
            .logo-yetz {
                display: none;
            }
        }
    }
`;
