import { Formik, Form, ErrorMessage } from "formik";
import { Box } from "alisson-application";
import { motion } from "framer-motion";
import Mask from "react-input-mask";

import { UseLogin } from "../../../Hook/user/UseLogin";

import { Button } from "../Button";
import { campanhaSelectForm, validationSelectCampanha } from "./config";
import Select from "./inputSelect";
import { useFormLogin } from "../../../Hook/user/useFormLogin";

export function SelectCampanha() {
    const { loginWithCampanha, campanhas } = UseLogin();
    const { state } = useFormLogin();
    const optionsCampanha = campanhas.map((campanha) => {
        return {
            label: campanha.chave,
            value: campanha.id,
        };
    });

    return (
        <Formik
            validateOnMount
            enableReinitialize
            initialValues={{ ...campanhaSelectForm, ...state }}
            validationSchema={validationSelectCampanha}
            onSubmit={(values, actions) => {
                loginWithCampanha(parseInt(values.selectCampanha));
            }}
        >
            {(props) => {
                const { handleChange, setFieldValue, values } = props;
                const { document } = values;
                //21342851099

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.2,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Mask
                                mask="999.999.999-99"
                                placeholder="CPF"
                                name="document"
                                type="tel"
                                onChange={handleChange}
                                value={document}
                            />
                        </motion.div>
                        <ErrorMessage name="document" component="span" />

                        <Box mb="40px">
                            <motion.div
                                animate={{
                                    scale: [0.2, 1],
                                }}
                                transition={{
                                    duration: 0.2,
                                }}
                                className={`container-input chave`}
                            >
                                <Select
                                    label="Campanha"
                                    options={optionsCampanha}
                                    onChange={(value) =>
                                        setFieldValue(
                                            "selectCampanha",
                                            value.value + ""
                                        )
                                    }
                                />
                            </motion.div>
                            <ErrorMessage
                                name="selectCampanha"
                                component="span"
                            />
                        </Box>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.4,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                disabled={!props.isValid}
                                className="login"
                            >
                                ENTRAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
