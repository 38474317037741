import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ModalValidacaoEmail from "../../ModalBottom";
import { Modal } from "./modal";
import { Loader } from "../../Loader";
import { Container } from "./styles";
import { api } from "../../../services";

interface HistoryProps {
    id: string;
}

export function ConfirmaEmail() {
    const history = useHistory<HistoryProps>();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const id = history?.location?.pathname?.replace(
                    /\/confirma-email\//,
                    ""
                );
                if (!id) {
                    history.push("/");
                    return;
                }

                await api.post("check-email-confirmation", {
                    code: id,
                });

                setLoader(false);
            } catch (error) {
                history.push("/");
            }
        }
        fetchData();
    }, [history]);

    return (
        <>
            <Container>{loader && <Loader width={100} />}</Container>
            <ModalValidacaoEmail
                open={!loader}
                handleClose={() => history.push("/dashboard")}
            >
                <Modal />
            </ModalValidacaoEmail>
        </>
    );
}
