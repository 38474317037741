import { Box, Text } from "alisson-application";
import { Container } from "./styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import imgSetaBaixo from "../../../assets/img/seta-baixo.svg";
import { IOSSwitch } from "../Switch";

interface AccordionProps {
    title: string;
    children: React.ReactNode;

    check?: boolean;
    checkOnChange?: (
        event: React.ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => void;
    checked?: boolean;
}

export function SimpleAccordion(props: AccordionProps) {
    const { title, children, check = false } = props;
    return (
        <Container>
            <AccordionSummary
                expandIcon={<img src={imgSetaBaixo} alt="" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Text>{title}</Text>

                {check && (
                    <Box ml="auto" mr="16px">
                        <IOSSwitch
                            checked={props.checked}
                            onChange={props.checkOnChange}
                        />
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Container>
    );
}
