import { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Text } from "alisson-application";
import { phoneNumber } from "../../util/helper";
import { useUser } from "../../Hook/user/UseUser";
import { useFaleConosco } from "../../Hook/user/UseFaleConosco";
import { Button } from "../Mobile/Button";
import imgClip from "../../assets/img/clip.svg";
import {
    FaleConosco,
    faleConosco,
    validationFaleConosco,
    values,
} from "./config";
import { FormStyles } from "./styles";
import { toast } from "react-toastify";

interface FormFaleConoscoProps {
    handleSuccess: () => void;
}

export type keyOfFaleConosco<T> = keyof T;

export function FormFaleConosco(props: FormFaleConoscoProps) {
    const { user } = useUser();
    const { handleSuccess } = props;
    const { enviarMensagem } = useFaleConosco();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState<FaleConosco>(values);
    const [file, setFile] = useState<File | null>();
    useEffect(() => {
        if (user.document) {
            setInitialValues({
                ...faleConosco,
                name: user.name,
                phone: phoneNumber(user.phone_number),
                email: user.email,
            });
        }
    }, [user]);

    return (
        <FormStyles>
            <p>
                Aqui você pode nos enviar sugestões, dúvidas ou reclamações
                sobre o funcionamento da Plataforma. Caso esteja com problemas
                no cadastro, envie um documento atualizado.
            </p>
            {user?.document ? (
                <Formik
                    enableReinitialize
                    validateOnMount
                    initialValues={initialValues}
                    validationSchema={validationFaleConosco}
                    onSubmit={async (values, actions) => {
                        try {
                            setLoading(true);
                            values = {
                                ...values,
                                email: user?.pessoa?.email?.toLowerCase(),
                            };

                            const formData = new FormData();

                            const keys = Object.keys(
                                values
                            ) as keyOfFaleConosco<FaleConosco>[];

                            keys.forEach((key) => {
                                formData.append(key, values[key]);
                            });

                            formData.append("file", file ? file : "");

                            const response = await enviarMensagem(formData);
                            if (response.status === 200) {
                                handleSuccess();
                                actions.resetForm();
                            }
                            setLoading(false);
                        } catch (error) {
                            setLoading(false);
                            toast.error("Algo de errado aconteceu");
                        }
                    }}
                >
                    {(props: FormikProps<FaleConosco>) => {
                        const { values, setFieldValue } = props;
                        return (
                            <Form>
                                <div
                                    className={`container-input ${
                                        props.errors.name && props.touched.name
                                            ? "error"
                                            : ""
                                    } user ${
                                        values.name ? "active disabled" : ""
                                    }`}
                                >
                                    <Field name="name" placeholder="NOME" />
                                </div>
                                <ErrorMessage name="name" component="span" />

                                <div
                                    className={`container-input ${
                                        props.errors.phone &&
                                        props.touched.phone
                                            ? "error"
                                            : ""
                                    } celular ${
                                        values.phone ? "active disabled" : ""
                                    }`}
                                >
                                    <Field
                                        name="phone"
                                        placeholder="TELEFONE"
                                    />
                                </div>
                                <ErrorMessage name="phone" component="span" />

                                <div
                                    className={`container-input ${
                                        props.errors.email &&
                                        props.touched.email
                                            ? "error"
                                            : ""
                                    } contato ${
                                        values.email ? "active disabled" : ""
                                    }`}
                                >
                                    <Field name="email" placeholder="E-MAIL" />
                                </div>
                                <ErrorMessage name="email" component="span" />

                                <div
                                    className={`container-input ${
                                        props.errors.subject &&
                                        props.touched.subject
                                            ? "error"
                                            : ""
                                    } autoFalante`}
                                >
                                    <Field
                                        name="subject"
                                        placeholder="Assunto"
                                    />
                                </div>
                                <ErrorMessage name="subject" component="span" />

                                <div
                                    className={`container-input ${
                                        props.errors.description &&
                                        props.touched.description
                                            ? "error"
                                            : ""
                                    }`}
                                >
                                    <Field
                                        component="textarea"
                                        name="description"
                                        placeholder="MENSAGEM"
                                    />
                                </div>
                                <ErrorMessage
                                    name="description"
                                    component="span"
                                />

                                <label htmlFor="arquivo" className="arquivo">
                                    <img src={imgClip} alt="" />
                                    <Text as="p">
                                        {file?.name
                                            ? file?.name
                                            : "insira seu anexo (se DESEJAR)"}
                                    </Text>
                                    <input
                                        type="file"
                                        id="arquivo"
                                        accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.xm"
                                        onChange={(e) => {
                                            if (e.target.files?.length) {
                                                const file = e.target.files[0];
                                                const fileSizeInMB =
                                                    file.size / (1024 * 1024) +
                                                    "";
                                                if (
                                                    parseInt(fileSizeInMB) > 25
                                                ) {
                                                    toast.error(
                                                        "O arquivo deve ter no máximo 25MB."
                                                    );
                                                    return;
                                                }
                                                setFile(file);
                                                setFieldValue(
                                                    "recipientes",
                                                    "ok"
                                                );
                                            }
                                        }}
                                    />
                                </label>

                                <Text as="p" className="erro-file">
                                    Arquivos aceitos: jpg, png, pdf e mp4 com
                                    até 25mb.
                                </Text>

                                <Button
                                    isLoading={loading}
                                    disabled={!props.isValid}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    type="submit"
                                    mt="36px"
                                >
                                    enviar mensagem
                                </Button>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                <></>
            )}
        </FormStyles>
    );
}
