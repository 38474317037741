import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import imgCardCredito from "../../../assets/img/card-creditos.svg";
import imgTransferirCredito from "../../../assets/img/transferir-credito.svg";
import imgPlayerCredito from "../../../assets/img/player-credito.svg";
import { Button } from "../Button";
import { Container } from "./styles";

interface AlertaCreditoAdicionadoProps {
    handleClose: () => Promise<void>;
}

export function AlertaCreditoAdicionado(props: AlertaCreditoAdicionadoProps) {
    const { handleClose } = props;
    const { state, setState } = UseModal();
    const history = useHistory();

    function openModalTutorial() {
        setState({
            ...state,
            alertaCreditoAdicionado: false,
            tutorialTransferir: true,
        });
    }

    return (
        <Container>
            <div className="container-img">
                <img src={imgCardCredito} alt="" />
            </div>

            <div className="container-text">
                <h4>Créditos foram adicionados à sua carteira YetzPay! </h4>
            </div>

            <div className="container-descricao">
                <div className="container-descricao-img">
                    <img src={imgTransferirCredito} alt="" />
                </div>

                <div className="container-descricao-text">
                    <p>Quando desejar, </p>
                    <h4>TransfIRA para o cartão yetpay </h4>
                </div>
            </div>

            <div className="container-btn-video">
                <button onClick={openModalTutorial}>
                    <img src={imgPlayerCredito} alt="" />
                    <p>Veja como transferir</p>
                </button>
            </div>

            <Button
                spinerColor="primary"
                spiner="BallTriangle"
                mb="12px"
                onClick={async () => {
                    const width = window.screen.width;
                    if (width <= 480) {
                        setState({
                            ...state,
                            alertaCreditoAdicionado: false,
                        });
                        history.push("/solicitar-resgate");
                    } else {
                        setState({
                            ...state,
                            solicitarResgate: true,
                            alertaCreditoAdicionado: false,
                        });
                    }
                    await handleClose();
                }}
            >
                TRANSFERIR AGORA
            </Button>

            <div className="container-close-modal">
                <button
                    onClick={async () => {
                        setState({
                            ...state,
                            alertaCreditoAdicionado: false,
                        });
                        await handleClose();
                    }}
                >
                    Fechar e transferir depois
                </button>
            </div>
        </Container>
    );
}
