import imgDownload from "../../../assets/img/dowload.svg";
import { toast } from "react-toastify";
import { api } from "../../../services";

interface ExportProps {
    ano: number;
    mes: number;
}

export function Export(props: ExportProps) {

    async function exportar() {
        try {
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(
                `reports/serpro-requests-count-export?year=${props.ano}&month=${props.mes}`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            toast.error("Erro ao exportar arquivo");
        }
    }
    
    return ( 
        <button
            onClick={exportar}
        >
            <img src={imgDownload} alt="" />
        </button>
   )
}