import { useContext, createContext } from "react";

import { toast } from "react-toastify";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { api } from "../../../../../../services";
import { AprovarData } from "../../Aprovar/Hook";
import { PedidoCredito } from "../../config";

interface useAprovadoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useAprovadoData {
    gePedido(page?: number): Promise<AprovarData>;
}

interface AprovadoData extends PaginationConfig {
    data: PedidoCredito[];
}

const UseAprovadoContext = createContext<useAprovadoData>(
    {} as useAprovadoData
);

export function AprovadoProvider(props: useAprovadoProviderProps) {
    const { children } = props;

    async function gePedido(page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const { data } = await api.get<AprovadoData>(
                `pedido-credito?page=${hasPage}&status=APROVADO`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as AprovarData;
        }
    }

    return (
        <UseAprovadoContext.Provider value={{ gePedido }}>
            {children}
        </UseAprovadoContext.Provider>
    );
}

export function useAprovado() {
    const context = useContext(UseAprovadoContext);
    return context;
}
