import React, { createContext, useContext, useState } from "react";
import { StateGlobalUser } from "../types";

interface UseStateUserProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseStatesUser {
    stateUser: StateGlobalUser;
    setStateUser: (state: StateGlobalUser) => void;

    salt: string;
    setSalt: (salt: string) => void;

    saltKeyUrl: string;
    setSaltKeyUrl: (salt: string) => void;

    imageSrc: string | null;
    setImageSrc(src: string): void;

    forceActivateAccountLogin: boolean;
    setForceActivateAccountLogin: (values: boolean) => void;
}

const UseStateUserContext = createContext<UseStatesUser>({} as UseStatesUser);

export function UseStateProvider(props: UseStateUserProps) {
    const { children } = props;
    const [salt, setSalt] = useState("");
    const [saltKeyUrl, setSaltKeyUrl] = useState("");
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [forceActivateAccountLogin, setForceActivateAccountLogin] =
        useState(false);
    const [stateUser, setStateUser] = useState<StateGlobalUser>({
        type: "",
        saldo: 0,
        number: "",
        validade: "",
        itens: [1, 2, 3],
        id: 0,
    });

    return (
        <UseStateUserContext.Provider
            value={{
                stateUser,
                setStateUser,
                salt,
                setSalt,
                setSaltKeyUrl,
                saltKeyUrl,
                imageSrc,
                setImageSrc,
                forceActivateAccountLogin,
                setForceActivateAccountLogin,
            }}
        >
            {children}
        </UseStateUserContext.Provider>
    );
}

export function useStateUser() {
    const context = useContext(UseStateUserContext);
    return context;
}
