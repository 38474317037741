import {
    useState,
    useContext,
    createContext,
    useEffect,
    useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { Myform as UpdateForm } from "../config";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../../../services";
import { ModalFormProps } from "../Modal/config";
import { HistoryProps } from "../../config";

interface ModalNovoClienteProps {
    initialValues: Myform;
    update(values: UpdateForm): Promise<void>;
    storeArea(values: CreateArea): Promise<void>;
    deleteArea(deletId: number): Promise<void>;
    updateArea(updateId: number): Promise<void>;
    deleteContato(): Promise<void>;

    storeContato(values: ModalFormProps): Promise<void>;
    updateContato(values: ModalFormProps): Promise<void>;

    setModalAlert: (value: boolean) => void;
    modalAlert: boolean;

    setModal: (value: boolean) => void;
    modal: boolean;

    setContatos: (value: ModalFormProps[]) => void;
    contatos: ModalFormProps[];

    setContato: (value: ModalFormProps) => void;
    contato: ModalFormProps;

    setContatoId: (value: number | null) => void;
    contatoId: number | null;

    setAreas: (value: ArrayArea[]) => void;
    areas: ArrayArea[];

    setModalAviso: (value: boolean) => void;
    modalAviso: boolean;

    setCliemtesEnUtilizacao: (value: ModalFormProps[]) => void;
    cliemtesEnUtilizacao: ModalFormProps[];

    loading: boolean;
    setLoading: (value: boolean) => void;
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface ArrayArea {
    label: string;
    value: string;
    id: number;
}

interface Contatos {
    id: number;
    cliente_area_id: number;
    nome: string;
    telefone: string;
    celular: string;
    email: string;
}

interface Areas {
    nome: string;
    contatos: Array<Contatos>;
}

interface Area {
    id: number;
    cliente_id: number;
    nome: string;
    created_at: null | string;
    updated_at: null | string;
    contatos: Contatos[];
}

interface CreateArea {
    nome: string;
}

export interface Myform {
    nome: string;
    razao_social: string;
    cnpj: string;
    areas: Areas[];
}

interface cliente {
    id: number;
    nome: string;
    razao_social: string;
    cnpj: string;
    created_at: null | string;
    updated_at: null | string;
    areas: Area[];
}

const Context = createContext<ModalNovoClienteProps>(
    {} as ModalNovoClienteProps
);

export function NovoClienteProvider(props: ProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalAviso, setModalAviso] = useState(false);
    const [modalAlert, setModalAlert] = useState(false);
    const [areas, setAreas] = useState<ArrayArea[]>([]);

    const [initialValues, setInitialValues] = useState<Myform>({} as Myform);
    const [contatos, setContatos] = useState<ModalFormProps[]>([]);
    const [contato, setContato] = useState<ModalFormProps>(
        {} as ModalFormProps
    );
    const [contatoId, setContatoId] = useState<number | null>(null);
    const [cliemtesEnUtilizacao, setCliemtesEnUtilizacao] = useState<
        ModalFormProps[]
    >([]);

    async function update(values: Myform) {
        try {
            await api.put(`cliente/${id}`, { ...values });
            toast.success("Cliente atualizado com sucesso!");
            history.push("/sistema/cliente");
        } catch (error: any) {
            toast.error("Erro ao atualizar cliente!");
            return error;
        }
    }

    async function storeArea(values: CreateArea) {
        setLoading(true);
        try {
            await api.post(`cliente/${id}/area`, { ...values });
            await getAreas();
            toast.success("Área cadastrada com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao cadastrar área!");
            return error;
        }
        setLoading(false);
    }

    async function updateArea(updateId: number) {
        setLoading(true);
        try {
            const area = areas.find((item) => item.id === updateId);

            await api.put(`cliente/${id}/area/${updateId}`, {
                nome: area?.label,
            });
            await getAreas();
            toast.success("Área atualizada com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao atualizar área!");
            return error;
        }
        setLoading(false);
    }

    async function deleteArea(deletId: number) {
        setLoading(true);
        try {
            await api.delete(`cliente/${id}/area/${deletId}`);
            await getAreas();
            toast.success("Área deletada com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao deletar área!");
            return error;
        }
        setLoading(false);
    }

    async function storeContato(values: ModalFormProps) {
        setLoading(true);

        const { area, ...rest } = values;

        try {
            await api.post(`cliente/${id}/area/${values.area.id}/contato`, {
                ...rest,
                area: area.label,
            });
            await getAreas();
            toast.success("Contato cadastrado com sucesso!");
        } catch (error: any) {
            toast.error("Erro ao cadastrar contato!");
            return error;
        }
        setLoading(false);
    }

    async function updateContato(values: ModalFormProps) {
        setLoading(true);

        const { area, ...rest } = values;

        try {
            if (contato.id) {
                await api.put(
                    `cliente/${id}/area/${values.area.id}/contato/${contato.id}`,
                    {
                        ...rest,
                        area: area.label,
                    }
                );
                await getAreas();
                setContato({} as ModalFormProps);
                toast.success("Contato atualizado com sucesso!");
            }
        } catch (error: any) {
            toast.error("Erro ao atualizar contato!");
            return error;
        }
        setLoading(false);
    }

    async function deleteContato() {
        setLoading(true);
        try {
            if (contato.id && contato.area.id) {
                await api.delete(
                    `cliente/${id}/area/${contato.area.id}/contato/${contato.id}`
                );
                await getAreas();
                setContato({} as ModalFormProps);
                toast.success("Contato deletado com sucesso!");
            }
        } catch (error: any) {
            toast.error("Erro ao deletar contato!");
            return error;
        }
        setLoading(false);
    }

    const getAreas = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get<cliente>(`/cliente/${id}`);

            const values = {
                nome: data.nome,
                razao_social: data.razao_social,
                cnpj: data.cnpj,
            } as Myform;

            const areas = [] as ArrayArea[];
            const contatos = [] as ModalFormProps[];

            data.areas.forEach((item) => {
                const area = { value: uuidv4(), label: item.nome, id: item.id };
                areas.push(area);
                item.contatos.forEach((item) => {
                    const contato = {
                        area: area,
                        celular: item.celular,
                        email: item.email,
                        id: item.id,
                        nome: item.nome,
                        telefone: item.telefone,
                    };
                    contatos.push(contato);
                });
            });

            setInitialValues({ ...values });
            setAreas(areas);
            setContatos(contatos);
        } catch (error) {
            return error;
        }
        setLoading(false);
    }, [id]);

    useEffect(() => {
        getAreas();
    }, [getAreas]);

    return (
        <Context.Provider
            value={{
                initialValues,
                update,
                storeArea,
                deleteArea,
                updateArea,
                deleteContato,

                storeContato,
                updateContato,

                modal,
                setModal,

                setContatos,
                contatos,

                contato,
                setContato,

                contatoId,
                setContatoId,

                setAreas,
                areas,

                setModalAviso,
                modalAviso,

                cliemtesEnUtilizacao,
                setCliemtesEnUtilizacao,

                modalAlert,
                setModalAlert,
                loading,
                setLoading,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
