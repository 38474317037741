import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0px 4px;
    width: 330px;
    margin: 0 auto;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
    &.error {
        input {
            border: 1px solid ${({ theme }) => theme.colors.failure} !important;
            transition: border 0.5s;
        }
    }
    &.active {
        input {
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            transition: border 0.5s;
        }
    }
    small {
        grid-column: 1 / 7;
        text-align: center;
        display: block;
        margin-top: 26px;
    }
    input {
        border: 1px solid #c6c8ca;
        border-radius: 4px;
        height: 51px;
        width: 100%;

        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 100%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        transition: border 0.5s;
    }
`;
