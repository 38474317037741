import { Text } from "alisson-application";

import { CardStyles } from "./styles";

interface CardProps {
    img: string;
    text: string;
    link: string;
    onClick: (value: string) => void;
}

export function Card(props: CardProps) {
    const { img, text, link, onClick } = props;
    return (
        <CardStyles onClick={() => onClick(link)}>
            <div className="container-thumbnail">
                <img src={img} alt="" />
            </div>

            <div className="container-text">
                <Text as="p" color="secondary">
                    {text}
                </Text>
            </div>
        </CardStyles>
    );
}
