import { Text } from "alisson-application";
import { Container } from "./styles";

interface DobleTextProps {
    title: string;
    text: string;
}
export function DobleText(props: DobleTextProps) {
    const { text, title } = props;

    return (
        <Container>
            <Text textTransform="uppercase" className="primeiro-item">
                {title}
            </Text>
            <Text className="segundo-item">{text}</Text>
        </Container>
    );
}
