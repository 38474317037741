import { Text } from "alisson-application";
import { StylesBloquear } from "./styles";
import { IOSSwitch } from "../Switch";
import { useCartao } from "../../../Hook/system/useCartao";
import { Loader } from "../../Loader";
import { useState } from "react";

interface BloquearProps {
    status: string;
    id: number;
    disabled: boolean;
}

export function Bloquear(props: BloquearProps) {
    const { disabled } = props;
    const { bloquearCartao, desbloquearCartao } = useCartao();
    const [loading, setLoading] = useState(false);
    const badStatus = ["CANCELADO", "INATIVO"].includes(props.status);
    const bloqueado = props.status === "BLOQUEADO";
    // const status = !badStatus.includes(props.status)
    //props.status === "BLOQUEADO"

    return (
        <StylesBloquear
            alignItems="center"
            className={`${badStatus || disabled ? "disabled" : ""} ${
                bloqueado ? "bloqueado" : "desbloquear"
            }`}
        >
            {loading ? (
                <Loader width={40} height={40} />
            ) : (
                <>
                    <IOSSwitch
                        checked={bloqueado}
                        onChange={async () => {
                            setLoading(true);
                            if (bloqueado) {
                                await desbloquearCartao(props.id);
                            } else {
                                await bloquearCartao(props.id);
                            }
                            setLoading(false);
                        }}
                    />

                    <Text
                        as="p"
                        ml="16px"
                        color={`${bloqueado ? "failure" : "secondary"}`}
                    >
                        {props.status}
                    </Text>
                </>
            )}
        </StylesBloquear>
    );
}
