import { Box, Text } from "alisson-application";
import imbBandeira from "../../../assets/img/bandeira.svg";
import { CardStyles } from "./styles";

interface CardProps {
    price: string;
    number: string;
    type: string;
    color: string;
}

export function Card(props: CardProps) {
    const { color, number, type } = props;

    return (
        <CardStyles background={color}>
            <Box className="container-text">
                <Text as="p" mb="9px">
                    {type}
                </Text>
                <Text as="span" fontSize="xss" mb="8px">
                    {number}
                </Text>
            </Box>
            <Box className="container-img">
                <img src={imbBandeira} alt="" />
            </Box>
        </CardStyles>
    );
}
