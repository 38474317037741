import { CartaoDigital } from "../../components/Mobile/CartaoDigital";
import { UsePassisProvider } from "../../Hook/UsePassos";

export function PageNovoCartaoFisico() {
    return (
        <UsePassisProvider>
            <CartaoDigital />
        </UsePassisProvider>
    );
}
