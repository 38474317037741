import { useTranslation } from "../../../contexts/Localization";
import { Link } from "react-router-dom";
import { Flex, Box, Text } from "alisson-application";
import { Container } from "./styles";
import imgUser from "../../../assets/img/user.png";
import { useUser } from "../../../Hook/user/UseUser";
import imgSeta from "../../../assets/img/seta-dir-verde.svg";

export function HeaderUser() {
    const { t } = useTranslation();
    const { user } = useUser();

    return (
        <Link to="/perfil">
            <Container className="container">
                <Flex width="100%">
                    <Box width="58px" height="58px">
                        <img
                            src={user?.image ? user?.image : imgUser}
                            alt=""
                            className="icon-user"
                        />
                    </Box>

                    <Flex flexDirection="column" ml="19px">
                        <Text as="span" mb="5px" color="primaryDark">
                            {t("Olá")}
                        </Text>
                        <Text
                            as="span"
                            fontWeight="bold"
                            fontSize="mdl"
                            textTransform="uppercase"
                        >
                            {user?.pessoa?.apelido}
                        </Text>
                    </Flex>
                </Flex>

                <button>
                    <img src={imgSeta} alt="" />
                </button>
            </Container>
        </Link>
    );
}
