import { AtivarCartao } from "./styles";
import { CheckBox } from "../CheckBox";
import { Button } from "../ButtonSlim";
import { usePassos } from "../../../Hook/UsePassos";

interface AtivarNotificacaoProps {
    setStateSms: (state: boolean) => void;
    onClick: () => void;
    loading?: boolean;
}

export function AtivarNotificacao(props: AtivarNotificacaoProps) {
    const { setStateSms, onClick, loading } = props;
    const { setDados, dados } = usePassos();

    return (
        <AtivarCartao className="container">
            <h1>Notificar transações via SMS</h1>

            <p>
                YETZPAY permite que você receba o resumo de cada transação dos
                cartões no seu celular, via SMS.
            </p>
            <br />
            <p>
                Você pode ativar essa funcionalidade marcando a caixa abaixo.
                Ela gera uma cobrança de <strong>R$2,00/mês,</strong> se houver
                saldo disponível.
            </p>

            <div className="check">
                <CheckBox
                    onChange={(e) => {
                        setStateSms(e.target.checked);
                        if (e.target.checked) {
                            setDados({ ...dados, acg_sms_service: 1 });
                        } else {
                            setDados({ ...dados, acg_sms_service: 0 });
                        }
                    }}
                    label="Ativar notificações e autorizar cobrança."
                />
            </div>

            <p>
                Você pode desativar essas notificações acessando os detalhes do
                cartão.
                <br />
                <br />
                Você não receberá as notificações a cada transação. tem certeza?
            </p>

            <Button
                mt="49px"
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
                onClick={onClick}
            >
                Avançar
            </Button>
        </AtivarCartao>
    );
}

interface AlertaNotificacaoProps {
    setStateSms: (state: boolean) => void;
    onClick: () => void;
    loading?: boolean;
}

export function AlertaNotificacao(props: AlertaNotificacaoProps) {
    const { setStateSms, onClick, loading } = props;
    const { setDados, dados } = usePassos();
    return (
        <AtivarCartao className="container">
            <h1>Atenção!</h1>

            <p>
                Você não ativou as notificações via SMS e não receberá as
                mensagens a cada transação efetuada no cartão.
            </p>
            <br />
            <p>Tem certeza que deseja prosseguir assim?</p>
            <br />
            <br />
            <p>
                Você poderá ativar essas notificações acessando os detalhes de
                cada cartão.
                <br/><br/><br/>
            </p>

            <Button
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
                onClick={onClick}
            >
                Avançar
            </Button>

            <div className="check">
                <CheckBox
                    onChange={(e) => {
                        setStateSms(e.target.checked);
                        if (e.target.checked) {
                            setDados({ ...dados, acg_sms_service: 1 });
                        } else {
                            setDados({ ...dados, acg_sms_service: 0 });
                        }
                    }}
                    label="Ativar notificações e autorizar cobrança."
                />
            </div>
        </AtivarCartao>
    );
}
