import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface ItensProps {
    id: string;
    handleChange: (id: string) => void;
    label: string;
    checked: boolean;
}

export function Item(props: ItensProps) {
    const { id, handleChange, label, checked } = props;
    return (
        <FormControlLabel
            label={`${label}`}
            control={
                <Checkbox
                    checked={checked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleChange(id);
                    }}
                />
            }
        />
    );
}
