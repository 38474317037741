import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { DocumentoErro } from "../../Mobile/DocumentoErro";

export function ModelBottomDocumentoErro(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <DocumentoErro />
        </ModalBottom>
    );
}
