const ChevronDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
    >
      <path d="M1.33615e-05 0.841685C-0.000493995 0.731553 0.0163183 0.622377 0.0494871 0.52042C0.0826559 0.418462 0.131529 0.325727 0.193303 0.247534C0.255264 0.169098 0.32898 0.106843 0.410201 0.0643582C0.491423 0.0218732 0.57854 -3.22174e-07 0.666528 -3.19692e-07C0.754516 -3.1721e-07 0.841633 0.0218732 0.922853 0.0643582C1.00407 0.106843 1.07779 0.169099 1.13975 0.247534L5.99864 6.35642L10.8575 0.247534C10.983 0.089955 11.1533 0.00142852 11.3308 0.00142852C11.4186 0.00142852 11.5057 0.0231619 11.5869 0.0653888C11.6681 0.107616 11.7418 0.169509 11.804 0.247534C11.8661 0.325559 11.9154 0.418188 11.9491 0.520133C11.9827 0.622077 12 0.731341 12 0.841686C12 1.06454 11.9295 1.27826 11.804 1.43584L6.47187 8.1305C6.40991 8.20894 6.33619 8.2712 6.25497 8.31368C6.17375 8.35617 6.08663 8.37804 5.99864 8.37804C5.91066 8.37804 5.82354 8.35617 5.74232 8.31368C5.6611 8.2712 5.58738 8.20894 5.52542 8.1305L0.193303 1.43584C0.131529 1.35764 0.0826559 1.26491 0.0494871 1.16295C0.0163183 1.06099 -0.000493992 0.951818 1.33615e-05 0.841685Z" />
    </svg>
  );
};

export default ChevronDown;
