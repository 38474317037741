import styled from "styled-components";
import imgCheck from "../../assets/img/check-auth.svg";

export const Container = styled.div`
    .container-alerta {
        text-align: center;
        margin-bottom: 23px;
        h4 {
            color: ${(props) => props.theme.colors.secondary}!important;
            font-weight: 600 !important;
            margin: 0px !important;
        }
        p {
            margin: 0px auto !important;
            color: #000 !important;
            font-size: 14px !important;
            font-weight: 400 !important;
        }
    }
    ul {
        padding-left: 35px;
        margin-bottom: 50px;
        li {
            p {
                font-size: 12px;
                text-align: left;
                margin: 0px !important;
            }
            position: relative;
            &.active {
                &:before {
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                }
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 55%;
                left: -30px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.failure};
                -webkit-mask: url(${imgCheck}) no-repeat;
                width: 14px;
                height: 10px;
                z-index: 20;
                transition: background-color 0.5s;
            }
        }
    }
`;
