import { useState, ChangeEvent, useEffect } from "react";
import { useCampanha } from "../../../../../Hook/system/useCampanha";
import { formataMoney } from "../../../../../util/helper";

import { Date } from "./table/config";
import { TaxaStyles } from "./styles";

interface QtdProps extends Date {
    rows: Date[];
}

export function Taxa(props: QtdProps) {
    const { campanhaById, updateProdutoCampanha } = useCampanha();
    const [value, setValue] = useState("");
    const ids =
        campanhaById?.produtos?.map((produto) => produto.produto.id) || [];

    const produtos = props.rows
        .filter((produto) => ids.includes(produto.id))
        .map((prod) => {
            return {
                produto_id: prod.id,
                taxa_cobranca: prod.taxa_cobranca,
                lim_quantidade: prod.qtd,
            };
        });

    async function updateTaxa(taxa: string) {
        taxa = taxa.replace(/[A-Z]\W\s/g, "");
        taxa = taxa.replace(/,/g, ".");

        const produtosUpdate = produtos.map((produto) => {
            if (produto.produto_id === props.id) {
                produto.taxa_cobranca = taxa;
                return produto;
            }

            return produto;
        });

        await updateProdutoCampanha(produtosUpdate);
    }

    useEffect(() => {
        const qtd: any = campanhaById?.produtos?.find(
            (produto) => produto.produto.id === props.id
        );

        if (qtd?.taxa_cobranca) {
            setValue(formataMoney(qtd.taxa_cobranca));
        }
    }, [campanhaById.produtos, props.id]);
    return (
        <TaxaStyles>
            <input
                disabled={!ids.includes(props.id)}
                value={value}
                placeholder="R$ 0,00"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const value =
                        parseInt(e.target.value.replace(/[^\d]+/g, "")) + "";

                    setValue(formataMoney(value));

                    if (value === "0") {
                        updateTaxa(formataMoney(value));
                        setValue(formataMoney("000"));
                    }

                    if (value.length === 4) {
                        updateTaxa(formataMoney(value));
                    }
                }}
            />
        </TaxaStyles>
    );
}
