import { Text } from "alisson-application";
import { Contador } from "../../../../Counter";
import { Container } from "./styles";

export function AntiFraude() {
    return (
        <Container>
            <div className="limit">
                <Text as="p">LIMITE DE CREDITOS POR DIA</Text>
                <Contador />
            </div>
            <div className="limit">
                <Text as="p">LIMITE DE CREDITOS RESGASTES POR DIA</Text>
                <Contador />
            </div>
        </Container>
    );
}
