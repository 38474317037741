import styled from "styled-components";
import imgNotificacao from "../../assets/img/notificacao.svg";

export const Container = styled.div`
    .container-notificacoes {
        padding-top: 32px;
        width: 90%;
        margin: 0 auto;
    }
`;

export const NotificacaoStyles = styled.div`
    padding-bottom: 32px;
    border-bottom: 1px solid ${(props) => props.theme.colors.disabled};
    display: grid;
    grid-template-columns: 28px 1fr;
    grid-gap: 16px;
    align-items: start;
    padding-top: 32px;
    &:first-child {
        padding-top: 0px;
    }
    &:last-child {
        border-bottom: none;
    }
    .container-img {
        width: 28px;
        height: 28px;
        background: ${(props) => props.theme.colors.secondary};
        border-radius: 50%;
        position: relative;
        &.disabled {
            background: #c6c8ca;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 55%;
            transform: translate(-50%, -50%);
            background-color: ${(props) => props.theme.colors.white};
            -webkit-mask: url(${imgNotificacao}) no-repeat;
            -webkit-mask-size: cover;
            width: 12px;
            height: 14px;
            z-index: 20;
            transition: background-color 0.5s;
        }
    }

    .container-text,
    div {
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 8px;
        }

        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.secondary};
            margin-bottom: 8px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primaryDark};
        }

        button {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.secondary};
            padding: 8px 24px;

            background: ${(props) => props.theme.colors.disabled};
            border-radius: 35px;
            margin-top: 24px;
        }
    }
`;
