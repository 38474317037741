import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { Loader } from "../../Loader";
import { InforCartao } from "./infoCartao";
import { Acoes } from "./acoes";
import { Meses } from "../Meses";
import { Empty } from "../../Mobile/Empty";
import { GraficoPositiveNegative } from "./grafico";
import { Movimentacao } from "./movimentacao";

export function OverViewItens() {
    const { getExtrato, cartaoId, loadingExtrato, extratos } = UseCartoes();
    const width = window.screen.width;

    async function handleClick(date: Date) {
        if (cartaoId) {
            await getExtrato(cartaoId, new Date(date));
        }
    }

    return (
        <>
            <InforCartao />
            <Acoes />
            <Meses
                handleClick={handleClick}
                slidesPerView={width < 480 ? 3.5 : 7.5}
            />

            <div className="container-loader-grafico">
                {loadingExtrato ? (
                    <div className="container-loading">
                        <Loader />
                    </div>
                ) : extratos?.length !== 0 ? (
                    <>
                        <GraficoPositiveNegative />
                        <Movimentacao />
                    </>
                ) : (
                    <Empty />
                )}
            </div>
        </>
    );
}
