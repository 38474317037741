import { useState } from "react";
import { Text, Flex } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { useTheme } from "../../../contexts/ThemeContext";
import { formataCPF, phoneNumber, formatCep } from "../../../util/helper";
import { Button } from "../Button";
import { Card } from "../CardSimple";
import { CardBack } from "../CardBack";
import imgUser from "../../../assets/img/user.svg";
// import imgCalendario from "../../../assets/img/calendario.svg";
import imgCelular from "../../../assets/img/celular.svg";
import imgEndereco from "../../../assets/img/gps-verde.svg";
import { ConfereDados } from "./styles";

export function Conferencia() {
    const { nextPasso, dados } = usePassos();
    const { t } = useTranslation();
    const { currentTheme } = useTheme();
    const [verso, setVerso] = useState(false);
    const [frente, setFrente] = useState(false);

    const color =
        dados?.tipo === "cartao_fisico" || dados?.tipo === "cartao_avulso"
            ? currentTheme.colors.tertiarybright
            : currentTheme.colors.tertiarydark;

    return (
        <ConfereDados className="container">
            <Text as="h3" mb="19px">
                {t(
                    "Agora é só dar uma conferida nos dados informados e confirmar o pedido."
                )}
            </Text>

            <div className="container-animation-cards-firula">
                <div className={`cards ${verso && "in"} ${frente && "out"}`}>
                    <Card color={color} />
                    <CardBack
                        solicitar={dados?.nome_cartao}
                        descricao={false}
                    />
                </div>
            </div>

            <div className="container-btns">
                <button
                    disabled={(frente === false && verso === false) || frente}
                    onClick={() => {
                        setFrente(!frente);
                        setVerso(false);
                    }}
                >
                    frente
                </button>
                <button
                    disabled={verso}
                    onClick={() => {
                        setVerso(!verso);
                        setFrente(false);
                    }}
                >
                    verso
                </button>
            </div>

            <ul>
                <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgUser} alt="" />
                        </div>
                        <Text
                            color="primaryDark"
                            textTransform="uppercase"
                            fontSize="xss"
                        >
                            {dados?.nome_completo ? dados.nome_completo : ""}
                        </Text>
                    </Flex>
                </li>
                <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgUser} alt="" />
                        </div>
                        <Text color="primaryDark" fontSize="xss">
                            CPF
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {formataCPF(dados?.cpf ? dados.cpf : "")}
                    </Text>
                </li>
                <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgCelular} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("celular")}
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {phoneNumber(dados?.celular ? dados.celular : "")}
                    </Text>
                </li>
                {/* <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgCalendario} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("data de nasc")}.
                        </Text>
                    </Flex>

                    <Text color="primaryDark" fontSize="xss">
                        {formatDate(
                            dados?.data_nascimento ? dados.data_nascimento : ""
                        )}
                    </Text>
                </li> */}
                {/* <li>
                    <Flex>
                        <div className="container-img">
                            <img src={imgUser} alt="" />
                        </div>
                        <Text
                            textTransform="uppercase"
                            color="primaryDark"
                            fontSize="xss"
                        >
                            {t("nome da mãe")}
                        </Text>
                    </Flex>

                    <Text
                        textTransform="uppercase"
                        color="primaryDark"
                        fontSize="xss"
                    >
                        {dados.nome_mae}
                    </Text>
                </li> */}
                <li>
                    <Flex alignItems="start">
                        <div className="container-img">
                            <img src={imgEndereco} alt="" />
                        </div>
                        <Flex flexDirection="column">
                            <Text
                                textTransform="uppercase"
                                color="primaryDark"
                                fontSize="xss"
                            >
                                {dados.nome}
                            </Text>
                            <Text
                                textTransform="uppercase"
                                color="primaryDark"
                                fontSize="xss"
                            >
                                Nº {dados?.numero} {dados?.complemento}
                            </Text>
                            <Text
                                textTransform="uppercase"
                                color="primaryDark"
                                fontSize="xss"
                            >
                                {dados?.bairro} {dados?.cidade} -{" "}
                                {dados?.estado}
                            </Text>
                            <Text
                                textTransform="uppercase"
                                color="primaryDark"
                                fontSize="xss"
                            >
                                CEP: {formatCep(dados?.cep)}
                            </Text>
                        </Flex>
                    </Flex>
                </li>
            </ul>

            <Button
                onClick={() => {
                    nextPasso(5);
                }}
            >
                {dados.valor_cobranca === "0.00"
                    ? "SOLICITAR CARTÃO"
                    : "avançar"}
            </Button>
        </ConfereDados>
    );
}
