import { ChangeEvent, useRef } from "react";
import { SearchCampaignContainer } from "./styles";

interface ISearchCampaign {
    onSearch: (text: string) => void;
    onChange: (text: string) => void;
}

export const SearchCampaign = ({ onSearch, onChange }: ISearchCampaign) => {
    const inputRef = useRef(null);

    async function handleSearchCampaign() {
        const search = inputRef.current as never as HTMLInputElement;

        if (onSearch) onSearch(search?.value);
    }

    async function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const search = e.target as never as HTMLInputElement;

        if (onChange) onChange(search.value);
    }

    return (
        <SearchCampaignContainer>
            <input
                type="text"
                placeholder="NOME, ÁREA, CHAVE E CLIENTE"
                ref={inputRef}
                onChange={handleChange}
            />
            <button onClick={handleSearchCampaign}>BUSCAR</button>
        </SearchCampaignContainer>
    );
};
