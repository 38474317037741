import { memo } from "react";

import { useConfig } from "../../../../Hook/system/useConfig";
import { AntiFraude } from "../Content/Antifraude";
import { CartaoDeCreditoMemorized } from "../Content/CartaoDeCredito/Main";
import { CreditoMemorized } from "../Content/Credito/Main";
import { EstornoMemorized } from "../Content/Estorno/Main";
import { InfraMemorized } from "../Content/Infra/Main/";
import { Produto } from "../Content/Produto";
import { Regulamento } from "../Content/Regulamento/Main";
import { UserMemorized } from "../Content/Usuarios";
import { Container } from "./styles";

function Content() {
    const { tabsCampanha } = useConfig();

    return (
        <Container>
            {tabsCampanha[0]?.active && (
                <div
                    className={`container-tab remove-padding ${
                        tabsCampanha[0]?.active ? "active" : ""
                    }`}
                >
                    {tabsCampanha[0]?.viewer && <InfraMemorized />}
                </div>
            )}

            {tabsCampanha[1]?.active && (
                <div
                    className={`container-tab remove-padding ${
                        tabsCampanha[1]?.active ? "active" : ""
                    }`}
                >
                    {tabsCampanha[1]?.viewer && <CreditoMemorized />}
                </div>
            )}

            {tabsCampanha[2]?.active && (
                <div
                    className={`container-tab remove-padding ${
                        tabsCampanha[2]?.active ? "active" : ""
                    }`}
                >
                    {tabsCampanha[2]?.viewer && <CartaoDeCreditoMemorized />}
                </div>
            )}

            {tabsCampanha[3]?.active && (
                <div
                    className={`container-tab remove-padding ${
                        tabsCampanha[3]?.active ? "active" : ""
                    }`}
                >
                    {tabsCampanha[3]?.viewer && <EstornoMemorized />}
                </div>
            )}

            {tabsCampanha[4]?.active && (
                <div
                    className={`container-tab ${
                        tabsCampanha[4]?.active ? "active" : ""
                    }`}
                >
                    {tabsCampanha[4]?.viewer && <Produto />}
                </div>
            )}

            {tabsCampanha[5]?.active && (
                <div
                    className={`container-tab ${
                        tabsCampanha[5]?.active ? "active" : ""
                    }`}
                >
                    <UserMemorized />
                </div>
            )}
            {tabsCampanha[6]?.active && (
                <div
                    className={`container-tab remove-padding ${
                        tabsCampanha[6]?.active ? "active" : ""
                    }`}
                >
                    <Regulamento />
                </div>
            )}
            {tabsCampanha[7]?.active && (
                <div
                    className={`container-tab ${
                        tabsCampanha[7]?.active ? "active" : ""
                    }`}
                >
                    <AntiFraude />
                </div>
            )}
        </Container>
    );
}

export const ContentMemorized = memo(Content);
