import * as Yup from "yup";

export interface MyForm {
    file: string;
}

export const initialValues: MyForm = {
    file: "",
};

export const validation = Yup.object().shape({
    file: Yup.string().required("Campo obrigatório"),
});
