import { useEffect, useState } from "react";
import { formatDateIso } from "../../util/helper";

import Select, { options } from "../NovoSelect";
import { CardNotificacao } from "../Notificacao/notificacao";
import { useNotificacoes } from "../../Hook/user/useNotificacoes";
import { Notificacao as INotificacao } from "../../Hook/user/UseUser";
import { Container } from "./styles";
export function Notificacao() {
    const { notificacoes: ApiNotificacoes, notificacoLida } = useNotificacoes();
    const [mesAno, setMesAno] = useState<options[]>([]);
    const [notificacoes, setNotificacoes] = useState<INotificacao[]>([]);

    function filterDate(value: string) {
        if (value === "Todas") {
            setNotificacoes(ApiNotificacoes);
            return;
        }

        const updateNotificacoes = ApiNotificacoes.filter((item) => {
            const date = formatDateIso(item.created_at).split(" - ")[0];
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_, mes, ano] = date.split(".");

            if (`${mes}/${ano}` === value) {
                return item;
            }

            return false;
        });

        setNotificacoes(updateNotificacoes);
    }

    useEffect(() => {
        if (ApiNotificacoes.length) {
            setNotificacoes(ApiNotificacoes);
        }
    }, [ApiNotificacoes]);

    useEffect(() => {
        if (ApiNotificacoes.length) {
            const allDates = ApiNotificacoes.map((item) => {
                const date = formatDateIso(item.created_at).split(" - ")[0];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, mes, ano] = date.split(".");

                return `${mes}/${ano}`;
            });

            const uniqueDates = ["Todas", ...new Set([...allDates])];

            setMesAno(
                uniqueDates.map((item, key) => {
                    return {
                        label: item,
                        value: key + 1,
                    };
                })
            );
        }
    }, [ApiNotificacoes]);

    return (
        <Container>
            <div className="container">
                <div className="container-header">
                    <h3>TODAS AS NOTIFICAÇÕES</h3>

                    <Select
                        tipo="calendario"
                        label="MÊS/ANO"
                        options={mesAno}
                        onChange={(values) => filterDate(values.label)}
                    />
                </div>

                <div className="container-notificacoes">
                    {notificacoes.map((item) => (
                        <CardNotificacao
                            key={item.id}
                            {...item}
                            titleBtn="EXCLUIR"
                            handleClick={async () => notificacoLida(item.id)}
                        />
                    ))}
                </div>
            </div>
        </Container>
    );
}
