import * as Yup from "yup";
// eslint-disable-next-line no-useless-escape
const regexCPF = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;
export interface Myform {
    name: string;
    email: string;
    phone_number: string;
    role: string;
}
export const values: Myform = {
    name: "",
    email: "",
    phone_number: "",
    role: "",
};

export const validadeForm = Yup.object().shape({
    name: Yup.string().required("Campo Obrigatório"),
    email: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
    phone_number: Yup.string().required("Campo Obrigatório"),
    role: Yup.string().required("Campo Obrigatório"),
});
