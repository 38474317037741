import { useEffect } from "react";
import { Box } from "alisson-application";
import { HeaderPassos } from "../HeaderPassos";
import { Validadar } from "../Validar";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { Documento } from "./documento";
import { AltearSenha } from "./alterarSenha";
import { Sucesso } from "../../Modais/DesktopAndMobile/sucesso";
import { Container } from "./styles";
import { Email } from "./email";
import imgLogo from "../../../assets/img/logo-menu.svg";
import { useHistory } from "react-router-dom";
import { When } from "../../when";
export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
];

export function EsqueciSenha() {
    const { setPassos, passos, nextPasso, dados } = usePassos();
    const { state,handleClose } = UseModal();
    const history = useHistory();

    useEffect(() => {
        if (state?.esqueciSenha) {
            setPassos(arrPassos);
        }
    }, [setPassos, state?.esqueciSenha]);

    async function validarUsuario() {
        nextPasso(3);
    }

    return (
        <Container>

            { !passos[3] &&
                <>
                    <div className="container-menu">
                        <img src={imgLogo} alt="" />
                    </div>
                    <HeaderPassos handleClose={() => {}} title="Recuperar Senha" />
                </>
            }
            <div className="container-tabs">
                <Box 
                    pt="50px"
                    pr="9px"
                    pb="35px"
                    pl="9px"
                    className={`tab ${passos[0]?.active ? "active" : ""}`}>
                        <When expr={history.location.pathname === "/sistema"}>
                            <>{passos[0]?.active ? <Email /> : null}</>
                        </When>

                        <When expr={history.location.pathname !== "/sistema"}>
                            <>{passos[0]?.active ? <Documento /> : null}</>
                        </When>
                </Box>
                <Box 
                    pt="15px"
                    pr="9px"
                    pl="9px"
                    className={`tab ${passos[1]?.active ? "active" : ""}`}>
                        {passos[1]?.active ? (
                            <Validadar
                                handleClick={validarUsuario}
                                phone_number={dados.phone_number}
                                autoSend={false}
                            />
                    ) : null}
                </Box>
                <Box
                    pt="50px"
                    pr="9px"
                    pb="35px"
                    pl="9px"
                    className={`tab ${passos[2]?.active ? "active" : ""}`}
                >
                    {passos[2]?.active ? <AltearSenha /> : null}
                </Box>
                <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                    <Sucesso btnLabel="Efetuar Login" handleClose={() => handleClose("esqueciSenha")}>
                        <h1>Senha Alterada
                            <br/>
                            com Sucesso!
                        </h1>
                        <p>Use sua nova senha para 
                           <br/> 
                           acessar seu YETZPAY!!
                        </p>
                    </Sucesso>
                </div>
            </div>
        </Container>
    );
}
