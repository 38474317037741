import styled from "styled-components";
import imgLupa from "../../../../assets/img/lupa.svg";
import imgUser from "../../../../assets/img/user.svg";
import { Container as DefaultHeader } from "../../Header/styles";

export const Container = styled.div`
    .user {
        img {
            width: 32px;
            border-radius: 50%;
        }
    }
    button {
        margin: 0 auto;
        display: block;
        &.cadastro {
            margin: 0px;
        }
    }
`;

export const HeaderStyles = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e2e3;
    justify-content: space-between;
    ${DefaultHeader} {
        border-bottom: unset;
    }
    .busca {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: auto;
            margin: 0px;
            margin-left: 11px;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            input {
                padding-left: 45px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }
    }
`;

export const EmptyStyles = styled.div`
    background: ${(props) => props.theme.colors.background};
    border-radius: 8px;
    height: 155px;
    width: 698px;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-user {
        background: #acafb1;
        width: 38.77px;
        height: 38.77px;
        border-radius: 50%;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 52%;
            transform: translate(-50%, -50%);
            background-color: ${(props) => props.theme.colors.white};
            -webkit-mask: url(${imgUser}) no-repeat;
            width: 19px;
            height: 17px;
            z-index: 20;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primaryDark};
        margin-left: 20px;
    }
`;
