import styled from "styled-components";
import imgDocument from "../../../../../assets/img/document.svg";
import imgPessoa from "../../../../../assets/img/pessoaBackOffice.svg";
import imgCard from "../../../../../assets/img/cardBackOffice.svg";
import imgAddresses from "../../../../../assets/img/addressesBackOffice.svg";
import imgEmail from "../../../../../assets/img/emailBackOffice.svg";
import imgPhone from "../../../../../assets/img/phone.svg";

export const Container = styled.div`
    border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
    padding-bottom: 15px;
    margin-bottom: 22px;
    width: 100%;
    .container-text {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-left: 35px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 1px;
            left: 0px;
            background-color: ${(props) => props.theme.colors.secondary};
            z-index: 20;
            transition: background-color 0.5s;
        }

        &.document {
            &:before {
                -webkit-mask: url(${imgDocument}) no-repeat;
                width: 21px;
                height: 16px;
            }
        }
        &.pessoa {
            &:before {
                -webkit-mask: url(${imgPessoa}) no-repeat;
                width: 14px;
                height: 18px;
            }
        }
        &.card {
            &:before {
                -webkit-mask: url(${imgCard}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }
        &.addresses {
            &:before {
                -webkit-mask: url(${imgAddresses}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }
        &.email {
            &:before {
                -webkit-mask: url(${imgEmail}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }
        &.phone {
            &:before {
                -webkit-mask: url(${imgPhone}) no-repeat;
                width: 24px;
                height: 24px;
            }
        }
        h3 {
            color: ${(props) => props.theme.colors.secondary};
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 16px */
            letter-spacing: 1.28px;
            text-transform: uppercase;
        }
        .container-btns {
            display: flex;
            button {
                border: none;
                background: transparent;
                display: flex;
                align-items: center;
                margin-left: 26px;
                p {
                    color: ${(props) => props.theme.colors.secondary};
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%; /* 14px */
                    letter-spacing: 1.12px;
                    margin-left: 8px;
                }
            }
        }
    }
`;
