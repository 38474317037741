import { Button } from"../ButtonSlim";
import CardOutOfService from "../../../assets/img/cardOutOfService.svg";
import { Container } from "../../Modais/ModalContainer/styles";
import { UseModal } from "../../../Hook/Usemodal";

export function HorarioFuncionamento() {
    const { handleClose, state } = UseModal();

    return (
        <Container>
            <img className="img-close" src={CardOutOfService} alt="" />
            {state.dados.text}
            <div className="buttonContainer">
                <Button onClick={() => handleClose("horarioFuncionamento")}>
                    Ok
                </Button>
            </div>
        </Container>
    );
}
