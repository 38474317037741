import styled from "styled-components";
import { Container as LineTextBoleto } from "../LineTextBoleto/styles";

export const Container = styled.div`
    border-radius: 17px;
    background: #f3f3f3;
    padding-top: 26px;
    padding-left: 21px;
    padding-right: 21px;
    margin-bottom: 24px;
    ${LineTextBoleto} {
        &:last-child {
            border-bottom: none;
            margin-bottom: 0px;
        }
    }
`;
