import { useState, useContext, createContext } from "react";

interface ModalInfra {
    setModalInfra: (value: boolean) => void;
    modalInfra: boolean;

    setNomeArquivoInfra: (value: string) => void;
    nomeArquivoInfra: string;
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

const Context = createContext<ModalInfra>({} as ModalInfra);

export function ModalInfraProvider(props: ProviderProps) {
    const { children } = props;
    const [modalInfra, setModalInfra] = useState(false);
    const [nomeArquivoInfra, setNomeArquivoInfra] = useState("");

    return (
        <Context.Provider
            value={{
                modalInfra,
                setModalInfra,
                nomeArquivoInfra,
                setNomeArquivoInfra,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
