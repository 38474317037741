import { useState } from "react";
import { AcoesStyles } from "./styles";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import imgCadeado from "../../../assets/img/cadeado.svg";

import imgTransferir from "../../../assets/img/transferir.svg";
import imgChave from "../../../assets/img/senha-ativar-cartao.svg";
// import imgCartao from "../../../assets/img/segunda-via.svg";
// import imgCartao from "../../../assets/img/cancelar.svg";
import imgEntrega from "../../../assets/img/entrega.svg";
import imgComprovante from "../../../assets/img/comprovante.svg";
import imgDinheiro from "../../../assets/img/$.svg";
import imgPerdaRoubo from "../../../assets/img/perda-roubo.svg";
import imgBoleto from "../../../assets/img/boleto.svg";

import imgSetaDir from "../../../assets/img/seta-dir-verde.svg";
import imgSetaEsq from "../../../assets/img/seta-esq-verde.svg";

import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    getCampanha,
    foraDoHorario,
    tempoInicioPlataforma,
    tempoFimPlataforma,
} from "../../../util/helper";
import { useUser } from "../../../Hook/user/UseUser";

SwiperCore.use([Navigation, A11y]);

export function Acoes() {
    const { cartao, cartoes, setSaldo } = UseCartoes();
    const { state, setState, handleOpen } = UseModal();
    const { user } = useUser();
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
    const [active, setActive] = useState(true);
    const hasCardActive =
        cartoes?.filter((item) => item.tipo === "ATIVO").length > 0;

    const hasPayment = getCampanha().allow_bill_payment;
    const hasActivePayment = !user?.all_campaigns_bill_payment_available
        ? user?.all_campaigns_bill_payment_available
        : getCampanha().allow_bill_payment_available;

    return (
        <AcoesStyles className={`${active ? "active" : ""}`}>
            <Swiper
                spaceBetween={0}
                slidesPerView={3.4}
                observer={true}
                className="swiper-slide-mes"
                navigation={{
                    nextEl,
                    prevEl,
                }}
            >
                {hasPayment ? (
                    <SwiperSlide>
                        <button
                            className={`btn-boleto ${!hasActivePayment ? "disabled" : ""
                                }`}
                            onClick={() => {
                                if (!hasActivePayment) {
                                    handleOpen("disabledBoleto");
                                    return;
                                }

                                if (foraDoHorario()) {
                                    handleOpen("horarioFuncionamento", {
                                        text: (
                                            <>
                                                 <h2 className="success">
                                                    Ops!
                                                </h2> 
                                                <p className="strong">
                                                    Pagamento de contas disponível{" "}
                                                    <br /> das{" "}
                                                    {tempoInicioPlataforma}h às{" "}
                                                    {tempoFimPlataforma}h em dias
                                                    úteis.
                                                </p>
                                                <p>
                                                    Por favor, retorne neste período.
                                                </p>
                                            </>
                                        ),
                                    });
                                    return;
                                }

                                handleOpen("alertaBoleto");
                            }}
                        >
                            <img src={imgBoleto} alt="" /> pagamento <br /> de
                            contas
                        </button>
                    </SwiperSlide>
                ) : (
                    <></>
                )}
                {hasPayment ? (
                    <SwiperSlide>
                        <button
                            onClick={() => {
                                setState({
                                    ...state,
                                    comprovanteDePagamento: true,
                                });
                            }}
                        >
                            <img src={imgComprovante} alt="" /> Comprovantes{" "}
                            <br /> de pagamento
                        </button>
                    </SwiperSlide>
                ) : (
                    <></>
                )}
                <SwiperSlide>
                    <button
                        disabled={cartao?.status !== "ATIVO" ? true : false}
                        onClick={() => {
                            setState({
                                ...state,
                                atualizaSenha: true,
                            });
                        }}
                    >
                        <img src={imgChave} alt="" /> atualizar <br /> senha
                    </button>
                </SwiperSlide>

                <SwiperSlide>
                    <button
                        disabled={cartao?.status === "CANCELADO"}
                        onClick={() => handleOpen("perdaRoubo")}
                    >
                        <img src={imgPerdaRoubo} alt="" /> Perda <br /> ou Roubo
                    </button>
                </SwiperSlide>

                <SwiperSlide>
                    <button onClick={() => handleOpen("limiteTarifas")}>
                        <img src={imgDinheiro} alt="" /> LIMITES E <br />{" "}
                        TARIFAS
                    </button>
                </SwiperSlide>

                {cartao?.tipo === "cartao_fisico" && (
                    <SwiperSlide>
                        <button onClick={() => handleOpen("entrega")}>
                            <img src={imgEntrega} alt="" /> status <br />
                            de entrega
                        </button>
                    </SwiperSlide>
                )}

                <SwiperSlide className="container-transferir">
                    <button
                        disabled={cartao?.status !== "ATIVO" || hasCardActive}
                        onClick={() => {
                            if (foraDoHorario()) {
                                setState({
                                    ...state,
                                    acoesCartaoMobile: false,
                                    horarioFuncionamento: true,
                                    dados: {
                                        text: (
                                            <>
                                                <h2 className="success">
                                                    Ops!
                                                </h2>                                            
                                                <p className="strong">
                                                    As transferências entre cartões<br/> 
                                                    só podem ser realizadas em dias úteis,<br/> 
                                                    {`das ${tempoInicioPlataforma}h  às ${tempoFimPlataforma}h.`}.
                                                </p>
                                                <p>
                                                    Por favor, retorne neste período.
                                                </p>
                                            </>
                                        ),
                                    },
                                });
                                return;
                            }

                            handleOpen("transferir");
                            setSaldo("");
                        }}
                    >
                        <img src={imgTransferir} alt="" />
                        TRANSFERIR ENTRE <br /> CARTÕES YETZPAY
                    </button>
                </SwiperSlide>

                <SwiperSlide>
                    <button
                        disabled={
                            cartao?.status === "BLOQUEADO" ||
                                cartao?.status === "CANCELADO"
                                ? true
                                : false
                        }
                        onClick={() => {
                            setState({
                                ...state,
                                acaoBloquearCartao: true,
                            });
                        }}
                    >
                        <img src={imgCadeado} alt="" />
                        BLOQUEIO <br /> TEMPORÁRIO
                    </button>
                </SwiperSlide>

                {/* <SwiperSlide>
                    <button
                        disabled={cartao?.status !== "ATIVO" ? true : false}
                        onClick={() => handleOpen("segundaVia")}
                    >
                        <img src={imgCartao} alt="" /> CANCELAR <br /> CARTÃO
                    </button>
                </SwiperSlide> */}
            </Swiper>

            <div className="controles">
                <div
                    ref={(node) => setPrevEl(node)}
                    onClick={() => setActive(true)}
                    className="rotate-y"
                >
                    <img src={imgSetaEsq} alt="" />
                </div>
                <div
                    ref={(node) => setNextEl(node)}
                    onClick={() => setActive(false)}
                >
                    <img src={imgSetaDir} alt="" />
                </div>
            </div>
        </AcoesStyles>
    );
}
