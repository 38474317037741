import * as Yup from "yup";

export interface IAtivarCarao {
    password: string;
    confirm_password: string;
    cvv: string;
    acg_account_id: string;
    hash?: string;
}

export const ativar: IAtivarCarao = {
    password: "",
    confirm_password: "",
    cvv: "",
    acg_account_id: "",
};

export const validationAtivarCartao = Yup.object().shape({
    password: Yup.string()
        .min(4, "Senha numérica de 4 dígitos")
        .required("Senha é obrigatório"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Senha é obrigatório"),
    cvv: Yup.string().min(3, "CVV é obrigatório").required("Campo obrigatório"),
    acg_account_id: Yup.string()
        .min(9, "Número da conta deve ter 8 á 9 números")
        .required("Campo obrigatório"),
});

export type keyOf<T> = keyof T;

export interface TipoCartao {
    cartao_fisico: string;
    cartao_virtual: string;
    cartao_avulso: string;
}

export interface SolicitarCartao {
    tipo: string;
}

export const solicitarCartao: SolicitarCartao = {
    tipo: "",
};

export const validaSolicitarCartao = Yup.object().shape({
    tipo: Yup.string().required("Campo obrigatório"),
});
