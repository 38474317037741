import {
    AcaoBloquearCartao,
    AcaoDesbloquearCartaoMobilePasso1,
    AcaoDesbloquearCartaoMobilePasso2,
    Menu,
    ModalAlertaBoleto,
    ModalAlertaCreditoAdicionado,
    ModalAlterarSenha,
    ModalAtivarCartaoAutomatico,
    ModalAtivarCartaoAvulso,
    ModalBoleto,
    ModalCartaoAvulso,
    ModalComprovanteDePagamento,
    ModalCpfInvalido,
    ModalCrop,
    ModalDiferencaCartoes,
    ModalDisabledBoleto,
    ModalEndereco,
    ModalEntrega,
    ModalFaleConosco,
    ModalFalhaAoPagarBoleto,
    ModalHorarioFuncionamento,
    ModalLimiteBoleto,
    ModalLimiteTarifas,
    ModalNotificacao,
    ModalNovoSaldo,
    ModalPerdaRoubo,
    ModalSaldoInsuficiente,
    ModalTransferirCredito,
    ModalTransferirEntreCateira,
    ModalUpdateEndereco,
    ModelBottomDocumentoEnviado,
    ModelBottomDocumentoErro,
    VerificacaoDocument,
} from "../";
import { UseModal } from "../../../Hook/Usemodal";

export function DesktopAndMobile() {
    const { handleClose, state, setState } = UseModal();
    const width = window.screen.width;
    return (
        <>
            {state?.menu ? (
                <Menu
                    open={state?.menu}
                    handleClose={() => handleClose("menu")}
                />
            ) : (
                <></>
            )}

            {state?.notificacao ? (
                <ModalNotificacao
                    open={state?.notificacao}
                    handleClose={() => handleClose("notificacao")}
                />
            ) : (
                <></>
            )}

            {state?.notificacao ? (
                <ModalNotificacao
                    open={state?.notificacao}
                    handleClose={() => handleClose("notificacao")}
                />
            ) : (
                <></>
            )}

            {state?.faleConosco ? (
                <ModalFaleConosco
                    open={state?.faleConosco}
                    handleClose={() => handleClose("faleConosco")}
                />
            ) : (
                <></>
            )}

            {state?.alterarSenha ? (
                <ModalAlterarSenha
                    open={state?.alterarSenha}
                    handleClose={() => handleClose("alterarSenha")}
                />
            ) : (
                <></>
            )}

            {state?.alterarEndereco ? (
                <ModalEndereco
                    open={state?.alterarEndereco}
                    handleClose={() => handleClose("alterarEndereco")}
                />
            ) : (
                <></>
            )}

            {state?.updateEndereco ? (
                <ModalUpdateEndereco
                    open={state?.updateEndereco}
                    handleClose={() => handleClose("updateEndereco")}
                />
            ) : (
                <></>
            )}

            {state?.cartaoAvulso ? (
                <ModalCartaoAvulso
                    open={state?.cartaoAvulso}
                    handleClose={() => handleClose("cartaoAvulso")}
                />
            ) : (
                <> </>
            )}

            {state?.crop ? (
                <ModalCrop
                    open={state?.crop}
                    handleClose={() => handleClose("crop")}
                />
            ) : (
                <></>
            )}

            {state?.diferencaCartoes ? (
                <ModalDiferencaCartoes
                    open={state?.diferencaCartoes}
                    handleClose={() => handleClose("diferencaCartoes")}
                />
            ) : (
                <></>
            )}

            {state?.limiteTarifas ? (
                <ModalLimiteTarifas
                    open={state?.limiteTarifas}
                    handleClose={() => handleClose("limiteTarifas")}
                />
            ) : (
                <> </>
            )}

            {state?.acaoBloquearCartao ? (
                <AcaoBloquearCartao
                    open={state?.acaoBloquearCartao}
                    handleClose={() => handleClose("acaoBloquearCartao")}
                />
            ) : (
                <> </>
            )}

            {state?.acaoDesbloquearCartaoMobilePasso1 ? (
                <AcaoDesbloquearCartaoMobilePasso1
                    open={state?.acaoDesbloquearCartaoMobilePasso1}
                    handleClose={() =>
                        handleClose("acaoDesbloquearCartaoMobilePasso1")
                    }
                />
            ) : (
                <></>
            )}

            {state?.acaoDesbloquearCartaoMobilePasso2 ? (
                <AcaoDesbloquearCartaoMobilePasso2
                    open={state?.acaoDesbloquearCartaoMobilePasso2}
                    handleClose={() =>
                        handleClose("acaoDesbloquearCartaoMobilePasso2")
                    }
                />
            ) : (
                <></>
            )}

            {state?.transferirEntreCarteiras ? (
                <ModalTransferirEntreCateira
                    open={state?.transferirEntreCarteiras}
                    handleClose={() => handleClose("transferirEntreCarteiras")}
                />
            ) : (
                <></>
            )}

            {state?.perdaRoubo ? (
                <ModalPerdaRoubo
                    open={state?.perdaRoubo}
                    handleClose={() => {
                        if (width <= 480) {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                acoesCartaoMobile: true,
                            });
                        } else {
                            handleClose("perdaRoubo");
                        }
                    }}
                />
            ) : (
                <></>
            )}

            {state?.entrega ? (
                <ModalEntrega
                    open={state?.entrega}
                    handleClose={() => handleClose("entrega")}
                />
            ) : (
                <></>
            )}

            {state?.ativarCartaoAvulso || state?.ativarCartaoAvulsoSubHeader ? (
                <ModalAtivarCartaoAvulso
                    open={
                        state?.ativarCartaoAvulso ||
                        state?.ativarCartaoAvulsoSubHeader
                    }
                    handleClose={() => {
                        setState({
                            ...state,
                            ativarCartaoAvulso: false,
                            ativarCartaoAvulsoSubHeader: false,
                        });
                    }}
                />
            ) : (
                <></>
            )}

            {state?.boleto ? (
                <ModalBoleto
                    open={state?.boleto}
                    handleClose={() => handleClose("boleto")}
                />
            ) : (
                <></>
            )}

            {state.alertaBoleto ? (
                <ModalAlertaBoleto
                    open={state?.alertaBoleto}
                    handleClose={() => handleClose("alertaBoleto")}
                />
            ) : (
                <></>
            )}

            {state.horarioFuncionamento ? (
                <ModalHorarioFuncionamento
                    open={state?.horarioFuncionamento}
                    handleClose={() => handleClose("horarioFuncionamento")}
                />
            ) : (
                <></>
            )}
            {state.verificacaoDocument ? (
                <VerificacaoDocument
                    open={state?.verificacaoDocument}
                    handleClose={() => {}}
                />
            ) : (
                <></>
            )}
            {state.sucessoVerificacaoDocumento ? (
                <ModelBottomDocumentoEnviado
                    open={state?.sucessoVerificacaoDocumento}
                    handleClose={() =>
                        handleClose("sucessoVerificacaoDocumento")
                    }
                />
            ) : (
                <></>
            )}
            {state.errorDocumento ? (
                <ModelBottomDocumentoErro
                    open={state?.errorDocumento}
                    handleClose={() => {
                        setState({
                            ...state,
                            verificacaoDocument: true,
                            errorDocumento: false,
                        });
                    }}
                />
            ) : (
                <></>
            )}
            {state?.alertaCreditoAdicionado ? (
                <ModalAlertaCreditoAdicionado
                    open={state?.alertaCreditoAdicionado}
                    handleClose={() => {
                        setState({
                            ...state,
                            alertaCreditoAdicionado: false,
                        });
                    }}
                />
            ) : (
                <></>
            )}

            {state?.tutorialTransferir ? (
                <ModalTransferirCredito
                    open={state?.tutorialTransferir}
                    handleClose={() => {
                        setState({
                            ...state,
                            alertaCreditoAdicionado: true,
                            tutorialTransferir: false,
                        });
                    }}
                />
            ) : (
                <></>
            )}

            {state?.comprovanteDePagamento ? (
                <ModalComprovanteDePagamento
                    open={state?.comprovanteDePagamento}
                    handleClose={() => handleClose("comprovanteDePagamento")}
                />
            ) : (
                <></>
            )}

            {state.saldoInsuficiente ? (
                <ModalSaldoInsuficiente
                    open={state?.saldoInsuficiente}
                    handleClose={() => handleClose("saldoInsuficiente")}
                />
            ) : (
                <></>
            )}

            {state.disabledBoleto ? (
                <ModalDisabledBoleto
                    open={state?.disabledBoleto}
                    handleClose={() => handleClose("disabledBoleto")}
                />
            ) : (
                <></>
            )}

            {state?.ativarCartaoAutomatico ? (
                <ModalAtivarCartaoAutomatico
                    open={state?.ativarCartaoAutomatico}
                    handleClose={() => {}}
                />
            ) : (
                <></>
            )}

            {state.limiteBoleto ? (
                <ModalLimiteBoleto
                    open={state?.limiteBoleto}
                    handleClose={() => handleClose("limiteBoleto")}
                />
            ) : (
                <></>
            )}
            {state.novoSaldo ? (
                <ModalNovoSaldo
                    open={state.novoSaldo}
                    handleClose={() => handleClose("novoSaldo")}
                />
            ) : (
                <></>
            )}

            {state.boletoCpfInvalido ? (
                <ModalCpfInvalido
                    open={state?.boletoCpfInvalido}
                    handleClose={() => handleClose("boletoCpfInvalido")}
                />
            ) : (
                <></>
            )}

            {state.boletoErroMsg ? (
                <ModalFalhaAoPagarBoleto
                    open={!!state?.boletoErroMsg}
                    handleClose={() => handleClose("boletoErroMsg")}
                />
            ) : (
                <></>
            )}
        </>
    );
}

//disabledBoleto
