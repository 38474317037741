import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { Text, Flex } from "alisson-application";
import { FiltroDateStyles } from "./styles";

interface FiltroDateProps {
    handleAntigo: (value: boolean) => void;
    handleRecent: (value: boolean) => void;
    reset?: boolean;
}

export function FiltroDate(props: FiltroDateProps) {
    const { handleAntigo, handleRecent } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [antigo, setAntigo] = useState(false);
    const [recent, setRecent] = useState(true);

    const handleClickFiltro = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFiltro = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props.reset) {
            setRecent(true);
            setAntigo(false);
        }
    }, [props.reset]);

    return (
        <>
            <button onClick={handleClickFiltro}>
                <Flex justifyContent="space-between">
                    <Text fontWeight="400" color="primaryDark">Ordernar por:</Text>
                    <Text fontWeight="700" ml="3px" color="secondary">
                        {recent ? "Mais recente" : "Mais antigo"}
                    </Text>
                </Flex>
            </button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseFiltro}
                className="filtro-date"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <FiltroDateStyles>
                    <Flex alignItems="center">
                        <button
                            className={`${antigo ? "active" : ""}`}
                            onClick={() => {
                                setAntigo(true);
                                handleAntigo(true);

                                handleRecent(false);
                                setRecent(false);

                                handleCloseFiltro();
                            }}
                        >
                            Mais antigo
                        </button>
                    </Flex>
                    <Flex alignItems="center">
                        <button
                            className={`${recent ? "active" : ""}`}
                            onClick={() => {
                                setRecent(true);
                                handleRecent(true);

                                setAntigo(false);
                                handleAntigo(false);

                                handleCloseFiltro();
                            }}
                        >
                            Mais recente
                        </button>
                    </Flex>
                </FiltroDateStyles>
            </Popover>
        </>
    );
}
