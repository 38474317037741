import { AxiosResponse } from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { api } from "../../services/";
import { base64Hash, getLocalStore } from "../../util/helper";
import { LocalStoreProps, Variant } from "../types";
import { Campanhas, useUser } from "./UseUser";

import { toast } from "react-toastify";
import {
    CampanhaForm,
    IEndereco,
    Register,
} from "../../components/Mobile/FormLogin/config";
import { UseModal } from "../Usemodal";
import { IStage } from "./useIdWall";

interface UseLoginProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseLoginData {
    checkDocument: (
        document: CheckDocumentValues
    ) => Promise<AxiosResponse<CheckDocumentData>>;
    checkAccountCampanha: (
        values: CheckAccountCampanha
    ) => Promise<AxiosResponse<CheckDocumentData>>;
    assignUserCampanha: (
        values: CheckAccountCampanha
    ) => Promise<AxiosResponse<CheckDocumentData>>;
    checkCampanha: (values: CampanhaForm) => Promise<AxiosResponse<CheckUser>>;
    login: (values: Login) => Promise<AxiosResponse<UserData>>;
    loginWithCampanha(id: number): void;
    registro: (values: Values) => Promise<AxiosResponse>;
    enderecoStore(values: IEndereco): Promise<AxiosResponse>;
    setFormValuesRegister: (values: Register) => void;
    confirmAuthCode(code: string): Promise<any>;
    forceLogin(): void;
    firstLogin(values: any): Promise<any>;

    formValuesRegister: Register;
    loading: boolean;

    setCodeType: (value: Variant) => void;
    codeType: Variant;

    getCode: (code?: Variant) => Promise<void>;
    confirmCode: (code: string) => Promise<AxiosResponse>;

    setModal: (value: boolean) => void;
    modal: boolean;
    user: LocalStoreProps;

    logout(): Promise<any>;

    campanhas: Campanhas[];
}

interface CheckUser {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    register_status: number;
    image: string;
    created_at: string;
    updated_at: string;
    saldo: number;
}

interface Login {
    document: string;
    password: string;
}

interface User {
    id: number;
    first_update_password: 0 | 1;
    name: string;
    email: string;
    document: string;
    phone_number: string | null;
    status: number;
    register_status: number;
    image: string;
    created_at: string;
    updated_at: string;
    saldo: number;
    access_token: string;
    nome_da_mae: string;
    pessoa: Pessoa;
    first_login: number;
}

export interface Pessoa {
    aceita_email: number;
    aceita_sms: number;
    aceita_termos: number;
    aceita_whats: number;
    acg_people_id: null | number;
    apelido: string;
    created_at: string;
    data_nascimento: string;
    email: string;
    email_confirmed: number;
    genero: string;
    id: number;
    nome_cpf: string;
    razao_social: string;
    nome_da_mae: string;
    updated_at: string;
    user_id: number;
    tipo: string;
}

interface UserData {
    access_token: string;
    user: User;
    campanhas: Campanhas[];
}

interface UserRegister {
    token: string;
    user: User;
    campanhas: Campanhas[];
    pessoa: Pessoa;
}

interface CheckDocumentValues {
    tipo: "juridica" | "fisica";
    document: string;
    campanha: string;
}
interface CheckAccountCampanha {
    document: string;
    campanha: string;
    account: string;
}

interface CheckDocumentData {
    campanha: Campanha;
    user: CheckDocumentUser | null;
    user_on_campanha: number | null;
    account: number | null;
    stage: IStage;
    idwall_service_status: number;
}

interface Campanha {
    end_date_time: string;
    start_date_time: string;
    external_register: number;
    force_account_register: number;
    force_activate_account_login: number;
    id: number;
    nome: string;
}

interface CheckDocumentUser {
    document: string;
    id: number;
    name: string;
    register_status: number;
    status: number;
}

// interface Cliente {
//     areas: Areas[];
//     cnpj: string;
//     created_at: null | string;
//     id: number;
//     nome: string;
//     razao_social: string;
//     updated_at: null | string;
// }

// interface ClienteAreaContato {
//     areas: Areas;
//     celular: string;
//     cliente_area_id: number;
//     created_at: null | string;
//     email: string;
//     id: number;
//     nome: string;
//     telefone: string;
//     updated_at: null | string;
// }

// interface Areas {
//     cliente_id: 1;
//     created_at: null;
//     id: 1;
//     nome: "Area do cliente 1";
//     updated_at: null;
// }

export interface Values extends Register, IEndereco { }

const UsePassosContext = createContext<UseLoginData>({} as UseLoginData);

export function LoginProvider(props: UseLoginProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const { handleOpen, handleClose } = UseModal();
    const { codeWhatsapp } = useUser();
    const [stateCodeWhatsapp, setStateCodeWhatsapp] = useState("");
    const [formValuesRegister, setFormValuesRegister] = useState(
        {} as Register
    );
    const [user, setUser] = useState<LocalStoreProps>({} as LocalStoreProps);
    const [campanhas, setCampanhas] = useState<Campanhas[]>([]);
    const [codeType, setCodeType] = useState<Variant>("whatsapp");
    const [modal, setModal] = useState(false);

    const permission = getLocalStore()?.permissions
        ? getLocalStore()?.permissions
        : {};

    async function checkDocument(values: CheckDocumentValues) {
        try {
            const reponse = await api.post<CheckDocumentData>(
                `check-document`,
                { ...values }
            );
            return reponse;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function checkCampanha(values: CampanhaForm) {
        const { campanha } = values;

        try {
            setLoading(true);
            const reponse = await api.post(`check-campanha`, {
                campanha,
            });

            setLoading(false);
            return reponse;
        } catch (error: any) {
            setLoading(false);
            return error?.response;
        }
    }

    async function checkAccountCampanha(values: CheckAccountCampanha) {
        try {
            const reponse = await api.post<CheckDocumentData>(
                `check-account-campanha`,
                { ...values }
            );
            return reponse;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function assignUserCampanha(values: CheckAccountCampanha) {
        try {
            const reponse = await api.post<CheckDocumentData>(
                `assign-user-campanha`,
                { ...values }
            );

            return reponse;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu!");
            return error?.response;
        }
    }

    async function firstLogin(dados: any) {
        try {
            const reponse = await api.post(
                `usuario/first-update-password`,
                {
                    ...dados,
                },
                {
                    headers: {
                        "x-token-auth2": `${base64Hash(stateCodeWhatsapp)}`,
                        authorization: `Bearer ${user.token_user}`,
                    },
                }
            );

            return reponse;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu!");
            return error?.response;
        }
    }

    async function login(values: Login) {
        const { password } = values;
        const cpf = values.document.replace(/[^0-9]/g, "");

        try {
            const reponse = await api.post<UserData>(`login`, {
                document: cpf,
                password,
            });

            const { data } = reponse;

            const headers = {
                authorization: `Bearer ${data.access_token}`,
            };

            await api.post(
                `usuario/generate-code`,
                { type: "whatsapp" },
                { headers }
            );

            const user: LocalStoreProps = {
                first_update_password: data?.user?.first_update_password,
                name: data?.user?.pessoa?.nome_cpf,
                token_user: data.access_token,
                token_adm: getLocalStore().token_adm
                    ? getLocalStore().token_adm
                    : "",
                email: data.user?.pessoa?.email,
                nome_da_mae: data.user?.pessoa?.nome_da_mae,
                id: data.user.id,
                phone_number: data.user.phone_number
                    ? data.user.phone_number
                    : "",
                type: "user",
                first_login: data.user.first_login,
                data_nascimento: data.user?.pessoa?.data_nascimento,
                documento: data.user.document,
                permissions_count: 0,
                campanha_user: {
                    id: data.campanhas[0].id,
                    nome: data.campanhas[0].nome,
                    chave: data.campanhas[0].chave,
                    status: data.campanhas[0].status,
                    regulamento: data.campanhas[0].regulamento,
                    start_date_time: data.campanhas[0].start_date_time,
                    end_date_time: data.campanhas[0].end_date_time,
                },
                image: data.user?.image,
                permissions: { ...permission },
                pessoa: data?.user?.pessoa,
                time: new Date(),
            };

            setUser(user);
            setCampanhas(data.campanhas);

            return reponse;
        } catch (error: any) {
            return error?.response;
        }
    }

    function loginWithCampanha(id: number) {
        const campanha = campanhas.find(
            (campanha) => campanha.id === id
        ) as Campanhas;
        const localStore: LocalStoreProps = {
            ...user,
            campanha_user: {
                id: campanha.id,
                nome: campanha.nome,
                chave: campanha.chave,
                status: campanha.status,
                regulamento: campanha.regulamento,
                start_date_time: campanha.start_date_time,
                end_date_time: campanha.end_date_time,
            },
        };
        setUser(localStore);

        handleOpen("loginCheck");

        // localStorage.setItem("user", JSON.stringify(localStore));
        // localStorage.setItem("campanha", JSON.stringify([campanha]));
        // window.location.href = "/dashboard";
    }

    async function registro(values: Values) {
        const onlyNumbersRegex = /\D/g;
        const cpf = values.document.replace(onlyNumbersRegex, "");
        const isCNPJ = values.document.length > 11

        const phone_number = values?.phone_number?.replace(
            onlyNumbersRegex,
            ""
        );

        try {
            const reponse = await api.post<UserRegister>(`register`, {
                tipo: values.document.length <= 11 ? "fisica" : "juridica",
                ...values,
                document: cpf,
                cep: values?.cep?.replace(onlyNumbersRegex, ""),
                phone_number:
                    phone_number?.length < 13
                        ? "55" + phone_number
                        : phone_number,
                data_nascimento: values.data_nascimento
                    ? values?.data_nascimento.split("/").reverse().join("-")
                    : undefined,
            });
            const { data } = reponse;

            const user: LocalStoreProps = {
                first_update_password: data?.user?.first_update_password,
                name: data.user?.pessoa?.nome_da_mae,
                email: data.user?.pessoa?.email,
                token_user: data.token,
                token_adm: getLocalStore().token_adm
                    ? getLocalStore().token_adm
                    : "",
                id: data.user.id,
                nome_da_mae: data.user?.pessoa?.nome_da_mae,
                phone_number: data.user.phone_number
                    ? data.user.phone_number
                    : "",
                type: "user",
                first_login: data.user.first_login,
                data_nascimento: data.user?.pessoa?.data_nascimento,
                documento: data.user.document,
                permissions_count: 0,
                campanha_user: {
                    id: data.campanhas[0].id,
                    nome: data.campanhas[0].nome,
                    chave: data.campanhas[0].chave,
                    status: data.campanhas[0].status,
                    regulamento: data.campanhas[0].regulamento,
                    start_date_time: data.campanhas[0].start_date_time,
                    end_date_time: data.campanhas[0].end_date_time,
                },
                image: "",
                permissions: { ...permission },
                pessoa: data?.user?.pessoa,
                time: new Date(),
            };

            localStorage.setItem("campanha", JSON.stringify(data.campanhas));
            setUser(user);

            if (isCNPJ) {
                setCampanhas(data.campanhas);
                localStorage.setItem("user", JSON.stringify(user));

                const headers = {
                    authorization: `Bearer ${user.token_user}`,
                };

                await api.post(
                    `usuario/generate-code`,
                    { type: "whatsapp" },
                    { headers }
                );

                handleOpen("loginCheck", null);
            }

            return reponse;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function enderecoStore(values: IEndereco) {
        const headers = {
            authorization: `Bearer ${user.token_user}`,
        };

        try {
            const reponse = await api.post<UserRegister>(
                `usuario/endereco`,
                {
                    ...values,
                },
                { headers }
            );

            return reponse;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function getCode(code?: Variant) {
        const headers = {
            authorization: `Bearer ${user.token_user}`,
        };

        try {
            await api.post(
                `usuario/generate-code`,
                {
                    type: "whatsapp",
                },
                { headers }
            );
        } catch (error: any) {
            return error?.response;
        }
    }

    async function confirmCode(code: string) {
        const headers = {
            authorization: `Bearer ${user.token_user}`,
        };

        try {
            const response = await api.post(
                `usuario/confirm-code`,
                {
                    code: code,
                    is_login: true,
                },
                { headers }
            );

            localStorage.setItem("user", JSON.stringify(user));

            setModal(false);
            handleOpen("cadastroEfetuado");
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    async function confirmAuthCode(code: string) {
        try {
            const response = await api.post<any>(
                "/usuario/confirm-code",
                { code, is_login: true },
                {
                    headers: { Authorization: `Bearer ${user.token_user}` },
                }
            );

            if (user.first_update_password === 0) {
                return response;
            }

            if (response.data.success) {
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("campanha", JSON.stringify(campanhas));
                window.location.href = "/dashboard";
                return response;
            } else {
                toast.error("Code inválido");
                return response;
            }
        } catch (error: any) {
            console.log(error);
            toast.error("Code inválido");
            return error;
        }
    }

    async function logout() {
        try {
            await api.post(`logout`);
            localStorage.setItem("user", JSON.stringify({}));
            localStorage.setItem("campanha", JSON.stringify({}));
            window.location.href = "/dashboard";
        } catch (error: any) {
            return error?.response;
        }
    }

    function forceLogin() {
        handleClose("resetarSenha");
        localStorage.setItem("user", JSON.stringify({}));
        localStorage.setItem("campanha", JSON.stringify({}));
    }

    useEffect(() => {
        if (codeWhatsapp !== "") {
            setStateCodeWhatsapp(codeWhatsapp);
        }
    }, [codeWhatsapp]);

    return (
        <UsePassosContext.Provider
            value={{
                registro,
                enderecoStore,
                login,
                loginWithCampanha,
                loading,
                checkDocument,
                checkAccountCampanha,
                assignUserCampanha,
                checkCampanha,
                campanhas,
                formValuesRegister,
                setFormValuesRegister,

                setCodeType,
                codeType,

                getCode,
                confirmCode,

                setModal,
                modal,
                user,
                logout,
                confirmAuthCode,
                forceLogin,
                firstLogin,
            }}
        >
            {children}
        </UsePassosContext.Provider>
    );
}

export function UseLogin() {
    const context = useContext(UsePassosContext);

    return context;
}
