import {
    GridColumns,
    GridRenderCellParams,
    GridRowsProp,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { Log } from "../../../Hook/system/useLogCarga";
import ModalAprove from "../../../components/System/ModalAlert";
import imgLixeira from "../../../assets/img/x-vermelho.svg";
import TableDetalheCarga from "../Table";
import { TableStyles } from "./styles";
import { toast } from "react-toastify";
import { api } from "../../../services";

interface TableProps {
    data: Log[];
    type?: string;
    setError: (value: boolean) => void;
    setAlert: (value: boolean) => void;
}

export function Table(props: TableProps) {
    const { data, setError, setAlert, type } = props;
    const [id, setId] = useState<string | null>(null);
    const [modalExcluir, setModalExcluir] = useState(false);

    const [collumnsTemp, setColumnsTemp] = useState<GridColumns>([]);
    const [dadosTemp, setDadosTemp] = useState<GridRowsProp>([]);

    async function deleteRelatorio() {
        try {
            await api.delete(`carga/${data[0].carga_id}/logs/${id}/CREDITO`);
            const update = dadosTemp.filter((data) => data.id !== id);
            setDadosTemp(update);
            toast.success("Excluído com sucesso");
        } catch (error) {
            toast.error("Erro ao excluir");
        }
    }

    useEffect(() => {
        if (data?.length) {
            const columns: GridColumns = Object.keys(
                JSON.parse(data[0].data)
            ).map((obj, key, arr) => {
                return {
                    field: obj,
                    headerName: obj.toLocaleUpperCase(),
                    width: 1280 / (arr.length + 2),
                };
            });

            const rows = data?.map((dados, key) => {
                const data = {
                    id: dados.id,
                    ...JSON.parse(dados.data),
                };

                if (dados.regra.tipo === "Alerta") {
                    data.tipo = dados.regra.tipo.toLocaleLowerCase();
                    data.error = dados.regra.name;
                    setAlert(true);
                }

                if (dados.regra.tipo === "Erro") {
                    data.tipo = dados.regra.tipo.toLocaleLowerCase();
                    data.error = dados.regra.name;
                    setError(true);
                }

                if (!data.error) {
                    data.error = "NENHUM";
                }

                return data;
            });

            columns.unshift({ field: "id" });
            columns.push({
                field: "error",
                headerName: "ERRO",
                width: 1280 / columns.length,
            });

            if (type === "creditos_log") {
                columns.push({
                    field: "excluir",
                    headerName: "EXCLUIR",
                    width: 150,
                    renderCell: ({ row }: GridRenderCellParams) => {
                        return (
                            <button
                                onClick={() => {
                                    setId(row.id);
                                    setModalExcluir(true);
                                }}
                                className={`${
                                    row.status === "ATIVO" ? "" : "SUSPENSA"
                                }`}
                            >
                                <img src={imgLixeira} alt="lixeira" />
                            </button>
                        );
                    },
                });
            }

            setColumnsTemp(columns);
            setDadosTemp(rows);
        }
    }, [data, setError, setAlert, type]);

    return (
        <TableStyles>
            <TableDetalheCarga
                columns={collumnsTemp}
                rows={dadosTemp}
                nameCSV="LOG-CARGAS"
            />

            <ModalAprove
                handleClose={() => setModalExcluir(false)}
                reject={() => setModalExcluir(false)}
                aceept={async () => {
                    if (id) {
                        await deleteRelatorio();
                        setModalExcluir(false);
                        return;
                    }
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalExcluir}
            />
        </TableStyles>
    );
}
