import styled from "styled-components";

import imgCadeado from "../../../assets/img/cadeado.svg";
import imgCartaoCredito from "../../../assets/img/card-credito-verde.svg";
import imgCarga from "../../../assets/img/carga.svg";
import imgCartao from "../../../assets/img/cartao.svg";
import imgCredito from "../../../assets/img/credito.svg";
import imgEstorno from "../../../assets/img/estorno-cred.svg";
import imgCliente from "../../../assets/img/menu/cliente.svg";
import imgUsuarios from "../../../assets/img/user.svg";

export const Container = styled.div`
    display: flex;
    height: max-content;
    .container-tabs {
        width: calc(100% - 308px);
        min-height: calc(100vh - 101px);
        height: max-content;
    }
`;

export const InformacaoLateralStyles = styled.div`
    padding-top: 52px;
    width: 308px;
    padding-right: 80px;
    border-right: 1px solid #e1e2e3;
    .container-header {
        .voltar-page {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 81px;
            margin-bottom: 45px;
        }

        .acaoes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 36px;
            border-bottom: 1px solid #c6c8ca;
            .acao-editar {
                width: 78px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .informacao-campanha {
            padding-top: 39px;
            padding-bottom: 39px;
            border-bottom: 1px solid #c6c8ca;
            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: ${({ theme }) => theme.fonts.mdl};
                line-height: 120%;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primary};
                margin-bottom: 28px;
            }

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: ${({ theme }) => theme.fonts.md};
                line-height: 100%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primary};
                margin-bottom: 16px;
            }
        }

        .detalhes {
            padding-top: 32px;
            .detalhe {
                display: flex;
                align-items: center;
                margin-bottom: 42px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .text {
                p {
                    white-space: nowrap;
                }
            }
            .card {
                background: ${({ theme }) => theme.colors.backgroundAlt};
                border-radius: 4px;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
            }
        }
    }
`;

export const HeaderStyles = styled.div`
    padding-left: 92px;
    padding-right: 38px;
    width: 100%;
    height: 101px;

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 100%;
        border-bottom: 1px solid #e1e2e3;
        li {
            height: 100%;
            button {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primaryDark};
                position: relative;
                padding-left: 32px;
                height: 100%;
                border-bottom: 1px solid transparent;
                transition: all 0.3s ease;
                &.active {
                    border-bottom: 1px solid
                        ${({ theme }) => theme.colors.secondary};
                    color: ${({ theme }) => theme.colors.secondary};
                    transition: all 0.3s ease;
                    &:before {
                        background-color: ${({ theme }) =>
                            theme.colors.secondary};
                        transition: all 0.3s ease;
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    background-color: ${(props) =>
                        props.theme.colors.primaryBright};
                    z-index: 20;
                    transition: background-color 0.5s;
                }
                &.infra {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCarga}) no-repeat;
                    }
                }
                &.credito {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCredito}) no-repeat;
                    }
                }
                &.produtos {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCartao}) no-repeat;
                    }
                }
                &.usuarios {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgUsuarios}) no-repeat;
                    }
                }
                &.regulamento {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCliente}) no-repeat;
                    }
                }
                &.antifralde {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCadeado}) no-repeat;
                    }
                }
                &.cartao-de-credito {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgCartaoCredito}) no-repeat;
                    }
                }
                &.estorno-de-cred {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${imgEstorno}) no-repeat;
                    }
                }
            }
        }
    }
`;
