import styled from "styled-components";
import Aprovar from "../../../../assets/img/aprovar.svg";
import Aprovado from "../../../../assets/img/aprovado.svg";
import Reprovado from "../../../../assets/img/reprovado.svg";

export const Container = styled.div`
    width: 100%;
    height: 101px;
    border-bottom: 1px solid #e1e2e3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 785px;
        li {
            height: 100%;
            button {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primaryDark};
                position: relative;
                padding-left: 44px;
                height: 100%;
                border-bottom: 1px solid transparent;
                transition: all 0.3s ease;
                &.active {
                    border-bottom: 1px solid
                        ${({ theme }) => theme.colors.secondary};
                    color: ${({ theme }) => theme.colors.secondary};
                    transition: all 0.3s ease;
                    &:before {
                        background-color: ${({ theme }) =>
                            theme.colors.secondary};
                        transition: all 0.3s ease;
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                    background-color: ${(props) =>
                        props.theme.colors.primaryBright};
                    z-index: 20;
                    transition: background-color 0.5s;
                }
                &.aprovar {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${Aprovar}) no-repeat;
                    }
                }
                &.aprovados {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${Aprovado}) no-repeat;
                    }
                }
                &.reprovados {
                    &:before {
                        width: 20px;
                        height: 20px;
                        -webkit-mask: url(${Reprovado}) no-repeat;
                    }
                }
            }
        }
    }

    .container-btns {
        display: flex;
        align-items: center;
        width: 249px;
        button {
            margin-left: auto;
        }
    }
`;
