import { ModalConfig } from "../config";
import ModalRight from "../../ModalRight";
import { MenuModalDesktopMobileMemorized } from "../../MenuModalDeskMobile";

export function Menu(props: ModalConfig) {
    const { handleClose, open } = props;

    return (
        <ModalRight open={open} handleClose={handleClose}>
            <MenuModalDesktopMobileMemorized />
        </ModalRight>
    );
}
