import { useFaleConosco } from "../../Hook/user/UseFaleConosco";
import { FormFaleConosco } from "./form";
import { Mensagen } from "./mensagens";
import { Loader } from "../Loader";
import { TabStyles } from "./styles";
import { IArrTabs } from ".";
import { SemMensagem } from "./semMensagem";

interface TabsProps {
    handleTab(str: "mensagens" | "enviar"): void;
    tabs: IArrTabs[];
    setSuccess: (value: boolean) => void;
}

export function Tabs(props: TabsProps) {
    const { handleTab, tabs, setSuccess } = props;
    const { faleConosco, loading } = useFaleConosco();

    if (loading && faleConosco.length === 0) {
        return <SemMensagem />;
    }

    function handleSuccess(){
        setSuccess(true);
        handleTab("mensagens")
    }

    return (
        <TabStyles>
            <div
                className={`tab-fale-conosco ${tabs[0].active ? "active" : ""}`}
            >
                {loading && (
                    <div className="container-loading">
                        <Loader />
                    </div>
                )}
                {faleConosco.map((fale) => (
                    <Mensagen {...fale} key={fale.id} />
                ))}
            </div>

            <div
                className={`tab-fale-conosco ${tabs[1].active ? "active" : ""}`}
            >
                <FormFaleConosco handleSuccess={handleSuccess} />
            </div>
        </TabStyles>
    );
}
