import { UseModal } from "../../../Hook/Usemodal";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { Validadar } from "../ValidarDeslogado";
import imgX from "../../../assets//img/x-black-btn.svg";
import {
    CloseBtnContainer,
} from "./styles";

interface ModalCheckUserProps {
    phone: string;
}

export function ModalCheckUser(props: ModalCheckUserProps) {
    const { phone } = props;
    const { handleClose, setState, state } = UseModal();
    const { user } = UseLogin();
    return (
        <>
        <Validadar
            pt={true}
            phone_number={phone || ""}
            handleClick={() => {
                if (user.first_update_password !== 0) {
                    handleClose("loginCheck");
                } else {
                    setState({
                        ...state,
                        loginCheck: false,
                        resetarSenha: true,
                    });
                }
            }}
        />
            <CloseBtnContainer >
                <button onClick={() => handleClose("loginCheck")} >
                    <img src={imgX} alt=""/>
                </button>
            </CloseBtnContainer>
        </>
        
    );
}
