import { PrecisoDeAjuda } from "../../../components/PrecisoDeAjuda";
import { UsePrecisoDeAjudaProvider } from "../../../Hook/user/UsePrecisoDeAjuda";

export function PagePrecisoDeAjuda() {
    return (
        <UsePrecisoDeAjudaProvider>
            <PrecisoDeAjuda />
        </UsePrecisoDeAjudaProvider>
    );
}
