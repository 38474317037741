import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";

import { api } from "../../services/";
import {
    formatDateIso,
    getCampanha,
    getFirsDayMonth,
    getLastDayMonth,
} from "../../util/helper";
import { UseModal } from "../Usemodal";

interface UseExtratoProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseExtrato {
    extratos: Extrato[];
    getExtrato: (data?: Date) => Promise<any>;
    loadingExtrato: boolean;
}

export interface Extrato {
    id: number;
    user_id: number;
    carga_credito_id: number;
    resgate_id: null | number;
    cupom_item_id: null | number;
    tipo: string;
    ctipo: string;
    valor: string;
    created_at: string;
    updated_at: string;
    acg_account_id: string;
    acg_account_card_number: string;
    acg_account_card_last4digits: string;
    descricao: string;
}

const UseExtratoContext = createContext<UseExtrato>({} as UseExtrato);

export function UseExtratoProvider(props: UseExtratoProviderProps) {
    const { children } = props;
    const history = useHistory();
    const [extratos, setExtratos] = useState<Extrato[]>([]);
    const [loadingExtrato, setLoadingExtrato] = useState(false);
    const { state } = UseModal();
    const path = history.location.pathname;

    const getExtrato = useCallback(async (data?: Date) => {
        setLoadingExtrato(true);
        const hasCampanha = getCampanha().id
            ? `&campanhas[0]=${getCampanha().id}`
            : "";
        const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");
        const ultimoDiaDoMes = formatDateIso(getLastDayMonth(data))
            .split(" - ")[0]
            .split(".")
            .reverse()
            .join("-");

        try {
            const { data } = await api.get<Extrato[]>(
                `/extrato?start=${primeiroDiaDoMes}&end=${ultimoDiaDoMes}${hasCampanha}`
            );

            setExtratos(data.reverse());
            setLoadingExtrato(false);
        } catch (error: any) {
            setLoadingExtrato(false);
            setExtratos([] as Extrato[]);
            return error?.response;
        }
    }, []);

    useEffect(() => {
        if (state?.extrato || path === "/movimentacao") {
            getExtrato();
        }
    }, [state?.extrato, getExtrato, path]);

    return (
        <UseExtratoContext.Provider
            value={{ extratos, getExtrato, loadingExtrato }}
        >
            {children}
        </UseExtratoContext.Provider>
    );
}

export function useExtrato() {
    const context = useContext(UseExtratoContext);
    return context;
}
