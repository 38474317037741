import { useEffect, useState } from "react";
import { Text, Box } from "alisson-application";
import { Link } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NovoCartao } from "../../Desktop/Novocartao";
import { UseModal } from "../../../Hook/Usemodal";
import imgDetalhe from "../../../assets/img/seta-cima-sistema.svg";
import { ModaisMenu } from "../../Modais/Mobile/menu";

import { Container } from "./styles";

import { Card } from "./card";
import {
    podesolicitarCartao,
    objCartaoes,
    getCampanha,
} from "../../../util/helper";
import { options } from "../../NovoSelect";
SwiperCore.use([Navigation, A11y, Pagination]);
export function Cartoes() {
    const { t } = useTranslation();
    const { cartoes, setCartaoId } = UseCartoes();
    const { state, setState, handleOpen } = UseModal();
    const [options, setOptions] = useState<options[]>([]);

    function handleClick() {
        if (!options.length) {
            setState({
                ...state,
                successGeral: { ...state?.successGeral },
                errorGeral: {
                    active: true,
                    message: cartoes.length
                        ? "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES"
                        : "VOCÊ NÃO POSSUI SALDO SUFICIENTE PARA SOLICITAR UM CARTÃO.",
                    title: "Erro",
                },
            });
        } else {
            handleOpen("diferencaCartoes");
        }
    }

    const cards = objCartaoes(cartoes);

    useEffect(() => {
        if (getCampanha().id) {
            setOptions(podesolicitarCartao([getCampanha()], cartoes));
        }
    }, [cartoes]);

    return (
        <Container>
            <div className="container">
                <Text
                    textTransform="uppercase"
                    fontSize="sm"
                    mb="16px"
                    bold={true}
                    color="secondary"
                >
                    {t("Meus cartões")}
                </Text>

                <Swiper
                    spaceBetween={9}
                    slidesPerView={3}
                    className="slide-cartoes"
                    pagination={true}
                >
                    {cards.map((card) => {
                        const { id, ...rest } = card;

                        let path = "";

                        if (card.status === "ATIVO")
                            path = "/detalhe-movimentacao";

                        if (card.status === "INATIVO")
                            path = `/ativar-cartao-avulso/${card.id}`;

                        return (
                            <SwiperSlide
                                key={card.id}
                                onClick={() => {
                                    if (card.status === "BLOQUEADO") {
                                        setState({
                                            ...state,
                                            acaoDesbloquearCartaoMobilePasso1:
                                                true,
                                        });
                                    }

                                    setCartaoId(id);
                                }}
                            >
                                <div className="icone-menu">
                                    <button
                                        onClick={() => {
                                            setCartaoId(id);
                                            setState({
                                                ...state,
                                                acoesCartaoMobile: true,
                                            });
                                        }}
                                    >
                                        <p>MENU</p>
                                        <img src={imgDetalhe} alt="" />
                                    </button>
                                </div>

                                <Link
                                    to={{
                                        pathname: path,
                                        state: {
                                            id: card.id,
                                        },
                                    }}
                                >
                                    <Card {...rest} />
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                    <SwiperSlide>
                        <Box height="143px">
                            <NovoCartao
                                onClick={handleClick}
                                empty={cartoes.length === 0}
                            />
                        </Box>
                    </SwiperSlide>
                </Swiper>
            </div>

            <ModaisMenu />
        </Container>
    );
}
