import { Text, Box, Flex } from "alisson-application";
import { formatDateIso, formatCurrentMoney } from "../../../util/helper";
import { useTranslation } from "../../../contexts/Localization";
import { Container } from "./styles";
import { Extrato } from "../../../Hook/user/useExtrato";

interface RelatorioProps extends Extrato {}

interface Dados {
    dados: RelatorioProps[];
}

export function Relatorio(props: Dados) {
    const { t } = useTranslation();

    return (
        <Container className="container">
            {props.dados.map((item, key) => {
                const {
                    created_at,
                    valor,
                    tipo,
                    acg_account_card_last4digits,
                    ctipo,
                    descricao,
                } = item;
                const fullDate = formatDateIso(created_at).split(" - ");
                let cardType = ctipo === "cartao_virtual";
                let cardNumber = "";

                if (cardType) {
                    cardNumber = acg_account_card_last4digits;
                } else {
                    cardNumber = acg_account_card_last4digits
                        ? acg_account_card_last4digits
                        : "";
                }
                return (
                    <>
                        <Text fontWeight="600" mb="16px">
                            {key === 0 ? fullDate[0] : ""}
                        </Text>
                        <div className="container-operacao">
                            <Flex
                                alignItems="start"
                                className={`tipo-op ${tipo}`}
                                justifyContent="space-between"
                            >
                                <Flex flexDirection="column" width="60%">
                                    <Text
                                        as="small"
                                        fontWeight="500"
                                        fontSize="xs"
                                    >
                                        {descricao ? (
                                            descricao
                                        ) : tipo === "credito" ? (
                                            t("ENTRADAS")
                                        ) : (
                                            <Text
                                                as="small"
                                                fontWeight="500"
                                                fontSize="xs"
                                            >
                                                Resgate para o cartão <br />{" "}
                                                final
                                                {` ${cardNumber.substring(
                                                    cardNumber.length - 4
                                                )}`}
                                            </Text>
                                        )}
                                    </Text>
                                    <Text
                                        as="small"
                                        color="primaryDark"
                                        fontSize="xss"
                                    >
                                        {fullDate[1]}
                                    </Text>
                                </Flex>

                                <Box width="30%">
                                    <Text as="h3" fontWeight="500">
                                        {tipo === "credito" ? "+" : "-"} R${" "}
                                        {formatCurrentMoney(parseFloat(valor))}
                                    </Text>
                                </Box>
                            </Flex>
                        </div>
                    </>
                );
            })}
        </Container>
    );
}
