/* eslint-disable indent */
import styled, { css } from "styled-components";

export const UpdateContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;

    input:disabled {
        text-align: start;
        text-transform: capitalize;
        margin: 0 0 20px;
    }

    h5 {
        padding: 15px 0 40px;
    }

    h1 {
        font-family: Oxanium;
        font-size: 20px;
        font-weight: 800;
        line-height: 25px;
        text-align: center;
        color: ${(props) => props.theme.colors.secondary};
        text-transform: uppercase;
    }
`;

export const CustomDescription = styled.p`
    font-family: Oxanium;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    width: 100%;
    max-width: 250px;
    margin: 20px 0 40px;
`;

export const UpdateForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    margin: 0 0 20px;
`;

const DefaultText = styled.p`
    text-align: center;
    font-family: "Oxanium";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;

export const PasswordWarning = styled(DefaultText)`
    display: flex;
    flex-direction: column;

    width: max-content;

    b {
        font-weight: 900;
    }

    h5 {
        margin: 0 0 20px;
        padding: 0;

        font-size: 14px;
        font-family: Oxanium;
        font-weight: 500;

        b {
            font-weight: 900;
            text-transform: uppercase;
        }
    }
`;

interface IPasswordConditionProps {
    $isCorrect?: boolean;
}

export const PasswordCondition = styled.span<IPasswordConditionProps>`
    display: flex;
    align-items: center;
    text-align: start;

    svg {
        margin-right: 10px;

        ${({ $isCorrect }) =>
            $isCorrect
                ? css`
                      path {
                          fill: ${(props) => props.theme.colors.secondary};
                      }
                  `
                : ""}
    }
`;
