import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    .container-tab {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s ease;
        padding-left: 92px;
        padding-right: 38px;
        padding-top: 59px;
        &.remove-padding {
            padding: 0px;
        }
        &.active {
            pointer-events: all;
            opacity: 1;
            transition: all 0.3s ease;
        }

        .container-loading {
            position: relative;
            height: 50vh;
        }
    }
`;
