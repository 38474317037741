import { useState, ChangeEvent } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import imgOlho from "../../../assets/img/olho.svg";

import { IAlterarSenha, altearSenha, validationAlterarSenha } from "./config";
import { FormStyles } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";

interface FormAtivarCartaoProps {
    handleSubimit: (values: IAlterarSenha) => void;
}

export function FormAtivarCartao(props: FormAtivarCartaoProps) {
    const { handleSubimit } = props;
    const [olhoSenha, setOlhoSenha] = useState(false);
    const { handleClose} = UseModal();
    
    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={altearSenha}
                validationSchema={validationAlterarSenha}
                onSubmit={(values) => {
                    handleSubimit(values);
                }}
            >
                {(props: FormikProps<IAlterarSenha>) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={4}
                                    value={values.password}
                                    autoComplete="new-password"
                                    placeholder="Digite a Senha do Cartão"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div className="mt-auto">
                                <Button type="submit" disabled={!props.isValid}>
                                    Avançar
                                </Button>
                                <button className="cancelar" onClick={() => handleClose("segundaVia")}>
                                    Voltar
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
