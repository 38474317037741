import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageNovoCartao } from "../../../pages/NovoCartaoDigital";

export function NovoCartao(props: ModalConfig) {
    const { handleClose, open } = props;

    return (
        <Modal open={open} handleClose={handleClose}>
            <PageNovoCartao />
        </Modal>
    );
}
