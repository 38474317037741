import { useSegmentacao } from "../../../Hook/system/useSegmentacao";
import { SegmentacoesArea } from "./segmentacaoArea";
import { HeaderNovo } from "../Header/novo";
import { Cliente } from "./cliente";

export function Segmentacao() {
    const { segmentacao, setSegmentacao, setCampanhas, setSegmentacaoArea } =
        useSegmentacao();

    return segmentacao.length > 0 ? (
        <>
            <HeaderNovo
                title="SELECIONAR CAMPANHAS"
                ml="10"
                onClick={() => {
                    setSegmentacao([]);
                    setSegmentacaoArea([]);

                    setCampanhas([]);
                }}
            />
            {/* <Segmentacoes /> */}
            <SegmentacoesArea />
        </>
    ) : (
        <>
            <Cliente />
        </>
    );
}
