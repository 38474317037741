import { UserProps } from "../../Hook/user/UseUser";
import { UseModal } from "../../Hook/Usemodal";
import { EnderecoStyles } from "./styles";
import imgGps from "../../assets/img/endereco.svg";
import { formatCep } from "../../util/helper";

export function Endereco(props: UserProps) {
    const { enderecos = [] } = props;
    const { setState, state } = UseModal();

    return (
        <EnderecoStyles>
            <span>ENDEREÇO CADASTRADO</span>

            <div className="container-endereco">
                <div className="container-img">
                    <img src={imgGps} alt="" />
                </div>

                <div className="container-text">
                    <span>{enderecos[0]?.nome}</span>

                    <p>
                        Nº {enderecos[0]?.numero} {enderecos[0]?.complemento}
                    </p>
                    <p>
                        {enderecos[0]?.bairro} {enderecos[0]?.cidade} -{" "}
                        {enderecos[0]?.estado}
                    </p>
                    <p>CEP: {formatCep(enderecos[0]?.cep || "")}</p>
                    <button
                        onClick={() => {
                            setState({
                                ...state,
                                perfil: false,
                                alterarEndereco: true,
                            });
                        }}
                    >
                        EDITAR ENDEREÇO
                    </button>
                </div>
            </div>
        </EnderecoStyles>
    );
}
