import styled, { css } from "styled-components";
import Yimg from "../../../assets/img/Y.svg";
import imgOlho from "../../../assets/img/olho.svg";

const status = css`
    filter: grayscale(1);
`;

interface CardProps {
    background: string;
    status: boolean;
}

export const CardStyles = styled.div<CardProps>`
    /* box-shadow: ${(props) =>
        props.status ? "none" : "0px 20px 40px rgba(42, 82, 39, 0.2)"}; */
    border-radius: 8px;
    height: auto;
    padding: 31px 13px 33px 23px;
    min-height: 100%;
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .dados-cartao {
        border: 0.32px solid #ffffff;
        border-radius: 1.29px;
        padding: 6px 10px;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 106%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.white};
        text-transform: uppercase;
        position: relative;
        z-index: 99;
    }

    .container-bandeira {
        button {
            background: ${(props) => props.theme.colors.white};
            height: 31px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 13px;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.secondary};
            border-radius: 64px;
            img {
                display: block;
                margin-left: 7px;
            }
        }
    }

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(${Yimg}), ${(props) => props.background};
        background-size: cover;
        background-repeat: no-repeat;
        ${(props) => props.status && status}
        border-radius: 8px;
        z-index: -1;
    }
    p {
        &.type {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: ${(props) => props.theme.colors.white};
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;

            button {
                display: flex;
                align-items: center;
                margin-bottom: 3px;
            }
        }
    }
    .saldo {
        border: 0.658986px solid ${(props) => props.theme.colors.white};
        height: 35px;
        border-radius: 2.63594px;
        display: flex;
        align-items: center;
        margin-bottom: 23px;
        padding-left: 8px;
        padding-right: 8px;
        &.show {
            .dinheiro {
                &:before,
                &:after {
                    opacity: 0;
                    transition: opacity 0.5s;
                }
            }
        }
        &.active {
            .dinheiro {
                &:before {
                    animation: shimmer 1.2s ease-in-out infinite;
                }
            }
        }

        .olho {
            position: relative;
            width: 34px;
            height: 24px;
            &.active {
                &:after {
                    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
                    transition: transform 0.5s;
                }
            }
            &:after {
                content: "";
                width: 110%;
                height: 2px;
                background: ${(props) => props.theme.colors.white};
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg) scale(0);
                transition: transform 0.5s;
                pointer-events: none;
            }
            &:before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                -webkit-mask: url(${imgOlho});
                background-color: white;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 8px;
                z-index: -1;
                pointer-events: none;
            }
        }
        .dinheiro {
            display: flex;
            align-items: end;
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 24px;
            margin-right: 4px;
            margin-left: 4px;
            &.fisico {
                &:after {
                    background-image: linear-gradient(
                        90deg,
                        #50864c 12.24%,
                        #65a35f 107.14%
                    );
                }
                &:before {
                    background-image: linear-gradient(
                        90deg,
                        #50864c 12.24%,
                        #65a35f 107.14%
                    );
                }
            }
            &.virtual {
                &:after {
                    background-image: linear-gradient(
                        90deg,
                        #16343b 12.24%,
                        #1e5e6c 107.14%
                    );
                }
                &:before {
                    background-image: linear-gradient(
                        90deg,
                        #16343b 12.24%,
                        #1e5e6c 107.14%
                    );
                }
            }
            &:after {
                content: "";
                position: absolute;
                color: grey;
                display: inline-block;
                background-repeat: no-repeat;
                font-size: 50px;
                max-width: 200px;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                width: 100%;
                height: 24px;
                opacity: 1;
                transition: opacity 0.5s;
            }
            &:before {
                content: "";
                position: absolute;
                color: grey;
                display: inline-block;
                background-image: linear-gradient(
                    90deg,
                    #50864c 12.24%,
                    #65a35f 107.14%
                );
                background-repeat: no-repeat;
                font-size: 50px;
                max-width: 200px;
                top: 0px;
                left: 0px;
                transform: translateX(-100%);
                width: 100%;
                height: 24px;
                z-index: 99;
                opacity: 1;
                transition: opacity 0.5s;
            }
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 10.5438px;
            line-height: 100%;
            display: block;
            padding-top: 2.5px;
            margin-right: 6.52px;
            color: ${(props) => props.theme.colors.white};
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            color: ${(props) => props.theme.colors.white};
        }
    }

    .validade {
        margin-bottom: 11px;
        span {
            display: block;
            font-style: normal;
            font-weight: 500;
            font-size: 9px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.white};
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 17.2798px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.white};
        }
    }

    .bandeira {
        text-align: right;
        ${(props) => props.status && status}
    }

    .number-card {
        margin-bottom: 16px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 100%;
            letter-spacing: 0.06em;
            white-space: nowrap;
            color: ${(props) => props.theme.colors.white};
        }
    }
`;
