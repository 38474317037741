import { memo } from "react";

import { CreditoProvider } from "../Hook";

import { TableCargaMemorized } from "./table";
import { UploadMemo } from "./upload";
import { Reeview } from "./reeview";
import { Container } from "./styles";

function Credito() {
    return (
        <Container>
            <CreditoProvider>
                <UploadMemo />
                <Reeview />
                <TableCargaMemorized />
            </CreditoProvider>
        </Container>
    );
}

export const CreditoMemorized = memo(Credito);
