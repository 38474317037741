import { useState, ChangeEvent, useEffect } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { IAtivarCarao, ativar, validationAtivarCartao } from "./config";
import { FormStyles } from "./styles";
import imgOlho from "../../../assets/img/olho.svg";

export function FormAtivarCartao() {
    const [olhoSenha, setOlhoSenha] = useState(false);
    const { nextPasso, setDados } = usePassos();
    const { cartao, cartaoId } = UseCartoes();
    const [values, setValues] = useState<IAtivarCarao>({} as IAtivarCarao);

    useEffect(() => {
        if (cartao?.tipo === "cartao_virtual") {
            setValues({ ...ativar });
        } else {
            setValues(ativar);
        }
    }, [cartao?.tipo]);

    useEffect(() => {
        if (cartao?.tipo === "cartao_virtual") {
            setValues({ ...ativar });
        } else {
            setValues(ativar);
        }
    }, [cartao?.tipo]);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={values}
                validationSchema={validationAtivarCartao}
                onSubmit={(values, actions) => {
                    const { confirm_password, ...resto } = values;
                    setDados({
                        ...resto,
                        cartao_id: cartaoId,
                        cvv: values.cvv,
                    });
                    actions.resetForm();
                    nextPasso(2);
                }}
            >
                {(props: FormikProps<IAtivarCarao>) => {
                    const { values, setFieldValue } = props;
                    const { cvv } = values;

                    return (
                        <Form>
                            <small>Senha numérica de 4 dígitos</small>
                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={4}
                                    autoComplete="new-password"
                                    value={values.password}
                                    placeholder="CRIAR SENHA DO CARTÃO"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.confirm_password &&
                                    props.touched.confirm_password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="confirm_password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "confirm_password",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    autoComplete="new-password"
                                    maxLength={4}
                                    value={values.confirm_password}
                                    placeholder="CONFIRMAR SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="confirm_password"
                                component="span"
                            />

                            <div
                                className={`container-input cartao-ativar ${
                                    cartao?.tipo === "cartao_virtual" &&
                                    "active disabled"
                                } ${
                                    props.errors.cvv && props.touched.cvv
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="cvv"
                                    type="tel"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) =>
                                        setFieldValue(
                                            "cvv",
                                            e.target.value.replace(
                                                /[^\d]+/g,
                                                ""
                                            )
                                        )
                                    }
                                    maxLength={3}
                                    autoComplete="new-password"
                                    value={
                                        cartao?.tipo === "cartao_virtual"
                                            ? cartao?.acg_account_card_cvv
                                                ? cartao?.acg_account_card_cvv
                                                : "cvv: "
                                            : cvv
                                    }
                                    placeholder="CVV "
                                />
                            </div>
                            <ErrorMessage name="cvv" component="span" />

                            {/* <button
                                onClick={() => {
                                    setState({
                                        ...state,
                                        cvv: true,
                                    });
                                }}
                                type="button"
                                className="open-modal-cvv"
                            >
                                Onde encontrar o CVV
                            </button> */}

                            <div className="mt-auto">
                                <Button type="submit" disabled={!props.isValid}>
                                    ATIVAR CARTÃO
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
