import { ModalConfig } from "../config";

import { Validadar } from "../../Mobile/Validar";
import { useUser } from "../../../Hook/user/UseUser";
import { UseModal } from "../../../Hook/Usemodal";
import Modal from "../../NovoModal";

export function ModalUpdateEndereco(props: ModalConfig) {
    const { open, handleClose } = props;
    const { updateEndereco, updateEnderecoUser } = useUser();
    const { setState, state } = UseModal();

    async function update() {
        const response = await updateEndereco(updateEnderecoUser);

        if (response.status === 200) {
            setState({
                ...state,
                alterarEndereco: false,
                updateEndereco: false,
                perfil: true,
            });
        }
    }

    return (
        <Modal open={open} handleClose={handleClose}>
            {open ? <Validadar handleClick={update} pt={true} /> : <></>}
        </Modal>
    );
}
