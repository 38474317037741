import { Text } from "alisson-application";
import { useState } from "react";
import { useTranslation } from "../../../contexts/Localization";
import { CardBack } from "../CardBack";
import { FormCartao } from "./form";

export function Cartao() {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    return (
        <>
            <div className="container">
                <CardBack noname={true} value={value} descricao={false} />
                <Text as="p">
                    {t("Preencha o campo abaixo para solicitar seu cartão.")}
                </Text>
            </div>

            <FormCartao setValue={setValue} />
        </>
    );
}
