import styled from "styled-components";

export const ChannelContainer = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 30px 180px 1fr;

    gap: 10px;
    padding: 20px 0 20px;
    margin: 30px 0;
    position: relative;

    svg {
        font-size: 27px;
    }

    fieldset {
        display: none;
    }

    & + & {
        margin: 15px 0 0;

        fieldset {
            display: flex;
            position: absolute;
            top: -20px;
            width: 100%;
        }
    }
`;

export const InputSection = styled.section`
    position: relative;

    span {
        font-size: 12px;
        font-family: Oxanium;
        margin: 0 10px 10px;

        position: absolute;
        top: -20px;
        left: 5px;
    }

    input {
        margin: 0;
        font-size: 12px;
    }
`;

export const CustomBtn = styled.button`
    width: 100%;
    height: 40px;
    max-width: 327px;
    text-align: center;

    font-family: Oxanium;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 0 10px;

    color: #fff;
    background: ${(props) => props.theme.colors.secondary};
    cursor: pointer;
    border-radius: 100px;

    transition: all 0.4s;

    &:hover {
        transform: scale(1.1);
    }
`;

export const DisabledInput = styled.input`
    max-width: 327px;
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border-radius: 100px;
    background: #e3e3e3;
    color: #5e5f5f;
    border: none;

    font-family: "Oxanium";
    font-size: 16px;
    font-weight: 700;
    text-align: center;

    margin-bottom: 10px;

    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
    }
`;
