import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 15px;
    padding-top: 35px;
    position: relative;


    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
        color: ${({ theme }) => theme.colors.secondary};
        margin-top: 22px;
        margin-bottom: 21px;
    }

    h5 {
        margin-bottom: 48px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #5e5f5f;
    }

    .container-itens {
        margin-bottom: 10px;
        .container-descricao {
            margin-bottom: 32px;
            &:last-child {
                margin-bottom: 0;
            }
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 100%;
                color: #5E5F5F;
                text-align: center;
            }
            .text-item {
                margin:3px 25px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 120%;
                text-align: center;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }

    button{
        width: 285px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
        span{
            font-weight: 400;
            color: #5E5F5F;
        }
    }

    .container-input {
        width: 100%;
    }
`;
