import { FaqProvider } from "../../../../Hook/system/useFaq";
import { EditarFaq } from "../../../../components/System/Faq/editar";

export function PageEditarFaq() {
    return (
        <FaqProvider>
            <EditarFaq />
        </FaqProvider>
    );
}
