import * as Yup from "yup";

export interface IAlterarSenha {
    password: string;
}

export const altearSenha: IAlterarSenha = {
    password: "",
};

export const validationAlterarSenha = Yup.object().shape({
    password: Yup.string()
        .min(4, "Senha deve ter 4 números")
        .required("Senha é obrigatório"),
});
