import { useCallback, useEffect, useState } from "react";
import { Cartoes, Saldo } from "../../../Hook/user/UseCartaoes";
import IconBandeira from "../../../assets/img/bandeira.svg";
import IconOlho from "../../../assets/img/olho-branco.svg";

import { Container } from "./styles";
import { UseModal } from "../../../Hook/Usemodal";
import { api } from "../../../services";
import { formatCurrentMoney } from "../../../util/helper";
import { Processando } from "../processando";

export function Card(props: Cartoes) {
    const { acg_account_card_last4digits, id, status } = props;
    const { setState, state } = UseModal();
    const [loading, setLoading] = useState(false);
    const [currentSaldo, setCurrentSaldo] = useState("");

    const fetchData = useCallback(async () => {
        setLoading(true);
        setCurrentSaldo("");
        try {
            const dados = await api.get<Saldo>(`cartao/${id}/saldo`);
            setCurrentSaldo(dados.data.saldo);
        } catch (error) {
            setLoading(false);
            setState({
                ...state,
                visualizarDados: false,
                errorGeral: {
                    active: true,
                    title: "Ops",
                    message: "Algo de errado aconteceu, tente novamente!",
                },
            });
            return "";
        }
    }, [id, state, setState]);

    useEffect(() => {
        if (status === "ATIVO") {
            fetchData();
        }
    }, [fetchData, status]);

    return (
        <Container>
            <div className="container-card">
                {status === "ATIVANDO" ? <Processando /> : <></>}
                <div className="container-logo ">
                    <img src={IconBandeira} alt="" />
                </div>

                <div className={`container-saldo ${loading ? "active" : ""}`}>
                    <small>R$</small>
                    <p
                        className={`dinheiro fisico ${
                            currentSaldo !== "" ? "show" : ""
                        }`}
                    >
                        {formatCurrentMoney(parseFloat(currentSaldo))}
                    </p>
                    <button
                        className={`container-olho ${
                            currentSaldo === "" ? "" : "hide"
                        }`}
                        onClick={fetchData}
                    >
                        <img src={IconOlho} alt="" />
                    </button>
                </div>

                <div className="container-numero-card">
                    <p>{acg_account_card_last4digits}</p>
                </div>

                <div className="container-informacoes">
                    <div className="container-validade">
                        <span>VALID TRU</span>
                        <p>**/**</p>
                    </div>
                    <div className="container-cvv">
                        <span>CVV</span>
                        <p>***</p>
                    </div>
                </div>

                <div className="title">
                    <h2>FÍSICO</h2>
                </div>
            </div>
        </Container>
    );
}
