import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { api } from "../../services/";

interface IPermission {
    id: number;
    name: string;
}

interface showPermission {
    created_at: string;
    guard_name: string;
    id: number;
    name: string;
    permissions: IPermission[];
}

interface UsePermissionProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UsePermissionData {
    userPermission: showPermission;
    allPermission: IPermission[];
}

const UsePermissionContext = createContext<UsePermissionData>(
    {} as UsePermissionData
);

export function PermissionProvider(props: UsePermissionProviderProps) {
    const { children } = props;
    const [userPermission, setUserPermission] = useState<showPermission>(
        {} as showPermission
    );
    const [allPermission, setAllPermission] = useState<IPermission[]>([]);

    const history = useHistory<{ id: number }>();
    const id = history.location.state?.id;

    async function getPermission() {
        try {
            const response = await api.get<IPermission[]>(`/permission`);

            setAllPermission(response.data);
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            setAllPermission([]);
        }
    }

    const showPermission = useCallback(async (id: number) => {
        try {
            const response = await api.get<showPermission>(`/role/${id}`);

            setUserPermission(response.data);
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            setUserPermission({} as showPermission);
        }
    }, []);

    useEffect(() => {
        if (id) {
            showPermission(id);
        }

        getPermission();
    }, [showPermission, id]);

    return (
        <UsePermissionContext.Provider
            value={{ userPermission, allPermission }}
        >
            {children}
        </UsePermissionContext.Provider>
    );
}

export function usePermission() {
    const context = useContext(UsePermissionContext);

    return context;
}
