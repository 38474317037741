import { useState } from "react";
import { ContainerButtonExport } from "./styles";
import { Button } from "../Button";
import imgDownloade from "../../../assets/img/dowload.svg";
import { useCupom } from "../../../Hook/system/useCupom";
interface ExportarCupomProps {
    id: number;
}
export function ExportarCupom(props: ExportarCupomProps) {
    const { exportar } = useCupom();
    const [loading, setLoading] = useState(false);

    return (
        <ContainerButtonExport>
            <Button
                isLoading={loading}
                transparent
                spiner="Oval"
                spinerColor="#57B847"
                onClick={async () => {
                    setLoading(true);
                    await exportar(props.id);
                    setLoading(false);
                }}
            >
                <img src={imgDownloade} alt="download" />
            </Button>
        </ContainerButtonExport>
    );
}
