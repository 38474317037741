/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";

import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { crypted, getLocalStore, phoneNumber } from "../../../util/helper";
import { CodeInput } from "../../CodeInput";

import { Reenviar } from "./reenviar";

import { Loader } from "../../Loader";
import {
    Container,
    Description,
    MobileNumberViewer,
    Title,
    LoaderContainer,
} from "./styles";

interface ValidarProps {
    handleClick: () => Promise<void>;
    pt?: boolean;
    phone_number?: string;
    autoSend?: boolean;
}

//usuário logado
export function Validadar(props: ValidarProps) {
    const { handleClick, phone_number, autoSend = true } = props;

    const token = getLocalStore().token_user;
    const { dados } = usePassos();
    const { confirmCode, confirmCodeSemToken } = useUser();
    const { validateUser, validateUserSemToken, user } = useUser();

    const [errorCode, setErrorCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState("");

    const mobile_number = phone_number || user?.phone_number;

    const sendSms = useCallback(async () => {
        if (phone_number && dados?.document) {
            await validateUserSemToken(
                "whatsapp",
                crypted(`document#${document}`)
            );
        } else {
            await validateUser("whatsapp");
        }
        setIsLoading(false)
    }, []);

    const handleConfirm = async () => {
        setIsLoading(true)
        setErrorCode(false)
        const responseCode =
            phone_number && !token
                ? await confirmCodeSemToken({
                    ...crypted(
                        `code#${code}`,
                        `document#${dados.document}`,
                        `email#${dados.email}`
                    ),
                    birth_date: dados.birth_date,
                })
                : await confirmCode(code);

        if (responseCode?.data?.success) {
            await handleClick();
        } else {
            setErrorCode(true);
        }

        setIsLoading(false)
    }

    useEffect(() => {
        if (autoSend) {
            sendSms();
        }
    }, []);

    useEffect(() => {

        const withOutBlanks = code.replaceAll(" ", "");
        if (withOutBlanks.length < 5) return;

        const delayInputCheck = setTimeout(() => {
            handleConfirm()
            return () => clearTimeout(delayInputCheck);
        }, 1500);
        return () => clearTimeout(delayInputCheck);

    }, [code]);


    return (
        <Container>
            <Title>Código de Verificação</Title>
            <Description>
                <b>Preencha com o código de verificação</b>
                <br/>enviado para o seu
                WhatsApp.
            </Description>

            {phone_number && (
                <MobileNumberViewer
                    type="text"
                    value={phoneNumber(phone_number)}
                    disabled
                />
            )}

            {isLoading 
            ? <LoaderContainer>
                <Loader width={75} height={75} />
            </LoaderContainer>
            : <CodeInput onChange={setCode} showError={errorCode} />}

            <Reenviar mobile_number={mobile_number} />
                
        </Container>
    );
}
