import styled from "styled-components";

export const Container = styled.div`
    padding: 35px 0;
    .container-informacoes {
        width: 95%;
        margin: 0 auto 30px;
        .item {
            padding-bottom: 19px;
            border-bottom: 1px solid #e1e2e3;
            margin-bottom: 19px;
        }
    }

    .buttonContainers{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:273px;
        margin-top:30px;
    }
`;
