import { FieldProps, ErrorMessage, Field } from "formik";
import Select, { options } from "../../NovoSelect";
import { useTemplate, Template } from "../../../../Hook/system/useTemplate";
import { DateTime } from "../../DateTime";
import { SmsPreview } from "../../Preview/";
import { formatDateTimeDb } from "../../../../util/helper";
import { useEffect, useState } from "react";

export function Sms(props: FieldProps) {
    const { form } = props;
    const { setFieldValue, values } = form;
    const { getTemplate } = useTemplate();
    const [template, setTemplate] = useState<Template[]>([]);

    const options: options[] = template.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const disparo: options[] = [
        {
            label: "IMEDIATO",
            value: 1,
        },
        {
            label: "AGENDADO",
            value: 2,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            const response = await getTemplate("SMS");
            setTemplate(response);
        }

        fetchData();
    }, [getTemplate]);
    return (
        <>
            <Select
                label="SELECIONAR TEMPLATE"
                options={options}
                onChange={(values) =>
                    setFieldValue("template_id", values.value)
                }
                icon={false}
            />
            <ErrorMessage component="span" name="template_id" />
            <Field
                component="textarea"
                name="conteudo"
                placeholder="DIGITE AQUI SUA MENSAGEM"
            />
            <ErrorMessage component="span" name="conteudo" />

            <Select
                label="DISPARO"
                options={disparo}
                onChange={(values) => {
                    setFieldValue("disparo", values.label.toLocaleLowerCase());
                    if (values.label === "IMEDIATO") {
                        setFieldValue("data_disparo", " ");
                    }
                }}
                icon={false}
            />
            <ErrorMessage component="span" name="disparo" />

            {values.disparo === "agendado" && (
                <DateTime
                    initialValue={null}
                    label="DATA/HORARIO DE DISPARO"
                    onChange={(dt_inicio) => {
                        setFieldValue(
                            "data_disparo",
                            formatDateTimeDb(dt_inicio).trim()
                        );
                    }}
                />
            )}
            <ErrorMessage component="span" name="data_disparo" />
            {values.template_id ? (
                <SmsPreview
                    text={
                        template.find((i) => i.id === values.template_id)?.text
                    }
                />
            ) : null}
        </>
    );
}
