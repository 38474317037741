import styled from "styled-components";

export const Container = styled.div``;

export const StylesForm = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    .toggle-item {
        align-items: center;
        & > span {
            margin: 0px;
        }
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 127.5%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primaryDark};
            width: 461px;
            margin-left: 32px;
        }
    }
`;
