import { CustomBtn, FirstUpdateContainer } from "./styles";

import imgWhats from "../../../../../../assets/img/whats-verde.svg";

interface IFirstUpdateProps {
    onNextPage: () => void;
}

export const FirstUpdate = ({ onNextPage }: IFirstUpdateProps) => {
    async function handleRequestWppToken() {
        try {
            if (onNextPage) onNextPage();
        } catch (error) {}
    }
    return (
        <FirstUpdateContainer>
            <figure>
                <img src={imgWhats} alt="" />
            </figure>

            <h1>Primeiro Acesso</h1>

            <p>
                Para continuar, realizaremos uma verificação de segurança e a
                redefinição da sua senha.
            </p>

            <p>
                Clique em <b>Solicitar Código por WhatsApp</b> E um código será
                enviado para o número de celular cadastrado em seu perfil.
            </p>

            <CustomBtn type="button" onClick={handleRequestWppToken}>
                Solicitar Código por WhatsApp
            </CustomBtn>
        </FirstUpdateContainer>
    );
};
