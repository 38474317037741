import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { useStateUser } from "../../../Hook/user/UseStateUser";
import { AtivarCartaoAutomatico } from "../../AtivarCartaoAutomatico";

export function ModalAtivarCartaoAutomatico(props: ModalConfig) {
    const { open, handleClose } = props;
    const { forceActivateAccountLogin } = useStateUser();

    return (
        <ModalBottom
            open={open}
            handleClose={() => {
                if (forceActivateAccountLogin) {
                    return;
                } else {
                    handleClose();
                }
            }}
        >
            <AtivarCartaoAutomatico />
        </ModalBottom>
    );
}
