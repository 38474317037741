import { SuccessStyles } from "./styles";
import imgcheck from "../../../assets/img/check.svg";

export function Success() {
    return (
        <SuccessStyles>
            <img src={imgcheck} alt="" />
            <p>PLANILHA sem erro</p>
        </SuccessStyles>
    );
}
