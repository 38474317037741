import styled from "styled-components";

export const Form = styled.div`
    button {
        background-color: ${({ theme }) => theme.colors.white};
    }
`;

export const Container = styled.div`
    overflow: hidden;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.colors.white};

    height: calc(100vh - 82px);
    display: flex;
    .img-fundo {
        height: 100%;
        img {
            background-color: ${({ theme }) => theme.colors.secondary};
            width: 554px;
            height: 100%;
        }
    }

    .box-login {
        margin-left: 225px;
        width: 327px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2 {
            font-style: normal;
            font-weight: 400;
            font-size: ${({ theme }) => theme.fonts.mdl};
            line-height: 100%;
            text-align: center;
            letter-spacing: 0.08em;
            margin-bottom: 19px;
        }

        form {
            width: 100%;
            button {
                background-color: ${({ theme }) => theme.colors.secondary};
                height: 56px;
                border-radius: 32px;
            }
            .input-login {
                width: 100%;
                margin-bottom: 16px;
                input {
                    background: ${({ theme }) => theme.colors.white};
                    border-radius: 100px;

                    font-family: "Oxanium";
                    font-style: normal;
                    font-weight: 500;
                    font-size: ${({ theme }) => theme.fonts.md};
                    line-height: 100%;
                    /* or 14px */

                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.1em;

                    color: ${({ theme }) => theme.colors.primaryDark};
                    border: none;
                    border: 1px solid ${({ theme }) => theme.colors.secondary};
                    width: 100%;
                    height: 60.4px;
                    margin-bottom: 8px;
                    &::placeholder {
                        font-family: "Oxanium";
                        font-style: normal;
                        font-weight: 500;
                        font-size: ${({ theme }) => theme.fonts.md};
                        line-height: 100%;
                        /* or 14px */

                        display: flex;
                        align-items: center;
                        text-align: center;
                        letter-spacing: 0.1em;
                        text-transform: uppercase;

                        color: ${({ theme }) => theme.colors.primaryDark};
                    }
                }
            }
            .esqueci-senha {
                background: none;
                margin-top: 20px;
                span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 140%;
                    text-align: center;
                    color: ${(props) => props.theme.colors.secondary};
                }
            }
        }
    }
`;

export const WhatsAppStyles = styled.div`
    width: 100%;

    .container-btn {
        margin-bottom: 32px;
        position: relative;
        button {
            display: flex;
            align-items: flex-start;
        }
        .container-img {
            margin-right: 16px;
        }
        .container-text {
            text-align: left;
            h3 {
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
        .container-seta-baixo {
            position: absolute;
            top: 8px;
            right: 0px;
        }
    }

    .container-input-system {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .container-text-input {
            position: relative;
            top: -8px;
            p {
                text-align: left;
                padding-left: 10px;
            }
        }

        img {
            width: 24px;
        }
        input {
            width: 90%;
            border-radius: 600px;
        }

        button {
            position: static;
            transform: unset;
            width: fit-content;
            padding: 0 16px;
            border-radius: 600px;
        }
    }

    .container-wrapper {
        height: 0px;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        form {
            padding-top: 8px;
        }
        button {
            width: 85px;
            height: 40px;
            position: relative;
            right: 0px;
        }
    }
`;
