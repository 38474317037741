import { memo } from "react";

import { InfraProvider } from "../Hook/";

import { UploadMemo } from "./upload";
import { ReviewMemorized } from "./reeview";
import { TableMemorized } from "./table";
import { Container } from "./styles";

function Infra() {
    return (
        <Container>
            <InfraProvider>
                <UploadMemo />
                <ReviewMemorized />
                <TableMemorized />
            </InfraProvider>
        </Container>
    );
}

export const InfraMemorized = memo(Infra);
