import { Text, Box, Flex } from "alisson-application";
import { formatDateIso, formatCurrentMoney } from "../../../util/helper";
import { Container } from "./styles";
import { Extrato } from "../../../Hook/user/UseCartaoes";

interface RelatorioProps extends Extrato {
    descricao?: string;
}

interface Dados {
    dados: RelatorioProps[];
}

export function Relatorio(props: Dados) {
    return (
        <Container className="container">
            {props.dados.map((item, key) => {
                const { details, eventDate, principalAmount } = item;
                const descricao = details?.includes("Transferência de Saldo")
                    ? details.split("(+)")[0]
                    : details?.trim();

                const fullDate = formatDateIso(eventDate).split(" - ");

                return (
                    <>
                        <Text fontWeight="600" mb="16px">
                            {key === 0 ? fullDate[0] : ""}
                        </Text>
                        <div className="container-operacao">
                            <Flex
                                alignItems="start"
                                className={`tipo-op ${
                                    item.isCredit === true
                                        ? "credito"
                                        : "debito"
                                } ${item.reversed ? "reversed" : ""} `}
                                justifyContent="space-between"
                            >
                                <Flex flexDirection="column" width="60%">
                                    <Text
                                        as="small"
                                        fontWeight="500"
                                        fontSize="xs"
                                        textTransform="uppercase"
                                        className="item-descricao"
                                        title={descricao}
                                    >
                                        {item.mcg === 5
                                            ? "Taxa referente a: "
                                            : ""}
                                        {descricao}
                                    </Text>
                                    <Text
                                        as="small"
                                        color="primaryDark"
                                        fontSize="xss"
                                    >
                                        {fullDate[1]}
                                    </Text>
                                </Flex>

                                <Box width="30%">
                                    <Text as="h3" fontWeight="500">
                                        R${" "}
                                        {formatCurrentMoney(
                                            parseFloat(principalAmount + "")
                                        )}
                                    </Text>
                                </Box>
                            </Flex>
                        </div>
                    </>
                );
            })}
        </Container>
    );
}
