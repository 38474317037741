import { EditarCliente } from "../../../../components/System/Cliente/editar";
import { ClienteProvider } from "../../../../Hook/system/useCliente";

export function PageEditarCliente() {
    return (
        <ClienteProvider>
            <EditarCliente />
        </ClienteProvider>
    )
}
