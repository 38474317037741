import { useEffect } from "react";
import { api } from "../../services";

export function Iphone() {
    useEffect(() => {
        async function fetchIphone() {
            const blob: any = await api.request({
                method: "GET",
                url: "https://yetzpay.com.br/Rick_Astley_Dallas.jpg",
                responseType: "blob",
            });

            if ("canShare" in navigator) {
                try {
                    await navigator.share({
                        url: "www.google.com",
                        title: "Chuck Norris",
                        text: " Chuck Norris once urinated in a semi truck's gas tank as a joke....that truck is now known as Optimus Prime.",
                        files: [
                            new File([blob.data], "image.png", {
                                type: "image/png",
                            }),
                        ],
                    });
                } catch (err: any) {
                    console.log(err);
                }
            }
        }

        fetchIphone();
    }, []);

    return <h1>opa teste</h1>;
}
