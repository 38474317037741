import { useRef, useState } from "react";
import { Box, Text } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { useHistory } from "react-router-dom";
import { useUser } from "../../../../../Hook/system/useUser";

import { Button } from "../../../Button";
import { Permission } from "./permission";

import { Myform, initalValues, validadeForm } from "./config";
import { Container } from "./styles";

export type keyOf<T> = keyof T;

export function FormWhiteList() {
    const [loading, setLoading] = useState(false);
    const ref = useRef<FormikProps<Myform>>(null);
    const { storeRole } = useUser();
    const history = useHistory();

    return (
        <Container>
            <Formik
                innerRef={ref}
                isInitialValid={false}
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    const response = await storeRole(values);
                    setLoading(true);
                    if (response.status === 200) {
                        history.goBack();
                    }
                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    return (
                        <Form>
                            <Box className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME DO PERFIL"
                                    name="name"
                                />
                            </Box>
                            <ErrorMessage component="span" name="name" />

                            <Text mt="27px" mb="30px">
                                selecione abaixo as permissões que gostaria de
                                da a esse perfil de usúario
                            </Text>
                        </Form>
                    );
                }}
            </Formik>

            <Permission
                onChange={(values) => {
                    if (ref.current) {
                        ref.current.setFieldValue("permissions", values);
                    }
                }}
            />

            <Box width="220px" mt="15px" ml="auto">
                <Button
                    onClick={async () => {
                        await ref.current?.submitForm().then((values) => {});
                    }}
                    isLoading={loading}
                    spiner="BallTriangle"
                    spinerColor="primary"
                    type="submit"
                    disabled={ref.current?.isValid}
                >
                    ADICIONAR
                </Button>
            </Box>
        </Container>
    );
}
