export const MessageIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6723 15.4398L10.683 12.9421L14.2379 12.9573C14.6304 12.959 14.9502 12.6418 14.9519 12.2494L14.9915 3.00653C14.9932 2.61406 14.676 2.29418 14.2836 2.2925L2.90777 2.24377C2.5153 2.24209 2.19541 2.55925 2.19373 2.95171L2.15414 12.1946C2.15246 12.587 2.46962 12.9069 2.86208 12.9086L7.12801 12.9269L10.6723 15.4398V15.4398Z"
                stroke="white"
                strokeWidth="1.06649"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.6982 9.38718L5.01026 9.36282"
                stroke="white"
                strokeWidth="1.06649"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.02552 5.80786L12.1354 5.83831"
                stroke="white"
                strokeWidth="1.06649"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
