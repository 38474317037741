import styled from "styled-components";
import imgUser from "../../../assets/img/user.svg";
import imgCartao from "../../../assets/img/cartao.svg";
import imgCalendario from "../../../assets/img/calendario.svg";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
`;

export const ListStyles = styled.ul`
    border-top: 1px solid #e1e2e3;
    margin-bottom: 0px;
    li {
        position: relative;
        display: grid;
        grid-template-columns: 24px 1fr 55px;
        align-items: center;
        height: 48px;
        border-bottom: 1px solid #e1e2e3;
        &:last-child {
            border-bottom: none;
        }
        .container-img {
            display: block;
            background-color: ${(props) => props.theme.colors.primary};
            z-index: 20;
            width: 24px;
            height: 24px;
            transition: background-color 0.5s;
            &.user {
                -webkit-mask: url(${imgUser}) no-repeat;
                -webkit-mask-position: center;
            }
            &.card {
                -webkit-mask: url(${imgCartao}) no-repeat;
                -webkit-mask-position: center;
            }
            &.date {
                -webkit-mask: url(${imgCalendario}) no-repeat;
                -webkit-mask-position: center;
            }
        }

        .container-date {
            display: flex;
            align-items: center;
            width: 100%;
            p {
                width: 50%;
                white-space: nowrap;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark};
            text-transform: uppercase;
            margin-left: 16px;
        }

        button {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;
