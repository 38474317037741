import { usePassos } from "../../../Hook/UsePassos";
import { Button } from "../Button";
import imgAlerta from "../../../assets/img/alerta.svg";
import { HeaderUser } from "./headerUser";
import { AlertaStyles } from "./styles";
import { arrPassos } from ".";

export function Permissao() {
    const { dados, setPassos } = usePassos();
    const userTransfer = dados[0];

    return (
        <AlertaStyles>
            <HeaderUser
                document={userTransfer?.document}
                img={userTransfer?.image}
                name={userTransfer?.name}
            />

            <div className="container-alerta">
                <div className="container-img">
                    <img src={imgAlerta} alt="" />
                </div>
                <p>
                    Não é possível transferir para esse usuário. Ele não
                    participa da mesma campanha que você.
                </p>
            </div>

            <Button
                type="submit"
                className="login"
                mt="auto"
                onClick={() => {
                    setPassos(arrPassos);
                }}
            >
                voltar
            </Button>
        </AlertaStyles>
    );
}
