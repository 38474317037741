import imgUser from "../../../assets/img/user.png";
import { StylesHeaderUser } from "./styles";

interface HeaderUserProps {
    name: string;
    img: string;
    document: string;
}

export function HeaderUser(props: HeaderUserProps) {
    const { name, img, document } = props;
    return (
        <StylesHeaderUser>
            <div className="container-img">
                <img src={img ? img : imgUser} alt="" />
            </div>

            <div className="container-text">
                <h3>{name}</h3>
                <p>CPF - {document}</p>
            </div>
        </StylesHeaderUser>
    );
}
