import { ConfirmaDadosStyles, Divisor } from "../Transferir/styles";
import { InformacaoTransferencia } from "../Transferir/informacaoTransferencia";
import { usePassos } from "../../../Hook/UsePassos";
import { formatCurrentMoney, getCampanha } from "../../../util/helper";
import { Button } from "../ButtonSlim";
import { useUser } from "../../../Hook/user/UseUser";

interface ConfirmaDadosProps {
    handleClick: () => void;
}

export function ConfirmaDados(props: ConfirmaDadosProps) {
    const { handleClick } = props;
    const { dados } = usePassos();
    const { user } = useUser();

    const detalhesOrigemMock = [
        {
            label: "SALDO ATUAL",
            value: dados?.saldoAtual
                ? formatCurrentMoney(dados?.saldoAtual)
                : "",
        },
        {
            label: "VALOR TRANSFERÊNCIA",
            value: dados?.valorTransferencia
                ? formatCurrentMoney(dados?.valorTransferencia)
                : "",
        },
        {
            label: "SALDO FINAL",
            value:
                dados?.subTotal >= 0 ? formatCurrentMoney(dados?.subTotal) : "",
        },
    ];

    const detalhesDestinoMock = [
        {
            label: "VALOR TRANSFERÊNCIA",
            value: dados?.valorTransferencia
                ? formatCurrentMoney(dados?.valorTransferencia)
                : "",
        },
    ];

    return (
        <ConfirmaDadosStyles>
            <h4>Confira abaixo os dados da transferência. </h4>

            <div className="container-info">
                <span>CARTEIRA DE ORIGEM</span>
                <p>NOME: {user.name}</p>
                <p>CPF: {user.document}</p>
            </div>
            <InformacaoTransferencia
                detalhes={detalhesOrigemMock}
                carteira={`CAMPANHA: ${getCampanha().chave}`}
                cardNumber={dados?.transferirCardActive?.number}
            />

            <Divisor />

            <div className="container-info">
                <span>CARTEIRA DE DESTINO</span>
                <p>NOME: {dados.name}</p>
                <p>CPF: {dados.document}</p>
            </div>
            <InformacaoTransferencia
                detalhes={detalhesDestinoMock}
                carteira={`CAMPANHA: ${dados.nameCapanha}`}
                cardNumber={dados?.transferirCurrentCard?.number}
            />

            <Button  mt="47px" onClick={handleClick}>
                Transferir
            </Button>
        </ConfirmaDadosStyles>
    );
}
