import { Box, Flex, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Permissions } from "../../Permission";

import { formatDateTimeDb } from "../../../../util/helper";

import { useCampanha } from "../../../../Hook/system/useCampanha";
import { Button } from "../../Button";
import { DateTime } from "../../DateTime";
import { IOSSwitch } from "../../Switch";
import { MemorizedCampo } from "./campos";
import { CampanhaForm, myform, validadeMyform } from "./config";

import Select, { options } from "../../../NovoSelect";
import { SelectArea } from "./selectArea";
import { SelectCliente } from "./SelectCliente";
import { FormStyles } from "./styles";

export interface ArrayArea {
    id: string;
    area: string;
}

interface FormProps {}

export function FormCampanha(props: FormProps) {
    const [cliente, setCliente] = useState<options>({} as options);
    const { storeCampanha } = useCampanha();
    const history = useHistory();

    const options: options[] = [
        {
            label: "CPF",
            value: "fisica",
        },
        {
            label: "CNPJ",
            value: "juridica",
        },
    ];

    return (
        <FormStyles>
            <Formik
                initialValues={myform}
                validationSchema={validadeMyform}
                onSubmit={async (values, actions) => {
                    const response = await storeCampanha(values);

                    if (response.status === 200) {
                        history.push("/sistema/campanha/");
                    }
                }}
            >
                {(props: FormikProps<CampanhaForm>) => {
                    const { setFieldValue, values } = props;
                    return (
                        <Form>
                            <Text as="p">DADOS DA CAMPANHA</Text>

                            <div className="container-input-system campanha">
                                <Field
                                    type="text"
                                    placeholder="NOME DA CAMPANHA"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <div className="container-input-system chave">
                                <Field
                                    type="text"
                                    name="chave"
                                    placeholder="CHAVE DA CAMPANHA"
                                />
                            </div>
                            <ErrorMessage component="span" name="chave" />

                            <DateTime
                                initialValue={null}
                                label="DATA INICIO"
                                onChange={(dt_inicio) => {
                                    setFieldValue(
                                        "start_date_time",
                                        formatDateTimeDb(dt_inicio).trim()
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="start_date_time"
                            />
                            <DateTime
                                initialValue={null}
                                label="DATA FIM"
                                onChange={(dt_end) => {
                                    setFieldValue(
                                        "end_date_time",
                                        formatDateTimeDb(dt_end).trim()
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="end_date_time"
                            />

                            <Select
                                tipo="cliente"
                                label="TIPO LOGIN"
                                options={options}
                                onChange={(values) => {
                                    setFieldValue(
                                        "register_type",
                                        values.value
                                    );
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="register_type"
                            />

                            <SelectCliente
                                onChange={(values) => {
                                    setFieldValue("cliente_id", values.value);
                                    setCliente(values);
                                }}
                            />
                            <ErrorMessage component="span" name="cliente_id" />

                            <Box mb="36px">
                                <SelectArea
                                    id={
                                        cliente.value
                                            ? parseInt(cliente.value.toString())
                                            : 0
                                    }
                                    onChange={(values) => {
                                        setFieldValue(
                                            "cliente_area_contato_id",
                                            values.value
                                        );
                                    }}
                                />
                                <ErrorMessage
                                    component="span"
                                    name="cliente_area_contato_id"
                                />
                            </Box>

                            <Text as="p" mb="16px">
                                CAMPOS PERSONALIZADOS
                            </Text>

                            <Field component={MemorizedCampo} />

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR CADASTRO SEM INFRA
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "external_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                OBRIGATORIO CONTA PARA CADASTRO
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "force_account_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>
                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR TRANSFERÊNCIA ENTRE USUÁRIOS
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_transference_between_users",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR TRANSFERÊNCIA ENTRE CAMPANHA
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_transference_between_campain",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR ADICIONAR CUPOM
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_cupom",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                GERAR 1 CARTÃO VIRTUAL AO REGISTRAR-SE
                                <IOSSwitch
                                    onChange={(e) =>
                                        setFieldValue(
                                            "virtual_card_on_register",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                            >
                                PERMITIR PAGAMENTO DE CONTAS
                                <IOSSwitch
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            setFieldValue(
                                                "allow_bill_payment_available",
                                                false
                                            );
                                        }

                                        if (e.target.checked) {
                                            setFieldValue(
                                                "allow_bill_payment_available",
                                                true
                                            );
                                        }

                                        setFieldValue(
                                            "allow_bill_payment",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </Flex>

                            <Flex
                                justifyContent="space-between"
                                alignItems="center"
                                mt="45px"
                                className={`disponibilidade ${
                                    !values.allow_bill_payment ? "disabled" : ""
                                }`}
                            >
                                DISPONIBILIDADE DO PAGAMENTO DE CONTAS
                                <IOSSwitch
                                    checked={
                                        values.allow_bill_payment_available
                                    }
                                    onChange={(e) =>
                                        setFieldValue(
                                            "allow_bill_payment_available",
                                            e.target.checked
                                        )
                                    }
                                />
                            </Flex>

                            <Permissions role="campanha.store">
                                <Box width="233px" mt="32px" ml="auto">
                                    <Button type="submit">
                                        ADICIONAR CAMPANHA
                                    </Button>
                                </Box>
                            </Permissions>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
