import { useEffect, useRef, useState } from "react";
import { Box } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
// import { useHistory } from "react-router-dom";

import { Button } from "../../Button";
import { useFaq } from "../../../../Hook/system/useFaq";
import { Myform, initalValues, validadeForm } from "./config";
import { Container } from "./styles";

export type keyOf<T> = keyof T;

export function FormWhiteList() {
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState<Myform>(initalValues);
    const { update, faq } = useFaq();
    const ref = useRef<FormikProps<Myform>>(null);

    useEffect(() => {
        if (Object.values(faq).length) {
            setValues({
                description: faq.description,
                title: faq.title,
            });
        }
    }, [faq]);

    return (
        <Container>
            <Formik
                innerRef={ref}
                isInitialValid={false}
                initialValues={values}
                validationSchema={validadeForm}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    await update(values);
                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    return (
                        <Form>
                            <Box className="container-input-system comunicacao">
                                <Field
                                    type="text"
                                    placeholder="TITULO"
                                    name="title"
                                />
                            </Box>
                            <ErrorMessage component="span" name="title" />

                            <Box className="container-input-system">
                                <Field
                                    component="textarea"
                                    placeholder="DESCRIÇÃO"
                                    name="description"
                                />
                            </Box>
                            <ErrorMessage component="span" name="description" />

                            <Button
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                type="submit"
                                disabled={!props.isValid}
                            >
                                EDITAR
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}
