import { useEffect, useState } from "react";
import { ModalConfig } from "../config";

import { Notificacao } from "../../Notificacao";
import { UseModal } from "../../../Hook/Usemodal";
import Modal from "../../ModalRight";

export function ModalNotificacao(props: ModalConfig) {
    const { handleClose, open } = props;
    const { state, setState } = UseModal();
    const [goBack, setGoBack] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);

    function handleCloseCuston() {
        if (openMenu) {
            setState({ ...state, notificacao: false, menu: true });
            setGoBack(true);
        } else {
            handleClose();
        }
        setOpenMenu(false);
    }

    useEffect(() => {
        if (state?.menu && !goBack) {
            setOpenMenu(true);
        }
    }, [state?.menu, goBack]);

    return (
        <Modal
            open={open}
            // open={true}
            handleClose={handleCloseCuston}
        >
            <Notificacao handleClose={handleClose} />
        </Modal>
    );
}
