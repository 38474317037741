import { useState, useContext, createContext } from "react";
import { ModalFormProps } from "../ModalEstorno/config";
import { api } from "../../../../../../services";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

interface ModalCredito {
    setModalCredito: (value: boolean) => void;
    modalCredito: boolean;

    modalEstorno: boolean;
    setModalEstorno: (value: boolean) => void;

    setNomeArquivoCredito: (value: string) => void;
    nomeArquivoCredito: string;

    setFormStateEstorno: (value: ModalFormProps) => void;
    getDetailEstorno(dates: string[]): Promise<DetailEstorno>;

    formStateEstorno: ModalFormProps;
    detailEstorno: DetailEstorno;

    createEstorno(): Promise<void>;
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface DetailEstorno {
    total_de_cargas: number;
    usuarios: number;
    valor: string;
}

const Context = createContext<ModalCredito>({} as ModalCredito);

export function ModalCreditoProvider(props: ProviderProps) {
    const { children } = props;
    const history = useHistory<{ id: number }>();
    const id = history.location.state?.id;
    const [modalCredito, setModalCredito] = useState(false);
    const [modalEstorno, setModalEstorno] = useState(false);
    const [nomeArquivoCredito, setNomeArquivoCredito] = useState("");
    const [formStateEstorno, setFormStateEstorno] = useState<ModalFormProps>(
        {} as ModalFormProps
    );
    const [detailEstorno, setDetailEstorno] = useState<DetailEstorno>(
        {} as DetailEstorno
    );

    async function getDetailEstorno(dates: string[]) {
        try {
            const response = await api.get<DetailEstorno>(
                `carga/preview-estorno/${id}/${dates[0]}/${dates[1]}`
            );
            setDetailEstorno(response.data);
            return response.data;
        } catch (error) {
            toast.error("Erro ao buscar detalhes do estorno");
            console.log(error);
            return {} as DetailEstorno;
        }
    }

    async function createEstorno() {
        try {
            await api.post("carga/store-estorno", {
                campanha_id: id,
                nome: formStateEstorno.nome,
                start_date_time: formStateEstorno.start_date_time,
                end_date_time: formStateEstorno.end_date_time,
            });
            setModalEstorno(false);
            toast.success("Estorno realizado com sucesso");
        } catch (error) {
            toast.error("Erro ao realizar estorno");
            console.log(error);
        }
    }

    return (
        <Context.Provider
            value={{
                modalCredito,
                setModalCredito,
                nomeArquivoCredito,
                setNomeArquivoCredito,
                setFormStateEstorno,
                getDetailEstorno,
                formStateEstorno,
                detailEstorno,
                createEstorno,
                modalEstorno,
                setModalEstorno,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
