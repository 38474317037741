import { Text, Flex, Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { PriceMemo } from "./price";
import {
    OverlayActive,
    OverlayBlock,
    OverlayProcess,
    OverlayCancelar,
} from "../OverlayStatus";
import imgDetalhe from "../../../assets/img/seta-cima-sistema.svg";
import imgMaster from "../../../assets/img/master.png";
import { CardStyles } from "./styles";

export interface CardProps {
    price: string;
    number: string;
    type: string;
    color: string;
    date: string;
    onClick?: () => void;
    status: string;
    showPrice?: boolean;
    visualizarDados?: boolean;
    id: number;
}

export function Card(props: CardProps) {
    const {
        color,
        number,
        type,
        onClick,
        status,
        date,
        showPrice = false,
        id,
        visualizarDados = false,
    } = props;
    const { handleOpen, handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    const check =
        status === "INATIVO" ||
        status === "BLOQUEADO" ||
        status === "CANCELADO" ||
        status === "PROCESSANDO";

    return (
        <CardStyles background={color} status={check}>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                className="container-bandeira"
                mb="40px"
            >
                <Box className="bandeira">
                    <img src={imgMaster} alt="" />
                </Box>
                {onClick && (
                    <button onClick={onClick}>
                        MENU
                        <img src={imgDetalhe} alt="" />
                    </button>
                )}
            </Flex>

            <PriceMemo showPrice={showPrice} id={id} type={type} />

            <Box className="number-card" mt="auto">
                <Text as="p">
                    {status === "PROCESSANDO" ? "**** **** **** ****" : number}
                </Text>
            </Box>

            <Box className="validade">
                <Text as="p">{date}</Text>
            </Box>

            {visualizarDados && (
                <button
                    className="dados-cartao"
                    onClick={() => {
                        if (width <= 480) {
                            history.push("dados-cartao");
                            handleClose("acoesCartaoMobile");
                        }
                        handleOpen("visualizarDados");
                    }}
                >
                    DADOS do cartão
                </button>
            )}

            <Text as="p" className="type">
                {type}
            </Text>

            {status === "INATIVO" && <OverlayActive number={number} />}
            {status === "BLOQUEADO" && <OverlayBlock number={number} />}
            {status === "PROCESSANDO" && <OverlayProcess number={number} />}
            {status === "ATIVANDO" && <OverlayProcess number={number} />}
            {status === "CANCELADO" && <OverlayCancelar number={number} />}
        </CardStyles>
    );
}
