import { Text } from "alisson-application";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { Meses } from "../../Desktop/Meses";
import { ContainerMovimentacao, Content } from "./styles";

export function Movimentacao() {
    const { getExtrato, cartaoId } = UseCartoes();

    async function handleClick(date: Date) {
        if (cartaoId) {
            await getExtrato(cartaoId, new Date(date));
        }
    }

    return (
        <Content>
            <ContainerMovimentacao className="container">
                <Text fontWeight="600" mb="40px">
                    Movimentação
                </Text>
            </ContainerMovimentacao>

            <div className="slide-mes">
                <Meses handleClick={handleClick} />
            </div>
        </Content>
    );
}
