import { ErrorMessage, FieldProps } from "formik";
import { DateTime } from "../../../../../components/System/DateTime";
import { formatDate, formatDateTimeDb } from "../../../../../util/helper";

interface PersonDateProps extends FieldProps {
    disabled?: boolean;
}

export function PersonDate(props: PersonDateProps) {
    const { form, field, disabled = false } = props;
    const { setFieldValue } = form;

    return (
        <div
            className={`container-element-backoffice ${
                disabled ? "disabled" : ""
            }`}
        >
            <label htmlFor="">{field.name}</label>
            <div className={`container-input-backoffice`}>
                <DateTime
                    initialValue={null}
                    label={formatDate(field.value)}
                    className="container-input-backoffice"
                    onChange={(dt_inicio) => {
                        setFieldValue(
                            field.name,
                            formatDateTimeDb(dt_inicio).split(" ")[0]
                        );
                    }}
                />
            </div>
            <ErrorMessage name={field.name} component="span" />
        </div>
    );
}
