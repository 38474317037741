import { Header as DefaultHeader } from "../Header";
import { Button } from "../Button";
import { HeaderStyles } from "./styles";
import { useCartao } from "../../../Hook/system/useCartao";

export function Header() {
    const { setSearch, search, getCard } = useCartao();

    return (
        <HeaderStyles>
            <DefaultHeader title="BUSCAR CARTÃO" />

            <div className="busca">
                <div className="container-input-system pesquisa">
                    <input
                        type="text"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        placeholder="CPF, OU CONTA"
                    />
                </div>

                <Button
                    onClick={async () => {
                        getCard(1, search, undefined);
                    }}
                >
                    BUSCAR
                </Button>
            </div>
        </HeaderStyles>
    );
}
