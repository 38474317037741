import { useEffect, useState } from "react";
import { GridColumns } from "@mui/x-data-grid-pro";
import DetalheTable from "../../../../Table";
import { Nonames, useCartaoDeCredito } from "../Hook";
import { TableStyles } from "./styles";
import { Data } from "./config";
import { formataMoney, tipoCartao } from "../../../../../../util/helper";
import Ximg from "../../../../../../assets/img/x-vermelho.svg";
import Modal from "../../../../../../components/System/ModalAlert";

import { GridRenderCellParams } from "@mui/x-data-grid-pro";

interface Dados {
    data: Nonames[];
    updateTable: () => Promise<void>;
}

const initialColumn: GridColumns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "cpf",
        headerName: "CPF",
        width: 172,
    },
    {
        field: "nome",
        headerName: "NOME",
        width: 172,
    },
    {
        field: "tipo_cartao",
        headerName: "TIPO DE CARTÃO",
        width: 172,
    },
    {
        field: "produto",
        headerName: "PRODUTO",
        width: 172,
    },
    {
        field: "n_conta",
        headerName: "Nº DA CONTA",
        width: 172,
    },

    {
        field: "valor",
        headerName: "VALOR",
        width: 172,
    },
];

export function Table(props: Dados) {
    const { data, updateTable } = props;
    const { deleteNoname } = useCartaoDeCredito();
    const [column, setColumn] = useState<GridColumns>(initialColumn);
    const [modalState, setModalState] = useState(false);
    const [itemId, setItemId] = useState<number | null>(null);
    function handleModal() {
        setModalState(!modalState);
    }

    const rows: Data[] = data?.map((carga) => {
        const hasDelete = carga?.cartao.nome_completo
            ? {}
            : { excluir: carga.id };
        return {
            id: carga?.cartao?.id || 0,
            cpf: carga?.user?.document ? carga?.user?.document : "",
            nome: carga?.user?.name ? carga?.user?.name : "",
            tipo_cartao: tipoCartao(carga?.cartao?.tipo),
            n_conta: carga?.cartao?.acg_account_id || "",
            produto: carga?.cartao?.produto?.nome || "",
            valor: carga?.cartao?.operation?.valor
                ? formataMoney(carga.cartao?.operation?.valor)
                : "",
            ...hasDelete,
        };
    });

    useEffect(() => {
        const dontHaveUser = data?.some(
            (carga) => !carga?.cartao?.nome_completo
        );
        if (dontHaveUser) {
            setColumn([
                ...initialColumn,
                {
                    field: "excluir",
                    headerName: "EXCLUIR",
                    width: 172,
                    renderCell: ({ row }: GridRenderCellParams) => {
                        return (
                            <button
                                onClick={() => {
                                    setModalState(true);
                                    setItemId(row.excluir);
                                }}
                            >
                                <img src={Ximg} alt="" />
                            </button>
                        );
                    },
                },
            ]);
        }
    }, [data]);

    return (
        <TableStyles>
            <DetalheTable
                rows={rows ? rows : []}
                columns={column}
                nameCSV="CARTAO-DE-CREDITO"
            />
            <Modal
                handleClose={handleModal}
                reject={handleModal}
                aceept={async () => {
                    if (itemId) {
                        handleModal();
                        await deleteNoname(itemId);
                        await updateTable();
                        return;
                    }
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalState}
            />
        </TableStyles>
    );
}
