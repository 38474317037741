import { TermoECondicoesDeUso } from "../../../components/System/TermoECondicoesDeUso";
import { TermoECondicoesDeUsoProvider } from "../../../Hook/system/TermoECondicoesDeUso";

export function PageTermoECondicoesDeUso() {
    return (
        <TermoECondicoesDeUsoProvider>
            <TermoECondicoesDeUso />
        </TermoECondicoesDeUsoProvider>
    );
}
