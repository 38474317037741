import { useState } from "react";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import imgSuccess from "../../../assets/img/success-user-side.svg";
import { Container } from "./styles";

export function ConfirmaDesbloqueioCartao() {
    const { state, setState } = UseModal();
    const [loading, setLoading] = useState(false);
    const { cartoes, cartaoId, desbloquear } = UseCartoes();
    const cartao = cartoes?.find((cartao) => cartao.id === cartaoId);

    async function desbloquearCartao() {
        setLoading(true);
        const response = await desbloquear();
        setLoading(false);
        if (response.status === 200) {
            setState({
                ...state,
                acaoDesbloquearCartaoMobilePasso2: false,
                successGeral: {
                    active: true,
                    title: "Desbloqueio realizado com sucesso!",
                    message: "Cartão desbloqueado com sucesso!",
                },
            });
        }
    }

    return (
        <Container>
            <img src={imgSuccess} alt="" />
            <h2>DESBLOQUEAR CARTÃO</h2>

            <p>
                Você confirma o desbloqueio do cartão com final{" "}
                <strong>
                    {cartao?.tipo === "cartao_fisico" ||
                    cartao?.tipo === "cartao_avulso"
                        ? cartao.acg_account_card_last4digits
                        : ""}
                </strong>
                .
            </p>

            <Button
                onClick={desbloquearCartao}
                type="submit"
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
            >
                CONFIRMAR DESBLOQUEIO
            </Button>
        </Container>
    );
}
