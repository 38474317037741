import styled from "styled-components";

export const Container = styled.div`
    .container {
        p {
            text-align: center;
            width: 260px;
            margin: 0 auto;
            margin-top: 19px;
            margin-bottom: 26px;
        }
    }

    .container-tabs{
        padding-top:0;
    }
`;


export const FormStyles = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-bottom: 23px;
    button {
        &.open-modal-cvv {
            width: 100%;
            margin-bottom: 68px;
            
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;
