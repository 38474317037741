import { ModalConfig } from "../config";
import { ContentModalCrop } from "../../Crop/modal";
import Modal from "../../NovoModal";

export function ModalCrop(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <Modal open={open} handleClose={handleClose}>
            <ContentModalCrop />
        </Modal>
    );
}
