import { Text, Flex } from "alisson-application";
import { useState } from "react";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import { FiltroEntradaSaidaStyles } from "./styles";
import imgFiltro from "../../../assets/img/filtro.svg";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const outerTheme = createTheme({
    palette: {
        primary: {
            main: "#5DB356",
        },
        secondary: {
            main: "#FF5F00",
        },
    },
});

interface FiltroEntradaSaidaProps {
    handleEntrada: (value: boolean) => void;
    handleSaida: (value: boolean) => void;
}

export function FiltroEntradaSaida(props: FiltroEntradaSaidaProps) {
    const { handleEntrada, handleSaida } = props;
    const [entrada, setEntrada] = useState(false);
    const [saida, setSaida] = useState(false);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const label = { inputProps: { "aria-label": "Checkbox demo" } };

    const handleClickFiltro = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFiltro = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={outerTheme}>
            <button onClick={handleClickFiltro}>
                <Flex>
                    <img src={imgFiltro} alt="" />
                    <Text color="secondary" fontWeight="700" ml="5px">
                        Filtro
                    </Text>
                </Flex>
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseFiltro}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <FiltroEntradaSaidaStyles>
                    <Flex alignItems="center">
                        <Checkbox
                            {...label}
                            checked={entrada}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const value = !entrada;
                                setEntrada(value);
                                handleEntrada(value);

                                handleSaida(false);
                                setSaida(false);
                                handleCloseFiltro();
                            }}
                        />
                        <p className="entrada">ENTRADAS</p>
                    </Flex>
                    <Flex alignItems="center">
                        <Checkbox
                            {...label}
                            color="secondary"
                            checked={saida}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                const value = !saida;

                                setSaida(value);
                                handleSaida(value);

                                setEntrada(false);
                                handleEntrada(false);
                                handleCloseFiltro();
                            }}
                        />
                        <p className="saida">SAÍDAS</p>
                    </Flex>
                </FiltroEntradaSaidaStyles>
            </Popover>
        </ThemeProvider>
    );
}
