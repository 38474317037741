import { Buttons, Container } from "./styles";
import Table from "../Table";

import { BtnDownload } from "./btnDownload";

import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { BtnDelete } from "./btnDelete";
import { useUpload } from "../../../Hook/system/useUploadFile";
import { Permissions } from "../Permission";

interface TableFilesProps {
    data: [];
    title: string;
}

export function TableFiles(props: TableFilesProps) {
    const width = 1645 / 7.5;
    const { setModalExcluir, setId } = useUpload();

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 90,
        },
        {
            field: "descricao",
            headerName: "DESCRIÇÃO",
            width,
        },
        {
            field: "name_file",
            headerName: "NOME DO ARQUIVO",
            width,
        },
        {
            field: "tipo",
            headerName: "TIPO",
            width,
        },
        {
            field: "size",
            headerName: "TAMANHO",
            width,
        },
        {
            field: "dt_hr",
            headerName: "DATA/HORA",
            width,
        },
        {
            field: "remetente",
            headerName: "REMETENTE",
            width,
        },
        {
            field: "destinatario",
            headerName: "DESTINATÁRIO",
            width,
        },

        {
            field: "acoes",
            headerName: "EXPORTAR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Buttons>
                        <BtnDownload id={row.id as number} />
                        <Permissions role="file.destroy">
                            <BtnDelete
                                onClick={() => {
                                    setId(row.id);
                                    setModalExcluir(true);
                                }}
                            />
                        </Permissions>
                    </Buttons>
                );
            },
            width,
        },
    ];

    return (
        <Container>
            <div className="container-button">
                <button>{props.title}</button>
            </div>

            <div className="container-table">
                <Table rows={props.data} columns={columns} nameCSV="upload" />
            </div>
        </Container>
    );
}
