import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 162px;
    .container-header {
        img {
            display: none;
        }
    }

    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        color: ${(props) => props.theme.colors.secondary};
        margin-bottom: 12px;
        text-align: center;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
        text-align: center;
        margin-bottom: 56px;
    }

    a {
        &.termo {
            font-weight: 500;
            font-size: 13px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            display: block;
            text-align: center;
            margin-bottom: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                margin-right: 12px;
            }
        }
    }

    .container-check {
        width: 282px;
        margin: 0 auto;
        margin-bottom: 49px;
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    button {
        width: 90%;
        margin: 0 auto;
    }

    label {
        span {
            color: ${(props) => props.theme.colors.secondary} !important;
            &.MuiFormControlLabel-label {
                color: ${(props) => props.theme.colors.primary} !important;
            }
        }
    }
`;
