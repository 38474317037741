import { ChangeEvent } from "react";
import { useStateUser } from "../../Hook/user/UseStateUser";

import { getOrientation } from "get-orientation/browser";
import { getRotatedImage } from "./canvasUtils";
import { UseModal } from "../../Hook/Usemodal";

const ORIENTATION_TO_ANGLE = {
    "3": 180,
    "6": 90,
    "8": -90,
};

interface ButtonProps {
    children: React.ReactNode;
}

export function ButtonCrop(props: ButtonProps) {
    const { handleOpen } = UseModal();
    const { setImageSrc } = useStateUser();

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            handleOpen("crop");
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation = ORIENTATION_TO_ANGLE[orientation];
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
            }

            setImageSrc(imageDataUrl);
        }
    };

    return (
        <>
            <input
                type="file"
                onChange={onFileChange}
                id="crop-user"
                accept="image/*"
            />
            <label htmlFor="crop-user">{props.children}</label>
        </>
    );
}

function readFile(file: File): any {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
}
