import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";

export const Container = styled.div``;

export const FormControlLabelStyled = styled(FormControlLabel)(({ theme }) => ({
    alignItems: "start !important",
    "& .MuiCheckbox-root": {
        padding: "0px 9px",
        top: "0px",
    },
}));
