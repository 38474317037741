import { Text } from "alisson-application";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useCampanha } from "../../../Hook/system/useCampanha";
import { api } from "../../../services";
import { getLocalStore } from "../../../util/helper";
import { Button } from "../Button";
import { Header as DefaultHeader } from "../Header";
import { Permissions } from "../Permission";
import { IOSSwitch } from "../Switch";

import { StylesHeader } from "./styles";

interface IHeader {
    allowBillPayment: boolean;
    handleChange: () => Promise<void>;
}

export function Header({ allowBillPayment, handleChange }: IHeader) {
    const user = getLocalStore();
    const history = useHistory();
    const { allowCampaignsBillPayment } = useCampanha();

    const [loadingExportar, setLoadingExportar] = useState(false);
    const [loadingAllowBillPayment, setLoadingAllowBillPayment] =
        useState(false);

    async function exportar() {
        setLoadingExportar(true);
        try {
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(`campanha/export`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
            setLoadingExportar(false);
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
            setLoadingExportar(false);
        }
    }

    return (
        <StylesHeader>
            <DefaultHeader
                title="Campanhas"
                titleBtn=""
                permission="campanha.store"
            />

            {user?.permissions["campanha.allow_campaigns_bill_payment"] ? (
                <div className="container-toggle">
                    <IOSSwitch
                        checked={allowBillPayment}
                        onChange={async (e) => {
                            setLoadingAllowBillPayment(true);
                            await allowCampaignsBillPayment(e.target.checked);
                            await handleChange();
                            setLoadingAllowBillPayment(false);
                        }}
                        disabled={loadingAllowBillPayment}
                    />
                    <Text ml="16px" as="p">
                        Disponibilidade de Pagamento de Boletos
                    </Text>
                </div>
            ) : null}

            <Button
                onClick={exportar}
                disabled={loadingExportar}
                isLoading={loadingExportar}
            >
                exportar
            </Button>
            <Permissions role="campanha.store">
                <Button
                    onClick={() => {
                        history.push("/sistema/campanha/nova");
                    }}
                >
                    CAMPANHAS
                </Button>
            </Permissions>
        </StylesHeader>
    );
}
