import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import imgTutorial from "../../../assets/img/tutorial.svg";
import imgLupa from "../../../assets/img/lupa.svg";
import imgCinza from "../../../assets/img/seta-esq-cinza.svg";
import { Container } from "./styles";
import { useTutorial } from "../../../Hook/user/useTutorial";

export function Header() {
    const { filterTutorial } = useTutorial();
    const history = useHistory();
    return (
        <Container>
            <div className="header-tutorial-title">
                <div className="container-img">
                    <img src={imgTutorial} alt="tutorial" />
                </div>
                <Text as="h3" color="secondary">
                    Tutoriais
                </Text>
            </div>

            <div className="header-tutorial-search">
                <div className="container-input">
                    <input
                        type="text"
                        placeholder="Procure por um tutorial"
                        onChange={(e) => filterTutorial(e.target.value)}
                    />
                    <div className="container-img">
                        <img src={imgLupa} alt="lupa" />
                    </div>
                </div>
            </div>

            <div className="goBack">
                <button onClick={() => history.goBack()}>
                    <img src={imgCinza} alt="" />
                    voltar
                </button>
            </div>
        </Container>
    );
}
