import React, { createContext, useContext, useState } from "react";

interface UseFormLoginProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseFormLogin {
    controllerForm: StateForms;
    setControllerForm: (values: StateForms) => void;

    state: ObejctForm;
    setState: (values: ObejctForm) => void;

    resetForm: () => void;
}

export interface StateForms {
    documento: boolean;
    campanha: boolean;
    cartaoNoname: boolean;
    selectCampanha: boolean;
    completeRegister: boolean;
    password: boolean;
    endereco: boolean;
}

export interface ObejctForm {
    document: string;
    campanha: string;
    name: string;
    account: string;
    idwall_service_status: number;
}

const UseModalContext = createContext<UseFormLogin>({} as UseFormLogin);

export function UseFormLoginProvider(props: UseFormLoginProviderProps) {
    const { children } = props;
    const [controllerForm, setControllerForm] = useState<StateForms>({
        campanha: true,
        documento: false,
        cartaoNoname: false,
        selectCampanha: false,
        completeRegister: false,
        password: false,
        endereco: false,
    });

    const [state, setState] = useState<ObejctForm>({
        document: "",
        campanha: "",
        name: "",
        account: "",
        idwall_service_status: 1,
    });

    function resetForm() {
        setState({
            document: "",
            campanha: "",
            name: "",
            account: "",
            idwall_service_status: 1,
        });

        setControllerForm({
            campanha: true,
            documento: false,
            cartaoNoname: false,
            selectCampanha: false,
            completeRegister: false,
            password: false,
            endereco: false,
        });
    }

    return (
        <UseModalContext.Provider
            value={{
                controllerForm,
                setControllerForm,
                state,
                setState,
                resetForm,
            }}
        >
            {children}
        </UseModalContext.Provider>
    );
}

export function useFormLogin() {
    const context = useContext(UseModalContext);
    return context;
}
