import { Text } from "alisson-application";
import imgCargaEstorno from "../../../../../../assets/img/estorno-cadastro.svg";
import imgUser from "../../../../../../assets/img/user.svg";
import imgMoney from "../../../../../../assets/img/money-estorno.svg";
import imgValores from "../../../../../../assets/img/valores-estorno.svg";
import { ConfirmaDadosStyles } from "./styles";
import { Button } from "../../../../Button";
import { useContex } from "../Hook/modal";
import { formatCurrentMoney } from "../../../../../../util/helper";
import { useState } from "react";

export function ConfirmaDados() {
    const { formStateEstorno, detailEstorno, createEstorno } = useContex();
    const [loading, setLoading] = useState(false);

    async function handleCreateEstorno() {
        setLoading(true);
        await createEstorno();
        setLoading(false);
    }

    return (
        <ConfirmaDadosStyles>
            <div className="container-content">
                <div className="container-img">
                    <img src={imgCargaEstorno} alt="" />
                </div>

                <Text as="span">{formStateEstorno.nome}</Text>

                <div className="container-periodo">
                    <div className="container-range">
                        <Text as="p" color="secondary">
                            Início
                        </Text>
                        <input
                            type="date"
                            value={formStateEstorno.start_date_time}
                            disabled
                        />
                    </div>
                    <div className="container-range">
                        <Text as="p" color="secondary">
                            Fim
                        </Text>
                        <input
                            type="date"
                            value={formStateEstorno.end_date_time}
                            disabled
                        />
                    </div>
                </div>

                <div className="container-resultado">
                    <h3>RESULTADO DA CONSULTA</h3>
                    <ul>
                        <li>
                            <div className="container-icon">
                                <img src={imgUser} alt="" />
                            </div>

                            <Text as="p" color="secondary" fontSize="xss">
                                USUÁRIOS:
                            </Text>
                            <Text as="p" color="primaryDark" fontSize="xss">
                                {detailEstorno.usuarios}
                            </Text>
                        </li>
                        <li>
                            <div className="container-icon">
                                <img src={imgMoney} alt="" />
                            </div>

                            <Text as="p" color="secondary" fontSize="xss">
                                TOTAL DE CARGAS:
                            </Text>
                            <Text as="p" color="primaryDark" fontSize="xss">
                                {detailEstorno.total_de_cargas}
                            </Text>
                        </li>
                        <li>
                            <div className="container-icon">
                                <img src={imgValores} alt="" />
                            </div>

                            <Text as="p" color="secondary" fontSize="xss">
                                VALOR:
                            </Text>
                            <Text as="p" color="primaryDark" fontSize="xss">
                                R$
                                {formatCurrentMoney(
                                    parseFloat(detailEstorno.valor)
                                )}
                            </Text>
                        </li>
                    </ul>
                </div>

                <Button
                    onClick={handleCreateEstorno}
                    isLoading={loading}
                    spiner="BallTriangle"
                    spinerColor="primary"
                    disabled={loading}
                >
                    CRIAR CARGA DE ESTORNO
                </Button>
            </div>
        </ConfirmaDadosStyles>
    );
}
