import { InputHTMLAttributes } from "react";
import { UseFormRegister } from "react-hook-form";

import { useTranslation } from "../../../../../../contexts/Localization";
import ErrorMessage from "../ErrorMessage";
import { InputContainer } from "./styles";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    name?: string;
    mode?: "primary" | "secondary";
    register?: UseFormRegister<any>;
    inputRef?: any;
    disableTranslation?: boolean;
    noErrorMessage?: boolean;
}

export const Input = ({
    disableTranslation,
    noErrorMessage,
    mode = "primary",
    inputRef,
    register,
    name,
    error,
    ...rest
}: IInput) => {
    const { t } = useTranslation();
    const hasError = !!error;

    const errorMessage = disableTranslation ? error : t(error || "");

    function handlePreventPastCopy(e: any) {
        const banned_names = [
            "email",
            "confirmed_email",
            "mobile_number",
            "confirmed_mobile_number",
        ];
        if (banned_names.includes(name || "")) {
            e.preventDefault();
            return false;
        }

        return e;
    }

    return (
        <>
            {register && name ? (
                <InputContainer
                    onPaste={handlePreventPastCopy}
                    onCopy={handlePreventPastCopy}
                    mode={mode}
                    error={hasError || undefined}
                    {...register(name)}
                    {...rest}
                />
            ) : (
                <InputContainer
                    onPaste={handlePreventPastCopy}
                    onCopy={handlePreventPastCopy}
                    ref={inputRef}
                    mode={mode}
                    error={hasError || undefined}
                    {...rest}
                />
            )}

            {!!error && !noErrorMessage && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
            )}
        </>
    );
};
