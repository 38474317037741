import { FieldProps, ErrorMessage } from "formik";
import { useFile } from "../hook";
import { BackgroundUpload } from "../../BackgroundUpload";
import { DateTime } from "../../DateTime";
import { formatDateTimeDb } from "../../../../util/helper";
import { FormPopUp } from "./styles";
import Select from "../../../NovoSelect";

export function PopUp(props: FieldProps) {
    const { form } = props;
    const { setFieldValue } = form;
    const { handleFiles, files } = useFile();

    const frequencias = [
        {
            label: "ÚNICO",
            value: "SINGLE",
        },
        {
            label: "DIÁRIO",
            value: "DAILY",
        },
        {
            label: "SEMANAL",
            value: "WEEKLY",
        },
        {
            label: "MENSAL",
            value: "MONTHLY",
        },
    ];

    return (
        <FormPopUp>
            <Select
                label="FREQUÊNCIA"
                options={frequencias}
                onChange={(values) => {
                    setFieldValue("recurrence", values.value);
                }}
            />
            <ErrorMessage component="span" name="recurrence" />

            <div className="container-imagens-mensagens">
                <div className="container-desktop">
                    <label htmlFor="img-desktop">
                        <BackgroundUpload
                            width={181}
                            title="IMAGEM DESKTOP"
                            subTitle="900PX / 600PX"
                            preview={
                                files?.arte_desktop
                                    ? URL.createObjectURL(files?.arte_desktop)
                                    : ""
                            }
                        />
                    </label>
                    <input
                        type="file"
                        name=""
                        id="img-desktop"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target.files?.length) {
                                handleFiles(
                                    e.target.files[0],
                                    "arte_desktop",
                                    900,
                                    600
                                );

                                setFieldValue("arte_desktop", "ok");
                            }
                        }}
                    />
                    <ErrorMessage component="span" name="arte_desktop" />
                </div>
                <div className="container-mobile">
                    <label htmlFor="img-mobile">
                        <BackgroundUpload
                            width={56}
                            title="IMAGEM MOBILE"
                            subTitle="390PX / 844PX"
                            preview={
                                files?.arte_mobile
                                    ? URL.createObjectURL(files?.arte_mobile)
                                    : ""
                            }
                        />
                    </label>
                    <input
                        type="file"
                        name=""
                        id="img-mobile"
                        accept="image/*"
                        onChange={(e) => {
                            if (e.target.files?.length) {
                                handleFiles(
                                    e.target.files[0],
                                    "arte_mobile",
                                    690,
                                    844
                                );
                                setFieldValue("arte_mobile", "ok");
                            }
                        }}
                    />
                    <ErrorMessage component="span" name="arte_mobile" />
                </div>
            </div>

            <DateTime
                initialValue={null}
                label="DATA/HORARIO DE INÍCIO"
                onChange={(dt_inicio) => {
                    setFieldValue("disparo", "agendado");
                    setFieldValue(
                        "data_disparo",
                        formatDateTimeDb(dt_inicio).trim()
                    );
                }}
            />

            <ErrorMessage component="span" name="data_disparo" />

            <DateTime
                initialValue={null}
                label="DATA/HORARIO DE FIM"
                onChange={(dt_fim) => {
                    setFieldValue("data_fim", formatDateTimeDb(dt_fim).trim());
                }}
            />

            <ErrorMessage component="span" name="data_fim" />
        </FormPopUp>
    );
}
