import { Text } from "alisson-application";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useCupom } from "../../../../Hook/system/useCupom";
import {
    formatCurrentMoney,
    formatDateIsoSystem,
} from "../../../../util/helper";

import Table, { Pagination } from "../../Table";
import { Container } from "./styles";
import { CheckCupom } from "./checked";
import { HeaderCupom } from "./header";
import { CheckBox } from "../../CheckBox";
import { Loader } from "../../../Loader";

export function Detalhe() {
    const width = 1645 / 8.5;

    const {
        showCupons,
        configCupons,
        fetchShowCupons,
        updateCupom,
        loadingCupom,
    } = useCupom();

    const rows = showCupons?.itens?.map((item, key) => {
        return {
            id: item.id,
            codigo: item.codigo,
            valor: "R$ " + formatCurrentMoney(parseFloat(item.valor)),
            usado_por: item?.user?.name,
            usado_em: formatDateIsoSystem(item.updated_at),
            status: item.status ? true : false,
            status_text: item.status ? true : false,
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.id}</Text>;
            },
            width: 50,
        },
        {
            field: "status",
            headerName: "",
            renderHeader: () => {
                const checkeds = rows?.every((row) => row.status) || false;
                return (
                    <CheckBox
                        checked={checkeds}
                        onChange={async () => {
                            const checkeds = rows?.map((row) => row.id);
                            await updateCupom(checkeds);
                        }}
                    />
                );
            },
            renderCell: ({ row }: GridRenderCellParams) => {
                return <CheckCupom status={row.status} id={row.id} />;
            },
            width: 100,
        },
        {
            field: "status_text",
            headerName: "Ativo",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.status ? "Ativo" : "Inativo"}</Text>;
            },
            width: 100,
        },
        {
            field: "CÓDIGO",
            headerName: "codigo",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.codigo}</Text>;
            },
            width: width,
        },
        {
            field: "valor",
            headerName: "VALOR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.valor}</Text>;
            },
            width: width,
        },
        {
            field: "usado_por",
            headerName: "USADO POR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.usado_por}</Text>;
            },
            width: width,
        },
        {
            field: "usado_em",
            headerName: "USADO EM",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.usado_em}</Text>;
            },
            width: width,
        },
    ];

    async function pagination(page: number) {
        await fetchShowCupons(page);
    }

    return (
        <Container>
            <HeaderCupom />
            {loadingCupom ? (
                <div className="loader">
                    <Loader width={60} height={60} />
                </div>
            ) : (
                <Table
                    columns={columns}
                    rows={rows ? rows : []}
                    nameCSV="CUPOM"
                />
            )}

            {configCupons?.last_page > 1 ? (
                <Pagination
                    total={configCupons?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
