import { ErrorStyles, AlertStyles } from "./styles";
import imgAtencao from "../../../assets/img/atencao.svg";

interface ErrorProps {
    alert: boolean;
}
export function Error(props: ErrorProps) {
    if (props.alert) {
        return (
            <AlertStyles>
                <img src={imgAtencao} alt="Atenção" />
                <p>
                    A carga foi aceita, mas há alertas. Por favor, verifique os
                    alertas antes de aprovar.
                </p>
            </AlertStyles>
        );
    }
    return (
        <ErrorStyles>
            <img src={imgAtencao} alt="" />
            <p>PLANILHA COM COLUNAS ERRADAS OU FALTANDO</p>
        </ErrorStyles>
    );
}
