import styled from "styled-components";

interface ITimerProps {
    $theme?: "primary" | "secondary";
}

export const TimerContainer = styled.div<ITimerProps>`
    --primary-timer-color: #5e5f5f;
    --secondary-timer-color: #000;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    svg {
        margin-right: 5px;

        &,
        path {
            stroke: ${({ $theme }) => `var(--${$theme}-timer-color)`};
        }
    }

    span {
        color: ${({ $theme }) => `var(--${$theme}-timer-color)`};
        text-align: center;
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 16px */
        letter-spacing: 0.64px;
    }
`;
