import { Header as DefaultHeader } from "../../Header";
import { Button } from "../../Button";
import { Container } from "./styles";
import { useBackoffice } from "../../../../Hook/system/useBackoffice";
import { DebounceInput } from "react-debounce-input";
import { useState } from "react";

export function Header() {
    const { getBackoffice, loading } = useBackoffice();
    const [value, setValue] = useState<string>("");

    async function handleClick() {
        await getBackoffice(value);
    }

    return (
        <Container>
            <DefaultHeader title="BACK OFFICE" />

            <div className="busca">
                <div className="container-input-system pesquisa">
                    <DebounceInput
                        type="text"
                        placeholder="INFORME O CPF PARA QUE A BUSCA SEJA FEITA NA ACG"
                        disabled={loading}
                        debounceTimeout={1000}
                        value={value}
                        onChange={async (e) => {
                            const value = e.target.value.replace(/\D/g, "");
                            setValue(value);
                            await getBackoffice(value);
                        }}
                    />
                </div>

                <Button isLoading={loading} onClick={handleClick}>
                    BUSCAR
                </Button>
            </div>
        </Container>
    );
}
