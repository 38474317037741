import styled from "styled-components";

export const Container = styled.div`
    padding: 35px 0;
    h4 {
        margin-bottom: 26px;

        color: ${(props) => props.theme.colors.secondary};
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: none;
    }

    .container-informacoes {
        margin: 13px auto;
        .item {
            padding-bottom: 19px;
            border-bottom: 1px solid #e1e2e3;
            margin-bottom: 19px;
        }
    }

    .buttonContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
        width:100%px;
        padding-top:30px;
    }
`;
