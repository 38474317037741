import { useEffect, useState, useCallback } from "react";
import { Text, Box } from "alisson-application";
import Mask from "react-input-mask";
import Select from "../../../NovoSelect";

import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { ModalFormProps, modalInitalValues, validationModal } from "./config";
import { useContex } from "../Hook";

import { Button } from "../../../Button";
import { ModalStyles } from "../styles";
import imgXVerde from "../../../../../assets/img/x.svg";
import { toast } from "react-toastify";

interface FormClienteProps {
    setSucess: (value: boolean) => void;
}

export function FormCliente(props: FormClienteProps) {
    const { setSucess } = props;
    const { setModal, areas, contato, contatos, storeContato, updateContato } =
        useContex();
    const [initalValues, setInitalValues] = useState<ModalFormProps>(
        {} as ModalFormProps
    );
    const hasEditar = Object.values(contato).length;

    const hasArea = useCallback(() => {
        if (hasEditar) {
            return areas.filter(
                (item) => item.label === contato?.area.label
            )[0];
        }
        return null;
    }, [areas, contato?.area?.label, hasEditar]);

    useEffect(() => {
        if (hasEditar > 0) {
            setInitalValues(contato);
        } else {
            setInitalValues(modalInitalValues);
        }
    }, [contato, hasEditar]);

    return (
        <ModalStyles>
            <Formik
                enableReinitialize
                initialValues={initalValues}
                validationSchema={validationModal}
                onSubmit={async (values, actions) => {
                    if (hasEditar) {
                        await updateContato(values);
                        setSucess(true);
                        return;
                    } else {
                        const index = contatos.findIndex(
                            (item) => item.email === values.email
                        );

                        if (index > -1) {
                            actions.setErrors({ email: "Email já cadastrado" });
                            toast.error("Email já cadastrado");
                            return;
                        } else {
                            await storeContato(values);
                            actions.resetForm();
                            setSucess(true);
                        }
                    }
                }}
            >
                {(props: FormikProps<ModalFormProps>) => {
                    const { handleChange, setFieldValue, values } = props;
                    const { celular, telefone } = values;

                    return (
                        <Form>
                            <div className="container-header">
                                <Text as="p">
                                    {hasEditar
                                        ? "Editar Contato"
                                        : "NOVO contato"}
                                </Text>
                                <button
                                    type="button"
                                    onClick={() => setModal(false)}
                                >
                                    <img src={imgXVerde} alt="" />
                                </button>
                            </div>

                            <div className="container-content">
                                <Text as="span">
                                    Preencha os campos abaixo para adicionar um
                                    novo contato ao cliente.
                                </Text>
                            </div>

                            <div className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <Select
                                initalValue={hasEditar ? hasArea() : null}
                                options={areas}
                                label="AREA"
                                onChange={(values) => {
                                    setFieldValue("area", values);
                                }}
                            />
                            <ErrorMessage component="span" name="area" />

                            <div className="container-input-system contato">
                                <Field
                                    type="email"
                                    placeholder="EMAIL"
                                    name="email"
                                />
                            </div>
                            <ErrorMessage component="span" name="email" />

                            <div className="container-input-system celular">
                                <Mask
                                    mask="(99) 99999-9999"
                                    placeholder="CELULAR"
                                    name="celular"
                                    type="tel"
                                    onChange={handleChange}
                                    value={celular}
                                />
                            </div>
                            <ErrorMessage component="span" name="celular" />

                            <div className="container-input-system telefone">
                                <Mask
                                    mask="(99) 9999-9999"
                                    placeholder="TELEFONE"
                                    name="telefone"
                                    type="tel"
                                    onChange={handleChange}
                                    value={telefone}
                                />
                            </div>
                            <ErrorMessage component="span" name="telefone" />

                            <Box mt="75px">
                                <Button type="submit">
                                    {" "}
                                    {hasEditar
                                        ? "EDITAR CONTATO"
                                        : "ADICIONAR CONTATO"}{" "}
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </ModalStyles>
    );
}
