import { DebounceInput } from "react-debounce-input";
import { BuscaStyles } from "./styles";
import { useState } from "react";

interface BuscaProps {
    serach: (value: string) => Promise<void>;
}
export function Busca(props: BuscaProps) {
    const { serach } = props;
    const [loading, setLoading] = useState(false);

    return (
        <BuscaStyles>
            <div className="busca">
                <div className="container-input-system busca">
                    <DebounceInput
                        type="text"
                        debounceTimeout={1000}
                        disabled={loading}
                        onChange={async (e) => {
                            setLoading(true);
                            await serach(e.target.value);
                            setLoading(false);
                        }}
                        placeholder="PESQUISAR"
                    />
                </div>
            </div>
        </BuscaStyles>
    );
}
