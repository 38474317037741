import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding-top: 44px;

    .passos {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4px;
        margin-bottom: 28px;
        padding: 0 25px;
        span {
            width: 100%;
            display: block;
            height: 2px;
            background-color: #c6c8ca;
            &.active {
                background-color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    .container-tabs {
        padding-top: 34px;
        .tab {
            button {
                display: block;
                margin: 0 auto;
                margin-bottom: 37px;
                width: 90%;
            }
        }
    }

    @media (min-width: 768px) {
        width: 375px;
    }
`;

export const BemVindoStyles = styled.div`
    width: 100%;
    .container-img {
        width: 179px;
        margin: 0 auto;
        margin-bottom: 51px;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        width: 174px;
        margin-bottom: 25px;
    }

    .texts {
        padding: 0 25px;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primaryDark};
            margin-bottom: 34px;
        }
    }
`;

export const ValidarEmailStyles = styled.div`
    .container-img {
        width: 242px;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .change-email {
        h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 14px;
        }

        .container-input {
            margin-bottom: 12px;
            padding: 0 25px;
            &:before {
                left: 45px;
                height: 16px;
            }
            &:after {
                display: none;
            }
        }

        button {
            &.alterar {
                margin: 0px auto;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                letter-spacing: 0.08em;
                color: ${({ theme }) => theme.colors.secondary};
                margin-bottom: 37px;
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primaryDark};
            margin-bottom: 37px;
            width: 100%;
            padding: 0 25px;
        }
    }
`;

export const ValidaDadosStyles = styled.div`
    .container-text {
        position: relative;
        width: 317px;
        margin: 0 auto;
        margin-bottom: 55px;
        &:before {
            content: "";
            display: block;
            width: 27px;
            height: 4px;
            border-radius: 5px;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            background-color: ${({ theme }) => theme.colors.secondary};
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            padding-top: 26px;
        }
    }

    .container-form {
        padding: 0 25px;
        .container-input {
            margin-bottom: 32px;
        }
        small {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.primaryDark};
            display: block;
            text-align: center;
            margin-bottom: 8px;
        }
    }

    button {
        &.alterar {
            margin: 0px auto;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.secondary};
            margin-bottom: 37px;
        }
    }
    .MuiTypography-root {
        white-space: pre-wrap !important;
        text-align: left !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: ${({ theme }) => theme.colors.primaryDark};
    }
`;

export const ObrigadoStyles = styled.div`
    .container-img {
        width: 287px;
        margin: 0 auto;
        margin-bottom: 37px;
    }

    .container-text {
        width: 270px;
        margin: 0 auto;
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            margin-bottom: 25px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primaryDark};
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`;
