import { Container } from "./styles";
import { WrapperUpload } from "./upload";
import { Table } from "./table";
import { RegulamentoProvider } from "../Hook";

export function Regulamento() {
    return (
        <Container>
            <RegulamentoProvider>
                <WrapperUpload />
                <Table />
            </RegulamentoProvider>
        </Container>
    );
}
