import { Container } from "./styles";
import imgSetaCima from "../../../assets/img/seta-cima.svg";

interface ButtonProps {
    name: string;
}

export function ButtonUploadDefault(props: ButtonProps) {
    return (
        <Container>
            <span>
                <img src={imgSetaCima} alt="" />
            </span>

            <p>{props.name ? props.name : "UPLOAD DE DOCUMENTO"} </p>
        </Container>
    );
}
