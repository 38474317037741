import { AdicionarPermissao } from "../../../../../components/System/Administracao/Permissao/adicionar";
import { UserProvider } from "../../../../../Hook/system/useUser";

export function PageAdicionarPermissao() {
    return (
        <UserProvider>
            <AdicionarPermissao />
        </UserProvider>
    );
}
