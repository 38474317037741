import { useEffect, useState } from "react";
import { Container } from "./styles";
import { Header, IHeader } from "./header";
import Table from "../../Table";
import { useRelatorio, Detail } from "../../../../Hook/system/useRelatorio";
import { GridColumns } from "@mui/x-data-grid-pro";
import { formatCurrentMoney, formatNumber } from "../../../../util/helper";
import { Loader } from "../../../Loader";
import { When } from "../../../when";

interface HeaderProps extends Omit<IHeader, "setSearch"> {}

export function RelatorioDetalhe() {
    const width = 1645 / 11;
    const [loading, setLoading] = useState(true);
    const { fetchDetail } = useRelatorio();
    const [data, setData] = useState<Detail[]>([]);
    const [configHeader, setConfigHeader] = useState<HeaderProps>(
        {} as HeaderProps
    );

    useEffect(() => {
        async function fetch() {
            const dados = await fetchDetail();
            if (!dados.data) {
                setLoading(false);
                return;
            }
            const { data, ...rest } = dados;
            const keys = Object.keys(dados.data);

            const temp = keys.map((key) => {
                return {
                    ...dados.data[key],
                    id: key + "",
                };
            });

            const sumItem = temp.reduce((acc: any, item) => {
                return {
                    valor: acc.valor + item.valor || 0,
                    resgate: acc.resgate + item.resgate || 0,
                    restante: acc.restante + item.restante || 0,
                    cancelamento: acc.cancelamento + item.cancelamento || 0,
                    t_avulso: acc.t_avulso + item.t_avulso || 0,
                    t_virtual: acc.t_virtual + item.t_virtual || 0,
                    t_fisico: acc.t_fisico + item.t_fisico || 0,
                    t_activate: acc.t_activate + item.t_activate || 0,
                    t_c_activate: acc.t_c_activate + item.t_c_activate || 0,
                    id: "TOTAIS",
                    campanha: "",
                };
            }, {});

            const detail: Detail[] = [...temp, sumItem].map((item: Detail) => {
                return {
                    ...item,
                    id: item.id,
                    valor: `R$ ${formatCurrentMoney(item.valor as number)}`,
                    restante: `R$ ${formatCurrentMoney(
                        item.restante as number
                    )}`,
                    t_avulso: `${formatNumber(parseFloat(item.t_avulso + ""))}`,

                    t_virtual: `${formatNumber(
                        parseFloat(item.t_virtual + "")
                    )}`,

                    t_fisico: `${formatNumber(parseFloat(item.t_fisico + ""))}`,
                    t_activate: `${formatNumber(
                        parseFloat(item.t_activate + "")
                    )}`,
                    t_c_activate: `${formatNumber(
                        parseFloat(item.t_c_activate + "")
                    )}`,
                    resgate: `R$ ${formatCurrentMoney(item.resgate as number)}`,
                    cancelamento: `R$ ${formatCurrentMoney(
                        item.cancelamento as number
                    )}`,
                };
            });

            setConfigHeader(rest);
            setData(detail);
            setLoading(false);
        }

        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 70,
        },
        {
            field: "campanha",
            headerName: "CAMPANHA",
            width,
        },

        {
            field: "valor",
            headerName: "CREDITOS ENVIADOS",
            width,
        },

        {
            field: "restante",
            headerName: "RESTANTE NA CARTEIRA",
            width,
        },

        {
            field: "resgate",
            headerName: "RESGATE",
            width,
        },

        {
            field: "cancelamento",
            headerName: "SLD. CANCELADO",
            width,
        },

        {
            field: "t_avulso",
            headerName: "CARTÃO AVULSO",
            width,
        },

        {
            field: "t_virtual",
            headerName: "CARTÃO VIRTUAL",
            width,
        },

        {
            field: "t_fisico",
            headerName: "CARTÃO FÍSICO",
            width,
        },
        {
            field: "t_activate",
            headerName: "CARTÃO AVULSO P/ ATIVAR",
            width,
        },
        {
            field: "t_c_activate",
            headerName: "VALOR AVULSO P/ ATIVAR",
            width,
        },
    ];

    return (
        <Container>
            <When expr={loading}>
                <div className="container-loading">
                    <Loader />
                </div>
            </When>

            <When expr={!loading}>
                <>
                    <Header {...configHeader} />

                    <Table rows={data} columns={columns} nameCSV="relatório" />
                </>
            </When>
        </Container>
    );
}
