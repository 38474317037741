import { useEffect, useState } from "react";
import { api } from "../../../services";
import { Card } from "./cardEntrega";
import { Logs, Delivery } from "../../Entrega";
import { toast } from "react-toastify";

interface EntregaProps {
    id: number;
}

export function Entrega(props: EntregaProps) {
    const { id } = props;
    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState<Delivery[]>([]);

    useEffect(() => {
        async function fectData() {
            setLoading(true);
            try {
                const response = await api.get<Logs>(`cartao/${id}/delivery`);
                const dados: Delivery[] = [...response.data.logs];
                setLoading(false);
                setDados(dados);
            } catch (error) {
                toast.error(
                    "Estamos enfrentando problemas tecnicos. Tente novamente mais tarde!"
                );
                console.log(error);
                setLoading(false);
            }
        }

        fectData();
    }, [id]);

    return (
        <>
            <Card loading={loading} dados={dados} />
        </>
    );
}
