import { Text } from "alisson-application";
import { Container } from "./styles";
const regex8Caracteres = /^.{8,}$/;
const peloMenosUmNumero = /(?=.*\d)/;
const peloMenosUmCaractereEspecial = /(?=.*\W+)/;
const peloMenosUmaLetraMaiuscula = /(?=.*[A-Z])/;
const peloMenosUmaLetraMinuscula = /(?=.*[a-z])/;
const naoRepetirCaracteres = /(.)\1{3}/;

interface CheckPasswordProps {
    values: string;
}

export function CheckPassword(props: CheckPasswordProps) {
    const { values } = props;
    return (
        <Container>
            <div className="container-alerta">
                <Text as="h4">Atenção</Text>
                <Text as="p">A senha deve ter obrigatoriamente:</Text>
            </div>
            <ul className="list-requisitos">
                <li
                    className={`${
                        regex8Caracteres.test(values) ? "active" : ""
                    }`}
                >
                    <Text as="p">No mínimo 8 caracteres</Text>
                </li>
                <li
                    className={`${
                        peloMenosUmNumero.test(values) ? "active" : ""
                    }`}
                >
                    <Text as="p">No mínimo 1 número</Text>
                </li>
                <li
                    className={`${
                        peloMenosUmaLetraMaiuscula.test(values) ? "active" : ""
                    }`}
                >
                    <Text as="p">No mínimo 1 letra maiúscula</Text>
                </li>
                <li
                    className={`${
                        peloMenosUmaLetraMinuscula.test(values) ? "active" : ""
                    }`}
                >
                    <Text as="p">No mínimo 1 letra minúscula</Text>
                </li>
                <li
                    className={`${
                        !naoRepetirCaracteres.test(values) && values.length
                            ? "active"
                            : ""
                    }`}
                >
                    <Text as="p">
                        Não repetir 4 caracteres iguais em sequência
                    </Text>
                </li>
                <li
                    className={`${
                        peloMenosUmCaractereEspecial.test(values)
                            ? "active"
                            : ""
                    }`}
                >
                    <Text as="p">
                        No mínimo 1 caractere especial (!@#$%^*&)
                    </Text>
                </li>
            </ul>
        </Container>
    );
}
