/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import Countdown, { zeroPad } from "react-countdown";

import WatchIcon from "../../assets/img/WatchIcon";
import { TimerContainer } from "./styles";

interface ITimerProps {
    onComplete?: () => void;
    theme?: "primary" | "secondary";
}

export const Timer = React.memo(
    ({ onComplete, theme = "primary" }: ITimerProps) => {
        const handleComplete = useCallback(() => {
            if (onComplete) onComplete();
        }, []);

        return (
            <TimerContainer $theme={theme}>
                <Countdown
                    date={Date.now() + 60000}
                    onComplete={handleComplete}
                    renderer={({ hours, minutes, seconds }) => {
                        return (
                            <>
                                <WatchIcon />
                                <span>
                                    {zeroPad(hours)}:{zeroPad(minutes)}:
                                    {zeroPad(seconds)}
                                </span>
                            </>
                        );
                    }}
                />
            </TimerContainer>
        );
    }
);
