import styled from "styled-components";
import { Container as Header } from "../../Header/styles";
import imgProfile from "../../../../assets/img/profile.svg";
import imgEndereco from "../../../../assets/img/endereco.svg";
import { Button as ButtonSystem } from "../../Button";

export const HeaderStyles = styled.div`
    border-bottom: 1px solid #e1e2e3;
    display: flex;
    align-items: center;
    .container-goback {
        display: flex;
        align-items: center;
        button {
            margin-left: 29px;
            margin-right: 41px;
            display: flex;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            img {
                margin-right: 10px;
            }
        }
    }

    small {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primaryDark};
        display: block;
    }

    .container-date {
        margin-left: auto;
        margin-right: 210px;
        display: flex;
        align-items: center;
        small {
            &:last-child {
                color: ${(props) => props.theme.colors.black};
                font-weight: bold;
                margin-left: 10px;
            }
        }
    }

    .container-status {
        display: flex;
        align-items: center;
        small {
            margin-right: 21px;
        }
    }

    ${Header} {
        border-bottom: unset;
    }
`;

export const Container = styled.div``;

export const UserStyles = styled.div`
    padding-top: 40px;
    border-bottom: 1px solid #e1e2e3;
    padding-bottom: 54px;
    .container-user {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .user {
            .container-itens {
                button {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    margin-left: 16px;
                    img {
                        display: block;
                        margin-right: 8px;
                    }
                }
            }

            ${ButtonSystem} {
                background-color: transparent;
                border: none;
                text-align: left;
                width: 178px;
                padding: 0px;
                height: 44px;
            }

            .container-text-img {
                display: flex;
                align-items: center;

                .container-img {
                    margin-right: 18px;
                    img {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }
                }

                .container-text {
                    small {
                        display: block;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 100%;
                        letter-spacing: 0.08em;
                        color: ${(props) => props.theme.colors.primaryDark};
                        margin-bottom: 5px;
                    }

                    h3 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 100%;
                        letter-spacing: 0.05em;
                        color: ${(props) => props.theme.colors.primary};
                    }
                }
            }

            .container-saldo {
                background: ${(props) => props.theme.colors.background};
                border-radius: 8px;
                padding: 11px 24px;
                grid-column: span 1;
                margin-top: 13px;
                width: auto;
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    color: ${(props) => props.theme.colors.primaryDark};
                }

                h4 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 100%;
                    letter-spacing: 0.05em;
                    color: ${(props) => props.theme.colors.primary};
                }
            }
        }

        .container-dados {
            display: grid;
            grid-template-columns: 1fr 350px 280px;
            grid-gap: 48px;
            align-items: flex-start;
            justify-content: space-between;
            min-width: 1120px;
        }

        .container-info-pessoal,
        .container-info-endereco,
        .container-info-complemento {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: -2px;
                left: -50px;
                transform: translateX(50%);
                background-color: ${(props) => props.theme.colors.primary};
                -webkit-mask: url(${imgProfile}) no-repeat;
                width: 24px;
                height: 24px;
                z-index: 20;
            }
            .info {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0px;
                }
                input {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    color: ${(props) => props.theme.colors.black};
                    display: block;
                    font-family: "Oxanium", Arial, Helvetica, sans-serif;
                    border: none;
                    padding: 0px;
                    padding-top: 1px;
                    margin-left: 8px;
                    transition: all 0.5s;
                    width: 100%;
                    /* border-bottom: 1px solid
                        ${(props) => props.theme.colors.secondary}; */
                    text-transform: uppercase;
                    &.email {
                        text-transform: lowercase !important;
                    }
                    &:disabled {
                        opacity: 0.8;
                        color: ${(props) => props.theme.colors.primaryDark};
                        cursor: not-allowed;
                        transition: all 0.5s;
                    }
                }
                small {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    color: ${(props) => props.theme.colors.primaryDark};
                    display: block;
                    text-transform: uppercase;
                    white-space: nowrap;
                    &.email {
                        text-transform: lowercase;
                    }
                    &:last-child {
                        color: ${(props) => props.theme.colors.black};
                        font-weight: bold;
                        margin-left: 10px;
                    }
                }
            }
        }

        .container-info-endereco {
            &:before {
                -webkit-mask: url(${imgEndereco}) no-repeat;
            }
        }

        .container-info-complemento {
            &:before {
                display: none;
            }
        }
    }
`;

export const TableStyles = styled.div`
    padding-top: 38px;
`;

export const StyledOlho = styled.button`
    width: 100%;
    display: block;
    text-align: left;
    div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
            margin-left: 8px;
        }
    }
`;

export const Button = styled.button`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 600px;
    text-align: center;
    padding: 10px 16px;
    white-space: nowrap;
    width: fit-content;
    &.active {
        background: ${(props) => props.theme.colors.disabled};
        transition: background 0.5s;
        color: ${(props) => props.theme.colors.secondarydark};
    }
`;

export const MesesStyles = styled.div`
    position: relative;
    margin-bottom: 32px;
    .swiper-slide {
        width: 110px !important;
    }

    .swiper-slide-mes {
        /* width: 790px; */
        .hide-slide {
            opacity: 0;
            pointer-events: none;
        }
    }
    .controles {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        div {
            cursor: pointer;
        }
    }
`;

export const StylesExtrato = styled.div`
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    padding-top: 24px;
    padding-bottom: 24px;

    h5 {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3a3b3d;
        border-top: 1px solid #e1e2e3;
        border-bottom: 1px solid #e1e2e3;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 16px;
    }

    .slide-mes {
        width: 90%;
        margin: 0 auto;
    }

    .container {
        width: 100%;
    }

    .extrato-usuario {
        padding: 24px;
    }
    .container-loading {
        position: static;
    }
`;

export const StylesAlterarSenha = styled.div`
    h5 {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #3a3b3d;
        border-top: 1px solid #e1e2e3;
        border-bottom: 1px solid #e1e2e3;
        height: 59px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 16px;
    }

    form {
        margin-top: 44px;
        padding: 0px 16px;
        padding-bottom: 32px;

        .mt-auto {
            width: 70%;
            margin: 0 auto;
        }
    }
`;
