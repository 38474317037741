import { ProdutoProvider } from "./Hook";
import { useCampanha } from "../../../../../Hook/system/useCampanha";
import { Loader } from "../../../../Loader";
import { Table } from "./table";
import { Container } from "./styles";

export function Produto() {
    const { loading } = useCampanha();

    return (
        <Container>
            <div className={`container-loader ${loading ? "active" : ""}`}>
                <Loader width={100} />
            </div>
            <ProdutoProvider>
                <Table />
            </ProdutoProvider>
        </Container>
    );
}
