import { Switch, useHistory } from "react-router-dom";
import { routes, routesSystem, routesUserSide } from "../../routes";
import { getLocalStore } from "../../util/helper";

import { UsePassisProvider } from "../../Hook/UsePassos";
import { ConfigProvider } from "../../Hook/system/useConfig";
import { SegmentacaoProvider } from "../../Hook/system/useSegmentacao";
import { UseCartoesProvider } from "../../Hook/user/UseCartaoes";
import { UseStateProvider } from "../../Hook/user/UseStateUser";
import { UserProvider } from "../../Hook/user/UseUser";
import { ModalSuccess } from "../ModalSuccess";

import { LoginProvider } from "../../Hook/user/UseLogin";
import { IdWallProvider } from "../../Hook/user/useIdWall";
import Route from "../Route";
import RouteSystem from "../Route/userAdmin";
import RouteUserSide from "../Route/userSide";

export function SwitchRoutes() {
    const history = useHistory();
    // const pathUser = history.location.pathname.replace(
    //     /(^\/[a-z].*\/)([a-zA-Z0-9].*)/,
    //     "$1:id"
    // );

    const path = history.location.pathname
        ? history.location.pathname.replace(
            /(^\/[a-z].*\/)([a-zA-Z0-9].*)/,
            "$1:id"
        )
        : window.location.pathname.replace(
            /(^\/[a-z].*\/)([a-zA-Z0-9].*)/,
            "$1:id"
        );

    const pathSystem = history.location.pathname.replace(/[0-9]+/g, ":id");

    const findRouteSystem = routesSystem.find(
        (route) => route.path === pathSystem
    );
    const findRouteUserSide = routesUserSide.find(
        (route) => route.path === path
    );

    if (findRouteSystem?.isPrivate && getLocalStore()?.token_adm) {
        return (
            <SegmentacaoProvider>
                <ConfigProvider>
                    <Switch>
                        {routesSystem.map((props, index) => {
                            return <RouteSystem {...props} key={index} />;
                        })}
                    </Switch>
                </ConfigProvider>
            </SegmentacaoProvider>
        );
    }

    if (findRouteUserSide?.isPrivate && getLocalStore()?.token_user) {
        return (
            <UserProvider>
                <UseStateProvider>
                    <UseCartoesProvider>
                        <LoginProvider>
                            <IdWallProvider>
                                <Switch>
                                    {routesUserSide.map((props, index) => {
                                        return (
                                            <RouteUserSide {...props} key={index} />
                                        );
                                    })}
                                </Switch>
                            </IdWallProvider>
                        </LoginProvider>
                    </UseCartoesProvider>
                    <ModalSuccess />
                </UseStateProvider>
            </UserProvider>
        );
    }

    return (
        <UserProvider>
            <UsePassisProvider>
                <LoginProvider>
                    <IdWallProvider>
                        <Switch>
                            {routes.map((props, index) => {
                                return <Route {...props} key={index} />;
                            })}
                        </Switch>
                    </IdWallProvider>
                </LoginProvider>
                <ModalSuccess />
            </UsePassisProvider>
        </UserProvider>
    );
}
