import styled from "styled-components";
import { Link as Url, LinkProps } from "react-router-dom";

interface imgProps extends LinkProps {
    icone?: string;
}

export const Link = styled(Url)<imgProps>`
    width: 28px;
    height: 28px;
    position: relative;
    display: block;
    &.active {
        &:before {
            background: ${(props) => props.theme.colors.secondary};
        }
    }
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-mask: url("${(props) => props.icone}");
        -webkit-mask-repeat: no-repeat;
        background: #95989a;
    }
`;

export const MenuStyles = styled.div`
    width: 148px;
    border-right: 1px solid #ececec;
    padding-top: 2.5rem;
    display: flex;
    flex-direction: column;
    .sair {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }
    li {
        list-style: none;
    }
    .logo {
        margin-bottom: 5.625rem;
        a {
            display: block;
            width: 82.95px;
            height: 50px;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
    }

    ul {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
            width: 28px;
            height: 28px;
            margin-bottom: 3.125rem;
        }
    }
`;
