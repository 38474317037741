import { Text, Flex, Box } from "alisson-application";
import { formatCurrentMoney } from "../../../util/helper";
import { useStateUser } from "../../../Hook/user/UseStateUser";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";

import { UseModal } from "../../../Hook/Usemodal";
import imgCartaoCinza from "../../../assets/img/cartao-cinza.svg";
import imgValor from "../../../assets/img/valor.svg";
import { InforCartaoStyles } from "./styles";
import { IOSSwitch } from "../../System/Switch";

export function InforCartao() {
    const { cartao, saldo, getSaldo, cartaoId } = UseCartoes();
    const { stateUser } = useStateUser();
    const { handleOpen } = UseModal();

    //alissonferreira@cryptos.com.br
    return (
        <InforCartaoStyles alignItems="center">
            <div className="container-info">
                <div className="container-card">
                    <img src={imgCartaoCinza} alt="" />
                    <h3>
                        {cartao?.produto
                            ? cartao?.produto?.nome.toLowerCase()
                            : stateUser?.type === "cartao_virtual"
                            ? "VIRTUAL"
                            : "FÍSICO"}
                    </h3>
                    <p>{cartao?.acg_account_card_last4digits}</p>
                </div>

                <button
                    onClick={async () => {
                        await getSaldo(cartaoId ? cartaoId : 0);
                    }}
                >
                    <div className="container-value">
                        <img src={imgValor} alt="" />
                        <h3 className="saldo">Saldo do Cartão</h3>
                        <h2>
                            {saldo
                                ? "R$" + formatCurrentMoney(parseFloat(saldo))
                                : "R$ ***"}
                        </h2>
                    </div>
                </button>
            </div>

            <Box ml="75px">
                <Flex alignItems="center">
                    <Box mr="10px">
                        <IOSSwitch
                            disabled={cartao?.status === "CANCELADO"}
                            checked={cartao?.acg_sms_service ? true : false}
                            onChange={() => {
                                handleOpen("confirmaToggleSms");
                            }}
                        />
                    </Box>
                    <Text fontSize="xss" mr="10px">
                        ATIVAR SMS DE TRANSAÇÕES
                    </Text>
                </Flex>
            </Box>
        </InforCartaoStyles>
    );
}
