import Cropper from "react-easy-crop";
import { Box } from "alisson-application";
import { Button } from "../Desktop/Button";
import { HeaderModal } from "../Mobile/HeaderModal";
import { useStateUser } from "../../Hook/user/UseStateUser";
import { useUser } from "../../Hook/user/UseUser";
import { UseModal } from "../../Hook/Usemodal";
import { getCroppedImg } from "./canvasUtils";
import { useCallback, useState } from "react";
import { Container } from "./styles";
import { Sucesso } from "../Modais/DesktopAndMobile/sucesso";

export function ContentModalCrop() {
    const { imageSrc } = useStateUser();
    const { handleClose } = UseModal();
    const { updateFotoUsuario } = useUser();
    const [rotation, setRotation] = useState(0);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            if (imageSrc && croppedAreaPixels) {
                setLoading(true);
                const croppedImage: any = await getCroppedImg(
                    imageSrc,
                    croppedAreaPixels,
                    rotation
                );

                const file = await convertBase64InFile(
                    croppedImage,
                    "image.png",
                    "image/png"
                );

                const formDate = new FormData();
                formDate.append("image", file);
                const response = await updateFotoUsuario(formDate);

                setLoading(false);

                if(response?.status === 200){   
                    setSuccess(true)
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels, rotation, updateFotoUsuario]);

    const convertBase64InFile = async (
        url: string,
        filename: string,
        mimeType: string
    ) => {
        const res = await fetch(url);
        const buf = await res.arrayBuffer();
        return new File([buf], filename, { type: mimeType });
    };

    return (
        <Container>
            {!success ? 
                <>
                    <HeaderModal
                        title="Alterar imagem"
                        handleClose={() => handleClose("crop")}
                    />
                    <div className="cropContainer">
                        <Cropper
                            image={imageSrc ? imageSrc : ""}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropSize={{ width: 150, height: 150 }}
                        />
                    </div>
                    <Box width="150px" m="0 auto" mb="35px">
                        <Button
                            onClick={showCroppedImage}
                            variant="contained"
                            color="primary"
                            isLoading={loading}
                            spiner="ThreeDots"
                        >
                            Cortar
                        </Button>
                    </Box>
                </>
            :
            <Sucesso btnLabel="Ok" handleClose={() => handleClose("crop")}>
                <h1>
                    Foto Alterada
                    <br/>
                    com Sucesso!
                </h1>
                <br/>
                <br/>
            </Sucesso>
            }
        </Container>
    );
}
