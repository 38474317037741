import styled from "styled-components";

export const Container = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border: 1px solid #bcdfba;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 8px;
    p {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        margin-left: 8px;
    }
`;

export const StylesSegmento = styled.div`
    width: 1158px;
    margin: 0 auto;
    .itens {
        border-top: 1px solid #e1e2e3;
        border-bottom: 1px solid #e1e2e3;
        /* height: 100%; */
        overflow: hidden;
        transition: all 0.5s;
        &.active {
            .container-item {
                .container-cliente {
                    .selecionar-tudo {
                        button {
                            transform: rotateX(180deg);
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
    .container-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 96px;
        .container-cliente {
            display: flex;
            justify-content: space-between;
            .selecionar-tudo {
                display: flex;
                align-items: center;
                justify-content: space-between;
                button {
                    margin-bottom: 5px;
                    transition: all 0.5s;
                }
            }
        }
    }

    .container-campanha {
        .campanhas {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

export const StylesClient = styled.div`
    width: 1158px;
    margin: 0 auto;
    .container-header {
        height: 87px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e1e2e3;
    }

    .container-body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 32px;
        padding-top: 26px;
    }
`;
