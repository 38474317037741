import { Text, Box } from "alisson-application";
import {
    OverlayActive,
    OverlayBlock,
    OverlayProcess,
    OverlayCancelar,
} from "../OverlayStatus";
import imbBandeira from "../../../assets/img/bandeira.svg";
import { CardStyles } from "./styles";

interface CardProps {
    price: string;
    number: string;
    type: string;
    color: string;
    status: string;
}

export function Card(props: CardProps) {
    const { color, number, type, status } = props;
    return (
        <CardStyles background={color} status={status !== "ATIVO"}>
            <Box className="bandeira">
                <img src={imbBandeira} alt="" />
            </Box>

            <Box className="number-card">
                <Text as="p">{number}</Text>
            </Box>

            <Text as="p" className="type">
                {type}
            </Text>

            {status === "INATIVO" && <OverlayActive number={number} />}
            {status === "BLOQUEADO" && <OverlayBlock number={number} />}
            {status === "PROCESSANDO" && <OverlayProcess number={number} />}
            {status === "ATIVANDO" && <OverlayProcess number={number} />}
            {status === "CANCELADO" && <OverlayCancelar number={number} />}
        </CardStyles>
    );
}
