import styled from "styled-components";
import Yimg from "../../../assets/img/Y.svg";

export const Container = styled.div`
    .container-tabs {
        padding-top: 34px;
        padding-bottom: 32px;
        padding-right: 9px;
    }
`;

export const LimitiCardsStyles = styled.div`
    margin-bottom: 60px;

    .contaner-cartao-fisico {
        display: flex;
        align-items: center;
        padding: 26px;
        margin-bottom: 8px;
        background-color: #f3f3f3;
        border-radius: 5px;
        .container-img {
            margin-right: 30px;
        }
        .container-text {
            h4 {
                font-size: 14px;
                color: ${({ theme }) => theme.colors.secondary};

                margin-bottom: 6px;
            }
            p {
                font-size: 12px;
                color: ${({ theme }) => theme.colors.primaryDark};
                margin: 0 !important;
                text-align: left !important;
            }
        }
    }

    .contaner-cartao-virtual {
        display: flex;
        align-items: center;
        padding: 26px;
        background-color: #f3f3f3;
        border-radius: 5px;
        .container-img {
            margin-right: 30px;
        }
        .container-text {
            h4 {
                font-size: 14px;
                color: ${({ theme }) => theme.colors.tertiarydark};
                margin-bottom: 6px;
                text-align: left !important;
            }
            p {
                font-size: 12px;
                color: ${({ theme }) => theme.colors.primaryDark};
                margin: 6px 0 0 0 !important;
                text-align: left !important;
            }
        }
    }
`;

export const SolicitarStyles = styled.div`
    padding-left:9px;

    h3 {
        margin: 0 auto;
        margin-bottom: 14px;
        width: 225px;
        text-align: center;
    }

    small {
        font-size: ${({ theme }) => theme.fonts.xss};
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryDark};
        display: block;
        text-align: center;
        margin-top: 13px;
        margin-bottom: 39px;
    }

    .container-slide {
        position: relative;
        .swiper-container {
            position: relative;
            .bloqueado {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: ${({ theme }) => theme.colors.failure};
                font-weight: bold;
            }
        }
    }

    .container-input {
        margin-bottom: 17px;
        input {
            height: 40px;
            padding-left: 42px;
            margin-bottom:35px;
            font-family: "Oxanium";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.05em;
            color: ${({ theme }) => theme.colors.primary};
        }

        &.valor:before{
                left: 12px
            }
    }

    .slide-cartoes {
        width: 247px;
        margin: 0 auto;
        .detalhe-card {
            padding: 19px 22px 23px 21px;
            border: 1px solid ${({ theme }) => theme.colors.secondary};
            box-sizing: border-box;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;
            &.inativo {
                pointer-events: none;
                border: 1px solid ${({ theme }) => theme.colors.failure};
                position: relative;
                z-index: 30;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(255 255 255 / 0.8);
                    border-radius: 8px;
                    z-index: 40;
                }
                span {
                    &.inativo {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) rotate(-45deg);
                        font-size: ${({ theme }) => theme.fonts.xss};
                        font-weight: 500;
                        color: ${({ theme }) => theme.colors.failure};
                        text-align: center;
                        z-index: 50;
                    }
                }
            }
            .container-info {
                h4 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.primary};
                    margin-bottom: 8px;
                    white-space: nowrap;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.primaryDark};
                }
            }
        }
    }

    .controles {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 95%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        div {
            cursor: pointer;
            &.swiper-button-disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
`;

interface CardProps {
    background: string;
}

export const CardStyles = styled.div<CardProps>`
    background: ${(props) => props.background};
    border-radius: 2px;
    padding: 7.26px 3px 2.89px 2px;
    height: 43px;
    width: 34px;
    position: relative;
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${(props) => props.theme.colors.white};
        -webkit-mask: url(${Yimg}) no-repeat;
        width: 34px;
        height: 43px;
        z-index: 20;
        -webkit-mask-position: 0 0;
        -webkit-mask-size: 34px;
        transition: background-color 0.5s;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 3.63107px;
        line-height: 5px;
        color: ${(props) => props.theme.colors.white};
        margin-bottom: 12px;
    }

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 2.9px;
        line-height: 100%;
        letter-spacing: 0.06em;
        color: ${(props) => props.theme.colors.white};
        margin: 0;
        display: block;
        margin-bottom: 7.5px;
        white-space: nowrap;
    }

    .container-img {
        height: 4px;
        img {
            height: 100%;
            width: 7px;
            display: block;
            margin-left: auto;
        }
    }

    /* 

    .card {
        
    } */
`;

export const ConfirmaStyles = styled.div`
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: ${(props) => props.theme.fonts.md};
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 18px;
        text-transform:none;
    }

    .container-lista {
        margin-bottom: 64px;
        .item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e1e2e3;
            height: 46px;
            position: relative;
            .segundo-item {
                position: static;
                &:before {
                    left: 60%;
                }
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }

    .container-card-confirma {
        border: 1px solid ${(props) => props.theme.colors.secondarybright};
        box-sizing: border-box;
        border-radius: 8px;
        display: flex;
        align-items: start;
        padding: 28px 37px 26.95px 33px;
        margin-bottom: 46px;
        ${CardStyles} {
            width: 57px;
            height: 73.05px;
            padding: 12px 5.53px 4.85px 4.89px;
            border-radius: 2.61774px;
            border: none;
            &:before {
                width: 57px;
                height: 73.05px;
                -webkit-mask-size: 57px;
            }
            p {
                font-size: 6.08738px;
                margin-bottom: 21px;
            }

            span {
                font-size: 4.53398px;
                margin-bottom: 12px;
            }

            .container-img {
                img {
                    width: 11.78px;
                    height: 7.29px;
                }
            }

            .container-text {
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 6.92661px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    color: ${(props) => props.theme.colors.white};
                }
            }
        }

        .container-detlhes {
            margin-left: 29px;
            h4 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }
`;

export const FinalizarStyles = styled.div`
    padding:1px 25px 3px 25px;
    div {
        text-align: center;
        font-size:14px;
        line-height: 130%;
        margin-bottom: 0;
        img{
            margin-bottom:10px;
        }
    }

    p {
        font-size: ${(props) => props.theme.fonts.mdl};
        color: ${(props) => props.theme.colors.secondary};
        width: 286px;
        margin: 0 auto;
        font-size:20px;
        line-height: 140%;
        text-align: center;
        font-weight: 700;
        margin-bottom: 34px;
    }

    button{
        height: 40px;
        margin-top: 40px;
        font-weight:700;
    }
`;

export const AtivarCartao = styled.div`
    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        width: 244px;
        margin: 0 auto;
        margin-bottom: 19px;
    }

    p {
        font-family: "Oxanium";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
    }

    .check {
        margin: 29px 0 22px;
        span {
            div {
                font-weight: bold;
                font-size: ${({ theme }) => theme.fonts.xs};
                white-space: nowrap;
                line-height: 23px;
            }
        }
    }
`;
