import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { HorarioFuncionamento } from "../../Mobile/HorarioFuncionamento";

export function ModalHorarioFuncionamento(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <HorarioFuncionamento />
        </ModalBottom>
    );
}
