import React, { useEffect, useState } from "react";

import { Variant } from "../../../Hook/types";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { crypted, getLocalStore, phoneNumber } from "../../../util/helper";
import { ResendCode } from "../../ResendCode";
import { Timer } from "../../Timer";

interface EnviaProps {
    mobile_number: string;
}

export const Reenviar = React.memo((props: EnviaProps) => {
    const { mobile_number } = props;
    const { dados } = usePassos();
    const token = getLocalStore().token_user;
    const { validateUser, validateUserSemToken } = useUser();

    const [timer, setTimer] = useState(false);

    useEffect(() => {
        setTimer(true);
    }, []);

    async function send(send: Variant) {
        setTimer(true);

        if (mobile_number && !token) {
            validateUserSemToken(send, crypted(`document#${dados.document}`));
        } else {
            await validateUser(send, mobile_number);
        }
    }

    return (
        <>
            {timer && <Timer onComplete={() => setTimer(false)} />}

            {!timer && (
                <ResendCode
                    onResend={async () => await send("whatsapp")}
                    useWhatsApp
                    user={{ mobile_number: phoneNumber(mobile_number) }}
                />
            )}
        </>
    );
});
