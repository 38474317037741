import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { api } from "../../services/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { User } from "./useUser";

interface UseLogCargaDeCreditoProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseLogCargaDeCredito {
    carga: Carga;
    loading: boolean;
    deleteCarga(carga_id: number): Promise<void>;
}

const UseLogCargaDeCreditoContext = createContext<UseLogCargaDeCredito>(
    {} as UseLogCargaDeCredito
);

interface Carga {
    approve_user_id: null | number;
    campanha_id: number;
    cliente_area_contato_id: number;
    created_at: string;
    external: number;
    file: string;
    id: number;
    job: null | string;
    nome: string;
    observacao: string | null;
    status: string;
    tipo: string;
    updated_at: string;
    user_id: number;
    creditos: Creditos[];
}

interface Creditos {
    carga_id: number;
    created_at: string;
    cupom_item_id: null | string;
    id: number;
    operacao_id: null;
    referencia: string;
    restante: string;
    status: number;
    updated_at: string;
    user_id: number;
    valor: string;
    user: User;
}

export function UseLogCargaDeCreditoProvider(
    props: UseLogCargaDeCreditoProviderProps
) {
    const { children } = props;
    const history = useHistory<{ id: number }>();
    const id = history.location.state?.id;
    const [carga, setCarga] = useState<Carga>({} as Carga);
    const [loading, setLoading] = useState(false);

    async function deleteCarga(carga_id: number) {
        try {
            await api.delete(`carga/${id}/item/${carga_id}/CREDITO`);
            toast.success("Carga deletada com sucesso");
            await getCarga();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao deletar carga");
        }
    }

    const getCarga = useCallback(async () => {
        try {
            setLoading(true);
            const response = await api.get<Carga>(`/carga/${id}`);
            setCarga(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("Erro ao buscar carga");
        }
    }, [id]);

    useEffect(() => {
        getCarga();
    }, [getCarga]);

    return (
        <UseLogCargaDeCreditoContext.Provider
            value={{ carga, loading, deleteCarga }}
        >
            {children}
        </UseLogCargaDeCreditoContext.Provider>
    );
}

export function useLogCargaDeCredito() {
    const context = useContext(UseLogCargaDeCreditoContext);
    return context;
}
