import * as Yup from "yup";
const regexValidadeCPf = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
const regexValidateBirthDate = /^\d{2}\/\d{2}\/\d{4}$/;

// const regex =
//     /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

export interface Myform {
    document: string;
    name: string;
    apelido: string;
    data_nascimento: string;

    genero: string;

    // nome_da_mae: string;
    nome_cpf: string;

    email: string;
    phone_number: string;
}

export const initalValues: Myform = {
    document: "",
    name: "",
    apelido: "",
    data_nascimento: "",

    genero: "",

    // nome_da_mae: "",
    nome_cpf: "",

    email: "",
    phone_number: "",
};

export const validation = Yup.object().shape({
    document: Yup.string()
        .matches(regexValidadeCPf, "Digite um CPF valido")
        .required("Campo obrigatório"),
    name: Yup.string()
        .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),
    apelido: Yup.string().required("Campo obrigatório"),
    data_nascimento: Yup.string()
        .matches(regexValidateBirthDate, "Data de nascimento inválida")
        .required("Campo obrigatório"),

    genero: Yup.string().required("Campo obrigatório"),
    // nome_da_mae: Yup.string()
    //     .min(2, "Mínimo 2 caracteres")
    //     .required("DIGITE O NOME COMPLETO"),
    nome_cpf: Yup.string().required("Campo obrigatório").nullable(),

    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    phone_number: Yup.string().required("Celular é obrigatório"),
});

export interface MyformCNPJ {
    document: string;
    name: string;
    apelido: string;

    nome_cpf: string;

    email: string;
    phone_number: string;
}

export const initalValuesCNPJ: MyformCNPJ = {
    document: "",
    name: "",
    apelido: "",

    nome_cpf: "",

    email: "",
    phone_number: "",
};

export const validationCNPJ = Yup.object().shape({
    document: Yup.string().required("Campo obrigatório"),
    name: Yup.string()
        .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),
    apelido: Yup.string().required("Campo obrigatório"),

    nome_cpf: Yup.string().nullable(),

    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    phone_number: Yup.string().required("Celular é obrigatório"),
});
