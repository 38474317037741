import { useContext, createContext } from "react";
import { api } from "../../services";
import { toast } from "react-toastify";

interface useTemplateProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseTemplate {
    getTemplate(value: keyOf<Channel>): Promise<Template[]>;
}

const UseTemplateContext = createContext<IuseTemplate>({} as IuseTemplate);

export interface Template {
    id: number;
    status: number;
    zenvia_id: string;
    channel: string;
    name: string;
    text: string;
    fields: string;
    created_at: string;
    updated_at: string;
    has_image: boolean;
}

interface Channel {
    SMS: "SMS";
    WHATSAPP: "WHATSAPP";
}

type keyOf<T> = keyof T;

export function TemplateProvider(props: useTemplateProviderProps) {
    const { children } = props;

    async function getTemplate(value: keyOf<Channel>) {
        try {
            const response = await api.get<Template[]>(
                `/template?channel=${value}`
            );

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar clientes");
            return [] as Template[];
        }
    }

    return (
        <UseTemplateContext.Provider
            value={{
                getTemplate,
            }}
        >
            {children}
        </UseTemplateContext.Provider>
    );
}

export function useTemplate() {
    const context = useContext(UseTemplateContext);
    return context;
}
