import { Link } from "react-router-dom";
import { SubHeader } from "../SubHeader";
import { UseModal } from "../../../Hook/Usemodal";
import { useUser } from "../../../Hook/user/UseUser";

import { Container } from "./styles";
import imgLogo from "../../../assets/img/logo-desktop.svg";
import imgSino from "../../../assets/img/sino.svg";
import imgHamburger from "../../../assets/img/hamburger.svg";

export function Header() {
    const { state, setState } = UseModal();
    const { user } = useUser();

    const hasnotificacao = user?.notificacoes?.filter(
        (item) => item.describe !== "__ACTIVE_MODAL_CREDITO__"
    );

    return (
        <>
            <Container>
                <div className="container">
                    <div className="container-logo">
                        <Link to="/dashboard">
                            <img src={imgLogo} alt="" />
                        </Link>
                    </div>

                    <div className="container-info">
                        <button
                            onClick={() =>
                                setState({
                                    ...state,
                                    notificacao: true,
                                })
                            }
                            className={`${
                                hasnotificacao?.length
                                    ? "container-notificacao"
                                    : ""
                            }`}
                        >
                            <img src={imgSino} alt="" />
                        </button>
                        <button
                            className="container-toggle"
                            onClick={() =>
                                setState({
                                    ...state,
                                    menu: true,
                                })
                            }
                        >
                            <img src={imgHamburger} alt="" />
                        </button>
                    </div>
                </div>
            </Container>

            <SubHeader />
        </>
    );
}
