import { useEffect, useState } from "react";
import { Box } from "alisson-application";
import axios from "axios";
import Mask from "react-input-mask";
import { useUser } from "../../Hook/user/UseUser";
import { UseModal } from "../../Hook/Usemodal";
import { Button } from "../Mobile/Button";
import { CheckBox } from "../Mobile/CheckBox";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import {
    IEndereco,
    endereco,
    validationEndereco,
    isValidBRZipRegex,
} from "./config";
import imgLupa from "../../assets/img/lupa-branca.svg";
import { toast } from "react-toastify";

interface Cep {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
    erro?: string;
}

export function FormAlterarEndereco() {
    const { user, setUpdateEnderecoUser } = useUser();
    const { state, setState } = UseModal();
    const [initalValues, setInitalValues] = useState<IEndereco>(endereco);
    const [loading, setLoading] = useState(false);
    const [disabledNumero, setDisabledNumero] = useState(false);
    const [loadingCep, setLoadingCep] = useState(false);

    async function findCep(value: string) {
        const regex = /[^\d]+/g;
        try {
            setLoadingCep(true);
            const response = await axios.get<Cep>(
                `https://viacep.com.br/ws/${value.replace(regex, "")}/json/`
            );

            if (response.data.erro && response.data.erro === "true") {
                toast.error("CEP inválido ou não localizado");
                setLoadingCep(false);
                return;
            }

            const obj = {
                cep: value,
                estado: response.data.uf,
                cidade: response.data.localidade,
                nome: response.data.logradouro,
                bairro: response.data.bairro,
                complemento: "",
                numero: " ",
            };

            setInitalValues({ ...initalValues, ...obj });
            setLoadingCep(false);
        } catch (error) {
            setLoadingCep(false);
            console.log(error);
        }
    }

    useEffect(() => {
        setInitalValues({
            ...endereco,
            cep: user?.enderecos
                ? user?.enderecos[0].cep.replace(
                      /^([0-9]{5})([0-9]{3})/g,
                      "$1-$2"
                  )
                : "",
            bairro: user?.enderecos ? user?.enderecos[0].bairro : "",
            cidade: user?.enderecos ? user?.enderecos[0]?.cidade : "",
            nome: user?.enderecos ? user?.enderecos[0]?.nome : "",
            estado: user?.enderecos ? user?.enderecos[0]?.estado : "",
            numero: user?.enderecos ? user?.enderecos[0]?.numero : "",
            complemento: user?.enderecos ? user?.enderecos[0]?.complemento : "",
        });
    }, [user]);

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initalValues}
            validationSchema={validationEndereco}
            onSubmit={async (values, actions) => {
                setLoading(true);

                setUpdateEnderecoUser(values);

                setState({
                    ...state,
                    updateEndereco: true,
                });

                setLoading(false);
            }}
        >
            {(props: FormikProps<IEndereco>) => {
                const { values, setFieldValue } = props;
                const { cep } = values;
                return (
                    <Form>
                        <div className={`container-input endereco cep`}>
                            <Mask
                                mask="99999-999"
                                placeholder="CEP"
                                name="cep"
                                type="tel"
                                onChange={(e) => {
                                    setFieldValue("cep", e.target.value);
                                }}
                                value={cep}
                            />
                            <Button
                                className="btn-lupa"
                                type="button"
                                isLoading={loadingCep}
                                spiner="BallTriangle"
                                disabled={!isValidBRZipRegex.test(values.cep)}
                                onClick={() => findCep(values.cep)}
                            >
                                <img src={imgLupa} alt="" />
                            </Button>
                        </div>
                        <ErrorMessage name="cep" component="span" />
                        <div className={`container-input endereco`}>
                            <Field name="estado" placeholder="ESTADO" />
                        </div>
                        <ErrorMessage name="estado" component="span" />
                        <div className={`container-input endereco `}>
                            <Field name="cidade" placeholder="CIDADE" />
                        </div>
                        <ErrorMessage name="cidade" component="span" />
                        <div className={`container-input casa`}>
                            <Field name="nome" placeholder="LOGRADOURO" />
                        </div>
                        <ErrorMessage name="logradouro" component="span" />
                        <div className={`container-input casa`}>
                            <Field name="bairro" placeholder="BAIRRO" />
                        </div>
                        <ErrorMessage name="bairro" component="span" />
                        <div
                            className={`container-input casa ${
                                disabledNumero ? "disabled active" : ""
                            }`}
                        >
                            <Field
                                name="numero"
                                type="tel"
                                maxLength={5}
                                placeholder="NÚMERO"
                            />
                        </div>
                        <ErrorMessage name="numero" component="span" />
                        <Box pl="23px" mb="12px">
                            <CheckBox
                                label="Sem número"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setFieldValue("numero", "---");
                                        setDisabledNumero(true);
                                    } else {
                                        setFieldValue("numero", "");
                                        setDisabledNumero(false);
                                    }
                                }}
                            />
                        </Box>

                        <div className={`container-input casa`}>
                            <Field
                                name="complemento"
                                type="text"
                                placeholder="COMPLEMENTO"
                            />
                        </div>
                        <ErrorMessage name="complemento" component="span" />
                        <div className="mt-auto">
                            <Button
                                type="submit"
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                SALVAR
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
