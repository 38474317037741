import styled from "styled-components";
import { Button as ButtonSystem, ButtonProps } from "alisson-application";

interface ContainerProps extends ButtonProps {
    transparent?: boolean;
}
export const Button = styled(ButtonSystem)<ContainerProps>`
    border-radius: 600px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    width: 100%;
    height: 3.5rem;
    /* border: 1px solid
        ${(props) => (props.transparent ? "#E1E2E3" : "transparent")}; */
    border: none;
    color: ${(props) =>
        props.transparent
            ? props.theme.colors.secondary
            : props.theme.colors.white};

    background-color: ${(props) =>
        props.transparent ? "transparent" : props.theme.colors.secondarydark};
    transition: all 1s ease;

    img {
        pointer-events: none;
    }

    opacity: 1;

    &:hover {
    }

    &:disabled {
        outline: none;
        transition: all 0.5s ease;
        color: ${(props) => props.theme.colors.primary} !important;
        &:before {
            border-radius: 600px;
        }
    }
`;
