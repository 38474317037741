import { Container } from "./styles";
// import imgEditar from "../../../../../assets/img/editarBackOffice.svg";
import imgSalvar from "../../../../../assets/img/salvarBackOffice.svg";

interface HeaderProps {
    icon: "document" | "pessoa" | "card" | "addresses" | "email" | "phone";
    title: string;
    onSave?: () => Promise<void>;
    showBtns?: boolean;
}

export function Header(props: HeaderProps) {
    const { icon, title, onSave, showBtns = true } = props;

    return (
        <Container>
            <div className={`container-text ${icon}`}>
                <h3>{title}</h3>

                {showBtns ? (
                    <div className="container-btns">
                        <button
                            onClick={() => {
                                if (onSave) onSave();
                            }}
                        >
                            <img src={imgSalvar} alt="salvar" />
                            <p>Salvar</p>
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </Container>
    );
}
