import { AxiosResponse } from "axios";
import { createContext, useContext } from "react";
import { toast } from "react-toastify";

import { api } from "../../services";
import { PaginationConfig } from "./config";
import { Cliente, ClienteArea } from "./useCampanha";
import { Endereco } from "./useUser";

interface useComunicacaoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseComunicao {
    getComunicacao(page?: number): Promise<DataComunicacao>;
    getDetalhe(id: number, page?: number): Promise<DetalheComunicacao>;
    handleDelete(id: number): Promise<void>;

    getFaleConosco(page?: number): Promise<DataFaleConosco>;
    updateFaleConosco(id: number, data: any): Promise<any>;
}

export interface Comunicacao {
    id: number;
    titulo: string;
    tipo: string;
    disparo: string;
    status: string;
    arte_desktop: null | string;
    arte_mobile: null | string;
    conteudo: string;
    template_id: null | string;
    data_disparo: string;
    data_fim: string;
    created_at: string;
    updated_at: string;
    recipientes_count: number;
    total_enviados: number;
    total_errors: number;
    total_recipientes: number;
    total_visualizados: number;
}

interface DataComunicacao extends PaginationConfig {
    data: Comunicacao[];
}

export interface DetalheComunicacao {
    id: number;
    titulo: string;
    tipo: string;
    disparo: string;
    status: string;
    arte_desktop: string;
    arte_mobile: string;
    conteudo: string;
    template_id: number;
    data_disparo: string;
    data_fim: null | string;
    fields: null | string;
    created_at: string;
    updated_at: string;
    recipientes: DataDetalheComunicacao;
    total_visualizados: number;
    total_recipientes: number;
    total_enviados: number;
}

export interface DataDetalheComunicacao extends PaginationConfig {
    data: DetalheComunicacaoData[];
}

export interface DetalheComunicacaoData {
    id: number;
    acao_id: number;
    user_id: number;
    status: number;
    sented: number;
    describe: string;
    service_object_id: string;
    service_response: string;
    data_disparo: string;
    data_fim: string;
    created_at: string;
    updated_at: string;
    name: string;
    email: string;
    document: string;
    phone_number: string;
}

export interface DataFaleConosco extends PaginationConfig {
    data: FaleConosco[];
}

export interface FaleConosco {
    id: number;
    campanha_id: number;
    user_id: number;
    description: string;
    status: string;
    created_at: string;
    updated_at: string;
    campanha_m: Campanha;
    campanha: string;
    user: User & {
        pessoa?: {
            email?: string;
        };
    };
    subject: string | null;
    email: string | null;
    name: string | null;
    phone: string | null;
    file: string | null;
}

export interface Campanha {
    cliente_id: number;
    cliente_area_contato_id: number;
    nome: string;
    chave: string;
    regulamento: string;
    status: string;
    max_carga_credito_per_user: null;
    custom_fields: string;
    emissao: number;
    start_date_time: string;
    end_date_time: string;
    created_at: string;
    updated_at: string;
    cliente: Cliente;
    cliente_area_contato: ClienteArea;
}

export interface User {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    register_status: number;
    first_login: number;
    image: null | string;
    created_at: string;
    updated_at: string;
    enderecos: Endereco[];
}

const UseComunicacaoContext = createContext<IuseComunicao>({} as IuseComunicao);

export function ComunicacaoProvider(props: useComunicacaoProviderProps) {
    const { children } = props;

    async function getComunicacao(page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const response = await api.get<DataComunicacao>(`acao${hasPage}`);

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar itens");
            return {} as DataComunicacao;
        }
    }

    async function getDetalhe(id: number, page?: number) {
        const hasPage = page ? `?page=${page}` : "";
        try {
            const response = await api.get<DetalheComunicacao>(
                `/acao/${id}${hasPage}`
            );

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar itens");
            return {} as DetalheComunicacao;
        }
    }

    async function getFaleConosco(page?: number) {
        const hasPage = page ? `?page=${page}` : "";

        try {
            const response = await api.get<DataFaleConosco>(
                `helpdesk${hasPage}`
            );

            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar itens");
            return {} as DataFaleConosco;
        }
    }

    async function updateFaleConosco(id: number, data: any) {
        try {
            const response = await api.put<AxiosResponse>(
                `helpdesk/${id}`,
                data
            );
            toast.success("Atualizado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar itens");
            return error;
        }
    }

    async function handleDelete(id: number) {
        try {
            await api.delete(`acao/${id}`);
            toast.success("Deletado com sucesso");
        } catch (error: any) {
            toast.error("Erro ao deletar");
        }
    }

    return (
        <UseComunicacaoContext.Provider
            value={{
                getComunicacao,
                handleDelete,
                updateFaleConosco,
                getDetalhe,
                getFaleConosco,
            }}
        >
            {children}
        </UseComunicacaoContext.Provider>
    );
}

export function useComunicacao() {
    const context = useContext(UseComunicacaoContext);
    return context;
}
