import { HeaderNovo } from "../../Header/novo";
import { FormTermoPolitica } from "./form";
import { Container } from "./styles";
export function TermoECondicoesDeUsoNovo() {
    return (
        <Container>
            <HeaderNovo title="adicionar ARQUIVO" />
            <FormTermoPolitica />
        </Container>
    );
}
