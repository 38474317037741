import { Text } from "alisson-application";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    formatDateIsoSystem,
    getLocalStore,
} from "../../../../../../util/helper";

import { process, useCartaoDeCredito } from "../Hook";

import { Button } from "../../../../Button/status";
import {
    default as ModalAprove,
    default as ModalDisAprove,
} from "../../../../ModalAlert";

import imgDownload from "../../../../../../assets/img/dowload.svg";
import imgVisualizar from "../../../../../../assets/img/visualizar.svg";

import { HistoryProps } from "../../../types";

import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import { Permissions } from "../../../../Permission";
import { ReeviewStyles } from "./styles";

export function Reeview() {
    const { inProcess, aprove, disApproval } = useCartaoDeCredito();
    const { campanhaById } = useCampanha();
    const [processes, setProcesses] = useState<process[]>([]);
    const history = useHistory<HistoryProps>();
    const permission = getLocalStore().permissions["carga.aprovar_valor"];
    const [modalAprove, setModalAprove] = useState(false);
    const [modalDisAprove, setModalDisAprove] = useState(false);
    const [id, setId] = useState<number | undefined>(undefined);

    const fetchData = useCallback(async () => {
        const reponse = await inProcess();
        setProcesses(reponse);
    }, [inProcess]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    async function handleAprove() {
        if (id) {
            const response = await aprove(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }

    async function handleDisAprove() {
        if (id) {
            const response = await disApproval(id);
            if (response.status === 200) {
                fetchData();
            }
        }
    }

    return (
        <>
            {processes?.map((processe) => {
                return (
                    <ReeviewStyles key={processe.id}>
                        <ul>
                            <li title={processe.nome}>
                                <Text as="p">{processe.nome}</Text>
                            </li>
                            {processe.status === "PROCESSANDO" ||
                            processe.status === "AGUARDANDO" ? (
                                <li>
                                    <Text as="p" className="processando">
                                        {processe.status}
                                    </Text>
                                </li>
                            ) : null}
                            <li>
                                <Text as="p">
                                    {formatDateIsoSystem(processe.created_at)}
                                </Text>
                            </li>
                            <li>
                                <Text as="p">{processe.user.name}</Text>
                            </li>
                            <li>
                                <Text as="p">{processe.total_logs}</Text>
                            </li>
                        </ul>

                        <div
                            className={`acoes ${
                                permission ? "" : "not-permission"
                            }`}
                        >
                            <button
                                className="background"
                                onClick={() =>
                                    history.push({
                                        pathname: `/sistema/campanha/carga/${processe.id}/log`,
                                        state: {
                                            id: processe.id,
                                            type: "nonames_log",
                                            carga: "cartoes",
                                            campanha: campanhaById.nome,
                                        },
                                    })
                                }
                            >
                                <img src={imgVisualizar} alt="" />
                            </button>

                            <button
                                className="background"
                                onClick={() => alert("Em desenvolvimento")}
                            >
                                <img src={imgDownload} alt="" />
                            </button>

                            <Permissions role="carga.aprovar_valor">
                                <Button
                                    color="#D50632"
                                    background="#FFE3E7"
                                    onClick={() => {
                                        setId(processe.id);
                                        setModalDisAprove(true);
                                    }}
                                >
                                    REPROVAR
                                </Button>

                                <Button
                                    color="#fff"
                                    background="#5DB356"
                                    onClick={() => {
                                        setId(processe.id);
                                        setModalAprove(true);
                                    }}
                                >
                                    APROVAR
                                </Button>
                            </Permissions>
                        </div>
                    </ReeviewStyles>
                );
            })}

            <ModalAprove
                handleClose={() => setModalAprove(false)}
                reject={() => setModalAprove(false)}
                aceept={() => {
                    handleAprove();
                    setModalAprove(false);
                }}
                title="Aprovar"
                description="Deseja aprovar a carga noname?"
                open={modalAprove}
            />

            <ModalDisAprove
                handleClose={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                reject={() => {
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                aceept={() => {
                    handleDisAprove();
                    setModalDisAprove(false);
                    setId(undefined);
                }}
                title="Reprovar"
                description="Deseja reprovar a carga noname?"
                open={modalDisAprove}
            />
        </>
    );
}
