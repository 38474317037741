import { Text, Box } from "alisson-application";
import { ModalStyles } from "./styles";
import imgXVerde from "../../../../assets/img/x.svg";
import imgCadastroCliente from "../../../../assets/img/sucess-cadastro-cliente.svg";
import { Button } from "../../Button";
import { useContex } from "./Hook";

export function ModalAvisoExclusao() {
    const { setModalAviso, cliemtesEnUtilizacao } = useContex();

    return (
        <ModalStyles>
            <div className="container-header">
                <Text as="p">ATENÇÃO</Text>
                <button onClick={() => setModalAviso(false)}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>

            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />
                <Text as="h3">Está área está sendo utilizada</Text>

                {cliemtesEnUtilizacao.map((item) => (
                    <Text key={item.id} as="p">
                        "{item.area.label}"
                    </Text>
                ))}

                <Box width="234px">
                    <Button
                        onClick={() => {
                            setModalAviso(false);
                        }}
                    >
                        Entendi
                    </Button>
                </Box>
            </div>
        </ModalStyles>
    );
}
