interface ConfigUpload {
    id: string;
    accept: string;
}
interface ButtonUploadProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
    config: ConfigUpload;
    onChange: (value: FileList | null) => void;
}

//image/*

export function ButtonUpload(props: ButtonUploadProps) {
    return (
        <>
            <input
                type="file"
                onChange={(e) => {
                    if (e.target.files?.length) {
                        props.onChange(e.target.files);
                    }
                }}
                id={props.config.id}
                accept={props.config.accept}
            />
            <label htmlFor={props.config.id}>{props.children}</label>
        </>
    );
}
