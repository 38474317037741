import styled from "styled-components";
import { Container as DefaultHeader } from "../../Header/styles";
import imgLupa from "../../../../assets/img/lupa.svg";

export const Container = styled.div`
    display: flex;
    border-bottom: 1px solid #e1e2e3;
    background-color: ${(props) => props.theme.colors.secondary};
    position: relative;
    left: -80px;
    width: calc(100vw - 122px);
    padding-left: 78px;
    padding-right: 86px;
    ${DefaultHeader} {
        border-bottom: unset;
        .container-title {
            p {
                color: white;
                &:before {
                    background-color: white;
                }
            }
        }
    }
    .busca {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            width: 120px;
            margin: 0px;
            margin-left: 11px;
            background-color: #f17e21;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            input {
                padding-left: 45px;
                border-radius: 8px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }
    }
`;
