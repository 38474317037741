import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { api } from "../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { formatDateIsoSystem } from "../../util/helper";

interface UseUploadFilesProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseUploadFileData {
    getUsers: () => Promise<any>;
    storeFiles(values: FormData): Promise<any>;
    load: () => Promise<void>;

    modalExcluir: boolean;
    setModalExcluir: (value: boolean) => void;
    id: string | null;
    setId: (value: string | null) => void;

    deleteFile(): Promise<void>;

    stateRecebidos: [];
    stateEnviados: [];
    loading: boolean;
}

//usuario?role[]=admin&role[]=gerente&role[]=produto&role[]=fale_conosco&role[]=file_uploader
const UseUploadFileContext = createContext<UseUploadFileData>(
    {} as UseUploadFileData
);

export function UploadFilesProvider(props: UseUploadFilesProps) {
    const { children } = props;
    const history = useHistory();
    const [modalExcluir, setModalExcluir] = useState(false);
    const [id, setId] = useState<string | null>(null);
    const [stateRecebidos, setStateRecebidos] = useState<any>([]);
    const [stateEnviados, setStateEnviados] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    async function getUsers() {
        try {
            const response = await api.get<any>(
                "/usuario?per_page=999&role[]=gerente&role[]=produto&role[]=file_uploader&role[]=commercial"
            );

            const users = response.data.data.map((user: any) => {
                return {
                    id: user.id,
                    name: user.name,
                    check: false,
                };
            });

            return users;
        } catch (error: any) {
            console.log(error);
        }
    }

    async function storeFiles(values: FormData) {
        try {
            await api.post("/file", values);
            history.push("/sistema/upload");
            toast.success("Arquivos enviados com sucesso!");
        } catch (error: any) {
            console.log(error);
        }
    }

    async function recebidos() {
        try {
            const response = await api.get<any>("/file?received=1");
            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    }

    async function enviados() {
        try {
            const response = await api.get<any>("/file?sented=1");
            return response.data;
        } catch (error: any) {
            console.log(error);
        }
    }

    async function deleteFile() {
        if (!id) {
            toast.error("Erro ao excluir arquivo!");
            return;
        }

        try {
            await api.delete(`/file/${id}`);
            await load();
            setModalExcluir(false);
            toast.success("Arquivo excluido com sucesso!");
        } catch (error: any) {
            console.log(error);
        }
    }

    const load = useCallback(async () => {
        const promises = [recebidos, enviados];
        setLoading(true);
        const [dataRecebidos, dataEnviados] = await Promise.all(
            promises.map((p) => p().then((data) => data.data))
        );

        const updateRecebido = dataRecebidos.map((item: any) => {
            return {
                id: item.id,
                descricao: item.nome,
                name_file: item.original_name,
                size: item.tamanho,
                tipo: item.tipo,
                remetente: item.remetente.name,
                dt_hr: formatDateIsoSystem(item.created_at),
                path: item.path,
                destinatario: item.destinatarios
                    .map((item: any) => item.user.name)
                    .join(", "),
                acoes: "",
            };
        });

        const updateEnviado = dataEnviados.map((item: any) => {
            return {
                id: item.id,
                descricao: item.nome,
                name_file: item.original_name,
                size: item.tamanho,
                tipo: item.tipo,
                remetente: item.remetente.name,
                destinatario: item.destinatarios.map((item: any) => {
                    return `${item.user.name}${
                        item.last_download_at ? "✔️" : ""
                    }`;
                }),
                dt_hr: formatDateIsoSystem(item.created_at),
                path: item.path,
                acoes: "",
            };
        });

        setStateRecebidos(updateRecebido || []);
        setStateEnviados(updateEnviado || []);
        setLoading(false);
    }, []);

    useEffect(() => {
        load();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <UseUploadFileContext.Provider
            value={{
                getUsers,
                storeFiles,
                load,
                modalExcluir,
                setModalExcluir,
                id,
                setId,
                deleteFile,

                stateRecebidos,
                stateEnviados,
                loading,
            }}
        >
            {children}
        </UseUploadFileContext.Provider>
    );
}

export function useUpload() {
    const context = useContext(UseUploadFileContext);

    return context;
}
