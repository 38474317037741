import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    useComunicacao,
    DataDetalheComunicacao,
} from "../../../../Hook/system/useComunicacao";
import { Esq } from "./esq";
import { Dir } from "./dir";
import { Container } from "./styles";

export interface DetalheComunicacao {
    id: number;
    titulo: string;
    tipo: string;
    disparo: string;
    status: string;
    arte_desktop: string;
    arte_mobile: string;
    conteudo: string;
    template_id: number;
    data_disparo: string;
    data_fim: null | string;
    fields: null | string;
    created_at: string;
    updated_at: string;
    total_visualizados: number;
    total_recipientes: number;
    total_enviados: number;
}

export function Detalhe() {
    const [detalheComunicacao, setDetalheComunicacao] =
        useState<DetalheComunicacao>({} as DetalheComunicacao);
    const [recibiente, setRecibiente] = useState<DataDetalheComunicacao>(
        {} as DataDetalheComunicacao
    );

    const [loading, setLoading] = useState(false);
    const { getDetalhe } = useComunicacao();
    const history = useHistory<{ id: number }>();
    const id = history.location?.state?.id;

    async function pagination(page: number) {
        const response = await getDetalhe(id, page);
        const { recipientes, ...rest } = response;

        setDetalheComunicacao(rest);
        setRecibiente(recipientes);
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response = await getDetalhe(id);
            const { recipientes, ...rest } = response;

            setDetalheComunicacao(rest);
            setRecibiente(recipientes);
            setLoading(false);
        }

        if (id) {
            fetchData();
        }
    }, [getDetalhe, id]);

    return (
        <Container>
            <Esq {...detalheComunicacao} count={recibiente?.data?.length} />
            <Dir
                {...recibiente}
                pagination={(page) => pagination(page)}
                loading={loading}
            />
        </Container>
    );
}
