import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 312px);
    grid-gap: 30px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 312px);
    }

    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        .container-thumbnail {
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
`;

export const CardStyles = styled.button`
    background-color: #f4f3eb;
    border-radius: 10px;
    padding: 20px;
    padding-bottom: 26px;
    min-height: 254px;
    .container-thumbnail {
        margin-bottom: 8px;
        img {
            border-radius: 8px;
        }
    }

    .container-text {
        p {
            font-weight: 600;
            font-size: 14px;
            text-align: left;
        }
    }
`;
