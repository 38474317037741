import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { DesbloqueioCartao } from "../../Mobile/DesbloquearCartao";

export function AcaoDesbloquearCartaoMobilePasso1(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <DesbloqueioCartao />
        </ModalBottom>
    );
}
