import { useEffect } from "react";
import { toast } from "react-toastify";
import { Flex } from "alisson-application";

import { Header } from "./components/header";
import { Empty } from "./components/empty";
import { Table } from "./components/table";
import { Container } from "./styles";

import { useUser } from "../../../../Hook/system/useUser";
import { useConfig } from "../../../../Hook/system/useConfig";

export function Usuarios() {
    const { users, searchUser, config } = useUser();
    const { search } = useConfig();

    useEffect(() => {
        if (config?.total === 0) {
            toast.warn("Nenhum resultado encontrado");
        }
    }, [config]);

    useEffect(() => {
        async function fetchData() {
            await searchUser(search);
        }

        if (search) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Header />

            {users.length === 0 && (
                <Flex alignItems="center" justifyContent="center" mt="75px">
                    <Empty
                        title={
                            config?.total === 0
                                ? "NENHUM RESULTADO ENCONTRADO"
                                : "PREENCHA O CAMPO DE BUSCA PARA ENCONTRAR UM USUÁRIO"
                        }
                    />
                </Flex>
            )}

            {users.length > 0 && <Table />}
        </Container>
    );
}
