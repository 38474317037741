import { SucessoStyles } from "./styles";
import imgSuccess from "../../../../assets/img/sucesso.svg";
import { Button } from "../../../Mobile/Button";

interface SucessoProps {
    children?: React.ReactNode;
    btnLabel?: string;
    handleClose: () => void;
}

export function Sucesso({children,btnLabel, handleClose}:SucessoProps) {
    return (
        <SucessoStyles>
            <img src={imgSuccess} alt="" />
            {children}
            <Button onClick={() => handleClose && handleClose()}>
                {btnLabel}
            </Button>
        </SucessoStyles>
    );
}

