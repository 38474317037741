import { Text } from "alisson-application";
import imgVersoCartao from "../../../assets/img/versocartao.png";
import imgCartaoAtivar from "../../../assets/img/modal-cartao-ativar.svg";
import { ModalStyles } from "./styles";

export function Modal() {
    return (
        <ModalStyles>
            <img className="icone-cartao-ativar" src={imgCartaoAtivar} alt="" />
            <Text as="p">
                Você pode encontrar o código no verso do cartão. É o número de 3
                dígitos sinalizado abaixo.
            </Text>
            <img className="cartao" src={imgVersoCartao} alt="" />
        </ModalStyles>
    );
}
