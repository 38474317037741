import { useState } from "react";
import { motion } from "framer-motion";
import { FormCliente } from "./form";
import { Sucess } from "./sucess";

export function ModalNovoCliente() {
    const [sucess, setSucess] = useState(false);

    return (
        <>
            {sucess ? (
                <motion.div
                    animate={{ scale: [0.1, 1] }}
                    transition={{ duration: 0.5 }}
                >
                    <Sucess setSucess={setSucess} />
                </motion.div>
            ) : (
                <FormCliente setSucess={setSucess} />
            )}
        </>
    );
}
