import { useEffect, useState } from "react";
import { Text, Box, Flex } from "alisson-application";
import { useHistory } from "react-router-dom";
import { useCliente, Clientes } from "../../../Hook/system/useCliente";
import { PaginationConfig } from "../../../Hook/system/config";
import TableUi, { Pagination } from "../Table";
import { Button } from "../Button/areas";
import { Header } from "../Header";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import imgEditar from "../../../assets/img/editar.svg";
import { Loader } from "../../Loader";
import { Permissions } from "../Permission";

export function Cliente() {
    const history = useHistory();
    const { getCliente } = useCliente();
    const [clientes, setClientes] = useState<Clientes[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [loading, setLoading] = useState(false);

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getCliente(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setClientes(response.data);
        } catch (error) {
            console.log(error);
            setClientes([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.id}</Text>;
            },
            width: 140,
        },
        {
            field: "nome",
            headerName: "Nome",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.nome}</Text>;
            },
            width: 340,
        },
        {
            field: "razao_social",
            headerName: "RAZÃO SOCIAL",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.razao_social}</Text>;
            },
            width: 340,
        },
        {
            field: "cnpj",
            headerName: "CNPJ",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.cnpj}</Text>;
            },
            width: 269,
        },
        {
            field: "area",
            headerName: "ÁREA",
            renderCell: ({ row }: GridRenderCellParams) => {
                const arrArea =
                    row.area.length > 2
                        ? row.area.split(",").splice(0, 3)
                        : row.area
                        ? row.area
                        : [];
                return (
                    <Flex>
                        {arrArea?.map((item: string) => (
                            <Box mr="12px">
                                <Button>{item}</Button>
                            </Box>
                        ))}

                        {arrArea.length > 2 ? (
                            <Button>{"+ " + arrArea.length}</Button>
                        ) : (
                            ""
                        )}
                    </Flex>
                );
            },
            width: 443,
        },
        {
            field: "editar",
            headerName: "EDITAR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Permissions role="cliente.update">
                        <button
                            onClick={() =>
                                history.push({
                                    pathname: `/sistema/cliente/editar/${row.id}`,
                                    state: {
                                        id: row.id,
                                    },
                                })
                            }
                        >
                            <img src={imgEditar} alt="" />
                        </button>
                    </Permissions>
                );
            },
            width: 100,
        },
    ];

    const rows = clientes?.map((cliente) => {
        return {
            id: cliente.id,
            nome: cliente.nome,
            razao_social: cliente.razao_social,
            cnpj: cliente.cnpj,
            area: cliente.areas.map((item) => item.nome).join(", "),
            editar: "",
        };
    });

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response = await getCliente();
            const { data, ...rest } = response;
            setConfig(rest);
            setClientes(response.data);

            setLoading(false);
        }

        fetchData();
    }, [getCliente]);

    return (
        <>
            <Header
                title="CLIENTES"
                titleBtn="ADICIONAR CLIENTE"
                permission="cliente.store"
                onClickBtn={() => history.push("/sistema/cliente/novo")}
            />

            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <TableUi
                    columns={columns}
                    rows={rows ? rows : []}
                    nameCSV="CLIENTE"
                />
            )}

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </>
    );
}
