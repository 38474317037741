import React, { createContext, useContext, useState } from "react";
import { usePassos } from "./UsePassos";

interface UseModalProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseStatesModal {
    state: Modais;
    setState: (state: Modais) => void;
    handleClose(close: keyOf<Modais>): void;
    closeAllModais(): void;
    handleOpen(close: keyOf<Modais>, dados?: any): void;
}

export interface Modais {
    dados?: any;
    crop: boolean;
    errorGeral: Content;
    successGeral: Content;
    menu: boolean;
    perfil: boolean;
    campanha: boolean;
    solicitarResgate: boolean;
    novoCartao: boolean;
    ativarCartao: boolean;
    cvv: boolean;
    acoesCartaoMobile: boolean;
    acaoDesbloquearCartaoMobilePasso1: boolean;
    acaoDesbloquearCartaoMobilePasso2: boolean;
    acaoBloquearCartao: boolean;
    atualizaSenha: boolean;
    notificacao: boolean;
    cupom: boolean;
    extrato: boolean;
    faleConosco: boolean;
    confirmaToggleSms: boolean;
    alterarSenha: boolean;
    alterarEndereco: boolean;
    visualizarDados: boolean;
    updateEndereco: boolean;
    cartaoAvulso: boolean;
    diferencaCartoes: boolean;
    esqueciSenha: boolean;
    limiteTarifas: boolean;
    segundaVia: boolean;
    transferir: boolean;
    transferirEntreCarteiras: boolean;
    perdaRoubo: boolean;
    entrega: boolean;
    vincularUsuarioCampanha: boolean;
    ativarCartaoAvulso: boolean;
    ativarCartaoAvulsoSubHeader: boolean;
    faleConoscoUsuarioDeslogado: boolean;
    retornoFaleConosco: boolean;
    boleto: boolean;
    alertaBoleto: boolean;
    disabledBoleto: boolean;
    saldoInsuficiente: boolean;
    horarioFuncionamento: boolean;
    verificacaoDocument: boolean;
    sucessoVerificacaoDocumento: boolean;
    errorDocumento: boolean;
    resetarSenha: boolean;
    alertaCreditoAdicionado: boolean;
    tutorialTransferir: boolean;
    comprovanteDePagamento: boolean;
    loginCheck: boolean;
    ativarCartaoAutomatico: boolean;
    limiteBoleto: boolean;
    novoSaldo: boolean;
    acessoBloqueado: boolean;
    boletoCpfInvalido: boolean;
    idWall: boolean;
    validandoDadosIdWall: boolean;
    acessoBloqueadoIdWall: boolean;
    verificacaoDocumentoIdWall: boolean;
    documentoEmAnaliseIdWall: boolean;
    cadastroEfetuado: boolean;
    tempoExpirado: boolean;
    boletoErroMsg: any;
    avisoSenhaInvalida: any;
}

export type ModaisKeys = keyof Modais;

interface Content {
    active: boolean;
    message: string;
    title: string;
}

type keyOf<T> = keyof T;
const UseModalContext = createContext<UseStatesModal>({} as UseStatesModal);

export function UseModalProvider(props: UseModalProviderProps) {
    const { children } = props;
    const { setPassos } = usePassos();

    const initialState = {
        crop: false,
        dados: {},
        errorGeral: {
            active: false,
            message: "",
            title: "",
        },
        successGeral: {
            active: false,
            title: "",
            message: "",
        },
        menu: false,
        perfil: false,
        campanha: false,
        solicitarResgate: false,
        novoCartao: false,
        ativarCartao: false,
        saldoInsuficiente: false,
        cvv: false,
        acoesCartaoMobile: false,
        acaoBloquearCartao: false,
        acaoDesbloquearCartaoMobilePasso1: false,
        acaoDesbloquearCartaoMobilePasso2: false,
        atualizaSenha: false,
        notificacao: false,
        faleConosco: false,
        extrato: false,
        cupom: false,
        confirmaToggleSms: false,
        alterarSenha: false,
        alterarEndereco: false,
        visualizarDados: false,
        updateEndereco: false,
        cartaoAvulso: false,
        diferencaCartoes: false,
        esqueciSenha: false,
        limiteTarifas: false,
        segundaVia: false,
        transferir: false,
        transferirEntreCarteiras: false,
        perdaRoubo: false,
        entrega: false,
        vincularUsuarioCampanha: false,
        ativarCartaoAvulso: false,
        ativarCartaoAvulsoSubHeader: false,
        faleConoscoUsuarioDeslogado: false,
        retornoFaleConosco: false,
        boleto: false,
        alertaBoleto: false,
        disabledBoleto: false,
        horarioFuncionamento: false,
        verificacaoDocument: false,
        sucessoVerificacaoDocumento: false,
        errorDocumento: false,
        tutorialTransferir: false,
        alertaCreditoAdicionado: false,
        comprovanteDePagamento: false,
        resetarSenha: false,
        loginCheck: false,
        ativarCartaoAutomatico: false,
        limiteBoleto: false,
        novoSaldo: false,
        acessoBloqueado: false,
        boletoCpfInvalido: false,
        boletoErroMsg: "",
        avisoSenhaInvalida: false,
        validandoDadosIdWall: false,
        acessoBloqueadoIdWall: false,
        documentoEmAnaliseIdWall: false,
        verificacaoDocumentoIdWall: false,
        tempoExpirado: false,
        cadastroEfetuado: false,
        idWall: false,
    };

    const [state, setState] = useState<Modais>(initialState);

    function handleClose(close: keyOf<Modais>) {
        setPassos([]);
        setState({
            ...state,
            dados: {},
            [close]: false,
        });
    }

    function handleOpen(open: keyOf<Modais>, dados?: any) {
        setState({
            ...state,
            dados,
            [open]: true,
        });
    }

    function closeAllModais() {
        setPassos([]);
        setState(initialState);
    }

    return (
        <UseModalContext.Provider
            value={{ state, setState, handleClose, closeAllModais, handleOpen }}
        >
            {children}
        </UseModalContext.Provider>
    );
}

export function UseModal() {
    const context = useContext(UseModalContext);
    return context;
}
