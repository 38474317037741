import styled from "styled-components";
import imgCliente from "../../../../assets/img/menu/cliente.svg";

export const Container = styled.div`
    height: 111px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e2e3;
    .container-header {
        margin-left: 155px;
        display: flex;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};

            img {
                margin-right: 12px;
            }
        }

        .title {
            margin-left: 436px;
            &.cliente {
                position: relative;
                padding-left: 36px;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 55%;
                    left: 0px;
                    transform: translateY(-50%);
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                    -webkit-mask: url(${imgCliente}) no-repeat;
                    width: 24px;
                    height: 24px;
                    z-index: 20;
                    transition: background-color 0.5s;
                }
            }
        }
    }
`;

export const FormStyles = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    .areas {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        /* identical to box height, or 12px */

        letter-spacing: 0.08em;
        text-transform: uppercase;

        /* Gray/01 */

        color: ${(props) => props.theme.colors.primary};
        margin-top: 36px;
        margin-bottom: 16px;
    }

    form {
        padding-top: 0px;
        .itens-areas {
            margin-top: 24px;
        }

        .btn-contato {
            background-color: ${(props) => props.theme.colors.backgroundAlt};
            color: ${(props) => props.theme.colors.secondary};
            font-size: ${(props) => props.theme.fonts.xss};
            font-weight: 600;
        }
    }
`;

export const ContatoStyles = styled.div`
    width: 100%;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    margin-top: 17px;

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        &.tel {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: -50px;
                transform: translateY(-50%);
                background-color: #e1e2e3;
                width: 1px;
                height: 12px;
            }
        }
    }

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primaryDark};
    }

    button {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-right: 5px;
        }
    }

    .pessoal {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e2e3;
    }

    .contato {
        padding-top: 20px;
    }
`;

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 105px;
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.sm};
            line-height: 140%;
            text-align: left;
            color: ${(props) => props.theme.colors.primaryDark};
            width: 100%;
            display: block;
            margin-bottom: 18px;
        }
    }
`;
