import { ModalConfig } from "../config";
import { Video } from "../../Mobile/LoginDesktop/video";
import Modal from "../../NovoModal/video";

export function ModalTransferirCredito(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <Modal open={open} handleClose={handleClose}>
            <Video link="https://vimeo.com/867316368/172831be02" />
        </Modal>
    );
}
