import { Text } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import { formatCurrentMoney } from "../../../util/helper";
import { Card } from "./card";
import { DobleText } from "../DobleText";
import { Button } from "../ButtonSlim";

import { usePassos } from "../../../Hook/UsePassos";
import { useTheme } from "../../../contexts/ThemeContext";
import { LimitiCards } from "./limitiCards";

import { ConfirmaStyles } from "./styles";

interface ConfirmaProps {
    hasFirstResgate: boolean;
    onClick: () => void;
    loading: boolean;
}

export function Confirmar(props: ConfirmaProps) {
    const { hasFirstResgate, onClick, loading } = props;
    const { nextPasso, dados } = usePassos();
    const { currentTheme } = useTheme();
    const { t } = useTranslation();

    const cardNumber = dados?.currentCard?.acg_account_card_last4digits;

    const config = {
        price: dados.saldo
            ? formatCurrentMoney(parseFloat(dados.saldo))
            : formatCurrentMoney(0),
        number:
            dados?.currentCard?.tipo === "cartao_fisico" ||
            dados?.currentCard?.tipo === "cartao_avulso"
                ? dados?.currentCard?.acg_account_card_last4digits
                : cardNumber,
        type:
            dados?.currentCard?.tipo === "cartao_fisico" ||
            dados?.currentCard?.tipo === "cartao_avulso"
                ? t("FÍSICO")
                : t("VIRTUAL"),
        color:
            dados?.currentCard?.tipo === "cartao_fisico" ||
            dados?.currentCard?.tipo === "cartao_avulso"
                ? currentTheme.colors.tertiarybright
                : currentTheme.colors.tertiarydark,
    };

    return (
        <ConfirmaStyles className="container">
            <Text textTransform="uppercase" as="h3">
                {t("Confira os dados do seu resgate")}
            </Text>

            <div className="container-card-confirma">
                <Card {...config} />
                <div className="container-detlhes">
                    <Text as="h4" color="primaryDark" mb="17px" fontSize="xss">
                        {dados?.currentCard?.tipo === "cartao_fisico" ||
                        dados?.currentCard?.tipo === "cartao_avulso"
                            ? dados?.currentCard?.acg_account_card_last4digits
                            : cardNumber}
                    </Text>
                </div>
            </div>

            <div className="container-lista">
                <div className="item">
                    <DobleText
                        title={t("SALDO DA CARTEIRA")}
                        text={`R$ ${
                            dados.saldo
                                ? formatCurrentMoney(parseFloat(dados.saldo))
                                : 0
                        }`}
                    />
                </div>
                <div className="item">
                    <DobleText
                        title={t("VALOR DO RESGATE")}
                        text={
                            dados.valor
                                ? `R$ ${formatCurrentMoney(dados.valor)}`
                                : "R$ 0,00"
                        }
                    />
                </div>
                <div className="item">
                    <DobleText
                        title={t("NOVO SALDO YETZPAY")}
                        text={
                            dados.subTotal
                                ? `R$ ${formatCurrentMoney(dados.subTotal)}`
                                : "R$ 0,00"
                        }
                    />
                </div>
            </div>

            <LimitiCards type="cartToCard"/>

            <Button
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
                onClick={() => {
                    if (!hasFirstResgate) {
                        onClick();
                    } else {
                        nextPasso(3);
                    }
                }}
            >
                Finalizar Resgate
            </Button>
        </ConfirmaStyles>
    );
}
