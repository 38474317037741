import { memo } from "react";

import { EstornoProvider } from "../Hook";

import { TableCargaMemorized } from "./table";
import { UploadMemo } from "./upload";
import { Reeview } from "./reeview";
import { Container } from "./styles";

function Estorno() {
    return (
        <Container>
            <EstornoProvider>
                <UploadMemo />
                <Reeview />
                <TableCargaMemorized />
            </EstornoProvider>
        </Container>
    );
}

export const EstornoMemorized = memo(Estorno);
