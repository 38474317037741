import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PagePagamentoBoleto } from "../../../pages/PagamentoBoleto";

export function ModalBoleto(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PagePagamentoBoleto />
        </Modal>
    );
}
