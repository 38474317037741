import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "alisson-application";
import { Extrato } from "../../../../Hook/user/useExtrato";
import { formatDate } from "../../../../util/helper";
import { Relatorio } from "../../../Mobile/Relatorio";
import { useUser } from "../../../../Hook/system/useUser";
import { FiltroEntradaSaida } from "../../../Mobile/Movimentacao/FiltroEntradaSaida";
import { FiltroDate } from "../../../Mobile/Movimentacao/FiltroDate";
import { Empty } from "../../../Mobile/Empty";
import { Meses } from "./mes";
import { StylesExtrato } from "./styles";
import { Loader } from "../../../Loader";

export function ExtratoCarteira() {
    const history = useHistory<{ id: number }>();
    const {
        extratosCarteira,
        getExtratoCarteira,
        user,
        loadingExtratoCarteira,
    } = useUser();

    const [dados, setDados] = useState<Extrato[][]>([]);
    const [filtro, setFiltro] = useState<Extrato[][]>([]);

    const [entrada, setEntrada] = useState(false);
    const [saida, setSaida] = useState(false);

    const [antigo, setAntigo] = useState(false);
    const [recent, setRecent] = useState(false);

    async function handleClick(date: Date) {
        const id = history.location?.state?.id;

        try {
            if (id) {
                await getExtratoCarteira(id, date);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const update = [...dados];
        if (entrada) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.tipo === "credito");
                })
            );
            return;
        }

        if (saida) {
            setFiltro(
                update.map((item) => {
                    return item.filter((item) => item.tipo === "debito");
                })
            );
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entrada, saida]);

    useEffect(() => {
        const update = [...dados];
        if (antigo) {
            setFiltro(update.reverse());
            return;
        }

        if (recent) {
            setFiltro(update);
            return;
        }

        setFiltro([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [antigo, recent]);

    useEffect(() => {
        const arrDate = [
            ...new Set(
                extratosCarteira.map((item) => {
                    return formatDate(item.created_at);
                })
            ),
        ];

        const arrGroupedDate = arrDate.map((item) => {
            return extratosCarteira.filter((extrato) => {
                return formatDate(extrato.created_at) === item;
            });
        });

        setDados(arrGroupedDate);
    }, [extratosCarteira]);

    return (
        <StylesExtrato>
            {/*/sistema*/}
            <h5>EXTRATO DA CARTEIRA</h5>
            <div className="slide-mes">
                <Meses
                    handleClick={handleClick}
                    created_at={user?.created_at ? user?.created_at : ""}
                />
            </div>
            {loadingExtratoCarteira ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : dados?.length !== 0 ? (
                <div className="extrato-usuario">
                    <Flex
                        width="90%"
                        m="0 auto 32px"
                        justifyContent="space-between"
                    >
                        <FiltroEntradaSaida
                            handleEntrada={setEntrada}
                            handleSaida={setSaida}
                        />
                        <FiltroDate
                            handleAntigo={setAntigo}
                            handleRecent={setRecent}
                        />
                    </Flex>
                    {dados.length !== 0
                        ? filtro.length
                            ? filtro.map((item: Extrato[]) => {
                                  if (item.length === 0) return <></>;

                                  return (
                                      <>
                                          <Relatorio dados={item} />
                                      </>
                                  );
                              })
                            : dados.map((item: Extrato[]) => {
                                  if (item.length === 0) return <></>;

                                  return (
                                      <>
                                          <Relatorio dados={item} />
                                      </>
                                  );
                              })
                        : null}
                </div>
            ) : (
                <Empty />
            )}
        </StylesExtrato>
    );
}
