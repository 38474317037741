import { GridColumns } from "@mui/x-data-grid-pro";
import DetalheTable from "../../../../Table";
import { CreditosEstorno } from "../../CartaoDeCredito/Hook";
import { TableStyles } from "./styles";
import { Data } from "./config";
import {
    formatDateIsoSystem,
    formataMoney,
} from "../../../../../../util/helper";

interface Dados {
    data: CreditosEstorno[];
    updateTable: () => Promise<void>;
}

const initialColumn: GridColumns = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },
    {
        field: "cpf",
        headerName: "CPF",
        width: 172,
    },
    {
        field: "nome",
        headerName: "NOME",
        width: 172,
    },
    {
        field: "valor",
        headerName: "valor",
        width: 172,
    },
    {
        field: "creditos_estorno",
        headerName: "Carga Crédito",
        width: 172,
    },
];

export function Table(props: Dados) {
    const { data } = props;

    const rows: Data[] = data?.map((carga) => {
        return {
            id: carga?.id || 0,
            cpf: carga?.user?.document ? carga?.user?.document : "",
            nome: carga?.user?.name ? carga?.user?.name : "",
            creditos_estorno: formatDateIsoSystem(
                carga?.carga_credito?.created_at || ""
            ),
            valor: formataMoney(parseFloat(carga?.operation.valor) + ""),
        };
    });

    return (
        <TableStyles>
            <DetalheTable
                rows={rows ? rows : []}
                columns={initialColumn}
                nameCSV="CARTAO-DE-CREDITO"
            />
        </TableStyles>
    );
}
