import { Text, Box } from "alisson-application";
import { ModalStyles } from "./styles";
import { Button } from "../../../../Button";
import { useContex } from "../Hook/modal";

import imgXVerde from "../../../../../../assets/img/x.svg";
import imgCadastroCliente from "../../../../../../assets/img/failure-cadastro-carga-infra.svg";

interface SucessProps {
    setSucess: (sucess: boolean | null) => void;
}

export function Failure(props: SucessProps) {
    const { setSucess } = props;
    const { setModalCredito } = useContex();

    function handleClose() {
        setModalCredito(false);
        setSucess(null);
    }

    return (
        <ModalStyles>
            <div className="container-header">
                <Text as="p">NOVA CARGA DE INFRA</Text>
                <button onClick={() => handleClose()}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>

            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />

                <Text as="h3">Algo deu Errado com sua carga de credito.</Text>

                <Text as="p">
                    Motivo: você está tentando subir um arquivo com CPFs
                    inválidos. Verifique as linhas: 1, 4, 666, 78.
                </Text>

                <Box width="234px">
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        FINALIZAR
                    </Button>
                </Box>
            </div>
        </ModalStyles>
    );
}
