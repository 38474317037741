import img01 from "../../../assets/img/slide/img-01.png";
import img02 from "../../../assets/img/slide/img-02.png";
import img03 from "../../../assets/img/slide/img-03.png";
import img04 from "../../../assets/img/slide/img-04.png";
import img05 from "../../../assets/img/slide/img-05.png";
import img06 from "../../../assets/img/slide/img-06.png";
import img07 from "../../../assets/img/slide/img-07.png";
import img08 from "../../../assets/img/slide/img-08.png";
import img09 from "../../../assets/img/slide/img-09.png";
import img10 from "../../../assets/img/slide/img-10.png";
import img11 from "../../../assets/img/slide/img-11.png";
import img12 from "../../../assets/img/slide/img-12.png";
import img13 from "../../../assets/img/slide/img-13.png";
import img14 from "../../../assets/img/slide/img-14.png";
import img15 from "../../../assets/img/slide/img-15.png";
import img16 from "../../../assets/img/slide/img-16.png";
import { SwiperSlideWrap } from "./styles";

import SwiperCore, { Navigation, A11y, Autoplay, EffectFade } from "swiper";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, A11y, Autoplay, EffectFade]);

export function Slide() {
    return (
        <SwiperSlideWrap
            spaceBetween={2}
            slidesPerView={1}
            className="swiper-slide-login"
            effect="fade"
            fadeEffect={{
                crossFade: true,
            }}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            loop={true}
        >
            <SwiperSlide>
                <img src={img01} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img02} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img03} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img04} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img05} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img06} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img07} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img08} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img09} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img10} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img11} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img12} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img13} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img14} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img15} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={img16} alt="" />
            </SwiperSlide>
        </SwiperSlideWrap>
    );
}
