import { useRef } from "react";
import Box from "@mui/material/Box";
import imgSetaCima from "../../../assets/img/seta-cima-sistema.svg";
import { useSegmentacao } from "../../../Hook/system/useSegmentacao";
import { Button } from "../Button";
import { Item } from "./item";
import { StylesSegmento } from "./styles";

interface Segementacoes {}

export function Segmentacoes(props: Segementacoes) {
    const ref = useRef<HTMLDivElement[]>([]);
    const { setModal, segmentacao, setSegmentacao, setCampanhas } =
        useSegmentacao();

    function handleCheckCampanha(id: string) {
        const update = segmentacao.map((seg: any) => {
            const item = seg.map((item: any) => {
                if (item?.id === id) {
                    const checked = !item.checked;
                    item.checked = checked;
                    return item;
                }
                return item;
            });
            return item;
        });
        setSegmentacao(update);
    }

    function handleCheckAllCampanha(id: string, value: boolean) {
        const update = segmentacao.map((seg: any) => {
            const item = seg.map((item: any) => {
                if (item?.id === id) {
                    item.checked = value;
                    return item;
                }
                return item;
            });
            return item;
        });
        setSegmentacao(update);
    }

    // function handleCheckICampos(id: string) {
    //     const update = segmentacao.map((seg: any) => {
    //         return seg.map((item: any) => {
    //             item.fields = item.fields.map((field: any) => {
    //                 if (field.id === id) {
    //                     const checked = !field.checked;
    //                     field.checked = checked;
    //                     field.itens = field.itens.map((item: any) => {
    //                         item.checked = checked;
    //                         return item;
    //                     });
    //                 }

    //                 return field;
    //             });
    //             return item;
    //         });
    //     });
    //     setSegmentacao(update);
    // }

    function handleHeightChange(el: HTMLDivElement) {
        const targetElement = (el as HTMLElement).parentElement;
        const height = (el as HTMLElement).offsetHeight + 96 + 32;

        if (targetElement) {
            if (targetElement.classList.value.includes("active")) {
                targetElement.style.height = "96px";
                targetElement.classList.remove("active");
            } else {
                targetElement.classList.add("active");
                targetElement.style.height = height + "px";
            }
        }
    }

    function handleCampanhas() {
        var campanhas: any = [];
        segmentacao.forEach((seg: any) => {
            seg.forEach((item: any) => {
                if (item.checked) {
                    const id = parseInt(item.id);
                    campanhas.push(id);
                }
            });
        });

        setCampanhas(campanhas);
    }

    return (
        <StylesSegmento>
            {segmentacao?.map((seg: any, key: number) => {
                const checkedAll = seg?.every((item: any) => item.checked);

                return (
                    <div className="itens active" key={key}>
                        <div className="container-item">
                            <div className="container-cliente">
                                <Item
                                    id={seg[0]?.id ? seg[0]?.id : ""}
                                    checked={checkedAll}
                                    handleChange={(id) => {
                                        seg.forEach((item: any) => {
                                            handleCheckAllCampanha(
                                                item.id,
                                                !checkedAll
                                            );
                                        });
                                    }}
                                    label={
                                        seg[0]?.cliente ? seg[0]?.cliente : ""
                                    }
                                />
                                <div className="selecionar-tudo">
                                    <Item
                                        id={seg[0]?.id ? seg[0]?.id : ""}
                                        checked={checkedAll}
                                        handleChange={(id) => {
                                            seg.forEach((item: any) => {
                                                handleCheckAllCampanha(
                                                    item.id,
                                                    !checkedAll
                                                );
                                            });
                                        }}
                                        label="SELECIONAR TODAS"
                                    />

                                    <button
                                        onClick={() =>
                                            handleHeightChange(ref.current[key])
                                        }
                                    >
                                        <img src={imgSetaCima} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {seg.map((item: any) => {
                            return (
                                <div
                                    key={item.nome}
                                    className="container-campanha"
                                    ref={(el: HTMLDivElement) => {
                                        ref.current[key] = el;
                                    }}
                                >
                                    <Item
                                        id={item?.id ? item?.id : ""}
                                        checked={item?.checked}
                                        handleChange={(id) =>
                                            handleCheckCampanha(id)
                                        }
                                        label={item?.nome ? item?.nome : ""}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            })}

            <Box
                sx={{
                    width: "220px",
                    marginLeft: "auto",
                    marginTop: "66px",
                }}
            >
                <Button
                    onClick={() => {
                        setModal(false);
                        handleCampanhas();
                    }}
                >
                    FINALIZAR SELEÇÃO
                </Button>
            </Box>
        </StylesSegmento>
    );
}
