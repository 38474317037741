import { Card } from "./card";
import { useTutorial } from "../../../Hook/user/useTutorial";
import { Container } from "./styles";
import Modal from "../../NovoModal/video";
import { useState } from "react";
import { Video } from "../../Mobile/LoginDesktop/video";

export function CardsTutorial() {
    const { tutorial } = useTutorial();
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState("");

    return (
        <Container>
            {tutorial.map((item) => (
                <Card
                    {...item}
                    onClick={(link) => {
                        setLink(link);
                        setOpen(true);
                    }}
                />
            ))}
            {link && open ? (
                <Modal
                    handleClose={() => {
                        setLink("");
                        setOpen(false);
                    }}
                    open={open}
                >
                    <Video link={link} />
                </Modal>
            ) : (
                <></>
            )}
        </Container>
    );
}
