import { Perfil } from "../../components/Perfil";
import { UseStateProvider } from "../../Hook/user/usePerfil";

export function PagePerfil() {
    return (
        <UseStateProvider>
            <div className="container">
                <Perfil />
            </div>
        </UseStateProvider>
    );
}
