import { FalhaAoPagarBoleto } from "../../FalhaAoPagarBoleto";
import ModalBottom from "../../ModalBottom";
import { ModalConfig } from "../config";

export function ModalFalhaAoPagarBoleto(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <FalhaAoPagarBoleto />
        </ModalBottom>
    );
}
