import { PreviewSms } from "./styles";

export function SmsPreview({ img = null, text = "" }) {
    text = text.replace(/\\\n/g, "");
    text = text.replace(/\\n/g, " ");
    return (
        <PreviewSms>
            <div className="container-preview-sms">
                <div className="content-preview">
                    <p>{text}</p>
                </div>
            </div>
        </PreviewSms>
    );
}
