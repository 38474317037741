import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { ConfirmaDados } from "./confirmaDados";
import { Endereco } from "./endereco";
import { Conferencia } from "./conferencia";
import { Validadar } from "../Validar";
import { Finalizar } from "./finalizar";
import { CustoEmissaoCartao } from "./custoEmissao";
// import {
//     AlertaNotificacao,
//     AtivarNotificacao,
// } from "../SolicitarResgate/ativarNotificacao";
// import { CustoEmissaoCartao } from "./custoEmissao";

// export const arrFisico = [
//     {
//         passo: 1,
//         active: false,
//         completed: true,
//     },
//     {
//         passo: 2,
//         active: false,
//         completed: true,
//     },
//     {
//         passo: 3,
//         active: false,
//         completed: true,
//     },
//     {
//         passo: 4,
//         active: true,
//         completed: true,
//     },
//     {
//         passo: 5,
//         active: false,
//         completed: true,
//     },
//     {
//         passo: 6,
//         active: false,
//         completed: false,
//     },
//     {
//         passo: 7,
//         active: false,
//         completed: false,
//         goBack: true,
//     },
// ];

// removendo tela de notificação tarefa YP-316

export function CartaoFisico() {
    const { passos, nextPasso, dados } = usePassos();
    const { storeCartao } = UseCartoes();

    // const [alertaSms, setAlertaSms] = useState(false);
    // const [stateSms, setStateSms] = useState(false);

    async function handleClick() {
        const responseStore = await storeCartao(dados);
        if (responseStore.status !== 500) {
            if (dados.valor_cobranca === "0.00") {
                nextPasso(6);
            } else {
                nextPasso(7);
            }
        }
    }

    // function handleClickSms() {
    //     if (stateSms) {
    //         nextPasso(7);
    //     } else {
    //         setAlertaSms(true);
    //     }
    // }

    // useEffect(() => {
    //     setAlertaSms(false);
    //     setStateSms(false);
    // }, []);

    // useEffect(() => {
    //     if (dados.valor_cobranca === "0.00" && state.novoCartao) {
    //         setPassos(arrFisico);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dados.valor_cobranca, setPassos]);

    return (
        <>
            <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                {passos[1]?.active ? <ConfirmaDados tipo="fisico" /> : null}
            </div>

            <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                {passos[2]?.active ? <Endereco /> : null}
            </div>

            <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                {passos[3]?.active ? <Conferencia /> : null}
            </div>

            {dados.valor_cobranca === "0.00" ? (
                <>
                    <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                        {passos[4]?.active ? (
                            <Validadar handleClick={() => handleClick()} />
                        ) : null}
                    </div>

                    <div className={`tab ${passos[5]?.active ? "active" : ""}`}>
                        {passos[5]?.active ? <Finalizar /> : null}
                    </div>
                </>
            ) : (
                <>
                    <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                        <CustoEmissaoCartao
                            onClick={() => {
                                nextPasso(6);
                            }}
                        />
                    </div>

                    <div className={`tab ${passos[5]?.active ? "active" : ""}`}>
                        {passos[5]?.active ? (
                            <Validadar handleClick={() => handleClick()} />
                        ) : null}
                    </div>

                    <div className={`tab ${passos[6]?.active ? "active" : ""}`}>
                        {passos[6]?.active ? <Finalizar /> : null}
                    </div>
                </>
            )}
            {/* <div className={`tab ${passos[5]?.active ? "active" : ""}`}>
                {passos[5]?.active ? (
                    alertaSms ? (
                        <AlertaNotificacao
                            setStateSms={setStateSms}
                            onClick={() => {
                                nextPasso(7);
                            }}
                        />
                    ) : (
                        <AtivarNotificacao
                            setStateSms={setStateSms}
                            onClick={handleClickSms}
                        />
                    )
                ) : null}
            </div> */}

            {/* <div className={`tab ${passos[5]?.active ? "active" : ""}`}>
                {passos[5]?.active ? (
                    
                ) : null}
            </div> */}
        </>
    );
}
