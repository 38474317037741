import { ButtonHTMLAttributes } from "react";
import { Text } from "alisson-application";
import { Container } from "./styles";
import imgCartao from "../../../assets/img/cartao-desktop.svg";

interface NovoCartaoProps extends ButtonHTMLAttributes<HTMLElement> {
    empty: boolean;
}

export function NovoCartao(props: NovoCartaoProps) {
    const { empty, onClick } = props;

    return (
        <Container
            className={empty ? "empty" : ""}
            disabled={empty}
            onClick={onClick}
        >
            <img src={imgCartao} alt="" />
            <Text as="p" color="secondary">
                SOLICITAR CARTÃO
            </Text>
        </Container>
    );
}
