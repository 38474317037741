import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import imgFisico from "../../../../../../assets/img/min-card-fisico.svg";
import imgDigital from "../../../../../../assets/img/min-card-digital.svg";
import TableProduto from "../../../../Table";
import { IOSSwitch } from "../../../../Switch";
import { formataMoney } from "../../../../../../util/helper";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";
import { useProduto } from "../Hook";
import { Qtd } from "../qtd";
import { Taxa } from "../taxa";
import { Date } from "./config";
import { toast } from "react-toastify";

export function Table() {
    const width = 1207 / 7.5;

    const { produtos } = useProduto();
    const { campanhaById, updateProdutoCampanha } = useCampanha();

    const rows: Date[] = produtos?.map((produto) => {
        const campanhaProduto: any = campanhaById?.produtos?.find(
            (prod) => prod.produto.id === produto.id
        );

        const nameTipo =
            produto.tipo === "cartao_virtual"
                ? "Virtual"
                : produto.tipo === "cartao_fisico"
                ? "Físico"
                : "Avulso";

        return {
            img: produto.tipo === "cartao_virtual" ? imgDigital : imgFisico,
            id: produto.id,
            tipo: nameTipo,
            nome: produto.nome,
            descricao: produto.descricao,
            lim_saque: formataMoney(produto.lim_saque),
            lim_compras: formataMoney(produto.lim_compras),
            custo_cartao: "",
            toggle: "",
            qtd: campanhaProduto?.lim_quantidade || "0",
            taxa_cobranca: campanhaProduto?.taxa_cobranca || "0.00",
        };
    });

    const columns: GridColumns = [
        {
            field: "img",
            headerName: "IMG",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <img src={row.img} alt="" />;
            },
            hideSortIcons: true,
            disableColumnMenu: true,
            disableExport: true,
            width: 60,
        },

        {
            field: "id",
            headerName: "ID",
            width: 60,
        },
        {
            field: "tipo",
            headerName: "TIPO",
            width: 80,
        },
        {
            field: "nome",
            headerName: "NOME",
            width: 120,
        },
        {
            field: "descricao",
            headerName: "DESCRIÇÃO",
            width: width,
        },
        // {
        //     field: "lim_saque",
        //     headerName: "LIM. SAQUE",
        //     width: width,
        // },
        // {
        //     field: "lim_compras",
        //     headerName: "LIM. COMPRAS",
        //     width: width,
        // },
        {
            field: "qtd",
            headerName: "QTD",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Qtd {...row} rows={rows} />;
            },
            hideSortIcons: true,
            disableColumnMenu: true,
            disableExport: true,
            width: 120,
        },

        {
            field: "custo_cartao",
            headerName: "CUSTO CARTÃO",
            width: 150,
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Taxa {...row} rows={rows} />;
            },
        },

        {
            field: "toggle",
            headerName: "",
            renderCell: ({ row }: GridRenderCellParams) => {
                const produtosCampanhaById =
                    campanhaById?.produtos?.map(
                        (produto) => produto.produto.id
                    ) || [];

                const active = produtosCampanhaById.includes(row.id);

                return (
                    <IOSSwitch
                        checked={active}
                        disabled={
                            !active && campanhaById?.produtos?.length === 3
                        }
                        onChange={async (e) => {
                            let arrProdutos: Array<any> = [];
                            let cartaoVirtual = 0;
                            let cartaoFisico = 0;
                            let cartaoNoname = 0;
                            let update = {} as any;
                            update["produto_id"] = row.id;
                            // update["lim_quantidade"] = qtd;

                            if (!e.target.checked) {
                                arrProdutos = produtosCampanhaById.filter(
                                    (id) => id !== row.id
                                );
                            } else {
                                arrProdutos = [
                                    ...new Set([
                                        ...produtosCampanhaById,
                                        row.id,
                                    ]),
                                ];
                            }

                            arrProdutos.forEach((item) => {
                                produtos.forEach((produto) => {
                                    if (
                                        produto.tipo === "cartao_virtual" &&
                                        produto.id === item
                                    ) {
                                        cartaoVirtual++;
                                    }

                                    if (
                                        produto.tipo === "cartao_fisico" &&
                                        produto.id === item
                                    ) {
                                        cartaoFisico++;
                                    }

                                    if (
                                        produto.tipo === "cartao_avulso" &&
                                        produto.id === item
                                    ) {
                                        cartaoNoname++;
                                    }
                                });
                            });

                            if (cartaoVirtual === 2) {
                                toast.error(
                                    "Só é permitido 1 cartões virtuais"
                                );
                                return;
                            }

                            if (cartaoFisico === 2) {
                                toast.error("Só é permitido 1 cartão físico");
                                return;
                            }

                            if (cartaoNoname === 2) {
                                toast.error("Só é permitido 1 cartão noname");
                                return;
                            }

                            if (arrProdutos.length > 3) {
                                toast.error(
                                    "eita, não era para você estar vendo isso \u{1F631} \u{1F631} \u{1F631}"
                                );
                                return;
                            }

                            const currentProduct = rows
                                .filter((produto) =>
                                    arrProdutos.includes(produto.id)
                                )
                                .map((prod) => {
                                    return {
                                        produto_id: prod.id,
                                        taxa_cobranca: prod.taxa_cobranca,
                                        lim_quantidade: prod.qtd,
                                    };
                                });
                            await updateProdutoCampanha(currentProduct);
                        }}
                    />
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
            disableExport: true,
            width: 60,
        },
    ];

    return <TableProduto rows={rows} columns={columns} nameCSV="PRODUTOS" />;
}
