import styled from "styled-components";

export const Container = styled.div``;

export const ContainerButtonExport = styled.div`
    button {
        border: none;
        width: 30px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: transparent !important;
        }
    }
`;
