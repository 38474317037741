import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0 35px;

    .linha-unica {
        grid-template-columns: 1fr !important;
    }

    .content-tarifa {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        position: relative;
        &.mobile {
            .container-itens {
                height: 480px;
                padding: 0 27px 11px 27px;
                overflow-y: scroll;
                background-color: #fff;
                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 5px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(89, 92, 118, 0.5);
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }
        }
        h1 {
            margin-top:21px;
            margin-bottom:30px;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.secondary};
        }

        .container-itens {
            padding-right: 11px;
            ul {

                @media (min-width: 678px) {
                    max-height: 400px;

                }
                @media (min-width: 1540px) {
                    max-height: 600px;
                }

                overflow: auto;
                scrollbar-width: thin;
                padding: 0 17px 11px 27px;
               

                li {
                    display: grid;
                    grid-template-columns: 216px 1fr;
                    padding: 16px 0px;
                    border-bottom: 1px solid #e8e8e8;
                    &:last-child {
                        border-bottom: 1px solid transparent;
                    }
                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.08em;
                        color: ${(props) => props.theme.colors.primaryBright};
                    }

                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 140%;
                        letter-spacing: 0.04em;
                        color: ${(props) => props.theme.colors.primaryDark};
                        text-align: right;
                    }

                    .ref{
                        position:relative;
                        display:inline-block;
                        text-align:center;
                        left:3px;
                        top:-5px;
                        width:13px;
                        height:13px;
                        padding:2px 1px 1px 1px;
                        color:${(props) => props.theme.colors.white};
                        background-color: ${(props) => props.theme.colors.primaryBright};
                        border-radius: 50%;
                        font-size:7px;
                        font-weight:700;
                    }

                    .ref-text{
                        position:relative;
                        padding-left:6px;
                        padding-bottom:16px;
                        .ref{
                            left:-6px;
                            top:-2px;
                        }
                        .text{
                            position:absolute;
                            display:inline-block;
                        }
                    }

                }
            }
        }

    }

    button{
        margin:50px auto 0;
        width: 100%;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
   }
`;
