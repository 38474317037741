import * as Yup from "yup";

export type keyOf<T> = keyof T;

export interface TipoCartao {
    cartao_fisico: string;
    cartao_virtual: string;
    cartao_avulso: string;
}

export interface SolicitarCartao {
    tipo: string;
}

export const solicitarCartao: SolicitarCartao = {
    tipo: "",
};

export const validaSolicitarCartao = Yup.object().shape({
    tipo: Yup.string().required("Campo obrigatório"),
});
