import {
    useContext,
    createContext,
    useCallback,
    useState,
    useEffect,
} from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
import { Notificacao } from "./UseUser";

interface useNotificacoesProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseNotificacoes {
    getNotificacoes: () => Promise<void>;
    notificacoLida(id: number): Promise<any>;
    notificacoes: Notificacao[];
    loading: boolean;
}

const UseNotificacoesContext = createContext<IuseNotificacoes>(
    {} as IuseNotificacoes
);

export function NotificacoesProvider(props: useNotificacoesProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);

    async function notificacoLida(id: number) {
        try {
            const response = await api.post(`recipiente/deleted`, {
                recipiente_id: id,
            });

            await getNotificacoes();

            toast.success("Notificação marcada como lida");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error?.response;
        }
    }

    const getNotificacoes = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get<Notificacao[]>("recipiente");
            setNotificacoes(
                response.data.filter(
                    (item) => item.describe !== "__ACTIVE_MODAL_CREDITO__"
                )
            );
            setLoading(false);
        } catch (error) {
            toast.error("Erro ao carregar Dados");
            setNotificacoes([]);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getNotificacoes();
    }, [getNotificacoes]);

    return (
        <UseNotificacoesContext.Provider
            value={{ getNotificacoes, notificacoLida, notificacoes, loading }}
        >
            {children}
        </UseNotificacoesContext.Provider>
    );
}

export function useNotificacoes() {
    const context = useContext(UseNotificacoesContext);
    return context;
}
