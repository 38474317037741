import React from "react";
import { Text } from "alisson-application";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { SegmentacaoCupom } from ".";
import { useSegmentacao } from "../../../Hook/system/useSegmentacao";
import { TransitionProps } from "@mui/material/transitions";
import { Container } from "./styles";
import imgCampanha from "../../../assets/img/campanha-verde.svg";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function ButtonSegmentacao() {
    const { modal, setModal, segmentacao } = useSegmentacao();

    return (
        <>
            <Container type="button" onClick={() => setModal(true)}>
                <img src={imgCampanha} alt="" />
                <p>SELECIONAR CAMPANHAS</p>
            </Container>
            {segmentacao.length > 0 && (
                <Text
                    mb="8px"
                    textTransform="uppercase"
                    color="secondary"
                    textAlign="center"
                    fontWeight="bold"
                >
                    segmentado
                </Text>
            )}

            <Dialog
                fullScreen
                open={modal}
                onClose={() => setModal(false)}
                TransitionComponent={Transition}
            >
                <SegmentacaoCupom />
            </Dialog>
        </>
    );
}
