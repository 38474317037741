import { Box, Text } from "alisson-application";
import { CardConfirmacao, ItemConfirmacao } from "../CardConfirmacao";
import { DataBoleto } from "../DataBoleto";
import { LineTextBoleto } from "../LineTextBoleto";
import { Container } from "./styles";
import { ContainerValor } from "../ContainerValor";
import { Button } from "../Mobile/ButtonSlim";
import { usePassos } from "../../Hook/UsePassos";
import { useUser } from "../../Hook/user/UseUser";
import { formatCurrentMoney, formatDate, formataCPF } from "../../util/helper";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { When } from "../when";
import { useEffect } from "react";

interface ConfirmacaoPagamentoProps {
    toTop: React.RefObject<HTMLDivElement>;
}

export function ConfirmacaoPagamento(props: ConfirmacaoPagamentoProps) {
    const { toTop } = props;
    const { nextPasso, dados } = usePassos();
    const { user } = useUser();
    const { cartao } = UseCartoes();
    const linhaDigitavel = dados?.boleto?.digitable || "";
    const dtVencimento = formatDate(dados.boleto?.dueDate || "");
    const hasFisictype = cartao?.tipo === "cartao_fisico" ? true : false;
    let cardNumber = cartao?.acg_account_card_last4digits || "";

    if (!hasFisictype && cartao?.acg_account_card_last4digits) {
        const formatCardNumber = cartao.acg_account_card_last4digits;
        cardNumber = formatCardNumber.substring(formatCardNumber.length - 4);
    } else {
        cardNumber =
            cartao?.acg_account_card_last4digits.substring(
                cartao?.acg_account_card_last4digits.length - 4
            ) || "";
    }

    const dtPagamento = new Date()
        .toLocaleString()
        .split(",")[0]
        .replace(/\//g, ".");

    const beneficiario = [
        {
            title: "Nome Fantasia: ",
            text: dados.boleto?.assignor || "",
        },
        {
            title: "Razão Social:",
            text: dados.boleto?.assignor || "",
        },
        {
            title: "CNPJ:",
            text: dados.boleto?.documentRecipient || "",
        },
    ];

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });

    useEffect(() => {
        if (toTop.current) {
            toTop.current.scrollTo(0, 0);
        }
    }, [toTop]);

    return (
        <Container>
            <CardConfirmacao
                title="PARA (Beneficiário):"
                itens={beneficiario}
            />
            {/* <CardConfirmacao title="Sacador/avalista:" itens={sacador} /> */}

            <div className="container-informacoes">
                <div className="item">
                    <ItemConfirmacao
                        title="Nome do Pagador:"
                        text={user.name}
                    />
                </div>
                <div className="item">
                    <ItemConfirmacao
                        title="CPF:"
                        text={formataCPF(user.document)}
                    />
                </div>
                <div className="item">
                    <ItemConfirmacao
                        title="Linha digitável:"
                        text={linhaDigitavel.replace(
                            /([0-9]{5})([0-9]{5})([0-9]{5})([0-9]{6})([0-9]{5})([0-9]{6})([0-9]{1})/,
                            "$1.$2 $3.$4 $5.$6 $7"
                        )}
                    />
                </div>
            </div>

            <DataBoleto dtVencimento={dtVencimento} dtPagamento={dtPagamento} />

            <Box width="95%" m="0 auto">
                <When expr={dados.boleto?.value}>
                    <LineTextBoleto
                        title="Valor do Título:"
                        text={`R$ ${formatCurrentMoney(
                            dados.boleto?.value || 0
                        )}`}
                    />
                </When>

                <When expr={dados.boleto?.discountValue}>
                    <LineTextBoleto
                        title="Valor do Desconto:"
                        text={`R$ ${formatCurrentMoney(
                            dados.boleto?.discountValue || 0
                        )}`}
                    />
                </When>

                <When expr={dados.boleto?.interestValueCalculated}>
                    <LineTextBoleto
                        title="Valor dos Juros:"
                        text={`R$ ${formatCurrentMoney(
                            dados.boleto?.interestValueCalculated || 0
                        )}`}
                    />
                </When>

                <When expr={dados.boleto?.fineValueCalculated}>
                    <LineTextBoleto
                        title="Valor da Multa:"
                        text={`R$ ${formatCurrentMoney(
                            dados.boleto?.fineValueCalculated || 0
                        )}`}
                    />
                </When>
            </Box>

            <ContainerValor
                value={formatCurrentMoney(dados.boleto?.value || 0)}
            />

            <Text as="p" fontSize="xss" textAlign="center">
                Saldo disponível no Cartão final {cardNumber}:{" "}
                {formatCurrentMoney(parseFloat(dados.boleto?.saldo || 0))}
            </Text>

            <Text mb="18px" mt="31px" as="hr" />

            <Text as="p" color="primaryDark" fontSize="xs" mb="24px">
                <Text as="small" color="secondary" bold fontSize="xs">
                    Descrição:
                </Text>{" "}
                {dados?.boleto?.customerDescription || ""}
            </Text>

            <Text as="p" mb="30px" color="primaryDark" textAlign="center" fontSize="xs">
                A conferência de todas as informações constantes acima,
                inclusive os dados do emissor do boleto (beneficiário) e do
                pagador, são de responsabilidade exclusiva do usuário.
            </Text>

            <Button mt="18px" onClick={() => nextPasso(4)}>
                Pagar
            </Button>
        </Container>
    );
}
