export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
        goBack: true,
    },
];

export const arrPassosSms = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
    },
    {
        passo: 4,
        active: false,
        completed: false,
    },
    {
        passo: 5,
        active: false,
        completed: false,
        goBack: true,
    },
];
