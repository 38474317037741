const WatchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10 2H14"
        stroke="#5E5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6568 7.34314C20.781 10.4673 20.781 15.5327 17.6568 18.6568C14.5327 21.781 9.46733 21.781 6.34314 18.6568C3.21895 15.5327 3.21895 10.4673 6.34314 7.34314C9.46733 4.21895 14.5327 4.21895 17.6568 7.34314"
        stroke="#5E5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 5L17.66 7.34"
        stroke="#5E5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 4L21 6"
        stroke="#5E5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.111 16.556L13.778 13.889L10.222 12.111L12.889 9.444"
        stroke="#5E5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WatchIcon;
