import { useRef } from "react";
import Box from "@mui/material/Box";
import imgSetaCima from "../../../assets/img/seta-cima-sistema.svg";
import { useSegmentacao } from "../../../Hook/system/useSegmentacao";
import { Button } from "../Button";
import { Item } from "./item";
import { StylesSegmento } from "./styles";

interface Segementacoes {}

export function SegmentacoesArea(props: Segementacoes) {
    const ref = useRef<HTMLDivElement[]>([]);
    const { setModal, segmentacaoArea, setSegmentacaoArea, setCampanhas } =
        useSegmentacao();

    function handleCheckCampanha(id: string) {
        const update = segmentacaoArea.map((seg: any) => {
            const item = seg.map((item: any) => {
                if (item?.id === id) {
                    const checked = !item.checked;
                    item.checked = checked;
                    item.fields = item?.fields?.map((field: any) => {
                        return {
                            value: field.value,
                            id: field.id,
                            checked: checked,
                            itens: field.itens.map((item: any) => {
                                return {
                                    value: item.value,
                                    id: item.id,
                                    checked: checked,
                                };
                            }),
                        };
                    });
                    return item;
                }
                return item;
            });
            return item;
        });

        setSegmentacaoArea(update);
    }

    function handleCheckICampos(id: string) {
        const update = segmentacaoArea.map((seg: any) => {
            return seg.map((item: any) => {
                item.fields = item.fields.map((field: any) => {
                    if (field.id === id) {
                        const checked = !field.checked;
                        field.checked = checked;
                        field.itens = field.itens.map((item: any) => {
                            item.checked = checked;
                            return item;
                        });
                    }

                    return field;
                });
                return item;
            });
        });
        setSegmentacaoArea(update);
    }

    function handleCheckItem(id: string) {
        let update = segmentacaoArea.map((seg: any) => {
            return seg.map((item: any) => {
                item.fields = item.fields.map((field: any) => {
                    field.itens = field.itens.map((item: any) => {
                        if (item.id === id) {
                            item.checked = !item.checked;
                        }
                        return item;
                    });
                    return field;
                });
                return item;
            });
        });

        update = update.map((seg: any) => {
            return seg.map((item: any) => {
                item.fields.map((field: any) => {
                    field.checked = field.itens.every(
                        (item: any) => item.checked
                    );
                    return item;
                });
                return item;
            });
        });

        setSegmentacaoArea(update);
    }

    function handleHeightChange(el: HTMLDivElement) {
        const targetElement = (el as HTMLElement).parentElement;
        const height = (el as HTMLElement).offsetHeight + 96 + 32;

        if (targetElement) {
            if (targetElement.classList.value.includes("active")) {
                targetElement.style.height = "96px";
                targetElement.classList.remove("active");
            } else {
                targetElement.classList.add("active");
                targetElement.style.height = height + "px";
            }
        }
    }

    function handleCampanhas() {
        var campanhas: any = [];

        segmentacaoArea.forEach((seg: any) => {
            var arrField: any = [];

            if (!seg[0]) return;

            if (seg[0].checked && seg[0].fields.length === 0) {
                campanhas.push({
                    id: seg[0].id,
                    checked: seg[0].checked,
                    fields: [],
                });
            } else {
                seg[0]?.fields.forEach((field: any) => {
                    if (field.itens.some((item: any) => item.checked)) {
                        field.itens.forEach((item: any) => {
                            if (item.checked) {
                                arrField.push({
                                    [field.value]: item.value,
                                });
                            }
                        });
                    }
                });

                campanhas.push({
                    id: seg[0].id,
                    checked: seg[0].checked,
                    fields: arrField,
                });
            }
        });

        campanhas = campanhas.filter((item: any) => {
            if (item.fields.length) return true;
            if (item.fields.length === 0 && item.checked) return true;
            return false;
        });

        campanhas = campanhas.map((item: any) => {
            return {
                id: item.id,
                fields: item.fields,
            };
        });

        setModal(false);
        setCampanhas(campanhas);
    }

    return (
        <StylesSegmento>
            {segmentacaoArea?.map((seg: any, key: number) => {
                const checkedAll = seg[0].fields.length
                    ? seg[0].fields.every((item: any) => item.checked)
                    : false;

                // if (seg[0].fields.length === 0) return <></>;

                return (
                    <div className="itens active">
                        <div className="container-item">
                            <div className="container-cliente">
                                <Item
                                    id={seg[0]?.id ? seg[0]?.id : ""}
                                    checked={
                                        checkedAll ? checkedAll : seg[0].checked
                                    }
                                    handleChange={(id) =>
                                        handleCheckCampanha(id)
                                    }
                                    label={seg[0].cliente ? seg[0].cliente : ""}
                                />
                                <div className="selecionar-tudo">
                                    <Item
                                        id={seg[0]?.id ? seg[0]?.id : ""}
                                        checked={
                                            checkedAll
                                                ? checkedAll
                                                : seg[0].checked
                                        }
                                        handleChange={(id) =>
                                            handleCheckCampanha(id)
                                        }
                                        label="SELECIONAR TODAS"
                                    />

                                    <button
                                        onClick={() =>
                                            handleHeightChange(ref.current[key])
                                        }
                                    >
                                        <img src={imgSetaCima} alt="" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                            className="container-campanha"
                            ref={(el: HTMLDivElement) => {
                                ref.current[key] = el;
                            }}
                        >
                            <Item
                                id={seg[0]?.id ? seg[0]?.id : ""}
                                checked={
                                    checkedAll ? checkedAll : seg[0].checked
                                }
                                handleChange={(id) => handleCheckCampanha(id)}
                                label={seg[0]?.value ? seg[0]?.value : ""}
                            />
                            <div className="campanhas">
                                {seg[0]?.fields?.map((field: any) => {
                                    return (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                ml: 3,
                                            }}
                                        >
                                            <Item
                                                id={field.id}
                                                checked={field.checked}
                                                handleChange={(id) =>
                                                    handleCheckICampos(id)
                                                }
                                                label={field.value}
                                            />
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    ml: 3,
                                                }}
                                            >
                                                {field.itens.map(
                                                    (item: any) => {
                                                        return (
                                                            <Item
                                                                id={item.id}
                                                                checked={
                                                                    item.checked
                                                                }
                                                                handleChange={(
                                                                    id
                                                                ) =>
                                                                    handleCheckItem(
                                                                        id
                                                                    )
                                                                }
                                                                label={
                                                                    item.value
                                                                }
                                                            />
                                                        );
                                                    }
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}

            <Box
                sx={{
                    width: "220px",
                    marginLeft: "auto",
                    marginTop: "66px",
                }}
            >
                <Button
                    onClick={() => {
                        handleCampanhas();
                    }}
                >
                    FINALIZAR SELEÇÃO
                </Button>
            </Box>
        </StylesSegmento>
    );
}
