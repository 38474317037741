import { ErrorMessage, FieldProps } from "formik";
import Select, { options } from "../../../../NovoSelect";
import { useCallback } from "react";

interface FieldPropsSelect extends FieldProps {
    options: options[];
    disabled?: boolean;
}

export function SelectDate(props: FieldPropsSelect) {
    const { form, field, options, disabled = false } = props;
    const { setFieldValue } = form;

    const hasSelect = useCallback(() => {
        if (options.length) {
            return options.filter(
                (cliente) => cliente.value.toLocaleString() === field.value
            )[0];
        }

        return null;
    }, [field.value, options]);

    return (
        <div
            className={`container-element-backoffice ${
                disabled ? "disabled" : ""
            }`}
        >
            <label htmlFor="">{field.name}</label>
            <div className={`container-input-backoffice`}>
                <Select
                    initalValue={hasSelect()}
                    tipo="cartao"
                    label={field.value}
                    options={options}
                    onChange={(values) => {
                        setFieldValue(field.name, values.value);
                    }}
                />
            </div>
            <ErrorMessage name={field.name} component="span" />
        </div>
    );
}
