import imgSetaEsq from "../../../../../../assets/img/seta-esq-sistema.svg";
import { HeaderStyles } from "./styles";

import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

export function Header() {
    const history = useHistory<HistoryProps>();

    return (
        <HeaderStyles>
            <div className="container-header">
                <button className="voltar" onClick={() => history.goBack()}>
                    <img src={imgSetaEsq} alt="" />
                    {history.location.state.campanha}
                </button>
            </div>
        </HeaderStyles>
    );
}
