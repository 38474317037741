import ReactDOM from "react-dom";
import { App } from "./App";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import Providers from "./Providers";

// Sentry.init({
//     dsn: "https://10a529d2f573439992e887412adaef13@o4504610650849280.ingest.sentry.io/4504610651897856",
//     integrations: [new BrowserTracing(), new Sentry.Replay()],
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production

//     tracesSampleRate: 1.0,
//     environment: process.env.NODE_ENV,
// });

ReactDOM.render(
    <Providers>
        <App />
    </Providers>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
