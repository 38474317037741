import { useContext, createContext, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

import { api } from "../../../../../../services";
import { toast } from "react-toastify";

interface useRegulamentoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useRegulamentoData {
    getCarga: () => Promise<carga[]>;
    inProcess: () => Promise<process[]>;

    setValue: (value: string) => void;
    value: string;

    store: (values: FormData) => Promise<AxiosResponse>;
    updateRegulamento: (values: FormData) => Promise<AxiosResponse>;
    setFile: (value: File | null) => void;
    file: File | null;

    aprove: (id: number) => Promise<AxiosResponse>;
    disApproval: (id: number) => Promise<AxiosResponse>;
}

interface cargaData {
    data: carga[];
}

export interface carga {
    approve_user_id: number | null;
    campanha_id: number;
    created_at: string;
    id: number;
    nome: string;
    status: string;
    tipo: string;
    total_itens_credito_sum: number | null;
    total_itens_users: number;
    updated_at: string;
    user: string;
    user_id: number;
}

interface porcemetroData {
    data: process[];
}

export interface process {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: string | null;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    user: string;
    total_itens_users: number;
    total_itens_credito_sum: string;
}

const UseRegulamentoContext = createContext<useRegulamentoData>(
    {} as useRegulamentoData
);

export function RegulamentoProvider(props: useRegulamentoProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;

    const [file, setFile] = useState<File | null>(null);
    const [value, setValue] = useState("");
    async function getCarga() {
        try {
            const { data } = await api.get<cargaData>(
                `carga?campanha_id=${id}&tipo[]=USER`
            );
            return data.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return [] as carga[];
        }
    }

    async function store(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post(`/campanha/${id}`, values, {
                headers,
            });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function updateRegulamento(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.put(`/campanha/${id}`, values, {
                headers,
            });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function inProcess() {
        try {
            const { data } = await api.get<porcemetroData>(
                `carga?status[]=AGUARDANDO&status[]=PROCESSANDO&campanha_id=${id}&tipo[]=USER`
            );
            return data.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return [] as process[];
        }
    }

    async function aprove(id: number) {
        try {
            const response = await api.post(`carga/aprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga aprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function disApproval(id: number) {
        try {
            const response = await api.post(`carga/reprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga reprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    return (
        <UseRegulamentoContext.Provider
            value={{
                getCarga,
                store,
                updateRegulamento,
                inProcess,
                setFile,
                file,
                aprove,
                value,
                setValue,
                disApproval,
            }}
        >
            {children}
        </UseRegulamentoContext.Provider>
    );
}

export function useRegulamento() {
    const context = useContext(UseRegulamentoContext);
    return context;
}
