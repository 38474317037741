import { Text } from "alisson-application";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/Localization";
import { UseCartoes, Extrato } from "../../../Hook/user/UseCartaoes";
import { formatDateIso, formatCurrentMoney } from "../../../util/helper";

import { MovimentacaoStyles } from "./styles";
export function Movimentacao() {
    const { t } = useTranslation();
    const { extratos } = UseCartoes();

    const [dados, setDados] = useState<Extrato[]>([]);

    function removeFilter(target: Element) {
        target.parentElement?.parentElement
            ?.querySelectorAll(".filter")
            .forEach((e) => e.classList.remove("filter"));
    }

    function handleSortDate(e: MouseEvent<HTMLButtonElement>) {
        const target = e.target as Element;
        const update = [...extratos];
        if (target.classList.value.includes("filter")) {
            setDados([
                ...update.sort(
                    (a, b) =>
                        new Date(a.eventDate).getTime() -
                        new Date(b.eventDate).getTime()
                ),
            ]);
            target.classList.remove("filter");
        } else {
            setDados([
                ...update.sort(
                    (a, b) =>
                        new Date(b.eventDate).getTime() -
                        new Date(a.eventDate).getTime()
                ),
            ]);

            target.classList.add("filter");
        }
    }

    function handleEntradas(e: MouseEvent<HTMLButtonElement>) {
        const target = e.target as Element;
        const update = [...extratos];
        if (target.classList.value.includes("filter")) {
            setDados([
                ...update.sort((a, b) => {
                    if (!a.isCredit && b.isCredit) {
                        return -1;
                    } else if (a.isCredit && !b.isCredit) {
                        return 1;
                    } else {
                        return 0;
                    }
                }),
            ]);

            target.classList.remove("filter");
        } else {
            setDados([
                ...update.sort((a, b) => {
                    if (a.isCredit && !b.isCredit) {
                        return -1; // Coloca 'a' antes de 'b'
                    } else if (!a.isCredit && b.isCredit) {
                        return 1; // Coloca 'b' antes de 'a'
                    } else {
                        return 0;
                    }
                }),
            ]);
            removeFilter(target);
            target.classList.add("filter");
        }
    }

    function handleSaidas(e: MouseEvent<HTMLButtonElement>) {
        const target = e.target as Element;
        const update = [...extratos];
        if (target.classList.value.includes("filter")) {
            setDados([
                ...update.sort((a, b) => {
                    if (a.isCredit && !b.isCredit) {
                        return -1; // Coloca 'a' antes de 'b'
                    } else if (!a.isCredit && b.isCredit) {
                        return 1; // Coloca 'b' antes de 'a'
                    } else {
                        return 0;
                    }
                }),
            ]);
            target.classList.remove("filter");
        } else {
            setDados([
                ...update.sort((a, b) => {
                    if (!a.isCredit && b.isCredit) {
                        return -1;
                    } else if (a.isCredit && !b.isCredit) {
                        return 1;
                    } else {
                        return 0;
                    }
                }),
            ]);

            removeFilter(target);
            target.classList.add("filter");
        }
    }

    useEffect(() => {
        setDados(extratos);
    }, [extratos]);

    return dados?.length > 0 ? (
        <MovimentacaoStyles>
            <div className="header-movimentacao">
                <Text color="black" fontWeight="700">
                    MOVIMENTAÇÕES
                </Text>

                <button onClick={handleSortDate}>
                    <Text as="p" fontWeight="700" className="data-hora">
                        DATA E HORA
                    </Text>
                </button>
                <button onClick={handleEntradas}>
                    <Text as="p" className="entrada active">
                        {t("entradas")}
                    </Text>
                </button>
                <button onClick={handleSaidas}>
                    <Text as="p" className="saida active">
                        {t("saídas")}
                    </Text>
                </button>
            </div>

            <div className="listagem">
                {dados?.map((item, key) => {
                    return (
                        <div
                            className={`item  ${
                                item.reversed ? "reversed" : ""
                            }`}
                            key={key}
                        >
                            <Text
                                as="p"
                                className={`${
                                    item.isCredit === true ? "entrada" : "saida"
                                }`}
                                textTransform="uppercase"
                            >
                                {item.details}
                            </Text>
                            <Text as="span" className="data-hora">
                                {formatDateIso(item.eventDate)}
                            </Text>
                            {item.isCredit === true ? (
                                <>
                                    <Text as="small" className="entrada">
                                        {item.isCredit === true ? "+" : "-"} R${" "}
                                        {formatCurrentMoney(
                                            item.principalAmount
                                        )}
                                    </Text>
                                    <Text></Text>
                                </>
                            ) : (
                                <>
                                    <Text></Text>
                                    <Text as="small" className="saida">
                                        {item.isCredit ? "+" : "-"} R${" "}
                                        {formatCurrentMoney(
                                            item.principalAmount
                                        )}
                                    </Text>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </MovimentacaoStyles>
    ) : (
        <></>
    );
}
