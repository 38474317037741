import styled from "styled-components";
import { Container as Header } from "../../../Header/styles";

export const HeaderStyles = styled.div`
    border-bottom: 1px solid #e1e2e3;
    display: flex;
    align-items: center;
    .container-goback {
        display: flex;
        align-items: center;
        button {
            margin-left: 29px;
            margin-right: 41px;
            display: flex;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            img {
                margin-right: 10px;
            }
        }
    }

    small {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primaryDark};
        display: block;
    }

    .container-date {
        margin-left: auto;
        margin-right: 210px;
        display: flex;
        align-items: center;
        small {
            &:last-child {
                color: ${(props) => props.theme.colors.black};
                font-weight: bold;
                margin-left: 10px;
            }
        }
    }

    .container-status {
        display: flex;
        align-items: center;
        small {
            margin-right: 21px;
        }
    }

    ${Header} {
        border-bottom: unset;
    }
`;
