import Modal from "../../ModalRight";
import { PagePerfil } from "../../../pages/Perfil";
import { ModalConfig } from "../config";

export function ModalPerfil(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PagePerfil />
        </Modal>
    );
}
