import {
    formatDateIsoSystem,
    getLocalStore,
} from "../../../../../../util/helper";
import { SubHeaderStyles } from "./styles";
// import imgUser from "../../../../../../assets/img/user.png";

interface ISubHeader {
    name: string;
    date: string;
}

export function SubHeader(props: ISubHeader) {
    const { date, name } = props;

    return (
        <SubHeaderStyles>
            <h3>{name}</h3>

            <div className="container-user">
                {/* <img src={imgUser} alt="" /> */}

                <div className="container-text">
                    <h4>{getLocalStore()?.name}</h4>
                    <p>{formatDateIsoSystem(date)}</p>
                </div>
            </div>
        </SubHeaderStyles>
    );
}
