import {
    useContext,
    createContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

import { api } from "../../../../../../services";
import { User } from "../../../../../../Hook/system/useUser";
import { ClienteArea } from "../../../../../../Hook/system/useCampanha";
import { PaginationConfig } from "../../../../../../Hook/system/config";

import { toast } from "react-toastify";

interface useInfraProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useInfraData {
    getCarga: (page?: number) => Promise<void>;
    searchCarga(cardNumber: string): Promise<void>;
    cargas: carga[];
    configCargas: PaginationConfig;

    inProcess(): Promise<void>;
    processes: process[];

    storeCarga: (values: FormData) => Promise<AxiosResponse>;
    setFile: (value: File | null) => void;
    file: File | null;

    aprove: (id: number) => Promise<AxiosResponse>;
    disApproval: (id: number) => Promise<AxiosResponse>;

    template: () => Promise<any>;

    loading: boolean;
}

interface cargaData extends PaginationConfig {
    data: carga[];
}

export interface carga {
    approve_user_id: number | null;
    campanha_id: number;
    created_at: string;
    id: number;
    nome: string;
    status: string;
    tipo: string;
    total_itens_credito_sum: number | null;
    total_itens_users: number;
    updated_at: string;
    user: User;
    user_id: number;
    file: string;
    job: string;
    cliente_area_contato: ClienteArea | null;
    observacao: string | null;
}

interface porcemetroData {
    data: process[];
}

export interface process {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: string | null;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    user: User;
    total_itens_users: number;
    total_itens_credito_sum: string;
    total_logs: number;
}

export interface DetalheCarga {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: number;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    users: Users;
}

interface Users {
    id: number;
    carga_id: number;
    user_id: number;
    status: number;
    created_at: string;
    updated_at: string;
}

const UseInfraContext = createContext<useInfraData>({} as useInfraData);

export function InfraProvider(props: useInfraProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState<File | null>(null);
    const [cargas, setCargas] = useState<carga[]>([]);
    const [configCargas, setConfigCargas] = useState<PaginationConfig>(
        {} as cargaData
    );
    const [processes, setProcesses] = useState<process[]>([]);
    const noname = history.location.pathname
        .split("/")
        .some((item) => item === "no-name");
    const getCarga = useCallback(
        async (page?: number) => {
            const hasPage = page ? `&page=${page}` : "";

            setLoading(true);
            try {
                const response = await api.get<cargaData>(
                    `carga?campanha_id=${id}&tipo[]=${
                        noname ? "NONAME" : "USER"
                    }${hasPage}&status[]=APROVADO`
                );
                const { data, ...resto } = response.data;

                setCargas(data);
                setConfigCargas(resto);
            } catch (error: any) {
                toast.error("Erro ao carregar dados da carga");
                setCargas([]);
            }
            setLoading(false);
        },
        [noname, id]
    );

    const inProcess = useCallback(async () => {
        try {
            const { data } = await api.get<porcemetroData>(
                `carga?status[]=AGUARDANDO&status[]=PROCESSANDO&campanha_id=${id}&tipo[]=${
                    noname ? "NONAME" : "USER"
                }`
            );
            setProcesses(data.data);
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            setProcesses([]);
        }
    }, [noname, id]);

    async function searchCarga(cardNumber: string) {
        const search = `carga/search?page=1&tipo=${
            noname ? "NONAME" : "USER"
        }&campanha_id=${id}&search=${cardNumber}&status[]=APROVADO`;
        try {
            setLoading(true);
            const response = await api.get<cargaData>(search);
            const { data, ...resto } = response.data;

            setCargas(data);
            setConfigCargas(resto);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            console.log(error);
            toast.error("Erro ao carregar dados da carga");
        }
    }

    async function storeCarga(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.post("/carga", values, { headers });
            await inProcess();
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function aprove(id: number) {
        try {
            const response = await api.post(`carga/aprovar`, {
                carga_id: `${id}`,
            });
            await inProcess();
            await getCarga();
            toast.success("Carga aprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function disApproval(id: number) {
        try {
            const response = await api.post(`carga/reprovar`, {
                carga_id: `${id}`,
            });
            await inProcess();
            await getCarga();
            toast.success("Carga reprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function template() {
        try {
            const response = await api.get<any>(
                `campanha/${id}/export-example?type=USER`
            );
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error;
        }
    }

    useEffect(() => {
        getCarga();
        inProcess();
    }, [getCarga, inProcess]);

    return (
        <UseInfraContext.Provider
            value={{
                getCarga,
                searchCarga,
                cargas,
                configCargas,
                storeCarga,
                inProcess,
                processes,
                setFile,
                file,
                aprove,
                disApproval,
                template,
                loading,
            }}
        >
            {children}
        </UseInfraContext.Provider>
    );
}

export function useInfra() {
    const context = useContext(UseInfraContext);
    return context;
}
