import { ChangeEvent, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { formataMoney } from "../../../../util/helper";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { Button } from "../../Button";
import { FieldProps } from "formik";

export interface Values {
    quantidade: string;
    valor: string;
}

interface ItensValues extends Values {
    id: string;
}

interface ItensProps extends FieldProps {}

export function Itens(props: ItensProps) {
    const { form } = props;
    const { setFieldValue } = form;

    const [values, setValues] = useState<Values>({} as Values);
    const [itens, setItens] = useState<ItensValues[]>([]);

    function handleclick() {
        const item = [...itens, { ...values, id: uuidv4() }];
        const update = item.map((item) => {
            return {
                quantidade: item.quantidade,
                valor: item.valor,
            };
        });
        setItens(item);
        setFieldValue("itens", update);
        setValues({ quantidade: "", valor: "" });
    }

    function handleRemove(id: string) {
        const updatedItens = itens.filter((item) => item.id !== id);
        const update = updatedItens.map((item) => {
            return {
                quantidade: item.quantidade,
                valor: item.valor,
            };
        });
        setFieldValue("itens", update);
        setItens(updatedItens);
    }

    function handleEdit(e: ChangeEvent<HTMLInputElement>, id: string) {
        const name = e.target.name;
        const value = e.target.value;
        const updatedItens = itens.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    [name]: name !== "quantidade" ? formataMoney(value) : value,
                };
            }
            return item;
        });
        const update = updatedItens.map((item) => {
            return {
                quantidade: item.quantidade,
                valor: item.valor,
            };
        });
        setFieldValue("itens", update);
        setItens(updatedItens);
    }

    return (
        <>
            <div className="container-inputs">
                <div className="container-input-system cupom">
                    <input
                        type="text"
                        placeholder="QUANTIDADE"
                        name="quantidade"
                        value={values.quantidade}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length <= 4) {
                                setValues({
                                    ...values,
                                    quantidade: e.target.value.replace(
                                        /\D/g,
                                        ""
                                    ),
                                });
                            }
                        }}
                    />
                </div>

                <div className="container-input-system valor">
                    <input
                        type="text"
                        placeholder="VALOR"
                        name="valor"
                        value={values.valor}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setValues({
                                ...values,
                                valor: formataMoney(e.target.value),
                            });
                        }}
                    />
                </div>

                <Button onClick={handleclick} type="button">
                    +
                </Button>
            </div>

            {itens.map((item) => {
                return (
                    <div className="container-inputs" key={item.id}>
                        <div className="container-input-system cupom">
                            <input
                                value={item.quantidade}
                                onChange={(e) => handleEdit(e, item.id)}
                                type="text"
                                placeholder="QUANTIDADE"
                                name="quantidade"
                            />
                        </div>

                        <div className="container-input-system valor">
                            <input
                                value={item.valor}
                                onChange={(e) => handleEdit(e, item.id)}
                                type="text"
                                placeholder="VALOR"
                                name="valor"
                            />
                        </div>

                        <button
                            onClick={() => handleRemove(item.id)}
                            type="button"
                        >
                            <img src={imgX} alt="X" />
                        </button>
                    </div>
                );
            })}
        </>
    );
}
