import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { Box } from "alisson-application";
import { useUser } from "../../../Hook/user/UseUser";
import { formatNameCard, formatDate } from "../../../util/helper";
import { Container } from "./styles";
import imgFundoCartao from "../../../assets/img/fundo-card.svg";

interface CardBackProps {
    noname?: boolean;
    value?: string;
    solicitar?: string;
    descricao?: boolean;
}

export function CardBack(props: CardBackProps) {
    const { noname, value, solicitar, descricao = true } = props;
    const { cartao } = UseCartoes();
    const { user } = useUser();

    //solicitar remove todos os campos exceto o nome do cartão
    return (
        <Container>
            <div className="container-img">
                <img src={imgFundoCartao} alt="" />
            </div>

            <div className="container-text">
                {solicitar ? (
                    <div className="numero">
                        <span>**** **** **** ****</span>
                    </div>
                ) : !noname ? (
                    <div className="numero">
                        {cartao?.tipo === "cartao_fisico" ||
                        cartao?.tipo === "cartao_avulso" ? (
                            <span>
                                {cartao?.acg_account_card_last4digits
                                    ? cartao?.acg_account_card_last4digits
                                    : "**** **** **** ****"}
                            </span>
                        ) : null}

                        {cartao?.tipo === "cartao_virtual" ? (
                            <span>
                                {cartao?.acg_account_card_last4digits
                                    ? cartao?.acg_account_card_last4digits
                                    : "**** **** **** ****"}
                            </span>
                        ) : null}
                    </div>
                ) : (
                    <div className="numero">
                        <span>**** **** **** ****</span>
                    </div>
                )}

                {solicitar ? (
                    <div className="conta">
                        <span>**** ****</span>
                    </div>
                ) : !noname ? (
                    <div className="conta">
                        <span>{cartao?.acg_account_id} </span>
                    </div>
                ) : (
                    <div className="conta">
                        <span>
                            {value ? value : "**** ****"}
                            <div className="container-destaque"></div>
                        </span>
                    </div>
                )}

                <div className="data-code">
                    {((noname || solicitar) &&
                        cartao?.tipo === "cartao_fisico") ||
                    cartao?.tipo === "cartao_avulso" ? (
                        <span>**/**</span>
                    ) : (
                        cartao?.tipo === "cartao_fisico" ||
                        (cartao?.tipo === "cartao_avulso" && (
                            <span>
                                {cartao?.acg_account_card_expiration
                                    ? formatDate(
                                          cartao?.acg_account_card_expiration
                                      )
                                    : "**/**"}
                            </span>
                        ))
                    )}

                    {noname && cartao?.tipo === "cartao_virtual" && (
                        <span>**/**</span>
                    )}

                    {solicitar && cartao?.tipo !== "cartao_fisico" && (
                        <span>**/**</span>
                    )}

                    <span>
                        {solicitar
                            ? "***"
                            : cartao?.acg_account_card_cvv
                            ? cartao?.acg_account_card_cvv
                            : "***"}
                        {noname && <div className="container-destaque"></div>}
                    </span>
                </div>

                {solicitar ? (
                    <div className="container-name">
                        <span>{solicitar}</span>
                    </div>
                ) : !noname ? (
                    <div className="container-name">
                        <span>{formatNameCard(user?.pessoa?.nome_cpf)}</span>
                    </div>
                ) : (
                    <div className="container-name">
                        <span></span>
                    </div>
                )}
            </div>

            {descricao && (
                <>
                    <Box className="container-descricao" mt="14px">
                        <h3>Número do cartão</h3>
                        <p>
                            {cartao?.tipo === "cartao_fisico" ||
                            cartao?.tipo === "cartao_avulso" ? (
                                <span>
                                    {cartao?.acg_account_card_last4digits
                                        ? cartao?.acg_account_card_last4digits
                                        : "**** **** **** ****"}
                                </span>
                            ) : null}

                            {cartao?.tipo === "cartao_virtual" ? (
                                <span>
                                    {cartao?.acg_account_card_last4digits
                                        ? cartao?.acg_account_card_cvv
                                        : "**** **** **** ****"}
                                </span>
                            ) : null}
                        </p>
                    </Box>

                    <div className="container-descricao">
                        <h3>Conta</h3>
                        <p>{cartao?.acg_account_id}</p>
                    </div>

                    <div className="container-descricao">
                        <h3>Nome</h3>
                        <p>{formatNameCard(user?.pessoa?.nome_cpf)}</p>
                    </div>
                </>
            )}
        </Container>
    );
}
