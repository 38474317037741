import * as React from "react";

import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import { ModalStyles } from "./styles";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

interface ModalProps {
    open: boolean;
    handleClose: () => void;
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
    classe?: any;
}

export default function ModalRegisterLogin(props: ModalProps) {
    const { open, handleClose, children, classe } = props;

    return (
        <ModalStyles
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            className={classe}
        >
            {children}
        </ModalStyles>
    );
}
