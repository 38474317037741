import { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import { routesProps } from "../../routes/types";

// import TemporaryDrawer from "../Menu";

function RouteWrapper(props: routesProps) {
    const { path } = props;

    if (path === "*") {
        return <Redirect to="/" from={path} />;
    }

    return <Route {...props} />;
    // rota privada sem token
}

export default memo(RouteWrapper);
