import { useEffect, useState } from "react";
import { Box } from "alisson-application";
import { usePassos } from "../../Hook/UsePassos";
import { HeaderPassos } from "../Mobile/HeaderPassos";
import TermoUsoPdf from "../../assets/img/termo-uso.svg";
import { CheckBox } from "../Mobile/CheckBox";
import { Button } from "../Mobile/Button";

import { Container } from "./styles";
import { api } from "../../services";
import { useUser } from "../../Hook/user/UseUser";

const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
];

interface TermoDeUsoProps {
    title: string;
    link: string;
    labelCheck: string;
    header: string;
}

export function TermoDeUso(props: TermoDeUsoProps) {
    const { title, labelCheck, link, header } = props;
    const { setPassos } = usePassos();
    const { user, getUser } = useUser();
    const [termo, setTermo] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setPassos(arrPassos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function logout() {
        try {
            await api.post(`logout`);
            localStorage.clear();
            window.location.href = "/dashboard";
        } catch (error: any) {
            return error?.response;
        }
    }

    async function handleClick() {
        setLoading(true);
        const qualPolitica = user?.termos_uso_file
            ? "usuario/read-termos"
            : "usuario/read-politicas";

        try {
            await api.post(`${qualPolitica}`);
            await getUser();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            return error;
        }
    }

    return (
        <Container>
            <div className="container-header">
                <HeaderPassos title={header} handleClose={() => {}} />
            </div>

            <div className="container-tabs">
                <div className="tab active">
                    <h1>{title}</h1>

                    <p>Leia novamente e aceite para continuar.</p>

                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="termo"
                    >
                        <img src={TermoUsoPdf} alt="" />
                        {header}
                    </a>

                    <div className="container-check">
                        <CheckBox
                            checked={termo}
                            label={labelCheck}
                            onChange={(e) => {
                                setTermo(e.target.checked);
                            }}
                        />
                    </div>

                    <Button
                        isLoading={loading}
                        spiner="BallTriangle"
                        spinerColor="primary"
                        onClick={handleClick}
                        disabled={!termo}
                    >
                        continuar
                    </Button>

                    <Box mt="16px">
                        <Button onClick={() => logout()}>Fechar</Button>
                    </Box>
                </div>
            </div>
        </Container>
    );
}
