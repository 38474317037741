import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import imgDetalhe from "../../../assets/img/editar.svg";
import { Container } from "./styles";
import { Header } from "../Header";
import { ExportarCupom } from "./exportar";
import Table, { Pagination } from "../Table";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useCupom } from "../../../Hook/system/useCupom";
import { formatDateIsoSystem } from "../../../util/helper";

export function Cupom() {
    const history = useHistory();
    const width = 1645 / 7.6;
    const { cupons, configCupons, getCupons } = useCupom();

    async function pagination(page: number) {
        await getCupons(page);
    }

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.id}</Text>;
            },
            width: 20,
        },
        {
            field: "nome",
            headerName: "NOME",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.nome}</Text>;
            },
            width: width,
        },
        {
            field: "campanha",
            headerName: "CAMPANHA",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.campanha}</Text>;
            },
            width: width,
        },
        {
            field: "utilizados",
            headerName: "UTILIZADOS",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.utilizados}</Text>;
            },
            width: width,
        },
        {
            field: "criado_por",
            headerName: "CRIADO POR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.criado_por}</Text>;
            },
            width: width,
        },
        {
            field: "data_de_criacao",
            headerName: "DATA DE CRIACAO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.data_de_criacao}</Text>;
            },
            width: width,
        },
        {
            field: "valido",
            headerName: "VALIDO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.valido}</Text>;
            },
            width: width,
        },
        {
            field: "editar",
            headerName: "EDITAR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/cupom/detalhe/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            })
                        }
                    >
                        <img src={imgDetalhe} alt="" />
                    </button>
                );
            },
            width: width,
        },
        {
            field: "exportar",
            headerName: "EXPORTAR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <ExportarCupom id={row.id} />;
            },
            width: width,
        },
    ];

    const rows = cupons?.map((item) => {
        return {
            id: item.id,
            nome: item.prefixo,
            campanha: item.campanhas.map((item) => item.nome).join("|"),
            utilizados: item.total_utilizado,
            criado_por: "falta retorna criado_por",
            data_de_criacao: formatDateIsoSystem(item.created_at),
            valido: "falta retornar",
            editar: "",
            exportar: "",
        };
    });

    return (
        <Container>
            <Header
                title="CUPONS"
                titleBtn="ADICIONAR CUPOM"
                permission="cupom.store"
                onClickBtn={() => history.push(`/sistema/cupom/novo`)}
            />
            <Table
                columns={columns}
                rows={rows ? rows : []}
                nameCSV="ADICIONAR-CUPOM"
            />

            {configCupons?.last_page > 1 ? (
                <Pagination
                    total={configCupons?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
