import { useEffect, useState } from "react";
import { Text } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { useUpload } from "../../../Hook/system/useUploadFile";

import { FormStyles } from "./styles";
import ImgSetaCima from "../../../assets/img/seta-cima.svg";
import { Myform, initalValues, validadeForm } from "./config";
import { CheckBox } from "../CheckBox";
import { Button } from "../Button";

interface Check {
    id: number;
    name: string;
    check: boolean;
}

export type keyOfMyform<T> = keyof T;

export function FormUploadFiles() {
    const [arqFiles, setArqFiles] = useState<File | null>(null);
    const [checks, setChecks] = useState<Check[]>([]);
    const { getUsers, storeFiles } = useUpload();

    useEffect(() => {
        async function fecthUser() {
            const dados = await getUsers();
            setChecks(dados);
        }

        fecthUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function updateChecks(id: number) {
        const newChecks = checks.map((check) => {
            if (check.id === id) {
                return {
                    ...check,
                    check: !check.check,
                };
            }
            return check;
        });
        setChecks(newChecks);
        return newChecks;
    }

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initalValues}
            validationSchema={validadeForm}
            onSubmit={async (values, actions) => {
                const formData = new FormData();

                formData.append("nome", values.nome);
                formData.append("file", values.file as File);

                for (let i = 0; i < values.users.length; i++) {
                    formData.append("users[]", JSON.stringify(values.users[i]));
                }

                await storeFiles(formData);
            }}
        >
            {(props: FormikProps<Myform>) => {
                const { setFieldValue } = props;
                return (
                    <FormStyles>
                        <Form>
                            <div className="container-input-system">
                                <Field
                                    type="text"
                                    placeholder="TÍTULO DO ARQUIVO"
                                    name="nome"
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <label htmlFor="arquivo" className="arquivo">
                                {arqFiles?.name ? (
                                    <Text as="p">{arqFiles?.name}</Text>
                                ) : (
                                    <Text as="p">
                                        <img src={ImgSetaCima} alt="" />
                                        UPLOAD DE DOCUMENTO
                                    </Text>
                                )}

                                <input
                                    type="file"
                                    id="arquivo"
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf"
                                    onChange={(e) => {
                                        if (e.target.files?.length) {
                                            const file = e.target.files[0];

                                            setArqFiles(file);
                                            setFieldValue("file", file);
                                        }
                                    }}
                                />
                            </label>
                            <ErrorMessage component="span" name="file" />

                            <Text as="p" color="secondary" mb="27px" mt="28px">
                                SELECIONE ABAIXO PARA QUEM DESEJA ENVIAR O
                                ARQUIVO:
                            </Text>

                            <div className="container-users">
                                {checks.map((item) => {
                                    return (
                                        <div className="item" key={item.id}>
                                            <CheckBox
                                                checked={item.check}
                                                onChange={() => {
                                                    const update = updateChecks(
                                                        item.id
                                                    );
                                                    const filter =
                                                        update.filter(
                                                            (item) =>
                                                                item.check ===
                                                                true
                                                        );
                                                    const map = filter.map(
                                                        (item) => item.id
                                                    );
                                                    setFieldValue("users", [
                                                        ...map,
                                                    ]);
                                                }}
                                            />
                                            <Text
                                                as="p"
                                                color="primaryBright"
                                                textTransform="uppercase"
                                            >
                                                {item.name}
                                            </Text>
                                        </div>
                                    );
                                })}
                            </div>
                            <ErrorMessage component="span" name="checks" />

                            <Button
                                isLoading={props.isSubmitting}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                type="submit"
                                disabled={!props.isValid}
                            >
                                ENVIAR ARQUIVO
                            </Button>
                        </Form>
                    </FormStyles>
                );
            }}
        </Formik>
    );
}
