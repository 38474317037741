import imgCartao from "../../../../../assets/img/cardBackOffice.svg";
import imgSetaTop from "../../../../../assets/img/seta-cima-sistema.svg";
import { Container } from "./styles";
import { Card } from "./Card";
import { useRef, useState } from "react";

export function Cards() {
    const ref = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState("");
    const [active, setActive] = useState(false);

    function handleClick() {
        const el = ref.current as HTMLDivElement;
        if (el) {
            const hasActive = el.classList.contains("active");
            const height = (
                el.querySelector(".container-itens") as HTMLDivElement
            ).offsetHeight;

            if (hasActive) {
                el.classList.remove("active");
                el.style.height = "0px";
                setActive(false);
            } else {
                el.classList.add("active");
                el.style.height = `${height}px`;
                setActive(true);
            }
        }
    }

    return (
        <Container>
            <div className="container-header">
                <div className="container-title">
                    <img src={imgCartao} alt="" />
                    <p>CARTÕES</p>
                </div>

                <div className="container-btn">
                    <div className="container-input-system pesquisa">
                        <input
                            type="text"
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </div>
                    <button
                        className={`${active ? "active" : ""}`}
                        onClick={handleClick}
                    >
                        <img src={imgSetaTop} alt="" />
                        <p>{active ? "ver menos" : "ver mais"}</p>
                    </button>
                </div>
            </div>

            <div className="cards" ref={ref}>
                <Card value={value} />
            </div>
        </Container>
    );
}
