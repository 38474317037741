import { HeaderNovo } from "../../Header/novo";
import { FormWhiteList } from "./form";

export function EditarFaq() {
    return (
        <>
            <HeaderNovo title="EDITAR" />
            <FormWhiteList />
        </>
    );
}
