import { useState } from "react";
import { Flex } from "alisson-application";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useRelatorio } from "../../../Hook/system/useRelatorio";
import { Data } from "./config";
import { Button } from "../Button/areas";
import Table, { Pagination } from "../Table";
import { Header } from "./header";
import { Container } from "./styles";
import imgExcluir from "../../../assets/img/x-vermelho.svg";
import imgDownload from "../../../assets/img/dowload.svg";
import imgOlho from "../../../assets/img/olho.svg";
import ModalAprove from "../../../components/System/ModalAlert";
import { toast } from "react-toastify";
import { formatDateIso, formatDateUser } from "../../../util/helper";
import { Loader } from "../../Loader";
export function Relatorio() {
    const width = 1645 / 8;
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [search, setSearch] = useState("");
    const {
        idRelatorio,
        setIdRelatorio,
        data,
        deleteRelatorio,
        loading,
        fetch,
    } = useRelatorio();
    const [modalExcluir, setModalExcluir] = useState(false);
    const progressStatus = ["PROCESSANDO", "ARQUIVO"];

    const dados: Data[] =
        data?.data?.map((data) => {
            const periodo = `${formatDateUser(data.start)} - ${formatDateUser(
                data.end
            )}`.replace(/\//g, ".");

            const hasProgress = progressStatus.includes(data.status);

            return {
                id: data.id,
                titulo: data.title,
                data_hora: formatDateIso(data.created_at),
                tipo: data.tipo,
                periodo,
                criado_por: data.usuario,
                status: data.status,
                excluir: "",
                acoes: data.file,
                progress: hasProgress ? `${data.progress}%` : "-",
            };
        }) || [];

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
        },
        {
            field: "titulo",
            headerName: "TÍTULO",
            width,
        },
        {
            field: "data_hora",
            headerName: "DATA/ HORA",
            width,
        },
        {
            field: "tipo",
            headerName: "TIPO",
            width,
        },
        {
            field: "periodo",
            headerName: "PERÍODO",
            width,
        },
        {
            field: "criado_por",
            headerName: "CRIADO POR",
            width,
        },
        {
            field: "progress",
            headerName: "PROGRESSO",
            width,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Button>{row.status}</Button>;
            },
        },
        {
            field: "excluir",
            headerName: "EXCLUIR",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            setIdRelatorio(row.id);
                            setModalExcluir(true);
                        }}
                    >
                        <img src={imgExcluir} alt="" />
                    </button>
                );
            },
        },
        {
            field: "AÇÕES",
            headerName: "acoes",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <>
                        {row.tipo === "consolidado" && (
                            <Flex mr="16px" alignItems="end">
                                <button
                                    onClick={() =>
                                        history.push({
                                            pathname: `/sistema/relatorio/${row.id}`,
                                            state: { id: row.id },
                                        })
                                    }
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </Flex>
                        )}
                        <button
                            onClick={() => {
                                window.open(row.acoes, "_blank");
                            }}
                        >
                            <img src={imgDownload} alt="" />
                        </button>
                    </>
                );
            },
        },
    ];

    return (
        <Container>
            <Header setSearch={setSearch} />

            <div className="container-table">
                {loading ? (
                    <div className="container-loading">
                        <Loader height={100} width={100} />
                    </div>
                ) : (
                    <Table
                        rows={dados.length > 0 ? dados : []}
                        columns={columns}
                        nameCSV="relatório"
                    />
                )}
            </div>

            {data?.last_page > 1 ? (
                <Pagination
                    total={data?.last_page}
                    currentPage={async (page) => {
                        await fetch(page);
                    }}
                />
            ) : (
                <></>
            )}

            <ModalAprove
                handleClose={() => setModalExcluir(false)}
                reject={() => setModalExcluir(false)}
                aceept={async () => {
                    if (idRelatorio) {
                        await deleteRelatorio();
                        setModalExcluir(false);
                        setIdRelatorio(null);
                        return;
                    }

                    toast.error("Erro ao excluir");
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalExcluir}
            />
        </Container>
    );
}
