import ImgError from "../../assets/img/crash.svg";
import { UseModal } from "../../Hook/Usemodal";
import { Button } from "../Mobile/Button";
import { Container } from "./styles";

export function FalhaAoPagarBoleto() {
    const { closeAllModais } = UseModal();

    return (
        <Container>
            <div className="container">
                <img className="img-close" src={ImgError} alt="" />

                <h1>
                    Não Conseguimos <br/>
                    Realizar o Pagamento
                </h1>

                <p>
                    Aconteceu uma instabilidade no sistema. Por favor, tente novamente em breve.
                </p>

                <Button
                    onClick={() => {
                        closeAllModais();
                    }}
                >
                    Ok
                </Button>
            </div>
        </Container>
    );
}
