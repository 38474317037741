import { ChangeEvent, useState } from "react";
import { motion } from "framer-motion";
import Mask from "react-input-mask";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Button } from "../Button";
import { cartao, validationCartao, CartaoAvulso } from "./config";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { toast } from "react-toastify";
import { rangeDate } from "../../../util/helper";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { UseModal } from "../../../Hook/Usemodal";

export function CartaoNoname() {
    const { controllerForm, setControllerForm, setState, state } =
        useFormLogin();
    const { handleOpen } = UseModal();
    const [loading, setLoading] = useState(false);
    const { checkAccountCampanha } = UseLogin();
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={cartao}
            validationSchema={validationCartao}
            onSubmit={async (values, actions) => {
                setLoading(true);
                const { data } = await checkAccountCampanha({
                    document: state.document.replace(/\D/g, ""),
                    campanha: state.campanha,
                    account: values.account,
                });

                const start = data.campanha?.start_date_time;
                const end = data.campanha?.end_date_time;

                if (
                    data?.user_on_campanha === 0 &&
                    data.campanha.external_register === 1 &&
                    data.user?.register_status === 1 &&
                    data.account === 1 &&
                    rangeDate(start, end)
                ) {
                    handleOpen("vincularUsuarioCampanha");
                    setLoading(false);
                    setState({
                        ...state,
                        name: "",
                        document: state.document,
                        account: values.account,
                    });
                    return;
                }

                if (data?.account) {
                    setControllerForm({
                        ...controllerForm,
                        cartaoNoname: false,
                        completeRegister: true,
                    });

                    setState({
                        ...state,
                        name: "",
                        document: state.document,
                        account: values.account,
                    });
                } else {
                    toast.error("Algo de errado aconteceu!");
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<CartaoAvulso>) => {
                const { values, setFieldValue, handleChange } = props;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.6,
                            }}
                            className={`container-input chave chave-campanha active disabled`}
                        >
                            <Field
                                placeholder="Campanha"
                                name="campanha"
                                type="text"
                                autoComplete="new-password"
                                value={state.campanha}
                            />
                        </motion.div>
                        <ErrorMessage name="campanha" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Mask
                                mask="999.999.999-99"
                                placeholder="CPF"
                                name="document"
                                type="tel"
                                onChange={handleChange}
                                value={state.document}
                            />
                        </motion.div>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className={`container-input chave chave-campanha `}
                        >
                            <Field
                                name="account"
                                type="tel"
                                autoComplete="new-password"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    setFieldValue(
                                        "account",
                                        e.target.value.replace(/[^\d]+/g, "")
                                    );
                                }}
                                maxLength={8}
                                value={values.account}
                                placeholder="CONTA"
                            />
                        </motion.div>
                        <ErrorMessage name="account" component="span" />
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                className="login"
                            >
                                ENTRAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
