import styled from "styled-components";

export const FormStyles = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;
    .areas {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        /* identical to box height, or 12px */

        letter-spacing: 0.08em;
        text-transform: uppercase;

        /* Gray/01 */

        color: ${(props) => props.theme.colors.primary};
        margin-top: 36px;
        margin-bottom: 16px;
    }

    form {
        padding-top: 0px;
        .itens-areas {
            margin-top: 24px;
        }

        .btn-contato {
            background-color: ${(props) => props.theme.colors.backgroundAlt};
            color: ${(props) => props.theme.colors.secondary};
            font-size: ${(props) => props.theme.fonts.xss};
            font-weight: 600;
        }
    }

    .container-imagens-mensagens {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
        margin-bottom: 8px;
        &.um-item {
            grid-template-columns: 1fr;
        }
        label {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid #bcdfba;
            border-radius: 4px;
        }
    }
`;

export const FormPopUp = styled.div``;
