import { CodeInput } from "./Code";
import ImgAuth from "../../../assets/img/auth.svg";
import { Container, LoaderContainer } from "./styles";
import { useLogin } from "../../../Hook/system/useLogin";
import { useEffect, useState } from "react";
import { Loader } from "../../Loader";

export function Authenticator() {
    const [base64, setBase64] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const { user, generateQRCode, confirmeAuthCode } = useLogin();
    const [code, setCode] = useState("");


    useEffect(() => {
        async function getAuthToken() {
            const response = await generateQRCode();
            setBase64(response);
        }
        if (user?.user?.google_2fa_secret_enabled === 0) {
            getAuthToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user.google_2fa_secret_enabled]);

    useEffect(() => {

        const withOutBlanks = code.replaceAll(" ", "");
        if (withOutBlanks.length < 6) return;
        
        const delayInputCheck = setTimeout(() => {
            async function getConfirmAuthCode() {
                setHasError(false);
                setLoading(true);
                const response = await confirmeAuthCode(code);
                if (!response) {
                    setHasError(!response);
                    setCode("");
                }
                setLoading(false);
            }
            getConfirmAuthCode()
            
            return () => clearTimeout(delayInputCheck);
        }, 1000);
        return () => clearTimeout(delayInputCheck);
        
    }, [code, confirmeAuthCode]);

    return (
        <Container>
            <div className="container">
                <div className="container-img">
                    <img src={ImgAuth} alt="Authenticator" />
                </div>

                <div className="container-title">
                    <h3>
                        Habilitar Autenticação <br />
                        de Dois Fatores
                    </h3>

                    <p>
                        Escaneie o QR Code abaixo usando o <br />
                        <strong>App Google Authenticator</strong> no seu
                        telefone.
                    </p>

                    {base64 ? (
                        <div className="container-qrcode">
                            <img
                                src={`data:image/png;base64,${base64}`}
                                alt=""
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    <h4>Código de Verificação</h4>


                    {loading 
                    ? <LoaderContainer>
                        <Loader width={75} height={75}/> 
                    </LoaderContainer>
                    : <CodeInput
                        code={code}
                        setCode={setCode}
                        hasError={hasError}
                      />
                    }
                </div>
            </div>
        </Container>
    );
}
