import { ModalConfig } from "../config";
import ModalRight from "../../ModalRight";
import { AlterarEndereco } from "../../AlterarEndereco";

export function ModalEndereco(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalRight open={open} handleClose={handleClose}>
            <AlterarEndereco />
        </ModalRight>
    );
}
