import { useBackoffice } from "../../../../Hook/system/useBackoffice";
import { When } from "../../../when";
import { Identification } from "./Identification";
import { Person } from "./Person";
import { Addresses } from "./Addresses";
import { Email } from "./Email";
import { Phone } from "./Phone";

import { Container } from "./styles";
export function Content() {
    const { state } = useBackoffice();
    return (
        <Container>
            <When expr={Boolean(state.id)}>
                <>
                    <div className="content-backoffice">
                        <Identification />
                        <br />
                        <Person />
                    </div>

                    <div className="content-backoffice">
                        <Addresses />
                    </div>

                    <div className="content-backoffice">
                        <Email />

                        <br />
                        <Phone />
                    </div>
                </>
            </When>
        </Container>
    );
}
