import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Text } from "alisson-application";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { Button } from "../Button";
import { email, validationEmail, IEmail } from "./config";
import { FormStyles } from "./styles";
import { ChangeEvent, useState } from "react";
import { maskCPF } from "../../../util/helper";

export function Email() {
    const { nextPasso, setDados } = usePassos();
    const { forgetPassword } = useUser();
    const [loading, setLoading] = useState(false);
    return (
        <FormStyles>
            <Text as="p">
                Preencha o campo abaixo com seu E-mail e clique em recuperar
                senha.
            </Text>

            <Formik
                enableReinitialize
                initialValues={email}
                validationSchema={validationEmail}
                validateOnMount
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    const dados = {
                        ...values,
                        email: values.email.replace(/\D/g, ""),
                        type: "whatsapp",
                    };
                    const response: any = await forgetPassword(dados as any);

                    if (response.status === 200) {
                        setDados({
                            ...values,
                            email: values.email,
                            ...response.data,
                        });

                        nextPasso(2);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<IEmail>) => {
                    const { values, setFieldValue } = props;

                    return (
                        <Form>
                            <div className={`container-input user `}>
                                <Field
                                    placeholder="Login"
                                    name="email"
                                    type="tel"
                                    autoComplete="organization"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF(
                                            e.target.value
                                        );

                                        if (document.length <= 14) {
                                            setFieldValue("email", document);
                                        }
                                    }}
                                    value={values.email}
                                />
                            </div>
                            <ErrorMessage name="documento" component="span" />

                            <div className="mt-auto">
                                <Button
                                    type="submit"
                                    isLoading={loading}
                                    disabled={!props.isValid}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    className="login"
                                >
                                    RECUPERAR SENHA
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
