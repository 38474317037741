import styled from "styled-components";

export const FormStyles = styled.div`
    width: 517px;
    padding-top: 63px;
    button {
        width: 220px;
        margin-top: 41px;
    }
    label {
        margin-bottom: 8px;
        &.arquivo {
            background-color: rgba(220, 237, 219, 0.5);
            border: none;
            justify-content: center;
            padding: 0 20px;
        }
        p {
            color: ${(props) => props.theme.colors.primary};
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            line-height: 100%; /* 12px */
            letter-spacing: 0.96px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                background-color: ${(props) => props.theme.colors.secondary};
                border-radius: 5px;
                padding: 5px 7px;
                margin-right: 8px;
            }
        }
    }

    .container-users {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-left: -10px;
        .item {
            display: flex;
            align-items: center;
        }
    }
`;
