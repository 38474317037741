import { Text } from "alisson-application";
import { Button } from "../../Desktop/Button";
import { SubHeaderStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { useUpload } from "../../../Hook/system/useUploadFile";
import { Permissions } from "../Permission";
export function Subheader() {
    const history = useHistory();
    const { stateEnviados, stateRecebidos } = useUpload();
    const total = stateEnviados.length + stateRecebidos.length;
    return (
        <SubHeaderStyles>
            <div className="container-resultados">
                <Text as="p" color="primaryDark">
                    EXIBIR
                </Text>
                <Text as="span" color="secondary">
                    {total ? total : ""}
                </Text>
                <Text as="p" color="primaryDark">
                    RESULTADOS POR PÁGINA
                </Text>
            </div>

            {/* <div className="container-busca">
                <Text as="p" color="secondary">
                    PESQUISAR:
                </Text>

                <div className="container-input-upload-files">
                    <input
                        type="text"
                        placeholder="Buscar registros"
                        name=""
                        id=""
                    />
                </div>
            </div> */}

            <Permissions role="file.store">
                <div className="container-button">
                    <Button
                        onClick={() => history.push("/sistema/upload/files")}
                    >
                        FAZER UPLOAD
                    </Button>
                </div>
            </Permissions>
        </SubHeaderStyles>
    );
}
