import { formatCurrentMoney } from "../../../util/helper";
import { CustoCartao } from "./styles";
import { CheckBox } from "../CheckBox";
import { Button } from "../Button";
import { useState } from "react";
import { usePassos } from "../../../Hook/UsePassos";

interface AlertaNotificacaoProps {
    onClick: () => void;
}

export function CustoEmissaoCartao(props: AlertaNotificacaoProps) {
    const { onClick } = props;
    const { dados } = usePassos();
    const [solicitar, setSolicitar] = useState(true);

    return (
        <CustoCartao className="container">
            <h1>Custo de emissão</h1>

            <p>
                Para emitir o cartão físico há um custo com a produção e envio
                do mesmo. <br />O valor da emissão, de{" "}
                <strong>
                    R${formatCurrentMoney(parseFloat(dados.valor_cobranca))},
                </strong>{" "}
                será cobrado diretamente da sua carteira.
            </p>

            <div className="check">
                <CheckBox
                    onChange={(e) => setSolicitar(!e.target.checked)}
                    label={`Concordo com a cobrança de R$${formatCurrentMoney(
                        parseFloat(dados.valor_cobranca)
                    )} <br /> para emissão do cartão físico.`}
                />
            </div>

            <Button mb="49px" mt="42px" disabled={solicitar} onClick={onClick}>
                SOLICITAR CARTÃO
            </Button>
        </CustoCartao>
    );
}
