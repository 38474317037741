import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useCampanha } from "../../../../../Hook/system/useCampanha";

import { Date } from "./table/config";
import { Input } from "./styles";
// import { parse } from "crypto-js/enc-hex";

interface QtdProps extends Date {
    rows: Date[];
}

export function Qtd(props: QtdProps) {
    const { campanhaById, updateProdutoCampanha } = useCampanha();
    const [value, setValue] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);

    const ids =
        campanhaById?.produtos?.map((produto) => produto.produto.id) || [];
    const produtos = props.rows
        .filter((produto) => ids.includes(produto.id))
        .map((prod) => {
            return {
                produto_id: prod.id,
                taxa_cobranca: prod.taxa_cobranca,
                lim_quantidade: prod.qtd,
            };
        });

    async function updateQdt(qtd: number) {
        setLoading(true);
        const produtosUpdate = produtos.map((produto) => {
            if (produto.produto_id === props.id) {
                produto.lim_quantidade = qtd;
                return produto;
            }

            return produto;
        });

        await updateProdutoCampanha(produtosUpdate);
        setLoading(false);
    }

    useEffect(() => {
        const item: any = campanhaById?.produtos?.find(
            (produto) => produto.produto.id === props.id
        );

        if (item?.id) {
            setValue(item.lim_quantidade);
        }
    }, [campanhaById?.produtos, props.id]);

    return (
        <Input>
            <DebounceInput
                type="number"
                disabled={!ids.includes(props.id) || loading}
                placeholder="QTD"
                value={value ? value : ""}
                debounceTimeout={2000}
                onChange={async (e) => {
                    setValue(parseInt(e.target.value));
                    await updateQdt(parseInt(e.target.value + ""));
                }}
            />
            {/* <Select
                // disabled={!ids.includes(props.id)}
                // label="QTD"
                options={options()}
                initalValue={!loading ? hasQtd() : { label: "⌛", value: 0 }}
                onChange={async (values) => {
                    await updateQdt(parseInt(values.value + ""));
                }}
            /> */}
        </Input>
    );
}
