import * as Yup from "yup";
import { regexPassword } from "../Mobile/EsqueciSenha/config";

export interface IAlterarSenha {
    password: string;
    current_password: string;
    password_confirmation: string;
}

export const altearSenha: IAlterarSenha = {
    password: "",
    current_password: "",
    password_confirmation: "",
};

export const validationAlterarSenha = Yup.object().shape({
    password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Senha é obrigatório"),
    current_password: Yup.string()
        .min(8, "Senha deve ter 8 números")
        .required("Senha é obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),
});
