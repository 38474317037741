import { UseLogCargaEstornoProvider } from "../../../Hook/system/useLogCargaEstorno";
import { LogCargaDetalheEstorno } from "../../../components/System/LogCargaDetalheEstorno";
import { EstornoProvider } from "../../../components/System/Carga/Content/Estorno/Hook";

export function PageLogCargaDetalheEstorno() {
    return (
        <UseLogCargaEstornoProvider>
            <EstornoProvider>
                <LogCargaDetalheEstorno />
            </EstornoProvider>
        </UseLogCargaEstornoProvider>
    );
}
