import styled from "styled-components";

const width = window.screen.width;

export const Container = styled.div`
    background: ${(props) => props.theme.colors.white};
    padding: 33px 23px 28px 25px;
    width: ${width <= 768 ? "100%" : "375px"};
    height: 100vh;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .content-menu {
        ul {
            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 88px;
                border-bottom: 1px solid
                    ${(props) => props.theme.colors.disabled};
                cursor: pointer;
                span,
                button {
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    img {
                        display: block;
                        margin-right: 12.31px;
                    }
                }
            }
        }
    }
`;
