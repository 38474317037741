import styled from "styled-components";
import { Container as Mes } from "../Mobile/Mes/styles";

export const Container = styled.div`
    width: 100%;

    .slide-mes {
        position: relative;
        height: 44px;
        margin: 27px 27px 18px 27px;
        .controles {
            display: none;
        }
        ${Mes} {
            position: absolute;
            bottom: 2px;
            padding-left: 5%;
            width: 100%;
            height: 99%;
        }
    }

    .container-cards {
        margin: 0 10px 0 27px;
        padding-bottom: 38px;
        overflow-y: scroll;

        @media (min-width: 1025px) {
            max-height: 240px;
        }

        @media (min-width: 1540px) {
                max-height:400px;
        }

        &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 5px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 12px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(89, 92, 118, 0.5);
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(255, 255, 255, 0.1);
                }
    }

    .container-loading {
        position: relative;
    }

    .container-filtros {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin: 0 20px 24px 24px;

        div{
            display: flex;

            font-family: Oxanium;
            font-size: 14px;
            font-style: normal;
            line-height: 19px;

            img{
                width: 16px;
                margin-right: 8px;
            }

            span{
                color: ${(props) => props.theme.colors.secondarydark};
                font-weight: 700;
            }
        }
    }

    .goBackBtn{
            margin:25px auto 35px;
            width: 285px;
            height: 2.5rem;
            font-weight: 700;
            letter-spacing: 0;
            text-transform: none;
        }

`;

export const HeaderStyle = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top:50px;
    margin:0 27px;
    border-bottom: 1px solid #e1e2e3;

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: ${(props) => props.theme.colors.secondarydark};
            margin-top:21px;
            margin-bottom:32px;
        }
`;
