import { HeaderNovo } from "../../Header/novo";
import { FormWhiteList } from "./form";

export function EditarWhiteList() {
    return (
        <>
            <HeaderNovo title="editar cpf" />
            <FormWhiteList />
        </>
    );
}
