import { useEffect, useState } from "react";
import { useTranslation } from "../../../contexts/Localization";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import MobileDetec from "mobile-detect";
import { getMonth } from "../../../util/helper";
import { getAllMonthToCurrentDay } from "../../../util/helper";
import { Button, MesesStyles } from "./styles";

import imgSetaDir from "../../../assets/img/seta-dir-verde.svg";
import imgSetaEsq from "../../../assets/img/seta-esq-verde.svg";

import SwiperCore, { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, A11y]);
const hasiPhone = new MobileDetec(window.navigator.userAgent).is("iPhone");
interface MesesProps {
    handleClick: (date: Date) => Promise<void>;
    slidesPerView?: number;
}

interface Mes {
    nome: string;
    active: boolean;
}

export function Meses(props: MesesProps) {
    const { handleClick, slidesPerView = 3.5 } = props;
    const [mes, setMes] = useState<Mes[]>([]);
    const [firstSwiper, setFirstSwiper] = useState<any>({} as any);
    const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslation();
    const { cartao } = UseCartoes();
    const width = window.screen.width;

    function handleMesClick(date: string) {
        const update = mes.map((item) => {
            if (item.nome === date) {
                return { ...item, active: true };
            }
            return { ...item, active: false };
        });

        setMes(update);
    }

    function convertDate(date: string) {
        let newDate = date.split("-");

        let fullDate = newDate[0] + "-" + getMonth(newDate[1].toLowerCase());

        const strDate = `${fullDate.trim()}-01${hasiPhone ? "" : " 00:00:00"}`;

        return new Date(strDate);
    }

    useEffect(() => {
        const meses = cartao?.created_at
            ? getAllMonthToCurrentDay(cartao?.created_at)
            : [];
        if (!meses) return;
        setMes(meses);
    }, [cartao]);

    return mes?.length > 0 ? (
        <MesesStyles>
            <Swiper
                spaceBetween={10}
                slidesPerView={slidesPerView}
                onSwiper={(swiper) => setFirstSwiper(swiper)}
                observer={true}
                initialSlide={mes.length - 1}
                className="swiper-slide-mes"
                navigation={{
                    nextEl,
                    prevEl,
                }}
            >
                {mes.map((m, key) => {
                    const format = m.nome.split(" . ");
                    return (
                        <SwiperSlide
                            key={key}
                            onClick={(values) => {
                                if (firstSwiper) {
                                    const arrSlides =
                                        firstSwiper.slides as HTMLDivElement[];
                                    const button =
                                        values.target as HTMLDivElement;
                                    arrSlides.forEach((slide) => {
                                        slide.classList.remove(
                                            "swiper-slide-active"
                                        );
                                    });
                                    if (button) {
                                        button.parentElement?.classList.add(
                                            "swiper-slide-active"
                                        );
                                    }
                                }
                            }}
                        >
                            <Button
                                onClick={() => {
                                    const date = convertDate(format[1]);
                                    handleClick(date);
                                    handleMesClick(m.nome);
                                }}
                                className={`${m.active ? "active" : ""}`}
                            >
                                {t(format[0])}
                            </Button>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            {width >= 1024 ? (
                <div className="controles">
                    <div ref={(node) => setPrevEl(node)} className="rotate-y">
                        <img src={imgSetaEsq} alt="" />
                    </div>
                    <div ref={(node) => setNextEl(node)}>
                        <img src={imgSetaDir} alt="" />
                    </div>
                </div>
            ) : null}
        </MesesStyles>
    ) : (
        <></>
    );
}
