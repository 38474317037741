import React, { createContext, useContext } from "react";
import { AxiosResponse } from "axios";
import { api } from "../../services/";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../components/System/Carga/types";

interface UseLogEstornoProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseLogEstornoData {
    getLogEstorno(): Promise<AxiosResponse<LogCarga>>;
}

const UseLogEstornoContext = createContext<UseLogEstornoData>(
    {} as UseLogEstornoData
);

interface LogCarga {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: null | number;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    users_log: Log[];
    creditos_log: Log[];
    valores_log: Log[];
    cartoes_log: Log[];
    nonames_log: Log[];
}

export interface Log {
    id: number;
    carga_id: number;
    carga_log_status_regra_id: number;
    data: string;
    created_at: string;
    updated_at: string;
    regra: Regra;
}

interface Regra {
    id: number;
    tipo: string;
    name: string;
    created_at: null | string;
    updated_at: null | string;
}

export function UseLogCargaEstornoProvider(props: UseLogEstornoProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;

    async function getLogEstorno() {
        try {
            const response = await api.get<LogCarga>(`carga/${id}/logs`);
            return response;
        } catch (error: any) {
            return error.response as AxiosResponse<LogCarga>;
        }
    }

    return (
        <UseLogEstornoContext.Provider value={{ getLogEstorno }}>
            {children}
        </UseLogEstornoContext.Provider>
    );
}

export function UseLogCarga() {
    const context = useContext(UseLogEstornoContext);
    return context;
}
