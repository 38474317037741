import { Header } from "../Header";
import { FormUploadFiles } from "../FormUploadFiles";
import ImgUpload from "../../../assets/img/menu/upload-files.svg";

export function UploadFiles() {
    return (
        <>
            <Header
                title="UPLOAD DE ARQUIVOS"
                permission="usuario.me"
                image={ImgUpload}
            />
            <FormUploadFiles />
        </>
    );
}
