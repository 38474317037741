import { Text } from "alisson-application";
import { FieldProps } from "formik";
import { memo, useEffect, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { v4 as uuidv4 } from "uuid";
import { Permissions } from "../../Permission";

import { Campanha, useCampanha } from "../../../../Hook/system/useCampanha";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { getLocalStore } from "../../../../util/helper";
import { Button } from "../../Button";
import { ArrayArea } from "./form";

interface CamposProps extends FieldProps {
    campanhaById: Campanha;
}

function Campos(props: CamposProps) {
    const { updateCampanha } = useCampanha();
    const { form, campanhaById } = props;
    const { setFieldValue, values } = form;

    const [tempArea, setTempArea] = useState<ArrayArea[]>([]);
    const [areas, setAreas] = useState<ArrayArea[]>([]);
    const [foundArea, setFoundArea] = useState<ArrayArea|undefined>();
    const ref = useRef<HTMLInputElement>(null);
    const uniqueValuesSet = new Set();

    const hasPermission = getLocalStore().permissions["campanha.update"];

    async function handleAddArea() {
        if (ref.current?.value) {
            setAreas([...areas, { id: uuidv4(), area: ref.current.value }]);
            const dados = [
                ...tempArea,
                ...areas,
                { id: uuidv4(), area: ref.current.value },
            ].map((area) => area.area);

            setFieldValue("custom_fields", [
                ...dados.filter((c, index) => dados.indexOf(c) === index),
            ]);

            await updateCampanha({
                custom_fields: dados.filter(
                    (c, index) => dados.indexOf(c) === index
                ),
                external_register: values.external_register,
                force_account_register: values.force_account_register,
                force_activate_account_login:
                    values.force_activate_account_login,
                allow_transference_between_users:
                    values.allow_transference_between_users,
                allow_transference_between_campain:
                    values.allow_transference_between_campain,
                allow_cupom: values.allow_cupom ? true : false,
                allow_bill_payment: values.allow_bill_payment ? true : false,
                virtual_card_on_register: values.virtual_card_on_register
                    ? true
                    : false,
                allow_bill_payment_available:
                    values.allow_bill_payment_available ? true : false,
            });
            ref.current.value = "";
        }
    }

    async function handleRemoveArea(id: string) {
        const updatedAreas = areas.filter((a) => a.id !== id);
        const dados = [...updatedAreas].map((area) => area.area);

        setFieldValue("custom_fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        setAreas(updatedAreas);
        setTempArea(updatedAreas);
        await updateCampanha({
            custom_fields: dados.filter(
                (c, index) => dados.indexOf(c) === index
            ),
            external_register: values.external_register,
            force_account_register: values.force_account_register,
            force_activate_account_login: values.force_activate_account_login,
            allow_transference_between_users:
                values.allow_transference_between_users,
            allow_transference_between_campain:
                values.allow_transference_between_campain,
            allow_cupom: values.allow_cupom ? true : false,
            allow_bill_payment: values.allow_bill_payment ? true : false,
            virtual_card_on_register: values.virtual_card_on_register
                ? true
                : false,
            allow_bill_payment_available: values.allow_bill_payment_available
                ? true
                : false,
        });
    }

    async function handleChange(newValue: string, id: string) {
        const foundArea = areas.find(area => area.area === newValue);
        setFoundArea(foundArea);

        
        const updateArea = areas.filter((area) => {
            if (area.id === id) {
                area.area = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempArea].filter((obj) => {
            // check if name property value is already in the set
            const isPresentInSet = uniqueValuesSet.has(obj.id);

            // add name property value to Set
            uniqueValuesSet.add(obj.id);

            // return the negated value of
            // isPresentInSet variable
            return !isPresentInSet;
        });

        const dados = [...tempArea, ...areas].map((area) => area.area);

        setFieldValue("custom_fields", [
            ...dados.filter((c, index) => dados.indexOf(c) === index),
        ]);

        if (foundArea?.area) return;

        await updateCampanha({
            custom_fields: dados.filter(
                (c, index) => dados.indexOf(c) === index
            ),
            external_register: values.external_register,
            force_account_register: values.force_account_register,
            force_activate_account_login: values.force_activate_account_login,
            allow_transference_between_users:
                values.allow_transference_between_users,
            allow_transference_between_campain:
                values.allow_transference_between_campain,
            allow_cupom: values.allow_cupom ? true : false,
            allow_bill_payment: values.allow_bill_payment ? true : false,
            virtual_card_on_register: values.virtual_card_on_register
                ? true
                : false,
            allow_bill_payment_available: values.allow_bill_payment_available
                ? true
                : false,
        });

        setTempArea(filteredArr);
    }

    useEffect(() => {
        if (
            campanhaById.custom_fields &&
            campanhaById.custom_fields !== "null"
        ) {
            const areas = JSON.parse(campanhaById.custom_fields);
            const updateAreas = areas.map((item: string, key: number) => {
                return {
                    id: key + "",
                    area: item,
                };
            });
            setAreas(updateAreas);
        }
    }, [campanhaById.custom_fields]);

    return (
        <>
            <div className="container-input-system">
                <input type="text" placeholder="NOME DO CAMPO" ref={ref} />
                <Permissions role="campanha.update">
                    <Button onClick={handleAddArea} type="button">
                        ok
                    </Button>
                </Permissions>
            </div>

            <Text as="span" fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </Text>
            <div className="itens-areas">
                {areas.map((area) => {
                    return (
                        <div
                            key={area.id}
                            className={`container-input-system area ${
                                hasPermission ? "" : "disabled"
                                }`}
                                >
                            <DebounceInput
                                type="text"
                                disabled={hasPermission ? false : true}
                                debounceTimeout={1000}
                                value={area.area}
                                onChange={(e) => {
                                    handleChange(e.target.value, area.id);
                                }}
                                />
                            <Permissions role="campanha.update">
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(area.id)}
                                    >
                                    <img src={imgX} alt="" />
                                </button>
                            </Permissions>
                        </div>
                    );
                })}
                
                <br />
                {foundArea?.area ? <span className="error">Campo {foundArea.area} não pode ser usado mais de uma vez!</span> : null}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
