import styled from "styled-components";

export const PasswordContainer = styled.div`
    width: 100%;
    position: relative;

    span {
        display: flex;
        justify-content: center;

        width: 100%;
        margin: 0 0 10px;
        font-family: Oxanium;
    }

    input {
        text-transform: unset !important;
        font-family: Oxanium;
    }
`;
export const VisibilityButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    position: absolute;
    top: 0;
    right: 10px;

    cursor: pointer;
`;
