import { Container } from "./styles";

interface EmptyProps {
    title: string;
    img?: string;
}

export function Empty(props: EmptyProps) {
    return (
        <Container img={props.img}>
            <div className="container-user"></div>
            <p>{props.title}</p>
        </Container>
    );
}
