import styled from "styled-components";
import imgDashBoard from "../../../assets/img/logo-dashboard.svg";
import imgCampanhas from "../../../assets/img/campanhas.svg";
import imgYetz from "../../../assets/img/yetz.svg";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    .container-toggle{
        cursor: pointer;
    }
    .container{
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .container-tabs{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 478px;
            .container-item{
                position: relative;
                p, a{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: ${({ theme }) => theme.colors.primaryBright};
                    padding-left: 32px;
                }

                &.dash-board{
                    &:before{
                        -webkit-mask: url(${imgDashBoard}) no-repeat;
                        width: 21px;
                        height: 22px;
                    }    
                }
                &.campanhas{
                    &:before{
                        -webkit-mask: url(${imgCampanhas}) no-repeat;
                        width: 18px;
                        height: 15px;
                    }    
                }
                &.sobre{
                    &:before{
                        -webkit-mask: url(${imgYetz}) no-repeat;
                        width: 21px;
                        height: 22px;
                    }    
                }

                &:before{
                    content: "";
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0px;
                        transform: translateY(-50%);
                        background-color: ${(props) =>
                            props.theme.colors.secondary};
                        -webkit-repeat
                        z-index: 20;
                        transition: background-color 0.5s;
                }
            }
        }

        .container-info{
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .container-notificacao{
                position: relative;
                &:before{
                    content "";
                    display: block;
                    position: absolute;
                    top: -5px;
                    left: 5px;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background-color: ${(props) => props.theme.colors.failure}
                }
            }
        }
    }
    
`;
