import { memo } from "react";
import { Text, Flex } from "alisson-application";
import { formatCurrentMoney } from "../../../util/helper";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useCallback, useEffect, useState } from "react";
import { UseModal } from "../../../Hook/Usemodal";

interface PriceProps {
    showPrice?: boolean;
    id: number;
    type: string;
}

function Price(props: PriceProps) {
    const { showPrice, id, type } = props;
    const [loading, setLoading] = useState(false);

    const { getSaldo, cartaoId, setSaldo, saldo, loadingSaldo } = UseCartoes();
    const { setState, state } = UseModal();

    const currentSaldo = cartaoId === id && saldo;

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            await getSaldo(id);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setState({
                ...state,
                visualizarDados: false,
                errorGeral: {
                    active: true,
                    title: "Ops",
                    message: "Algo de errado aconteceu, tente novamente!",
                },
            });
            return "";
        }
    }, [id, getSaldo, state, setState]);

    useEffect(() => {
        async function fetchVisualizarDados() {
            setLoading(true);
            setSaldo("");
            try {
                await getSaldo(cartaoId ? cartaoId : 0);
                setLoading(false);
                return;
            } catch (error) {
                setLoading(false);
                setState({
                    ...state,
                    visualizarDados: false,
                    errorGeral: {
                        active: true,
                        title: "Ops",
                        message: "Algo de errado aconteceu, tente novamente!",
                    },
                });
            }
        }

        if (showPrice) {
            fetchVisualizarDados();
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPrice, cartaoId]);

    useEffect(() => {
        if (id !== cartaoId && !showPrice) {
            setSaldo("");
            setLoading(false);
        }
    }, [id, cartaoId, setSaldo, showPrice]);

    useEffect(() => {
        if (id === cartaoId && loadingSaldo) {
            setLoading(true);
        }
        if (id === cartaoId && loadingSaldo === false) {
            setLoading(false);
        }
    }, [cartaoId, id, loadingSaldo]);

    return (
        <Flex
            className={`saldo ${loading ? "active" : ""} ${
                currentSaldo ? "show" : ""
            }`}
            mb="16px"
        >
            <Text as="span" mr="0px !important">
                R$
            </Text>
            <div
                className={`dinheiro ${
                    type === "FÍSICO" ? "fisico" : "virtual"
                }`}
            >
                {currentSaldo && (
                    <Text as="p">
                        {formatCurrentMoney(parseFloat(currentSaldo))}
                    </Text>
                )}
            </div>
            {!showPrice && (
                <button
                    onClick={async () => {
                        if (currentSaldo) {
                            setSaldo("");
                        } else {
                            await fetchData();
                        }
                    }}
                    disabled={loading}
                    className={`olho ${!currentSaldo ? "active" : ""}`}
                ></button>
            )}
        </Flex>
    );
}

export const PriceMemo = memo(Price);
