import { SemMensagemStyles } from "./styles";
import SemMensagemImg from "../../assets/img/sem-sms.svg";
export function SemMensagem() {
    return (
        <SemMensagemStyles>
            <div className="container-img">
                <img src={SemMensagemImg} alt="" />
            </div>

            <h3>
                Nenhuma mensagem
                <br />
                enviada
            </h3>
        </SemMensagemStyles>
    );
}
