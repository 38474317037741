import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));
    grid-gap: 40px 24px;
`;

export const CardStyles = styled.div`
    border: 1px solid #e1e2e3;
    padding: 20px 25px;
    width: 100%;

    button {
        &.btn-excluir {
            margin-left: auto;
            height: auto;
            img {
                margin: 0px;
            }
        }
    }
    .container-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        display: flex;
        align-items: start;
        padding-bottom: 16px;
        border-bottom: 1px solid #e1e2e3;
        img {
            margin-right: 16px;
        }
    }

    .container-info {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid #e1e2e3;
        margin-bottom: 16px;
        h4 {
            margin-bottom: 16px;
        }

        .tag {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};

            display: block;
            padding: 6px 10px;

            width: fit-content;
            background: ${(props) => props.theme.colors.disabled};
            margin-bottom: 16px;
        }
    }

    button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.secondary};
        display: flex;
        align-items: center;
        img {
            display: block;
            margin-right: 12px;
        }
    }
`;
