import styled from "styled-components";
import { Container as Mes } from "../Mes/styles";

export const Container = styled.div`
    position: relative;
    margin-bottom: 28px;
    width: 100%;
    overflow: hidden;
    .container-loading {
        position: relative;
    }

    .slide-mes {
        position: relative;
        height: 44px;
        margin: 24px auto 29px auto;
        width: 90%;
        .controles {
            display: none;
        }
        ${Mes} {
            position: absolute;
            bottom: 2px;
            padding-left: 5%;
            width: 100%;
            height: 99%;
        }
    }

    .container-filtros {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 18px;
    }
`;

export const Content = styled.div``;

export const FiltroEntradaSaidaStyles = styled.div`
    .entrada {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 12px solid ${(props) => props.theme.colors.secondary};
            position: absolute;
            top: 3px;
            left: 0px;
        }
    }

    .saida {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 12px solid ${(props) => props.theme.colors.warning};
            position: absolute;
            top: 6px;
            left: 0px;
        }
    }
`;

export const FiltroDateStyles = styled.div`
    padding: 14px 19px 24px 23px;
    button {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: ${(props) => props.theme.colors.primaryDark};
        text-transform: uppercase;
        &.active {
            color: ${(props) => props.theme.colors.secondary};
            font-weight: bold;
        }
    }
`;
