import { Text } from "alisson-application";
import { Container } from "./styles";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import imgSetaBaixo from "../../assets/img/seta-baixo.svg";

interface AccordionProps {
    title: string;
    description: string;
}

export default function SimpleAccordion(props: AccordionProps) {
    const { title, description } = props;
    return (
        <Container>
            <AccordionSummary
                expandIcon={<img src={imgSetaBaixo} alt="" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Text>{title}</Text>
            </AccordionSummary>
            <AccordionDetails>
                <Text
                    fontSize="xs"
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            </AccordionDetails>
        </Container>
    );
}
