import styled from "styled-components";
import { Button } from "../Button";
import fundoBtn from "../../../assets/img/fundo-btn-2.svg";
import fundoMobileBtn from "../../../assets/img/fundo-btn-mobile.svg";
import imgCheck from "../../../assets/img/check-auth.svg";

export const PassworStrength = styled.div`
    position: relative;
    .main-container {
        width: 100%;
        top: 100px;
    }

    small {
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 100%;
        -webkit-letter-spacing: 0.08em;
        -moz-letter-spacing: 0.08em;
        -ms-letter-spacing: 0.08em;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.white};
        text-align: center;
        display: block;
    }

    @media (min-width: 768px) {
        small {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .form-container {
        /* bottom: 100px; */
    }

    legend.form-label {
        font-size: 1.5rem;
        color: #3d658f;
    }

    .control-label {
        font-size: 0.8rem;
        font-weight: bold;
        color: #52657a;
    }

    .form-hint {
        font-size: 0.6rem;
        line-height: 1.4;
        margin: -5px auto 5px;
        color: #999;
    }
    .form-hint.error {
        color: #c00;
        font-size: 0.8rem;
    }

    button.btn {
        letter-spacing: 1px;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .password-count {
        bottom: 16px;
        right: 10px;
        font-size: 1rem;
    }

    .strength-meter {
        position: relative;
        height: 3px;
        background: #ddd;
        margin: 7px 0;
        border-radius: 2px;
    }
    .strength-meter:before,
    .strength-meter:after {
        content: "";
        height: inherit;
        background: transparent;
        display: block;
        border-color: transparent;
        border-style: solid;
        border-width: 0 6px 0;
        position: absolute;
        width: calc(20% + 6px);
        z-index: 10;
    }
    .strength-meter:before {
        left: calc(20% - 3px);
    }
    .strength-meter:after {
        right: calc(20% - 3px);
    }

    .strength-meter-fill {
        background: transparent;
        height: inherit;
        position: absolute;
        width: 0;
        border-radius: inherit;
        transition: width 0.5s ease-in-out, background 0.25s;
    }
    .strength-meter-fill[data-strength="0"] {
        &.visible {
            width: 0%;
            background: #ddd !important;
        }
    }
    .strength-meter-fill[data-strength="0"] {
        width: 20%;
        background: #be180b;
    }
    .strength-meter-fill[data-strength="1"] {
        width: 40%;
        background: #de4109;
    }
    .strength-meter-fill[data-strength="2"] {
        width: 60%;
        background: #f68108;
    }
    .strength-meter-fill[data-strength="3"] {
        width: 80%;
        background: #eed80b;
    }
    .strength-meter-fill[data-strength="4"] {
        width: 100%;
        background: #62ee45;
    }
`;

export const Card = styled.div`
    padding: 0px;
    position: relative;
    margin-top: 0px;
    transition: margin-top 0.5s ease-in-out;
    ${Button} {
        margin-top: auto;
    }
    .container-form-login {
        .container-input {
            &.cep {
                display: flex;
                align-items: center;
                input {
                    width: 238px;
                    margin-right: 8px;
                }
            }
            &.user {
                &.select {
                    margin-bottom: 8px;
                }
            }
        }
        form {
            display: flex;
            flex-direction: column;
            padding-bottom: 0px;
            min-height: 290px;
            height: auto;
            width: 398px;
            margin: 0 auto;
            padding-bottom: 40px;
            .mt-auto {
                margin-top: 37px;
                width: 100%;
                button {
                    width: 100%;
                }
            }

            .container-input {
                &.genero {
                    margin-bottom: 0px;
                }
            }

            span {
                color: ${(props) => props.theme.colors.failure} !important;
                display: block;
                margin-bottom: 8px;
            }

            small {
                &.informacao-senha {
                    font-size: ${(props) => props.theme.fonts.md};
                    color: ${(props) => props.theme.colors.secondary};
                    font-weight: bold;
                    margin-left: 28px;
                    width: 260px;
                    display: block;
                    text-transform: uppercase;
                    margin-top: 17px;
                }
            }

            li {
                span {
                    color: ${(props) => props.theme.colors.primary} !important;
                    text-align: left;
                    &.MuiCheckbox-root {
                        color: ${(props) =>
                            props.theme.colors.secondary} !important;
                    }
                }
            }
            input {
                background-color: ${(props) => props.theme.colors.white};
            }

            ${Button} {
                /* background-color: transparent !important; */
                background-color: ${(props) => props.theme.colors.secondary};
                background-size: cover;
                background-repeat: no-repeat;
                height: 68px;
                color: ${(props) => props.theme.colors.white};
                transition: 0.3s !important;
                position: relative;
                &.login {
                    -webkit-mask: url(${fundoBtn});
                    position: relative;
                    z-index: 50;
                    overflow: hidden;
                    background-color: ${(props) => props.theme.colors.white};
                    color: ${(props) => props.theme.colors.secondary};

                    &:hover:not(:disabled) {
                        background-color: #a2cf43;
                        color: ${(props) => props.theme.colors.white};
                    }
                    &:disabled {
                        background-color: ${(props) =>
                            props.theme.colors.white} !important;
                    }
                }

                &:hover:not(:disabled) {
                    opacity: 1 !important;
                    background-color: ${(props) =>
                        props.theme.colors.secondary};
                    color: ${(props) => props.theme.colors.white};
                    box-shadow: 0px 15px 20px rgb(76 151 70 / 50%);
                    transform: translateY(-7px);
                }

                &:disabled {
                    opacity: 0.65;
                }
                &.btn-lupa {
                    background-color: ${(props) =>
                        props.theme.colors.secondary} !important;
                    background: unset;
                    position: relative;
                    z-index: 99;
                }
                &:disabled {
                    border-color: transparent !important;
                    background-color: ${(props) =>
                        props.theme.colors.secondary} !important;
                }
                div {
                    /* position: relative; */
                    z-index: 50;
                }
            }
        }
    }

    p {
        width: 254px;
        margin: 0 auto;
        margin-bottom: 1.188rem;
    }

    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @media (max-width: 768px) {
        .container-form-login {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
        .container-form-login {
            padding-bottom: 0px;
            form {
                padding-top: 19px;
                padding-bottom: 56px;
                min-height: 205px;
                input {
                    height: 54px;
                }
                li {
                    span {
                        color: ${(props) =>
                            props.theme.colors.secondary} !important;
                    }
                }
                ${Button} {
                    background-image: unset;
                    background-color: ${(props) =>
                        props.theme.colors.secondary} !important;
                    color: ${(props) => props.theme.colors.white};

                    height: 52px;
                    &.login {
                        -webkit-mask: url(${fundoMobileBtn});
                        -webkit-mask-repeat: no-repeat;
                        -webkit-mask-size: contain;
                        background-color: ${(props) =>
                            props.theme.colors.white} !important;
                    }
                    &.btn-lupa {
                        background-color: ${(props) =>
                            props.theme.colors.secondary} !important;
                        background: unset;
                        position: relative;
                        z-index: 99;
                    }
                }
                .mt-auto {
                    margin-top: auto;
                }
            }
        }
    }
`;

export const Container = styled.div`
    /* background-color: ${(props) => props.theme.colors.white}; */
    background-color: ${(props) => props.theme.colors.secondary};
    .text-senha {
        display: block;
        text-align: left;
        margin-top: 62px;
    }

    ul {
        &.list-requisitos {
            padding-left: 35px;
            margin-bottom: 0px;
            li {
                p {
                    font-size: 12px;
                    margin-bottom: 0px;
                    color: ${(props) => props.theme.colors.black};
                }
                position: relative;
                &.active {
                    &:before {
                        background-color: ${(props) =>
                            props.theme.colors.secondary};
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 45%;
                    left: -30px;
                    transform: translateY(-50%);
                    background-color: ${(props) => props.theme.colors.failure};
                    -webkit-mask: url(${imgCheck}) no-repeat;
                    width: 14px;
                    height: 10px;
                    z-index: 20;
                    transition: background-color 0.5s;
                }
            }
        }
    }

    .container-text-politicamente-exposta {
        width: 258px;
        margin-left: 30px;
        height: 0px;
        overflow: hidden;
        transition: height 0.5s;
        &.active {
            height: 100px;
            transition: height 0.5s;
        }
        small {
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .politicamente-exposta {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.secondary};
        padding-left: 30px;
        &.active {
            img {
                transform: rotateX(180deg);
                transition: all 0.5s;
            }
        }

        img {
            margin-right: 6.67px;
            transition: all 0.5s;
        }
    }

    .esqueci-senha {
        width: 100%;
    }

    .text-login {
        position: relative;
        img {
            display: block;
            margin-bottom: 53px;
        }

        p {
            color: ${(props) => props.theme.colors.white};
        }
        .login {
            opacity: 1;
            position: relative;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 1s;
            margin: 0px;
            margin-bottom: 1.188rem;
        }
        .register {
            opacity: 0;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 1s;
            margin: 0px;
            margin-bottom: 1.188rem;
        }
    }
    &.active {
        .text-login {
            .login {
                opacity: 0;
                position: absolute;
                top: 0px;
                transition: opacity 1s;
            }
            .register {
                opacity: 1;
                position: relative;
                transition: opacity 1s;
            }
        }
    }

    .container-checks {
        width: 285px;
        padding-top: 32px;
        padding-bottom: 32px;
    }

    li {
        display: flex;
        align-items: center;
        label {
            span {
                color: ${(props) => props.theme.colors.primary} !important;
                font-size: ${(props) => props.theme.fonts.xs} !important;
                white-space: nowrap;
            }
        }
        &.li-acordo {
            .MuiTypography-root {
                white-space: normal;
                text-align: left;
            }
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-align: left;
            color: ${(props) => props.theme.colors.failure};
            margin: 0px;
            white-space: nowrap;
        }
    }

    @media (max-width: 768px) {
        .container-img {
            height: 284px;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .container-form-login {
            .mt-auto {
                display: flex;
                align-items: center;
                justify-content: center;
                button {
                    width: 70%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding-bottom: 0px;
        height: 100%;
        li {
            label {
                span {
                    color: ${(props) =>
                        props.theme.colors.secondary} !important;
                    &.MuiFormControlLabel-label {
                        color: ${(props) =>
                            props.theme.colors.primary} !important;
                    }
                }
            }
        }
        .container-form-login {
            border-radius: 24px 24px 0px 0px;
            height: 90%;
        }
        .container-img {
            margin-bottom: 0px;
        }
        .text-login {
            display: none;
            .login {
                margin-bottom: 17px;
            }
            img {
                display: none;
            }
        }
    }
`;

export const HeaderPassosStyles = styled.div`
    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 21px;
        margin-top: 45px;
    }
    .container-passos {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        div {
            display: block;
            height: 1px;
            width: 100%;
            margin-right: 4px;
            border-bottom: 1px solid #e1e2e3;
            &.active {
                border-bottom: 1px solid
                    ${(props) => props.theme.colors.secondary};
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 768px) {
        width: 300px;
        margin: 0 auto;
        h3 {
            width: 100%;
            margin: 0px;
            margin-top: 16px;
        }
    }
`;
