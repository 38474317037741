import { useHistory } from "react-router-dom";
import { Box } from "alisson-application";
import { Header as HeaderUsuario } from "../../../Header";
import { Button } from "../../../Button";

import imgSetaEsq from "../../../../../assets/img/seta-esq-sistema.svg";
import imgUser from "../../../../../assets/img/menu/adm.svg";
import { HeaderStyles } from "./styles";

export function Header() {
    const history = useHistory();

    return (
        <HeaderStyles>
            <div className="container-goback">
                <button
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <img src={imgSetaEsq} alt="" />
                    voltar
                </button>

                <HeaderUsuario title="Perfil de usuario" image={imgUser} />
            </div>

            <Box width="268px" ml="auto">
                <Button
                    onClick={() =>
                        history.push(
                            `/sistema/administracao/permissao/adicionar`
                        )
                    }
                >
                    ADICIONAR Perfil de usuario
                </Button>
            </Box>
        </HeaderStyles>
    );
}
