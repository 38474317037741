import {
    useContext,
    createContext,
    useState,
    useCallback,
    useEffect,
} from "react";
import { api } from "../../services";
import {
    formatDateIso,
    getFirsDayMonth,
    getLastDayMonth,
} from "../../util/helper";
import { ExtratoBoleto, UseCartoes } from "./UseCartaoes";

interface useComprovanteProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface UseComprovante {
    requestComprovante: (data?: Date) => Promise<any>;
    extratos: ExtratoBoleto[];
    loading: boolean;
}

const Context = createContext<UseComprovante>({} as UseComprovante);

export function ComprovanteProvider(props: useComprovanteProviderProps) {
    const { children } = props;
    const { cartao } = UseCartoes();
    const [extratos, setExtratos] = useState<ExtratoBoleto[]>([]);
    const [loading, setLoading] = useState(false);
    const id = cartao?.id;

    const requestComprovante = useCallback(
        async (data?: Date) => {
            if (!id) {
                setExtratos([] as ExtratoBoleto[]);
                return;
            }

            const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(data))
                .split(" - ")[0]
                .split(".")
                .reverse()
                .join("-");
            const ultimoDiaDoMes = formatDateIso(getLastDayMonth(data))
                .split(" - ")[0]
                .split(".")
                .reverse()
                .join("-");

            try {
                setLoading(true);
                const { data } = await api.get<ExtratoBoleto[]>(
                    `conta?start=${primeiroDiaDoMes}&end=${ultimoDiaDoMes}&cartao_id=${id}`
                );

                setExtratos(data);
                setLoading(false);
            } catch (error: any) {
                setExtratos([] as ExtratoBoleto[]);
                setLoading(false);
                return error?.response;
            }
        },
        [id]
    );

    useEffect(() => {
        requestComprovante();
    }, [requestComprovante]);

    return (
        <Context.Provider value={{ requestComprovante, extratos, loading }}>
            {children}
        </Context.Provider>
    );
}

export function useComprovante() {
    const context = useContext(Context);
    return context;
}
