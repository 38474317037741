import { Box, Text } from "alisson-application";
import { useLogin } from "../../../Hook/system/useLogin";
// import { UseModal } from "../../../Hook/Usemodal";
import { Formik, Form, FormikProps, Field, ErrorMessage } from "formik";
import { initialValues, validation, MyFormValues } from "./types";
import { Button } from "../../Desktop/Button";
import { maskCPF } from "../../../util/helper";
import { ChangeEvent } from "react";

// src/assets/sistema/yetzestoque_branco.svg

export function FormLogin() {
    const { login } = useLogin();
    // const { handleOpen } = UseModal();

    return (
        <Box className="box-login">
            <Text as="h2">Acesso Administrador</Text>

            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={async (values, actions) => {
                    values = {
                        ...values,
                        email: values.email.replace(/\D/g, ""),
                    };
                    await login(values);
                }}
            >
                {(props: FormikProps<MyFormValues>) => {
                    const { setFieldValue, values } = props;
                    return (
                        <Form>
                            <div className="input-login">
                                <Field
                                    placeholder="Login"
                                    name="email"
                                    type="tel"
                                    autoComplete="organization"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF(
                                            e.target.value
                                        );

                                        if (document.length <= 14) {
                                            setFieldValue("email", document);
                                        }
                                    }}
                                    value={values.email}
                                />
                                <ErrorMessage name="email" component="span" />
                            </div>
                            <div className="input-login">
                                <Field
                                    name="password"
                                    type="password"
                                    placeholder="DIGITE SUA SENHA"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="span"
                                />
                            </div>

                            <Button type="submit">ENTRAR</Button>

                            {/* <button
                                type="button"
                                className="esqueci-senha"
                                onClick={() => handleOpen("esqueciSenha")}
                            >
                                <span>Esqueci minha senha</span>
                            </button> */}
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
}
