import { useState } from "react";
import ModalAprove from "../../../components/System/ModalAlert";
import { useTermoECondicoesDeUso } from "../../../Hook/system/TermoECondicoesDeUso";
import imgExcluir from "../../../assets/img/x-vermelho.svg";

interface ExcluirProps {
    id: number;
}

export function Excluir(props: ExcluirProps) {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const { deleteTermo } = useTermoECondicoesDeUso();
    return (
        <>
            <button
                disabled={loading}
                onClick={() => {
                    setOpenModal(true);
                }}
            >
                {loading ? "⌛" : <img src={imgExcluir} alt="" />}
            </button>

            <ModalAprove
                handleClose={() => setOpenModal(false)}
                reject={() => setOpenModal(false)}
                aceept={async () => {
                    setLoading(true);
                    await deleteTermo(props.id);
                    setLoading(false);
                    setOpenModal(false);
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={openModal}
            />
        </>
    );
}
