import { Flex, Box, Text } from "alisson-application";
import { api } from "../../../services";
import { getLocalStore } from "../../../util/helper";
import { Link, useHistory } from "react-router-dom";
import { Card } from "./card";
import { Container } from "./styles";

import imgAdm from "../../../assets/img/menu/adm.svg";
import imgCampanha from "../../../assets/img/menu/campanha.svg";
import imgCliente from "../../../assets/img/menu/cliente.svg";
import imgComunicacao from "../../../assets/img/menu/comunicacao.svg";
import imgContato from "../../../assets/img/menu/contato.svg";
import imgDownload from "../../../assets/img/menu/download.svg";
import imgCardUser from "../../../assets/img/menu/user.svg";
import ImgUpload from "../../../assets/img/menu/upload-files-menu.svg";

import imgLogo from "../../../assets/img/logo-sistema-verde.svg";
import imgUser from "../../../assets/img/user.svg";
import imgX from "../../../assets/img/x.svg";
import { toast } from "react-toastify";
import { useEffect } from "react";

export function DashBoard() {
    const permissions = Object.keys(getLocalStore().permissions);
    const history = useHistory();
    async function logout() {
        try {
            await api.post("logout");
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error("Erro ao sair do sistema");
        }
    }

    const arrCards = [
        {
            title: "CLIENTE",
            img: imgCliente,
            path: "cliente",
            permission: "cliente.index",
        },
        {
            title: "CAMPANHAS",
            img: imgCampanha,
            path: "campanha",
            permission: "campanha.index",
        },
        {
            title: "ADM",
            img: imgAdm,
            path: "administracao",
            permission: "role.index",
        },
        {
            title: "USUÁRIOS",
            img: imgCardUser,
            path: "usuarios",
            permission: "usuario.search",
        },
        {
            title: "DOWNLOADS",
            img: imgDownload,
            path: "",
            permission: "",
        },
        {
            title: "COMUNICAÇÃO",
            img: imgComunicacao,
            path: "comunicacao",
            permission: "acao.index",
        },
        {
            title: "FALE CONOSCO",
            img: imgContato,
            path: "fale-conosco",
            permission: "helpdesk.index",
        },
        {
            title: "FAQ",
            img: imgContato,
            path: "faq",
            permission: "faq.index",
        },
        {
            title: "FILE",
            img: ImgUpload,
            path: "upload",
            permission: "file.index",
        },
    ];

    const arrCardsPermission = arrCards.filter((item) => {
        return permissions.find((permission) => item.permission === permission);
    });

    useEffect(() => {
        if (getLocalStore().permissions_count === 2) {
            history.push("/sistema/upload");
        }
    }, [history]);

    return (
        <Container className="container">
            <Flex className="header">
                <Box>
                    <img src={imgLogo} alt="" />
                </Box>

                <Flex alignItems="center" className="acoes">
                    <Text as="p" color="primaryDark">
                        <img src={imgUser} alt="" /> Olá {getLocalStore()?.name}
                    </Text>

                    <Text
                        as="p"
                        className="sair"
                        color="primaryDark"
                        onClick={() => logout()}
                    >
                        <img src={imgX} alt="" /> sair
                    </Text>
                </Flex>
            </Flex>

            <div className="container-cards">
                {arrCardsPermission.map((item, key) => (
                    <Link to={`/sistema/${item.path}`} key={key}>
                        <Card {...item} />
                    </Link>
                ))}
            </div>

            <Text color="primaryDark" textAlign="center" mb="104px">
                ® YETZ LTDA. Todos os direitos reservados | CNPJ:
                28.325.166/0001-05
            </Text>
        </Container>
    );
}
