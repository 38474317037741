import { motion } from "framer-motion";
import { Box } from "alisson-application";
import { toast } from "react-toastify";
import imgX from "../../../../assets/img/x.svg";
import imgReprovar from "../../../../assets/img/reprovacao.svg";
import NovoModal from "../../../NovoModal";
import Select, { options } from "../../../NovoSelect";
import { Container } from "./styles";
import { Button } from "../../Button";
import { useAprovacaoTransferencia } from "../../../../Hook/system/useAprovacaoTransferencia";
import { useEffect, useState } from "react";
import { api } from "../../../../services";

interface Motivos {
    id: number;
    nome: string;
    descricao: string;
    created_at: null | string;
    updated_at: null | string;
}

export function Reprovar() {
    const { modalReprovar, setModalReprovar, loading, reprovarPedidos, ids } =
        useAprovacaoTransferencia();
    const [oprions, setOption] = useState<options[]>([]);
    const [motivoId, setMotivoId] = useState<number | null>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.get<Motivos[]>(
                    `pedido-credito/motivos`
                );

                setOption(
                    response.data.map((item) => {
                        return {
                            label: item.nome,
                            value: item.id,
                        };
                    })
                );
            } catch (error) {
                toast.error("Erro ao carregar motivos");
            }
        }

        fetchData();
    }, []);

    return (
        <motion.div
            animate={{ scale: [0.1, 1] }}
            transition={{ duration: 0.5 }}
        >
            <NovoModal
                handleClose={() => setModalReprovar(false)}
                open={modalReprovar}
            >
                <Container>
                    <div className="header">
                        <h3>confirmação</h3>
                        <button onClick={() => setModalReprovar(false)}>
                            <img src={imgX} alt="" />
                        </button>
                    </div>

                    <div className="container-img">
                        <img src={imgReprovar} alt="" />
                    </div>

                    <div className="container-text">
                        <h2>
                            Tem certeza que <br />
                            deseja{" "}
                            <strong className="reprovar">REPROVAR</strong> essas
                            cargas de crédito?
                        </h2>

                        <p>
                            Essa operação não poderá <br /> ser desfeita!
                        </p>

                        <Box width="300px" m="0 auto 32px">
                            <Select
                                tipo="cliente"
                                label="MOTIVO"
                                options={oprions}
                                onChange={(values) =>
                                    setMotivoId(parseInt(values.value + ""))
                                }
                            />
                        </Box>

                        <div className="btns">
                            <Button
                                disabled={!motivoId}
                                className="reprovar"
                                onClick={async () => {
                                    if (motivoId) {
                                        await reprovarPedidos({
                                            pedidos_creditos: ids,
                                            pedidos_creditos_motivos_reprove_id:
                                                motivoId,
                                        });

                                        setModalReprovar(false);
                                    }
                                }}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                SIM, REPROVAR
                            </Button>

                            <button onClick={() => setModalReprovar(false)}>
                                CANCELAR
                            </button>
                        </div>
                    </div>
                </Container>
            </NovoModal>
        </motion.div>
    );
}
