import styled from "styled-components";
import { NotificacaoStyles } from "../Notificacao/styles";
export const Container = styled.div`
    padding-top: 46px;
    .container {
        .container-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 53px;
        }

        .container-notificacoes {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            align-items: flex-start;
            grid-gap: 62px;
            ${NotificacaoStyles} {
                padding: 0px;
                border-bottom: none;
            }
        }
    }

    @media (max-width: 480px) {
        .container {
            .container-notificacoes {
                grid-template-columns: 1fr;
            }
        }
    }
`;
