import { Relatorio } from "../../../components/System/Relatorio";
import { RelatorioProvider } from "../../../Hook/system/useRelatorio";

export function PageRelatorio() {
    return (
        <RelatorioProvider>
            <Relatorio />
        </RelatorioProvider>
    );
}
