import { Flex } from "alisson-application";
import { Link } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { useUser } from "../../../Hook/user/UseUser";
import imgLogo from "../../../assets/img/logo.svg";
import imgNotificacao from "../../../assets/img/notificacao.svg";
import imgMenu from "../../../assets/img/hamburger.svg";

import { Container } from "./styles";

export function Header() {
    const { state, setState } = UseModal();
    const { user } = useUser();
    const hasnotification =
        user?.notificacoes?.filter(
            (item) => item.describe !== "__ACTIVE_MODAL_CREDITO__"
        ) || [];
    return (
        <Container className="container">
            <Link to="/">
                <img src={imgLogo} alt="" />
            </Link>

            <Flex width="64px" justifyContent="space-between">
                <button
                    className={hasnotification?.length ? "notificacao" : ""}
                    onClick={() =>
                        setState({
                            ...state,
                            notificacao: true,
                        })
                    }
                >
                    <img src={imgNotificacao} alt="" />
                </button>

                <button
                    onClick={() =>
                        setState({
                            ...state,
                            menu: true,
                        })
                    }
                >
                    <img src={imgMenu} alt="" />
                </button>
            </Flex>
        </Container>
    );
}
