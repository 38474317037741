import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { OverViewItens } from "./overview";
import { Empty } from "./empty";
import { Container } from "./styles";

export function OverView() {
    const { cartoes } = UseCartoes();

    return (
        <Container>
            {cartoes?.length === 0 ? <Empty /> : <OverViewItens />}
        </Container>
    );
}
