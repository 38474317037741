import { Text } from "alisson-application";
import { Container, ContainerItemStyles } from "./styles";

interface CardConfirmacaoProps {
    title: string;
    itens: CardItem[];
}

interface CardItem {
    title: string;
    text: string;
}

export function CardConfirmacao(props: CardConfirmacaoProps) {
    const { itens, title } = props;

    return (
        <Container>
            <h3>{title}</h3>
            {itens.map((item, key) => {
                if (!item.text) return <></>;

                return (
                    <ItemConfirmacao
                        key={key}
                        text={item.text}
                        title={item.title}
                    />
                );
            })}
        </Container>
    );
}

export function ItemConfirmacao(props: CardItem) {
    const { title, text } = props;
    return (
        <ContainerItemStyles>
            <Text color="secondary" bold>
                {title}
            </Text>
            <Text color="primaryDark" fontWeight="bold">
                {text}
            </Text>
        </ContainerItemStyles>
    );
}
