import React, { createContext, useContext } from "react";
import { AxiosResponse } from "axios";
import { api } from "../../services/";
import { toast } from "react-toastify";

interface UseCupomProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseCupom {
    cupomRequest(values: Cupom): Promise<AxiosResponse<any>>;
    checkCupom(cupom: string): Promise<AxiosResponse<CheckCupom>>;
}

const UseCupomContext = createContext<UseCupom>({} as UseCupom);

interface Cupom {
    cupom: string;
}

export interface CheckCupom {
    resource?: {
        id: number;
        cupom_id: number;
        campanha_id: number;
        user_id: null;
        codigo: string;
        validade: string;
        valor: string;
        created_at: string;
        updated_at: string;
    };
    success: boolean;
}

export function UseCupomProvider(props: UseCupomProps) {
    const { children } = props;

    async function cupomRequest(values: Cupom) {
        try {
            const response = await api.post<CheckCupom>("cupom/use", {
                ...values,
            });

            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu!");
            return error?.response;
        }
    }

    async function checkCupom(cupom: string) {
        try {
            const response = await api.post<CheckCupom>("cupom/exists", {
                cupom,
            });
            return response;
        } catch (error: any) {
            return error?.response;
        }
    }

    return (
        <UseCupomContext.Provider value={{ cupomRequest, checkCupom }}>
            {children}
        </UseCupomContext.Provider>
    );
}

export function useCupom() {
    const context = useContext(UseCupomContext);
    return context;
}
