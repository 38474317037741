import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { formatDate } from "../../../util/helper";
import Table from "../Table";
import { Header } from "../Header";
import { Data } from "./config";

import { Excluir } from "./excluir";
import { useTermoECondicoesDeUso } from "../../../Hook/system/TermoECondicoesDeUso";
import { Container } from "./styles";
// deleteTermo
export function TermoECondicoesDeUso() {
    const history = useHistory();
    const { termoDeUso } = useTermoECondicoesDeUso();

    const dados: Data[] = termoDeUso.map((termo) => {
        return {
            id: termo.id + "",
            titulo: termo.titulo,
            descricao: termo.descricao,
            criado_por: termo.user.name,
            data_hora: formatDate(termo.created_at),
            excluir: "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
        },
        {
            field: "titulo",
            headerName: "TÍTULO",
            width: 300,
        },
        {
            field: "descricao",
            headerName: "DESCRIÇÃO",
            width: 300,
        },
        {
            field: "criado_por",
            headerName: "CRIADO POR",
            width: 300,
        },
        {
            field: "data_hora",
            headerName: "DATA/HORA",
            width: 300,
        },
        {
            field: "excluir",
            headerName: "Excluir",
            width: 100,
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Excluir id={parseInt(row.id)} />;
            },
        },
    ];

    return (
        <Container>
            <Header
                title="TERMOS E POLÍTICA"
                titleBtn="ADICIONAR TERMOS OU POLÍTICA"
                onClickBtn={() =>
                    history.push("/sistema/termo-e-condicoes-de-uso/novo")
                }
                permission="faq.index"
            />

            <Table
                columns={columns}
                rows={dados ? dados : []}
                nameCSV="termos e condicoes de uso"
            />
        </Container>
    );
}
