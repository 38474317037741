import * as Yup from "yup";

export interface cupomI {
    cupom: string;
}
export const cupom: cupomI = {
    cupom: "",
};

export const validadeCumpom = Yup.object().shape({
    cupom: Yup.string().required("CUPOM INVÁLIDO"),
});
