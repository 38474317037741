import { AtivarStyles } from "./styles";
import imgCadeado from "../../../assets/img/cadeado-fundo-verde.svg";
import imgSolicitado from "../../../assets/img/cartao-processando.svg";
import imgCancelar from "../../../assets/img/cancelar-cartao.svg";

interface AtivarProps {
    number?: string;
}

export function OverlayActive(props: AtivarProps) {
    let { number } = props;
    let formtnumber = "";
    if (number) {
        number = number + "";
        formtnumber = `...${number.substring(
            number.length - 4,
            number.length
        )}`;
    }

    return (
        <AtivarStyles>
            <div className="card-status">
                <img src={imgCadeado} alt="" />
                {number && <h3>{formtnumber}</h3>}
                <p>ATIVAR CARTÃO</p>
            </div>
        </AtivarStyles>
    );
}

export function OverlayBlock(props: AtivarProps) {
    let { number } = props;
    let formtnumber = "";
    if (number) {
        number = number + "";
        formtnumber = `...${number.substring(
            number.length - 4,
            number.length
        )}`;
    }

    return (
        <AtivarStyles>
            <div className="card-status">
                <img src={imgCadeado} alt="" />
                {number && <h3>{formtnumber}</h3>}
                <span>DESBLOQUEAR CARTÃO</span>
            </div>
        </AtivarStyles>
    );
}

export function OverlayProcess(props: AtivarProps) {
    return (
        <AtivarStyles>
            <div className="card-status">
                <img src={imgSolicitado} alt="" />
                <h3>****</h3>
                <span className="solicitado">
                    CARTÃO <br /> SOLICITADO
                </span>
            </div>
        </AtivarStyles>
    );
}

export function OverlayCancelar(props: AtivarProps) {
    return (
        <AtivarStyles>
            <div className="card-status">
                <img src={imgCancelar} alt="" />
                <h3>****</h3>
                <span className="cancelar">
                    CARTÃO <br /> CANCELADO
                </span>
            </div>
        </AtivarStyles>
    );
}
