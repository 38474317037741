import styled from "styled-components";
import Dialog from "@mui/material/Dialog";

export const ModalStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-bottom": {
        ".MuiDialog-container": {
            alignItems: "end",
        },
    },
    "& .MuiDialog-container": {
        justifyContent: "end",
    },

    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "auto",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "24px 24px 0px 0px;",
        overflow: "unset",
        maxHeight: "calc(95% - 64px)",
        "& > div": {
            overflowY: "unset",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                "-webkit-appearance": "none",
            },
            "&::-webkit-scrollbar:vertical": {
                width: "5px",
            },
            "&::-webkit-scrollbar:horizontal": {
                height: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(89, 92, 118, 0.5)",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
        },
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "375px",
            minWidth: "auto",
            borderRadius: "10px",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
                justifyContent: "center",
            },
        },
    },
}));

export const ModalScrollStyles = styled(Dialog)(({ theme }) => ({
    "&.modal-bottom": {
        ".MuiDialog-container": {
            alignItems: "end",
        },
    },
    "& .MuiDialog-container": {
        justifyContent: "end",
    },

    "& .MuiPaper-root": {
        width: "100%",
        minWidth: "90%",
        height: "auto",
        margin: "0px",
        minHeight: "auto",
        boxShadow: "0px 0px 60px rgba(0, 0, 0, 0.05);",
        borderRadius: "24px 24px 0px 0px;",
        overflow: "auto",
        maxHeight: "calc(95% - 64px)",
        "& > div": {
            overflowY: "auto",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                "-webkit-appearance": "none",
            },
            "&::-webkit-scrollbar:vertical": {
                width: "5px",
            },
            "&::-webkit-scrollbar:horizontal": {
                height: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(89, 92, 118, 0.5)",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
        },
    },

    ".container": {
        width: "90%",
    },

    "@media (min-width: 768px)": {
        "& .MuiPaper-root": {
            width: "375px",
            minWidth: "auto",
            borderRadius: "10px",
        },

        "&.modal-bottom": {
            ".MuiDialog-container": {
                alignItems: "center",
                justifyContent: "center",
            },
        },
    },
}));

export const ValidadarStyles = styled.div`
    position: relative;
    .fechar-modal {
        position: absolute;
        top: -20px;
        right: -20px;
    }

    h3 {
        font-weight: 600;
        font-size: ${({ theme }) => theme.fonts.mdl};
        text-align: center;
        width: 240px;
        margin: 0 auto;
        margin-bottom: 23px;
    }

    p {
        width: 260px;
        margin: 0 auto;
        margin-bottom: 23px;
        text-align: center;
    }

    .container-timer {
        padding-top: 30px;
        margin-bottom: 23px;
        &.active {
        }
    }

    .container-submit {
        border-top: 1px solid #e1e2e3;
        padding-top: 23px;
        margin-top: 30px;

        button {
            border-radius: 8px;
        }
        &.disabled {
            margin-top: 0px;
            .container-tipo {
                .card {
                    cursor: not-allowed;
                    background: #e1e2e3;
                }
            }
        }
        p {
            padding-top: 30px;
            padding-bottom: 19px;
        }
    }

    .container-input {
        margin-bottom: 33px;
        input {
            font-style: normal;
            font-weight: bold;
            line-height: 100%;
            text-align: center;
            color: ${({ theme }) => theme.colors.primary};
            font-size: ${({ theme }) => theme.fonts.md};
            padding: 0;
        }
    }

    .container-tipo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        margin-bottom: 46px;
        .card {
            background: ${({ theme }) => theme.colors.secondary};
            height: 93px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            cursor: pointer;
        }
    }
`;
