import exclamation from "../../../assets/img/exclamation.svg"
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../../Mobile/ButtonSlim";
import { ButtonGoBack } from "../../Mobile/ButtonGoBack";
import { Container } from "../../Modais/ModalContainer/styles"

export const SecondStrikeModal = () => {
    const { handleClose, setState, state } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    function handleForgotPassword() {
        setState({
            ...state,
            esqueciSenha: true,
            avisoSenhaInvalida: false,
        });
    }

    return (
        <Container >
            <img className="img-close" src={exclamation} alt="" />

            <h2>ATENÇÃO!<br/>Dados incorretos</h2>
                <p className="strong">
                    Na próxima tentativa incorreta, <br />
                    seu acesso será bloqueado.{" "}
                </p>
                <p>
                    Caso não se lembre da senha, <br />
                    por favor, redefina-a.
                </p>
            <div className="buttonContainer">
                <Button onClick={handleForgotPassword}>
                    Redefinir Senha
                </Button>

                <ButtonGoBack onClick={handleCloseModal}>
                    Voltar
                </ButtonGoBack>
            </div>


        </Container>
    );
};
