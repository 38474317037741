import * as Yup from "yup";

export interface ModalFormProps {
    id?: string | number;
    nome: string;
    area: {
        label: string;
        value: string;
        id: number;
    };
    email: string;
    celular: string;
    telefone: string;
}

export const modalInitalValues: ModalFormProps = {
    nome: "",
    area: {
        label: "",
        value: "",
        id: 0,
    },
    email: "",
    celular: "",
    telefone: "",
};

export const validationModal = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    area: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .required("Campo Obrigatório"),
    email: Yup.string()
        .email("Insira um E-mail válido")
        .required("E-mail e um campo obrigatório"),
    celular: Yup.string().required("Campo Obrigatório"),
    telefone: Yup.string().required("Campo Obrigatório"),
});
