import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { HeaderPassos } from "../HeaderPassos";
import { UseModal } from "../../../Hook/Usemodal";
import { FormAlterarSenha } from "../FormAlterarSenha";
import { Validadar } from "../Validar";

import { Container } from "./styles";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
];

export function AlterarSenhaCartao() {
    const { setPassos, nextPasso, passos, dados, setDados } = usePassos();
    const { t } = useTranslation();
    const { alterarSenha } = UseCartoes();
    const { state, setState } = UseModal();

    const width = window.screen.width;
    const history = useHistory();
    const hasPath = history.location.pathname.includes("/alterar-senha-cartao");

    async function handleClick() {
        const responseStore = await alterarSenha(dados);

        if (responseStore?.status === 200) {
            if (width < 480) {
                history.goBack();
            }

            setState({
                ...state,
                atualizaSenha: false,
                successGeral: {
                    active: true,
                    title: "Senha",
                    message: "Senha alterada com sucesso!",
                },
            });
        } else {
            setPassos(arrPassos);
        }
    }

    function handleSubimit(values: any) {
        setDados(values);
        nextPasso(2);
    }

    useEffect(() => {
        if (state?.atualizaSenha || hasPath) {
            setPassos(arrPassos);
        }
    }, [setPassos, state, hasPath]);

    return (
        <Container>
            <HeaderPassos
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, novoCartao: false });
                        setPassos([]);
                    }
                }}
                title={t("Mudar senha")}
            />

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active ? (
                        <FormAlterarSenha handleSubimit={handleSubimit} />
                    ) : null}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active ? (
                        <Validadar handleClick={() => handleClick()} />
                    ) : null}
                </div>
            </div>
        </Container>
    );
}
