import { useHistory, HashRouterProps } from "react-router-dom";

import { MenuProps, Links } from "./types";
import { MenuStyles, Link } from "./styles";

import imgSair from "../../../assets/img/sistema/menu/sair.svg";
import { toast } from "react-toastify";

export default function Menu(props: MenuProps) {
    const { links, logo, sair } = props;
    const arrUnique: Links[] = Object.values(
        links.reduce((acc, cur) => Object.assign(acc, { [cur.label]: cur }), {})
    );

    const targetBlank = ["/sistema/backoffice"];
    const history = useHistory<HashRouterProps>();
    const path = history.location.pathname.replace("/", "");

    async function logout() {
        try {
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            toast.error("Erro ao sair do sistema");
        }
    }

    return (
        <MenuStyles>
            <div className="logo">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>

            <ul>
                {arrUnique.map((link, key) => {
                    const arrLinks = link.href.split("/");
                    return (
                        <li title={link.label} key={key}>
                            <Link
                                target={
                                    targetBlank.includes(link.href)
                                        ? "_blank"
                                        : ""
                                }
                                to={link.href}
                                icone={link.icon}
                                className={`${
                                    arrLinks.includes(
                                        path.replace("sistema/", "")
                                    )
                                        ? "active"
                                        : ""
                                }`}
                            ></Link>
                        </li>
                    );
                })}

                <li onClick={() => logout()}>
                    <button>
                        <img src={imgSair} alt="SAIR" title="SAIR" />
                    </button>
                </li>
            </ul>

            <div className="sair">{sair ? sair : null}</div>
        </MenuStyles>
    );
}
