import imgLogo from "../../assets/img/yetz-pay-logo.svg";
import imgX from "../../assets/img/x.svg";

import { Container } from "./styles";

interface HeaderModalProps {
    handleClick: () => void;
}

export function HeaderModal(props: HeaderModalProps) {
    const { handleClick } = props;

    return (
        <Container>
            <img src={imgLogo} alt="" />

            <button onClick={handleClick}>
                <img src={imgX} alt="" />
            </button>
        </Container>
    );
}
