import { memo } from "react";
import { Text } from "alisson-application";

import { useContex, ModalCreditoProvider } from "../Hook/modal";
import { useCredito } from "../Hook";

import { Modal } from "../../../../../Modal";
import { NovaCargaInfra } from "../Modal";

import imgSetaCima from "../../../../../../assets/img/seta-cima.svg";
import imgX from "../../../../../../assets/img/x.svg";

import { UploadStyles } from "./styles";

function Upload() {
    const { modalCredito, setNomeArquivoCredito, setModalCredito } =
        useContex();
    const { file, setFile, template } = useCredito();

    async function donwloadCsv() {
        const { data } = await template();
        if (data) {
            let csvContent = "data:text/csv;charset=utf-8," + data;
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }
    }

    return (
        <UploadStyles>
            <div className="container-text">
                <Text as="p">
                    USE O CAMPO AO LADO PARA INSERIR SUA CARGA DE CRÉDITO.
                    <button onClick={() => donwloadCsv()}>
                        <Text as="strong">BAIXAR TEMPLATE</Text>
                    </button>
                </Text>
            </div>

            <div className="container-input-file">
                <label htmlFor="file-credito">
                    <div className="container-text">
                        {file?.name ? (
                            <Text color="primaryDark" as="span" fontSize="xs">
                                {file.name}
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setNomeArquivoCredito("");
                                        setFile(null);
                                    }}
                                >
                                    <img src={imgX} alt="" />
                                </button>
                            </Text>
                        ) : (
                            <Text color="primaryDark" as="span" fontSize="xs">
                                Selecione o arquivo
                            </Text>
                        )}
                    </div>

                    <div
                        className={`container-img ${
                            file?.name ? "active" : ""
                        }`}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setModalCredito(true);
                        }}
                    >
                        <img src={imgSetaCima} alt="" />
                    </div>
                </label>

                <input
                    type="file"
                    id="file-credito"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                        setNomeArquivoCredito(
                            e.target.files?.length ? e.target.files[0].name : ""
                        );
                        setFile(
                            e.target.files?.length ? e.target.files[0] : null
                        );
                    }}
                />
            </div>

            <Modal component={NovaCargaInfra} active={modalCredito} />
        </UploadStyles>
    );
}

function WrapperUpload() {
    return (
        <ModalCreditoProvider>
            <Upload />
        </ModalCreditoProvider>
    );
}

export const UploadMemo = memo(WrapperUpload);
