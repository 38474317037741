import { useState, useContext, createContext } from "react";

interface ModalCartaoDeCredito {
    setModalCredito: (value: boolean) => void;
    modalCredito: boolean;

    setNomeArquivoCredito: (value: string) => void;
    nomeArquivoCredito: string;
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

const Context = createContext<ModalCartaoDeCredito>({} as ModalCartaoDeCredito);

export function ModalCreditoProvider(props: ProviderProps) {
    const { children } = props;
    const [modalCredito, setModalCredito] = useState(false);
    const [nomeArquivoCredito, setNomeArquivoCredito] = useState("");

    return (
        <Context.Provider
            value={{
                modalCredito,
                setModalCredito,
                nomeArquivoCredito,
                setNomeArquivoCredito,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
