import { HeaderStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { Header as HeaderUsuario } from "../../Header";
import { Button } from "../../Button/areas";
import imgUser from "../../../../assets/img/user.svg";
import imgSetaEsq from "../../../../assets/img/seta-esq-sistema.svg";
import { useUser } from "../../../../Hook/system/useUser";
import { formatDateIsoSystem } from "../../../../util/helper";

export function Header() {
    const { user } = useUser();
    const history = useHistory();
    const status = user?.status && user.status === 1;

    return (
        <HeaderStyles>
            <div className="container-goback">
                <button
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    <img src={imgSetaEsq} alt="" />
                    voltar
                </button>
                <HeaderUsuario
                    title={user?.name ? user?.name : ""}
                    image={user?.image ? user.image : imgUser}
                />
            </div>

            <div className="container-date">
                {user?.registered_at ? (
                    <>
                        <small>DATA DE CADASTRO </small>
                        <small>
                            {formatDateIsoSystem(
                                user?.registered_at ? user?.registered_at : ""
                            )}
                        </small>
                    </>
                ) : (
                    <></>
                )}
            </div>

            <div className="container-status">
                <small>STATUS</small>

                <Button className={`cadastro ${status ? "" : "ERROR"}`}>
                    {status ? "ATIVO" : "INATIVO"}
                </Button>
            </div>
        </HeaderStyles>
    );
}
