import { formatDateIso } from "../../util/helper";
import { FaleConosco } from "../../Hook/user/UseFaleConosco";
import imgCalendario from "../../assets/img/calendario.svg";
import imgAutoFalante from "../../assets/img/auto-falante.svg";
import { MensagensStyles } from "./styles";

interface MensageProps extends FaleConosco {}

export function Mensagen(props: MensageProps) {
    const { status, description, created_at } = props;
    return (
        <MensagensStyles>
            <div className="container-data">
                <img src={imgCalendario} alt="" />
                <p>DATA: {formatDateIso(created_at)}</p>
            </div>

            <div className="container-assunto">
                <img src={imgAutoFalante} alt="" />
                <p>{description}</p>
            </div>

            <div className="container-status">
                <h3>{status}</h3>
            </div>
        </MensagensStyles>
    );
}
