import { TableAprovar } from "./main/table";
import { AprovarProvider } from "./Hook";

export function Aprovar() {
    return (
        <AprovarProvider>
            <TableAprovar />
        </AprovarProvider>
    );
}
