import * as Yup from "yup";

export interface Myform {
    name: string;
    permissions: number[];
}
export const initalValues: Myform = {
    name: "",
    permissions: [],
};

export const validadeForm = Yup.object().shape({
    name: Yup.string().required("Campo Obrigatório"),
    permissions: Yup.array().of(Yup.number()).required("Campo Obrigatório"),
});
