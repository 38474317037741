import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Text } from "alisson-application";
import Select, { options } from "../../../NovoSelect";
import { useRelatorio } from "../../../../Hook/system/useRelatorio";
import { DateTime } from "../../DateTime";
import { Myform, initalValues, validadeForm } from "./config";
import { Button } from "../../Button";
import { formatDateTimeDb } from "../../../../util/helper";
import { FormStyles } from "./styles";

export function FormRelatorio() {
    const { create } = useRelatorio();

    const options: options[] = [
        {
            value: "consolidado",
            label: "CONSOLIDADO",
        },
        {
            value: "detalhado",
            label: "DETALHADO",
        },
    ];

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={create}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue } = props;

                    return (
                        <Form>
                            <div className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="title"
                                />
                                <ErrorMessage component="span" name="title" />
                            </div>

                            <Select
                                tipo="cliente"
                                label="TIPO"
                                options={options}
                                onChange={(values) => {
                                    setFieldValue("tipo", values.value);
                                }}
                            />
                            <ErrorMessage
                                component="span"
                                name="register_type"
                            />

                            <Text as="p" mb="8px" mt="16px">
                                PERÍODO
                            </Text>
                            <div className="container-date-time">
                                <DateTime
                                    initialValue={null}
                                    label="DATA DE INÍCIO"
                                    onChange={(dt_inicio) => {
                                        setFieldValue(
                                            "start",
                                            formatDateTimeDb(dt_inicio)
                                                .trim()
                                                .split(" ")[0]
                                        );
                                    }}
                                />

                                <DateTime
                                    initialValue={null}
                                    label="DATA DE FIM"
                                    onChange={(dt_fim) => {
                                        setFieldValue(
                                            "end",
                                            formatDateTimeDb(dt_fim)
                                                .trim()
                                                .split(" ")[0]
                                        );
                                    }}
                                />
                            </div>

                            <Button
                                isLoading={props.isSubmitting}
                                disabled={!props.isValid}
                            >
                                GERAR RELATÓRIO
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
