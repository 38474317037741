import { ModalStyles } from "./styles";
import { useHistory } from "react-router-dom";
import { Button } from "../../Mobile/Button";
export function Modal() {
    const history = useHistory();

    return (
        <ModalStyles>
            <p>
                Seu e-mail foi validado com sucesso! Agora você poderá receber
                as mensagens enviadas para seu YETZPAY.
            </p>

            <Button onClick={() => history.push("/dashboard")}>
                OK, IR PARA O LOGIN
            </Button>
        </ModalStyles>
    );
}
