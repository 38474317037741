import {
    useContext,
    createContext,
    useCallback,
    useState,
    useEffect,
} from "react";
import { toast } from "react-toastify";
import { api } from "../../services";

interface useSeproProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface Sepro {
    cnpj: string;
    cpf: string;
    mes: string;
    month: number;
    total: number;
    valor_estimado: string;
    year: number;
}

interface IuseSeProProps {
    loading: boolean;
    sepro: Sepro[];
}

const UseSeproContext = createContext<IuseSeProProps>({} as IuseSeProProps);

export function SeproProvider(props: useSeproProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [sepro, setSepro] = useState<Sepro[]>([]);

    const getSepro = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get<Sepro[]>(
                "reports/serpro-requests-count"
            );
            setSepro(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao carregar dados");
            setSepro([]);
        }
    }, []);

    useEffect(() => {
        getSepro();
    }, [getSepro]);

    return (
        <UseSeproContext.Provider value={{ loading, sepro }}>
            {children}
        </UseSeproContext.Provider>
    );
}

export function useSepro() {
    const context = useContext(UseSeproContext);
    return context;
}
