import { EmptyStyles } from "../styles";

interface EmptyProps {
    title: string;
}

export function Empty(props: EmptyProps) {
    return (
        <EmptyStyles>
            <div className="container-user"></div>
            <p>{props.title}</p>
        </EmptyStyles>
    );
}
