import { UploadFiles } from "../../../components/System/Uploads";

import { UploadFilesProvider } from "../../../Hook/system/useUploadFile";
export function PageUploads() {
    return (
        <UploadFilesProvider>
            <UploadFiles />
        </UploadFilesProvider>
    );
}
