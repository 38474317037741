import { Text, Flex, Box } from "alisson-application";
import { Permissions } from "../../Permission";
import { ModalFormProps } from "./Modal/config";
import { useContex } from "./Hook";
import ModalAlert from "../../ModalAlert";
import imgEditar from "../../../../assets/img/editar.svg";
import imgContato from "../../../../assets/img/contato-cinza.svg";
import imgCelular from "../../../../assets/img/celular-cinza.svg";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { ContatoStyles } from "./styles";

export function Contato(props: ModalFormProps) {
    const { area, celular, email, nome, telefone } = props;
    const {
        setContato,
        setModal,
        modalAlert,
        setModalAlert,
        deleteContato,
        setContatoId,
    } = useContex();

    async function handleDeleteContato() {
        setModalAlert(false);
        await deleteContato();
    }

    return (
        <>
            <ContatoStyles>
                <div className="pessoal">
                    <Box>
                        <Text as="p">{nome}</Text>
                        <Text as="span"> {area.label}</Text>
                    </Box>

                    <Flex width="120px" justifyContent="space-between">
                        <Permissions role="cliente.area.contato.update">
                            <button
                                type="button"
                                onClick={() => {
                                    setContato({} as ModalFormProps);
                                    setContato({ ...props });
                                    setModal(true);
                                }}
                            >
                                <img src={imgEditar} alt="" />
                                editar
                            </button>
                        </Permissions>

                        <Permissions role="cliente.area.contato.destroy">
                            <button
                                type="button"
                                onClick={() => {
                                    setModalAlert(true);
                                    setContato({} as ModalFormProps);
                                    setContato({ ...props });
                                }}
                            >
                                <img src={imgX} alt="" />
                            </button>
                        </Permissions>
                    </Flex>
                </div>
                <div className="contato">
                    <Text as="span">{email}</Text>

                    <Flex mt="16px">
                        <Flex alignItems="center">
                            <img src={imgContato} alt="" />
                            <Text as="p" ml="9px">
                                {telefone}
                            </Text>
                        </Flex>
                        <Flex alignItems="center" ml="48px">
                            <img src={imgCelular} alt="" />
                            <Text ml="13px" as="p" className="tel">
                                {celular}
                            </Text>
                        </Flex>
                    </Flex>
                </div>
            </ContatoStyles>
            <ModalAlert
                open={modalAlert}
                description="deseja realmente apagar este contato?"
                title="apagar contato"
                aceept={handleDeleteContato}
                reject={() => {
                    setModalAlert(false);
                    setContatoId(null);
                }}
                handleClose={() => {
                    setModalAlert(false);
                    setContato({} as ModalFormProps);
                }}
            />
        </>
    );
}
