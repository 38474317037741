import { Text, Box } from "alisson-application";
import { ModalStyles } from "../styles";
import { Button } from "../../../Button";
import { useContex } from "../Hook";

import imgXVerde from "../../../../../assets/img/x.svg";
import imgCadastroCliente from "../../../../../assets/img/sucess-cadastro-cliente.svg";

interface SucessProps {
    setSucess: (sucess: boolean) => void;
}

export function Sucess(props: SucessProps) {
    const { setSucess } = props;
    const { setModal } = useContex();

    return (
        <ModalStyles>
            <div className="container-header">
                <Text as="p">NOVO CLIENTE</Text>
                <button onClick={() => setModal(false)}>
                    <img src={imgXVerde} alt="" />
                </button>
            </div>

            <div className="container-sucess">
                <img src={imgCadastroCliente} alt="" />

                <Text as="h3">Contato editado com sucesso!</Text>

                <Text as="p">Você pode alterá-lo clicando em editar.</Text>

                <Box width="234px" mb="86px">
                    <Button
                        onClick={() => {
                            setModal(false);
                            setSucess(false);
                        }}
                    >
                        FINALIZAR
                    </Button>
                </Box>
            </div>
        </ModalStyles>
    );
}
