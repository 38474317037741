import { memo } from "react";
import { Route, Redirect } from "react-router-dom";

import { getToken } from "../../util/helper";
import { setToken } from "../../services/";

import { Header } from "../Mobile/Header";
import { Footer } from "../Desktop/Footer";
import { Header as HeaderDesktop } from "../../components/Desktop/Header";
import { routesProps } from "../../routes/types";

// import TemporaryDrawer from "../Menu";

function RouteWrapper(props: routesProps) {
    const { isPrivate = false, path } = props;
    const excludRoutes = ["/confirma-email/:id", "/codigo-de-barras"];

    const width = window.screen.width;

    const token = getToken("user");

    if (token) {
        setToken("user");
    }

    // rota privada sem token
    if (isPrivate && !token) {
        return <Redirect to="/" from={path} />;
    }

    // redirecionar rotas inexistentes
    if (path === "*" || path === "/") {
        return <Redirect to="/dashboard" from={path} />;
    }

    if (path === "/" && token) {
        return <Redirect to="/dashboard" from={path} />;
    }

    if (excludRoutes.includes(path)) {
        return <Route {...props} />;
    }

    return (
        <>
            {width <= 768 ? <Header /> : <HeaderDesktop />}

            <Route {...props} />

            <Footer />
        </>
    );
}

export default memo(RouteWrapper);
