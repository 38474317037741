import styled from "styled-components";

export const Container = styled.div`
    border: 1px solid #e1e2e3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 7px;
    .container-contador {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            width: 27px;
            height: 27px;
            background: ${(props) => props.theme.colors.backgroundAlt};
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;
