import { useTranslation } from "../../../contexts/Localization";
import imgEsq from "../../../assets/img/seta-esq-verde.svg";
import { Container } from "./styles";

interface HeaderPassosProps {
    title: string;
    handleClose: () => void;
}

export function HeaderModal(props: HeaderPassosProps) {
    const { handleClose, title } = props;
    const { t } = useTranslation();

    return (
        <Container
            onClick={() => {
                handleClose();
            }}
        >
            <img src={imgEsq} alt="" />
            <button>{t(title)}</button>
        </Container>
    );
}
