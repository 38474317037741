import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { HeaderModal } from "../Mobile/HeaderModal";
import { UseModal } from "../../Hook/Usemodal";
import { useUser, Campanhas as CampanhaType } from "../../Hook/user/UseUser";
import { CardCampanha } from "./Card";
import { Container } from "./styles";
import { Loader } from "../Loader";

export function Campanha() {
    const history = useHistory();
    const path = history.location.pathname;
    const [layoutDesktop, setLayoutDesktop] = useState(false);
    const { setState, state } = UseModal();
    const [campanhas, setCampanhas] = useState<CampanhaType[]>(
        [] as CampanhaType[]
    );

    const [loading, setLoading] = useState(false);

    const { getCampanhas } = useUser();
    const width = window.screen.width;

    const handleClose = () => {
        width < 480
            ? history.push("/dashboard")
            : setState({ ...state, campanha: false, menu: true });
    };

    useEffect(() => {
        if (path === "/campanhas" && width >= 768) {
            setLayoutDesktop(true);
        }
    }, [path, width]);

    useEffect(() => {
        async function fectData() {
            setLoading(true);
            const data = await getCampanhas();
            if (data) {
                setCampanhas(data);
            }
            setLoading(false);
        }

        fectData();
    }, [getCampanhas]);

    return (
        <Container className={`${layoutDesktop ? "container active" : ""}`}>
            {!layoutDesktop ? (
                <HeaderModal
                    title="Campanha"
                    handleClose={() => handleClose()}
                />
            ) : (
                <></>
            )}

            <div className="itens">
                {loading ? (
                    <div className="container-loading">
                        <Loader />
                    </div>
                ) : (
                    <></>
                )}

                {campanhas.map((campanha) => (
                    <CardCampanha key={campanha.id} {...campanha} />
                ))}
            </div>
        </Container>
    );
}
