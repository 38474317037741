export const loadingAnimation = [
    {
        v: "4.6.0",
        fr: 29.9700012207031,
        ip: 0,
        op: 49.0000019958109,
        w: 200,
        h: 200,
        nm: "loading_ring_medium",
        ddd: 0,
        assets: [],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "green ring 1",
                ks: {
                    o: { a: 0, k: 100 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.833], y: [0.833] },
                                o: { x: [0.167], y: [0.167] },
                                n: ["0p833_0p833_0p167_0p167"],
                                t: 0,
                                s: [0],
                                e: [360],
                            },
                            { t: 49.0000019958109 },
                        ],
                    },
                    p: { a: 0, k: [100, 100, 0] },
                    a: { a: 0, k: [0, 0, 0] },
                    s: { a: 0, k: [200, 200, 100] },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [54, 54] },
                                p: { a: 0, k: [0, 0] },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.3215686274509804, 0.38823529411764707,
                                        0.8156862745098039, 1,
                                    ],
                                },
                                o: { a: 0, k: 100 },
                                w: { a: 0, k: 6 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        mn: "ADBE Vector Group",
                    },
                    {
                        ty: "tm",
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.644], y: [0] },
                                    n: ["0p667_1_0p644_0"],
                                    t: 10,
                                    s: [0],
                                    e: [100],
                                },
                                { t: 50.0000020365418 },
                            ],
                            ix: 1,
                        },
                        e: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [0.333], y: [0] },
                                    n: ["0p667_1_0p333_0"],
                                    t: -1,
                                    s: [0],
                                    e: [100],
                                },
                                { t: 37.0000015070409 },
                            ],
                            ix: 2,
                        },
                        o: { a: 0, k: 0, ix: 3 },
                        m: 1,
                        ix: 2,
                        nm: "Trim Paths 1",
                        mn: "ADBE Vector Filter - Trim",
                    },
                ],
                ip: 0,
                op: 50.0000020365418,
                st: -1.00000004073083,
                bm: 0,
                sr: 1,
            },
            {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "flamingo ring 3",
                parent: 1,
                ks: {
                    o: { a: 0, k: 100 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.785], y: [1] },
                                o: { x: [1], y: [0] },
                                n: ["0p785_1_1_0"],
                                t: 17,
                                s: [14.2],
                                e: [360],
                            },
                            { t: 50.0000020365418 },
                        ],
                    },
                    p: { a: 0, k: [0, 0, 0] },
                    a: { a: 0, k: [0, 0, 0] },
                    s: { a: 0, k: [100, 100, 100] },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [54, 54] },
                                p: { a: 0, k: [0, 0] },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.3176470588235294, 0.796078431372549,
                                        1, 1,
                                    ],
                                },
                                o: { a: 0, k: 100 },
                                w: { a: 0, k: 6 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        mn: "ADBE Vector Group",
                    },
                    {
                        ty: "tm",
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    n: ["0p833_0p833_0p167_0p167"],
                                    t: 42,
                                    s: [0],
                                    e: [1],
                                },
                                { t: 44.0000017921567 },
                            ],
                            ix: 1,
                        },
                        e: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [1] },
                                    o: { x: [0.333], y: [0] },
                                    n: ["0p833_1_0p333_0"],
                                    t: 20,
                                    s: [0],
                                    e: [1],
                                },
                                { t: 44.0000017921567 },
                            ],
                            ix: 2,
                        },
                        o: { a: 0, k: 0, ix: 3 },
                        m: 1,
                        ix: 2,
                        nm: "Trim Paths 1",
                        mn: "ADBE Vector Filter - Trim",
                    },
                ],
                ip: 17.0000006924242,
                op: 44.0000017921567,
                st: -1.00000004073083,
                bm: 0,
                sr: 1,
            },
            {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "flamingo ring 2",
                parent: 1,
                ks: {
                    o: { a: 0, k: 100 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.612], y: [1] },
                                o: { x: [1], y: [0] },
                                n: ["0p612_1_1_0"],
                                t: 17,
                                s: [14.2],
                                e: [360],
                            },
                            { t: 50.0000020365418 },
                        ],
                    },
                    p: { a: 0, k: [0, 0, 0] },
                    a: { a: 0, k: [0, 0, 0] },
                    s: { a: 0, k: [100, 100, 100] },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [54, 54] },
                                p: { a: 0, k: [0, 0] },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.3176470588235294, 0.796078431372549,
                                        1, 1,
                                    ],
                                },
                                o: { a: 0, k: 100 },
                                w: { a: 0, k: 6 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        mn: "ADBE Vector Group",
                    },
                    {
                        ty: "tm",
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [0.833] },
                                    o: { x: [0.167], y: [0.167] },
                                    n: ["0p833_0p833_0p167_0p167"],
                                    t: 42,
                                    s: [0],
                                    e: [13.7],
                                },
                                { t: 44.0000017921567 },
                            ],
                            ix: 1,
                        },
                        e: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.833], y: [1] },
                                    o: { x: [0.333], y: [0] },
                                    n: ["0p833_1_0p333_0"],
                                    t: 20,
                                    s: [0],
                                    e: [13.7],
                                },
                                { t: 44.0000017921567 },
                            ],
                            ix: 2,
                        },
                        o: { a: 0, k: 0, ix: 3 },
                        m: 1,
                        ix: 2,
                        nm: "Trim Paths 1",
                        mn: "ADBE Vector Filter - Trim",
                    },
                ],
                ip: 17.0000006924242,
                op: 44.0000017921567,
                st: -1.00000004073083,
                bm: 0,
                sr: 1,
            },
            {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "flaming ring 1",
                parent: 1,
                ks: {
                    o: { a: 0, k: 100 },
                    r: { a: 0, k: 0 },
                    p: { a: 0, k: [0, 0, 0] },
                    a: { a: 0, k: [0, 0, 0] },
                    s: { a: 0, k: [100, 100, 100] },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [54, 54] },
                                p: { a: 0, k: [0, 0] },
                                nm: "Ellipse Path 1",
                                mn: "ADBE Vector Shape - Ellipse",
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.3176470588235294, 0.796078431372549,
                                        1, 1,
                                    ],
                                },
                                o: { a: 0, k: 100 },
                                w: { a: 0, k: 6 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                nm: "Stroke 1",
                                mn: "ADBE Vector Graphic - Stroke",
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Ellipse 1",
                        np: 3,
                        mn: "ADBE Vector Group",
                    },
                    {
                        ty: "tm",
                        s: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [1], y: [0] },
                                    n: ["0p667_1_1_0"],
                                    t: 8,
                                    s: [0],
                                    e: [100],
                                },
                                { t: 48.0000019550801 },
                            ],
                            ix: 1,
                        },
                        e: {
                            a: 1,
                            k: [
                                {
                                    i: { x: [0.667], y: [1] },
                                    o: { x: [1], y: [0] },
                                    n: ["0p667_1_1_0"],
                                    t: -1,
                                    s: [0],
                                    e: [100],
                                },
                                { t: 37.0000015070409 },
                            ],
                            ix: 2,
                        },
                        o: { a: 0, k: 0, ix: 3 },
                        m: 1,
                        ix: 2,
                        nm: "Trim Paths 1",
                        mn: "ADBE Vector Filter - Trim",
                    },
                ],
                ip: 15.0000006109625,
                op: 44.0000017921567,
                st: -1.00000004073083,
                bm: 0,
                sr: 1,
            },
        ],
    },

    {
        v: "5.6.3",
        fr: 60,
        ip: 0,
        op: 300,
        w: 1000,
        h: 1000,
        nm: "Astronaut - the basis",
        ddd: 0,
        assets: [],
        layers: [
            {
                ddd: 0,
                ind: 1,
                ty: 4,
                nm: "Note 6",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0.9, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 186,
                                s: [151.293, -221.941, 0],
                                to: [3.156, -62.958, 0],
                                ti: [3.016, 52.054, 0],
                            },
                            { t: 300, s: [250.167, -340.402, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 186,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 206,
                                s: [-100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 224,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 251,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 275,
                                s: [-100, 100, 100],
                            },
                            { t: 300, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 186,
                op: 300,
                st: 186,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "Note 4",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0.009, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 101,
                                s: [201.692, -174.765, 0],
                                to: [2.177, -63, 0],
                                ti: [3.825, 52.001, 0],
                            },
                            { t: 215, s: [298.712, -294.749, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 101,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 121,
                                s: [-100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 139,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 166,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 190,
                                s: [-100, 100, 100],
                            },
                            { t: 215, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 101,
                op: 215,
                st: 101,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "Note 2",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0.891, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 50,
                                s: [193.596, -207.351, 0],
                                to: [3.146, -62.959, 0],
                                ti: [3.024, 52.053, 0],
                            },
                            { t: 164, s: [292.451, -325.828, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 50,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 70,
                                s: [-100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 88,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 115,
                                s: [-80, 80, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 139,
                                s: [-100, 100, 100],
                            },
                            { t: 164, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 50,
                op: 164,
                st: 50,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "Note 5",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: -0.9, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 166,
                                s: [-195.769, -151.338, 0],
                                to: [-3.156, -62.958, 0],
                                ti: [-3.016, 52.054, 0],
                            },
                            { t: 280, s: [-294.642, -269.799, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 166,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 186,
                                s: [120, 120, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 204,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 231,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 255,
                                s: [120, 120, 100],
                            },
                            { t: 280, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 166,
                op: 280,
                st: 166,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 5,
                ty: 4,
                nm: "Note 3",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: -0.891, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 102,
                                s: [-219.024, -144.948, 0],
                                to: [-3.146, -62.959, 0],
                                ti: [-3.024, 52.053, 0],
                            },
                            { t: 216, s: [-346.059, -248.735, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 102,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 122,
                                s: [120, 120, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 140,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 167,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 191,
                                s: [120, 120, 100],
                            },
                            { t: 216, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 102,
                op: 216,
                st: 102,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 6,
                ty: 4,
                nm: "Note",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.833, y: 0.833 },
                                o: { x: 0.167, y: 0.167 },
                                t: 0,
                                s: [-191.646, -175.011, 0],
                                to: [-2.167, -63, 0],
                                ti: [-3.833, 52, 0],
                            },
                            { t: 114, s: [-288.646, -295.011, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [-201.146, -119.011, 0], ix: 1 },
                    s: {
                        a: 1,
                        k: [
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 0,
                                s: [0, 0, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 20,
                                s: [120, 120, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 38,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 65,
                                s: [100, 100, 100],
                            },
                            {
                                i: {
                                    x: [0.833, 0.833, 0.833],
                                    y: [0.833, 0.833, 0.833],
                                },
                                o: {
                                    x: [0.167, 0.167, 0.167],
                                    y: [0.167, 0.167, 0.167],
                                },
                                t: 89,
                                s: [120, 120, 100],
                            },
                            { t: 114, s: [0, 0, 100] },
                        ],
                        ix: 6,
                    },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [-2.5, 6.25],
                                            [13.75, -5.125],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [0, 0],
                                            [2.5, -6.25],
                                            [-0.875, -0.25],
                                        ],
                                        v: [
                                            [-208, -159.25],
                                            [-234.625, -154.5],
                                            [-231.375, -143],
                                            [-228.875, -148.125],
                                            [-213.625, -150.5],
                                            [-215.375, -116.5],
                                            [-196, -116],
                                            [-210.25, -123],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039223166, 0.047058827269,
                                        0.137254901961, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 114,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 7,
                ty: 4,
                nm: "Headphone",
                parent: 11,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-14.854, -217.005, 0], ix: 2 },
                    a: { a: 0, k: [-15.604, -217.005, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [21, -72.5],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-21, 72.5],
                                        ],
                                        v: [
                                            [-59.5, -319.5],
                                            [-138, -233.75],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 1,
                                ty: "sh",
                                ix: 2,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [22.931, -43.516],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-23.074, 43.787],
                                        ],
                                        v: [
                                            [147.198, -212.282],
                                            [143.955, -117.967],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 2",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 2,
                                ty: "sh",
                                ix: 3,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [5.429, -13.29],
                                            [-17.245, -22.949],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-6.637, 16.247],
                                            [27.972, 37.225],
                                        ],
                                        v: [
                                            [-145.313, -210.875],
                                            [-160.054, -193.523],
                                            [-153.438, -126.563],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 3",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 4,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [98.726, -2.974],
                                            [5.028, -34.972],
                                            [0, -22.923],
                                            [-16.842, 0],
                                            [0, 26.51],
                                            [10.188, 7.772],
                                            [-104.692, -11.207],
                                            [-11.403, -32.145],
                                        ],
                                        o: [
                                            [-10.934, -41.265],
                                            [-105.809, 3.187],
                                            [-13.651, 4.768],
                                            [0, 26.51],
                                            [16.842, 0],
                                            [0, -19.016],
                                            [11.244, -36.835],
                                            [62.387, 6.678],
                                            [0, 0],
                                        ],
                                        v: [
                                            [134.4, -236.115],
                                            [-8.341, -328.98],
                                            [-142.245, -212.357],
                                            [-166.107, -165.508],
                                            [-135.612, -117.506],
                                            [-105.117, -165.508],
                                            [-122.33, -208.726],
                                            [30.639, -316.624],
                                            [131.133, -240.516],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 1,
                                ty: "sh",
                                ix: 2,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-1.405, 0.692],
                                            [0, 21.513],
                                            [10.661, 7.48],
                                            [0.684, -23.47],
                                        ],
                                        o: [
                                            [12.39, -6.1],
                                            [0, -19.559],
                                            [-1.94, -1.361],
                                            [-0.653, 22.38],
                                        ],
                                        v: [
                                            [144.726, -119.686],
                                            [166.107, -165.508],
                                            [148.015, -209.372],
                                            [155.038, -163.36],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 2",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Arc",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-21.26, 0],
                                            [0, -33.465],
                                            [21.26, 0],
                                            [0, 33.465],
                                        ],
                                        o: [
                                            [21.26, 0],
                                            [0, 33.465],
                                            [-21.26, 0],
                                            [0, -33.465],
                                        ],
                                        v: [
                                            [0, -60.594],
                                            [38.495, 0],
                                            [0, 60.594],
                                            [-38.495, 0],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-114.945, -165.508], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Eye",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 3,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 8,
                ty: 4,
                nm: "R_arm",
                parent: 9,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 8.615, ix: 10 },
                    p: { a: 0, k: [-70.026, -13.524, 0], ix: 2 },
                    a: { a: 0, k: [-70.026, -13.524, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [1.506, -50.563],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-1.825, 61.267],
                                        ],
                                        v: [
                                            [-50.944, -8.5],
                                            [-81.506, 80.563],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 3, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [-20.051, 4.199],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [14.625, -3.063],
                                            [0, 0],
                                        ],
                                        v: [
                                            [-122.039, 124.954],
                                            [-97.784, 136.447],
                                            [-84.515, 114.202],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 1,
                                ty: "sh",
                                ix: 2,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [1.506, -50.563],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-1.825, 61.267],
                                        ],
                                        v: [
                                            [-94.444, 4.5],
                                            [-125.006, 93.563],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 2",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-119, 52], ix: 2 },
                                a: { a: 0, k: [-116, 46], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: -8, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare 2",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [6.348, -35.492],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-6.348, 35.492],
                                            [0, 0],
                                        ],
                                        v: [
                                            [-72.994, -10.939],
                                            [-98.8, 58.997],
                                            [-97.053, 121.069],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 40, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Arm",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 3,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 9,
                ty: 4,
                nm: "R_joint",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [18.288],
                            },
                            { t: 299, s: [0] },
                        ],
                        ix: 10,
                    },
                    p: { a: 0, k: [-68.714, -13.991, 0], ix: 2 },
                    a: { a: 0, k: [-68.714, -13.991, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [47, 47], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [-68.714, -13.991], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 10,
                ty: 4,
                nm: "R_leg",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [29.813],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [24.475],
                            },
                            { t: 299, s: [29.813] },
                        ],
                        ix: 10,
                    },
                    p: { a: 0, k: [-25.712, 123.249, 0], ix: 2 },
                    a: { a: 0, k: [47.788, 114.249, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [8.5, -35.5],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-8.5, 35.5],
                                        ],
                                        v: [
                                            [77, 143],
                                            [78, 217],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [-0.394, -32.321],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.394, 32.321],
                                            [0, 0],
                                        ],
                                        v: [
                                            [47.844, 115.605],
                                            [61.722, 181.542],
                                            [49.755, 235.893],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 40, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Leg",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 11,
                ty: 4,
                nm: "Head",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [-7.447],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 46,
                                s: [6.522],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 98,
                                s: [-15.12],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [6.522],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 202,
                                s: [-15.12],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 250,
                                s: [6.522],
                            },
                            { t: 299, s: [-7.447] },
                        ],
                        ix: 10,
                    },
                    p: { a: 0, k: [-15.194, -36, 0], ix: 2 },
                    a: { a: 0, k: [-15.194, -36, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 27.338],
                                            [3.028, 0],
                                            [0, -27.338],
                                            [-3.028, 0],
                                        ],
                                        o: [
                                            [0, -27.338],
                                            [-3.028, 0],
                                            [0, 27.338],
                                            [3.028, 0],
                                        ],
                                        v: [
                                            [-12.137, -168.5],
                                            [-3.963, -218],
                                            [-23.103, -168.5],
                                            [-3.963, -119],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [75.111, 0],
                                            [10.461, 64.713],
                                            [0, 7.445],
                                            [-75.111, 0],
                                        ],
                                        o: [
                                            [-67.666, 0],
                                            [-1.151, -7.12],
                                            [0, -75.111],
                                            [75.111, 0],
                                        ],
                                        v: [
                                            [0, 136],
                                            [-134.251, 21.87],
                                            [-136, 0],
                                            [0, -136],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [10.556, -168], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare 2",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 2,
                                ty: "el",
                                s: { a: 0, k: [193, 189], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [58.556, -168], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Glass",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 3,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [221.593, 217], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [43.056, -168.501], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Visor",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 4,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [272, 272], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [10.556, -168], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Helmet",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 5,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 12,
                ty: 4,
                nm: "Body",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [4.468],
                            },
                            { t: 299, s: [0] },
                        ],
                        ix: 10,
                    },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.667, y: 1 },
                                o: { x: 0.333, y: 0 },
                                t: 0,
                                s: [504.762, 701.25, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.667, y: 1 },
                                o: { x: 0.333, y: 0 },
                                t: 150,
                                s: [504.762, 599.25, 0],
                                to: [0, 0, 0],
                                ti: [0, 0, 0],
                            },
                            { t: 299, s: [504.762, 701.25, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [4.762, 109.25, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [86, -48],
                                        ],
                                        o: [
                                            [21.5, 12.5],
                                            [-78.583, 43.86],
                                        ],
                                        v: [
                                            [73.556, -30],
                                            [33.296, 135.818],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0.5, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.65, 2.501],
                                            [2.696, 12.812],
                                            [0, 6.674],
                                            [25.957, 0],
                                            [0, -9.941],
                                            [-25.957, 0],
                                            [-0.573, 0.008],
                                            [-2.563, -15.658],
                                            [-2.391, -0.226],
                                            [0, 0],
                                        ],
                                        o: [
                                            [3.456, -13.297],
                                            [14.088, -3.108],
                                            [0, -9.941],
                                            [-25.957, 0],
                                            [0, 9.941],
                                            [0.578, 0],
                                            [3.248, 7.564],
                                            [0.388, 2.37],
                                            [0, 0],
                                            [2.572, 0.243],
                                        ],
                                        v: [
                                            [29.728, 16.979],
                                            [32.477, -23.897],
                                            [56.056, -39.5],
                                            [9.056, -57.5],
                                            [-37.944, -39.5],
                                            [9.056, -21.5],
                                            [10.78, -21.517],
                                            [19.405, 16.475],
                                            [24.121, 20.884],
                                            [24.121, 20.884],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Collar",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 64.069],
                                            [49.153, 0],
                                            [0, -64.069],
                                            [-49.153, 0],
                                        ],
                                        o: [
                                            [0, -64.069],
                                            [-49.153, 0],
                                            [0, 64.069],
                                            [49.153, 0],
                                        ],
                                        v: [
                                            [93.556, 25.993],
                                            [4.556, -47],
                                            [-84.444, 25.993],
                                            [4.556, 142],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 4,
                                },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Torso",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 3,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 13,
                ty: 4,
                nm: "L_joint",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [0],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [-13.413],
                            },
                            { t: 299, s: [0] },
                        ],
                        ix: 10,
                    },
                    p: { a: 0, k: [73.286, -13.991, 0], ix: 2 },
                    a: { a: 0, k: [73.286, -13.991, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                d: 1,
                                ty: "el",
                                s: { a: 0, k: [48.536, 47], ix: 2 },
                                p: { a: 0, k: [0, 0], ix: 3 },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Ellipse",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [73.286, -13.991], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 14,
                ty: 4,
                nm: "L_arm",
                parent: 13,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: -7.895, ix: 10 },
                    p: { a: 0, k: [79.568, -10.46, 0], ix: 2 },
                    a: { a: 0, k: [79.568, -10.46, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [-17.625, -1.5],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [17.625, 1.5],
                                            [0, 0],
                                        ],
                                        v: [
                                            [80.125, 103],
                                            [96.875, 124.25],
                                            [118.25, 111],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ind: 1,
                                ty: "sh",
                                ix: 2,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [2, -39],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-2, 39],
                                        ],
                                        v: [
                                            [110.5, 5.5],
                                            [125, 80],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path 1",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [-5.298, -31.06],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [5.298, 31.06],
                                            [0, 0],
                                        ],
                                        v: [
                                            [79.77, -10.939],
                                            [103.419, 49.886],
                                            [99.924, 104.57],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 40, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 15,
                ty: 4,
                nm: "L_leg",
                parent: 12,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: {
                        a: 1,
                        k: [
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 0,
                                s: [19.732],
                            },
                            {
                                i: { x: [0.667], y: [1] },
                                o: { x: [0.333], y: [0] },
                                t: 150,
                                s: [-4.717],
                            },
                            { t: 299, s: [19.732] },
                        ],
                        ix: 10,
                    },
                    p: { a: 0, k: [47.788, 114.249, 0], ix: 2 },
                    a: { a: 0, k: [47.788, 114.249, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [8.5, -35.5],
                                        ],
                                        o: [
                                            [0, 0],
                                            [-8.5, 35.5],
                                        ],
                                        v: [
                                            [77, 143],
                                            [78, 217],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 2, ix: 5 },
                                lc: 1,
                                lj: 1,
                                ml: 4,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Flare",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [0, 0],
                                            [-0.394, -32.321],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.394, 32.321],
                                            [0, 0],
                                        ],
                                        v: [
                                            [47.844, 115.605],
                                            [61.722, 181.542],
                                            [49.755, 235.893],
                                        ],
                                        c: false,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 40, ix: 5 },
                                lc: 2,
                                lj: 1,
                                ml: 10,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Leg",
                        np: 2,
                        cix: 2,
                        bm: 0,
                        ix: 2,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 16,
                ty: 4,
                nm: "Star 6",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [225.714, -60, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [114.286, 114.286, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 17,
                ty: 4,
                nm: "Star 8",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-51.429, 348.571, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 18,
                ty: 4,
                nm: "Star 5",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-737.143, -91.429, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [71.429, 71.429, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 19,
                ty: 4,
                nm: "Star 4",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-594.286, 520, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [142.857, 142.857, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 20,
                ty: 4,
                nm: "Star 7",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-902.857, 1028.571, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [71.429, 71.429, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 21,
                ty: 4,
                nm: "Star 3",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-62.857, 868.571, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [142.857, 142.857, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 22,
                ty: 4,
                nm: "Star 2",
                parent: 23,
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: { a: 0, k: [-777.143, 617.143, 0], ix: 2 },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
            {
                ddd: 0,
                ind: 23,
                ty: 4,
                nm: "Star",
                sr: 1,
                ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                        a: 1,
                        k: [
                            {
                                i: { x: 0.667, y: 1 },
                                o: { x: 0.333, y: 0 },
                                t: 0,
                                s: [768.5, 190.5, 0],
                                to: [0, 6, 0],
                                ti: [0, 0, 0],
                            },
                            {
                                i: { x: 0.667, y: 1 },
                                o: { x: 0.333, y: 0 },
                                t: 150,
                                s: [768.5, 226.5, 0],
                                to: [0, 0, 0],
                                ti: [0, 6, 0],
                            },
                            { t: 299, s: [768.5, 190.5, 0] },
                        ],
                        ix: 2,
                    },
                    a: { a: 0, k: [0, 0, 0], ix: 1 },
                    s: { a: 0, k: [70, 70, 100], ix: 6 },
                },
                ao: 0,
                shapes: [
                    {
                        ty: "gr",
                        it: [
                            {
                                ind: 0,
                                ty: "sh",
                                ix: 1,
                                ks: {
                                    a: 0,
                                    k: {
                                        i: [
                                            [-0.738, 0],
                                            [0, 0],
                                            [0, 0],
                                            [1.336, 0.97],
                                            [0.189, 0.08],
                                            [0, 0],
                                            [0.597, 0.434],
                                            [0.026, 0.038],
                                            [0, 0],
                                            [0, 0],
                                        ],
                                        o: [
                                            [0, 0],
                                            [0.011, 0.101],
                                            [-0.597, 0.434],
                                            [0, 0],
                                            [0, 0],
                                            [-1.336, 0.97],
                                            [0, 0],
                                            [0, 0],
                                            [0.738, 0],
                                            [1.683, 3.07],
                                        ],
                                        v: [
                                            [3.873, -3.726],
                                            [13.677, -1.913],
                                            [7.038, 3.562],
                                            [8.241, 12.079],
                                            [0.436, 8.607],
                                            [-7.554, 12.016],
                                            [-6.038, 3.562],
                                            [-13.026, -3.163],
                                            [-3.936, -3.601],
                                            [-0.308, -12.633],
                                        ],
                                        c: true,
                                    },
                                    ix: 2,
                                },
                                nm: "Path",
                                mn: "ADBE Vector Shape - Group",
                                hd: false,
                            },
                            {
                                ty: "st",
                                c: {
                                    a: 0,
                                    k: [
                                        0.098039217293, 0.04705882445,
                                        0.137254908681, 1,
                                    ],
                                    ix: 3,
                                },
                                o: { a: 0, k: 100, ix: 4 },
                                w: { a: 0, k: 5, ix: 5 },
                                lc: 1,
                                lj: 2,
                                bm: 0,
                                nm: "Stroke",
                                mn: "ADBE Vector Graphic - Stroke",
                                hd: false,
                            },
                            {
                                ty: "fl",
                                c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                                o: { a: 0, k: 100, ix: 5 },
                                r: 1,
                                bm: 0,
                                nm: "Fill",
                                mn: "ADBE Vector Graphic - Fill",
                                hd: false,
                            },
                            {
                                ty: "tr",
                                p: { a: 0, k: [0, 0], ix: 2 },
                                a: { a: 0, k: [0, 0], ix: 1 },
                                s: { a: 0, k: [100, 100], ix: 3 },
                                r: { a: 0, k: 0, ix: 6 },
                                o: { a: 0, k: 100, ix: 7 },
                                sk: { a: 0, k: 0, ix: 4 },
                                sa: { a: 0, k: 0, ix: 5 },
                                nm: "Transform",
                            },
                        ],
                        nm: "Group",
                        np: 3,
                        cix: 2,
                        bm: 0,
                        ix: 1,
                        mn: "ADBE Vector Group",
                        hd: false,
                    },
                ],
                ip: 0,
                op: 302,
                st: 0,
                bm: 0,
            },
        ],
        markers: [],
    },
];
