import * as Yup from "yup";

export interface ModalFormProps {
    nome_arq: string;
    nome: string;
    observacao: string;
    job: string;
    cliente_area_contato_id: string;
}

export const modalInfra: ModalFormProps = {
    nome_arq: "",
    nome: "",
    job: "",
    cliente_area_contato_id: "",
    observacao: "",
};

export const validationModalInfra = Yup.object().shape({
    nome_arq: Yup.string().required("Campo Obrigatório"),
    nome: Yup.string().required("Campo Obrigatório"),
    cliente_area_contato_id: Yup.string().required("Campo Obrigatório"),
    observacao: Yup.string().required("Campo Obrigatório"),
    job: Yup.string(),
});
