import exclamation from "../../../assets/img/exclamation.svg"
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../../Mobile/ButtonSlim";
import { ButtonGoBack } from "../../Mobile/ButtonGoBack";
import { Container } from "../../Modais/ModalContainer/styles"

export const ThirdStrikeModal = () => {
    const { handleClose, setState, state } = UseModal();

    function handleCloseModal() {
        handleClose("avisoSenhaInvalida");
    }

    function handleForgotPassword() {
        setState({
            ...state,
            esqueciSenha: true,
            avisoSenhaInvalida: false,
        });
    }

    return (
        <Container >
            <img className="img-close" src={exclamation} alt="" />

            <h2>Seu Acesso<br/>
                foi Bloqueado!
            </h2>

            <p className="strong">
                Você excedeu o número máximo de <br/>tentativas de login ou
                identificamos alguma inconsistência. Por questões de segurança,<br/>
                sua conta foi bloqueada.
            </p>

            <p>
                Clique no botão abaixo e redefina<br/>sua senha para recuperar o
                acesso
            </p>

            <div className="buttonContainer">
                <Button onClick={handleForgotPassword}>
                    Redefinir Senha
                </Button>

                <ButtonGoBack onClick={handleCloseModal}>
                    Voltar
                </ButtonGoBack>
            </div>
        </Container>
    );
};
