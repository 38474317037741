import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 32px;
    width: 90%;
    margin: 0 auto;
    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        margin-top: 56px;
    }
`;

export const MensagensStyles = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    padding: 26px 29px;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 16px;
    .container-data,
    .container-assunto {
        display: flex;
        align-items: start;
        border-bottom: 1px solid #e1e2e3;
        padding-bottom: 16px;
        padding-top: 16px;
    }

    img {
        display: block;
        margin-right: 8px;
        width: 21px;
    }

    .container-status {
        padding-top: 16px;
        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;

export const FormStyles = styled.div`
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primaryDark};
        width: 309px;
        margin: 0 auto;
        margin-bottom: 32px;
        &.erro-file {
            margin-top: 12px;
            margin-bottom: 19px;
            color: ${(props) => props.theme.colors.primaryDark};
            font-size: 12px;
        }
    }

    textarea {
        border-radius: 10px;
    }

    h5 {
        color: ${(props) => props.theme.colors.secondary};
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 144.5%; /* 17.34px */
        letter-spacing: 0.96px;
        width: 303px;
        strong {
            color: ${(props) => props.theme.colors.warning};
        }
    }

    .arquivo {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        img {
            width: 16px;
            margin-right: 8px;
        }
        p {
            margin: 0px !important;
            width: auto !important;
        }
    }

    textarea {
        height: 110px !important;
    }
`;

export const Button = styled.button`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 600px;
    text-align: center;
    padding: 10px 16px;
    white-space: nowrap;
    &.active {
        background: ${(props) => props.theme.colors.disabled};
        transition: background 0.5s;
        color: ${(props) => props.theme.colors.secondarydark};
    }
`;

export const ButtonStyles = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 24px auto 0px;
    button {
        &:first-child {
            margin-right: 4px;
        }
    }
`;

export const TabStyles = styled.div`
    margin: 0 auto;
    position: relative;
    padding-top: 28px;
    .tab-fale-conosco {
        position: absolute;
        top: 28px;
        left: 0px;
        opacity: 0;
        width: 100%;
        transition: all 0.2s ease;
        pointer-events: none;
        &.active {
            position: static;
            pointer-events: all;
            opacity: 1;
            transition: all 0.2s ease;
        }
    }
`;

export const DuvidasFrequenteStyles = styled.div`
    padding-top: 38px;
    h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 32px;
    }

    .container-input {
        position: relative;
        margin-bottom: 24px;
        input {
            padding: 0 50px 0 24px;
        }

        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 24px;
            pointer-events: none;
        }
    }
`;

export const SemMensagemStyles = styled.div`
    width: 172px;
    margin: 0 auto;
    text-align: center;
    margin-top: 71px;
    h3 {
        font-size: 14px;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primaryDark};
        font-weight: 400;
    }
`;
