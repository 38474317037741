import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import { useTranslation } from "../../../contexts/Localization";
import { HeaderPassos } from "../HeaderPassos";
import { FormAddCumpom } from "./formAddCupom";
import { SucessoCupom } from "./sucessoCupom";
import { Container } from "./styles";

const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
        goBack: true,
    },
];

export function AdicionarCupom() {
    const { setPassos, passos } = usePassos();
    const history = useHistory();
    const { state, setState } = UseModal();
    const { t } = useTranslation();
    const path = history.location.pathname;
    const width = window.screen.width;

    useEffect(() => {
        if (state?.cupom || path.includes("/adicionar-cumpom")) {
            setPassos(arrPassos);
        }
    }, [setPassos, state?.cupom, path]);

    return (
        <Container>
            <HeaderPassos
                title={t("cupom yetzpay")}
                handleClose={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({
                            ...state,
                            cupom: false,
                        });
                        setPassos([]);
                    }
                }}
            />
            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    {passos[0]?.active && <FormAddCumpom />}
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    {passos[1]?.active && <SucessoCupom />}
                </div>
            </div>
        </Container>
    );
}
