import * as Yup from "yup";

export interface FaleConosco {
    name: string;
    email: string;
    phone: string;
    description: string;
    subject: string;
}

export const faleConosco: FaleConosco = {
    email: "",
    name: "",
    phone: "",
    description: "",
    subject: "",
};

export const values = {
    email: "",
    name: "",
    phone: "",
    description: "",
    subject: "",
};

export const validationFaleConosco = Yup.object().shape({
    description: Yup.string().required("Campo obrigatório"),
    subject: Yup.string().required("Campo obrigatório"),
});
