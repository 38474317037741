import * as Yup from "yup";

export interface ModalFormProps {
    nome: string;
    start_date_time: string;
    end_date_time: string;
}

export const initialValues: ModalFormProps = {
    nome: "",
    start_date_time: "",
    end_date_time: "",
};

export const validation = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    start_date_time: Yup.string().required("Seleciona a data inicial e final"),
    end_date_time: Yup.string().required("Seleciona a data inicial e final"),
});
