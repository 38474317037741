import { Box, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import Mask from "react-input-mask";

import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { formatNameCard, phoneNumber } from "../../../util/helper";
import { Button } from "../ButtonSlim";
import {
    confirmaDadosCartao,
    ConfirmaDadosCartao,
    validaDadosCartao,
} from "./config";

import { ConfirmaDadosCartaoStyles } from "./styles";

export const variant = {
    FISICO: "fisico",
    DIGITAL: "digital",
} as const;
export type Variant = (typeof variant)[keyof typeof variant];

interface ConfirmaDadosProps {
    tipo?: Variant;
}

export function ConfirmaDados(props: ConfirmaDadosProps) {
    const { tipo } = props;
    const { nextPasso, setDados, dados } = usePassos();
    const { user } = useUser();
    const { t } = useTranslation();
    const [values, setValues] = useState(confirmaDadosCartao);
    // const regexPhone = /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/

    useEffect(() => {
        if (tipo !== "fisico") {
            setValues({
                celular: phoneNumber(user?.phone_number),
                cpf: user?.document,
                data_nascimento: user?.pessoa?.data_nascimento.replace(
                    /^([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                    "$3/$2/$1"
                ),
                nome_completo: formatNameCard(user?.pessoa?.nome_cpf),
            });
        } else {
            setValues({
                celular: phoneNumber(user?.phone_number),
                cpf: user?.document,
                data_nascimento: user?.pessoa?.data_nascimento.replace(
                    /^([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                    "$3/$2/$1"
                ),
                nome_completo: formatNameCard(
                    user?.pessoa?.nome_cpf || user?.pessoa?.razao_social
                ),
            });
        }
    }, [tipo, user]);

    return (
        <ConfirmaDadosCartaoStyles className="container">
            <Text as="p">
                {t(
                    `Para pedir o cartão ${
                        tipo === "fisico" ? "físico" : "virtual"
                    } precisamos confirmar seus dados`
                )}
            </Text>

            <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validaDadosCartao}
                onSubmit={(values, actions) => {
                    const { data_nascimento, ...resto } = values;
                    setDados({
                        ...resto,
                        data_nascimento: data_nascimento
                            .split("/")
                            .reverse()
                            .join("-"),
                        ...user.enderecos[0],
                        ...dados,
                    });
                    nextPasso(3);
                }}
            >
                {(props: FormikProps<ConfirmaDadosCartao>) => {
                    const { handleChange, values } = props;
                    const { cpf, celular } = values;

                    return (
                        <Box>
                            <Form>
                                <div className="container-input user active disabled">
                                    <Mask
                                        mask="999.999.999-99"
                                        placeholder="CPF"
                                        name="cpf"
                                        type="tel"
                                        onChange={handleChange}
                                        value={cpf}
                                    />
                                </div>
                                <ErrorMessage name="cpf" component="span" />

                                <div className="container-input user active disabled">
                                    <Field
                                        name="nome_completo"
                                        placeholder={t("nome")}
                                    />
                                </div>
                                <ErrorMessage
                                    name="nome_completo"
                                    component="span"
                                />

                                <div
                                    className={`container-input celular active disabled`}
                                >
                                    <Mask
                                        mask="(99) 99999-9999"
                                        placeholder={t("celular")}
                                        name="celular"
                                        type="tel"
                                        onChange={handleChange}
                                        value={celular}
                                    />
                                </div>
                                <ErrorMessage name="celular" component="span" />

                                <Box mt="56px">
                                    <Button type="submit">
                                        {t("Avançar")}
                                    </Button>
                                </Box>
                            </Form>
                        </Box>
                    );
                }}
            </Formik>
        </ConfirmaDadosCartaoStyles>
    );
}
