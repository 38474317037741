export const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="21"
      viewBox="0 0 27 21"
      fill="none"
      className="email"
    >
      <path
        d="M22.3125 0H3.9375C2.89321 0 1.89169 0.414843 1.15327 1.15327C0.414843 1.89169 0 2.89321 0 3.9375V17.0625C0 18.1068 0.414843 19.1083 1.15327 19.8467C1.89169 20.5852 2.89321 21 3.9375 21H22.3125C23.3568 21 24.3583 20.5852 25.0967 19.8467C25.8352 19.1083 26.25 18.1068 26.25 17.0625V3.9375C26.25 2.89321 25.8352 1.89169 25.0967 1.15327C24.3583 0.414843 23.3568 0 22.3125 0ZM21.4331 2.625L13.125 8.85938L4.81687 2.625H21.4331ZM22.3125 18.375H3.9375C3.5894 18.375 3.25556 18.2367 3.00942 17.9906C2.76328 17.7444 2.625 17.4106 2.625 17.0625V4.26562L12.3375 11.55C12.5647 11.7204 12.841 11.8125 13.125 11.8125C13.409 11.8125 13.6853 11.7204 13.9125 11.55L23.625 4.26562V17.0625C23.625 17.4106 23.4867 17.7444 23.2406 17.9906C22.9944 18.2367 22.6606 18.375 22.3125 18.375Z"
        fill="#000D3C"
      />
    </svg>
  );
};
