import { Text } from "alisson-application";

import { CardStyles } from "./styles";

interface CardProps {
    title: string;
    img: string;
}

export function Card(props: CardProps) {
    const { title, img } = props;

    return (
        <CardStyles>
            <div className="container-img">
                <img src={img} alt="" />
            </div>
            <Text as="p" textTransform="uppercase" color="secondarydark">
                {title}{" "}
            </Text>
        </CardStyles>
    );
}
