import styled from "styled-components";

export const Container = styled.ul`
    button {
        &.ativar-cartao-avulso {
            margin-top: 20px;
            border-radius: 8px;
            background-color: ${(props) => props.theme.colors.secondary};
            img {
                margin-right: 16px;
            }
        }
    }
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        border-bottom: 1px solid ${(props) => props.theme.colors.disabled};
        div {
            img {
                display: block;
                margin: 0 auto;
            }
        }
        &:last-child {
            border-bottom: unset;
        }
        &:first-child {
            border-top: 1px solid ${(props) => props.theme.colors.disabled};
        }
    }
`;
