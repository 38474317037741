import { Container } from "./styles";
import imgNovoSaldo from "../../assets/img/novoSaldo.svg";
import { Button } from "../Mobile/Button";
import { UseModal } from "../../Hook/Usemodal";
import { useUser } from "../../Hook/user/UseUser";
import { useState } from "react";
import { useHistory } from "react-router-dom";

export function NovoSaldo() {
    const { setState, state } = UseModal();
    const { novoSaldo } = useUser();
    const [loading, setLoading] = useState(false);
    const width = window.screen.width;
    const history = useHistory();
    return (
        <Container>
            <div className="container-img">
                <img src={imgNovoSaldo} alt="" />
            </div>

            <div className="container-title">
                <h3>
                    CRÉDITOS INSERIDOS <br />
                    no saldo da sua carteira
                </h3>
            </div>

            <div className="container-description">
                <p>
                    Confira seu{" "}
                    <button
                        onClick={async () => {
                            if (width < 1024) {
                                history.push("/movimentacao");
                                setState({
                                    ...state,
                                    novoSaldo: false,
                                    extrato: true,
                                });
                            } else {
                                setState({
                                    ...state,
                                    novoSaldo: false,
                                    extrato: true,
                                });
                            }

                            await novoSaldo();
                        }}
                    >
                        EXTRATO
                    </button>{" "}
                    agora mesmo.
                </p>
            </div>

            <div className="container-aviso">
                <p>
                    Lembre-se que você <br /> precisa transferir o{" "}
                    <strong>SALDO DA CARTEIRA</strong> <br />
                    para um <strong>CARTÃO YETZPAY.</strong>
                </p>
            </div>

            <div className="container-btn">
                <Button
                    isLoading={loading}
                    onClick={async () => {
                        setLoading(true);
                        await novoSaldo();
                        setLoading(false);
                        setState({
                            ...state,
                            novoSaldo: false,
                        });
                    }}
                >
                    ok
                </Button>
            </div>
        </Container>
    );
}
