import styled from "styled-components";

export const WhatsAppStyles = styled.div`
    width: 212px;
    .container-img-template {
        background: #d5d7d5;
        border-radius: 8.82474px 8.82474px 0px 0px;
        width: 100%;
        height: 114px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8.82474px 8.82474px 0px 0px;
        }
    }

    .content-template {
        width: 100%;
        height: 151px;
        background: #d4f6c1;
        border-radius: 0px 0px 8.82474px 8.82474px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 0px 24px;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 9.92784px;
            line-height: 140%;
            color: ${(props) => props.theme.colors.primary};
        }
    }
`;
