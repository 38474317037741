import {
    useContext,
    createContext,
    useCallback,
    useState,
    useEffect,
} from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
import { useHistory } from "react-router-dom";
import { User } from "./useUser";

interface useTermoECondicoesDeUsoProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface ITermoECondicoesDeUso {
    loading: boolean;
    termoDeUso: TermoUso[];
    getTermos: () => Promise<void>;
    store(values: FormData): Promise<any>;
    deleteTermo(id: number): Promise<any>;
}

interface TermoUsoData {
    data: TermoUso[];
}

interface TermoUso {
    created_at: string;
    descricao: string;
    file: string;
    id: number;
    titulo: string;
    updated_at: string;
    user: User;
}

const TermoECondicoesDeUso = createContext<ITermoECondicoesDeUso>(
    {} as ITermoECondicoesDeUso
);

export function TermoECondicoesDeUsoProvider(
    props: useTermoECondicoesDeUsoProps
) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [termoDeUso, setTermoDeUso] = useState<TermoUso[]>([]);
    const history = useHistory();
    const path = history.location.pathname;

    const getTermos = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get<TermoUsoData>("termo-politica");

            setTermoDeUso(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error("Erro ao carregar Termos");
            setTermoDeUso([]);
            setLoading(false);
        }
    }, []);

    async function store(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };

        try {
            const response = await api.post(`termo-politica`, values, {
                headers,
            });

            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            return error.response;
        }
    }

    async function deleteTermo(id: number) {
        try {
            await api.delete(`termo-politica/${id}`);
            await getTermos();
            toast.success("Sucesso");
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
        }
    }

    useEffect(() => {
        if (path === "/sistema/termo-e-condicoes-de-uso") {
            getTermos();
        }
    }, [getTermos, path]);

    return (
        <TermoECondicoesDeUso.Provider
            value={{ loading, getTermos, termoDeUso, store, deleteTermo }}
        >
            {children}
        </TermoECondicoesDeUso.Provider>
    );
}

export function useTermoECondicoesDeUso() {
    const context = useContext(TermoECondicoesDeUso);
    return context;
}
