import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { ptBR } from "@mui/x-date-pickers";
import { ptBR as coreptBr } from "@mui/material/locale";

interface DataRangerProps {
    onChange: (newValue: string[]) => void;
}

export function DataRanger(props: DataRangerProps) {
    const { onChange } = props;
    const theme = createTheme(
        {
            palette: {
                primary: { main: "#5DB356" },
            },
        },
        ptBR, // x-date-pickers translations
        coreptBr // core translations
    );

    function convertDateIso(date: Date) {
        if (!date) return "";

        const dateFormated = date
            .toLocaleDateString()
            .replace(/\//g, "-")
            .split("-")
            .reverse()
            .join("-");

        return dateFormated;
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                    sx={{
                        width: "100%",
                    }}
                    slots={{ field: SingleInputDateRangeField }}
                    label="Inicio - Fim"
                    onChange={(newValue) => {
                        const update = newValue.map((date: any) => {
                            if (!date) return "";
                            return convertDateIso(date);
                        });
                        onChange(update);
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
