import { DebounceInput } from "react-debounce-input";
import { FilterStyles } from "./styles";

interface FilterProps {
    searchFetchData(search: string): Promise<void>;
}

export function Filter(props: FilterProps) {
    const { searchFetchData } = props;

    return (
        <FilterStyles>
            <div className="container-input-system pesquisa">
                <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    onChange={async (e) => {
                        await searchFetchData(e.target.value);
                    }}
                />
            </div>
        </FilterStyles>
    );
}
