import { EditarCampanha } from "../../../../components/System/Campanha/Editar";
import { CampanhaProvider } from "../../../../Hook/system/useCampanha";

export function EditarCampanhaPage() {
    return (
        <CampanhaProvider>
            <EditarCampanha />
        </CampanhaProvider>
    );
}
