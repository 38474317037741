import { NovaCampanha } from "../../../../components/System/Campanha/Nova";
import { CampanhaProvider } from "../../../../Hook/system/useCampanha";

export function PageNovaCampanha() {
    return (
        <CampanhaProvider>
            <NovaCampanha />
        </CampanhaProvider>
    );
}
