import { Login } from "../../../components/System/Login";
import { Footer } from "../../../components/System/Footer";
import { LoginProvider } from "../../../Hook/system/useLogin";
import { UseModal } from "../../../Hook/Usemodal";
import Modal from "../../../components/NovoModal";
import { EsqueciSenha } from "../../../components/Mobile/EsqueciSenha";
import { getToken } from "../../../util/helper";

import { PageDashBoard } from "../DashBoard";

export function PageLoginSystem() {
    const token = getToken("admin");
    const { handleClose, state } = UseModal();

    return token ? (
        <PageDashBoard />
    ) : (
        <LoginProvider>
            <Login />
            <Footer />
            <Modal
                open={state?.esqueciSenha}
                handleClose={() => handleClose("esqueciSenha")}
            >
                <EsqueciSenha />
            </Modal>
        </LoginProvider>
    );
}
