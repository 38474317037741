import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { DiferencaCartoes } from "../../DiferencaCartoes";

export function ModalDiferencaCartoes(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <DiferencaCartoes />
        </ModalBottom>
    );
}
