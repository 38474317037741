import React from "react";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import { LanguageProvider } from "./contexts/Localization/";
import { UseModalProvider } from "./Hook/Usemodal";
import { UsePassisProvider } from "./Hook/UsePassos";

interface ProvidersProps {
    children: JSX.Element | JSX.Element[] | string | string[];
}

export default function Providers({ children }: ProvidersProps) {
    return (
        <ThemeContextProvider>
            <LanguageProvider>
                <UsePassisProvider>
                    <UseModalProvider>{children}</UseModalProvider>
                </UsePassisProvider>
            </LanguageProvider>
        </ThemeContextProvider>
    );
}
