import * as Yup from "yup";

export interface Myform {
    nome: string;
    razao_social: string;
    cnpj: string;
}
export const staticInitalValues: Myform = {
    nome: "",
    razao_social: "",
    cnpj: "",
};

export const validadeForm = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    razao_social: Yup.string().required("Campo Obrigatório"),
    cnpj: Yup.string().required("Campo Obrigatório"),
});
