import styled from "styled-components";
import imgUser from "../../../../../../assets/img/user.svg";

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    height: auto;
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 150px;
        img {
            position: absolute;
            top: -140px;
            left: 50%;
            transform: translateX(-50%);
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 105px;
        }
    }

    .container-input-system {
        &.cliente {
            &.select {
                &:before {
                    -webkit-mask: url(${imgUser}) no-repeat;
                }
            }
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 124px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        position: relative;
        .container-img {
            position: absolute;
            top: -155px;
            left: 50%;
            transform: translateX(-50%);
        }
        span {
            display: block;
            width: 254px;
            margin: 0 auto;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 22px;
        }
    }
`;
