import * as Yup from "yup";

export interface IAlterarSenha {
    password: string;
    confirm_password: string;
    cvv?: string;
}

export const altearSenha: IAlterarSenha = {
    password: "",
    confirm_password: "",
};

export const validationAlterarSenha = Yup.object().shape({
    password: Yup.string()
        .min(4, "Senha deve ter 4 números")
        .required("Senha é obrigatório"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Campo Confirmar Senha é obrigatório"),
});
export const validationAlterarSenhaCvv = Yup.object().shape({
    cvv: Yup.string()
        .min(3, "Cvv deve ter 3 números")
        .required("Cvv é obrigatório"),
});
