import styled from "styled-components";
import { Button as ButtonSystem, ButtonProps } from "alisson-application";

interface ContainerProps extends ButtonProps {
    transparent?: boolean;
}
export const Button = styled(ButtonSystem)<ContainerProps>`
    border-radius: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fonts.xss};
    line-height: 100%;

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    padding: 0px 32px;

    width: 100%;
    height: 44px;
    border: 1px solid
        ${(props) => (props.transparent ? "#E1E2E3" : "transparent")};

    color: ${(props) =>
        props.transparent
            ? props.theme.colors.secondary
            : props.theme.colors.white};

    background-color: ${(props) =>
        props.transparent ? "transparent" : props.theme.colors.secondary};
    transition: all 0.5s ease;

    &:disabled {
        outline: none;
        transition: all 0.5s ease;
    }
`;
