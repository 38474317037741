import styled from "styled-components";
import { ButtonProps, Button as ButtonSystem } from 'alisson-application'

interface CustonButtonProps extends ButtonProps {
    background?: string;
    color?: string;
}

export const Button = styled(ButtonSystem)<CustonButtonProps>`
    &.SUSPENSA {
        background: ${(props) => props.background || "#FFE8E8"};
        color: ${(props) => props.color || "#D40000"};
    }

    &.ENCERRADA {
        background: ${(props) => props.background || "#DBF6FF"};
        color: ${(props) => props.color || "#0037A4"};
    }

    background: ${(props) =>
        props.background ? props.background : props.theme.colors.backgroundAlt};
    border: none;
    height: auto;
    border-radius: 4px;
    padding: 8px !important;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;

    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.primary};
`;
