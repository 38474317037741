import styled from "styled-components";

export const Container = styled.div`
    padding: 35px 0 35px 0;
    .container-text {
        text-align: center;
        width: 231px;
        margin: 0 auto;
        margin-bottom: 20px;
        small {
            color: ${({ theme }) => theme.colors.primaryDark};
        }
    }
`;
