import { EditarPermissao } from "../../../../../components/System/Administracao/Permissao/editar";
import { PermissionProvider } from "../../../../../Hook/system/usePermission";
import { UserProvider } from "../../../../../Hook/system/useUser";

export function PageEditarPermissao() {
    return (
        <PermissionProvider>
            <UserProvider>
                <EditarPermissao />
            </UserProvider>
        </PermissionProvider>
    );
}
