import { FieldProps, ErrorMessage } from "formik";
import Select, { options } from "../../NovoSelect";
import { DateTime } from "../../DateTime";
import { formatDateTimeDb } from "../../../../util/helper";
import { useTemplate, Template } from "../../../../Hook/system/useTemplate";
import { BackgroundUpload } from "../../BackgroundUpload";
import { useFile } from "../hook";
import { useEffect, useState } from "react";

export function WhatsApp(props: FieldProps) {
    const { form } = props;
    const { handleFiles, files } = useFile();
    const { setFieldValue, values } = form;
    const { getTemplate } = useTemplate();
    const [template, setTemplate] = useState<Template[]>([]);

    const options: options[] = template.map((item) => {
        return {
            label: item.name,
            value: item.id,
        };
    });

    const disparo: options[] = [
        {
            label: "IMEDIATO",
            value: 1,
        },
        {
            label: "AGENDADO",
            value: 2,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            const response = await getTemplate("WHATSAPP");
            setTemplate(response);
        }

        fetchData();
    }, [getTemplate]);

    return (
        <>
            <Select
                label="SELECIONAR TEMPLATE"
                options={options}
                onChange={(values) =>
                    setFieldValue("template_id", values.value)
                }
                icon={false}
            />
            <ErrorMessage component="span" name="template_id" />

            <div className="container-imagens-mensagens um-item">
                <div className="container-desktop">
                    <label htmlFor="img-whatsapp">
                        <BackgroundUpload
                            width={181}
                            title="IMAGEM DESKTOP"
                            subTitle="900PX / 600PX"
                            preview={
                                files?.arte_desktop
                                    ? URL.createObjectURL(files?.arte_desktop)
                                    : ""
                            }
                        />
                    </label>
                    <input
                        type="file"
                        name=""
                        id="img-whatsapp"
                        accept="image/*"
                        onChange={async (e) => {
                            if (e.target.files?.length) {
                                handleFiles(
                                    e.target.files[0],
                                    "arte_desktop",
                                    900,
                                    600
                                );

                                setFieldValue("arte_desktop", "ok");
                            }
                        }}
                    />
                    <ErrorMessage component="span" name="arte_desktop" />
                </div>
            </div>

            <Select
                label="DISPARO"
                options={disparo}
                onChange={(values) => {
                    setFieldValue("disparo", values.label.toLocaleLowerCase());
                    if (values.label === "IMEDIATO") {
                        setFieldValue("data_disparo", " ");
                    }
                }}
                icon={false}
            />
            <ErrorMessage component="span" name="disparo" />

            {values.disparo === "agendado" && (
                <DateTime
                    initialValue={null}
                    label="DATA/HORARIO DE DISPARO"
                    onChange={(dt_inicio) => {
                        setFieldValue(
                            "data_disparo",
                            formatDateTimeDb(dt_inicio).trim()
                        );
                    }}
                />
            )}

            <ErrorMessage component="span" name="data_disparo" />
        </>
    );
}
