import { NovoCliente } from "../../../../components/System/Cliente/novo";
import { ClienteProvider } from "../../../../Hook/system/useCliente";

export function PageNovoCliente() {
    return (
        <ClienteProvider>
            <NovoCliente />
        </ClienteProvider>
    )
}
