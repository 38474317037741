import * as Yup from "yup";
const regexCPFouCNPJ =
    /^([0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2})|([0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2})$/;

const regex_number =
    "^(?<DDI>(\\+|00|\\+00)?[\\s\\-\\.]?(\\(?[5]{2}\\)?)?)?[\\s\\-\\.]?(?<DDD>\\(?0?([1-9]{2})\\)?)[\\s\\-\\.]?(?<Digito>9)?[\\s\\-\\.]?(?<Numero>(?<PrimeiraParte>[1-9][0-9]{3})[\\s\\-\\.]?(?<SegundaParte>[0-9]{4}))\\s?$";
const regexMobileNumber = new RegExp(regex_number, "g");

export interface FaleConosco {
    campanha: string;
    name: string;
    phone: string;
    email: string;
    document: string;
    description: string;
    subject: string;
}

export const faleConosco: FaleConosco = {
    email: "",
    name: "",
    phone: "",
    description: "",
    document: "",
    campanha: "",
    subject: "",
};

export const validationFaleConosco = Yup.object().shape({
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
    name: Yup.string().required("Campo obrigatório"),
    phone: Yup.string()
        .matches(regexMobileNumber, "Digite um telefone valido")
        .required("Campo obrigatório"),
    campanha: Yup.string().required("Campo obrigatório"),
    description: Yup.string().required("Campo obrigatório"),
    subject: Yup.string().required("Campo obrigatório"),
    document: Yup.string()
        .matches(regexCPFouCNPJ, "Digite um CPF ou CNPJ valido")
        .required("Campo obrigatório"),
});
