import { useHistory } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { Header as DefaultHeader } from "../Header";
import { Button } from "../Button";
import { StylesHeader } from "./styles";
import { toast } from "react-toastify";
import imgReload from "../../../assets/img/reload.svg";
import { useRelatorio } from "../../../Hook/system/useRelatorio";

interface IHeader {
    setSearch: (value: string) => void;
}

export function Header(props: IHeader) {
    const history = useHistory();
    const { fetch } = useRelatorio();

    const { setSearch } = props;

    return (
        <StylesHeader>
            <DefaultHeader
                title="RELATÓRIO"
                titleBtn=""
                permission="faq.cupom"
            />

            <div className="container-input-system pesquisa">
                <DebounceInput
                    type="text"
                    debounceTimeout={1000}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        toast.info("Falta implementar a busca");
                    }}
                />
            </div>

            <button className="reload" onClick={async () => await fetch()}>
                <img src={imgReload} alt="" />
            </button>

            <Button
                onClick={() => {
                    history.push("/sistema/relatorio/novo");
                }}
            >
                GERAR RELATÓRIO
            </Button>
        </StylesHeader>
    );
}
