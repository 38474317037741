import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { TransferirEntreCateira } from "../../../components/Mobile/TransferirEntreCateira";

export function ModalTransferirEntreCateira(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <TransferirEntreCateira />
        </ModalBottom>
    );
}
