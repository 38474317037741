import { useEffect, useState } from "react";
import { Flex, Text } from "alisson-application";
import { IOSSwitch } from "../../../Switch";
import { api } from "../../../../../services";
import { SimpleAccordion } from "../../../Accordion";

interface ArrayGroup {
    name: string;
    group: Group[];
}

interface Group {
    name: string;
    check: boolean;
    id: number;
}

interface IPermission {
    id: number;
    name: string;
}

interface PersmissionsProps {
    onChange: (value: number[]) => void;
}

export function Permission(props: PersmissionsProps) {
    const [permissions, setPermissions] = useState<ArrayGroup[]>([]);

    function createArrayPermission(permissions: ArrayGroup[]) {
        return permissions.reduce((acc, item) => {
            item.group
                .filter((item) => item.check)
                .forEach((item) => {
                    acc.push(item.id);
                });
            return acc;
        }, [] as number[]);
    }

    function checkAll(item: ArrayGroup, check: boolean) {
        const updatePermission = permissions.map((permission) => {
            if (permission.name === item.name) {
                return {
                    name: permission.name,
                    group: item.group.map((item) => {
                        return {
                            ...item,
                            check,
                        };
                    }),
                };
            }
            return {
                ...permission,
            };
        });

        setPermissions(updatePermission);
        props.onChange(createArrayPermission(updatePermission));
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await api.get<IPermission[]>(`/permission`);

                const permissions = response.data.map((permission) => {
                    return {
                        id: permission.id,
                        name: permission.name,
                    };
                });

                const keys = [
                    ...new Set(
                        permissions.map((item) => {
                            return item.name.split(".")[0];
                        })
                    ),
                ];

                const arrayGroup: ArrayGroup[] = keys.map((key) => {
                    let format = permissions.filter((item) => {
                        return item.name.split(".")[0] === key;
                    });

                    const group = format.map((item) => {
                        return {
                            name: item.name,
                            check: false,
                            id: item.id,
                        };
                    });

                    return {
                        name: key,
                        group,
                    };
                });
                setPermissions(arrayGroup);
            } catch (error) {
                setPermissions([]);
            }
        }

        fetchData();
    }, []);

    return (
        <>
            {permissions.map((item) => {
                const check = item.group.every((item) => item.check);

                return (
                    <SimpleAccordion
                        key={item.name}
                        title={item.name}
                        check={true}
                        checked={check}
                        checkOnChange={() => checkAll(item, !check)}
                    >
                        {item.group.map((item) => {
                            return (
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className="list-switch"
                                    key={item.id}
                                >
                                    <Text as="p">{item.name}</Text>
                                    <IOSSwitch
                                        checked={item.check}
                                        onChange={() => {
                                            const updatePermission =
                                                permissions.map(
                                                    (permission) => {
                                                        return {
                                                            name: permission.name,
                                                            group: permission.group.map(
                                                                (group) => {
                                                                    if (
                                                                        group.name ===
                                                                        item.name
                                                                    ) {
                                                                        return {
                                                                            name: item.name,
                                                                            check: !item.check,
                                                                            id: item.id,
                                                                        };
                                                                    }

                                                                    return group;
                                                                }
                                                            ),
                                                        };
                                                    }
                                                );
                                            setPermissions(updatePermission);
                                            props.onChange(
                                                createArrayPermission(
                                                    updatePermission
                                                )
                                            );
                                        }}
                                    />
                                </Flex>
                            );
                        })}
                    </SimpleAccordion>
                );
            })}
        </>
    );
}
