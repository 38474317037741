import { ErrorMessage, Field, FieldProps } from "formik";

interface InputProps extends FieldProps {
    disabled?: boolean;
}

export function Input(props: InputProps) {
    const { field, disabled = false } = props;

    const itensDisabled = ["id"];
    return (
        <div className="container-element-backoffice">
            <label htmlFor="">{field.name}</label>
            <div className={`container-input-backoffice`}>
                <Field
                    disabled={itensDisabled.includes(field.name) || disabled}
                    name={field.name}
                    placeholder={field.name}
                />
            </div>
            <ErrorMessage name={field.name} component="span" />
        </div>
    );
}
