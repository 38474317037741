import { useState } from "react";
import { useHistory } from "react-router-dom";
import imgMensagem from "../../../assets/img/messages.svg";
import imgPlayer from "../../../assets/img/player.png";
import imgSms from "../../../assets/img/sms-login.svg";
import pdf from "../../../assets/pdf/tutorial_desbloqueio_Whatsapp_2024_pay.pdf";
import Modal from "../../NovoModal/video";
import { AjduaStyles } from "./styles";
import { Video } from "./video";

export function Ajuda() {
    const [open, setOpen] = useState(false);
    const history = useHistory();

    return (
        <>
            <AjduaStyles>
                <div className="content-ajuda">
                    <div
                        onClick={() => setOpen(true)}
                        className="container-video"
                    >
                        <img src={imgPlayer} alt="" />
                        <p>
                            Como fazer <br />
                            seu cadastro
                        </p>
                    </div>

                    <a
                        href={pdf}
                        target="_blank"
                        className="container-text"
                        rel="noreferrer"
                    >
                        <img src={imgSms} alt="" />
                        <p>
                            Clique aqui se não <br /> receber seu código
                        </p>
                    </a>
                </div>
                <button onClick={() => history.push("/precisa-ajuda")}>
                    <img src={imgMensagem} alt="" />
                    PRECISA DE AJUDA?
                </button>
            </AjduaStyles>
            <Modal handleClose={() => setOpen(false)} open={open}>
                {open && <Video />}
            </Modal>
        </>
    );
}
//
