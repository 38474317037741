import { useState, useEffect, useRef, MouseEvent } from "react";
import { Text, Box } from "alisson-application";

import { useTranslation } from "../../../contexts/Localization";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useStateUser } from "../../../Hook/user/UseStateUser";

import { NovoCartao } from "../Novocartao";
import { podesolicitarCartao, getCampanha } from "../../../util/helper";
import imgImage from "../../../assets/img/image.png";
import imgVerMais from "../../../assets/img/icone-ver-mais.svg";

import { CartoesStyles } from "./styles";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { options } from "../../NovoSelect";
import { MemoCard } from "./card";

export function Cartoes() {
    const [distancia, setDistancia] = useState(65);
    const [height, setHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);
    const refCard = useRef<HTMLDivElement[]>([]);
    const { handleOpen, state, setState } = UseModal();
    const { cartoes } = UseCartoes();
    const { setStateUser } = useStateUser();
    const { t } = useTranslation();
    const { campanhas } = UseLogin();
    const [options, setOptions] = useState<options[]>([]);

    function timeout(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    function handleOpenModal() {
        if (!options.length) {
            setState({
                ...state,
                successGeral: { ...state?.successGeral },
                errorGeral: {
                    active: true,
                    message: cartoes.length
                        ? "VOCÊ NÃO PODE MAIS SOLICITAR CARTÕES"
                        : "VOCÊ NÃO POSSUI SALDO SUFICIENTE PARA SOLICITAR UM CARTÃO.",

                    title: "Erro",
                },
            });
        } else {
            handleOpen("diferencaCartoes");
        }
    }

    function getHeight() {
        return refCard.current.reduce((acc: any, item, key) => {
            if (key <= 2) {
                acc = acc + (item as HTMLElement).offsetHeight;
            }

            return acc;
        }, 0);
    }

    async function handleVerMais(e: MouseEvent<HTMLButtonElement>) {
        const event = e.target;
        const el = event as HTMLElement;
        const p = (e.target as HTMLElement).querySelector("p");

        if (el.classList.value.includes("active")) {
            el.classList.remove("active");
            if (p) {
                p.innerHTML = "VER MAIS";
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
            await timeout(600);

            if (refCard?.current[0]) {
                refCard.current[0].click();
            }

            setHeight(getHeight() + 64);
        } else {
            el.classList.add("active");
            if (p) {
                p.innerHTML = "VER MENOS";
            }
            const height = (ref.current as HTMLElement).offsetHeight + 48;
            setHeight(height);
        }
    }

    useEffect(() => {
        if (cartoes?.length) {
            const values = {
                type: cartoes[0]?.tipo ? cartoes[0].tipo : "",
                saldo: 0,
                number: cartoes[0]?.acg_account_card_last4digits
                    ? cartoes[0].acg_account_card_last4digits
                    : "",
                validade: cartoes[0]?.acg_account_card_expiration
                    ? cartoes[0]?.acg_account_card_expiration
                    : "",
                itens: [1, 2, 3],
                id: cartoes[0]?.id,
            };

            setStateUser(values);
        }
    }, [cartoes, setStateUser]);

    useEffect(() => {
        if (getCampanha().id) {
            setOptions(podesolicitarCartao([getCampanha()], cartoes));
        }
    }, [campanhas, cartoes]);

    useEffect(() => {
        if (cartoes.length && refCard?.current.length) {
            if (2 <= cartoes.length) {
                setHeight(getHeight() + 64);
            }
        }
    }, [cartoes]);

    return (
        <CartoesStyles>
            <div className="container-img" style={{ top: distancia }}>
                <img src={imgImage} alt="" />
            </div>

            <div
                className="esq"
                style={{
                    height: `${height === 0 ? "auto" : height}px`,
                }}
            >
                <Text
                    textTransform="uppercase"
                    textAlign="center"
                    fontWeight="bold"
                    mb="16px"
                    color="secondary"
                >
                    {t("Meus cartões")}
                </Text>

                <div className="container-cartoes" ref={ref}>
                    <MemoCard setDistancia={setDistancia} refCards={refCard} />
                </div>
            </div>

            <Box height="200px" width="162px" m="0 auto">
                <NovoCartao
                    onClick={handleOpenModal}
                    empty={cartoes?.length === 0}
                />
            </Box>

            {cartoes?.length > 2 && (
                <button className="ver-mais" onClick={handleVerMais}>
                    <img src={imgVerMais} alt="" />
                    <Text as="p">VER MAIS</Text>
                </button>
            )}
        </CartoesStyles>
    );
}
