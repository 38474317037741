import { useContext, createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../../../../services/";

interface useProdutoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useProdutoData {
    produtos: Produto[];
    updateProduto: (values: any) => Promise<any>;
}

export interface Produto {
    acg_comercial_origin_id: string;
    acg_produto_id: string;
    created_at: string;
    descricao: string;
    id: number;
    lim_compras: string;
    lim_saque: string;
    nome: string;
    tipo: string;
    updated_at: string;
}

const UseProdutoContext = createContext<useProdutoData>({} as useProdutoData);

export function ProdutoProvider(props: useProdutoProviderProps) {
    const { children } = props;
    const [produtos, setProdutos] = useState<Produto[]>([]);
    const history = useHistory<{ id: number }>();
    const id = history.location.state?.id;

    async function getProdutos() {
        try {
            const { data } = await api.get<Produto[]>(`produto`);
            setProdutos(data);
        } catch (error: any) {
            return error;
        }
    }

    async function updateProduto(values: any) {
        try {
            const response = await api.post(`campanha/${id}`, {
                produtos: [...values],
            });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    useEffect(() => {
        getProdutos();
    }, []);

    return (
        <UseProdutoContext.Provider value={{ produtos, updateProduto }}>
            {children}
        </UseProdutoContext.Provider>
    );
}

export function useProduto() {
    const context = useContext(UseProdutoContext);
    return context;
}
