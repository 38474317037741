import * as Yup from "yup";

export interface Myform {
    name: string;
    document: string;
    file: string;
}
export const initalValues: Myform = {
    name: "",
    document: "",
    file: "",
};

export const validadeForm = Yup.object().shape({
    name: Yup.string().required("Campo Obrigatório"),
    document: Yup.string().required("Campo Obrigatório"),
    file: Yup.string().required("Campo Obrigatório"),
});
