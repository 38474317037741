import { FaqProvider } from "../../../../Hook/system/useFaq";
import { NovoFaq } from "../../../../components/System/Faq/novo";

export function PageNovoFaq() {
    return (
        <FaqProvider>
            <NovoFaq />
        </FaqProvider>
    );
}
