import styled from "styled-components";

export const Button = styled.button`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.secondary};
    border: 1px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 600px;
    text-align: center;
    padding: 10px 16px;
    white-space: nowrap;
    width: 100%;
    &.active {
        background: ${(props) => props.theme.colors.disabled};
        transition: background 0.5s;
        color: ${(props) => props.theme.colors.secondarydark};
    }
`;

export const MesesStyles = styled.div`
    position: relative;
    margin-bottom: 32px;
    .swiper-slide {
        /* width: 110px !important; */
    }

    .swiper-slide-mes {
        width: 100%;

        .hide-slide {
            opacity: 0;
            pointer-events: none;
        }
    }
    .controles {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 30%;
        left: 0px;
        transform: translateY(-50%);
        width: 100%;
        height: 0px;
        z-index: 99;
        div {
            cursor: pointer;
        }
    }

    button{
        height: 40px;
    }
`;
