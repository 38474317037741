import styled from "styled-components";

export const UploadStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.backgroundAlt};
    padding-left: 92px;
    position: relative;
    .disabled {
        pointer-events: none;
        opacity: 0.5;
    }
    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 80px;
        background-color: ${({ theme }) => theme.colors.backgroundAlt};
        position: absolute;
        top: 0px;
        right: -80px;
    }
    .container-text {
        span {
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 240px;
            button {
                margin-left: 9px;
            }
        }
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${({ theme }) => theme.colors.primary};
            width: 278px;
            strong {
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.secondary};
            }
        }
    }

    .container-input-file {
        display: flex;
        input[type="file"] {
            display: none;
        }

        input {
            width: 486px;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;

            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.primary};
            border: none;
            margin-right: 11px;
            padding-left: 18px;
            &::placeholder {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #767b7f;
            }
        }

        label {
            background: #ffffff;
            border-radius: 4px;
            padding-left: 18px;
            width: 314px;
            height: 40px;
            display: block;
            position: relative;
            .container-text {
                position: absolute;
                top: 55%;
                left: 18px;
                transform: translateY(-50%);
            }

            .container-img {
                background: #acafb1;
                border-radius: 4px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                pointer-events: none;
                transition: all 0.3s ease;
                &.active {
                    cursor: pointer;
                    pointer-events: all;
                    background-color: ${({ theme }) => theme.colors.secondary};
                    transition: all 0.3s ease;
                }
            }
        }
    }
`;

export const TableStyles = styled.div`
    padding-left: 93px;
    .table {
        .tr {
            &.header {
                padding-bottom: 31px;
                margin-bottom: 21px;
            }
        }
    }
`;

export const ReeviewStyles = styled.div`
    background: #fafafa;
    padding-left: 92px;
    display: flex;
    align-items: center;
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 80px;
        height: 57px;
        background-color: #fafafa;
        position: absolute;
        top: 0px;
        right: -80px;
    }
    ul {
        width: 698px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
            height: 100%;
            display: flex;
            align-items: center;
            p {
                font-style: normal;
                font-weight: 500;
                font-size: ${({ theme }) => theme.fonts.xs};
                line-height: 100%;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .acoes {
        margin-left: auto;
        width: 284px;
        /* width: 190px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        .background {
            background-color: ${({ theme }) => theme.colors.white};
            padding: 5px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const Container = styled.div`
    padding-right: 38px;
`;
