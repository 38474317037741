import { HeaderNovo } from "../../../Header/novo";
import { FormWhiteList } from "./form";

export function EditarPermissao() {
    return (
        <>
            <HeaderNovo title=" EDITAR PERFIL DE ADMINISTRADOR" />
            <FormWhiteList />
        </>
    );
}
