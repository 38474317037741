import { Box } from "alisson-application";
import { Button } from "../Button";
import { useTranslation } from "../../../contexts/Localization";
import { usePassos } from "../../../Hook/UsePassos";
import imgGps from "../../../assets/img/gps-verde.svg";
import imgUser from "../../../assets/img/user.svg";
import { EnderecoStyles } from "./styles";
import { formatCep } from "../../../util/helper";

export function Endereco() {
    const { t } = useTranslation();
    const { nextPasso, dados } = usePassos();

    return (
        <EnderecoStyles className="container">
            <h2>
                {t("O cartão solicitado será enviado para o endereço abaixo.")}
            </h2>

            <Box className="container-endereco">
                <div className="container-img">
                    <img src={imgGps} alt="" />
                </div>

                <div className="container-text">
                    <h3>{dados?.nome}</h3>
                    <span>
                        Nº {dados?.numero} {dados?.complemento}
                    </span>{" "}
                    <br />
                    <span>
                        {dados?.bairro} • {dados?.cidade} - {dados?.estado}
                    </span>
                    <br />
                    <span>CEP: {formatCep(dados?.cep)}</span>
                </div>
            </Box>

            <Box className="container-endereco alert" mb="56px">
                <div className="container-img">
                    <img src={imgUser} alt="" />
                </div>

                <div className="container-text">
                    <span>
                        CASO PRECISE ATUALIZAR O ENDEREÇO DE ENTREGA, ACESSE SEU
                        PERFIL PARA EDITAR ESSAS INFORMAÇÕES.ESSA ALTERAÇÃO NÃO
                        É VÁLIDA PARA CARTÕES JÁ SOLICITADOS.
                    </span>
                </div>
            </Box>

            <Button onClick={() => nextPasso(4)}>AVANÇAR</Button>
        </EnderecoStyles>
    );
}
