import { Header as DefaultHeader } from "../../../Header";
import { useUser } from "../../../../../Hook/system/useUser";
import { useConfig } from "../../../../../Hook/system/useConfig";
import { Button } from "../../../Button";
import { HeaderStyles } from "../styles";

export function Header() {
    const { searchUser } = useUser();
    const { setSearch, search } = useConfig();

    return (
        <HeaderStyles>
            <DefaultHeader title="usuário" />

            <div className="busca">
                <div className="container-input-system pesquisa">
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="BUSQUE POR NOME, CPF, E-MAIL OU CELULAR, CONTA"
                    />
                </div>

                <Button
                    onClick={async () => {
                        await searchUser(search);
                    }}
                >
                    BUSCAR
                </Button>
            </div>
        </HeaderStyles>
    );
}
