const ChevronUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
    >
      <path d="M12 7.53637C12.0005 7.6465 11.9837 7.75567 11.9505 7.85763C11.9173 7.95959 11.8685 8.05232 11.8067 8.13052C11.7447 8.20895 11.671 8.27121 11.5898 8.31369C11.5086 8.35618 11.4215 8.37805 11.3335 8.37805C11.2455 8.37805 11.1584 8.35618 11.0771 8.31369C10.9959 8.27121 10.9222 8.20895 10.8602 8.13052L6.00136 2.02163L1.14247 8.13052C1.01696 8.2881 0.846736 8.37662 0.669242 8.37662C0.581356 8.37662 0.49433 8.35489 0.413134 8.31266C0.331938 8.27044 0.258161 8.20854 0.196016 8.13052C0.133872 8.05249 0.0845757 7.95986 0.0509432 7.85792C0.0173107 7.75597 8.72116e-09 7.64671 1.0037e-08 7.53637C1.26945e-08 7.31352 0.0705092 7.09979 0.196016 6.94221L5.52813 0.247548C5.59009 0.169113 5.66381 0.106856 5.74503 0.0643714C5.82625 0.0218862 5.91337 1.36593e-05 6.00136 1.36639e-05C6.08935 1.36684e-05 6.17646 0.0218862 6.25768 0.0643714C6.3389 0.106856 6.41262 0.169113 6.47458 0.247548L11.8067 6.94221C11.8685 7.02041 11.9173 7.11314 11.9505 7.2151C11.9837 7.31706 12.0005 7.42623 12 7.53637Z" />
    </svg>
  );
};

export default ChevronUp;
