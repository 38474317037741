import { useEffect } from "react";

export function Clarity() {
    useEffect(() => {

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = `
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "nrrxh18ykr");
        `;
        document.head.appendChild(script);

        const extractAction = (url) => {
            const parsedUrl = new URL(url);
            const path = parsedUrl.pathname;
            const action = path.split('/').pop();
            return action;
        };

        const identifyAction = () => {
            if (window.clarity) {
                
                const currentUrl = window.location.href;
                const action = extractAction(currentUrl);

                window.clarity("set", "action", action);

            }
        };

        const intervalId = setInterval(() => {
            if (window.clarity) {
                identifyAction();
                clearInterval(intervalId);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
            document.head.removeChild(script);
        };
    }, []);

    return null;
}