import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { DocumentoEnviado } from "../../Mobile/DocumentoEnviado";

export function ModelBottomDocumentoEnviado(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <DocumentoEnviado />
        </ModalBottom>
    );
}
