import * as Yup from "yup";

export interface Myform {
    title: string;
    tipo: string;
    start: string;
    end: string;
}
export const initalValues: Myform = {
    title: "",
    tipo: "",
    start: "",
    end: "",
};

export const validadeForm = Yup.object().shape({
    title: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),
    start: Yup.string().required("Campo Obrigatório"),
    end: Yup.string().required("Campo Obrigatório"),
});
