import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { SaldoInsuficiente } from "../../Mobile/SaldoInsuficiente";

export function ModalSaldoInsuficiente(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <SaldoInsuficiente />
        </ModalBottom>
    );
}
