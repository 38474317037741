import styled from "styled-components";

export const ButtonEmail = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    height: 20px;
    background: #ffe9de;

    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.08em;
    color: ${(props) => props.theme.colors.primary};
    &.active {
        background: #efffee;
    }
`;
