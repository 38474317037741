import { Container } from "./styles";
import { AprovadosWhiteList } from "./Aprovados";
import { ReprovadosWhiteList } from "./Reprovados";

import { TableAprovar } from "./tableAprovar";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";
export function Content() {
    const { tabs } = useWhiteList();
    return (
        <Container>
            <div
                className={`container-tab remove-padding ${
                    tabs[0]?.active ? "active" : ""
                }`}
            >
                {tabs[0]?.active && <TableAprovar />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[1]?.active ? "active" : ""
                }`}
            >
                {tabs[1]?.active && <AprovadosWhiteList />}
            </div>
            <div
                className={`container-tab remove-padding ${
                    tabs[2]?.active ? "active" : ""
                }`}
            >
                {tabs[2]?.active && <ReprovadosWhiteList />}
            </div>
        </Container>
    );
}
