import styled from "styled-components";

export const Container = styled.div`
    padding-top: 67px;
    padding-bottom: 47px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    small {
        color: ${(props) => props.theme.colors.primary};
        display: block;
        text-align: center;
    }

    .container-img {
        position: absolute;
        left: 50%;
        top: -85px;
        transform: translateX(-50%);
    }

    .container-text {
        text-align: center;
        margin-bottom: 26px;
        h4 {
            color: #3a3b3d;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 25.2px */
            text-transform: uppercase;
            width: 313px;
            margin: 0 auto;
        }

        p {
            color: ${(props) => props.theme.colors.primary};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .container-descricao {
        background-color: #efefef;
        height: 110px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        .container-descricao-img {
        }

        .container-descricao-text {
            width: 146px;
            margin-left: 19px;
            p {
                color: #3a3b3d;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
            }

            h4 {
                color: ${(props) => props.theme.colors.secondary};
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                text-transform: uppercase;
            }
        }
    }

    .container-btn-video {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                color: ${(props) => props.theme.colors.secondary};
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-left: 4px;
            }
        }
    }

    .container-close-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            color: #3a3b3d;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
        }
    }

    .arquivo {
        border-radius: 60px;
        margin-bottom: 7px;
        justify-content: center;
        padding: 0px;
        transition: all 0.3s ease-in-out;
        &.error {
            border: 1px solid #eb5757;
            transition: all 0.3s ease-in-out;
        }
    }
    .error-input {
        color: #eb5757;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.96px;
    }
`;
