import { Text } from "alisson-application";
import { FieldProps } from "formik";
import { memo, useRef, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Permissions } from "../../Permission";

import { toast } from "react-toastify";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { getLocalStore } from "../../../../util/helper";
import { Button } from "../../Button";
import { ArrayArea, useContex } from "./Hook";

interface CamposProps extends FieldProps {}

function Campos(props: CamposProps) {
    const {
        areas,
        setAreas,
        contatos,
        setCliemtesEnUtilizacao,
        setModalAviso,
        storeArea,
        deleteArea,
        updateArea,
    } = useContex();

    const ref = useRef<HTMLInputElement>(null)
    const [foundArea, setFoundArea] = useState<ArrayArea|undefined>();

    const hasPermission = getLocalStore().permissions["cliente.area.update"];

    async function handleAddArea() {
        if (ref.current?.value) {
            const value = ref.current.value;
            const foundArea = areas.find(area => area.label === value);
            const hasIsValue = contatos.find(
                (item) =>
                    item.area.label.toLowerCase() === value.toLocaleLowerCase()
            )?.id;

            if (hasIsValue || !!foundArea?.value) {
                toast.error(`Está área já existe ${value}`);
                return;
            }

            ref.current.value = "";
            storeArea({ nome: value });
        }
    }

    async function handleRemoveArea(id: number) {
        const hasValues = contatos.filter((contato) => id === contato.area.id);

        if (hasValues.length === 0) {
            await deleteArea(id);
        } else {
            setCliemtesEnUtilizacao(hasValues);
            setModalAviso(true);
        }
    }

    async function handleChange(newValue: string, oldValue: string) {
        let id = null;

        const foundArea = areas.find(area => area.label === newValue);
        setFoundArea(foundArea);
        if (foundArea?.id) return

        const update = areas.filter((area) => {
            if (area.value === oldValue) {
                area.label = newValue;
                id = area.id;
                return area;
            }
            return area;
        });

        if (id) {
            setAreas(update);
            return await updateArea(id);
        }
    }

    return (
        <>
            <div className="container-input-system">
                <input type="text" placeholder="NOME DA ÁREA" ref={ref} />
                <Permissions role="cliente.area.store">
                    <Button onClick={handleAddArea} type="button">
                        ok
                    </Button>
                </Permissions>
            </div>

            <Text as="span" fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </Text>
            <div className="itens-areas">
                {areas.map((area) => {
                    return (
                        <div
                            key={area.id}
                            className={`container-input-system area ${
                                hasPermission ? "" : "disabled"
                            }`}
                        >
                            <DebounceInput
                                type="text"
                                placeholder="Busca"
                                debounceTimeout={1000}
                                value={area.label}
                                disabled={hasPermission ? false : true}
                                onChange={(e) => {
                                    handleChange(e.target.value, area.value);
                                }}
                            />
                            <Permissions role="cliente.area.destroy">
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(area.id)}
                                >
                                    <img src={imgX} alt="" />
                                </button>
                            </Permissions>
                        </div>
                    );
                })}

                <br />
                {foundArea?.label ? <span className="error">Campo {foundArea.label} não pode ser usado mais de uma vez!</span> : null}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
