import { getLocalStore, formatDateIsoSystem } from "../../../util/helper";
import { SubHeaderStyles } from "../LogCarga/styles";

export interface Config {
    nome: string;
    status: string;
    tipo: string;
    created_at: string;
    nome_campanha: string;
}

export function SubHeader(props: Config) {
    const { nome, created_at } = props;

    return (
        <SubHeaderStyles>
            <h3>{nome}</h3>

            <div className="container-user">
                {/* <img src={imgUser} alt="" /> */}

                <div className="container-text">
                    <h4>{getLocalStore()?.name}</h4>
                    <p>{formatDateIsoSystem(created_at)}</p>
                </div>
            </div>
        </SubHeaderStyles>
    );
}
