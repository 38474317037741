/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { usePassos } from "../../Hook/UsePassos";
import { UseModal } from "../../Hook/Usemodal";
import { useIdWall } from "../../Hook/user/useIdWall";
import { Register } from "../../components/Mobile/FormLogin/config";
import { cleanString, formatOnlyDateDb } from "../../util/helper";

export function PageIdWall() {
    const { nextPasso } = usePassos();
    const { handleClose, state } = UseModal();
    const { setIdWallUser } = useIdWall();

    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [scriptError, setScriptError] = useState(false);
    const dados = state?.dados as Register;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://sdkweb-lib.idwall.co/index.js"; // Substitua pelo URL do seu script
        script.async = true;

        const onScriptLoad = () => {
            setScriptLoaded(true);
            loadIdWall();
        };

        const onScriptError = () => {
            setScriptError(true);
        };

        script.addEventListener("load", onScriptLoad);
        script.addEventListener("error", onScriptError);

        document.body.appendChild(script);

        return () => {
            script.removeEventListener("load", onScriptLoad);
            script.removeEventListener("error", onScriptError);
            document.body.removeChild(script);
        };
    }, []);

    async function loadIdWall() {
        // @ts-ignore
        idwSDKWeb({
            token: "U2FsdGVkX1/R/Gf/cktG9GvGbr8PVLy0gkFiYi+8sBSoaxiiog==",
            onRender: (props: any) => {
                //esta chamando o passo do resgistro quando usuário clicar no botão avançar
            },
            onComplete: async (values: any): Promise<void> => {
                const { token } = values;

                try {
                    const body = {
                        token,
                        document: dados.document,
                        data_nascimento: formatOnlyDateDb(dados.data_nascimento),
                        name: dados.name,
                        email: dados.email,
                        phone_number: "55" + cleanString(dados.phone_number),
                    };

                    setIdWallUser({ ...body })
                    handleClose("idWall");
                    nextPasso(2);
                } catch (error) {
                    throw new Error("Algo deu Errado");
                }
            },
            onError: (error: any) => {
                alert(error);
            },
        });
    }
    return (
        <div>
            {scriptLoaded && <div data-idw-sdk-web></div>}
            {scriptError && <p>Falha ao carregar o script.</p>}
            {/* Seu conteúdo aqui */}
        </div>
    );
}
