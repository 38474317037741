import { NovoRelatorio } from "../../../../components/System/Relatorio/novo";
import { RelatorioProvider } from "../../../../Hook/system/useRelatorio";

export function PageNovoRelatorio() {
    return (
        <RelatorioProvider>
            <NovoRelatorio />
        </RelatorioProvider>
    );
}
