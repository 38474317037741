import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    text-align: center;
    padding-top: 74px;
    padding-bottom: 32px;
    position: relative;
    margin: 0 auto;
    img {
        position: absolute;
        top: -90px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }
    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        /* identical to box height, or 20px */

        letter-spacing: 0.04em;
        text-transform: uppercase;

        color: ${({ theme }) => theme.colors.secondary};
    }

    p {
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        margin: 0 auto;
        margin-top: 19px;
        padding: 0px 20px;
        margin-bottom: 32px;
    }
`;
