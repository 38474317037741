import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/ButtonSlim";
import { ButtonGoBack } from "../Mobile/ButtonGoBack";
import imgPerdaRoubo from "../../assets/img/cardLossTheft.svg";
import { UseModal } from "../../Hook/Usemodal";
import { UseCartoes } from "../../Hook/user/UseCartaoes";
import { Container } from "../Modais/ModalContainer/styles"

export function PerdaRoubo() {
    const { state, setState, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const history = useHistory();
    const width = window.screen.width;

    return (
        <Container>
            <img src={imgPerdaRoubo} alt="" />
            <h2 className="success">Perda ou Roubo</h2>
            <p>
                Escolha a opção com a qual deseja continuar:
            </p>
            <div className="buttonContainer">
                {cartao?.status === "ATIVO" ? (
                    <Button onClick={() => {
                        setState({
                            ...state,
                            perdaRoubo: false,
                            acaoBloquearCartao: true,
                        });
                    }}
                    >
                        Bloqueio Temporário
                    </Button>
                ) : null}

                <Button
                    onClick={() => {
                        if (width <= 480) {
                            history.push("/cancelar-cartao");
                            handleClose("perdaRoubo");
                        } else {
                            setState({
                                ...state,
                                perdaRoubo: false,
                                segundaVia: true,
                            });
                        }
                    }}
                >
                    Cancelar Cartão
                </Button>

                <ButtonGoBack onClick={() => handleClose("perdaRoubo")}>
                    Voltar ao Início
                </ButtonGoBack>
            </div>


        </Container>
    );
}
