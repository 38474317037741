import { links } from "./config";
import MeunuUikit from "./menu";
import logoImg from "../../../assets/img/logo-menu.svg";
import { getLocalStore } from "../../../util/helper";

export function Menu() {
    const permissions = Object.keys(getLocalStore().permissions);

    const arrCardsPermission = links.filter((item) => {
        return permissions.find((permission) => item.permission === permission);
    });

    return <MeunuUikit links={arrCardsPermission} logo={logoImg} />;
}
