import { Container } from "./styles";
import { useWhiteList } from "../../../../Hook/system/useWhiteList";

// import imgDownload from "../../../assets/img/dowload.svg";

export function Tabs() {
    const { tabs, handleTab } = useWhiteList();

    return (
        <Container>
            <ul>
                {tabs.map((tab) => {
                    return (
                        <li key={tab.id}>
                            <button
                                className={`${tab.className} ${
                                    tab.active ? "active" : ""
                                }`}
                                onClick={() => handleTab(tab.id)}
                            >
                                {tab.title}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </Container>
    );
}
