import styled from "styled-components";

export const Container = styled.div`
    padding: 35px 0;

    p {
        margin-bottom: 9px;
        text-align: center;
    }
    span {
        color: ${(props) => props.theme.colors.failure};
        display: flex;
        justify-content: center;
    }
    .container-input {
        input {
            height: 40px;
            padding-left: 9px;
            padding-right: 2.1rem;

            font-family: Oxanium;
        }
        &.error {
            input {
                color: ${(props) => props.theme.colors.failure} !important;
            }
        }
        &:after {
            top: 50%;
            right: 10px;
        }
    }

    .container-btn {
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
        &.active {
            opacity: 1;
            pointer-events: all;
            transition: all 0.3s ease-in-out;
        }
    }
`;
