import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormControlLabelStyled } from "./styles";

const outerTheme = createTheme({
    palette: {
        primary: {
            main: "#5DB356",
        },
    },
});

interface CheckBoxProps {
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
}

export function CheckBox(props: CheckBoxProps) {
    const { checked, disabled, onChange, label } = props;

    return (
        <ThemeProvider theme={outerTheme}>
            <FormGroup>
                <FormControlLabelStyled
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    }
                    label={
                        <div
                            dangerouslySetInnerHTML={{
                                __html: label,
                            }}
                        ></div>
                    }
                />
            </FormGroup>
        </ThemeProvider>
    );
}
