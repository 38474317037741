import { useState,useEffect } from "react";

import { phoneNumber } from "../../../util/helper";

import { Reenviar } from "./reenviar";

import { UseLogin } from "../../../Hook/user/UseLogin";
import { CodeInput } from "../../CodeInput";
import {
    Container,
    Description,
    MobileNumberViewer,
    Title,
    LoaderContainer,
} from "./styles";
import { Loader } from "../../Loader";

interface ValidarProps {
    handleClick: () => void;
    pt?: boolean;
    phone_number: string;
}

export function Validadar(props: ValidarProps) {
    const { handleClick, phone_number } = props;
    const { confirmAuthCode } = UseLogin();

    const [errorCode, setErrorCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState("");

    useEffect(() => {

        const withOutBlanks = code.replaceAll(" ", "");
        if (withOutBlanks.length < 5) return;
        
        async function validateCode(){
            setErrorCode(false);
            setIsLoading(true)
            const responseCode = await confirmAuthCode(code);
    
            if (responseCode?.data?.success) {
                handleClick();
                return;
            } else {
                setErrorCode(true);
                setCode("");
            }
            setIsLoading(false)
        }
        
        const delayInputCheck = setTimeout(() => {
            validateCode()
            return () => clearTimeout(delayInputCheck);
        }, 1500);
        return () => clearTimeout(delayInputCheck);
        
    }, [code, confirmAuthCode, handleClick]);



    return (
        <Container>
            <Title>Código de Verificação</Title>
            <Description>
                <b>Preencha com o Código de Verificação</b><br/> Enviado para o seu
                WhatsApp.
            </Description>

            {phone_number && (
                <MobileNumberViewer
                    type="text"
                    value={phoneNumber(phone_number)}
                    disabled
                />
            )}

            { isLoading 
            ? <LoaderContainer>
                <Loader width={75} height={75}/> 
            </LoaderContainer>
            : <CodeInput onChange={setCode} showError={errorCode} />}

            <Reenviar />
        </Container>
    );
}
