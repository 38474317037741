import { useEffect, useState } from "react";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";
import { Flex, Text } from "alisson-application";

import { Button } from "../Button";
import { Permissions } from "../Permission";
import { useUser, User } from "../../../Hook/system/useUser";
import { PaginationConfig } from "../../../Hook/system/config";
import { phoneNumber, formatDateIsoSystem } from "../../../util/helper";
import Table, { Pagination } from "../Table";
import { Header } from "../Header";
import { Container } from "./styles";
import { Data } from "./config";

import { Loader } from "../../Loader";
import imgEditar from "../../../assets/img/editar.svg";

export function Adminstracao() {
    const { getUsersAdm } = useUser();
    const history = useHistory();
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getUsersAdm(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setUsers(response.data);
        } catch (error) {
            console.log(error);
            setUsers([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    const dados: Data[] = users?.map((user) => {
        return {
            id: user.id,
            nome: user.name,
            login: user.email,
            celular: phoneNumber(user.phone_number),
            perfil: user?.roles?.length ? user.roles[0].name : "",
            status: user.status === 1 ? "Ativo" : "Inativo",
            ultimo_acesso: formatDateIsoSystem(
                user.updated_at ? user.updated_at : ""
            ),
            editar: "",
        };
    });

    const columns: GridColumns = [
        {
            field: "nome",
            headerName: "Nome",
            width: 274,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Flex alignItems="start" className="user">
                        {/* <img src={imgUser} alt="" /> */}
                        <Text ml="17px">{row.nome}</Text>
                    </Flex>
                );
            },
        },
        {
            field: "login",
            headerName: "Login",
            width: 274,
        },
        {
            field: "celular",
            headerName: "Celular",
            width: 274,
        },
        {
            field: "perfil",
            headerName: "Perfil",
            width: 200,
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
        },
        {
            field: "ultimo_acesso",
            headerName: "Último acesso",
            width: 274,
        },
        {
            field: "editar",
            headerName: "",
            width: 50,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/administracao/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            });
                        }}
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },
    ];

    useEffect(() => {
        async function fectData() {
            setLoading(true);
            const response = await getUsersAdm();
            const { data, ...resto } = response;
            setUsers(data);
            setConfig(resto);
            setLoading(false);
        }
        fectData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Container>
            <Header title="administradores" />

            <div className="container-btns">
                <Permissions role="role.store">
                    <button
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/administracao/permissao`,
                            })
                        }
                        className="detalhe"
                    >
                        PERFIL DE USUARIO
                    </button>
                </Permissions>

                <Button
                    spiner="BallTriangle"
                    spinerColor="primary"
                    onClick={() =>
                        history.push({
                            pathname: `/sistema/administracao/novo`,
                        })
                    }
                >
                    ADICIONAR USUÁRIO
                </Button>
            </div>

            {loading && (
                <div className="container-loading">
                    <Loader />
                </div>
            )}

            <Table
                rows={dados ? dados : []}
                columns={columns}
                nameCSV="administradores"
            />
            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
