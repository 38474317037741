import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { AlertaCreditoAdicionado } from "../../Mobile/AlertaCreditoAdicionado";
import { useUser } from "../../../Hook/user/UseUser";

export function ModalAlertaCreditoAdicionado(props: ModalConfig) {
    const { open, handleClose } = props;
    const { user, notificacoLida } = useUser();

    async function handleCloseNotificacao() {
        const ids = user.notificacoes
            .filter((item) => item.describe === "__ACTIVE_MODAL_CREDITO__")
            .map((item) => item.id);
        if (ids.length > 0) {
            try {
                ids.map(async (id) => {
                    await notificacoLida(id);
                });
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <ModalBottom
            open={open}
            handleClose={async () => {
                handleClose();
                await handleCloseNotificacao();
            }}
        >
            <AlertaCreditoAdicionado handleClose={handleCloseNotificacao} />
        </ModalBottom>
    );
}
