import { BuscaCartao } from "../../../components/System/BuscarCartao";
import { UseNonameProvider } from "../../../Hook/system/useNoname";
import { CartaoProvider } from "../../../Hook/system/useCartao";

export function PageBuscarCartao() {
    return (
        <UseNonameProvider>
            <CartaoProvider>
                <BuscaCartao />
            </CartaoProvider>
        </UseNonameProvider>
    );
}
