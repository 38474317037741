import { useContext, createContext, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
import { Produto } from "../user/UseUser";
import { User } from "./useUser";
import { PaginationConfig } from "./config";

interface UseCartaoProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IUseCartaoProps {
    getCard(page: number, search: string, tipo?: string): Promise<void>;
    // pagination: (page: number, search?: string) => Promise<void>;
    exportarCartao(): Promise<void>;
    bloquearCartao(id: number): Promise<any>;
    desbloquearCartao(id: number): Promise<any>;
    search: string;
    setSearch: (value: string) => void;
    cards: Card[];
    config: PaginationConfig;
    loading: boolean;
    currentBody: Body;
}

export interface Card {
    acg_account: string;
    acg_account_card_cvv: null | string;
    acg_account_card_expiration: string;
    acg_account_card_id: string;
    acg_account_card_number: string;
    acg_account_id: string;
    acg_comercial_origin_id: string;
    acg_produto_id: string;
    acg_sms_service: number;
    acg_account_card_last4digits: string;
    activated_at: string;
    campanha_id: number;
    canceled_at: null | string;
    celular: null | string;
    cpf: string;
    created_at: string;
    data_nascimento: null | string;
    deleted_at: null | string;
    first_active: number;
    id: number;
    nome_cartao: null | string;
    nome_completo: string;
    nome_mae: null | string;
    produto_id: number;
    requested_at: string;
    resgates_count: number;
    status: string;
    tipo: string;
    updated_at: string;
    produto: Produto;
    user: User;
    acg_tracking_id: string | null;
    logs: Log[];
    campanha: string;
    cobranca: Cobranca | null;
}

interface Cobranca {
    valor: number;
}

interface Log {
    cartao_id: number;
    content: null | string;
    created_at: string;
    id: number;
    status: string;
    updated_at: string;
}

type TipoCartao = "cartao_virtual" | "cartao_fisico" | "cartao_avulso";

export interface Body {
    search: string;
    tipo: TipoCartao | undefined;
}

export interface CardData extends PaginationConfig {
    data: Card[];
}

const UseCartaoContext = createContext<IUseCartaoProps>({} as IUseCartaoProps);

export function CartaoProvider(props: UseCartaoProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [currentBody, setCurrentBody] = useState({} as Body);

    const [cards, setCards] = useState<Card[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );

    async function getCard(page: number, search: string, tipo?: TipoCartao) {
        setLoading(true);
        const body = { search, tipo };
        try {
            const response = await api.post<CardData>(
                `cartao/search?page=${page}`,
                { ...body }
            );
            const { data = [], ...resto } = response.data;
            const update = data.map((item) => {
                const hasLast4digits = item.acg_account_card_last4digits
                    ? `**** **** **** ${item.acg_account_card_last4digits}`
                    : "-";
                return {
                    ...item,
                    acg_account_card_last4digits: hasLast4digits,
                };
            });
            setCards(update);
            setConfig(resto);
            setCurrentBody(body);
            setLoading(false);
        } catch (error: any) {
            toast.error("Erro ao carregar Cartões");
            setCards([]);
            setLoading(false);
            return error;
        }
    }

    async function bloquearCartao(id: number) {
        try {
            const response = await api.post<CardData>(`cartao/block`, {
                cartao_id: id,
            });

            await getCard(1, search);

            toast.success("Sucesso!");
            return response.data;
        } catch (error: any) {
            toast.error("Algo deu Errado");
            return error;
        }
    }

    async function desbloquearCartao(id: number) {
        try {
            const response = await api.post<CardData>(`cartao/unblock`, {
                cartao_id: id,
            });

            await getCard(1, search);

            toast.success("Sucesso!");
            return response.data;
        } catch (error: any) {
            toast.error("Algo deu Errado");
            return error;
        }
    }

    async function exportarCartao() {
        try {
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.post(
                `cartao/search-export`,
                currentBody,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            toast.error("Erro ao exportar arquivo");
        }
    }

    // const pagination = useCallback(
    //     async (page: number, search?: string) => {
    //         setLoading(true);
    //         try {
    //             await getCard(page, search);
    //         } catch (error) {
    //             console.log(error);
    //             setCards([]);
    //             setConfig({} as PaginationConfig);
    //         }
    //         setLoading(false);
    //     },
    //     [getCard]
    // )
    //cartao/search-export
    return (
        <UseCartaoContext.Provider
            value={{
                search,
                exportarCartao,
                setSearch,
                getCard,
                currentBody,
                cards,
                loading,
                config,
                bloquearCartao,
                desbloquearCartao,
            }}
        >
            {children}
        </UseCartaoContext.Provider>
    );
}

export function useCartao() {
    const context = useContext(UseCartaoContext);
    return context;
}
