import { useEffect, useState, MouseEvent } from "react";
import { ModalProps } from "./types";
import { Container } from "./styles";

export function Modal(props: ModalProps) {
    const {
        component: Component,
        active,
        bottom,
        closeModal,
        right,
        maxWidthMobile,
        ...rest
    } = props;
    const [modal, setModal] = useState(false);

    function handleModal() {
        setModal(!modal);
        if (closeModal) {
            closeModal();
        }
    }

    useEffect(() => {
        if (active) {
            document.body.style.overflow = "hidden";
            setModal(true);
        } else {
            document.body.style.overflow = "auto";
            setModal(false);
        }
    }, [active]);

    return (
        <Container
            active={modal}
            className={`${bottom ? "bottom" : ""} class-default-modal`}
            right={right}
            maxWidthMobile={maxWidthMobile}
            onClick={(e: MouseEvent<HTMLDivElement>) => {
                if (
                    (e.target as Element).classList.value.indexOf(
                        "class-default-modal"
                    ) > 0
                ) {
                    handleModal();
                }
            }}
        >
            <div className="container-body">
                <Component {...rest} />
            </div>
        </Container>
    );
}
