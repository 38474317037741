import { HeaderNovo } from "../../Header/novo";
import { FormRelatorio } from "./form";
import { Container } from "./styles";
export function NovoRelatorio() {
    return (
        <Container>
            <HeaderNovo title="NOVO RELATÓRIO" />
            <FormRelatorio />
        </Container>
    );
}
