import { memo, MouseEvent, MutableRefObject, useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult,
} from "react-beautiful-dnd";
import { Card as Cartoes } from "../../Mobile/Card";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { objCartaoes } from "../../../util/helper";
import { useStateUser } from "../../../Hook/user/UseStateUser";
import { UseModal } from "../../../Hook/Usemodal";

interface Cards {
    id: number;
    price: string;
    number: string;
    date: string;
    type: string;
    status: string;
    color: string;
    visualizarDados: boolean;
    active: boolean;
}

export interface CardProps {
    setDistancia: (value: number) => void;
    refCards: MutableRefObject<HTMLDivElement[]>;
}

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    ...draggableStyle,
});

function Card(props: CardProps) {
    const { setDistancia, refCards } = props;
    const { cartoes, cartao, setCartaoId, setSaldo, newOrder } = UseCartoes();
    const { setStateUser } = useStateUser();
    const { handleOpen } = UseModal();
    const [cards, setCards] = useState<Cards[]>([]);

    function handleClick(e: MouseEvent<HTMLElement>) {
        const offsetTop = e.currentTarget.offsetTop;
        const offsetHeight = e.currentTarget.offsetHeight / 8;

        const distancia = offsetTop + offsetHeight;
        setDistancia(distancia);
    }

    function handleClickCard(id: number) {
        const cartao = cartoes.find((cartao) => cartao.id === id);
        if (cartao?.acg_account_card_id) {
            const values = {
                type: cartao?.tipo ? cartao.tipo : "",
                saldo: 0,
                number: cartao?.acg_account_card_id
                    ? cartao.acg_account_card_id
                    : "",
                validade: "",
                itens: [1, 2, 3],
                id: id,
            };
            setStateUser(values);
        }
    }

    const onDragEnd = async (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        const [newListOrder] = cards.splice(source.index, 1);
        cards.splice(destination.index, 0, newListOrder);

        const listNewOrder = cards.map((item, index) => {
            return {
                id: item.id,
                order: index,
            };
        });
        await newOrder(listNewOrder);

        localStorage.setItem("order", JSON.stringify(cards));
        setCards(cards);
    };

    useEffect(() => {
        if (cartoes.length) {
            const newCards = objCartaoes(cartoes, false).sort(
                (a, b) => a?.order - b?.order
            );

            setCards(newCards);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartoes]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="todo">
                {(provided) => {
                    return (
                        <div
                            className="todo"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {cards.map((item, index) => {
                                return (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id + ""}
                                        index={index}
                                        isDragDisabled={cards.length < 3}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                <div
                                                    className="item-card"
                                                    key={item.id}
                                                    ref={(
                                                        el: HTMLDivElement
                                                    ) => {
                                                        refCards.current[
                                                            index
                                                        ] = el;
                                                    }}
                                                    onClick={async (
                                                        e: MouseEvent<HTMLElement>
                                                    ) => {
                                                        if (
                                                            item.status ===
                                                            "INATIVO"
                                                        ) {
                                                            setCartaoId(
                                                                item.id
                                                            );
                                                            handleClick(e);
                                                            handleClickCard(
                                                                item.id
                                                            );
                                                            handleOpen(
                                                                "ativarCartaoAvulso"
                                                            );
                                                            return;
                                                        }

                                                        if (
                                                            item.status ===
                                                            "BLOQUEADO"
                                                        ) {
                                                            setCartaoId(
                                                                item.id
                                                            );
                                                            handleClick(e);
                                                            handleClickCard(
                                                                item.id
                                                            );
                                                            handleOpen(
                                                                "acaoDesbloquearCartaoMobilePasso1"
                                                            );
                                                            return;
                                                        }

                                                        if (
                                                            item.status ===
                                                            "PROCESSANDO"
                                                        ) {
                                                            return;
                                                        }

                                                        if (
                                                            cartao?.id !==
                                                            item.id
                                                        ) {
                                                            setSaldo("");
                                                        }

                                                        setCartaoId(item.id);
                                                        handleClick(e);
                                                        handleClickCard(
                                                            item.id
                                                        );
                                                    }}
                                                >
                                                    {item.status ===
                                                    "PROCESSANDO" ? (
                                                        <div className="container-card process">
                                                            <Cartoes
                                                                {...item}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <motion.div
                                                            whileHover={{
                                                                scale: 1.05,
                                                            }}
                                                            whileTap={{
                                                                scale: 0.9,
                                                            }}
                                                            className="container-card"
                                                        >
                                                            <Cartoes
                                                                {...item}
                                                            />
                                                        </motion.div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </div>
                    );
                }}
            </Droppable>
            {/* {cards?.map((item, key) => (
                    
                ))} */}
        </DragDropContext>
    );
}

export const MemoCard = memo(Card);
