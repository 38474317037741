import styled from "styled-components";

export const Container = styled.div`
    min-height: 60vh;
    padding-bottom: 64px;
    .container-tabs {
        padding-bottom: 32px;
    }
    .container-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99px;
        border-bottom: 1px solid #e1e2e3;
        margin: 0 5%;
    }
`;

export const SegurancaStyles = styled.div`
    text-align: center;
    h2 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    p {
        width: 295px;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .container-btn {
        width: 90%;
        margin: 0 auto;
        button {
            border-radius: 600px;
        }
    }
`;
