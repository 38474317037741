import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .primeiro-item {
        font-style: normal;
        font-weight: bold;
        font-size: ${({ theme }) => theme.fonts.xss};
        line-height: 100%;
        letter-spacing: 0.08em;

        color: ${({ theme }) => theme.colors.primaryBright};
    }
    .segundo-item {
        font-style: normal;
        font-weight: 500;
        font-size: ${({ theme }) => theme.fonts.xs};
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.primaryBright};
        position: relative;
        &:before {
            content: "";
            width: 5px;
            height: 5px;
            display: block;
            border-radius: 50%;
            background: ${({ theme }) => theme.colors.secondary};
            position: absolute;
            left: -13px;
            top: 40%;
            transform: translateY(-50%);
        }
    }
`;
