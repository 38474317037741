import { useHistory } from "react-router-dom";
import { Button } from "../Mobile/ButtonSlim";
import { ButtonGoBack } from "../Mobile/ButtonGoBack";
import imgIconePagamento from "../../assets/img/cardPaid.svg";
import { Container } from "../Modais/ModalContainer/styles";
import { usePassos } from "../../Hook/UsePassos";
import { UseModal } from "../../Hook/Usemodal";
import { arrPassosBoletoPagamento } from "../PagamentoBoleto/config";

export function PagamentoRealizado() {
    const { nextPasso, setPassos, setDados } = usePassos();
    const datePagamento = new Date().toLocaleString('en-GB', {     year: "numeric",
        day: "2-digit", 
        month: "2-digit",
        hour: '2-digit', 
        minute: '2-digit' 
    }).replace(/,/g, " |").replace(/:/g, "h");

    const { handleClose } = UseModal();
    const history = useHistory();
    const width = window.screen.width;
    return (
        <Container>
            <img src={imgIconePagamento} alt="" />

            <h2 className="success">
                Pagamento Realizado<br/> com Sucesso!
            </h2>

            <p>
                {datePagamento}
            </p>

            <div className="buttonContainer">

                <Button mb="8px" onClick={() => nextPasso(6)}>
                    Exibir Comprovante em PDF
                </Button>

                <Button
                    onClick={() => {
                        setPassos(arrPassosBoletoPagamento);
                        setDados({});
                    }}
                >
                    Pagar Outra Conta
                </Button>

                <ButtonGoBack
                    onClick={() => {
                        if (width <= 480) {
                            history.goBack();
                        } else {
                            handleClose("boleto");
                        }
                    }}
                >
                    Voltar ao início
                </ButtonGoBack>
            </div>
        </Container>
    );
}
