/* eslint-disable no-useless-escape */
import * as Yup from "yup";
export const regexPassword =
    /^(?!.*(.)\1{3})(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
const date = /\d{2}\/\d{2}\/\d{4}/;

export interface Validation {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
    data_nascimento: string;
}

export const values: Validation = {
    current_password: "",
    data_nascimento: "",
    new_password: "",
    new_password_confirmation: "",
};

export const validation = Yup.object().shape({
    current_password: Yup.string().required("Campo obrigatório"),
    new_password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Campo obrigatório"),

    new_password_confirmation: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),
    data_nascimento: Yup.string()
        .matches(date, "Digite a data corretamente")
        .required("Campo obrigatório"),
});
