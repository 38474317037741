import { usePassos } from "../../../Hook/UsePassos";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useUser } from "../../../Hook/user/UseUser";
import { Container } from "./styles";
import { Conferencia } from "./conferencia";
import { Validadar } from "../Validar";
import { ConfirmaDados } from "../CartaoFisico/confirmaDados";
import { Finalizar } from "./finalizar";
// import {
//     AlertaNotificacao,
//     AtivarNotificacao,
// } from "../SolicitarResgate/ativarNotificacao";
// import { useState } from "react";

export function CartaoDigital() {
    const { passos, dados, nextPasso } = usePassos();
    const { storeCartao } = UseCartoes();
    const { getUser } = useUser();
    // const [alertaSms, setAlertaSms] = useState(false);
    // const [stateSms, setStateSms] = useState(false);

    async function handleClick() {
        const responseStore = await storeCartao(dados);
        await getUser();
        if (responseStore.status !== 500) {
            nextPasso(5);
        }
    }

    // function handleClickSms() {
    //     if (stateSms) {
    //         nextPasso(5);
    //     } else {
    //         setAlertaSms(true);
    //     }
    // }

    return (
        <Container>
            <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                {passos[1]?.active ? <ConfirmaDados /> : null}
            </div>
            <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                {passos[2]?.active ? <Conferencia /> : null}
            </div>
            {/* <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                {passos[3]?.active ? (
                    alertaSms ? (
                        <AlertaNotificacao
                            setStateSms={setStateSms}
                            onClick={() => {
                                nextPasso(5);
                            }}
                        />
                    ) : (
                        <AtivarNotificacao
                            setStateSms={setStateSms}
                            onClick={handleClickSms}
                        />
                    )
                ) : null}
            </div> */}
            <div className={`tab ${passos[3]?.active ? "active" : ""}`}>
                {passos[3]?.active ? (
                    <Validadar handleClick={handleClick} />
                ) : null}
            </div>
            <div className={`tab ${passos[4]?.active ? "active" : ""}`}>
                {passos[4]?.active ? <Finalizar /> : null}
            </div>
        </Container>
    );
}
