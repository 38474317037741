import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageAdicionarCupom } from "../../../pages/AdicionarCupom";

export function Cupom(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PageAdicionarCupom />
        </Modal>
    );
}
