import { useState } from "react";

import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { UseModal } from "../../../Hook/Usemodal";

import { Button } from "../ButtonSlim";
import { ButtonGoBack } from "../ButtonGoBack";

import { Container } from "../../Modais/ModalContainer/styles"
import imgcardTemporaryBlock from "../../../assets/img/cardTemporaryBlock.svg";

export function BloquearCartao() {
    const { bloquear } = UseCartoes();
    const { setState, state, handleClose } = UseModal();
    const { cartao } = UseCartoes();
    const [loading, setLoading] = useState(false);

    async function handleBlock() {
        setLoading(true);

        const response = await bloquear();

        setLoading(false);

        if (response.status === 200) {
            setState({
                ...state,
                acaoBloquearCartao: false,
                successGeral: {
                    active: true,
                    title: "Bloqueio",
                    message: "Bloqueado com sucesso",
                },
            });
        }
    }

    return (
        <Container>
            <img src={imgcardTemporaryBlock} alt="" />

            <h2>Bloqueio Temporário</h2>
            <p className="strong">
                Confirma o bloqueio temporário <br/> 
                {`do Cartão com final ${cartao?.acg_account_card_last4digits.slice(-4)}?`}
            </p>
            <p>
                Você pode desfazer essa ação <br/>a qualquer momento.
            </p>
            
            <div className="buttonContainer">
                <Button
                    onClick={async () => handleBlock()}
                    isLoading={loading}
                    type="submit"
                    spinerColor="primary"
                    spiner="BallTriangle"
                    mb="32px"
                >
                    Bloquear Temporariamente
                </Button>

                <ButtonGoBack onClick={() => handleClose("acaoBloquearCartao")}>
                    Voltar ao Início
                </ButtonGoBack>
            </div>
        </Container>
    );
}
