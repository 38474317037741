import styled from "styled-components";

export const FraudBtnContainer = styled.button`
  display: flex;
  position: relative;
  width: max-content;
  margin: 0 !important;
`
export const WarningContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
`
export const WarningContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  padding: 30px;

  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 400px;
  border-radius: 4px;

  h1 {
    text-transform: uppercase;
    font-family: Oxanium;
    font-size: 24px;
    font-weight: 600;

  }

  span {
    margin: 30px 0;
    font-family: Oxanium;
    font-size: 16px;
    font-weight: 400;
    text-align: center;

  }

  section {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin: 30px 0 0;

    button {
      font-size: 16px;
      padding: 16px 32px;
      border-radius: 8px;
      font-weight: 600;

      font-family: Oxanium;
      font-size: 14px;
      font-weight: 600;

      color: #fff;
      background: ${props => props.theme.colors.secondary};

      transition: all 0.4s;

      & + button {
        color: ${props => props.theme.colors.failure};
        background: #FFE3E7;
      }

      &:hover {
        scale: 1.1;
      }
    }
  }
`