import { useHistory } from "react-router-dom";
import { Button } from "../Button/status";
import { HistoryProps } from "../Carga/types";
import { HeaderStyles } from "../LogCarga/styles";
import imgSetaEsq from "../../../assets/img/seta-esq-sistema.svg";

export function Header() {
    const history = useHistory<HistoryProps>();
    return (
        <HeaderStyles>
            <div className="container-header">
                <button className="voltar" onClick={() => history.goBack()}>
                    <img src={imgSetaEsq} alt="" />
                    name
                </button>

                <div className="container-btns">
                    <Button
                        color="#fff"
                        background="#5DB356"
                        spiner="BallTriangle"
                        spinerColor="primary"
                        onClick={() => {}}
                        disabled={false}
                        isLoading={false}
                        width="100px"
                    >
                        exportar
                    </Button>
                </div>
            </div>
        </HeaderStyles>
    );
}
