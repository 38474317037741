import imgAspas from "../../../assets/img/aspas.svg";
import { DescricaoStyles } from "./styles";

interface DescricaoProps {
    descricao: string;
}

export function Descricao(props: DescricaoProps) {
    if (!props.descricao) return <></>;

    return (
        <DescricaoStyles>
            <img src={imgAspas} alt="" />
            <p>{props.descricao}</p>
        </DescricaoStyles>
    );
}
