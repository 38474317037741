import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { Text } from "alisson-application";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { Button } from "../ButtonSlim";
import { documento, validationDocumento, IDocumento } from "./config";
import { FormStyles } from "./styles";
import {
    convertDateDB,
    crypted,
    maskCPF_CNPJ,
    maskDateRegex,
} from "../../../util/helper";
import { ChangeEvent, useState } from "react";

export function Documento() {
    const { nextPasso, setDados } = usePassos();
    const { forgetPassword } = useUser();
    const [loading, setLoading] = useState(false);
    return (
        <FormStyles>
            <Text as="p">
                Preencha o campo abaixo com seu CPF/CNPJ e clique em recuperar
                senha.
            </Text>

            <Formik
                enableReinitialize
                initialValues={documento}
                validationSchema={validationDocumento}
                validateOnMount
                onSubmit={async (values, actions) => {
                    setLoading(true);
                    const birth_date = convertDateDB(values.birth_date);
                    const document = values.document.replace(/\D/g, "");
                    const dados = crypted(
                        `document#${document}`,
                        `email#${values.email}`
                    );
                    const response: any = await forgetPassword({
                        ...dados,
                        birth_date,
                        hash: dados.hash,
                        type: "whatsapp",
                    });

                    if (response.status === 200) {
                        setDados({
                            ...values,
                            document,
                            ...response.data,
                            birth_date,
                        });

                        nextPasso(2);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<IDocumento>) => {
                    const { values, setFieldValue } = props;
                    const { document } = values;

                    return (
                        <Form>
                            <div className={`container-input user `}>
                                <Field
                                    placeholder="CPF/CNPJ"
                                    name="document"
                                    type="tel"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF_CNPJ(
                                            e.target.value
                                        );

                                        if (document.length <= 18) {
                                            setFieldValue("document", document);
                                        }
                                    }}
                                    value={document}
                                />
                            </div>
                            <ErrorMessage name="documento" component="span" />
                            <div className={`container-input  calendario`}>
                                <Field
                                    name="birth_date"
                                    placeholder="Data de Nascimento"
                                    type="tel"
                                    value={values.birth_date}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        if (e.target.value.length <= 10) {
                                            setFieldValue(
                                                "birth_date",
                                                maskDateRegex(e.target.value)
                                            );
                                        }
                                    }}
                                />
                            </div>
                            <ErrorMessage
                                name="data_nascimento"
                                component="span"
                            />

                            <div className={`container-input contato`}>
                                <Field
                                    placeholder="E-mail"
                                    name="email"
                                    type="text"
                                />
                            </div>
                            <ErrorMessage name="email" component="span" />

                            <div className="mt-auto">
                                <Button
                                    type="submit"
                                    isLoading={loading}
                                    disabled={!props.isValid}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    className="login"
                                >
                                    Recuperar Senha
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
