import { useBackoffice } from "../../../../../../Hook/system/useBackoffice";
import { filterCard } from "../../../../../../util/helper";
import { Container } from "./styles";

interface CardProps {
    value: string;
}

export function Card(props: CardProps) {
    const { value } = props;
    const { cards } = useBackoffice();

    const cardsFilter = filterCard(cards, value);

    return (
        <Container>
            <div className="container-itens">
                {cardsFilter.map((card) => {
                    const keyValues = Object.entries(card);
                    return (
                        <div className="container-item" key={card.id}>
                            <div className="conatiner-header">
                                <h3>last4Digits</h3>
                                <p>{card.last4Digits}</p>
                            </div>
                            {keyValues.map((item) => {
                                const [key, value] = item;
                                return (
                                    <div key={key}>
                                        <h5>{key}</h5>
                                        <p>{value}</p>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </Container>
    );
}
