import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputHTMLAttributes, LegacyRef, useState } from "react";
import { UseFormRegister } from "react-hook-form";

import { Input } from "../Input";
import { PasswordContainer, VisibilityButton } from "./styles";
interface IInput extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
    mode?: "primary" | "secondary";
    register?: UseFormRegister<any>;
    inputRef?: LegacyRef<HTMLInputElement>;
    disableTranslation?: boolean;
}

export const PasswordInput = ({ error, register, ...rest }: IInput) => {
    const [hidePassword, setHidePassword] = useState(true);

    function handleHidePassword() {
        setHidePassword(!hidePassword);
    }

    return (
        <PasswordContainer>
            <Input
                {...rest}
                error={error}
                register={register}
                type={hidePassword ? "password" : "text"}
            />

            <VisibilityButton onClick={handleHidePassword} type="button">
                {hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </VisibilityButton>
        </PasswordContainer>
    );
};
