import axios from "axios";
import { FormikHelpers } from "formik";
import React, { createContext, useContext, useState } from "react";
import { FaleConosco } from "../../components/FaleConosco/UsuarioDeslogado/config";
import { UseModal } from "../Usemodal";
import { toast } from "react-toastify";
import { getBaseUrl } from "../../util/helper";

interface UsePrecisoDeAjudaProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UsePrecisoDeAjuda {
    loading: boolean;
    dataFaleConosco: DataFaleConosco;
    fetchFaleConosco(
        values: FormData,
        actions: FormikHelpers<FaleConosco>
    ): Promise<void>;
}

const UseCupomContext = createContext<UsePrecisoDeAjuda>(
    {} as UsePrecisoDeAjuda
);

interface DataFaleConosco {
    campanha: string;
    created_at: string;
    description: string;
    document: string;
    email: string;
    file: null | string;
    id: number;
    name: string;
    phone: string;
    updated_at: string;
}

export function UsePrecisoDeAjudaProvider(props: UsePrecisoDeAjudaProps) {
    const { children } = props;
    const { setState, state } = UseModal();
    const [loading, setLoading] = useState(false);
    const [dataFaleConosco, setdataFaleConosco] = useState<DataFaleConosco>(
        {} as DataFaleConosco
    );

    async function fetchFaleConosco(
        values: FormData,
        actions: FormikHelpers<FaleConosco>
    ) {
        setLoading(true);
        const baseUrl = getBaseUrl();
        var url = "";
        if (baseUrl) {
            url =
                baseUrl[baseUrl?.length - 1] === "/" ? baseUrl : baseUrl + "/";
        }

        try {
            const response = await axios.post<DataFaleConosco>(
                `${url}fale-conosco`,
                values
            );

            setdataFaleConosco(response.data);

            actions.resetForm();

            setState({
                ...state,
                faleConoscoUsuarioDeslogado: false,
                retornoFaleConosco: true,
            });

            setLoading(false);
        } catch (error) {
            toast.error("Algo de errado aconteceu! Tente novamente.");
            setLoading(false);
        }
    }

    return (
        <UseCupomContext.Provider
            value={{ loading, dataFaleConosco, fetchFaleConosco }}
        >
            {children}
        </UseCupomContext.Provider>
    );
}

export function usePrecisoDeAjuda() {
    const context = useContext(UseCupomContext);
    return context;
}
