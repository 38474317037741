import { AxiosResponse } from "axios";
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { Myform } from "../../components/System/Cupom/config";
import { Campanha } from "./useCampanha";
import { User } from "./useUser";
import { PaginationConfig } from "./config";

import { toast } from "react-toastify";
import { api } from "../../services/";

interface UseCupomProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface StoreCupom extends Myform {
    campanhas: number[];
}

interface UseCupomData {
    store: (values: StoreCupom) => Promise<AxiosResponse>;
    getCupons: (page?: number) => Promise<void>;
    updateCupom(ids: number[]): Promise<void>;
    exportar(id?: number): Promise<void>;
    cupons: Cupom[];

    fetchShowCupons: (page?: number, serach?: string) => Promise<void>;
    showCupons: ShowCupom;

    loading: boolean;
    loadingCupom: boolean;
    configCupons: PaginationConfig;
}

const UseClienteContext = createContext<UseCupomData>({} as UseCupomData);

interface CupomData extends PaginationConfig {
    data: Cupom[];
}

interface Cupom {
    id: number;
    campanhas: Campanha[];
    prefixo: string;
    quantidade: number;
    created_at: string;
    updated_at: string;
    user: User;
    total_utilizado: number;
}

// interface ShowCupomData extends PaginationConfig {
//     data: ShowCupom;
// }

interface ShowCupom {
    id: number;
    campanhas: number[];
    prefixo: string;
    quantidade: number;
    created_at: string;
    updated_at: string;
    itens: Itens[];
}

interface Itens {
    id: number;
    cupom_id: number;
    campanha_id: number;
    user_id: null | number;
    codigo: string;
    validade: string;
    valor: string;
    created_at: string;
    updated_at: string;
    user: User;
    status: number;
}

export function CupomProvider(props: UseCupomProviderProps) {
    const { children } = props;
    const [loading, setLoading] = useState(false);
    const [loadingCupom, setLoadingCupom] = useState(false);
    const [cupons, setCupons] = useState<Cupom[]>([]);
    const [showCupons, setShowCupons] = useState<ShowCupom>({} as ShowCupom);

    const [configCupons, setConfigCupons] = useState<PaginationConfig>(
        {} as CupomData
    );
    const history = useHistory<{ id: number }>();
    const path = history.location.pathname.replace(/[0-9]/g, ":id");
    const id = history.location.state?.id;

    async function store(values: StoreCupom) {
        const dados = {
            ...values,
            itens: values.itens.map((item) => {
                const currentValue = parseInt(
                    item.valor.replace(/[^0-9]/g, "").trim()
                );
                return {
                    quantidade: parseInt(item.quantidade),
                    valor: currentValue / 100,
                };
            }),
            validade: values.validade.split(" ")[0],
        };

        try {
            const response = await api.post(`cupom`, {
                ...dados,
            });

            toast.success("Cupom criado com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao carregar cupom");
            return error;
        }
    }

    const getCupons = useCallback(async (page?: number) => {
        setLoading(true);
        const hasPage = page ? `&page=${page}` : "";
        try {
            const response = await api.get<CupomData>(`cupom${hasPage}`);
            const { data, ...resto } = response.data;

            setCupons(data);
            setConfigCupons(resto);
            setLoading(false);
        } catch (error: any) {
            toast.error("Erro ao carregar cupons");
            setCupons([]);
            setLoading(false);
        }
    }, []);

    const fetchShowCupons = useCallback(
        async (page?: number, search?: string) => {
            setLoadingCupom(true);
            const hasSearch = search ? `&search=${search}` : "";
            const hasPage = page ? `&page=${page}` : "";
            if (!id) return;
            try {
                const response = await api.get<ShowCupom>(
                    `cupom/${id}?${hasPage}${hasSearch}`
                );
                const { data } = response;

                setShowCupons(data);
                setLoadingCupom(false);
            } catch (error: any) {
                toast.error("Erro ao carregar cupons");
                setShowCupons({} as ShowCupom);
                setLoadingCupom(false);
            }
        },
        [id]
    );

    async function updateCupom(ids: number[]) {
        try {
            await api.post(`cupom/${id}/itens-status`, {
                itens: ids,
            });
            await fetchShowCupons();
            toast.success("Cupom atualizado com sucesso");
        } catch (error) {
            console.log(error);
            toast.error("Erro ao atualizar cupom");
        }
    }

    async function exportar(cupom_id?: number) {
        try {
            setLoading(true);
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(
                `cupom/${cupom_id ? cupom_id : id}/export`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            setLoading(false);
            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);
            setLoading(false);
            toast.error("Erro ao exportar arquivo");
        }
    }

    useEffect(() => {
        if (path === "/sistema/cupom") {
            getCupons();
        }
        if (path === "/sistema/cupom/detalhe/:id") {
            fetchShowCupons();
        }
    }, [getCupons, fetchShowCupons, path]);

    return (
        <UseClienteContext.Provider
            value={{
                getCupons,
                store,
                exportar,
                updateCupom,
                loading,
                loadingCupom,
                cupons,
                showCupons,
                configCupons,
                fetchShowCupons,
            }}
        >
            {children}
        </UseClienteContext.Provider>
    );
}

export function useCupom() {
    const context = useContext(UseClienteContext);

    return context;
}
