import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 32px;
    .container-header {
        .container-add-image {
        }
    }
    form {
        .container-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 290px;
            margin: 0 auto 17px;
            button {
                &.btn-verificar {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    margin-left: auto;
                }
            }
        }
    }

    .container-header {
        display: flex;
        align-items: center;
        height: 150px;
        .container-add-image {
            margin-left: auto;
            img {
                width: 30px;
                margin: 0px;
            }
        }
        .crop {
            position: relative;
            p {
                position: absolute;
                bottom: -26px;
                white-space: nowrap;
            }

            .container-inserir-foto {
                position: absolute;
                top: 59px;
                left: 40%;
                transform: translateX(-50%);
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                    width: 16px;
                    height: auto;
                    margin: 0px;
                    margin: 0 auto;
                    /* position: absolute; */
                    /* top: 0px; */
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 100%;
                    text-align: center;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    position: static;
                    margin-top: 2px;
                }
            }
        }
        img {
            border-radius: 50%;
            display: block;
            margin-right: 19px;
            width: 69px;
            height: 69px;
        }

        .container-text {
            h2 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: ${(props) => props.theme.colors.primary};
            }

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
                margin-bottom: 5px;
            }
        }
    }

    .container-senha {
        display: flex;
        align-items: center;
        height: 62px;
        border-top: 1px solid #e1e2e3;
        border-bottom: 1px solid #e1e2e3;
        margin-bottom: 23px;
        width: 100%;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            margin-left: 8px;
            margin-top: 2px;
        }

        img {
            &.seta {
                margin-left: auto;
            }
        }
    }

    .container-input {
        &.active {
            &:after {
                display: none;
            }
        }
    }
`;

export const EnderecoStyles = styled.div`
    padding-top: 34px;
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        display: block;
        margin-bottom: 8px;
    }

    .container-endereco {
        border: 1px solid ${(props) => props.theme.colors.secondary};
        border-radius: 8px;
        padding-left: 27px;
        padding-top: 21px;
        padding-bottom: 10px;
        display: flex;
        align-items: start;
        .container-text {
            margin-left: 16px;
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
                text-transform: uppercase;
            }

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
                text-transform: uppercase;
            }

            button {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.secondary};
                margin-top: 16px;
                text-transform: uppercase;
            }
        }
    }
`;
