import { AxiosResponse } from "axios";
import { createContext, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../../../services";
import { ModalFormProps } from "../Modal/config";

interface ModalNovoClienteProps {
    store: (values: Myform) => Promise<AxiosResponse>;

    setModalAlert: (value: boolean) => void;
    modalAlert: boolean;

    setModal: (value: boolean) => void;
    modal: boolean;

    setContatos: (value: ModalFormProps[]) => void;
    contatos: ModalFormProps[];

    setContato: (value: ModalFormProps) => void;
    contato: ModalFormProps;

    setAreas: (value: ArrayArea[]) => void;
    areas: ArrayArea[];

    setModalAviso: (value: boolean) => void;
    modalAviso: boolean;

    setCliemtesEnUtilizacao: (value: ModalFormProps[]) => void;
    cliemtesEnUtilizacao: ModalFormProps[];
}

interface ProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface ArrayArea {
    label: string;
    value: string;
}

interface Contatos {
    nome: string;
    telefone: string;
    celular: string;
    email: string;
}

interface Areas {
    nome: string;
    contatos: Array<Contatos>;
}

export interface Myform {
    nome: string;
    razao_social: string;
    cnpj: string;
    areas: Areas[];
}

const Context = createContext<ModalNovoClienteProps>(
    {} as ModalNovoClienteProps
);

export function NovoClienteProvider(props: ProviderProps) {
    const { children } = props;
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [modalAviso, setModalAviso] = useState(false);
    const [modalAlert, setModalAlert] = useState(false);
    const [areas, setAreas] = useState<ArrayArea[]>([]);

    const [contatos, setContatos] = useState<ModalFormProps[]>([]);
    const [contato, setContato] = useState<ModalFormProps>(
        {} as ModalFormProps
    );
    const [cliemtesEnUtilizacao, setCliemtesEnUtilizacao] = useState<
        ModalFormProps[]
    >([]);

    async function store(values: Myform) {
        try {
            const response = await api.post<AxiosResponse>("/cliente", {
                ...values,
            });
            toast.success("Cliente cadastrado com sucesso");
            history.push("/sistema/cliente");
            return response;
        } catch (error: any) {
            toast.error("Erro ao cadastrar cliente");
            return error;
        }
    }

    return (
        <Context.Provider
            value={{
                store,

                modal,
                setModal,

                setContatos,
                contatos,

                contato,
                setContato,

                setAreas,
                areas,

                setModalAviso,
                modalAviso,

                cliemtesEnUtilizacao,
                setCliemtesEnUtilizacao,

                modalAlert,
                setModalAlert,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useContex() {
    const context = useContext(Context);
    return context;
}
