import { ChangeEvent } from "react";
import { Text } from "alisson-application";
import { Button } from "../Button";
import { Permissions } from "../Permission";
import { links } from "../Menu/config";
import imgLupa from "../../../assets/img/lupa.svg";
import imgAction from "../../../assets/img/action.svg";

import { Container } from "./styles";
interface HeaderProps {
    title: string;
    titleBtn?: string;
    onClick?: () => void;
    onClickBtn?: () => void;
    onChange?: (e: string) => void;
    hasFilter?: boolean;
    permission?: string;
    image?: string;
}
export function Header(props: HeaderProps) {
    const {
        title,
        onClickBtn,
        onClick,
        onChange,
        titleBtn,
        permission,
        image,
    } = props;
    const lastBar = window.location.pathname.lastIndexOf("/");
    const path = window.location.pathname.substring(lastBar + 1);

    const icone = image
        ? image
        : links.find((item) => item.href.split("/")[2] === path)?.icon;

    return (
        <Container icone={icone ? icone : ""}>
            <div className="container-title">
                <Text as="p" textTransform="uppercase">
                    {title}
                </Text>
            </div>

            {titleBtn ? (
                <div className="container-acoes">
                    {onChange && (
                        <div className="container-filtro">
                            <input
                                type="text"
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    if (onChange) {
                                        onChange(e.target.value);
                                    }
                                }}
                            />
                            <img src={imgLupa} alt="" />
                        </div>
                    )}

                    {onClick && (
                        <Permissions role={permission ? permission : ""}>
                            <button onClick={onClick} className="action">
                                <img src={imgAction} alt="" />
                            </button>
                        </Permissions>
                    )}

                    {titleBtn && (
                        <Permissions role={permission ? permission : ""}>
                            <Button className="add" onClick={onClickBtn}>
                                {titleBtn}
                            </Button>
                        </Permissions>
                    )}
                </div>
            ) : null}
        </Container>
    );
}
