import { useHistory } from "react-router-dom";
import { UseModal } from "../../Hook/Usemodal";

import Modal from "../../components/NovoModal";
import ModalBottom from "../../components/ModalBottom";
import SimpleAccordion from "../../components/Accordion";

import imgLogo from "../../assets/img/nova-logo.svg";
import imgContato from "../../assets/img/menu/contato.svg";
import { Footer } from "../System/Footer";

import { Container } from "./styles";
import { FaleConosco } from "../FaleConosco/UsuarioDeslogado";
import { RetornoContato } from "../RetornoContato";

const duvidas = [
    {
        title: "O que é Campanha YETZPAY?",
        description:
            "É modo pelo qual as empresas contratantes premiam seus funcionários através de cartão pré-pago físico e/ou virtual. A empresa define o escopo da campanha, suas metas, regras, periodicidade e parâmetros de premiação. Além disso, o contratante tem como premissa definir quais dos cartões pré-pagos físicos e/ou virtuais estarão disponíveis para solicitação, podendo customizar sua campanha.",
    },
    {
        title: "Como sei qual é minha Campanha?",
        description:
            "Esta informação deve ser fornecida por sua Gestão ou por quem lhe forneceu o cartão avulso.",
    },
    {
        title: "Qual é meu Login?",
        description: "Seu LOGIN será sempre o seu número de CPF ou CNPJ.",
    },
    {
        title: "Onde encontro o número da conta do meu cartão?",
        description: "No verso do cartão consta a conta do seu cartão físico.",
    },
    {
        title: "Como faço para me cadastrar na plataforma YETZPAY?",
        description:
            "Para se cadastrar na Plataforma, é necessário que a empresa em que você trabalha faça um pré-cadastramento junto a YETZPAY ou que você tenha recebido um cartão avulso em mãos, vinculado a uma Campanha.",
    },
    {
        title: "Ao realizar meu cadastro não recebi o SMS o que faço?",
        description: `<p>Se seu celular tem um sistema Android:</p> 
        <br />
        <p>
            Abra o app Telefone e toque em Mais, depois toque em
            “Configurações” e em seguida “Números bloqueados”,
            desbloqueie todos os números com 5 caracteres, já que o
            SMS da YETZPAY utiliza esse padrão de telefone. Para
            isso toque em limpar = X e em seguida em desbloquear.
        </p>
        <br />
        
        <p>
            Se seu celular tem um sistema IOS: 
            <br />
            <br />
            Acesse “Ajustes” do seu iPhone, Entre na opção “Mensagens” em
            seguida toque em “Contatos Bloqueados” e você verá uma
            lista dos números bloqueados, Toque em “Editar”,
            desbloqueie todos os números com 5 caracteres, já que o
            SMS da YETZPAY utiliza esse padrão de telefone, toque no
            “Ícone vermelho com sinal de menos” e em seguida no
            botão “Desbloquear”, para finalizar, toque em “OK”.
        </p>

        <p>
            Caso não funcione o desbloqueio, reinicie o telefone e
            faça uma nova tentativa, se ainda assim não funcionar,
            tente novamente utilizando um número de celular
            diferente do informado anteriormente ou utilize a opção
            de validação via WhatsApp que não possui nenhum bloqueio
            de recebimento.
        </p>`,
    },

    {
        title: "Quero alterar minha senha de login. O que eu faço?",
        description:
            "Para alterar a senha de login na plataforma YETZPAY, acesse MENU clique em PERFIL em seguida ALTERAR SENHA, a senha deve ser composta de 8 números, lembrando que a secreta, pessoal e intransferível, utilizada para acessar e realizar todas as operações na Plataforma YETZPAY.",
    },
    {
        title: "Esqueci minha senha. O que eu faço?",
        description:
            "Ao acessar a plataforma, na tela de LOGIN (CPF ou CNPJ) clique no botão ESQUECI MINHA SENHA. Informe novamente o LOGIN (CPF ou CNPJ) e clique em ENVIAR escolhendo o modo que deseja receber, se por SMS ou WhatsApp. Você receberá o código de verificação no número de telefone cadastrado na Plataforma, após isso a plataforma habilitará o campo para realizar a alteração de senha.",
    },
    {
        title: "Esqueci minha Senha e não tenho mais acesso ao número de telefone cadastrado na Plataforma. O que devo fazer?",
        description:
            "Caso não tenha acesso ao número de telefone cadastrado na Plataforma, procure o gestor na empresa que trabalha ou quem lhe enviou o cartão avulso, para que ele possa solicitar a Equipe interna YETZPAY o reset de senha.",
    },
    {
        title: "A redefinição de senha não chega no celular cadastrado e agora?",
        description: `
        <p>
            Pode ser que o haja algum tipo de bloqueio por parte de
            seu aparelho ou operadora para receber determinados
            tipos de SMS. Orientamos a seguir as opções abaixo:
        </p>
        <br />


        <p>Se seu celular tem um sistema Android:</p>
        <br />

        <p>
            Abra o app Telefone e toque em Mais, depois toque em
            “Configurações” e em seguida “Números bloqueados”,
            desbloqueie todos os números com 5 caracteres, já que o
            SMS da YETZPAY utiliza esse padrão de telefone. Para
            isso toque em limpar = X e em seguida em desbloquear.
        </p>

        <br />

        <p>Se seu celular tem um sistema IOS:</p>

        <p>
            Acesse “Ajustes” do seu iPhone, Entre na opção
            “Mensagens” em seguida toque em “Contatos Bloqueados” e
            você verá uma lista dos números bloqueados, Toque em
            “Editar”, desbloqueie todos os números com 5 caracteres,
            já que o SMS da YETZPAY utiliza esse padrão de telefone,
            toque no “Ícone vermelho com sinal de menos” e em
            seguida no botão “Desbloquear”, para finalizar, toque em
            “OK”. <br /> <br />Caso não funcione o desbloqueio, reinicie o
            telefone e faça uma nova tentativa, se ainda assim não
            funcionar, você também pode realizar a validação através
            do WhatsApp que não possui nenhum tipo de bloqueio ou
            por questões de segurança será necessário solicitar o
            reset de senha ao Gestor da sua operação (que solicitará
            à YETZPAY) e assim que receber a nova senha, não deixe
            de validar o número de celular cadastrado na Plataforma,
            acessando o MENU e após PERFIL.
        </p>`,
    },
    {
        title: "Fui desligado ou solicitei desligamento da empresa e ainda tenho saldo na carteira na plataforma que não foram utilizados. Até quando posso usá-los?",
        description:
            "Você terá acesso a plataforma até finalizar/zerar o crédito do cartão e/ou SALDO DA CARTEIRA.",
    },
    {
        title: "Ocorreu erro “NÃO ENCONTRADO – Dados não encontrados” ao tentar me logar e ao digitar Campanha, o que fazer?",
        description:
            "Esta falha ocorre quando você digita algum dado (LOGIN“CPF ou CNPJ” ou Campanha) diferente do cadastrado na Plataforma ou caso seu usuário esteja bloqueado por algum motivo. Nessas situações é necessário informar ao seu Gestor para que ele analise e entre em contato com a YETZPAY.",
    },
    {
        title: "Ocorreu Senha Inválida, o que fazer?",
        description:
            "Caso você digite a senha incorreta o sistema vai informar Senha Inválida. Se lembrar da senha digite-a novamente; caso não saiba clique em Esqueci minha senha, mas esta opção funcionará apenas se você tiver validado seu celular anteriormente; quando não, por questões de segurança, será necessário solicitar o reset de senha ao Gestor ou a quem lhe entregou o cartão avulso.",
    },
    {
        title: "Como entro em contato com a YETZPAY?",
        description: `
        <p>
        Se mesmo com a leitura dos itens acima você não
        conseguir acessar a Plataforma, clique e envie uma
        mensagem para “AINDA PRECISA DE AJUDA?”. Este canal é
        exclusivo para dúvidas sobre acesso à Plataforma. Todas
        as demais questões serão respondidas exclusivamente
        através do FALE CONOSCO da área logada, após acesso, em
        MENU, FALE CONOSCO ou através do e-mail
        <a href="/asd">cards@yetzpay.com.br.</a>
    </p>`,
    },
];

export function PrecisoDeAjuda() {
    const history = useHistory();
    const { handleClose, handleOpen, state } = UseModal();

    return (
        <Container>
            <div className="container-fale-conosco">
                <div className="container-header">
                    <div className="container-title">
                        <img
                            src="https://www.yetzcards.com.br/img/novo-login/interrogacao.svg"
                            alt=""
                        />

                        <span>
                            precisa <br /> de ajuda?
                        </span>
                    </div>

                    <div className="container-img">
                        <img src={imgLogo} alt="" />
                    </div>

                    <button
                        className="btn-voltar"
                        onClick={() => history.push("/")}
                    >
                        voltar
                    </button>
                </div>

                <div className="container-content">
                    {duvidas.map((item) => (
                        <SimpleAccordion {...item} />
                    ))}
                </div>

                <div className="container-btns">
                    <div className="fale-conosco">
                        <span>ainda precisa de ajuda?</span>

                        <button
                            className="contato"
                            onClick={() =>
                                handleOpen("faleConoscoUsuarioDeslogado")
                            }
                        >
                            <img src={imgContato} alt="" /> fale conosco
                        </button>
                    </div>

                    <button
                        className="btn-voltar"
                        onClick={() => history.push("/")}
                    >
                        voltar
                    </button>
                </div>
            </div>

            <div className="footer">
                <Footer />
            </div>

            <Modal
                open={state?.faleConoscoUsuarioDeslogado}
                handleClose={() => handleClose("faleConoscoUsuarioDeslogado")}
            >
                <FaleConosco />
            </Modal>

            <ModalBottom
                open={state?.retornoFaleConosco}
                handleClose={() => handleClose("retornoFaleConosco")}
            >
                <RetornoContato />
            </ModalBottom>
        </Container>
    );
}
