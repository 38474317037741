import React, { createContext, useContext, useState } from "react";
import imgCartoFisico from "../../assets/img/tutorial/cartaoFisico.png";
import imgCartaoVirtual from "../../assets/img/tutorial/cartaoVirtual.png";
import imgBoleto from "../../assets/img/tutorial/pagamentoContas.png";
import imgTransferencia from "../../assets/img/tutorial/transferencia.png";

interface UseTutorialProps {
    children: React.ReactChild | React.ReactChild[];
}

interface Tutorial {
    img: string;
    text: string;
    link: string;
}

interface UseTutorial {
    tutorial: Tutorial[];
    setTutorial: (value: Tutorial[]) => void;
    filterTutorial(value: string): void;
}

const dados = [
    {
        img: imgCartoFisico,
        text: "Como Solicitar um Cartão Físico YetzPay",
        link: "https://vimeo.com/871614988/96d9b1b1b0",
    },
    {
        img: imgCartaoVirtual,
        text: "Como Solicitar um Cartão Virtual YetzPay",
        link: "https://vimeo.com/866966960/c7c7b7bebd",
    },
    {
        img: imgBoleto,
        text: "Pagamento de Contas",
        link: "https://vimeo.com/872182027/930ffc806d",
    },
    {
        img: imgTransferencia,
        text: "Realize a Transferência de Saldo da Carteira para o Cartão",
        link: "https://vimeo.com/867316368/172831be02",
    },
];

const UseTutorialContext = createContext<UseTutorial>({} as UseTutorial);

export function UseTutorialProvider(props: UseTutorialProps) {
    const { children } = props;
    const [tutorial, setTutorial] = useState(dados);

    function filterTutorial(value: string) {
        if (value === "") return setTutorial(dados);

        const filter = dados.filter((item) =>
            JSON.stringify(item, ["text", "link"])
                .toLocaleLowerCase()
                .includes(value.toLocaleLowerCase())
        );
        setTutorial(filter);
    }

    return (
        <UseTutorialContext.Provider
            value={{ tutorial, setTutorial, filterTutorial }}
        >
            {children}
        </UseTutorialContext.Provider>
    );
}

export function useTutorial() {
    const context = useContext(UseTutorialContext);
    return context;
}
