import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageDadosCartao } from "../../../pages/DadosCartao";

export function DadosCartao(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PageDadosCartao />
        </Modal>
    );
}
