import { LogCarga } from "../../../components/System/LogCarga";
import { UseLogCargaProvider } from "../../../Hook/system/useLogCarga";
import { InfraProvider } from "../../../components/System/Carga/Content/Infra/Hook";
import { CreditoProvider } from "../../../components/System/Carga/Content/Credito/Hook";
import { CreditoProvider as CartaoDeCreditoNoname } from "../../../components/System/Carga/Content/CartaoDeCredito/Hook";

export function LogCargaPage() {
    return (
        <UseLogCargaProvider>
            <InfraProvider>
                <CreditoProvider>
                    <CartaoDeCreditoNoname>
                        <LogCarga />
                    </CartaoDeCreditoNoname>
                </CreditoProvider>
            </InfraProvider>
        </UseLogCargaProvider>
    );
}
