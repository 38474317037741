import { Text, Box } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { useHistory } from "react-router-dom";
import { useSegmentacao } from "../../../../Hook/system/useSegmentacao";
import {
    Myform,
    initalValues,
    validadeForm,
    validadeFormNotificacao,
    validadeFormPopUp,
    validadeFormSms,
    validadeFormWhats,
    formNotificacao,
    formPopUp,
    formSms,
    formWhats,
} from "./config";

import { Button } from "../../Button";
import { Notificacao } from "./notificacao";
import { File } from "./file";
import { PopUp } from "./popUp";
import { WhatsApp } from "./whatsapp";
import { Sms } from "./sms";
import Select, { options } from "../../NovoSelect";

import { FormStyles } from "./styles";
import { ButtonSegmentacao } from "../../Segmentacao/Button";
import { useFile } from "../hook";
import { useEffect, useState } from "react";

interface FormProps {}
export type keyOfCarga<T> = keyof T;

export function FormComunicacao(props: FormProps) {
    const { setFiles, files, arqFiles, setArqFiles } = useFile();
    const [values, setValues] = useState(initalValues);
    const { segmentacaoArea, setSegmentacao, store, campanhas, setCampanhas } =
        useSegmentacao();
    const history = useHistory();
    const [validadtion, setValidadtion] = useState<any>(validadeForm);
    const [canal, setCanal] = useState(0);
    const [loading, setLoading] = useState(false);

    const options: options[] = [
        {
            label: "NOTIFICAÇÃO",
            value: 1,
        },
        {
            label: "POPUP",
            value: 2,
        },
        {
            label: "WHATSAPP",
            value: 3,
        },
        {
            label: "SMS",
            value: 4,
        },
    ];

    const segmentacao: options[] = [
        {
            label: "SEGMENTO",
            value: 1,
        },
        {
            label: "ARQUIVO",
            value: 2,
        },
        {
            label: "ATIVOS",
            value: 3,
        },
    ];

    const handleType = (values: Myform, type: string) => {
        if (type === "NOTIFICAÇÃO") {
            setValues({ ...values, ...formNotificacao });
            setValidadtion(validadeFormNotificacao);
        }

        if (type === "POPUP") {
            setValues({ ...values, ...formPopUp });
            setValidadtion(validadeFormPopUp);
        }

        if (type === "WHATSAPP") {
            setValues({ ...values, ...formWhats });
            setValidadtion(validadeFormWhats);
        }

        if (type === "SMS") {
            setValues({ ...values, ...formSms });
            setValidadtion(validadeFormSms);
        }
    };

    useEffect(() => {
        setFiles(null);
        setCampanhas([]);
    }, [canal, setFiles, setCampanhas]);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validadtion}
                onSubmit={async (values, actions) => {
                    const formData = new FormData();

                    const keys = Object.keys(values) as keyOfCarga<Myform>[];

                    const removeItens = [
                        "arte_mobile",
                        "arte_desktop",
                        "recipientes",
                        "segmentacao",
                    ];

                    if (canal === 1) {
                        if (segmentacaoArea.length === 0) {
                            actions.setFieldError(
                                "segmentacao",
                                "Campo obrigatório"
                            );
                            return;
                        }

                        formData.append("campanhas", JSON.stringify(campanhas));
                    }

                    if (canal === 2) {
                        if (!arqFiles?.name) {
                            actions.setFieldError(
                                "recipientes",
                                "Selecione o Arquivo opa"
                            );
                            return;
                        }

                        formData.append(
                            "recipientes",
                            arqFiles as unknown as File
                        );
                    }
                    setLoading(true);

                    keys.forEach((key) => {
                        if (!removeItens.includes(key)) {
                            formData.append(key, values[key] as string);
                        }
                    });

                    if (files?.arte_desktop) {
                        formData.append("arte_desktop", files.arte_desktop);
                    }

                    if (files?.arte_mobile) {
                        formData.append("arte_mobile", files?.arte_mobile);
                    }

                    // keys.forEach((item) => {
                    //     console.log(formData.get(item));
                    // });

                    const response = await store(formData);
                    setSegmentacao([]);

                    if (response.status === 200) {
                        setFiles(null);
                        setArqFiles(null);
                        history.goBack();
                    } else {
                        setFiles(null);
                        setArqFiles(null);
                    }

                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { setFieldValue, values } = props;
                    const { tipo } = values;

                    return (
                        <Form>
                            <Text mb="8px" fontWeight={500} fontSize="xss">
                                DADOS DA EMPRESA
                            </Text>

                            <div className="container-input-system comunicacao">
                                <Field
                                    type="text"
                                    placeholder="TÍTULO DA MENSAGEM"
                                    name="titulo"
                                />
                            </div>
                            <ErrorMessage component="span" name="titulo" />

                            <Select
                                label="TIPO DE MENSAGEM"
                                options={options}
                                onChange={(option) => {
                                    let value = option.label;
                                    value = value.replace(/Ç/, "C");
                                    value = value.replace(/Ã/, "A");

                                    setFieldValue("tipo", value);
                                    setFiles(null);
                                    handleType(
                                        { ...values, tipo: value },
                                        value
                                    );
                                }}
                                icon={false}
                            />
                            <ErrorMessage component="span" name="tipo" />

                            <Select
                                label="SEGMENTAR"
                                options={segmentacao}
                                onChange={(values) => {
                                    setFieldValue("canal", values.label);
                                    setCanal(values.value as number);
                                }}
                                icon={false}
                            />
                            <ErrorMessage component="span" name="canal" />

                            {canal === 1 && (
                                <>
                                    <ButtonSegmentacao />
                                    {segmentacaoArea.length === 0 && (
                                        <ErrorMessage
                                            component="span"
                                            name="segmentacao"
                                        />
                                    )}
                                </>
                            )}

                            {canal === 2 ? (
                                <div className="container-input-system">
                                    <Field component={File} />
                                    <ErrorMessage
                                        component="span"
                                        name="recipientes"
                                    />
                                </div>
                            ) : null}

                            {tipo === "NOTIFICACAO" && (
                                <div className="container-input-system">
                                    <Field component={Notificacao} />
                                </div>
                            )}

                            {tipo === "POPUP" && (
                                <div className="container-input-system">
                                    <Field component={PopUp} />
                                </div>
                            )}

                            {tipo === "WHATSAPP" && (
                                <div className="container-input-system">
                                    <Field component={WhatsApp} />
                                </div>
                            )}

                            {tipo === "SMS" && (
                                <div className="container-input-system">
                                    <Field component={Sms} />
                                </div>
                            )}

                            <Box width="220px" mt="30px" ml="auto">
                                <Button
                                    isLoading={loading}
                                    spiner="BallTriangle"
                                    spinerColor="primary"
                                    type="submit"
                                >
                                    AVANÇAR
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
