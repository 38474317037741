import { ModalConfig } from "../config";

import { FaleConosco } from "../../FaleConosco";
import { UseFaleConoscolProvider } from "../../../Hook/user/UseFaleConosco";
import Modal from "../../ModalRight";

export function ModalFaleConosco(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <Modal open={open} handleClose={handleClose}>
            <UseFaleConoscolProvider>
                <FaleConosco handleClose={handleClose} />
            </UseFaleConoscolProvider>
        </Modal>
    );
}
