import { HeaderNovo } from "../Header/novo";
import { FormUsuarios } from "./form";

export function EditarAdministrador() {
    return (
        <>
            <HeaderNovo title="editar administrador" />
            <FormUsuarios />
        </>
    );
}
