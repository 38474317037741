import { useEffect, useState } from "react";
import { useAprovacaoTransferencia } from "../../../Hook/system/useAprovacaoTransferencia";

import { Button } from "../Button/status";
import { HeaderStyles } from "./styles";

// import imgDownload from "../../../assets/img/dowload.svg";

export function Tabs() {
    const { tabs, handleTab, ids, setModalAprovar, setModalReprovar } =
        useAprovacaoTransferencia();
    const [disabled, setDisabled] = useState(true);

    const hasAprovar = tabs.find((item) => item.className === "aprovar");

    useEffect(() => {
        setDisabled(!ids.length);
    }, [ids]);

    return (
        <HeaderStyles>
            <ul>
                {tabs.map((tab) => {
                    return (
                        <li key={tab.id}>
                            <button
                                className={`${tab.className} ${
                                    tab.active ? "active" : ""
                                }`}
                                onClick={() => handleTab(tab.id)}
                            >
                                {tab.title}
                            </button>
                        </li>
                    );
                })}
            </ul>

            <div className="container-btns">
                {/* <button>
                    <img src={imgDownload} alt="" />
                </button> */}

                {hasAprovar?.active && (
                    <>
                        <Button
                            color={`${disabled ? "#8E8E8E" : "#D50632"}`}
                            background={`${disabled ? "#ECECEC" : "#FFE3E7"}`}
                            onClick={() => {
                                setModalReprovar(true);
                            }}
                        >
                            REPROVAR
                        </Button>

                        <Button
                            color={`${disabled ? "#fff" : "#fff"}`}
                            background={`${disabled ? "#8E8E8E" : "#5DB356"}`}
                            onClick={() => {
                                setModalAprovar(true);
                            }}
                        >
                            APROVAR
                        </Button>
                    </>
                )}
            </div>
        </HeaderStyles>
    );
}
