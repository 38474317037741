import { WhiteList } from "../../../components/System/White-list";
import { WhiteListProvider } from "../../../Hook/system/useWhiteList";

export function PageWhiteList() {
    return (
        <WhiteListProvider>
            <WhiteList />
        </WhiteListProvider>
    );
}
