import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { Button } from "../Button";
import imgCartao from "../../../assets/img/solicitar-cartao.svg";
import { FinalizarStyles } from "./styles";
import { useUser } from "../../../Hook/user/UseUser";

export function Finalizar() {
    const history = useHistory();
    const { setState, state } = UseModal();
    const { getUser } = useUser();
    const width = window.screen.width;

    return (
        <FinalizarStyles flexDirection="column" className="container">
            <img src={imgCartao} alt="Solicitar Cartão" />

            <Text textTransform="uppercase" as="h3" fontWeight={600}>
                SEU CARTÃO FOI ATIVADO COM SUCESSO!
            </Text>

            <Text as="p">
                TRANSFIRA SALDO DA CARTEIRA PARA SEU CARTÃO PARA PODER
                UTILIZÁ-LO.
            </Text>

            <Button
                mt="32px"
                onClick={async () => {
                    await getUser();

                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, ativarCartao: false });
                    }
                }}
            >
                OK, ENTENDI
            </Button>
        </FinalizarStyles>
    );
}
