import styled from "styled-components";
import { Container as SimpleAccordion } from "../../../Accordion/styles";

export const Container = styled.div`
    width: 570px;
    margin: 0 auto;
    padding-top: 42px;
    padding-bottom: 42px;

    ${SimpleAccordion} {
        margin-bottom: 8px;
    }

    .list-switch {
        margin-bottom: 8px;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    label {
        background: rgba(220, 237, 219, 0.5);
        border-radius: 4px;
        height: 65px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 22px;
            background: ${(props) => props.theme.colors.secondary};
            border-radius: 2.75px;
            margin-bottom: 0px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
            margin-left: 8px;
        }
    }
`;
