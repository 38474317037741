import styled from "styled-components";

export const Container = styled.div`
    padding-top: 55px;
    padding-bottom: 44px;

    .container {
        display: flex;
        flex-direction: column;

        width: 100%;
        max-width: 300px;

        text-align: center;
    }

    h5 {
        color: ${(props) => props.theme.colors.secondary};
        font-weight: 600;
        margin: 20px 0 30px;

        strong {
            color: ${(props) => props.theme.colors.warning};
        }
    }

    button#submit {
        background: ${({ theme }) => theme.colors.secondary};
    }

    img {
        &.img-close {
            position: absolute;
            top: -87px;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
            z-index: 99;
        }
    }

    .cancelar {
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        margin-top: 12px;
    }
`;

export const Title = styled.h2`
    font-size: 16px;
    font-weight: 700;
    font-family: Oxanium;
`;

export const Description = styled.p`
    font-size: 14px;
    font-weight: 400;
    font-family: Oxanium;

    margin: 20px 0 30px;
`;

export const Warning = styled.span`
    font-size: 14px;
    font-weight: 700;
    font-family: Oxanium;

    color: ${({ theme }) => theme.colors.secondary};
`;

export const BtnTicketCode = styled.button`
    background: #f2f2f2;
    border-radius: 10px;
    margin: 20px 0 38px;
    width: 100%;
    padding: 20px 30px;

    span {
        display: flex;
        justify-content: center;

        font-size: 22px;
        font-weight: 800;
        font-family: Oxanium;
        width: 100%;
        padding: 10px 0;
        border-radius: 5px;

        background: #fff;
        color: ${({ theme }) => theme.colors.secondary};
    }
`;
