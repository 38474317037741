import styled from "styled-components";

export const Container = styled.button`
    border: 0.881137px solid ${(props) => props.theme.colors.secondarybright};
    box-sizing: border-box;
    border-radius: 7.0491px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &.empty {
        border: 0.881137px solid #e1e2e3;
        opacity: 1;
        cursor: not-allowed;
        img {
            filter: grayscale(1);
        }

        p {
            filter: grayscale(1);
        }
    }

    p {
        font-style: normal;
        font-weight: 600;
        font-size: 0.661rem;
        line-height: 120%;
        letter-spacing: 0.08em;
        text-align: center;
        width: 125px;
        margin-top: 12px;
    }

    @media (max-width: 480px) {
        p {
            width: 90%;
            font-size: 8px;
        }
    }
`;
