import styled from "styled-components";
import { Container as DefaultHeader } from "../../Header/styles";
import imgLupa from "../../../../assets/img/lupa.svg";

export const Container = styled.div`
    div {
        &[aria-label="animation"] {
            margin: 0px !important;
            margin-left: 8px !important;
        }

        &.loader {
            position: absolute;
            top: 50%;
            left: 50%;
            div {
                &[aria-label="animation"] {
                    margin: 0px !important;
                }
            }
        }
    }

    .status {
        padding: 10px;
        background-color: #e3f1ff;
        border-radius: 8px;

        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        cursor: pointer;
    }
`;

export const HedaerStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e1e2e3;
    ${DefaultHeader} {
        border: none;
    }
    button {
        width: auto;
        padding: 0 16px;
        margin: 0px;
        margin-left: 11px;
    }
    .container-input-system {
        margin-bottom: 0px;
        width: 532px;
        position: relative;
        margin-right: 44px;
        input {
            padding-left: 0px;
            border: none;
            border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            left: unset;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            -webkit-mask: url(${imgLupa}) no-repeat;
            width: 19px;
            height: 17px;
            z-index: 20;
            transition: opacity 0.5s;
        }
    }
`;
