import { ListStyles } from "./styles";
import { useUser } from "../../../Hook/user/UseUser";
import { formatNameCard } from "../../../util/helper";
import { toast } from "react-toastify";

export interface ListDados {
    number: string;
    date: string;
    cvv: string;
    conta: string;
}

export function List(props: ListDados) {
    const { cvv, date, number, conta } = props;
    const { user } = useUser();
    function copy(value: string, field: string) {
        navigator.clipboard.writeText(value);
        toast.success(`${field} copiado(a)`);
    }

    return (
        <ListStyles>
            <li>
                <div className="container-img user"></div>

                <p>{formatNameCard(user?.pessoa?.nome_cpf)}</p>

                <button
                    onClick={() =>
                        copy(formatNameCard(user?.pessoa?.nome_cpf), "Nome")
                    }
                >
                    COPIAR
                </button>
            </li>
            <li>
                <div className="container-img card"></div>

                <p>{number}</p>

                <button onClick={() => copy(number, "Número")}>COPIAR</button>
            </li>
            <li>
                <div className="container-img date"></div>

                <div className="container-date">
                    <p>VALIDADE</p>
                    <p>{date}</p>
                </div>

                <button onClick={() => copy(date, "Data")}>COPIAR</button>
            </li>
            <li>
                <div className="container-img card"></div>

                <div className="container-date">
                    <p>CVV</p>
                    <p>{cvv}</p>
                </div>

                <button onClick={() => copy(cvv, "CVV")}>COPIAR</button>
            </li>
            <li>
                <div className="container-img card"></div>

                <div className="container-date">
                    <p>Nº da conta</p>
                    <p>{conta}</p>
                </div>

                <button onClick={() => copy(conta, "Conta")}>COPIAR</button>
            </li>
        </ListStyles>
    );
}
