import styled from "styled-components";

export const Container = styled.div``;

export const FormAddCupom = styled.div`
    padding-top: 34px;
    padding-bottom: 32px;
    height: 443px;
    form {
        height: 100%;
        display: flex;
        flex-direction: column;
        input {
            font-style: normal;
            font-weight: 600;
            font-size: ${({ theme }) => theme.fonts.md};
            line-height: 100%;
            letter-spacing: 0.2em;
            color: ${({ theme }) => theme.colors.primary};
            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${({ theme }) => theme.colors.primaryDark};
            }
        }
        button {
            margin-top: auto;
        }
    }

    p {
        text-align: center;
        margin-bottom: 27px;
    }
`;

export const SucessoCupomStyles = styled.div`
    padding-bottom: 32px;
    .valor-cupom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding-bottom: 15px;
        border-bottom: 1px solid #e1e2e3;
        margin-bottom: 15px;
        margin-top: 0px;
        &:last-child {
            margin-bottom: 0;
        }
        &:before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: ${(props) => props.theme.colors.secondary};
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 12px);
            left: 60%;
            transform: translateY(-50% -50%);
        }
    }
    .text-icon {
        flex-direction: column;
        align-items: center;
        p {
            width: 278px;
            margin: 0 auto;
            margin-top: -48px;
            text-align: center;
        }
    }
`;
