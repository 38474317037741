import { Link } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { useTheme } from "../../../contexts/ThemeContext";
import { UseModal } from "../../../Hook/Usemodal";

import { Cartoes } from "./styles";
import { Card } from "../Card";
import SwiperCore, { Navigation, A11y, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
SwiperCore.use([Navigation, A11y, Pagination]);

interface CardProps {
    setIndexSwiper: (index: number) => void;
}

export function Cards(props: CardProps) {
    const { setIndexSwiper } = props;
    const { t } = useTranslation();
    const { cartoes, setSaldo, setCartaoId, cartaoId } = UseCartoes();
    const [firstSwiper, setFirstSwiper] = useState<any>({} as any);
    const { currentTheme } = useTheme();
    const { state, setState } = UseModal();

    const cards = cartoes.map((item) => {
        const cardNumber = item.acg_account_card_last4digits;
        const cardDate = item.acg_account_card_expiration?.replace(
            /^([0-9]{2})([0-9]{2})/g,
            "$2/$1"
        );
        return {
            id: item.id,
            price: "0.00",
            number:
                item.tipo === "cartao_virtual"
                    ? cardNumber
                    : item.acg_account_card_last4digits,
            date:
                item.tipo === "cartao_fisico" || item.tipo === "cartao_avulso"
                    ? "**/**"
                    : cardDate,
            type:
                item.tipo === "cartao_fisico" || item.tipo === "cartao_avulso"
                    ? t("FÍSICO")
                    : t("VIRTUAL"),
            status:
                item.status === "BLOQUEADO" && item.first_active === 0
                    ? "INATIVO"
                    : item.status,
            color:
                item.tipo === "cartao_fisico" || item.tipo === "cartao_avulso"
                    ? currentTheme.colors.tertiarybright
                    : currentTheme.colors.tertiarydark,
            visualizarDados: item.tipo === "cartao_virtual" ? true : false,
            onClick: () => {
                setCartaoId(item.id);
                setState({
                    ...state,
                    acoesCartaoMobile: true,
                });
            },
        };
    });

    useEffect(() => {
        const indexItem = cartoes.findIndex((cartao) => cartao.id === cartaoId);
        setIndexSwiper(indexItem);

        if (firstSwiper.navigation && Number.isInteger(indexItem)) {
            firstSwiper.slideTo(indexItem, 0);
        }
    }, [cartaoId, cartoes, firstSwiper, setIndexSwiper]);

    return (
        <>
            <Cartoes>
                <Swiper
                    spaceBetween={9}
                    slidesPerView={1}
                    pagination={true}
                    className="slide-cartoes-detalhe"
                    onActiveIndexChange={(swiper) =>
                        setIndexSwiper(swiper.realIndex)
                    }
                    onSwiper={(swiper) => setFirstSwiper(swiper)}
                    onSlideChange={(swiper) => {
                        setCartaoId(cards[swiper.realIndex].id);
                        setSaldo("");
                    }}
                >
                    {cards.map((card, key) => {
                        let path = "";

                        if (card.status === "ATIVO") path = "";

                        if (card.status === "INATIVO")
                            path = `/ativar-cartao-avulso/${card.id}`;

                        return (
                            <SwiperSlide
                                key={card.id}
                                onClick={() => {
                                    if (card.status === "BLOQUEADO") {
                                        setCartaoId(card.id);
                                        setState({
                                            ...state,
                                            acaoDesbloquearCartaoMobilePasso1:
                                                true,
                                        });
                                    }
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: path,
                                        state: {
                                            id: card.id,
                                        },
                                    }}
                                >
                                    <Card {...card} key={key} />
                                </Link>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Cartoes>
        </>
    );
}
