import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 64px;
`;

export const HeaderStyle = styled.div`
    display: flex;
    align-items: center;
    padding-top: 47px;
    padding-bottom: 16px;
    border-bottom: 1px solid #9cabb8;

    .header-tutorial-title {
        display: flex;
        align-items: center;
        .container-img {
            margin-right: 16px;
        }
        h3 {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .header-tutorial-search {
        margin-left: 55px;
        margin-right: auto;
        width: 487px;
        .container-input {
            position: relative;
            margin-bottom: 0px;
            .container-img {
                position: absolute;
                top: 50%;
                right: 23px;
                transform: translateY(-50%);
            }
            input {
                padding-left: 27px;
                padding-right: 42px;
            }
        }
    }

    .goBack {
        button {
            display: flex;
            align-items: center;
            color: #767b7f;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            img {
                margin-right: 8px;
            }
        }
    }
`;
