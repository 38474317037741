import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";

import Table, { Pagination } from "../Table";

import ModalAprove from "../../../components/System/ModalAlert";
import { Faq as IFaq, useFaq } from "../../../Hook/system/useFaq";
import { PaginationConfig } from "../../../Hook/system/config";

import { Header } from "./header";
import { Container } from "./styles";
import { Data } from "./config";
import { Loader } from "../../Loader";
import imgEditar from "../../../assets/img/editar.svg";
import imgExcluir from "../../../assets/img/x-vermelho.svg";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export function Faq() {
    const { getFaq, deleteFaq } = useFaq();
    const history = useHistory();
    const [faqs, setFaqs] = useState<IFaq[]>([]);
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [id, setId] = useState<number | null>(null);
    const [modalExcluir, setModalExcluir] = useState(false);
    const [search, setSearch] = useState("");
    const width = 1645 / 2.5;

    const dados: Data[] = faqs?.map((faq) => {
        return {
            id: faq.id + "",
            description: faq.description,
            title: faq.title,
            editar: "",
            excluir: "",
        };
    });

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 75,
        },
        {
            field: "title",
            headerName: "TITULO",
            width,
        },
        {
            field: "description",
            headerName: "DESCRIÇÃO",
            width,
        },
        {
            field: "editar",
            headerName: "EDITAR",
            width: 75,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/faq/editar/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            });
                        }}
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },
        {
            field: "excluir",
            headerName: "EXCLUIR",
            width: 75,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            setModalExcluir(true);
                            setId(parseInt(row.id));
                        }}
                    >
                        <img src={imgExcluir} alt="" />
                    </button>
                );
            },
        },
    ];

    const pagination = useCallback(
        async (page: number, search?: string) => {
            setLoading(true);
            try {
                const response = await getFaq(page, search);
                const { data, ...resto } = response;

                setConfig(resto);
                setFaqs(response.data);
            } catch (error) {
                console.log(error);
                setFaqs([]);
                setConfig({} as PaginationConfig);
            }
            setLoading(false);
        },
        [getFaq]
    );

    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await getFaq();
        const { data, ...resto } = response;
        setFaqs(data);
        setConfig(resto);
        setLoading(false);
    }, [getFaq]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (search) {
            pagination(1, search);
        }
        if (search === "") {
            fetchData();
        }
    }, [pagination, search, fetchData]);

    return (
        <Container>
            <Header setSearch={setSearch} />

            <div className={`content-table ${loading ? "active" : ""}`}>
                {loading && (
                    <div className="container-loading">
                        <Loader />
                    </div>
                )}

                <Table
                    rows={dados ? dados : []}
                    columns={columns}
                    nameCSV="administradores"
                />
            </div>

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page, search);
                    }}
                />
            ) : null}

            <ModalAprove
                handleClose={() => setModalExcluir(false)}
                reject={() => setModalExcluir(false)}
                aceept={async () => {
                    if (id) {
                        setLoading(true);
                        await deleteFaq(id);
                        await fetchData();
                        setLoading(false);
                        setModalExcluir(false);
                        setId(null);
                        return;
                    }

                    toast.error("ops algo de errado aconteceu!");
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalExcluir}
            />
        </Container>
    );
}
