// import { useSegmentacao } from "../../../../../Hook/system/useSegmentacao";
import { Segmentacoes } from "../../../Segmentacao/segmentacao";
import { ModalStyles } from "../styles";

export function ModalSegmentacao() {
    return (
        <ModalStyles>
            <h1>segmentação</h1>
            <Segmentacoes />
        </ModalStyles>
    );
}
