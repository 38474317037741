import { FieldProps, Field, ErrorMessage } from "formik";
import Select, { options } from "../../NovoSelect";
import { DateTime } from "../../DateTime";
import { formatDateTimeDb } from "../../../../util/helper";

export function Notificacao(props: FieldProps) {
    const { form } = props;
    const { setFieldValue, values } = form;

    const disparo: options[] = [
        {
            label: "IMEDIATO",
            value: 1,
        },
        {
            label: "AGENDADO",
            value: 2,
        },
    ];

    return (
        <>
            <Field
                component="textarea"
                name="conteudo"
                placeholder="DIGITE AQUI SUA MENSAGEM"
            />
            <ErrorMessage component="span" name="conteudo" />

            <Select
                label="DISPARO"
                options={disparo}
                onChange={(values) => {
                    setFieldValue("disparo", values.label.toLocaleLowerCase());
                    if (values.label === "IMEDIATO") {
                        setFieldValue("data_disparo", " ");
                    }
                }}
                icon={false}
            />
            <ErrorMessage component="span" name="disparo" />

            {values.disparo === "agendado" && (
                <DateTime
                    initialValue={null}
                    label="DATA/HORARIO DE DISPARO"
                    onChange={(dt_inicio) => {
                        setFieldValue(
                            "data_disparo",
                            formatDateTimeDb(dt_inicio).trim()
                        );
                    }}
                />
            )}

            <ErrorMessage component="span" name="data_disparo" />
        </>
    );
}
