import { useContex } from "./Hook";
import { ModalNovoCliente } from "./Modal";
import Modal from "../../../NovoModal";
import { ModalAvisoExclusao } from "./modalAviso";
import { HeaderNovo } from "../../Header/novo";
import { FormCliente } from "./form";

export function Cliente() {
    const { modal, setModal, modalAviso, setModalAviso } = useContex();

    return (
        <>
            <HeaderNovo title="novo cliente" />
            <FormCliente />

            <Modal open={modal} handleClose={() => setModal(false)}>
                <ModalNovoCliente />
            </Modal>

            <Modal open={modalAviso} handleClose={() => setModalAviso(false)}>
                <ModalAvisoExclusao />
            </Modal>
        </>
    );
}
