import styled from "styled-components";
import { Form } from "formik";
import { CardStyles } from "../../Mobile/Cartoes/styles";
import imgCheck from "../../../assets/img/check-transferir.svg";

export const Container = styled.div`
    .container-tabs {
        width: 90%;
        margin: 0 auto;
        padding-top:0;
        .container-loading {
            position: static;
        }
    }
`;

export const FormCpfStyles = styled(Form)`
    display: flex;
    flex-direction: column;
    padding-bottom: 215px;
    .container-input {
        margin-bottom: 289px;
    }
`;

export const ConfirmaUserStyles = styled.div`
    .container-user {
        display: flex;
        align-items: center;
        margin-bottom: 37px;
        .container-img {
            margin-right: 32px;
            img {
                width: 65px;
                border-radius: 50%;
            }
        }

        .container-info {
            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.primary};
                margin-bottom: 2px;
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: ${(props) => props.theme.colors.primaryDark};
            }
        }
    }
`;

export const FormConfirmaUserStyles = styled(Form)`
    height: calc(100vh - 70px - 37px - 34px - 61px - 99px - 32px);
    display: flex;
    flex-direction: column;
    padding-bottom: 215px;
`;

export const ConfirmaDadosStyles = styled.div`
    padding: 35px 0;
    text-transform: uppercase;
    h4 {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        text-transform: none;
        margin-bottom: 35px;
    }

    .container-info {
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
            background: rgba(220, 237, 219, 0.5);
            border-radius: 2px;
            padding: 6px;
            display: block;
            margin-bottom: 8px;
            width: fit-content;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 8px;
        }
    }
`;

export const InformacaoTransferenciaStyles = styled.div`
    small {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
        display: block;
        padding: 6px;
        background: ${(props) => props.theme.colors.disabled};
        border-radius: 2px;
        margin-bottom: 8px;
        width: fit-content;
    }

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primary};
    }

    .container-info {
        margin-bottom: 24px;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
            text-transform: uppercase;
            &.campanha {
                font-weight: bold;
            }
        }
    }

    ul {
        li {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 76px;
            height: 44px;
            border-bottom: 1px solid #e1e2e3;
            &:last-child {
                border-bottom: none;
            }
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryDark};
                position: relative;
                white-space: nowrap;
                text-align: left;
                &:last-child {
                    &:before {
                        content: "";
                        display: block;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: ${(props) => props.theme.colors.secondary};
                        position: absolute;
                        left: -63px;
                        top: 0;
                    }
                }
            }
        }
    }
`;

export const Divisor = styled.div`
    height: 21px;
    position: relative;
    width: 100%;
    padding: 32px 0px;
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.colors.primaryDark};
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url(${imgCheck}) no-repeat;
        width: 24px;
        height: 24px;
        z-index: 20;
    }
`;

export const CardTransferirStyles = styled.div`
    padding:35px 9px;

    .container-card-active {
        display: flex;
        align-items: start;
        margin-bottom: 26px;
        .container-detalhes {
            margin-left: 25px;
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primary};
                margin-bottom: 33px;
            }

            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 100%;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.primaryBright};
                margin-bottom: 9px;
            }

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: ${(props) => props.theme.colors.primaryBright};
            }
        }

        ${CardStyles} {
            width: 72px;
            height: 93px;
            padding: 15px 7px 6.18px 6px;
            &:before {
                border-radius: 3px;
            }
            .number-card {
                margin-bottom: auto;
            }
            .bandeira {
                width: 14px;
                height: 5px;
                img {
                    width: 100%;
                    margin-top: 0px;
                    display: block;
                }
            }

            p {
                font-size: 6.04545px;
                &.type {
                    margin-bottom: 0px;
                }
            }
        }
    }

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.08em;
        color: ${(props) => props.theme.colors.primaryDark};
        display: block;
        margin-top: 30px;
        margin-bottom: 11px;
    }
    .container-input{
        input{
            height: 40px;
            padding-left: 40px;
            font-size:16px;
            font-weight: 700;
            font-family: "Oxanium", Arial, Helvetica, sans-serif;
        }

        &:before{
                left:10px;
        }
    }

    .container-alerta{
        margin-bottom:46px;
    }

`;

export const ContainerCards = styled.div`
    display: flex;
    align-items: start;
    border: 1px solid #e1e2e3;
    border-radius: 8px;
    padding: 19px 18px 23px 21px;
    margin-bottom: 7px;
    transition: all 0.5s;
    cursor: pointer;
    &.active {
        border: 1px solid ${(props) => props.theme.colors.secondary};
        transition: all 0.5s;
    }
    .container-card {
        ${CardStyles} {
            width: 43px;
            height: auto;
            padding: 7px 3px 3px 3px;
            &:before {
                border-radius: 3px;
            }
            .number-card {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .bandeira {
                width: 7px;
                height: 5px;
                img {
                    width: 100%;
                    margin-top: 0px;
                    display: block;
                }
            }

            p {
                font-size: 3.30097px;
                &.type {
                    margin-bottom: 0px;
                }
            }
        }
    }
    .container-detalhes {
        margin-left: 13px;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 8px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .container-check {
        margin-left: auto;
        & > div {
            width: 19px;
        }
    }
`;

export const ContainerCardsTransferir = styled.div`
    padding:35px 9px;

    img {
        display: block;
        margin: 0 auto 21px;
        width:48;
    }

    h3{
        text-transform: none;
        font-size:20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.secondary};
    }

`;
