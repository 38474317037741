import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { CpfInvalido } from "../../CpfInvalido";

export function ModalCpfInvalido(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <CpfInvalido />
        </ModalBottom>
    );
}
