import styled from "styled-components";

export const Container = styled.div`
    .container-button {
        margin-bottom: 32px;
        button {
            width: 100%;
            background: ${(props) => props.theme.colors.secondary};
            height: 32px;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 12px */
            letter-spacing: 0.96px;
            text-transform: uppercase;
            text-align: left;
            padding-left: 23px;
        }
    }

    .container-table {
        & > div {
            padding: 0px;
            min-height: auto;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50px;
`;
