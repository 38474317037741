import { useEffect } from "react";
import { Descricao } from "./descricao";
import { AlterarSenha } from "./AlterarSenha";
import { Sucesso } from "./Sucesso";
import { usePassos } from "../../Hook/UsePassos";

import imgLogo from "../../assets/img/logo-menu.svg";
import { Container } from "./styles";
import { UseModal } from "../../Hook/Usemodal";

export const arrPassos = [
    {
        passo: 1,
        active: true,
        completed: true,
    },
    {
        passo: 2,
        active: false,
        completed: false,
    },
    {
        passo: 3,
        active: false,
        completed: false,
        goBack: false,
    },
];

export function ResetarSenha() {
    const { setPassos, passos } = usePassos();
    const { state } = UseModal();
    useEffect(() => {
        if (state.resetarSenha) {
            setPassos(arrPassos);
        }
        setPassos(arrPassos);
    }, [setPassos, state.resetarSenha]);

    return (
        <Container>
            <div className="container-menu">
                <img src={imgLogo} alt="" />
            </div>

            <div className="container-tabs">
                <div className={`tab ${passos[0]?.active ? "active" : ""}`}>
                    <Descricao />
                </div>
                <div className={`tab ${passos[1]?.active ? "active" : ""}`}>
                    <AlterarSenha />
                </div>
                <div className={`tab ${passos[2]?.active ? "active" : ""}`}>
                    <Sucesso />
                </div>
            </div>
        </Container>
    );
}
