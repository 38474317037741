import { Button } from "../ButtonSlim";
import { ButtonGoBack } from "../ButtonGoBack";
import { Container } from "../../Modais/ModalContainer/styles"
import { usePassos } from "../../../Hook/UsePassos";
import { UseModal } from "../../../Hook/Usemodal";
import imgCardCancel from "../../../assets/img/cardCancel.svg";

export function PageSegundaVia() {
    const { nextPasso } = usePassos();
    const { handleClose} = UseModal();
    return (
        <Container>
            <img src={imgCardCancel} alt="" />
            <h2>Cancelar Cartão</h2>
            <p className="strong">
                    Deseja realmente cancelar, <br/>
                    de forma <u>irreversível</u> este cartão?
            </p>
            <p>
                O crédito existente no cartão será<br/>
                transferido automaticamente para o <br/>
                Saldo na Carteira.
            </p>

            <div className="buttonContainer">
                <Button onClick={() => nextPasso(2)} mb="32px">
                    Confirmar Cancelamento
                </Button>
                <ButtonGoBack onClick={() => handleClose("segundaVia")} >
                    Voltar
                </ButtonGoBack>
            </div>
        </Container>
    );
}
