import imgExtratoDisabled from "../../../assets/img/extrato-disabled.svg";
import { EmptyStyled } from "./styles";

interface EmptyProps {
    modal?: boolean;
}

export function Empty({modal=false}:EmptyProps) {

    return  modal? <EmptyStyled>
                        <p>Não há comprovantes para o período selecionado.</p>
                    </EmptyStyled>
                :   <EmptyStyled>
                        <img src={imgExtratoDisabled} alt="" />                           
                        <span>
                            <p>Não há movimentações para exibição do extrato</p>
                        </span>
                    </EmptyStyled>
}
