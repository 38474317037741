import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { PerdaRoubo } from "../../PerdaRoubo";

export function ModalPerdaRoubo(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <PerdaRoubo />
        </ModalBottom>
    );
}
