import { useState, useEffect, memo } from "react";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";
import Table, { Pagination } from "../../../../Table";
import {
    formatDateIsoSystem,
    formatCurrentMoney,
} from "../../../../../../util/helper";

import { useEstorno, carga } from "../Hook";
import { Data } from "./config";
import { Filter } from "./filter";
import imgSetaDir from "../../../../../../assets/img/dowload.svg";
import imgVisualizar from "../../../../../../assets/img/visualizar.svg";
import { TableStyles } from "./styles";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { Loader } from "../../../../../Loader";
import { useCampanha } from "../../../../../../Hook/system/useCampanha";

function TableCarga() {
    const { getCarga, searchCarga } = useEstorno();
    const history = useHistory();
    const { campanhaById } = useCampanha();
    const [loading, setLoading] = useState(false);
    const [cargas, setCargas] = useState<carga[]>([]);
    const [config, setConfig] = useState<PaginationConfig>(
        {} as PaginationConfig
    );

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getCarga(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setCargas(response.data);
        } catch (error) {
            console.log(error);
            setCargas([]);
            setConfig({} as PaginationConfig);
        }
        setLoading(false);
    }

    async function searchFetchData(search: string) {
        setLoading(true);
        const reponse = await searchCarga(search);
        const { data, ...resto } = reponse;
        setCargas(data);
        setConfig(resto);
        setLoading(false);
    }

    const rows: Data[] = cargas?.map((carga) => {
        return {
            id: carga.id,
            titulo: carga.nome.trim(),
            data_hora: formatDateIsoSystem(carga.created_at),
            criado_por: carga.user.name.trim(),
            apr_por: carga?.approve_user?.name.trim(),
            valor:
                "R$ " +
                formatCurrentMoney(
                    parseFloat(carga.total_itens_credito_estornado)
                ),
            contato: carga.cliente_area_contato?.nome || "",
            observacao: carga.observacao,
            job: carga?.job,
            file: carga.file,
        };
    });

    const column: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
        },
        {
            field: "titulo",
            headerName: "Título",
            width: 172,
        },
        {
            field: "data_hora",
            headerName: "Data/Hora",
            width: 172,
        },
        {
            field: "criado_por",
            headerName: "Criado por",
            width: 150,
        },
        {
            field: "apr_por",
            headerName: "Aprovado por",
            width: 150,
        },
        {
            field: "valor",
            headerName: "Valor",
            width: 120,
        },
        {
            field: "contato",
            headerName: "CONTATO",
            width: 172,
        },
        {
            field: "observacao",
            headerName: "OBSERVAÇÃO",
            width: 150,
        },
        {
            field: "job",
            headerName: "JOB",
            width: 100,
        },
        {
            field: "download",
            headerName: "",
            width: 50,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    row.file && (
                        <button
                            onClick={() => (window.location.href = row.file)}
                        >
                            <img src={imgSetaDir} alt="" />
                        </button>
                    )
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
        },
        {
            field: "detail",
            headerName: "",
            width: 50,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            history.push({
                                pathname: `/sistema/campanha/carga/${row.id}/detalhe-estorno`,
                                state: {
                                    id: row.id,
                                    type: "creditos_estorno",
                                    carga: "estorno",
                                    campanha: campanhaById.nome,
                                },
                            });
                        }}
                    >
                        <img src={imgVisualizar} alt="" />
                    </button>
                );
            },
            hideSortIcons: true,
            disableColumnMenu: true,
        },
    ];

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const reponse = await getCarga();
            const { data, ...resto } = reponse;
            setCargas(data);
            setConfig(resto);
            setLoading(false);
        }

        fetchData();
    }, [getCarga]);

    return (
        <TableStyles>
            <Filter searchFetchData={searchFetchData} />
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table
                    rows={rows ? rows : []}
                    columns={column}
                    nameCSV="CREDITO"
                />
            )}

            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                    }}
                />
            ) : null}
        </TableStyles>
    );
}

export const TableCargaMemorized = memo(TableCarga);
