import Mask from "react-input-mask";
import { Box } from "alisson-application";
import { motion } from "framer-motion";
import { AxiosResponse } from "axios";
import { KeyboardEvent, useEffect, useState } from "react";
import { UseModal } from "../../../Hook/Usemodal";
import { api } from "../../../services";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";

import { Button } from "../Button";
import imgOlho from "../../../assets/img/olho.svg";
import imgSetaBaixo from "../../../assets/img/seta-baixo.svg";
import pdfRegulamento from "../../../assets/pdf/regulamento.pdf";

import { registerCNPJ, RegisterCNPJ, validationRegisterCNPJ } from "./config";
import { toast } from "react-toastify";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { UseLogin, Values } from "../../../Hook/user/UseLogin";
import { CheckBox } from "../CheckBox";
import { CheckPassword } from "../../CheckPassword";

interface ValidadeDocuments {
    document: string;
}

interface IcheckDocs {
    documento: string;
    nome_empresarial: string;
    email: string;
}

export function CompleteRegisterCNPJ() {
    const { registro } = UseLogin();
    const { setState: setStateModal, state: stateModal } = UseModal();
    const { setControllerForm } = useFormLogin();
    const [initalValues, setInitialValues] =
        useState<RegisterCNPJ>(registerCNPJ);
    const { state } = useFormLogin();
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [politicamenteExposta, setPoliticamenteExposta] = useState(false);

    async function checkDocs(values: ValidadeDocuments) {
        setLoading(true);
        try {
            const response = await api.post<AxiosResponse<IcheckDocs>>(
                "consult-documents",
                { ...values }
            );
            setLoading(false);
            return response;
        } catch (error: any) {
            console.log(error);
            toast.error("Dados não encontrados");
            setLoading(false);
            return error;
        }
    }

    useEffect(() => {
        async function fetchData() {
            const response = await checkDocs({
                document: state.document.replace(/\D/g, ""),
            });

            const { data } = response;

            if (response.status !== 500) {
                setInitialValues({
                    ...registerCNPJ,
                    name: data?.nome_empresarial,
                    email: "",
                    email_confirmation: "",
                    document: state.document,
                });
            } else {
                setControllerForm({
                    documento: true,
                    campanha: false,
                    selectCampanha: false,
                    cartaoNoname: false,
                    completeRegister: false,
                    password: false,
                    endereco: false,
                });
            }
        }

        fetchData();
    }, [state, state.document, setControllerForm]);

    return (
        <Formik
            enableReinitialize
            initialErrors={{
                apelido: "",
                campanha: state.campanha,
                document: state.document,
                name: "",
                phone_number: "",
                confirm_phone_number: "",
                email: "",
                email_confirmation: "",
                password: "",
                password_confirmation: "",
            }}
            validateOnMount
            initialValues={{ ...state, ...initalValues }}
            validationSchema={validationRegisterCNPJ}
            onSubmit={async (values, actions) => {
                const response = await checkDocs({
                    document: values.document.replace(/\D/g, ""),
                });

                const validadteUser = response?.data || {};
                delete response.data.email;

                const arrValidateUser = Object.entries(validadteUser);

                const hasError = arrValidateUser.some((item) => !item[1]);

                if (hasError) {
                    window.scrollTo({ top: 0, behavior: "smooth" });

                    setStateModal({
                        ...stateModal,
                        errorGeral: {
                            active: true,
                            title: "DADOS INCORRETOS!",
                            message: "CORRIJA OS CAMPOS DESTACADOS EM VERMELHO",
                        },
                    });
                    return;
                }

                const { confirm_phone_number, account, ...resto } = values;

                await registro({
                    ...resto,
                    campanha: state.campanha,
                } as unknown as Values);
            }}
        >
            {(props: FormikProps<RegisterCNPJ>) => {
                const { values, handleChange, handleBlur, setFieldValue } =
                    props;

                const { phone_number, confirm_phone_number } = values;

                return (
                    <Form>
                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user ${
                                state.name ? "active disabled" : ""
                            }`}
                        >
                            <Field name="name" placeholder="NOME" />
                        </motion.div>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.1,
                            }}
                            className={`container-input user active disabled`}
                        >
                            <Field name="document" placeholder="NOME" />
                        </motion.div>

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.3,
                            }}
                            className={`container-input ${
                                props.errors.apelido && props.touched.apelido
                                    ? "error"
                                    : ""
                            } user`}
                        >
                            <Field name="apelido" placeholder="APELIDO" />
                        </motion.div>
                        <ErrorMessage name="apelido" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.7,
                            }}
                            className={`container-input contato ${
                                props.errors.email && props.touched.email
                                    ? "error"
                                    : ""
                            }
                            `}
                        >
                            <Field
                                name="email"
                                type="text"
                                placeholder="E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }

                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey) {
                                        toast.error("Ação não permitida");
                                    }
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage name="email" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.8,
                            }}
                            className={`container-input ${
                                props.errors.email_confirmation &&
                                props.touched.email_confirmation
                                    ? "error"
                                    : ""
                            } contato`}
                        >
                            <Field
                                name="email_confirmation"
                                type="text"
                                placeholder="CONFIRMAR E-MAIL"
                                onCut={(e: KeyboardEvent<HTMLInputElement>) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onCopy={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                                onPaste={(
                                    e: KeyboardEvent<HTMLInputElement>
                                ) => {
                                    if (!e.ctrlKey)
                                        toast.error("Ação não permitida");
                                    e.preventDefault();
                                }}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="email_confirmation"
                            component="span"
                        />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 0.9,
                            }}
                            className={`container-input ${
                                props.errors.phone_number &&
                                props.touched.phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CELULAR"
                                name="phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={phone_number}
                            />
                        </motion.div>
                        <ErrorMessage name="phone_number" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1,
                            }}
                            className={`container-input ${
                                props.errors.confirm_phone_number &&
                                props.touched.confirm_phone_number
                                    ? "error"
                                    : ""
                            } celular`}
                        >
                            <Mask
                                mask="(99) 99999-9999"
                                placeholder="CONFIRMAR CELULAR"
                                name="confirm_phone_number"
                                type="tel"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={confirm_phone_number}
                            />
                        </motion.div>
                        <ErrorMessage
                            name="confirm_phone_number"
                            component="span"
                        />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.1,
                            }}
                            className={`container-input olho ${
                                props.errors.password && props.touched.password
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="SENHA"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>

                        <ErrorMessage name="password" component="span" />

                        <motion.div
                            animate={{
                                scale: [0.1, 1],
                            }}
                            transition={{
                                duration: 1.2,
                            }}
                            className={`container-input olho ${
                                props.errors.password_confirmation &&
                                props.touched.password_confirmation
                                    ? "error"
                                    : ""
                            } senha`}
                        >
                            <Field
                                name="password_confirmation"
                                type={`${olhoSenha ? "text" : "password"}`}
                                placeholder="CONFIRMA SENHA"
                            />

                            <button
                                type="button"
                                className={`${olhoSenha ? "active" : ""} `}
                                onClick={() => setOlhoSenha(!olhoSenha)}
                            >
                                <img src={imgOlho} alt="" />
                            </button>
                        </motion.div>
                        <ErrorMessage
                            name="password_confirmation"
                            component="span"
                        />
                        <Box mt="32px">
                            <CheckPassword values={values.password} />
                        </Box>

                        <ul className="container-checks">
                            <li>
                                <CheckBox
                                    label="Aceito receber mensagens via SMS e <br /> Whatsapp"
                                    checked={true}
                                />
                            </li>
                            <li>
                                <CheckBox
                                    label="Aceito receber mensagens via E-mail"
                                    onChange={(e) =>
                                        setFieldValue(
                                            "aceita_email",
                                            e.target.checked
                                        )
                                    }
                                    checked={values.aceita_email}
                                />
                            </li>
                            <li className="li-acordo">
                                <CheckBox
                                    label={`Li, compreendi e concordo com as <br /> disposições contidas nos <a href="${pdfRegulamento}" target='_blank' rel='noopener noreferrer'><strong>Termos de <br /> Uso</strong>  desta Plataforma.</a> Caso não <br /> concorde, clique no botão cancelar.`}
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_termos",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {!values.aceita_termos && (
                                <span>Campo obrigatório</span>
                            )}
                            <li className="li-acordo">
                                <CheckBox
                                    label="Aceito a <strong>Política de Privacidade</strong>"
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_politica_uso_de_dados",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {!values.aceita_politica_uso_de_dados && (
                                <span>Campo obrigatório</span>
                            )}

                            <li className="li-acordo">
                                <CheckBox
                                    label="Confirmo não ser uma Pessoa <br /> Politicamente  Exposta"
                                    onChange={(e) => {
                                        setFieldValue(
                                            "aceita_politicamente_exposta",
                                            e.target.checked
                                        );
                                    }}
                                />
                            </li>
                            {!values.aceita_politicamente_exposta && (
                                <span>Campo obrigatório</span>
                            )}

                            <div
                                className={`container-text-politicamente-exposta ${
                                    politicamenteExposta ? "active" : ""
                                }`}
                            >
                                <small>
                                    (que desempenha ou desempenhou nos últimos
                                    05 anos cargos e funções públicas listadas
                                    na regulamentação de PLDFT em vigor) e nem
                                    ser representante, familiar ou estreito
                                    colaborador de uma Pessoa Politicamente
                                    Exposta.
                                </small>
                            </div>

                            <button
                                onClick={() =>
                                    setPoliticamenteExposta(
                                        !politicamenteExposta
                                    )
                                }
                                type="button"
                                className={`politicamente-exposta ${
                                    politicamenteExposta ? "active" : ""
                                }`}
                            >
                                <img src={imgSetaBaixo} alt="" />
                                Saiba mais
                            </button>
                        </ul>

                        <motion.div
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.5 }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                disabled={!props.isValid}
                                isLoading={loading}
                                spiner="BallTriangle"
                                spinerColor="primary"
                            >
                                AVANÇAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
