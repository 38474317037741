import { Text } from "alisson-application";
import { Container } from "./styles";

interface ContainerValorProps {
    value: string;
}

export function ContainerValor(props: ContainerValorProps) {
    return (
        <Container>
            <Text color="secondary" bold>
                Valor a Pagar:
            </Text>
            <Text color="primaryDark" bold>
                R${props.value}
            </Text>
        </Container>
    );
}
