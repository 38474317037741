import * as Yup from "yup";

export interface Myform {
    nome: string;
    file: File | null;
    users: number[];
}
export const initalValues: Myform = {
    nome: "",
    file: null,
    users: [],
};

export const validadeForm = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    file: Yup.mixed().required("Arquivo Obrigatório"),
    users: Yup.array().min(1, "Selecione pelo menos uma opção"),
});
