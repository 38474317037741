import { Box } from "alisson-application";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import { useFormLogin } from "../../../Hook/user/useFormLogin";

import { UseLogin } from "../../../Hook/user/UseLogin";

import { Button } from "../Button";
import { campanhaForm, CampanhaForm, validationCampanhaForm } from "./config";

export function FormCampanha() {
    const { checkCampanha } = UseLogin();
    const { controllerForm, setControllerForm, setState, state } =
        useFormLogin();
    const [loading, setLoading] = useState(false);
    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
                ...campanhaForm,
                document: state.document,
            }}
            validationSchema={validationCampanhaForm}
            onSubmit={async (values, actions) => {
                setLoading(true);
                // const cpf = values.document.replace(/[^\d]+/g, "");
                const response = await checkCampanha({
                    ...values,
                });

                if (response.status === 200) {
                    setControllerForm({
                        ...controllerForm,
                        campanha: false,
                        documento: true,
                    });

                    setState({
                        ...state,
                        campanha: values.campanha,
                    });
                }

                setLoading(false);
            }}
        >
            {(props: FormikProps<CampanhaForm>) => {
                return (
                    <Form autoComplete="on">
                        <Box mb="107px">
                            <motion.div
                                animate={{
                                    scale: [0.1, 1],
                                }}
                                transition={{
                                    duration: 0.8,
                                }}
                                className={`container-input chave chave-campanha`}
                            >
                                <Field
                                    placeholder="Campanha"
                                    type="text"
                                    name="campanha"
                                    id="campanha"
                                    autoComplete="username"
                                />
                            </motion.div>
                            <ErrorMessage name="campanha" component="span" />
                        </Box>

                        <motion.div
                            whileHover={{ scale: 1 }}
                            whileTap={{ scale: 0.5 }}
                            transition={{
                                duration: 1,
                            }}
                            className="mt-auto"
                        >
                            <Button
                                type="submit"
                                isLoading={loading}
                                disabled={!props.isValid}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                className="login"
                            >
                                ENTRAR
                            </Button>
                        </motion.div>
                    </Form>
                );
            }}
        </Formik>
    );
}
