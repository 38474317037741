import { Text, Flex } from "alisson-application";
import { useHistory } from "react-router-dom";
import { formatDateSystem } from "../../../../util/helper";
import { Comunicacao } from "../../../../Hook/system/useComunicacao";
import { CardStyles } from "../styles";
import imgblockquote from "../../../../assets/img/blockquote.svg";
import imgEditar from "../../../../assets/img/editar.svg";
import imgX from "../../../../assets/img/x-vermelho.svg";

interface CardProps extends Comunicacao {
    handleDelete: (id: number) => void;
}

export function Card(props: CardProps) {
    const history = useHistory();
    const { handleDelete } = props;
    return (
        <CardStyles>
            <h2>
                <img src={imgblockquote} alt="" />
                {props?.titulo}
            </h2>

            <div className="container-info">
                {/* <Text as="h4" color="secondary" textTransform="uppercase">
                    32 campanhas
                </Text> */}

                <Flex alignItems="flex-start">
                    <Text as="span" className="tag">
                        {props?.tipo.replace(/NOTIFICACAO/, "NOTIFICAÇÃO")}
                    </Text>

                    {props.status === "AGUARDANDO" ? (
                        <button
                            className="btn-excluir"
                            onClick={() => handleDelete(props.id)}
                        >
                            <img src={imgX} alt="" />
                        </button>
                    ) : (
                        <></>
                    )}
                </Flex>

                <Flex justifyContent="space-between" mb="16px">
                    <Text>{formatDateSystem(props?.data_disparo)}</Text>
                    <Text>
                        {props?.data_fim
                            ? formatDateSystem(props?.data_fim)
                            : formatDateSystem(props?.updated_at)}
                    </Text>
                </Flex>

                <Text>
                    DESTINATÁRIOS: <strong>{props?.recipientes_count}</strong>
                </Text>
                <Text>
                    QTD ENVIADA: <strong>{props?.total_enviados}</strong>
                </Text>
                <Text>
                    QTD LIDA: <strong>{props?.total_visualizados}</strong>
                </Text>
                <Text>
                    QTD ERRO(S): <strong>{props?.total_errors}</strong>
                </Text>
            </div>

            <div className="container-btns">
                {/* <button>
                    <img src={imgEditar} alt="" />
                    EDITAR
                </button> */}
                <button
                    onClick={() => {
                        history.push({
                            pathname: `/sistema/comunicacao/detalhe/${props?.id}`,
                            state: {
                                id: props?.id,
                            },
                        });
                    }}
                >
                    <img src={imgEditar} alt="" />
                    detalhes
                </button>
            </div>
        </CardStyles>
    );
}
