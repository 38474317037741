import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageTransferir } from "../../../pages/Transferir";

export function Transferir(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <Modal open={open} handleClose={handleClose}>
            <PageTransferir />
        </Modal>
    );
}
