import { InformacaoLateralMemo } from "./informacaoLateral";
import { CampanhaProvider } from "../../../Hook/system/useCampanha";

import { HeaderMemorized } from "./header";
import { ContentMemorized } from "./Content";

import { Container } from "./styles";

export interface tabs {
    id: number;
    title: string;
    className: string;
    active: boolean;
    viewer: boolean;
    permission: boolean;
}

export function Carga() {
    return (
        <Container>
            <CampanhaProvider>
                <InformacaoLateralMemo />

                <div className="container-tabs">
                    <HeaderMemorized />
                    <ContentMemorized />
                </div>
            </CampanhaProvider>
        </Container>
    );
}
