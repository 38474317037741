import { Button } from "../ButtonSlim";
import { Container } from "../../Modais/ModalContainer/styles"
import imgUser from "../../../assets/img/exclamation.svg";
import { usePassos } from "../../../Hook/UsePassos";
import { arrPassos } from ".";

export function UsuarioNaoEncontrado() {
    const { setPassos } = usePassos();

    return (
        <Container>
            <img src={imgUser} alt="" />
            <h2>Algo deu Errado</h2>
            <p>
                Confira se os dados foram digitados corretamente e tente novamente.
            </p>
            <div className="buttonContainer">
                <Button
                    type="submit"
                    className="login"
                    mt="auto"
                    onClick={() => {
                        setPassos(arrPassos);
                    }}
                >Ok
                </Button>
            </div>
        </Container>
    );
}
