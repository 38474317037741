import styled from "styled-components";

export const Container = styled.div`
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
    .container-saldo {
        margin-left: 13px;
        position: relative;
        &.active {
            .dinheiro {
                &:before {
                    animation: shimmer 1.2s ease-in-out infinite;
                }
            }
        }
        .container-olho {
            margin-left: auto;
            position: absolute;
            width: 28px;
            height: 28px;
            top: -1.51px;
            right: -30px;
            &.hide {
                &:before {
                    transform: translate(-50%, -50%) rotate(-45deg) scale(0);
                    transition: transform 0.5s;
                }
            }
            &:before {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: ${(props) => props.theme.colors.secondary};
                border-radius: 8px;
                z-index: 1;
                transform: translate(-50%, -50%) rotate(-45deg) scale(1);
                transition: transform 0.5s;
            }

            img {
                width: 28px;
            }
        }

        .dinheiro {
            position: relative;
            overflow: hidden;
            width: 115px;
            height: 27px;
            margin-right: 4px;
            margin-left: 4px;
            line-height: normal;
            &.show {
                &::after {
                    opacity: 0;
                    transition: all 0.5s;
                }
                &::before {
                    opacity: 0;
                    transition: all 0.5s;
                }
            }
            &:after {
                background: #eeeeee;
            }
            &:before {
                background-image: linear-gradient(
                    90deg,
                    #eeeeee 12.24%,
                    #fff 107.14%
                );
            }

            &:after {
                content: "";
                position: absolute;
                color: grey;
                display: inline-block;
                background-repeat: no-repeat;
                font-size: 50px;
                max-width: 200px;
                top: 50%;
                left: 0px;
                transform: translateY(-50%);
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity 0.5s;
                border-radius: 4px;
            }
            &:before {
                content: "";
                position: absolute;
                color: grey;
                display: inline-block;
                background: linear-gradient(
                    90deg,
                    #eeeeee 12.24%,
                    #fff 107.14%
                );
                background-repeat: no-repeat;
                font-size: 50px;
                max-width: 200px;
                top: -1px;
                left: 0px;
                transform: translateX(-100%);
                width: 100%;
                height: 106%;
                z-index: 99;
                opacity: 1;
                transition: opacity 0.5s;
                border-radius: 4px;
            }
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }

    .ativar-cartao-avulso {
        height: 100%;
        width: 162px;
        margin-left: auto;
    }
`;
