import * as Yup from "yup";

export interface IAtivarCarao {
    password: string;
    confirm_password: string;
    cvv: string;
    hash?: string;
}

export const ativar: IAtivarCarao = {
    password: "",
    confirm_password: "",
    cvv: "",
};

export const validationAtivarCartao = Yup.object().shape({
    password: Yup.string()
        .min(4, "Senha numérica de 4 dígitos")
        .required("Senha é obrigatório"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Senha é obrigatório"),
    cvv: Yup.string()
        .min(3, "CVV é obrigatório")
        .required("Senha é obrigatório"),
});
