import { Container } from "../DesbloquearCartao/styles";
import { Button } from "../Button";
import { useFormLogin } from "../../../Hook/user/useFormLogin";
import { UseLogin } from "../../../Hook/user/UseLogin";
import { UseModal } from "../../../Hook/Usemodal";
import { rangeDate } from "../../../util/helper";
import { toast } from "react-toastify";
import { useState } from "react";

export function VincularUsuario() {
    const { state, setControllerForm, resetForm } = useFormLogin();
    const { assignUserCampanha } = UseLogin();
    const [loading, setLoading] = useState(false);
    const { handleClose } = UseModal();
    async function fetch() {
        setLoading(true);
        //
        const { account, campanha, document } = state;

        const response = await assignUserCampanha({
            account,
            campanha,
            document: document.replace(/\D/g, ""),
        });
        const { data } = response;

        const start = data.campanha?.start_date_time;
        const end = data.campanha?.end_date_time;

        if (
            data.user?.register_status === 1 &&
            data.campanha?.external_register === 1 &&
            data.user_on_campanha &&
            rangeDate(start, end)
        ) {
            setControllerForm({
                campanha: false,
                documento: false,
                cartaoNoname: false,
                selectCampanha: false,
                completeRegister: false,
                endereco: false,
                password: true,
            });
        } else {
            resetForm();
            toast.error("Algo de errado aconteceu!");
        }

        handleClose("vincularUsuarioCampanha");
        setLoading(false);
    }

    return (
        <Container>
            <h2>CONFIRMA VINCULO</h2>

            <p>
                VOCÊ JÁ POSSUI CADASTRO YETZPAY EM OUTRA CAMPANHA. DESEJA
                ASSOCIAR SEU USUÁRIO À CAMPANHA
                <strong>{state?.campanha}</strong>
            </p>

            <Button
                onClick={fetch}
                isLoading={loading}
                spiner="BallTriangle"
                spinerColor="primary"
            >
                CONFIRMAR
            </Button>
        </Container>
    );
}
