import styled from "styled-components";
import { Container as DefaultHeader } from "../../Header/styles";
import imgLupa from "../../../../assets/img/lupa.svg";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e1e2e3;
    .container-btns {
        display: flex;
        align-items: center;
    }
    ${DefaultHeader} {
        border: none;
    }
    button {
        width: auto;
        padding: 0 16px;
        margin: 0px;
        margin-left: 11px;
    }
    .container-input-system {
        margin-bottom: 0px;
        width: 532px;
        position: relative;
        margin-right: 44px;
        input {
            padding-left: 0px;
            border: none;
            border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            left: unset;
            transform: translateY(-50%);
            background-color: ${(props) => props.theme.colors.secondary};
            -webkit-mask: url(${imgLupa}) no-repeat;
            width: 19px;
            height: 17px;
            z-index: 20;
            transition: opacity 0.5s;
        }
    }
`;
