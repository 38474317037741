/* eslint-disable react/style-prop-object */
import { Text } from "alisson-application";

import { isMobile } from "mobile-device-detect";

import { Container } from "./styles";
import imgSuccess from "../../assets/img/money.svg";
import { tempoFimPlataforma, tempoFimPlataformaExtendido, tempoInicioPlataforma, tempoInicioPlataformaExtendido } from "../../util/helper";
import { Button } from "../Mobile/Button";

export interface LimitesTarifasProps{
    handleClose: () => void;
}

export function LimitesTarifas({handleClose}: LimitesTarifasProps) {
    // function teste() {
    //     const keys = Object.keys(deviceDetect());

    //     return keys
    //         .map((item) => {
    //             return `${item} => ${deviceDetect()[item]}`;
    //         })
    //         .join(" ---  ");
    // }

    return (
        <Container>
            <img className="img-close" src={imgSuccess} alt="" />
            <div className={`content-tarifa ${isMobile ? "mobile" : ""}`}>
                <Text as="h1">Limites e Tarifas</Text>

                <div className="container-itens">
                    <ul>
                        <li>
                            <span>Tarifa de emissão de Cartão Físico<span className="ref">I</span></span>
                            <p>R$ 15,00</p>
                        </li>
                        {/* <li>
            <span>Tarifa de emissão de cartão virtual</span>
            <p>R$ 1,90</p>
        </li> */}
                        <li>
                            <span>Frete para envio do Cartão<span className="ref">I</span></span>
                            <p>R$ 15,00</p>
                        </li>
                        <li>
                            <span>
                            Limite de Saque<br />
                                (até 2 transações diárias)<span className="ref">I</span>
                            </span>
                            <p>R$ 500,00</p>
                        </li>
                        <li>
                            <span>
                                Tarifa de Saque<br />
                                (caixa eletrônico do Banco 24h e/ou Rede Saque e
                                Pague e/ou Rede Cirus)<span className="ref">I</span>
                            </span>
                            <p>R$ 6,90</p>
                        </li>

                        <li>
                            <span>Limite de transação por Compra</span>
                            <p>R$ 9.990,00</p>
                        </li>

                        <li>
                            <span>Tarifa de SMS (opcional)</span>
                            <p>R$ 2,00 (mensal)</p>
                        </li>

                        <li>
                            <span>
                                Tarifa de Inatividade <br />
                                (após 6 meses)
                            </span>
                            <p>R$ 7,90 (mensal)</p>
                        </li>

                        <li>
                            <span>Tarifa de Pagamento de Contas</span>
                            <p>R$3,50</p>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>Transferência da Carteira</strong> para: <br/><br/>
                                <strong>Cartões Físicos:</strong>{" "}até R$ 10.000,00
                                mensais, em dias úteis, das{" "}
                                {tempoInicioPlataformaExtendido}h às {tempoFimPlataformaExtendido}
                                h.
                                <br />
                                <br />
                                <strong>Cartões Virtuais:</strong>{" "}até R$ 1.500,00 mensais,
                                em dias úteis, das {tempoInicioPlataformaExtendido}h às {tempoFimPlataformaExtendido}h. (Para transferir valores mais altos, solicite Cartão Físico).
                            </span>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>Transferência Entre Cartões:</strong>{" "}
                                até R$ 2.000,00 por mês, em dias úteis, das {tempoInicioPlataformaExtendido}h às {tempoFimPlataformaExtendido}
                                h.
                            </span>
                        </li>

                        <li className="linha-unica">
                            <span>
                                <strong>Pagamento de Contas</strong>{" "}com:<br/><br/>
                                <strong>Cartões Físicos:</strong>{" "}até R$ 10.000,00 mensais, em dias úteis, das {tempoInicioPlataforma}h às {tempoFimPlataforma}h.
                                <br />
                                <br />
                                <strong>Cartões Virtuais</strong>:{" "}até R$ 1.500,00 mensais, em dias úteis, das {tempoInicioPlataforma}h às {tempoFimPlataforma}
                                h.
                            </span>
                        </li>

                        <li className="linha-unica">
                            <span className="ref-text">
                                <span className="ref">I</span>
                                <span className="text">
                                    Apenas para campanhas que contemplem esta
                                    modalidade
                                </span>
                            </span>
                        </li>

                        <Button  onClick={() => handleClose()}>
                            Voltar
                        </Button>
                    </ul>
                </div>
            </div>
        </Container>
    );
}
