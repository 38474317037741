import styled from "styled-components";

export const Container = styled.div`
    padding-bottom: 32px;
    /* min-height: 559px; */
    /* min-height: 657px; */

    .container-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 99px;
        border-bottom: 1px solid #e1e2e3;
        margin: 0 5%;
    }
    .container-tabs {
        width: 90%;
        margin: 0 auto;
    }
`;
