import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { Error } from "./error";
import { Success } from "./success";
import { Table } from "./table";
import { UseLogCarga, Log } from "../../../Hook/system/useLogCargaEstorno";
import { HistoryProps } from "../Carga/types";
import { Config } from "./types";

export function LogCargaDetalheEstorno() {
    const [error, setError] = useState(false);
    const [alert, setAlert] = useState(false);
    const history = useHistory<HistoryProps>();
    const [data, setData] = useState<Log[]>([]);
    const [empty, setEmpty] = useState(false);
    const [config, setConfig] = useState<Config>({} as Config);
    const { getLogEstorno } = UseLogCarga();
    const type = history.location.state?.type;
    const campanha = history.location.state.campanha;

    useEffect(() => {
        if (data?.length === 0) {
            setEmpty(true);
        } else {
            setEmpty(false);
        }
    }, [data]);

    useEffect(() => {
        async function fetchData() {
            const { data } = await getLogEstorno();
            setConfig({
                nome: data.nome,
                created_at: data.created_at,
                status: data.status,
                tipo: data.tipo,
                nome_campanha: campanha,
            });

            setData(
                data[type ? type : "creditos_estorno_log"]
                    ? data[type ? type : "creditos_estorno_log"]
                    : []
            );
        }

        fetchData();
    }, [getLogEstorno, type, campanha]);

    return (
        <>
            <Header error={error} empty={empty} config={config} />
            <SubHeader {...config} />
            {error || alert ? (
                !empty ? (
                    <Error alert={alert} />
                ) : null
            ) : !empty ? (
                <Success />
            ) : null}
            <Table
                data={data}
                setError={setError}
                setAlert={setAlert}
                type={type}
            />
        </>
    );
}
