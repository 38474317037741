import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const passwordRegex = new RegExp(
    "^(?=.*\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\\w\\d\\s:])([^\\s]){8,20}$",
    "g"
);

const updatePasswordSchema = z
    .object({
        current_password: z.string().min(1, "A senha não deve ser nula!"),
        new_password: z
            .string()
            .min(8, "A senha deve ter no minimo 8 caracteres!")
            .regex(
                passwordRegex,
                "A senha deve ter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial"
            ),
        new_password_confirmation: z.string(),
    })
    .refine(
        ({ new_password, new_password_confirmation }) =>
            new_password === new_password_confirmation,
        {
            message: "A confirmação de senha não confere!",
            path: ["new_password_confirmation"],
        }
    );

export const updatePasswordResolver = {
    resolver: zodResolver(updatePasswordSchema),
};

export type updatePasswordFormData = {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
};
