import imgLixeira from "../../../assets/img/lixeira.svg";

interface BtnDeleteProps {
    onClick: () => void;
}

export function BtnDelete(props: BtnDeleteProps) {
    return (
        <button onClick={() => props.onClick()}>
            <img src={imgLixeira} alt="" />
        </button>
    );
}
