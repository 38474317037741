import { Box, Flex } from "alisson-application";

import { useLogin } from "../../../Hook/system/useLogin";
import Modal from "../../NovoModal";
import { Authenticator } from "../Authenticator";
import { FormLogin } from "./FormLogin";
import { ModalValidaLogin } from "./Modal";

import homemImg from "../../../assets/img/fundo-login-desktop.png";

import { Container, Form } from "./styles";

export function Login() {
    const { modalConfirm, setModalConfirm, authenticator } = useLogin();

    return (
        <Container>
            {authenticator ? (
                <Authenticator />
            ) : (
                <Flex alignItems="center" justifyContent="space-between">
                    <Box className="img-fundo">
                        <img src={homemImg} alt="" />
                    </Box>
                    <Form>
                        <FormLogin />
                    </Form>
                </Flex>
            )}

            {modalConfirm ? (
                <Modal
                    open={modalConfirm}
                    handleClose={() => setModalConfirm(false)}
                    classe={"system-login-modal"}
                >
                    <ModalValidaLogin />
                </Modal>
            ) : null}
        </Container>
    );
}
