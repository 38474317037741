import styled from "styled-components";

export const Container = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 99px;
    border-bottom: 1px solid #e1e2e3;
    .notificacao{
        position: relative;
        &:before{
            content "";
            display: block;
            position: absolute;
            top: -5px;
            left: 5px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: ${(props) => props.theme.colors.failure}
        }
    }
`;
