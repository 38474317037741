import { ModalConfig } from "../config";
import Modal from "../../NovoModal";
import { PageAtivarCartao } from "../../../pages/AtivarCartao";
import { usePassos } from "../../../Hook/UsePassos";
import { useUser } from "../../../Hook/user/UseUser";
import { useHistory } from "react-router-dom";

export function AtivarCartao(props: ModalConfig) {
    const { dados } = usePassos();
    const { getUser } = useUser();
    const { open, handleClose } = props;
    const width = window.screen.width;
    const history = useHistory();

    return (
        <Modal
            open={open}
            handleClose={async () => {
                handleClose();

                if (dados?.operation) {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        await getUser();
                    }
                }
            }}
        >
            <PageAtivarCartao />
        </Modal>
    );
}
