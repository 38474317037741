import styled from "styled-components";
import imgLupa from "../../../assets/img/lupa.svg";
import { Container as DefaultHeader } from "../Header/styles";
export const Container = styled.div`
    .busca {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        .container-esq {
            display: flex;
        }
        ${DefaultHeader} {
            border: none;
        }
        button {
            width: auto;
            margin: 0px;
            margin-left: 11px;
        }
        .container-input-system {
            margin-bottom: 0px;
            width: 532px;
            position: relative;
            input {
                padding-left: 45px;
            }
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 13px;
                transform: translateY(-50%);
                background-color: ${(props) => props.theme.colors.secondary};
                -webkit-mask: url(${imgLupa}) no-repeat;
                width: 19px;
                height: 17px;
                z-index: 20;
                transition: opacity 0.5s;
            }
        }
    }
`;
