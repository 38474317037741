import { Box } from "alisson-application";
import { useHistory } from "react-router-dom";
import { useUser } from "../../Hook/user/UseUser";
import { UseModal } from "../../Hook/Usemodal";
import { HeaderModal } from "../Mobile/HeaderModal";
import { Button } from "../../components/Desktop/Button";
import { CardNotificacao } from "./notificacao";
import { Container } from "./styles";

interface NotificacaoProps {
    handleClose: () => void;
}

export function Notificacao(props: NotificacaoProps) {
    const { handleClose } = props;
    const { user } = useUser();
    const { handleClose: handleCloseModal } = UseModal();
    const history = useHistory();

    return (
        <Container>
            <Box p="0 5%">
                <HeaderModal title="notificações" handleClose={handleClose} />
            </Box>

            {user?.notificacoes?.length ? (
                <div className="container-notificacoes">
                    {user?.notificacoes
                        ?.filter(
                            (item) =>
                                item.describe !== "__ACTIVE_MODAL_CREDITO__"
                        )
                        .map((notificaco) => {
                            return notificaco.acao ? (
                                notificaco.acao?.tipo === "NOTIFICACAO" && (
                                    <CardNotificacao
                                        {...notificaco}
                                        key={notificaco.id}
                                    />
                                )
                            ) : (
                                <CardNotificacao
                                    {...notificaco}
                                    key={notificaco.id}
                                />
                            );
                        })}
                </div>
            ) : null}

            <Box width="50%" m="32px auto  0px">
                <Button
                    onClick={() => {
                        history.push("/notificacoes");
                        handleCloseModal("notificacao");
                    }}
                >
                    Ver Todas
                </Button>
            </Box>
        </Container>
    );
}
