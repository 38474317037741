import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { VerificacaoDocumento } from "../../Mobile/VerificacaoDocumento";

export function VerificacaoDocument(props: ModalConfig) {
    const { open, handleClose } = props;
    return (
        <ModalBottom open={open} handleClose={handleClose}>
            <VerificacaoDocumento />
        </ModalBottom>
    );
}
