export const ADDRESSE = {
    addressType: "",
    city: "",
    complement: "",
    country: "",
    isActive: false,
    mailingAddress: false,
    neighborhood: "",
    number: 0,
    postalCode: "",
    state: "",
    street: "",
};

export const PERSON = {
    birthdate: "",
    gender: "",
    name: "",
};

export const COMPANY = {
    id: "",
    name: "",
};

export const PHONE = {
    ddd: 0,
    ddi: 0,
    id: 0,
    isActive: false,
    number: "",
    phoneType: "",
};

export const EMAIL = {
    email: "",
    emailType: "",
    isActive: false,
};
