import { ChangeEvent, useEffect, useState } from "react";
import { Flex, Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Button } from "../../Button";
import imgUser from "../../../../assets/img/user.png";
import imgExtrato from "../../../../assets/img/extrato.svg";
import {
    useUser,
    ValuesUpdateUser,
    ValuesUpdateEndereco,
} from "../../../../Hook/system/useUser";
import {
    formataCPF,
    phoneNumber,
    formatCurrentMoney,
    formatCep,
    getLocalStore,
    maskDateRegex,
} from "../../../../util/helper";
import Modal from "../../../NovoModal";
import { ExtratoCarteira } from "./extratocarteira";
import { FormAlterarSenha } from "./alterarSenha";
import { UserStyles } from "./styles";
import { DebounceInput } from "react-debounce-input";

export function User() {
    const { user, getExtratoCarteira, updateAdmUser, updateAdmEndereco } =
        useUser();
    const history = useHistory<{ id: number }>();
    const [loading, setLoading] = useState(false);
    const [modalExtrato, setModalExtrato] = useState(false);
    const [modalAlterarSenha, setModalAlterarSenha] = useState(false);
    const id = history.location?.state?.id;
    const hasPermission =
        getLocalStore()?.permissions["usuario.can_update"] || false;

    const [values, setValues] = useState<ValuesUpdateUser>(
        {} as ValuesUpdateUser
    );

    const [valuesEndereco, setValuesEndereco] = useState<ValuesUpdateEndereco>(
        {} as ValuesUpdateEndereco
    );

    async function updateUser(e: ChangeEvent<HTMLInputElement>) {

        const name = e.target.name;

        let value = name === "document" || name === "phone_number" || name === "data_nascimento" 
        ? e.target.value.replace(/\D/g, "")
        : e.target.value;

        const update = { ...values, [name]: value };

        if (name === "document") update.document = formataCPF(value);
        if (name === "phone_number") {
            update.phone_number = phoneNumber(value);
            value = "55" + value.replace(/\D/g, "");
        }
        if (name === "data_nascimento") {
            value = value.replace(
                /([0-9]{2})([0-9]{2})([0-9]{4})/g,
                "$3-$2-$1"
            );
            update.data_nascimento = value.replace(
                /([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                "$3/$2/$1"
            );
        }

        setLoading(true);
        await updateAdmUser({ [name]: value });
        setLoading(false);
        setValues(update);
    }

    async function updateEndereco(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.name;
        const id = user?.enderecos[0].id;
        const hasNumber =
            name !== "complemento" ? e.target.value.match(/\d+/g) : null;

        const value =
            hasNumber !== null
                ? e.target.value.replace(/\D/g, "")
                : e.target.value;
        const update = { ...valuesEndereco, [name]: value };

        if (name === "cep") update.cep = formatCep(value);
        setLoading(true);
        await updateAdmEndereco(id, { [name]: value });
        setLoading(false);
        setValuesEndereco(update);
    }

    useEffect(() => {
        if (user.id) {
            setValues({
                name: user?.name,
                document: formataCPF(user?.document),
                phone_number: phoneNumber(user?.phone_number),
                email: user?.pessoa?.email,
                nome_da_mae: user?.pessoa?.nome_da_mae,
                data_nascimento: user?.pessoa?.data_nascimento.replace(
                    /([0-9]{4})-([0-9]{2})-([0-9]{2})/g,
                    "$3/$2/$1"
                ),
            });

            setValuesEndereco({
                cep: user?.enderecos[0]?.cep
                    ? formatCep(user?.enderecos[0].cep)
                    : "",
                estado: user?.enderecos[0]?.estado,
                cidade: user?.enderecos[0]?.cidade,
                bairro: user?.enderecos[0]?.bairro,
                nome: user?.enderecos[0]?.nome,
                numero: user?.enderecos[0]?.numero,
                complemento: user?.enderecos[0]?.complemento,
            });
        }
    }, [user]);

    //37701-706

    return (
        <UserStyles>
            <div className="container-user">
                <div className="user">
                    <div className="container-text-img">
                        <div className="container-img">
                            <img
                                src={user.image ? user.image : imgUser}
                                alt=""
                            />
                        </div>

                        <div className="container-text">
                            <small>APELIDO</small>
                            <h3>{user?.pessoa?.apelido}</h3>
                        </div>
                    </div>

                    <Button onClick={() => setModalAlterarSenha(true)}>
                        <Text as="p" m="16px 0px 0px" color="secondary">
                            ALTERAR SENHA
                        </Text>
                    </Button>

                    <Flex className="container-itens" alignItems="center">
                        <div className="container-saldo">
                            <p>SALDO DA CARTEIRA</p>
                            <h4>
                                R$
                                {formatCurrentMoney(
                                    parseFloat(
                                        user?.credito_total
                                            ? user.credito_total
                                            : ""
                                    )
                                )}
                            </h4>
                        </div>

                        <button
                            onClick={async () => {
                                setModalExtrato(true);
                                await getExtratoCarteira(id);
                            }}
                        >
                            <img src={imgExtrato} alt="" />
                            EXTRATO DA CARTEIRA
                        </button>
                    </Flex>
                </div>

                <div className="container-dados">
                    <div className="container-info-pessoal">
                        <div className="info">
                            <small>NOME COMPLETO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateUser}
                                disabled={loading || !hasPermission}
                                value={values.name}
                                name="name"
                            />
                        </div>
                        <div className="info">
                            <small>CPF: </small>

                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                placeholder=""
                                name="document"
                                onChange={updateUser}
                                disabled={loading || !hasPermission}
                                value={values.document}
                            />
                        </div>
                        <div className="info">
                            <small>CELULAR: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                placeholder=""
                                name="phone_number"
                                onChange={updateUser}
                                disabled={loading || !hasPermission}
                                value={values.phone_number}
                            />
                        </div>
                        <div className="info">
                            <small>EMAIL: </small>
                            <DebounceInput
                                type="text"
                                className="email"
                                debounceTimeout={1000}
                                onChange={updateUser}
                                disabled={loading || !hasPermission}
                                value={values.email}
                                name="email"
                            />
                        </div>
                        <div className="info">
                            <small>NOME DA MÃE: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateUser}
                                disabled={loading || !hasPermission}
                                value={values.nome_da_mae}
                                name="nome_da_mae"
                            />
                        </div>
                        <div className="info">
                            <small>DATA DE NASCIMENTO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={(e) => {
                                    updateUser(e);
                                    maskDateRegex(e.target.value);
                                }}
                                disabled={loading || !hasPermission}
                                value={values.data_nascimento}
                                name="data_nascimento"
                            />
                        </div>
                    </div>

                    <div className="container-info-endereco">
                        <div className="info">
                            <small>CEP: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.cep}
                                name="cep"
                            />
                        </div>
                        <div className="info">
                            <small>ESTADO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.estado}
                                name="estado"
                            />
                        </div>
                        <div className="info">
                            <small>CIDADE: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.cidade}
                                name="cidade"
                            />
                        </div>
                        <div className="info">
                            <small>BAIRRO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.bairro}
                                name="bairro"
                            />
                        </div>
                        <div className="info">
                            <small>RUA: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.nome}
                                name="nome"
                            />
                        </div>
                    </div>

                    <div className="container-info-complemento">
                        <div className="info">
                            <small>NÚMERO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.numero}
                                name="numero"
                            />
                        </div>
                        <div className="info">
                            <small>COMPLEMENTO: </small>
                            <DebounceInput
                                type="text"
                                debounceTimeout={1000}
                                onChange={updateEndereco}
                                disabled={loading || !hasPermission}
                                value={valuesEndereco.complemento}
                                name="complemento"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                open={modalExtrato}
                handleClose={() => setModalExtrato(false)}
            >
                {modalExtrato ? <ExtratoCarteira /> : null}
            </Modal>

            <Modal
                open={modalAlterarSenha}
                handleClose={() => setModalAlterarSenha(false)}
            >
                {modalAlterarSenha ? (
                    <FormAlterarSenha
                        onSuccess={() => setModalAlterarSenha(false)}
                    />
                ) : null}
            </Modal>
        </UserStyles>
    );
}
