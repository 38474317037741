import styled from "styled-components";

export const Container = styled.div`
    padding: 0 5%;
    padding-bottom: 55px;
    padding-top: 19px;
    &.active {
        padding: 0px;
        padding-top: 39px;
        .itens {
            grid-template-columns: repeat(3, 1fr);
            display: grid;
            grid-gap: 17px;
            margin-top: 0px;
            & > div {
                margin-bottom: 0px;
            }
        }
    }
    .itens {
        margin-top: 19px;
        & > div {
            margin-bottom: 23px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`;
