import { Header } from "./header";
import { CardsTutorial } from "./cards";
import { Container } from "./styles";
export function Tutorias() {
    return (
        <Container>
            <div className="container">
                <Header />
                <CardsTutorial />
            </div>
        </Container>
    );
}
