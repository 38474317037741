import { memo } from "react";
import { Text } from "alisson-application";
import { Button } from "../Button/areas";
import { useHistory } from "react-router-dom";
import { useCampanha } from "../../../Hook/system/useCampanha";
import { formatDateIsoSystem } from "../../../util/helper";
import { HistoryProps } from "./types";

import imgSetaEsq from "../../../assets/img/seta-esq-sistema.svg";
import imgEditar from "../../../assets/img/editar.svg";
import imgChave from "../../../assets/img/chave-system.svg";
import imgUser from "../../../assets/img/user.svg";
import imgCalendario from "../../../assets/img/chave-system.svg";

import { InformacaoLateralStyles } from "./styles";

function InformacaoLateral() {
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const { campanhaById } = useCampanha();

    if (!id) {
        history.goBack();
        return <></>;
    }

    return (
        <InformacaoLateralStyles>
            <div className="container-header">
                <button
                    onClick={() => history.goBack()}
                    className="voltar-page"
                >
                    <img src={imgSetaEsq} alt="" />
                    <Text color="secondary" fontSize="xs">
                        VOLTAR
                    </Text>
                </button>

                <div className="acaoes">
                    <Button className={`${campanhaById?.status}`}>
                        {campanhaById?.status}
                    </Button>
                    <button
                        className="acao-editar"
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/campanha/editar/${id}`,
                                state: {
                                    id,
                                    campanha: campanhaById.nome,
                                },
                            })
                        }
                    >
                        <img src={imgEditar} alt="" />
                        <Text color="secondary" fontWeight="600" fontSize="xs">
                            EDITAR
                        </Text>
                    </button>
                </div>

                <div className="informacao-campanha">
                    <Text color="primaryDark" fontSize="xs" mb="16px">
                        NOME DA CAMPANHA
                    </Text>

                    <Text as="h3">{campanhaById?.nome}</Text>

                    <Text as="h5">{campanhaById?.cliente?.nome}</Text>

                    <Text color="primaryDark" fontSize="xss" mb="16px">
                        {campanhaById?.cliente_area_contato?.area?.nome}
                    </Text>
                    <Text color="primaryDark" fontSize="xss">
                        {campanhaById?.cliente_area_contato?.nome}
                    </Text>
                </div>

                <div className="detalhes">
                    <div className="detalhe">
                        <div className="card">
                            <img src={imgChave} alt="" />
                        </div>
                        <div className="text">
                            <Text color="primaryDark" fontSize="xs">
                                CHAVE
                            </Text>
                            <Text fontSize="md" fontWeight="600">
                                {campanhaById?.chave}
                            </Text>
                        </div>
                    </div>

                    <div className="detalhe">
                        <div className="card">
                            <img src={imgUser} alt="" />
                        </div>
                        <div className="text">
                            <Text color="primaryDark" fontSize="xs">
                                Nº DE USUÁRIOS
                            </Text>
                            <Text fontSize="md" fontWeight="600">
                                {campanhaById?.total_users}
                            </Text>
                        </div>
                    </div>

                    <div className="detalhe">
                        <div className="card">
                            <img src={imgCalendario} alt="" />
                        </div>
                        <div className="text">
                            <Text color="primaryDark" fontSize="xs">
                                DATA DE INÍCIO
                            </Text>
                            <Text fontSize="md" fontWeight="600" as="p">
                                {formatDateIsoSystem(
                                    campanhaById.start_date_time
                                )}
                            </Text>
                        </div>
                    </div>

                    <div className="detalhe">
                        <div className="card">
                            <img src={imgCalendario} alt="" />
                        </div>
                        <div className="text">
                            <Text color="primaryDark" fontSize="xs">
                                DATA DE FIM
                            </Text>
                            <Text fontSize="md" fontWeight="600" as="p">
                                {formatDateIsoSystem(
                                    campanhaById.end_date_time
                                )}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </InformacaoLateralStyles>
    );
}
export const InformacaoLateralMemo = memo(InformacaoLateral);
