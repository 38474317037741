import { Text } from "alisson-application";
import { FieldProps } from "formik";
import { useFile } from "../hook";

export function File(props: FieldProps) {
    const { form } = props;
    const { setFieldValue } = form;
    const { handleFilesArq, arqFiles } = useFile();

    return (
        <>
            <label htmlFor="arquivo" className="arquivo">
                <Text as="p">
                    {arqFiles?.name ? arqFiles?.name : "Selecione o arquivo"}
                </Text>
                <input
                    type="file"
                    id="arquivo"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => {
                        if (e.target.files?.length) {
                            const file = e.target.files[0];

                            handleFilesArq(file);
                            setFieldValue("recipientes", "ok");
                        }
                    }}
                />
            </label>
        </>
    );
}
