import * as Yup from "yup";

export type keyOf<T> = keyof T;
export const regexName = /^[A-Za-z ]{1,26}$/g;

export interface TipoCartao {
    cartao_fisico: string;
    cartao_virtual: string;
}

export interface ConfirmaDadosCartao {
    cpf: string;
    nome_completo: string;
    celular: string;
    data_nascimento: string;
}
export const confirmaDadosCartao: ConfirmaDadosCartao = {
    cpf: "",
    nome_completo: "",
    celular: "",
    data_nascimento: "",
};

export const validaDadosCartao = Yup.object().shape({
    cpf: Yup.string(),
    nome_completo: Yup.string(),
    celular: Yup.string(),
    data_nascimento: Yup.string(),
});
