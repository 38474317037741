import { Header } from "./header";
import { User } from "./user";
import { Table } from "./table";
import { Container } from "./styles";

export function Detalhe() {
    return (
        <Container>
            <Header />
            <User />
            <Table />
        </Container>
    );
}
