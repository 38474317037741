import { Header } from "../Header";
import { Tabs } from "./tabs";
import { Content } from "./content";
import { Aprovar } from "./modal/aprovar";
import { Reprovar } from "./modal/reprovar";
import { Container } from "./styles";
import imgDownload from "../../../assets/img/dowload.svg";
import { api } from "../../../services";
import { toast } from "react-toastify";
import {
    formatDateIso,
    getFirsDayMonth,
    getLastDayMonth,
} from "../../../util/helper";
import { useState } from "react";
import { Button } from "../Button";

export function AprovacaoTransferencia() {
    const [loading, setLoading] = useState(false);
    const primeiroDiaDoMes = formatDateIso(getFirsDayMonth(new Date()))
        .split(" - ")[0]
        .split(".")
        .reverse()
        .join("-");
    const ultimoDiaDoMes = formatDateIso(getLastDayMonth(new Date()))
        .split(" - ")[0]
        .split(".")
        .reverse()
        .join("-");

    async function exportar() {
        setLoading(true);
        try {
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(
                `pedido-credito/export?from=${primeiroDiaDoMes}&to=${ultimoDiaDoMes}`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
            setLoading(false);
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
            setLoading(false);
        }
    }

    return (
        <Container>
            <Header title="APROVAÇÃO DE TRANSFERÊNCIA" />

            <div className="container-export">
                <Button
                    onClick={exportar}
                    disabled={loading}
                    isLoading={loading}
                    spiner="BallTriangle"
                    spinerColor="secondary"
                >
                    <img src={imgDownload} alt="" />
                    exportar tudo
                </Button>
            </div>

            <Tabs />

            <Content />

            <Aprovar />
            <Reprovar />
        </Container>
    );
}
