import { ChangeEvent, useEffect, useState } from "react";
import { Box } from "alisson-application";
import Mask from "react-input-mask";
import { useHistory } from "react-router-dom";
import {
    Formik,
    Form,
    ErrorMessage,
    FormikProps,
    Field,
    FormikHelpers,
} from "formik";
import { useUser } from "../../../Hook/system/useUser";
import Select, { options } from "../NovoSelect";
import { Button } from "../Button";
import { Myform, initalValues, validadeForm } from "./config";
import { FormStyles } from "./styles";
import { api } from "../../../services";
import { toast } from "react-toastify";
import { maskCPF } from "../../../util/helper";

interface Role {
    name: string;
    id: number;
}

export function FormUsuarios() {
    const { storeUserAdm } = useUser();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<options[]>([]);

    async function getUserPermission() {
        try {
            const response = await api.get<Role[]>("/role");
            const options: options[] = response.data.map((item) => {
                return {
                    label: item.name,
                    value: item.id,
                };
            });
            setOptions(options);
        } catch (error) {
            toast.error("Erro ao buscar permissões");
            console.log(error);
        }
    }

    async function handleSubmit(
        values: Myform,
        actions: FormikHelpers<Myform>
    ) {
        const response = await storeUserAdm(values);

        if (response.status === 200) {
            actions.resetForm();
            history.push("/sistema/administracao");
        }
    }

    useEffect(() => {
        getUserPermission();
    }, []);

    return (
        <FormStyles>
            <Formik
                initialValues={initalValues}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    values = {
                        ...values,
                        email: values.email.replace(/\D/g, ""),
                        phone_number:
                            "55" + values.phone_number.replace(/\D/g, ""),
                    };

                    setLoading(true);
                    await handleSubmit(values, actions);
                    setLoading(false);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { handleChange, values, setFieldValue } = props;
                    const { phone_number } = values;

                    return (
                        <Form>
                            <div className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="name"
                                />
                            </div>
                            <ErrorMessage component="span" name="name" />

                            <div className="container-input-system user">
                                <Field
                                    placeholder="Login"
                                    name="email"
                                    type="tel"
                                    autoComplete="organization"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF(
                                            e.target.value
                                        );

                                        if (document.length <= 14) {
                                            setFieldValue("email", document);
                                        }
                                    }}
                                    value={values.email}
                                />
                            </div>
                            <ErrorMessage component="span" name="email" />

                            <div className="container-input-system celular">
                                <Mask
                                    mask="(99) 99999-9999"
                                    placeholder="CELULAR"
                                    name="phone_number"
                                    type="tel"
                                    onChange={handleChange}
                                    value={phone_number}
                                />
                            </div>
                            <ErrorMessage
                                component="span"
                                name="phone_number"
                            />

                            <div className="container-input-system">
                                <Select
                                    options={options}
                                    label="PERFIL"
                                    onChange={(values) =>
                                        setFieldValue("role", values.label)
                                    }
                                />
                            </div>

                            <div className="container-input-system senha">
                                <Field
                                    type="password"
                                    placeholder="SENHA"
                                    name="password"
                                />
                            </div>
                            <ErrorMessage component="span" name="password" />

                            <div className="container-input-system senha">
                                <Field
                                    type="password"
                                    placeholder="CONFIRMAR SENHA"
                                    name="password_confirmation"
                                />
                            </div>
                            <ErrorMessage
                                component="span"
                                name="password_confirmation"
                            />

                            <Box width="220px" mt="32px" ml="auto">
                                <Button
                                    type="submit"
                                    isLoading={loading}
                                    spiner="ThreeDots"
                                >
                                    CADASTRAR
                                </Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
