import { Text, Grid } from "alisson-application";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "../../../contexts/Localization";
import imgCupom from "../../../assets/img/cupom.svg";
import imgSetadir from "../../../assets/img/seta-dir-verde.svg";
import imgMovimentacao from "../../../assets/img/movimentacao.svg";
import imgCartao from "../../../assets/img/cartao-ativar-branco.svg";
import imgTransferirCarteiraMobile from "../../../assets/img/transferir-carteira-mobile.svg";
import { Button } from "../Button";
import { Container } from "./styles";
import { getCampanha } from "../../../util/helper";

export function AcoesDashBoard() {
    const { t } = useTranslation();
    const history = useHistory();
    const tranferenciaEntreUsuarios =
        getCampanha().allow_transference_between_users;

    const apenasVirtual = getCampanha().produtos.every(
        (item) => item.produto.tipo === "cartao_virtual"
    );

    return (
        <Container className="container">
            {tranferenciaEntreUsuarios ? (
                <Link to="/transferir-entre-carteiras">
                    <li>
                        <Grid
                            alignItems="center"
                            gridTemplateColumns="29px 1fr"
                            gridGap="6px"
                        >
                            <img src={imgTransferirCarteiraMobile} alt="" />
                            <Text
                                color="secondarydark"
                                textTransform="uppercase"
                                fontSize="xss"
                            >
                                Transferir entre carteiras
                            </Text>
                        </Grid>
                        <img src={imgSetadir} alt="" />
                    </li>
                </Link>
            ) : null}

            <Link to="/movimentacao">
                <li>
                    <Grid
                        alignItems="center"
                        gridTemplateColumns="29px 1fr"
                        gridGap="6px"
                    >
                        <img src={imgMovimentacao} alt="" />
                        <Text
                            color="secondarydark"
                            textTransform="uppercase"
                            fontSize="xss"
                        >
                            EXTRATO CARTEIRA
                        </Text>
                    </Grid>
                    <img src={imgSetadir} alt="" />
                </li>
            </Link>

            {getCampanha()?.allow_cupom ? (
                <Link to="/adicionar-cumpom">
                    <li>
                        <Grid
                            alignItems="center"
                            gridTemplateColumns="29px 1fr"
                            gridGap="6px"
                        >
                            <img src={imgCupom} alt="" />
                            <Text
                                color="secondarydark"
                                textTransform="uppercase"
                                fontSize="xss"
                            >
                                {t("adicionar cupom")}
                            </Text>
                        </Grid>
                        <img src={imgSetadir} alt="" />
                    </li>
                </Link>
            ) : (
                <></>
            )}

            {!apenasVirtual ? (
                <Button
                    className="ativar-cartao-avulso"
                    onClick={() => {
                        history.push({
                            pathname: `/ativar-cartao-avulso/${0}`,
                            state: { id: 0 },
                        });
                    }}
                >
                    <img src={imgCartao} alt="" />
                    ATIVAR CARTÃO FÍSICO
                </Button>
            ) : null}
        </Container>
    );
}
