import * as Yup from "yup";
import { getLocalStore } from "../../../util/helper";
const regexCheckTwoWorks = /^(.*) (.*).*/;

export interface IEmail {
    email: string;
}
export const email: IEmail = {
    email: getLocalStore().email,
};

export const validadeEmail = Yup.object().shape({
    email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),
});

export interface Dados {
    nome_cpf: string;
    nome_da_mae: string;
}

export const dados: Dados = {
    nome_cpf: getLocalStore().name,
    nome_da_mae: getLocalStore().nome_da_mae,
};

export const validadeDAdos = Yup.object().shape({
    nome_cpf: Yup.string()
        .matches(regexCheckTwoWorks, "DIGITE O NOME COMPLETO")
        .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),
    nome_da_mae: Yup.string()
        .matches(regexCheckTwoWorks, "DIGITE O NOME COMPLETO")
        .min(2, "Mínimo 2 caracteres")
        .required("DIGITE O NOME COMPLETO"),
});
