import { useContext, createContext, useState } from "react";
import { AxiosResponse } from "axios";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

import { api } from "../../../../../../services";
import { User } from "../../../../../../Hook/system/useUser";
import { toast } from "react-toastify";
import { PaginationConfig } from "../../../../../../Hook/system/config";
import { ClienteArea } from "../../../../../../Hook/system/useCampanha";
import { Detalhe } from "../../CartaoDeCredito/Hook";

const UseCreditoContext = createContext<useCreditoData>({} as useCreditoData);

interface useCreditoProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useCreditoData {
    searchCarga(cardNumber: string): Promise<cargaData>;
    getCarga: (page?: number) => Promise<cargaData>;
    inProcess: () => Promise<process[]>;

    storeCarga: (values: FormData) => Promise<AxiosResponse>;
    setFile: (value: File | null) => void;
    file: File | null;

    aprove: (id: number) => Promise<AxiosResponse>;
    disApproval: (id: number) => Promise<AxiosResponse>;
    template: () => Promise<any>;
    getDetalhes(): Promise<Detalhe>;
}

interface cargaData extends PaginationConfig {
    data: carga[];
}

export interface carga {
    approve_user_id: string | null;
    campanha_id: number;
    created_at: string;
    id: number;
    job: string;
    nome: string;
    status: string;
    tipo: string;
    total_itens_credito_sum: string;
    total_itens_users: number;
    total_itens_credito_estornado: string;
    updated_at: string;
    user: User;
    user_id: number;
    file: string;
    approve_user: User;
    cliente_area_contato: ClienteArea | null;
    observacao: string | null;
}

// interface ApproveUser {
//     id: number;
//     name: string;
//     email: string;
//     document: string;
//     phone_number: string;
//     status: number;
//     register_status: number;
//     registered_at: null | string;
//     first_login: number;
//     image: null | string;
//     token: null | string;
//     created_at: string;
//     updated_at: string;
//     enderecos: [];
// }

interface processData {
    data: process[];
}

export interface process {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: string | null;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    user: User;
    total_itens_users: number;
    total_itens_credito_sum: string;
    total_logs: number;
    total_noname_credito_log_sum: number;
}

export function EstornoProvider(props: useCreditoProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;
    const [file, setFile] = useState<File | null>(null);

    async function searchCarga(cardNumber: string) {
        try {
            const { data } = await api.get<cargaData>(
                `carga/search?page=1&tipo=CREDITO_ESTORNO&search=${cardNumber}&campanha_id=${id}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as cargaData;
        }
    }

    async function getCarga(page?: number) {
        const hasPage = page ? `&page=${page}` : "";
        try {
            const { data } = await api.get<cargaData>(
                `carga?campanha_id=${id}&status[]=APROVADO&tipo[]=CREDITO_ESTORNO${hasPage}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as cargaData;
        }
    }

    async function storeCarga(values: FormData) {
        const headers = {
            "Content-Type": "multipart/form-data",
        };
        try {
            const response = await api.post("/carga", values, { headers });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    async function inProcess() {
        try {
            const { data } = await api.get<processData>(
                `carga?status[]=AGUARDANDO&status[]=PROCESSANDO&campanha_id=${id}&tipo[]=CREDITO_ESTORNO`
            );
            return data.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return [] as process[];
        }
    }

    async function aprove(id: number) {
        try {
            const response = await api.post(`carga/aprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga aprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function disApproval(id: number) {
        try {
            const response = await api.post(`carga/reprovar`, {
                carga_id: `${id}`,
            });
            toast.success("Carga reprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function template() {
        try {
            const response = await api.get<any>(
                `campanha/${id}/export-example?type=CREDITO_ESTORNO`
            );
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Algo de errado aconteceu");
            return error;
        }
    }

    async function getDetalhes() {
        try {
            const response = await api.get<Detalhe>(`carga/${id}`);
            return response.data;
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            return {} as Detalhe;
        }
    }

    return (
        <UseCreditoContext.Provider
            value={{
                searchCarga,
                getCarga,
                storeCarga,
                setFile,
                file,
                inProcess,
                aprove,
                disApproval,
                template,
                getDetalhes,
            }}
        >
            {children}
        </UseCreditoContext.Provider>
    );
}

export function useEstorno() {
    const context = useContext(UseCreditoContext);
    return context;
}
