import { Header } from "./header";
import { SubHeader } from "./subHeader";
import { Table } from "./table";
import { EstornoProvider, useEstorno } from "../Hook";
import { Detalhe as IDetalhe } from "../../CartaoDeCredito/Hook";
import { useCallback, useEffect, useState } from "react";
import { Loader } from "../../../../../Loader";
export function Detalhe() {
    const { getDetalhes } = useEstorno();
    const [data, setData] = useState<IDetalhe>({} as IDetalhe);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const data = await getDetalhes();
        setData(data);
        setLoading(false);
    }, [getDetalhes]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            <Header />
            <SubHeader date={data.created_at} name={data.nome} />
            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <Table data={data.creditos_estorno} updateTable={fetchData} />
            )}
        </>
    );
}

export function DetalheEstorno() {
    return (
        <EstornoProvider>
            <Detalhe />
        </EstornoProvider>
    );
}
