import { Container } from "./styles";

interface StatusProps {
    label?: string;
    handleClick?: () => void;
    active: boolean;
}

export function Status(props: StatusProps) {
    const { label, handleClick, active } = props;

    return (
        <Container
            className={`${active ? "active" : ""}`}
            onClick={handleClick}
        >
            <p className={`${active ? "active" : ""}`}>RESOLVIDO</p>

            <p className={`${!active ? "active" : ""} pendente`}>
                {" "}
                {label ? label : "PENDENTE"}
            </p>
        </Container>
    );
}
