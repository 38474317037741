import { useRef } from "react";
import { Formik, Form, Field } from "formik";
import { useBackoffice } from "../../../../../Hook/system/useBackoffice";
import { Header } from "../Header";
import { PersonDate } from "./PersonDate";
import { options } from "../../../../NovoSelect";
import { Input } from "../Input";
import { SelectDate } from "../Select";

export function Person() {
    const { state, createObjBackoffice, updatePerson, loading, hasCNPJ } =
        useBackoffice();
    const btnRef = useRef<HTMLButtonElement>(null);
    const { arrKey, values } = createObjBackoffice(
        hasCNPJ ? state.company : state.person,
        hasCNPJ ? "company" : "person"
    );
    const genderOptions: options[] = [
        { value: "M", label: "Masculino" },
        { value: "F", label: "Feminino" },
        { value: "I", label: "Outros" },
    ];

    async function handleSave() {
        btnRef.current?.click();
    }

    return (
        <>
            <Header
                title="pessoa"
                icon="pessoa"
                onSave={handleSave}
                showBtns={!hasCNPJ}
            />
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={values}
                onSubmit={async (values) => {
                    const { id, ...rest } = values;
                    await updatePerson(id as number, rest);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            {arrKey.map((item) => {
                                const [key] = item;
                                if (key === "birthdate") {
                                    return (
                                        <Field
                                            component={PersonDate}
                                            name={key}
                                            disabled={loading || hasCNPJ}
                                        />
                                    );
                                }
                                if (key === "gender") {
                                    return (
                                        <Field
                                            component={SelectDate}
                                            name={key}
                                            options={genderOptions}
                                            disabled={loading || hasCNPJ}
                                        />
                                    );
                                }
                                return (
                                    <Field
                                        component={Input}
                                        disabled={loading || hasCNPJ}
                                        name={key}
                                    />
                                );
                            })}

                            <button hidden ref={btnRef} type="submit"></button>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
}
