import styled from "styled-components";

export const Container = styled.div`
    .container-loading-fale-conosco {
        position: relative;
        &.active {
            opacity: 0.7;
            cursor: not-allowed;
            .loader-fale-conosco {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .MuiDataGrid-virtualScrollerContent {
        min-height: auto !important;
        height: auto !important;
        .user {
            display: flex;
            align-items: center;

            img {
                width: 32px;
                border-radius: 50%;
                display: block;
                margin-right: 17px;
            }
        }
        .MuiDataGrid-virtualScrollerRenderZone {
            position: static !important;
            .MuiDataGrid-row {
                overflow: hidden;
                min-height: 52px;
                max-height: 52px;
                position: relative;
                transition: max-height, min-height, height, 0.3s;
                div {
                    &.MuiDataGrid-cell {
                        &:nth-child(9) {
                            button {
                                &.active {
                                    img {
                                        transform: rotateX(180deg);
                                        transition: all 0.5s;
                                    }
                                }

                                img {
                                    transition: all 0.5s;
                                }
                            }
                        }
                        &:nth-child(11) {
                            overflow: unset;
                            white-space: pre-wrap !important;
                            max-height: fit-content !important;
                            min-height: auto !important;
                            height: auto !important;
                            width: 100% !important;
                            max-width: 100% !important;
                            min-width: 100% !important;
                            position: absolute;
                            top: 52px;
                            left: 0px;
                        }
                    }
                }
            }
        }
    }
`;

export const DescricaoStyles = styled.div`
    display: flex;
    align-items: start;
    padding: 40px 0px;
    img {
        margin-right: 40px;
    }
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        width: 639px;
        white-space: pre-wrap !important;
    }
`;
