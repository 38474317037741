import { UseLogCargaDeCreditoProvider } from "../../../Hook/system/useLogCargaDeCredito";
import { LogCargaDetalheCredito } from "../../../components/System/LogCargaDetalheCredito";

export function PageLogCargaDetalheCredito() {
    return (
        <UseLogCargaDeCreditoProvider>
            <LogCargaDetalheCredito />
        </UseLogCargaDeCreditoProvider>
    );
}
