import * as Yup from "yup";
import { regexPassword } from "../../../Mobile/EsqueciSenha/config";

export interface Data {
    id: string;
    tipo: string;
    produto: string;
    nome_cartao: string;
    sms: string;
    conta: string;
    numero: string;
    status: string;
    ver_extrato: string;
    entrega: string;
    ver_saldo: string;
    acg_tracking_id: string | null;
}

export interface IAlterarSenha {
    password: string;
    password_confirmation: string;
}

export const altearSenha: IAlterarSenha = {
    password: "",
    password_confirmation: "",
};

export const validationAlterarSenha = Yup.object().shape({
    password: Yup.string()
        .matches(regexPassword, "Por favor, insira uma senha válida")
        .required("Senha é obrigatório"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Senha Não Confere")
        .required("Confirmação de Senha é obrigatório"),
});
