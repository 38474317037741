import styled from "styled-components";

export const SucessoStyles = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 50px 11px 35px;
    text-align: center;
        
    img {
        margin-bottom:22px;        
    }

    h1 {
        flex:1;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.secondarydark};
        margin-bottom: 10px;
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        text-align: center;
        color: ${(props) => props.theme.colors.primary};
        margin-bottom: 42px;
    }

    button{
        max-width: 273px;
        height: 2.5rem;
        font-weight: 700;
        letter-spacing: 0;
        text-transform: none;
    }
`;
