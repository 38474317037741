import { HeaderNovo } from "../Header/novo";
import { FormUsuarios } from "./form";
export function NovoAdministrador() {
    return (
        <>
            <HeaderNovo title="novo administrador" />
            <FormUsuarios />
        </>
    );
}
