import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    .container-btns {
        display: flex;
        align-items: center;
        width: 380px;
        position: absolute;
        top: 30px;
        right: 0px;
        button {
            margin: 0px;
            width: fit-content;
        }
        .detalhe {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            margin-right: auto;
            color: ${({ theme }) => theme.colors.secondary};
        }
    }
    .user {
        img {
            width: 32px;
            border-radius: 50%;
        }
    }
    button {
        margin: 0 auto;
        display: block;
    }
`;
