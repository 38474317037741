import Modal from "../../ModalBottom";
import { UseModal } from "../../../Hook/Usemodal";
import { UseCartoes } from "../../../Hook/user/UseCartaoes";
import { DesbloqueioCartao } from "../DesbloquearCartao";
import { ConfirmaDesbloqueioCartao } from "../DesbloquearCartao/confirmar";
import { HeaderUser } from "../HeaderUser";
import { Saldo } from "../Saldo";
import { AcoesDashBoard } from "../AcoesDashBoard";
import { Cartoes } from "../Cartoes";
import { Empty } from "./empty";

import { Container } from "./styles";

export function Dashboard() {
    const { state, setState } = UseModal();
    const { cartoes } = UseCartoes();
    return (
        <Container>
            <HeaderUser />
            <Saldo />
            <AcoesDashBoard />
            {cartoes?.length === 0 ? <Empty /> : <Cartoes />}

            <Modal
                open={state?.acaoDesbloquearCartaoMobilePasso1}
                handleClose={() =>
                    setState({
                        ...state,
                        acaoDesbloquearCartaoMobilePasso1: false,
                    })
                }
            >
                <DesbloqueioCartao />
            </Modal>
            <Modal
                open={state?.acaoDesbloquearCartaoMobilePasso2}
                handleClose={() =>
                    setState({
                        ...state,
                        acaoDesbloquearCartaoMobilePasso2: false,
                    })
                }
            >
                <ConfirmaDesbloqueioCartao />
            </Modal>
        </Container>
    );
}
