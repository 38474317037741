/* eslint-disable no-useless-escape */
import { ChangeEvent, useState } from "react";
import { useForm } from "react-hook-form";

import { CircleCheck } from "../../../../../../assets/img/CircleCheck";
import { Button } from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import { Input } from "../../components/Input";
import { PasswordInput } from "../../components/PasswordInput";

import { useLogin } from "../../../../../../Hook/system/useLogin";
import { updatePasswordFormData, updatePasswordResolver } from "./schema";
import {
    CustomDescription,
    PasswordCondition,
    PasswordWarning,
    UpdateContainer,
    UpdateForm,
} from "./styles";

interface INewPassword {
    onNextPage: () => void;
}

type PasswordValidations = {
    min_1_lower: boolean;
    min_1_upper: boolean;
    min_1_number: boolean;
    min_1_special: boolean;
    min_8_char: boolean;
    no_repeat_4_char: boolean;
};

export const NewPassword = ({ onNextPage }: INewPassword) => {
    const { updateFirstPassword, user } = useLogin();

    const [passwordConditions, setPasswordConditions] = useState(
        {} as PasswordValidations
    );

    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm<updatePasswordFormData>(updatePasswordResolver);

    function handleNavigateToNextStep() {
        if (onNextPage) onNextPage();
    }

    async function onSubmit(fields: {
        new_password: string;
        new_password_confirmation: string;
        current_password: string;
    }) {
        const response = await updateFirstPassword(fields);

        if (!response.success && response.message)
            return setError("new_password_confirmation", {
                message: response.message,
            });

        handleNavigateToNextStep();
    }

    function handleCheckPassStrength(e: ChangeEvent<HTMLInputElement>) {
        const password = e.target.value;

        const validations: PasswordValidations = {
            min_1_lower: /[a-z]/.test(password),
            min_1_number: /[0-9]/.test(password),
            min_1_special: /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/.test(password),
            min_1_upper: /[A-Z]/.test(password),
            min_8_char: password.length >= 8,
            no_repeat_4_char: !/([0-9A-z])\1\1\1/.test(password),
        };

        setPasswordConditions(validations);
    }

    return (
        <UpdateContainer>
            <h1>Nova Senha</h1>

            <CustomDescription>
                Preencha o campo abaixo com sua nova senha e clique para
                alterar.
            </CustomDescription>

            <UpdateForm onSubmit={handleSubmit(onSubmit)}>
                <Input disabled value={user?.user?.name}></Input>

                <PasswordInput
                    mode="secondary"
                    placeholder="Digite sua senha atual"
                    name="current_password"
                    register={register}
                />

                <ErrorMessage>{errors.current_password?.message}</ErrorMessage>

                <PasswordInput
                    mode="secondary"
                    placeholder="Digite sua nova senha"
                    name="new_password"
                    register={register}
                    onChange={handleCheckPassStrength}
                />

                <ErrorMessage>{errors.new_password?.message}</ErrorMessage>

                <PasswordInput
                    mode="secondary"
                    placeholder="Confirme sua nova senha"
                    name="new_password_confirmation"
                    register={register}
                />

                <ErrorMessage>
                    {errors.new_password_confirmation?.message}
                </ErrorMessage>

                <Button
                    type="submit"
                    disabled={
                        !passwordConditions.min_8_char ||
                        !passwordConditions.min_1_number ||
                        !passwordConditions.min_1_upper ||
                        !passwordConditions.min_1_lower ||
                        !passwordConditions.no_repeat_4_char ||
                        !passwordConditions.min_1_special
                    }
                >
                    Alterar senha
                </Button>
            </UpdateForm>

            <PasswordWarning>
                <h5>
                    <b>ATENÇÃO!</b>
                    <p>Sua senha deve ter obrigatorimente:</p>
                </h5>

                <PasswordCondition $isCorrect={passwordConditions.min_8_char}>
                    <CircleCheck /> No mínimo 8 caracteres
                </PasswordCondition>

                <PasswordCondition $isCorrect={passwordConditions.min_1_number}>
                    <CircleCheck /> No mínimo 1 número
                </PasswordCondition>

                <PasswordCondition $isCorrect={passwordConditions.min_1_upper}>
                    <CircleCheck /> No mínimo 1 letra maiúscula
                </PasswordCondition>

                <PasswordCondition $isCorrect={passwordConditions.min_1_lower}>
                    <CircleCheck /> No mínimo 1 letra minúscula
                </PasswordCondition>

                <PasswordCondition
                    $isCorrect={passwordConditions.no_repeat_4_char}
                >
                    <CircleCheck /> Não repetir 4 caracteres iguais em sequência
                </PasswordCondition>

                <PasswordCondition
                    $isCorrect={passwordConditions.min_1_special}
                >
                    <CircleCheck /> No mínimo 1 caractere especial (!@#$%^*&)
                </PasswordCondition>
            </PasswordWarning>
        </UpdateContainer>
    );
};
