import { TableAprovar } from "./main/table";
import { ReprovadoProvider } from "./Hook";

export function Reprovado() {
    return (
        <ReprovadoProvider>
            <TableAprovar />
        </ReprovadoProvider>
    );
}
