import styled from "styled-components";

export const Container = styled.div`
    .container-header {
        p {
            text-align: center;
            margin-bottom: 32px;
        }
    }
`;

export const StylesForm = styled.div`
    .container-input {
        input {
            text-transform: unset;
        }
    }

    .container-alerta {
        /* margin-top: 32px; */
    }

    button {
        border-radius: 600px;
        height: 40px;
    }
`;
