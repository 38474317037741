import { useContext, createContext, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../../services";
import {
    ADDRESSE,
    EMAIL,
    PERSON,
    PHONE,
    COMPANY,
} from "../../components/System/Backoffice/content/types";

interface useBackofficeProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseBackofficeProps {
    getBackoffice(document: string): Promise<void>;
    cards: BackofficeCard[];
    loading: boolean;
    hasCNPJ: boolean;
    state: BackofficeData;

    updatePerson(id: number, values: any): Promise<void>;

    updateAddresses(id: number, values: any): Promise<void>;
    createAddresses(values: any): Promise<void>;

    updatePhone(id: number, values: any): Promise<void>;
    createPhone(values: any): Promise<void>;

    updateEmail(id: number, values: any): Promise<void>;
    createEmail(values: any): Promise<void>;

    createObjBackoffice(
        obj: KeyValue | KeyValue[],
        name: BackofficeType,
        removeKeys?: string[]
    ): {
        arrKey: [string, string | number][];
        values: {
            [k: string]: string | number;
        };
        arrKeyRemoved: [string, string | number][];
    };
}

export interface KeyValue {
    [key: string]: string | number;
}

interface BackofficeData {
    id: number;
    document: string;
    company: KeyValue;
    person: KeyValue;
    addresses: KeyValue[];
    emails: KeyValue[];
    phones: KeyValue[];
}

export interface BackofficeCard {
    id: number;
    status: string;
    stage: string;
    printedName: string;
    alias: string;
    type: string;
    issuingDate: string;
    last4Digits: string;
    contactlessEnabled: boolean;
    bin: string;
}

type BackofficeType = "addresses" | "person" | "phones" | "emails" | "company";

const Context = createContext<IuseBackofficeProps>({} as IuseBackofficeProps);

export function BackofficeProvider(props: useBackofficeProps) {
    const { children } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [cards, setCards] = useState<BackofficeCard[]>([]);
    const [state, setState] = useState<BackofficeData>({} as BackofficeData);
    const [hasCNPJ, setHasCNPJ] = useState<boolean>(false);

    async function getBackoffice(document: string) {
        try {
            setLoading(true);
            const response = await api.get<BackofficeData>(
                `backoffice/customer/document/${document}`
            );
            const cards = await api.get<BackofficeCard[]>(
                `backoffice/cards/document/${document}`
            );
            setHasCNPJ(document.length === 14);
            setLoading(false);
            setCards(cards.data);
            setState(response.data);
        } catch (error) {
            toast.error("Erro ao buscar usuário");
            setLoading(false);
            console.log(error);
        }
    }

    function objBackoffice(value: BackofficeType) {
        switch (value) {
            case "addresses":
                return ADDRESSE;
            case "person":
                return PERSON;
            case "company":
                return COMPANY;
            case "phones":
                return PHONE;
            case "emails":
                return EMAIL;
            default:
                return ADDRESSE;
        }
    }

    function createObjBackoffice(
        obj: KeyValue | KeyValue[],
        name: BackofficeType,
        removeKeys = [""]
    ) {
        let arrKey: [string, string | number][] = [];
        let arrKeyRemoved: [string, string | number][] = [];
        let values: { [k: string]: string | number } = {};

        if (!obj) return { arrKey, values, arrKeyRemoved };

        if (Array.isArray(obj)) {
            const key = obj.length > 0 ? obj.length - 1 : 0;
            const arrayObj = obj[key]
                ? obj[key]
                : (objBackoffice(name) as KeyValue);

            arrKey = Object.entries(arrayObj).filter(
                (item) => !removeKeys.includes(item[0])
            );

            const temp = Object.entries(arrayObj).filter((item) =>
                removeKeys.includes(item[0])
            );

            values = Object.fromEntries([...arrKey, ...temp]);

            arrKeyRemoved = Object.entries(arrayObj).filter((item) =>
                removeKeys.includes(item[0])
            );
        } else {
            arrKey = Object.entries(obj);
            arrKeyRemoved = [];
            values = Object.fromEntries(arrKey);
        }

        return { arrKey, values, arrKeyRemoved };
    }

    async function updatePerson(id: number, values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/person/${id}/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Pessoa atualizada com sucesso");
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao atualizar pessoa");
            console.log(error);
        }
    }
    async function updateAddresses(id: number, values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/address/${id}/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Endereço atualizado com sucesso");
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    async function createAddresses(values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/address/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Endereço criado com sucesso");
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    async function updateEmail(id: number, values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/email/${id}/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Email atualizado com sucesso");
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    async function createEmail(values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/email/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Email criado com sucesso");
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }
    async function updatePhone(id: number, values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/phone/${id}/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Telefone atualizado com sucesso");
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao atualizar telefone");
            console.log(error);
        }
    }

    async function createPhone(values: any) {
        try {
            setLoading(true);
            await api.post(`backoffice/phone/customer/${state.id}`, {
                ...values,
            });
            setLoading(false);
            toast.success("Telefone criado com sucesso");
        } catch (error) {
            setLoading(false);
            toast.error("Erro ao criar telefone");
            console.log(error);
        }
    }

    return (
        <Context.Provider
            value={{
                getBackoffice,
                hasCNPJ,
                createObjBackoffice,
                loading,
                state,
                cards,
                updatePerson,
                createAddresses,
                updateAddresses,
                createEmail,
                updateEmail,
                createPhone,
                updatePhone,
            }}
        >
            {children}
        </Context.Provider>
    );
}

export function useBackoffice() {
    const context = useContext(Context);
    return context;
}
