import { useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import { HistoryProps } from "../../../types";

import { api } from "../../../../../../services";
import { toast } from "react-toastify";
import { PaginationConfig } from "../../../../../../Hook/system/config";

interface useUserProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface useUserData {
    getUser(page?: number, search?: string): Promise<UserData>;
    exportar(): Promise<void>;
}

interface UserData extends PaginationConfig {
    data: User[];
}

export interface User {
    id: number;
    name: string;
    password: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    register_status: number;
    image: string | null;
    created_at: string | null;
    updated_at: string;
    campanha: string;
    total_creditos: string | null;
}

const UseUserContext = createContext<useUserData>({} as useUserData);

export function InfraProvider(props: useUserProviderProps) {
    const { children } = props;
    const history = useHistory<HistoryProps>();
    const id = history.location.state?.id;

    async function getUser(page?: number, search?: string) {
        const hasPage = page
            ? `?page=${page}&search=${encodeURI(search || "")}`
            : "";

        try {
            const { data } = await api.get<UserData>(
                `campanha/${id}/usuarios${hasPage}`
            );
            return data;
        } catch (error: any) {
            toast.error("Erro ao carregar usuários");
            return {} as UserData;
        }
    }

    async function exportar() {
        try {
            const outputFilename = `${Date.now()}.xlsx`;

            const response: any = await api.get(
                `campanha/${id}/usuarios-export`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <UseUserContext.Provider value={{ getUser, exportar }}>
            {children}
        </UseUserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UseUserContext);
    return context;
}
