import Autocomplete from "@mui/material/Autocomplete";

export interface options {
    label: string;
    value: number | string;
}

interface AutoCompleteProps {
    options: options[];
    onChange?: (value: options) => void;
    label: string;
}

export default function CustomInputAutocomplete(props: AutoCompleteProps) {
    const { options, onChange, label } = props;
    return (
        <label>
            <Autocomplete
                sx={{
                    display: "inline-block",
                    "& input": {
                        width: "100%",
                        bgcolor: "background.paper",
                        color: (theme) =>
                            theme.palette.getContrastText(
                                theme.palette.background.paper
                            ),
                    },
                }}
                onChange={(event, values) => onChange?.(values as options)}
                id="custom-input-demo"
                options={options}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderInput={(params) => (
                    <div ref={params.InputProps.ref} className="seta-baixo">
                        <input
                            type="text"
                            {...params.inputProps}
                            placeholder={label}
                        />
                    </div>
                )}
            />
        </label>
    );
}
