import styled from "styled-components";
import imgUser from "../../../../../../assets/img/user.svg";

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    max-height: 80vh;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(89, 92, 118, 0.5);
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .container-tabs {
        min-height: auto;
        width: auto;
    }

    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: 150px;
        img {
            position: absolute;
            top: -140px;
            left: 50%;
            transform: translateX(-50%);
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 105px;
        }
    }

    .container-input-system {
        &.cliente {
            &.select {
                &:before {
                    -webkit-mask: url(${imgUser}) no-repeat;
                }
            }
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 124px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        position: relative;
        .container-img {
            position: absolute;
            top: -155px;
            left: 50%;
            transform: translateX(-50%);
        }
        span {
            display: block;
            width: 254px;
            margin: 0 auto;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 22px;
        }
    }
`;

export const ConfirmaDadosStyles = styled.div`
    .container-content {
        .container-periodo {
            display: flex;
            margin-bottom: 18px;
            .container-range {
                p {
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 12px;
                }
                input {
                    width: 111px;
                    height: 48px;
                    background-color: #f0f0f0;
                    margin-right: 21px;
                    border: none;
                    font-size: 12px;
                    border-radius: 4px;

                    padding-left: 16px;
                }
            }
        }

        .container-resultado {
            padding: 20px 12px 38px;
            background-color: #efefef;
            border-radius: 12px;
            margin-bottom: 56px;
            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 120%;
                text-align: center;
                color: ${(props) => props.theme.colors.primaryDark};
                padding-bottom: 12px;
                margin-bottom: 18px;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #d9d9d9;
                    margin-bottom: 12px;
                    position: absolute;
                    bottom: -12px;
                    left: 0px;
                }
            }
            ul {
                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                    p {
                        font-weight: bold;
                        &:nth-child(2) {
                            margin-left: 8px;
                            margin-right: 8px;
                        }
                    }
                    .container-icon {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
`;
