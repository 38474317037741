import imgModalComprovante from "../../assets/img/comprovantePagmento.svg";
import { HeaderStyle } from "./styles";
export function Header() {
    return (
        <HeaderStyle>
                <img src={imgModalComprovante} alt="comprovante" />
                <h4>Comprovantes de Pagamento</h4>
        </HeaderStyle>
    );
}
