import { Flex } from "alisson-application";
import { Header } from "./Header";
import { Empty } from "../Empty";
import { Content } from "./content";
import { Container } from "./styles";
import { useBackoffice } from "../../../Hook/system/useBackoffice";
import { When } from "../../when";
import { Cards } from "./content/Cards";
import { Loader } from "../../Loader";

export function Backoffice() {
    const { state, loading } = useBackoffice();

    return (
        <Container>
            <Header />

            <When expr={!Boolean(state?.document)}>
                <Flex alignItems="center" justifyContent="center" mt="110px">
                    {loading ? (
                        <Loader height={100} width={100} />
                    ) : (
                        <Empty
                            title={
                                state?.document
                                    ? "Usuário não Encontrado"
                                    : "PREENCHA O CAMPO DE BUSCA PARA ENCONTRAR UM USUÁRIO"
                            }
                        />
                    )}
                </Flex>
            </When>
            <When expr={Boolean(state?.document)}>
                <>
                    <Content />
                    <Cards />
                </>
            </When>
        </Container>
    );
}
