import { useCallback, useEffect, useState } from "react";

import { Cliente, useCampanha } from "../../../../Hook/system/useCampanha";
import Select, { options } from "../../../NovoSelect";

interface SelectClienteProps {
    onChange?: (value: options) => void;
}

export function SelectCliente(props: SelectClienteProps) {
    const { onChange } = props;
    const [clientes, setClientes] = useState<options[]>([]);
    const { getAllClientes } = useCampanha();

    const get = useCallback(() => {
        async function cliente() {
            const response = await getAllClientes();
            if (response) {
                setClientes(
                    response.map((cliente: Cliente) => ({
                        label: `${cliente.nome} - ${cliente.cnpj}`,
                        value: cliente.id,
                    }))
                );
            }
        }

        cliente();
    }, [getAllClientes]);

    useEffect(() => {
        get();
    }, [get]);

    return (
        <Select
            tipo="cliente"
            label="CLIENTE"
            options={clientes}
            onChange={(values) => {
                if (onChange) {
                    onChange(values);
                }
            }}
        />
    );
}
