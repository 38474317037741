import { Container } from "./styles";
import ImgBloqueio from "../../assets/img/bloqueio.svg";
import ImgFaleConoscoBranco from "../../assets/img/fale-conosco-branco.svg";
import { Button } from "../Mobile/Button";
import { UseModal } from "../../Hook/Usemodal";
import { useHistory } from "react-router-dom";

export function AcessoBloqueado() {
    const { state, handleClose } = UseModal();
    const history = useHistory();
    const message = state.dados?.message;
    const layout = state.dados?.layout;

    return (
        <Container>
            <div className="container-img">
                <img src={ImgBloqueio} alt="" />
            </div>

            <div className="container-title">
                <h3>ops!</h3>
            </div>

            <div className="container-text">
                {layout === 2 ? (
                    <p>
                        Não conseguimos validar seus dados.
                        <br />
                        Entre em contato pelo Fale Conosco para que
                        <br />
                        possamos liberar seu acesso.
                    </p>
                ) : (
                    <p>
                        {/* <strong>
                        Você excedeu o número máximo de tentativas de login ou
                        identificamos alguma inconsistência. Por questões de
                        segurança, sua conta foi bloqueada.
                    </strong> */}
                        <strong>{message}</strong>
                        <br />
                        <br />
                        Clique no botão abaixo e envie uma mensagem para
                        recuperar seu acesso.
                    </p>
                )}
            </div>

            <div className="container-btns">
                <Button
                    onClick={() => {
                        handleClose("acessoBloqueado");
                        history.push("/precisa-ajuda");
                    }}
                >
                    <div className="container-icon">
                        <img src={ImgFaleConoscoBranco} alt="" />
                    </div>
                    <span>Fale conosco</span>
                </Button>
                <Button onClick={() => handleClose("acessoBloqueado")}>
                    <span>Voltar</span>
                </Button>
            </div>
        </Container>
    );
}
