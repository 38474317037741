import { NovaWhiteList } from "../../../../components/System/White-list/novo";
import { WhiteListProvider } from "../../../../Hook/system/useWhiteList";

export function PageNovaWhiteList() {
    return (
        <WhiteListProvider>
            <NovaWhiteList />
        </WhiteListProvider>
    );
}
