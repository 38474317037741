import { Text } from "alisson-application";
import { useTranslation } from "../../../contexts/Localization";
import { CardBack } from "../CardBack";
import { FormAtivarCartao } from "./form";

export function Ativar() {
    const { t } = useTranslation();

    return (
        <>
            <div className="container">
                <CardBack noname={true} solicitar=" " />
                <Text as="p">
                    {t("Preencha os campos abaixo para ativar seu cartão.")}
                </Text>
            </div>

            <FormAtivarCartao />
        </>
    );
}
