import { Header } from "./header";
import { SubHeader } from "./subHeader";
import Table from "../Table";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useLogCargaDeCredito } from "../../../Hook/system/useLogCargaDeCredito";
import ModalAprove from "../../../components/System/ModalAlert";
import imgLixeira from "../../../assets/img/x-vermelho.svg";
import { useState } from "react";
import { formatCurrentMoney } from "../../../util/helper";

interface Data {
    id: number;
    documento: string;
    nome: string;
    valor: string;
    status: string;
    restante: string;
}

export function LogCargaDetalheCredito() {
    const { carga, deleteCarga } = useLogCargaDeCredito();
    const [id, setId] = useState<number | null>(null);
    const [modalExcluir, setModalExcluir] = useState(false);

    const rows: Data[] = carga?.creditos?.map((credito) => {
        return {
            id: credito.id,
            documento: credito.user.document,
            nome: credito.user.name,
            valor: `R$ ${formatCurrentMoney(parseFloat(credito.valor))}`,
            restante: `R$ ${formatCurrentMoney(parseFloat(credito.restante))}`,
            status: credito.status + "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
        },
        {
            field: "documento",
            headerName: "DOCUMENTO",
        },
        {
            field: "nome",
            headerName: "NOME",
        },
        {
            field: "valor",
            headerName: "VALOR",
        },
        {
            field: "restante",
            headerName: "RESTANTE",
        },
        {
            field: "status",
            headerName: "",
            width: 50,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() => {
                            setId(row.id);
                            setModalExcluir(true);
                        }}
                        className={`${
                            row.status === "ATIVO" ? "" : "SUSPENSA"
                        }`}
                    >
                        <img src={imgLixeira} alt="lixeira" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            <Header />
            <SubHeader
                created_at={carga?.created_at}
                nome={carga?.nome}
                nome_campanha=""
                status=""
                tipo=""
            />
            <Table rows={rows ? rows : []} columns={collumns} nameCSV="carga" />
            <ModalAprove
                handleClose={() => setModalExcluir(false)}
                reject={() => setModalExcluir(false)}
                aceept={async () => {
                    if (id) {
                        await deleteCarga(id);
                        setModalExcluir(false);
                        return;
                    }
                }}
                title="Excluir"
                description="Está ação é irreversível"
                open={modalExcluir}
            />
        </>
    );
}
