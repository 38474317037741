import styled from "styled-components";

export const Container = styled.div`
    background-color: ${(props) => props.theme.colors.backgroundAlt};
    padding: 23px 19px 19px 19px;
    border-radius: 16px;
    margin-bottom: 8px !important;
    text-transform: uppercase;
    .container-operacao {
        padding-top: 16px;
        &:first-child {
            padding-top: 0px;
        }
        &:last-child {
            .tipo-op {
                border-bottom: unset;
            }
        }

        .tipo-op {
            padding-bottom: 16px;
            border-bottom: 1px solid #e4e4e4;
            padding-left: 28px;
            position: relative;
            width: 100%;

            &.reversed {
                p,
                span,
                small,
                h3 {
                    text-decoration: line-through;
                }
            }

            small {
                width: 100%;
            }

            h3 {
                font-size: 14px;
            }

            &.credito {
                h3 {
                    color: ${(props) => props.theme.colors.secondary};
                    white-space: nowrap;
                }
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 12px solid
                        ${(props) => props.theme.colors.secondary};
                    position: absolute;
                    top: 6px;
                    left: 0px;
                }
            }
            &.debito {
                h3 {
                    color: ${(props) => props.theme.colors.warning};
                    white-space: nowrap;
                }
                &:before {
                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 12px solid
                        ${(props) => props.theme.colors.warning};
                    position: absolute;
                    top: 6px;
                    left: 0px;
                }
            }
        }

        .item-descricao {
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
