import React, { ButtonHTMLAttributes } from "react";

import { ButtonContainer } from "./styles";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    theme?: "primary" | "secondary";
    medium?: boolean;
}

export const Button = ({
    children,
    theme = "primary",
    medium,
    ...rest
}: IButton) => {
    return (
        <ButtonContainer $theme={theme} medium={medium} {...rest}>
            {children}
        </ButtonContainer>
    );
};
