import { ModalConfig } from "../config";
import { PageCartaoAvulso } from "../../../pages/CartaoAvulso";
import Modal from "../../NovoModal";

export function ModalCartaoAvulso(props: ModalConfig) {
    const { open, handleClose } = props;

    return (
        <Modal open={open} handleClose={handleClose}>
            <PageCartaoAvulso />
        </Modal>
    );
}
