import { useLogin } from "../../../../../../Hook/system/useLogin";
import { CircleCheck } from "../../../../../../assets/img/CircleCheck";
import { Button } from "../../components/Button";

import { SuccessContainer } from "./styles";

const SuccessScreen = () => {
    const { setAuthenticator, setModalConfirm } = useLogin();

    function navigateToAuthenticator() {
        setAuthenticator(true);
        setModalConfirm(false);
    }

    return (
        <SuccessContainer>
            <CircleCheck />
            <h1>Senha alterada com sucesso!</h1>
            <Button onClick={navigateToAuthenticator}>Fazer Login</Button>
        </SuccessContainer>
    );
};

export default SuccessScreen;
