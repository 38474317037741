import { ErrorMessageContainer } from "./styles";

interface IErrorMessage {
  children: string | undefined;
}

const ErrorMessage = ({ children }: IErrorMessage) => {
  return (
    <ErrorMessageContainer id={"error-message"}>
      {children}
    </ErrorMessageContainer>
  );
};

export default ErrorMessage;
