import * as Yup from "yup";

export interface Myform {
    titulo: string;
    descricao: string;
    file: string;
    tipo: string;
    send_notification_users: number;
}
export const initalValues: Myform = {
    titulo: "",
    descricao: "",
    file: "",
    tipo: "",
    send_notification_users: 0,
};

export const validadeForm = Yup.object().shape({
    titulo: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),
    file: Yup.string().required("Campo Obrigatório"),
    send_notification_users: Yup.number(),
});
