import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GridRenderCellParams, GridColumns } from "@mui/x-data-grid-pro";

import { useUser, Roles } from "../../../../../Hook/system/useUser";
import { formatDateIsoSystem } from "../../../../../util/helper";

import { Header } from "./header";
import Table from "../../../Table";
import { Button } from "../../../Button/status";

import { Data } from "./config";
import imgEditar from "../../../../../assets/img/editar.svg";

export function Permissao() {
    const width = 1645 / 4;
    const history = useHistory();
    const { getRoles } = useUser();
    const [roles, setRoles] = useState<Roles[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await getRoles();
            setRoles(response);
        }

        fetchData();
    }, [getRoles]);

    const rows: Data[] = roles.map((item) => {
        return {
            id: item.id + "",
            nome: item.name,
            data_cadastro: formatDateIsoSystem(item.created_at),
            status: "falta retorna",
            editar: "",
        };
    });

    const collumns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
        },
        {
            field: "nome",
            headerName: "NOME",
            width,
        },
        {
            field: "data_cadastro",
            headerName: "DATA DE CADASTRO",
            width,
        },
        {
            field: "status",
            headerName: "STATUS",
            width,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Button
                        className={`${
                            row.status === "ATIVO" ? "" : "SUSPENSA"
                        }`}
                    >
                        {row.status}
                    </Button>
                );
            },
        },
        {
            field: "editar",
            headerName: "EDITAR",
            width: 100,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <button
                        onClick={() =>
                            history.push({
                                pathname: `/sistema/administracao/permissao/editar/${row.id}`,
                                state: {
                                    id: row.id,
                                },
                            })
                        }
                    >
                        <img src={imgEditar} alt="" />
                    </button>
                );
            },
        },
    ];

    return (
        <>
            <Header />
            <Table
                columns={collumns}
                rows={rows ? rows : []}
                nameCSV="PERFIL USUÁRIO"
            />
        </>
    );
}
