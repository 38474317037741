import { Container } from "./styles";
import imgCheck from "../../../assets/img/simple-check.svg";

interface BlockStatusProps {
    title: string;
    value: string;
    check?: boolean;
}

export function BlockStatus(props: BlockStatusProps) {
    const { title, value, check } = props;

    return (
        <Container>
            {check && <img src={imgCheck} alt="" />}

            <p>{title}</p>
            <strong>{value}</strong>
        </Container>
    );
}
