import * as Yup from "yup";
// eslint-disable-next-line no-useless-escape
const regexCPF = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/;

export interface Myform {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    phone_number: string;
    role: string;
}
export const initalValues: Myform = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone_number: "",
    role: "",
};

export const validadeForm = Yup.object().shape({
    name: Yup.string().required("Campo Obrigatório"),
    email: Yup.string()
        .required("Campo Obrigatório")
        .matches(regexCPF, "CPF inválido"),
    password: Yup.string()
        .required("Campo Obrigatório")
        .min(8, "Mínimo de 8 caracteres"),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Senha Não Confere"
    ),
    phone_number: Yup.string().required("Campo Obrigatório"),
    role: Yup.string().required("Campo Obrigatório"),
});
