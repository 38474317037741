import imgDownload from "../../../assets/img/dowload.svg";
import { api } from "../../../services";

interface BtnDownloadProps {
    id: number;
}

export function BtnDownload(props: BtnDownloadProps) {
    async function downloadFile() {
        try {
            const response: any = await api.get(`/file/${props.id}`);
            window.open(response.data.path);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <button onClick={() => downloadFile()}>
            <img src={imgDownload} alt="" />
        </button>
    );
}
