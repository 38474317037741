import { ModalConfig } from "../config";
import ModalBottom from "../../ModalBottom";
import { AtivarCartaoAvulso } from "../../../components/Mobile/AtivarCartaoAvulso";
import { useStateUser } from "../../../Hook/user/UseStateUser";

export function ModalAtivarCartaoAvulso(props: ModalConfig) {
    const { open, handleClose } = props;
    const { forceActivateAccountLogin } = useStateUser();

    return (
        <ModalBottom
            open={open}
            handleClose={() => {
                if (forceActivateAccountLogin) {
                    return;
                } else {
                    handleClose();
                }
            }}
        >
            <AtivarCartaoAvulso />
        </ModalBottom>
    );
}
