import React, { createContext, useContext, useState } from "react";
import { Myform } from "../../components/Perfil/config";
import { convertDateDB } from "../../util/helper";
import { api } from "../../services";
import { toast } from "react-toastify";

interface UsePerfilProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UsePerfil {
    formatValues(values: Myform): Myform;

    dataSemsible: boolean;
    setDataSensible: (value: boolean) => void;

    values: Myform;

    generateCode(number: string): Promise<any>;
}

const UsePerfilContext = createContext<UsePerfil>({} as UsePerfil);

export function UseStateProvider(props: UsePerfilProps) {
    const { children } = props;
    const [dataSemsible, setDataSensible] = useState(false);
    const [values, setValues] = useState<Myform>({} as Myform);

    function formatValues(values: Myform): Myform {
        if (values?.phone_number) {
            values = {
                ...values,
                phone_number: `55${values?.phone_number?.replace(/\D/g, "")}`,
            };
        }
        if (values?.data_nascimento) {
            values = {
                ...values,
                data_nascimento: convertDateDB(values?.data_nascimento) || "",
            };
        }
        if (values?.document) {
            values = {
                ...values,
                document: values.document.replace(/\D/g, ""),
            };
        }
        setValues(values);
        return values;
    }

    async function generateCode(number: string) {
        try {
            await api.post(`usuario/generate-code`, {
                type: "whatsapp",
                phone_number: number,
            });
            toast.success("Código enviado com sucesso");
        } catch (error: any) {
            toast.error("Erro ao gerar código");
            setDataSensible(false);
            return error?.response;
        }
    }

    return (
        <UsePerfilContext.Provider
            value={{
                formatValues,
                dataSemsible,
                setDataSensible,
                values,
                generateCode,
            }}
        >
            {children}
        </UsePerfilContext.Provider>
    );
}

export function usePerfil() {
    const context = useContext(UsePerfilContext);
    return context;
}
