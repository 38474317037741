import styled from "styled-components";

export const Container = styled.div`
    padding: 0 5%;
    .container-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;
    }

    .container-text {
        text-align: center;
        margin-bottom: 36px;
        p {
            font-size: 20px;
            font-weight: 600;
        }
    }

    button {
        border-radius: 600px;
    }
`;
