import { Text, Box } from "alisson-application";

import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { ModalFormProps, initialValues, validation } from "./config";

import { useContex } from "../Hook/modal";

import { Button } from "../../../../Button";
import imgCargaEstorno from "../../../../../../assets/img/estorno-cadastro.svg";
import { DataRanger } from "../../../../DateRange";

interface FormEstornoProps {
    handlePassos: (passo: number) => void;
}

export type keyOfCarga<T> = keyof T;

export function FormEstorno(props: FormEstornoProps) {
    const { handlePassos } = props;
    const { setFormStateEstorno, getDetailEstorno } = useContex();

    return (
        <Formik
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={async (values, actions) => {
                const response = await getDetailEstorno([
                    values.start_date_time,
                    values.end_date_time,
                ]);
                if (response.usuarios > 0) {
                    setFormStateEstorno(values);
                    handlePassos(2);
                } else {
                    actions.setFieldError(
                        "start_date_time",
                        "Não há registros para o período selecionado"
                    );
                }
            }}
        >
            {(props: FormikProps<ModalFormProps>) => {
                const { setFieldValue } = props;
                return (
                    <Form>
                        <div className="container-content">
                            <div className="container-img">
                                <img src={imgCargaEstorno} alt="" />
                            </div>

                            <Text as="span">
                                PREENCHA OS CAMPOS ABAIXO PARA REALIZAR O
                                ESTORNO POR INATIVIDADE.
                            </Text>
                        </div>

                        <div className="container-input-system estorno">
                            <Field
                                type="text"
                                placeholder="TÍTULO DO ESTORNO"
                                name="nome"
                            />
                        </div>
                        <ErrorMessage component="span" name="nome" />

                        <Text as="p" fontSize="xss" mt="43px" mb="24px">
                            PERÍODO DO ESTORNO
                        </Text>

                        <DataRanger
                            onChange={(dates) => {
                                setFieldValue("start_date_time", dates[0]);
                                setFieldValue("end_date_time", dates[1]);
                            }}
                        />

                        <ErrorMessage component="span" name="start_date_time" />

                        <Box mt="75px">
                            <Button
                                isLoading={props.isSubmitting}
                                spiner="BallTriangle"
                                spinerColor="primary"
                                disabled={!props.isValid}
                                type="submit"
                            >
                                Avançar
                            </Button>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
}
