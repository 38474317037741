import styled from "styled-components";
import { Container as Empty } from "../../../../Empty/styles";

export const Container = styled.div`
    button[aria-label="Export"] {
        display: none;
    }

    ${Empty} {
        margin: 0 auto;
        background: #e0e8df;
    }
`;
