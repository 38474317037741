import styled from "styled-components";

export const Container = styled.div`
    height: 59px;
    border-bottom: 1px solid #e1e2e3;
    button {
        position: relative;
        text-align: center;
        height: 100%;
        width: 100%;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        /* identical to box height, or 14px */

        text-transform: none;

        /* Gray/01 */

        font-family: "Oxanium";
        color: ${(props) => props.theme.colors.black};

        img {
            position: absolute;
            top: 50%;
            left: 0px;
            transform: translateY(-50%);
        }
    }
`;
