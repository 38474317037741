import { AcessoBloqueado } from "../../components/AcessoBloqueado";
import { AcessoBloqueadoIdWall } from "../../components/AcessoBloqueadoIdWall";
import { DocumentoEmAnaliseIdWall } from "../../components/DocumentoEmAnaliseIdWall";
import { FaleConosco } from "../../components/FaleConosco/UsuarioDeslogado";
import { DocumentoEnviado } from "../../components/Mobile/DocumentoEnviado";
import { EsqueciSenha } from "../../components/Mobile/EsqueciSenha";
import { Logindesktop } from "../../components/Mobile/LoginDesktop";
import { VerificacaoDocumento } from "../../components/Mobile/VerificacaoDocumento";
import { ModalAvisoSenhaInvalida } from "../../components/Modais";
import ModalDocumento from "../../components/ModalBottom";
import ModalBottom from "../../components/ModalBottom/bottomScroll";
import Modal from "../../components/NovoModal";
import ModalMobile from "../../components/NovoModal/mobile";
import { ResetarSenha } from "../../components/ResetarSenha";
import { Footer } from "../../components/System/Footer";
import { ValidandoDadosIdWall } from "../../components/ValidandoDadosIdWall";
import { VerificacaoDocumentoIdWall } from "../../components/VerificacaoDocumentoIdWall";
import { UseModal } from "../../Hook/Usemodal";
import { UseFormLoginProvider } from "../../Hook/user/useFormLogin";
import { PageIdWall } from "../../pages/IdWall";
import { TempoExpirado } from "../../components/TempoExpirado";

export function PageLogin() {
    const width = window.screen.width;
    const { handleClose, state } = UseModal();

    return (
        <UseFormLoginProvider>
            <Logindesktop />
            {width > 768 ? <Footer /> : <></>}

            {state?.esqueciSenha ? (
                <Modal
                    open={state?.esqueciSenha}
                    handleClose={() => handleClose("esqueciSenha")}
                >
                    <EsqueciSenha />
                </Modal>
            ) : (
                <></>
            )}

            {state.avisoSenhaInvalida ? (
                <ModalAvisoSenhaInvalida
                    open={!!state?.avisoSenhaInvalida}
                    handleClose={() => handleClose("avisoSenhaInvalida")}
                />
            ) : (
                <></>
            )}

            <Modal
                open={state?.faleConoscoUsuarioDeslogado}
                handleClose={() => handleClose("faleConoscoUsuarioDeslogado")}
            >
                <FaleConosco />
            </Modal>

            {state?.resetarSenha ? (
                <ModalBottom
                    open={state?.resetarSenha}
                    handleClose={() => handleClose("resetarSenha")}
                >
                    <ResetarSenha />
                </ModalBottom>
            ) : (
                <></>
            )}

            <ModalBottom
                open={state.acessoBloqueado}
                handleClose={() => handleClose("acessoBloqueado")}
            >
                <AcessoBloqueado />
            </ModalBottom>

            {state?.idWall ? (
                <Modal open={state?.idWall} handleClose={() => {}}>
                    <PageIdWall />
                </Modal>
            ) : (
                <></>
            )}

            {state?.verificacaoDocument ? (
                <ModalDocumento
                    open={state?.verificacaoDocument}
                    handleClose={() => {}}
                >
                    <VerificacaoDocumento />
                </ModalDocumento>
            ) : (
                <></>
            )}

            {state?.sucessoVerificacaoDocumento ? (
                <ModalDocumento
                    open={state?.sucessoVerificacaoDocumento}
                    handleClose={() => {}}
                >
                    <DocumentoEnviado />
                </ModalDocumento>
            ) : (
                <></>
            )}

            {state?.acessoBloqueadoIdWall ? (
                <ModalMobile
                    open={state?.acessoBloqueadoIdWall}
                    handleClose={() => handleClose("acessoBloqueadoIdWall")}
                >
                    <AcessoBloqueadoIdWall />
                </ModalMobile>
            ) : (
                <></>
            )}

            {state?.verificacaoDocumentoIdWall ? (
                <ModalMobile
                    open={state?.verificacaoDocumentoIdWall}
                    handleClose={() => {}}
                >
                    <VerificacaoDocumentoIdWall />
                </ModalMobile>
            ) : (
                <></>
            )}

            {state?.validandoDadosIdWall ? (
                <ModalMobile
                    open={state?.validandoDadosIdWall}
                    handleClose={() => handleClose("validandoDadosIdWall")}
                >
                    <ValidandoDadosIdWall />
                </ModalMobile>
            ) : (
                <></>
            )}

            {state?.documentoEmAnaliseIdWall ? (
                <ModalMobile
                    open={state?.documentoEmAnaliseIdWall}
                    handleClose={() => handleClose("documentoEmAnaliseIdWall")}
                >
                    <DocumentoEmAnaliseIdWall />
                </ModalMobile>
            ) : (
                <></>
            )}

            {state?.tempoExpirado ? (
                <ModalMobile open={state?.tempoExpirado} handleClose={() => {}}>
                    <TempoExpirado />
                </ModalMobile>
            ) : (
                <></>
            )}
        </UseFormLoginProvider>
    );
}
