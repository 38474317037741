import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Flex, Text } from "alisson-application";
import { useCallback, useEffect, useRef, useState } from "react";

import imgDownload from "../../../assets/img/dowload.svg";
import imgSetaBaixo from "../../../assets/img/seta-baixo.svg";
import imgUser from "../../../assets/img/user.png";
import {
    DataFaleConosco,
    FaleConosco as IFaleConosco,
    useComunicacao,
} from "../../../Hook/system/useComunicacao";
import {
    formataCPF,
    formatDateIsoSystem,
    getLocalStore,
    phoneNumber,
    validateEmail,
} from "../../../util/helper";

import { Loader } from "../../Loader";
import { Header } from "../Header";
import { Status } from "../Status";
import Table, { Pagination } from "../Table";
import { Descricao } from "./descricao";
import { Container } from "./styles";

type DataFaleConoscoPick = Omit<DataFaleConosco, "data">;

export function FaleConosco() {
    const { getFaleConosco, updateFaleConosco } = useComunicacao();
    const [faleConosco, setFaleConosco] = useState<IFaleConosco[]>([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState<DataFaleConoscoPick>(
        {} as DataFaleConoscoPick
    );

    const ref = useRef<HTMLButtonElement[]>([]);
    const width = 1645 / 10;

    const columns: GridColumns = [
        {
            field: "id",
            headerName: "ID",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.id}</Text>;
            },
            width: 10,
        },
        {
            field: "nome",
            headerName: "NOME",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <Text className="user">
                        <img src={imgUser} alt="" />
                        {row.nome}
                    </Text>
                );
            },
            width: 220,
        },
        {
            field: "email",
            headerName: "E-MAIL2",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text className="user">{row.email}</Text>;
            },
            width: 220,
        },

        {
            field: "telefone",
            headerName: "TELEFONE",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.telefone}</Text>;
            },
            width: width,
        },
        {
            field: "document",
            headerName: "CPF",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.document}</Text>;
            },
            width: width,
        },
        {
            field: "data_hora",
            headerName: "DATA / HORA",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.data_hora}</Text>;
            },
            width: 200,
        },
        {
            field: "campanha",
            headerName: "CAMPANHA",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.campanha}</Text>;
            },
            width: width,
        },
        {
            field: "subject",
            headerName: "ASSUNTO",
            renderCell: ({ row }: GridRenderCellParams) => {
                return <Text>{row.subject}</Text>;
            },
            width: 200,
        },
        {
            field: "status",
            headerName: "STATUS",
            renderCell: ({ row }: GridRenderCellParams) => {
                if (getLocalStore().permissions["helpdesk.update"]) {
                    return (
                        <Status
                            label={row.status}
                            handleClick={async () =>
                                updateStatus(row.id, row.status)
                            }
                            active={row.status === "RESOLVIDO" ? true : false}
                        />
                    );
                }

                return <></>;
            },
            width: width,
        },
        {
            field: "saiba_mais",
            headerName: "SAIBA MAIS",
            renderCell: ({ row }: GridRenderCellParams) => {
                const btn = ref.current[row.id] as HTMLButtonElement;
                function handleClickDownload() {
                    window.open(row.file, "_blank");
                }
                function handleClick() {
                    if (btn) {
                        const target = btn.parentElement?.parentElement
                            ?.parentElement as HTMLDivElement;

                        const texto = target?.querySelector(
                            "div:nth-child(11)"
                        ) as HTMLDivElement;

                        const height = texto?.offsetHeight + 80;

                        if (target.offsetHeight === 52) {
                            target.style.height = `${height}px`;
                            target.style.minHeight = `${height}px`;
                            target.style.maxHeight = `${height}px`;
                            btn.classList.add("active");
                        } else {
                            target.style.height = "52px";
                            target.style.minHeight = "52px";
                            target.style.maxHeight = "52px";
                            btn.classList.remove("active");
                        }
                    }
                }

                return (
                    <Flex width={100} justifyContent="space-between">
                        <button
                            onClick={() => handleClick()}
                            ref={(el: HTMLButtonElement) => {
                                ref.current[row.id] = el;
                            }}
                        >
                            <img src={imgSetaBaixo} alt="" />
                        </button>
                        {row.file ? (
                            <button onClick={handleClickDownload}>
                                <img src={imgDownload} alt="" />
                            </button>
                        ) : (
                            <></>
                        )}
                    </Flex>
                );
            },
            width: 100,
        },
        {
            field: "texto",
            headerName: "",
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <>
                        <Descricao descricao={row.descricao} />
                    </>
                );
            },
            width: 0,
        },
    ];

    const rows = faleConosco?.map(({ email, ...item }) => {
        return {
            id: item?.id,
            nome: item?.user?.name ? item?.user?.name : item.name,
            document: item?.user?.document
                ? formataCPF(item.user.document)
                : "",
            telefone: item?.user?.phone_number
                ? phoneNumber(item.user.phone_number)
                : phoneNumber(item.phone ? item.phone : ""),
            data_hora: item?.created_at
                ? formatDateIsoSystem(item.created_at)
                : "",
            email: validateEmail(email || "")
                ? email
                : validateEmail(item?.user?.email)
                ? item?.user?.email
                : item?.user?.pessoa?.email || "",
            campanha: item?.campanha_m?.chave
                ? item?.campanha_m?.chave
                : item.campanha,
            descricao: item?.description,
            subject: item?.subject,
            status: item?.status,
            saiba_mais: "",
            file: item?.file,
        };
    });

    async function updateStatus(id: number, status: string) {
        const response = await updateFaleConosco(id, {
            status: status === "RESOLVIDO" ? "AGUARDANDO" : "RESOLVIDO",
        });

        if (response.status === 200) {
            if (page) {
                await pagination(page);
            } else {
                await fetchData();
            }
        }
    }

    async function pagination(page: number) {
        setLoading(true);
        try {
            const response = await getFaleConosco(page);
            const { data, ...resto } = response;

            setConfig(resto);
            setFaleConosco(response.data);
        } catch (error) {
            console.log(error);
            setFaleConosco([]);
            setConfig({} as DataFaleConoscoPick);
        }
        setLoading(false);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await getFaleConosco();
            const { data, ...resto } = response;

            setConfig(resto);
            setFaleConosco(response.data);
        } catch (error) {
            console.log(error);
            setFaleConosco([]);
            setConfig({} as DataFaleConoscoPick);
        }
        setLoading(false);
    }, [getFaleConosco]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    console.log({ columns });

    return (
        <Container>
            <Header title="FALE CONOSCO" />
            <div
                className={`container-loading-fale-conosco ${
                    loading ? "active" : ""
                }`}
            >
                {loading ? (
                    <div className="loader-fale-conosco">
                        <Loader width={100} />
                    </div>
                ) : null}

                <Table
                    columns={columns}
                    rows={rows ? rows : []}
                    nameCSV="FALE-CONOSCO"
                />
            </div>
            {config?.last_page > 1 ? (
                <Pagination
                    total={config?.last_page}
                    currentPage={(page) => {
                        pagination(page);
                        setPage(page);
                    }}
                />
            ) : null}
        </Container>
    );
}
