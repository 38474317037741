import {
    useContext,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { Myform } from "../../components/System/Faq/novo/config";
import { PaginationConfig } from "./config";

interface useFaqProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

interface IuseFaq {
    faq: Faq;
    getFaq: (page?: number, search?: string) => Promise<DataFaq>;

    store(values: Myform): Promise<any>;
    update(values: Myform): Promise<any>;
    deleteFaq(id: number): Promise<any>;
    loading: boolean;
}

export interface Faq {
    id: number;
    title: string;
    description: string;
    created_at: null | string;
    updated_at: null | string;
}

interface DataFaq extends PaginationConfig {
    data: Faq[];
}

const UseFaqContext = createContext<IuseFaq>({} as IuseFaq);

export function FaqProvider(props: useFaqProps) {
    const { children } = props;

    const [faq, setFaq] = useState<Faq>({} as Faq);
    const [loading, setLoading] = useState(false);

    const history = useHistory<{ id: number }>();
    const id = history.location?.state?.id;

    const getFaq = useCallback(async (page?: number, search?: string) => {
        const hasPage = page
            ? `?page=${page}&search=${encodeURI(search || "")}`
            : "";

        try {
            const response = await api.get<DataFaq>(`faq${hasPage}`);
            return response.data;
        } catch (error) {
            toast.error("Erro ao carregar Faq");
            return {} as DataFaq;
        }
    }, []);

    const swhoFaq = useCallback(async () => {
        setLoading(true);
        try {
            const response = await api.get<Faq>(`faq/${id}`);
            setFaq(response.data);
            setLoading(false);
        } catch (error) {
            toast.error("Erro ao carregar Faq");
            setFaq({} as Faq);
            setLoading(false);
        }
    }, [id]);

    async function store(values: Myform) {
        try {
            const response = await api.post(`faq`, {
                ...values,
            });

            toast.success("Sucesso");
            history.goBack();
            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            return error.response;
        }
    }
    async function update(values: Myform) {
        try {
            const response = await api.post(`faq/${id}`, {
                ...values,
            });

            toast.success("Sucesso");
            history.goBack();
            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            return error.response;
        }
    }
    async function deleteFaq(id: number) {
        try {
            const response = await api.delete(`faq/${id}`);
            toast.success("Sucesso");
            return response;
        } catch (error: any) {
            toast.error("Ops algo de errado aconteceu! tente novamente");
            return error.response;
        }
    }

    useEffect(() => {
        if (id) {
            swhoFaq();
        }
    }, [getFaq, id, swhoFaq]);

    return (
        <UseFaqContext.Provider
            value={{ faq, getFaq, store, update, loading, deleteFaq }}
        >
            {children}
        </UseFaqContext.Provider>
    );
}

export function useFaq() {
    const context = useContext(UseFaqContext);
    return context;
}
