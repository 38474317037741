import { RelatorioDetalhe } from "../../../../components/System/Relatorio/detalhe";
import { RelatorioProvider } from "../../../../Hook/system/useRelatorio";

export function PageDetalheRelatorio() {
    return (
        <RelatorioProvider>
            <RelatorioDetalhe />
        </RelatorioProvider>
    );
}
