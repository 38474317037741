import { Text } from "alisson-application";
import { useHistory } from "react-router-dom";
import { UseModal } from "../../../Hook/Usemodal";
import { usePassos } from "../../../Hook/UsePassos";
import { useTranslation } from "../../../contexts/Localization";
import { Button } from "../ButtonSlim";
import { arrFisico } from "../SolicitarCartao";
import imgCartao from "../../../assets/img/solicitar-cartao.svg";
import { FinalizarStyles } from "./styles";

export function Finalizar() {
    const history = useHistory();
    const { t } = useTranslation();
    const { setState, state } = UseModal();
    const { setPassos } = usePassos();
    const width = window.screen.width;

    return (
        <FinalizarStyles flexDirection="column" className="container">
            <img src={imgCartao} alt="Solicitar Cartão" />

            <Text as="h3" fontWeight={600}>
                {t("Cartão Solicitado com Sucesso!")}
            </Text>

            <Text as="p">
                {/* Seu novo cartão está em processo de aprovação e será liberado em breve. O prazo de entrega é de até 10 dias úteis. */}
                Seu cartão está em processo de aprovação e será liberado para <strong>inserir crédito</strong>.
            </Text>

            <Button
                onClick={() => {
                    if (width <= 480) {
                        history.goBack();
                    } else {
                        setState({ ...state, novoCartao: false });
                    }

                    setPassos(arrFisico);
                }}
            >
                Ok, {t("entendi")}
            </Button>
        </FinalizarStyles>
    );
}
