import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    .limit {
        width: 455px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${({ theme }) => theme.colors.primary};
            padding-bottom: 25px;
            margin-bottom: 25px;
            border-bottom: 1px solid #e1e2e3;
        }
    }
`;
