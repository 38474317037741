import { HeaderNovo } from "../../Header/novo";
import { FormCampanha } from "./form";

export function NovaCampanha() {
    return (
        <>
            <HeaderNovo title="NOVA CAMPANHA" />
            <FormCampanha />
        </>
    );
}
