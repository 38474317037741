import { Box } from "alisson-application";
import imgSetaCima from "../../../assets/img/upload-seta-cima.svg";
import { Container } from "./styles";

interface BackgroundUploadProps {
    width: number;
    title: string;
    subTitle: string;
    preview?: string;
}

export function BackgroundUpload(props: BackgroundUploadProps) {
    const { subTitle, title, width, preview } = props;
    return (
        <Container>
            <Box
                width={width}
                className={`${
                    preview
                        ? "container-background preview"
                        : "container-background"
                }`}
            >
                <img src={preview ? preview : imgSetaCima} alt="" />
            </Box>
            <p>{title}</p>
            <p>{subTitle}</p>
        </Container>
    );
}
