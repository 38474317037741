import styled from "styled-components";

export const Container = styled.div`
    display: grid;
    grid-template-columns: 308px 1110px;
    grid-gap: 0px 192px;
`;

export const ContainerEsq = styled.div`
    width: 308px;
    position: relative;
    .header-go-back {
        height: 111px;
        display: flex;
        align-items: center;
        justify-content: start;
        border-bottom: 1px solid #e1e2e3;
        button {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.secondary};
            img {
                margin-right: 12px;
            }
        }
    }

    .container-content {
        margin-bottom: 24px;
        padding-top: 49px;
        h4 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.08em;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 16px;
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 28px;
        }

        .container-img {
            background: #ededed;
            border-radius: 8px;
            height: 185px;
            width: 100%;
            margin-bottom: 32px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }

        .container-slider {
            pointer-events: none;
            .count {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }

    .container-footer {
        .detalhe {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            .card {
                background: ${({ theme }) => theme.colors.backgroundAlt};
                border-radius: 4px;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 14px;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &.btns {
                justify-content: space-between;
                margin-top: 20px;
                .btnSegmentacao {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    color: ${(props) => props.theme.colors.secondary};
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 10px;
                    }
                }
            }
        }

        .text {
            p {
                white-space: nowrap;
            }
        }
    }
`;

export const ContainerDir = styled.div`
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 2px;
        min-height: 100vh;
        height: calc(100% + 3rem);
        background-color: #e6e6e6;
        position: absolute;
        top: 0;
        left: -96px;
    }

    .container-loading {
        height: calc(100vh - 111px - 32px - 3rem);
        position: relative;
    }

    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${(props) => props.theme.colors.primary};
        display: flex;
        align-items: center;
        height: 111px;
        img {
            margin-right: 12px;
        }
    }

    & > div {
        padding-top: 0px;
        height: auto;
    }
`;

export const ModalStyles = styled.div`
    padding: 34px 24px 47px 24px;
    background-color: ${(props) => props.theme.colors.white};
    width: 375px;
    .container-sucess {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 166px;
        }
        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.mdl};
            line-height: 120%;
            text-align: center;
            color: ${(props) => props.theme.colors.primary};
            margin-bottom: 34px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 140%;
            text-align: center;
            color: ${(props) => props.theme.colors.primaryDark};
            margin-bottom: 105px;
        }
    }

    .container-header {
        padding-bottom: 22px;
        margin-bottom: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e1e2e3;
        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .container-content {
        width: 100%;
        span {
            font-style: normal;
            font-weight: 400;
            font-size: ${(props) => props.theme.fonts.sm};
            line-height: 140%;
            text-align: left;
            color: ${(props) => props.theme.colors.primaryDark};
            width: 100%;
            display: block;
            margin-bottom: 18px;
        }
    }
`;
