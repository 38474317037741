import * as Yup from "yup";
const regexCPFouCNPJ =
    /^([0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2})|([0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2})$/;

export interface Documento {
    document: string;
}

export const documento: Documento = {
    document: "",
};

export const validationDocumento = Yup.object().shape({
    document: Yup.string()
        .matches(regexCPFouCNPJ, "Digite um CPF/CNPJ valido")
        .required("Campo obrigatório"),
});

export interface ConfirmaUser {
    valor: string;
    campanha: string;
}

export const confirmaUser: ConfirmaUser = {
    valor: "",
    campanha: "",
};

export const validationConfirmaUser = Yup.object().shape({
    valor: Yup.string().required("Campo obrigatório"),
    campanha: Yup.string().required("Senha é obrigatório"),
});
