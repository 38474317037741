import { Box, Text } from "alisson-application";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { useEffect, useState } from "react";
import Mask from "react-input-mask";
import { Permissions } from "../../Permission";
import { Myform, staticInitalValues, validadeForm } from "./config";

import { useContex } from "./Hook";

import { Button } from "../../Button";
import { MemorizedCampo } from "./areas";
import { Contato } from "./contato";

import { useCliente } from "../../../../Hook/system/useCliente";
import { ClientCNPJData } from "../../../../Hook/types";
import { cleanString } from "../../../../util/helper";
import { FormStyles } from "./styles";

interface FormProps {}

export function     FormCliente(props: FormProps) {
    const { recoveryClientCnpjData } = useCliente()
    const { setModal, contatos, initialValues, update } = useContex();
    
    const [values, setValues] = useState<Myform>({} as Myform);
    const [cnpjInfo, setCnpjInfo] = useState<ClientCNPJData>({} as ClientCNPJData)

    const contatoNomesUnicosArea = [
        ...new Set(contatos.map((contato) => contato.area.label)),
    ];

    const agrupandoAreas = contatoNomesUnicosArea.map((item) => {
        return [contatos.filter((contato) => contato.area.label === item)];
    });

    async function handleSearchForCnpjInfo(e: React.ChangeEvent, updateField: (field: string, value: string) => void) {
        const defaultCnpjLenght = 14;
        const target = e.target as never as HTMLInputElement;

        const cnpj = cleanString(target.value);

        if (cnpj.length === defaultCnpjLenght) {
            const response = await recoveryClientCnpjData(cnpj);
            const name = response.data?.nome || ""
            const fantasy = response.data?.fantasia || ""

            setCnpjInfo(response)
            updateField("nome", name)
            updateField("razao_social", fantasy)
        }
    }

    useEffect(() => {
        if (Object.keys(initialValues).length > 0) {
            setValues(initialValues);
        } else {
            setValues(staticInitalValues);
        }
    }, [initialValues]);

    return (
        <FormStyles>
            <Formik
                enableReinitialize
                initialValues={values}
                validationSchema={validadeForm}
                onSubmit={async (values, actions) => {
                    await update(values);
                }}
            >
                {(props: FormikProps<Myform>) => {
                    const { handleChange, setFieldValue, values } = props;
                    const { cnpj } = values;

                    return (
                        <Form>
                            <Text mb="8px" fontWeight={500} fontSize="xss">
                                DADOS DA EMPRESA
                            </Text>

                            <div className="container-input-system cliente">
                                <Mask
                                    mask="99.999.999/9999-99"
                                    placeholder="CNPJ"
                                    name="cnpj"
                                    type="tel"
                                    onChange={(e) =>{ 
                                        handleSearchForCnpjInfo(e, setFieldValue); 
                                        handleChange(e)
                                    }}
                                    value={cnpj}
                                />
                            </div>
                            <ErrorMessage component="span" name="cnpj" />

                            <div className="container-input-system user">
                                <Field
                                    type="text"
                                    placeholder="NOME"
                                    name="nome"
                                    disabled={cnpjInfo.data?.nome}
                                />
                            </div>
                            <ErrorMessage component="span" name="nome" />

                            <div className="container-input-system cliente">
                                <Field
                                    type="text"
                                    placeholder="NOME/ RAZÃO SOCIAL"
                                    name="razao_social"
                                    disabled={cnpjInfo.data?.fantasia}
                                />
                            </div>
                            <ErrorMessage
                                component="span"
                                name="razao_social"
                            />

                            <Text className="areas">ÁREAS</Text>

                            <Field component={MemorizedCampo} />

                            <Text
                                mb="8px"
                                mt="36px"
                                fontWeight={500}
                                fontSize="xss"
                            >
                                CONTATOS
                            </Text>

                            <Permissions role="cliente.area.contato.store">
                                <Button
                                    onClick={() => setModal(true)}
                                    className="btn-contato"
                                    type="button"
                                >
                                    ADICIONAR CONTATO
                                </Button>
                            </Permissions>

                            {agrupandoAreas.map((grupo) => {
                                return (
                                    <>
                                        <Text
                                            as="h1"
                                            m="10px 0px"
                                            textAlign="center"
                                            fontWeight="bold"
                                            fontSize="mdl"
                                        >
                                            {grupo[0][0].area.label}
                                        </Text>
                                        {grupo.map((contato) => {
                                            return contato.map((contato) => {
                                                const {
                                                    nome,
                                                    email,
                                                    celular,
                                                    telefone,
                                                } = contato;
                                                return (
                                                    <Contato
                                                        key={contato.area.value}
                                                        id={contato.id}
                                                        area={contato.area}
                                                        celular={celular}
                                                        email={email}
                                                        nome={nome}
                                                        telefone={telefone}
                                                    />
                                                );
                                            });
                                        })}
                                    </>
                                );
                            })}

                            <Box width="220px" mt="30px" ml="auto">
                                <Button>EDITAR CLIENTE</Button>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </FormStyles>
    );
}
