import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../services";
import { toast } from "react-toastify";
import { Cartoes } from "../user/UseCartaoes";
import { PaginationConfig } from "./config";

interface UseNonameProviderProps {
    children: React.ReactChild | React.ReactChild[];
}

interface UseNonameModal {
    getDetalheCartaoVirtual: () => Promise<void>;
    virtualDetail: VirtualDetailsTotal;
    getCarga(page?: number): Promise<void>;
    configCargas: PaginationConfig;
    cargas: Noname[];

    showCargas: ShowCarga;
    fetchShowCargas(): Promise<void>;
    searchCarga(value: string): Promise<void>;

    inProcess: () => Promise<void>;
    processes: process[];

    aprove: (id: number) => Promise<AxiosResponse>;
    disApproval: (id: number) => Promise<AxiosResponse>;

    storeCargaVirtual(): Promise<AxiosResponse>;

    loading: boolean;
}

const UseNonameContext = createContext<UseNonameModal>({} as UseNonameModal);

interface NonameData extends PaginationConfig {
    data: Noname[];
}

export interface Noname {
    id: number;
    campanha_id: null | number;
    user_id: number;
    approve_user_id: null | number;
    nome: string;
    tipo: string;
    status: string;
    file: string;
    created_at: string;
    updated_at: string;
    total_itens_users: number;
    total_itens_credito_sum: null | number;
    campanha: null | number;
    approve_user: null | User;
    user: User;
}

interface User {
    id: number;
    name: string;
    email: string;
    document: string;
    phone_number: string;
    status: number;
    register_status: number;
    first_login: number;
    image: null | number;
    created_at: string;
    updated_at: string;
    enderecos: [];
}

export interface ShowCarga {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: null | number;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    users_log: [];
    creditos_log: [];
    valores_log: [];
    cartoes_log: [];
    nonames: ShowCargaNoname[];
    virtuais: ShowCargaNoname[];
}

interface ShowCargaNoname {
    id: number;
    carga_id: number;
    user_id: number;
    cartao_id: number;
    created_at: string;
    updated_at: string;
    user: User | null;
    cartao: Cartoes;
}

interface porcemetroData {
    data: process[];
}

export interface process {
    id: number;
    campanha_id: number;
    user_id: number;
    approve_user_id: string | null;
    nome: string;
    tipo: string;
    status: string;
    created_at: string;
    updated_at: string;
    user: User;
    total_itens_users: number;
    total_itens_credito_sum: string;
}

export interface VirtualDetailsTotal {
    total_avulso: string;
    total_fisico: string;
    total_fisico_ativo: string;
    total_fisico_nao_ativo: string;
    total_nao_vinculados_avulso: string;
    total_nao_vinculados_virtual: string;
    total_vinculados_avulso: string;
    total_vinculados_virtual: string;
    total_virtual: string;
    total_virtual_acg: number;
    total_virtual_acg_nao_importado_carga: number;
}

export function UseNonameProvider(props: UseNonameProviderProps) {
    const { children } = props;
    const history = useHistory<{ id: number }>();
    const [loading, setLoading] = useState(false);
    const [cargas, setCargas] = useState<Noname[]>([]);
    const [virtualDetail, setVirtualDetail] = useState<VirtualDetailsTotal>(
        {} as VirtualDetailsTotal
    );
    const [configCargas, setConfigCargas] = useState<PaginationConfig>(
        {} as PaginationConfig
    );
    const [processes, setProcesses] = useState<process[]>([]);
    const [showCargas, setShowCargas] = useState<ShowCarga>({} as ShowCarga);
    const id = history.location.state?.id;
    const path = history.location.pathname.replace(/[0-9]+/g, ":id");

    const getCarga = useCallback(async (page?: number) => {
        setLoading(true);
        const hasPage = page ? `&page=${page}` : "";
        try {
            const response = await api.get<NonameData>(
                `carga?tipo[]=VIRTUAL${hasPage}`
            );
            const { data, ...resto } = response.data;

            setCargas(data);
            setConfigCargas(resto);
            setLoading(false);
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            setCargas([]);
            setLoading(false);
        }
    }, []);

    const getDetalheCartaoVirtual = useCallback(async () => {
        try {
            const response = await api.get<VirtualDetailsTotal>(
                `reports/cards-details-total`
            );

            setVirtualDetail(response.data);
        } catch (error: any) {
            setVirtualDetail({} as VirtualDetailsTotal);
        }
    }, []);

    const inProcess = useCallback(async () => {
        try {
            const { data } = await api.get<porcemetroData>(
                `carga?status=AGUARDANDO&tipo[]=VIRTUAL`
            );
            setProcesses(data.data);
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            setProcesses([]);
        }
    }, []);

    const fetchShowCargas = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await api.get<ShowCarga>(`carga/${id}`);
            setShowCargas(data);
            setLoading(false);
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            setShowCargas({} as ShowCarga);
            setLoading(false);
        }
    }, [id]);

    async function aprove(id: number) {
        try {
            const response = await api.post(`carga/aprovar`, {
                carga_id: `${id}`,
            });
            await inProcess();
            toast.success("Carga aprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function disApproval(id: number) {
        try {
            const response = await api.post(`carga/reprovar`, {
                carga_id: `${id}`,
            });
            await inProcess();
            toast.success("Carga reprovada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao aprovar carga");
            return error;
        }
    }

    async function storeCargaVirtual() {
        try {
            const response = await api.post(`carga/virtual`);
            await getCarga();
            await inProcess();
            toast.success("Carga virtual criada com sucesso");
            return response;
        } catch (error: any) {
            toast.error("Erro ao criar carga virtual");
            return error;
        }
    }

    async function searchCarga(value: string) {
        setLoading(true);
        try {
            const { data } = await api.get<{ data: Cartoes[] }>(
                `carga/search?search=${value}`
            );

            setShowCargas({
                ...showCargas,
                nonames: [
                    ...data?.data?.map((item) => {
                        const carga = showCargas?.nonames?.find(
                            (noname) => noname.id === item.id
                        );
                        if (carga?.id) {
                            return {
                                ...carga,
                                cartao: {
                                    ...item,
                                    tipo:
                                        item.tipo === "cartao_avulso"
                                            ? "NONAME"
                                            : "VIRTUAL",
                                },
                            };
                        }
                        return {
                            id: 0,
                            carga_id: 0,
                            user_id: 0,
                            cartao_id: 0,
                            created_at: item.created_at ? item.created_at : "",
                            updated_at: item.updated_at ? item.updated_at : "",
                            user: null,
                            cartao: { ...item },
                        };
                    }),
                ],
            });

            setLoading(false);
        } catch (error: any) {
            toast.error("Erro ao carregar dados da carga");
            console.log(error);
            setShowCargas({} as ShowCarga);
            setLoading(false);
        }
    }

    useEffect(() => {
        // if (path === "/sistema/cartao-virtual") {
        //     getDetalheCartaoVirtual();
        //     getCarga();
        //     inProcess();
        // }

        // if (path === "/sistema/cartao-virtual/detalhe/:id") {
        //     fetchShowCargas();
        // }

        if (path === "/sistema/buscar-cartao") {
            getDetalheCartaoVirtual();
        }
    }, [getCarga, inProcess, fetchShowCargas, getDetalheCartaoVirtual, path]);

    return (
        <UseNonameContext.Provider
            value={{
                getCarga,
                getDetalheCartaoVirtual,
                virtualDetail,
                configCargas,
                cargas,
                searchCarga,
                fetchShowCargas,
                showCargas,
                inProcess,
                processes,
                aprove,
                disApproval,
                storeCargaVirtual,
                loading,
            }}
        >
            {children}
        </UseNonameContext.Provider>
    );
}

export function useNoname() {
    const context = useContext(UseNonameContext);
    return context;
}
