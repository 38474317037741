import styled from "styled-components";

export const Container = styled.div`
    padding-top: 67px;
    padding-bottom: 74px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    small {
        color: ${(props) => props.theme.colors.primary};
        display: block;
        text-align: center;
    }

    .container-img {
        position: absolute;
        left: 50%;
        top: -85px;
        transform: translateX(-50%);
    }

    .container-text {
        text-align: center;
        margin-bottom: 26px;
        h4 {
            color: ${(props) => props.theme.colors.primary};
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
        }

        p {
            color: ${(props) => props.theme.colors.primary};
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }

    .arquivo {
        border-radius: 60px;
        margin-bottom: 7px;
        justify-content: center;
        padding: 0px;
        transition: all 0.3s ease-in-out;
        &.error {
            border: 1px solid #eb5757;
            transition: all 0.3s ease-in-out;
        }
    }
    .error-input {
        color: #eb5757;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.96px;
    }
`;
