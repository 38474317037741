import styled, { css } from "styled-components";
import Yimg from "../../../assets/img/Y.svg";

const status = css`
    filter: grayscale(1);
`;

export const Container = styled.div`
    padding-top: 36px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px 0px 75px rgba(0, 0, 0, 0.05);
    height: 290px;
    position: relative;
    padding-bottom: 36px;
    margin-top: 35px;
    overflow: hidden;
    position: relative;
    .icone-menu {
        position: absolute;
        top: 9px;
        right: 6px;
        z-index: 99;
        button {
            background: ${(props) => props.theme.colors.white};
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 7px;

            border-radius: 64px;
            outline: none;
            p {
                font-style: normal;
                font-weight: 600;
                font-size: 5.625px;
                letter-spacing: 0.08em;
                color: ${(props) => props.theme.colors.secondary};
                padding-top: 2.9px;
            }
            img {
                display: block;
                width: 7px;
                margin-left: 7px;
            }
        }
    }

    .swiper-pagination {
        &.swiper-pagination-bullets {
            bottom: -35px;
        }
    }
    .container {
        .slide-cartoes {
            overflow: unset;
            width: 100%;
        }
    }
`;

interface CardProps {
    background: string;
    status: boolean;
}

export const CardStyles = styled.div<CardProps>`
    box-shadow: ${(props) =>
        props.status ? "none" : "0px 20px 40px rgba(42, 82, 39, 0.2)"};
    border-radius: 8px;
    height: 150px;
    padding-left: 9px;
    padding-right: 5px;
    padding-top: 22px;
    display: flex;
    flex-direction: column;

    padding-bottom: 8px;
    position: relative;
    z-index: 20;
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(${Yimg});
        background-size: cover;
        background-repeat: no-repeat;
        background: ${(props) => props.background};
        ${(props) => props.status && status}
        border-radius: 8px;
        z-index: -1;
    }
    p {
        &.type {
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 14px;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 9px;
        }
    }
    .saldo {
        border: 0.658986px solid ${(props) => props.theme.colors.white};
        height: 35px;
        border-radius: 2.63594px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        div {
            display: flex;
            align-items: start;
            height: 16px;
            padding-left: 5px;
        }
        span {
            font-style: normal;
            font-weight: 600;
            font-size: 5.85585px;
            line-height: 100%;
            display: block;
            padding-top: 1px;

            color: ${(props) => props.theme.colors.white};
        }

        p {
            font-style: normal;
            font-weight: 600;
            font-size: ${(props) => props.theme.fonts.md};
            line-height: 100%;
            color: ${(props) => props.theme.colors.white};
        }
    }

    .bandeira {
        text-align: left;
        ${(props) => props.status && status}
    }

    .number-card {
        margin-top: 28px;
        margin-bottom: 43px;
        p {
            font-style: normal;
            font-weight: 600;
            font-size: 0.531rem;
            line-height: 100%;
            letter-spacing: 0.06em;
            white-space: nowrap;
            color: ${(props) => props.theme.colors.white};
        }
    }
`;
