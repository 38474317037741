import { ButtonStyles, Button } from "./styles";
import { IArrTabs } from ".";

interface ButtonsProps {
    handleTab(str: "mensagens" | "enviar"): void;
    tabs: IArrTabs[];
}

export function Buttons(props: ButtonsProps) {
    const { handleTab, tabs } = props;
    return (
        <ButtonStyles>
            <Button
                className={`${tabs[0].active ? "active" : ""}`}
                onClick={() => handleTab("mensagens")}
            >
                Mensagens enviadas
            </Button>
            <Button
                className={`${tabs[1].active ? "active" : ""}`}
                onClick={() => handleTab("enviar")}
            >
                Nova Mensagem
            </Button>
        </ButtonStyles>
    );
}
