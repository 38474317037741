import { Text } from "alisson-application";
import { FieldProps } from "formik";
import { memo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Permissions } from "../../Permission";

import { toast } from "react-toastify";
import imgX from "../../../../assets/img/x-vermelho.svg";
import { getLocalStore } from "../../../../util/helper";
import { Button } from "../../Button";
import { ArrayArea, useContex } from "./Hook";

interface CamposProps extends FieldProps {}

function Campos(props: CamposProps) {
    const {
        areas,
        setAreas,
        contatos,
        setCliemtesEnUtilizacao,
        setModalAviso,
    } = useContex();

    const [tempArea, setTempArea] = useState<ArrayArea[]>([]);
    const [foundArea, setFoundArea] = useState<ArrayArea|undefined>();
    const ref = useRef<HTMLInputElement>(null);
    const uniqueValuesSet = new Set();

    const hasPermission = getLocalStore().permissions["cliente.area.update"];

    function handleAddArea() {
        if (ref.current?.value) {
            const value = ref.current.value;
            const foundArea = areas.find(area => area.label === value);
            const hasIsValue = contatos.find(
                (item) =>
                    item.area.label.toLowerCase() === value.toLocaleLowerCase()
            )?.id;

            if (hasIsValue || !!foundArea?.value) {
                toast.error(`Está área já existe ${value}`);
                return;
            }

            ref.current.value = "";
            setAreas([...areas, { value: uuidv4(), label: value }]);
        }
    }

    function handleRemoveArea(id: string) {
        const updatedAreas = areas.filter((a) => a.value !== id);

        const hasValues = contatos.filter(
            (contato) => id === contato.area.value
        );

        if (hasValues.length === 0) {
            setAreas(updatedAreas);
            setTempArea(updatedAreas);
        } else {
            setCliemtesEnUtilizacao(hasValues);
            setModalAviso(true);
        }
    }

    function handleChange(newValue: string, id: string) {
        const foundArea = areas.find(area => area.label === newValue);
        setFoundArea(foundArea);

        const updateArea = areas.filter((area) => {
            if (area.value === id) {
                area.label = newValue;
                return area;
            }
            return area;
        });

        const filteredArr = [...updateArea, ...tempArea].filter((obj) => {
            // check if name property value is already in the set
            const isPresentInSet = uniqueValuesSet.has(obj.value);

            // add name property value to Set
            uniqueValuesSet.add(obj.value);

            // return the negated value of
            // isPresentInSet variable
            return !isPresentInSet;
        });

        setAreas(filteredArr);
        setTempArea(filteredArr);
    }

    return (
        <>
            <div className="container-input-system">
                <input type="text" placeholder="NOME DA ÁREA" ref={ref} />
                <Permissions role="cliente.area.store">
                    <Button onClick={handleAddArea} type="button">
                        ok
                    </Button>
                </Permissions>
            </div>

            <Text as="span" fontSize="xss" color="primaryDark">
                Digite o nome da área e clique no botão para cadastrar
            </Text>
            <div className="itens-areas">
                {areas.map((area) => {
                    return (
                        <div
                            key={area.value}
                            className={`container-input-system area ${
                                hasPermission ? "" : "disabled"
                            }`}
                        >
                            <input
                                type="text"
                                onChange={(e) => {
                                    handleChange(e.target.value, area.value);
                                }}
                                disabled={hasPermission ? false : true}
                                value={area.label}
                            />
                            <Permissions role="cliente.area.destroy">
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(area.value)}
                                >
                                    <img src={imgX} alt="" />
                                </button>
                            </Permissions>
                        </div>
                    );
                })}

                <br />
                {foundArea?.label ? <span className="error">Campo {foundArea.label} não pode ser usado mais de uma vez!</span> : null}
            </div>
        </>
    );
}

export const MemorizedCampo = memo(Campos);
