import { useState } from "react";
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { useHistory } from "react-router-dom";
import { Button } from "../../Button";
import { IAlterarSenha, altearSenha, validationAlterarSenha } from "./config";
import { StylesAlterarSenha } from "./styles";
import imgOlho from "../../../../assets/img/olho.svg";
import { useUser } from "../../../../Hook/system/useUser";
import { CheckPassword } from "../../../CheckPassword";

interface FormAlterarSenhaProps {
    onSuccess: () => void;
}

export function FormAlterarSenha(props: FormAlterarSenhaProps) {
    const [olhoSenha, setOlhoSenha] = useState(false);
    const [loading, setLoading] = useState(false);
    const { resetPasswordUserPlataform } = useUser();
    const history = useHistory<{ id: number }>();
    const id = history.location.state?.id;

    return (
        <StylesAlterarSenha>
            <h5>Alterar Senha</h5>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={altearSenha}
                validationSchema={validationAlterarSenha}
                onSubmit={async (values, actions) => {
                    setLoading(true);

                    try {
                        if (id) {
                            await resetPasswordUserPlataform(
                                id,
                                values.password
                            );

                            props.onSuccess();
                        }
                        setLoading(false);
                    } catch (error) {
                        console.log(error);
                        setLoading(false);
                    }
                }}
            >
                {(props: FormikProps<IAlterarSenha>) => {
                    const { values } = props;

                    return (
                        <Form>
                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password &&
                                    props.touched.password
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    maxLength={8}
                                    value={values.password}
                                    placeholder="SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>

                            <ErrorMessage name="password" component="span" />

                            <div
                                className={`container-input chave-cartao olho ${
                                    props.errors.password_confirmation &&
                                    props.touched.password_confirmation
                                        ? "error"
                                        : ""
                                }`}
                            >
                                <Field
                                    name="password_confirmation"
                                    type={`${olhoSenha ? "text" : "password"}`}
                                    value={values.password_confirmation}
                                    placeholder="CONFIRMAR SENHA"
                                />

                                <button
                                    type="button"
                                    className={`${olhoSenha ? "active" : ""} `}
                                    onClick={() => setOlhoSenha(!olhoSenha)}
                                >
                                    <img src={imgOlho} alt="" />
                                </button>
                            </div>
                            <ErrorMessage
                                name="password_confirmation"
                                component="span"
                            />

                            {/* <Text
                                as="p"
                                className="informacao-senha"
                                m="17px auto 32px auto"
                                textAlign="center"
                                width="250px"
                            >
                                Sua senha deve ser numérica e possuir 8 dígitos.
                            </Text> */}

                            <CheckPassword values={values.password} />

                            <div className="mt-auto">
                                <Button
                                    mt="70px"
                                    type="submit"
                                    spinerColor="primary"
                                    isLoading={loading}
                                    disabled={!props.isValid}
                                    spiner="BallTriangle"
                                >
                                    ALTERAR SENHA
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </StylesAlterarSenha>
    );
}
