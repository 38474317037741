import { Text } from "alisson-application";
import imgSeguranca from "../../assets/img/seguranca.svg";
import { SegurancaStyles } from "./styles";
import { Button } from "../Desktop/Button";
import { usePassos } from "../../Hook/UsePassos";

export function Descricao() {
    const { nextPasso } = usePassos();

    return (
        <SegurancaStyles>
            <div className="container-img">
                <img src={imgSeguranca} alt="" />
            </div>

            <Text as="h2" color="secondary">
                Redefinição de senha
            </Text>

            <Text as="p">
                Para sua segurança, será necessário redefinir sua senha de
                acesso.
            </Text>

            <div className="container-btn">
                <Button onClick={() => nextPasso(2)}>avançar</Button>
            </div>
        </SegurancaStyles>
    );
}
