import { useEffect, useState } from "react";
import { Box } from "alisson-application";
import { HeaderNovo } from "../Header/novo";
import { Button } from "../Button";
import { useSegmentacao } from "../../../Hook/system/useSegmentacao";
import { Item } from "./item";
import { StylesClient } from "./styles";

interface OptionsArr {
    checked: boolean;
    id: string;
    nome: string;
}

interface ClienteProps {}

export function Cliente(props: ClienteProps) {
    const { getClient, setModal, getCampanha } = useSegmentacao();

    const [optionsArr, setOptionsArr] = useState<OptionsArr[]>([]);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const cliente = await getClient();
            const arrCli = cliente.map((cli) => {
                return {
                    checked: false,
                    id: cli.id + "",
                    nome: cli.nome,
                };
            });

            setOptionsArr(arrCli);
        }

        fetchData();
    }, [getClient]);

    function handleChange(id: string) {
        const update = optionsArr.map((item) => {
            if (item.id === id) {
                item.checked = !item.checked;
                return item;
            }
            return item;
        });

        setOptionsArr(update);
    }

    function handleChangeAll() {
        const check = !checkAll;
        const update = optionsArr.map((item) => {
            item.checked = check;
            return item;
        });

        setCheckAll(check);
        setOptionsArr(update);
    }

    return (
        <>
            <HeaderNovo
                title="SELECIONAR CLIENTES"
                ml="10"
                onClick={() => setModal(false)}
            />
            <StylesClient>
                <div className="container-header">
                    <Item
                        checked={
                            optionsArr.every((item) => item.checked)
                                ? true
                                : checkAll
                        }
                        handleChange={() => handleChangeAll()}
                        id={""}
                        label={"SELECIONAR TODOS"}
                    />
                </div>

                <div className="container-body">
                    {optionsArr.map((item) => {
                        return (
                            <Item
                                checked={item.checked}
                                handleChange={(id) => handleChange(id)}
                                id={item.id + ""}
                                label={item.nome}
                            />
                        );
                    })}
                </div>

                <Box mt="50px" width="220px" ml="auto">
                    <Button
                        type="button"
                        onClick={async () => {
                            await getCampanha(
                                optionsArr
                                    .filter((item) => item.checked)
                                    .map((item) => parseInt(item.id))
                            );
                        }}
                    >
                        AVANÇAR
                    </Button>
                </Box>
            </StylesClient>
        </>
    );
}
